import React, { useEffect, useState } from "react";
import Alert from "./Alert";
import { GlobalContext } from "./App";
import { mailbotsAdminBrowser } from "./lib/utils";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { ExpandableStateful } from "./Expandable";
import { message as antMessage } from "antd";
import Loading from "./Loading";
import { Popover } from "antd";
import { logger } from "./lib/Logger";
import { ToolFilled } from "@ant-design/icons";

export function DeliveryLogs(props) {
  const { reloadUser, loggedInUser } = React.useContext(GlobalContext);
  const [deliveryLogsBounced, setDeliveryLogsBounced] = useState();
  const [deliveryLogsAll, setDeliveryLogsAll] = useState();

  const taskid = queryString.parse(window.location.search).taskid || "";

  useEffect(() => {
    (async function () {
      // fetch bounced logs
      const { delivery_logs: bouncedLogs } =
        await mailbotsAdminBrowser.getDeliveryLogs({
          delivery_status: "errors",
          taskid
        });

      setDeliveryLogsBounced(bouncedLogs);

      // fetch normal logs
      const { delivery_logs: allLogs } =
        await mailbotsAdminBrowser.getDeliveryLogs({ taskid });
      setDeliveryLogsAll(allLogs);
    })();
  }, []);

  const removeDeliveryProblems = async () => {
    try {
      await mailbotsAdminBrowser.userUpdateSelf({
        user: {
          user_warning: ""
        }
      });
      logger.log("delivery warning removed by user");
      await reloadUser();
      antMessage.success("Delivery warning removed");
    } catch (e) {
      console.error(e);
      antMessage.error(e);
    }
  };

  const hasDeliveryWarning = loggedInUser =>
    loggedInUser &&
    loggedInUser.user_warning &&
    (loggedInUser.user_warning === "delivery_errors" ||
      loggedInUser.user_warning.includes("could not be delivered"));

  return (
    <div
      style={{
        marginTop: 50,
        width: "90vw",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 1100
      }}
    >
      <div>
        {hasDeliveryWarning(loggedInUser) ? (
          <Alert level="grey" style={{ minWidth: 600 }}>
            <div>
              <p>
                <button
                  style={{ margin: 10, float: "right" }}
                  className="btn btn-primary"
                  onClick={removeDeliveryProblems}
                >
                  Remove Delivery Warning
                </button>
                Too many delivery emails can cause your account to become
                locked. Normally it's a quick fix (such as removing an old
                address, or contacting your IT department). You can remove this
                warning yourself by clicking the button to the right.
              </p>
              <ExpandableStateful more="How to fix delivery problems">
                <div>
                  <ul>
                    <li>
                      {" "}
                      If a valid address of yours is bouncing, read about{" "}
                      <a
                        href="https://help.followupthen.com/knowledge-base/my-followups-are-getting-bounced-or-deferred-by-my-email-provider/"
                        target="_blank"
                        rel="nofollower noopener noreferrer"
                      >
                        how to whitelist
                      </a>{" "}
                      emails from FollowUpThen.
                    </li>
                    <li>
                      If this is an old, inactive address of yours, remove it
                      from your <Link to="/settings">settings page</Link>. (You
                      may have to switch your primary email address first).
                    </li>
                    <li>
                      If someone else's address is bouncing, please ensure you
                      have permission to send automated reminders to them. Use
                      the <Link to="/skills/wl">Whitelabel skill</Link> to send
                      marketing-related followups.
                    </li>
                  </ul>
                  You can unlock your own account a limited number of times
                  before requiring help from FollowUpThen support. <br />
                </div>
              </ExpandableStateful>
            </div>
          </Alert>
        ) : null}
        {deliveryLogsBounced && deliveryLogsBounced.length ? (
          <>
            <h2>Tasks with delivery problems</h2>
            <p>
              Is one of your addresses bouncing our followups?{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/my-followups-are-getting-bounced-or-deferred-by-my-email-provider/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                <ToolFilled /> How to fix.
              </a>
            </p>
            <DeliveryLogsTable deliveryLogs={deliveryLogsBounced} />
          </>
        ) : null}
      </div>

      {taskid ? (
        <h2 style={{ marginTop: 60 }}>
          Delivery logs for task <Link to={`/tasks/${taskid}`}>{taskid}</Link>
        </h2>
      ) : (
        <h2 style={{ marginTop: 60 }}>All delivery logs</h2>
      )}
      {deliveryLogsAll && deliveryLogsAll.length > 0 ? (
        <div>
          <DeliveryLogsTable deliveryLogs={deliveryLogsAll} />
        </div>
      ) : null}
      {deliveryLogsAll && deliveryLogsAll.length === 0 ? (
        <p style={{ textAlign: "center" }}>No delivery logs</p>
      ) : null}
      {!deliveryLogsAll ? (
        <Loading style={{ marginLeft: "auto", marginRight: "auto" }} />
      ) : null}
    </div>
  );
}

export function DeliveryLogsTable({ deliveryLogs }) {
  const statusColors = {
    bounced: "#ffafaf",
    complained: "rgb(255, 102, 194)",
    deferred: "#ffda95",
    sent: "#e0ffe0"
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "80px 80px 1fr 2fr 80px",
        minWidth: 600,
        columnGap: 5,
        rowGap: 7
      }}
    >
      {/* Headers */}
      <div style={{ fontWeight: "bold" }}>Date</div>
      <div style={{ fontWeight: "bold" }}>Status</div>
      <div style={{ fontWeight: "bold" }}>Recipient</div>
      <div style={{ fontWeight: "bold" }}>Subject</div>
      <div style={{ fontWeight: "bold" }}>Task</div>
      {/* Bounces */}
      {deliveryLogs && deliveryLogs.length
        ? deliveryLogs.map((log, i) => (
            <React.Fragment key={i}>
              <div
                className="text-ellipsis"
                title={new Date(log.created).toLocaleString()}
              >
                {new Date(log.created).toLocaleDateString()}
              </div>
              <div className="text-ellipsis" title={log.delivery_status}>
                <Popover
                  content={
                    <div style={{ maxWidth: 300 }}>
                      {log.delivery_status === "bounced"
                        ? `We tried to send this email but the receiving email server rejected it`
                        : null}
                      {log.delivery_status === "deferred"
                        ? `The receiving email server temporarily rejected this email. We continue to try to deliver deferred messages over several hours. 
It usually succeeds eventually.  If this email was sent to you, placing  FollowUpThen on your "safe list" (described above) can help ensure your messages are delivered on time.`
                        : null}
                      {log.delivery_status === "deferred"
                        ? `The receiving email server accepted this email. If you have not yet received this
email, check your spam filters both on your computer and on the email server.`
                        : null}
                      {!["bounced", "deferred", "sent"].includes(
                        log.delivery_status
                      )
                        ? `Message status not yet received or recognized.`
                        : null}
                    </div>
                  }
                >
                  <span
                    style={{
                      padding: 3,
                      borderRadius: 4,
                      backgroundColor: statusColors[log.delivery_status]
                    }}
                  >
                    {log.delivery_status}
                  </span>
                </Popover>
              </div>
              <div className="text-ellipsis" title={log.to}>
                {log.to}
              </div>
              <div className="text-ellipsis" title={log.subject}>
                {log.subject}
              </div>
              <div className="text-ellipsis">
                {log.taskid ? (
                  <Link to={`/tasks/${log.taskid}`}>Related Task</Link>
                ) : null}
              </div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
}
