import React, { Component } from "react";
import _ from "lodash";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import cookie from "js-cookie";
import Alert from "./Alert";
import querySting from "query-string";
import MailBotDetailsSettings from "./MailBotDetailsSettings";
import NavLeft from "./NavLeft";
import { message as antMessage } from "antd";
import DocsLink from "./DocsLink";
import {
  mailbotIsInstalled,
  isCoreSkill,
  mailbotsAdminBrowser,
  getAccessToken
} from "./lib/utils";
import {
  checkSkillBillingLimits,
  getStripePlanId,
  getStripePlanPrice,
  isOnFreePlan,
  isPaying,
  userPaidForSkill,
  userIsBillingAnnually,
  planExistsForFlag,
  getPackagesForSkillFlag,
  planKeyExists,
  getSkillUpgradeState,
  getRemainingBudgetCent,
  getTeamAdmin,
  isEmployee
} from "./Billing/billingHelpers";
import {
  SkillTopPayForAddOn,
  SkillTopInstallSkill,
  SkillTopSetupNeeded,
  SkillTopAddBasePlan,
  AddBasePlan,
  SkillTopAddPackage,
  EmployeeAskForMoreBudget,
  SkillStatusLoading,
  SkillTopYouHaveSkill,
  SkillTopLegacyPlan,
  SkillTopYouHaveSkillMorePlans
} from "./Billing/UpgradeDialogs";
import Card from "./Card";
import { ResponsiveSettingsSubnav } from "./Layout";
import { logger } from "./lib/Logger";

class MailBotDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsSubmenu: null,
      skillUpgradeDialogVisible: false,
      needsSubscription: false,
      needsActivation: false,
      skillStatus: "loading"
    };
  }

  userExistsButIsntValidated() {
    return (
      this.props.loggedInUser && !_.get(this.props, "loggedInUser.is_validated")
    );
  }

  async componentDidMount() {
    if (this.userExistsButIsntValidated()) {
      cookie.set("mailbotsRedirect", `/skills/${this.props.mailbot.id}`);
    }
    await this.loadBillingInfo();
    await this.checkSkillNeedsActivation();
    this.loadSkillStatus();
    window.scrollTo(0, 0);
  }

  async componentWillReceiveProps(prevProps, nextProps) {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return;
    await this.checkSkillNeedsActivation();
    this.loadSkillStatus();
  }

  // For a skill to render a "Needs Activation" dialog:
  // 1. A setting with key name of "active" must exist.
  // 2. It must be set to a falsy value.
  // Otherwise, the UI assumes no activation needed.
  // @todo - move to  component will mount?
  checkSkillNeedsActivation = async () => {
    if (!mailbotIsInstalled(this.props.mailbot)) {
      return false; // most skills do not. Email will give an error message anyways
    }
    const settingsRes = await this.loadSettings();
    let needsActivation;
    try {
      const activeKeyExists =
        "active" in settingsRes.settings[this.props.mailbot.subdomain].formData;
      const activeKeyValue =
        settingsRes.settings[this.props.mailbot.subdomain].formData.active;
      // falsey values include "", "false" and undefined @todo fix types in mailbots framework
      needsActivation =
        activeKeyExists && (activeKeyValue === "false" || !activeKeyValue);
    } catch (e) {
      // When the "active" property does not exist, assume no activation needed
      needsActivation = false;
    }
    this.setState({ needsActivation });
  };

  loadSkillStatus = () => {
    const skillStatus = getSkillUpgradeState(
      this.props.loggedInUser,
      this.props.mailbot,
      this.state.needsActivation
    );
    this.setState({ skillStatus });
  };

  // Load settings specifically to check for the "active" key.
  // if present, show a "Needs Activation" is is not truthy
  loadSettings = async () => {
    try {
      const params = {
        urlParams: { coreSkillFlag: this.props.mailbot.subdomain },
        mailbotid: isCoreSkill(this.props.mailbot)
          ? process.env.REACT_APP_FUT_MAILBOT_ID
          : this.props.mailbot.id
      };
      const settingsRes = await mailbotsAdminBrowser.getMailBotSettings(params);
      const settingsSubmenu = this.getSettingsMenuItems(settingsRes.settings);
      this.setState({ settingsData: settingsRes, settingsSubmenu });
      return settingsRes;
    } catch (e) {
      logger.log("Error loading settings", { level: "warn", error: e });
    }
  };

  getSettingsMenuItems = mailbotSettings => {
    const settingsMenuItems = [];
    for (const key in mailbotSettings) {
      const settingsForm = mailbotSettings[key];
      const menuTitle = settingsForm.formMeta.menuTitle;
      settingsMenuItems.push({ key, menuTitle });
    }
    return settingsMenuItems;
  };

  loadBillingInfo = async () => {
    try {
      const res = await mailbotsAdminBrowser.getBillingInfo();
      const needsSubscription = checkSkillBillingLimits(
        this.props.mailbot,
        res.billing
      );
      console.log("needs subscription", needsSubscription);
      this.setState({ needsSubscription });
    } catch (error) {
      console.error(error);
      antMessage.error(error.message);
    }
  };

  /**
   * The MailBot details component dynamically builds the settings via webhook requests / responses.
   * The remote data defines the settings menu. Once received, it fires this callback to the containing
   * element can render the menu.
   */
  setSettingsSubmenu = settingsSubmenu => {
    this.setState({ settingsSubmenu });
  };

  billAddOnPlanIfNeeded = async () => {
    const price = this.getSkillPrice();

    // don't bill if user has it, or it's free
    const skillFlag = this.props.mailbot.subdomain;
    if (userPaidForSkill(this.props.loggedInUser, skillFlag) || price == 0)
      return true;

    if (!this.confirmAddOnBilling()) return false; // aborts install

    const userId = this.props.loggedInUser && this.props.loggedInUser.id;
    const billingAnnually = userIsBillingAnnually(this.props.loggedInUser);
    const stripePlanId = getStripePlanId(
      this.props.mailbot.subdomain,
      billingAnnually
    );
    if (!stripePlanId)
      throw Error(`Error: No plan for ${this.props.mailbot.subdomain}`);
    return mailbotsAdminBrowser
      .addSubscriptionAddons({
        userId: userId,
        plans: [stripePlanId]
      })
      .then(() => {
        logger.log("billing: subscribed to add-on", {
          data: { plans_arr: stripePlanId }
        });
        return true;
      })
      .catch(e => {
        logger.log("billing: error subscribing to addon", {
          level: "error",
          data: { error_obj: e }
        });
        window.alert(
          `We ran into an error trying to add this skill: ${
            e.message
          } Please contact ${
            isEmployee(this.props.loggedInUser)
              ? "your team administrator or"
              : ""
          } help@humans.fut.io if you need help`
        );
        return false; // aborts install
      });
  };

  confirmAddOnBilling = () => {
    // return this.confirmAddOnBillingOwner()
    if (isEmployee(this.props.loggedInUser)) {
      return this.confirmAddOnBillingEmployee();
    } else
      return window.confirm(`This will add $${this.getSkillPrice()}/mo ${
        userIsBillingAnnually(this.props.loggedInUser)
          ? "(billed annually)."
          : ""
      } \
Cancel any time to receive an account credit for any unused time.`);
  };

  confirmAddOnBillingEmployee = () => {
    if (
      !window.confirm(
        `This will use $${this.getSkillPrice()} of your \
monthly skill budget. Ok to proceed?`
      )
    ) {
      return false;
    }
    return true;
  };

  handleInstall = async e => {
    if (e) e.preventDefault();
    if (!(await this.billAddOnPlanIfNeeded())) return;
    if (isCoreSkill(this.props.mailbot)) {
      const accessToken = getAccessToken();
      this.props.reloadUser({ accessToken }); // reloads user billing data
      return antMessage.info("Skill activated!");
    } else if (this.userExistsButIsntValidated()) {
      return antMessage.info(
        "Oops. We need to confirm your email aaddress first."
      );
    } else {
      return (window.location.href =
        this.props.mailbot.install_url || this.props.mailbot.base_url);
    }
  };

  toggleSkillUpgradeDialog = isVisible => {
    this.setState({ skillUpgradeDialogVisible: isVisible });
  };

  getSkillPrice = () => {
    const billAnnually = userIsBillingAnnually(this.props.loggedInUser);
    const planKey = this.props.mailbot.subdomain; // conventionally, this is the case. Package plans to not have subdomain : plankey correspondance
    const skillPrice = getStripePlanPrice(planKey, billAnnually);
    return skillPrice;
  };

  /**
   * If a skill is listed as part of a package, show its package, otherwise assume it is included
   */
  // extract this into a testable function...test every condition with
  // employees, the crazy variations.
  getUpgradeDialog = () => {
    switch (this.state.skillStatus) {
      case "loading":
        return <SkillStatusLoading />;
      case "request_budget":
        return <EmployeeAskForMoreBudget mailbot={this.props.mailbot} />;
      case "upgrade_addon_plan":
        return (
          <SkillTopPayForAddOn
            skillPrice={this.getSkillPrice()}
            skillName={this.props.mailbot.name}
            onActivate={this.handleInstall}
            billAnnually={userIsBillingAnnually(this.props.loggedInUser)}
            skillFlag={this.props.mailbot.subdomain}
          />
        );

      case "offer_package": // same dialog for new and existing users
      case "upgrade_package":
        const packages = getPackagesForSkillFlag(this.props.mailbot.subdomain);
        const packageNames =
          _.uniqBy(
            packages.map(p => p.name),
            "planKey"
          ) || [];
        return (
          <SkillTopAddPackage
            loggedInUser={this.props.loggedInUser}
            headline={`This skill is part of the ${packageNames.join(", ")} ${
              packageNames.length > 1 ? "packages" : "package"
            }`}
            text="Upgrade to enable this and other skills on your account"
          />
        );

      case "offer_addon_plan":
        return (
          <SkillTopAddBasePlan
            skillPrice={this.getSkillPrice()}
            skillName={this.props.mailbot.name}
            loggedInUser={this.props.loggedInUser}
            // open ChoosePlan Modal
          />
        );

      case "upgrade_addon_plan_legacy":
        return (
          <SkillTopLegacyPlan
            skillPrice={this.getSkillPrice()}
            skillName={this.props.mailbot.name}
            loggedInUser={this.props.loggedInUser}
            // open ChoosePlan Modal
          />
        );

      case "install":
        return (
          <SkillTopInstallSkill
            skillName={this.props.mailbot.name}
            onActivate={this.handleInstall}
            mailbot={this.props.mailbot}
          />
        );

      case "setup_dialog":
        return (
          <SkillTopSetupNeeded
            skillName={this.props.mailbot.name}
            settingsUrl={`/skills/${this.props.mailbot.id}/settings`}
            skillFlag={this.props.mailbot.subdomain}
          />
        );

      case "ok_more_choices":
        return (
          <div>
            <SkillTopYouHaveSkillMorePlans
              skillFlag={this.props.mailbot.subdomain}
            />
          </div>
        );

      case "ok":
        return (
          <SkillTopYouHaveSkill skillFlag={this.props.mailbot.subdomain} />
        );

      default:
        logger.log("billing: unhandled upgrade dialog");
        debugger;
        return (
          <Card style={{ textAlign: "center" }}>
            <div>Contact us to activate this skill.</div>
          </Card>
        );
    }
  };

  render() {
    if (!this.props.mailbot) {
      return (
        <Card type="medium">
          <h3>Skill Not Found</h3>
          <p>
            Running into trouble?{" "}
            <a href="mailto:help@humans.fut.io">Contact us</a>.
          </p>
        </Card>
      );
    }

    // @todo Display skills in modal https://github.com/rsweetland/gopher-admin-ui/issues/32
    const buildSettingsPageUrl = settingsItem => {
      if (window.location.href.startsWith("/tasks")) {
        const matches = window.location.href.search(/^\/tasks\/[0-9]+/);
        const hrefBase = matches[0];
        return `${hrefBase}/settings/${settingsItem.key}`;
      } else if (window.location.href.startsWith("/skills")) {
        return `/skills/${this.props.mailbot.id}/settings/${settingsItem.key}`;
      }
    };

    // submenu rendered under the "Settings" option
    const SettingsSubmenu = ({ settingsSubmenu }) => {
      // most of the time there is only one page, in which case we don't need a submenu
      if (settingsSubmenu.length === 1) return null;
      return (
        <ul className="mailbot-submenu">
          {settingsSubmenu.map(settingsItem => (
            <li title={settingsItem.menuTitle} key={settingsItem.key}>
              <NavLink
                to={`/skills/${this.props.mailbot.id}/settings/${settingsItem.key}`}
                onClick={window.scrollTo(0, 0)}
              >
                {settingsItem.menuTitle}
              </NavLink>
            </li>
          ))}
        </ul>
      );
    };

    // It's confusing to only have "About" as the only nav item. Hide it if no other menu option is there.
    const showAboutLink = () => {
      if (!this.state.settingsData || !this.state.settingsData.settings)
        return false;
      if (
        this.state.skillStatus === "ok" ||
        this.state.skillStatus === "ok_more_choices" ||
        this.state.skillStatus === "setup_dialog"
      )
        return true;
    };

    return (
      <div
        className="row"
        style={{
          position: "relative",
          width: "98%",
          maxWidth: 1000,
          margin: "auto"
        }}
      >
        {!this.props.mailbot.enabled && (
          <p className="text-muted" style={{ textAlign: "center" }}>
            Your skill is only visible to you.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="alert-link"
              href="https://fut.readme.io/v1.0/docs/sharing"
            >
              How To Share
            </a>
          </p>
        )}
        <div
          className="col-sm-2 sub-nav text-center sticky-left-nav-on-desktop "
          style={{ paddingTop: 50, zIndex: 1, textAlign: "left" }}
        >
          <img
            alt="icon"
            src={
              this.props.mailbot.icon ||
              "https://files-38348em97-mailbots.vercel.app/"
            }
            className="mailbots-icon large"
          />
          <br />
          <NavLeft mobileTitle={"Menu"}>
            {(this.state.skillStatus === "ok" ||
              this.state.skillStatus === "ok_more_choices" ||
              this.state.skillStatus === "setup_dialog") &&
            this.state.settingsData &&
            this.state.settingsData.settings ? (
              <li>
                <NavLink to={`/skills/${this.props.mailbot.id}/settings`}>
                  Settings
                </NavLink>
                {this.state.settingsSubmenu &&
                this.state.settingsSubmenu.length > 0 ? (
                  <SettingsSubmenu
                    settingsSubmenu={this.state.settingsSubmenu}
                  />
                ) : null}
              </li>
            ) : null}
            {showAboutLink() ? (
              <li>
                <NavLink to={`/skills/${this.props.mailbot.id}`} exact>
                  About
                </NavLink>
              </li>
            ) : null}
            {this.props.mailbot.learn_more_url && (
              <li>
                <a href={this.props.mailbot.learn_more_url} target="_blank">
                  App Homepage{" "}
                  <i className="glyphicon glyphicon-new-window text-muted" />
                </a>
              </li>
            )}
            {this.props.mailbot.help_url && (
              <li>
                <a target="_blank" href={this.props.mailbot.help_url}>
                  Help{" "}
                  <i className="glyphicon glyphicon-new-window text-muted" />
                </a>
              </li>
            )}
            {this.props.mailbot.privacy_policy_url && (
              <li>
                <a href={this.props.mailbot.privacy_policy_url} target="_blank">
                  Privacy Policy{" "}
                  <i className="glyphicon glyphicon-new-window text-muted" />
                </a>
              </li>
            )}
            {mailbotIsInstalled(this.props.mailbot) &&
              !isCoreSkill(this.props.mailbot) &&
              this.props.uninstallMailBot && (
                <li>
                  <a onClick={this.props.uninstallMailBot}>Deactivate</a>
                </li>
              )}
            {this.props.mailbot.oauth2_client_secret && (
              <React.Fragment>
                <li style={{ borderTop: "1px solid #efefef", marginTop: 50 }}>
                  <Link to={`/developer/skills/${this.props.mailbot.id}`}>
                    Admin <span className="glyphicon glyphicon-cog" />
                  </Link>
                </li>
                <li style={{ borderBottom: "1px solid #efefef" }}>
                  <Link to={`/sandbox/${this.props.mailbot.id}`}>
                    Sandbox <span className="glyphicon glyphicon-wrench" />
                  </Link>
                </li>
              </React.Fragment>
            )}
          </NavLeft>
        </div>
        <div className="col-sm-10">
          {this.getUpgradeDialog()}
          <div className="main-panel" style={{ marginBottom: 100 }}>
            <Switch>
              <Route
                path="/skills/:mailbotid/settings"
                render={() => (
                  <MailBotDetailsSettings
                    mailbotId={
                      isCoreSkill(this.props.mailbot)
                        ? process.env.REACT_APP_FUT_MAILBOT_ID
                        : this.props.mailbot.id
                    }
                    mailbotFlag={this.props.mailbot.subdomain}
                    loggedInUser={this.props.loggedInUser}
                    setSettingsSubmenu={this.setSettingsSubmenu}
                    reloadUser={this.props.reloadUser}
                  />
                )}
              />
              <Route
                path="/skills/:mailbotid"
                render={() => (
                  <div>
                    <h3 className="media-heading">{this.props.mailbot.name}</h3>
                    <p>By: {this.props.mailbot.developer_name}</p>
                    <ReactMarkdown
                      source={this.props.mailbot.description}
                      className="markdown-container"
                    />
                  </div>
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default MailBotDetails;
