import React, { useEffect, useState } from "react";
import { message as antMessage } from "antd";
import icon from "./images/googleButtonIcon.png";
import { logger } from "./lib/Logger";

function loadGoogleLibDynamically() {
  return new Promise(resolve => {
    const scriptId = "googleAuth";
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/platform.js";
      script.id = scriptId;
      document.body.appendChild(script);

      script.onload = () => resolve();
    } else {
      resolve();
    }
  });
}

/* global gapi */

const GoogleLoginButton = props => {
  const [oauth2, setOauth2] = useState();

  useEffect(() => {
    const init = async () => {
      // wait for google apis script to load
      await loadGoogleLibDynamically();

      gapi.load("auth2", function () {
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        const auth2 = gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
          cookiepolicy: "single_host_origin",
          redirect_uri: `${process.env.REACT_APP_MAILBOTS_WEB_APP_BASE}/register`,
          // setting plugin_name enables legacy Google lib https://github.com/mailbots/fut-admin-ui/issues/199
          plugin_name: "fut-web-app"
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });
        setOauth2(auth2);
      });
    };

    try {
      init();
    } catch (error) {
      console.error("failed to init google auth button", error);
    }
  }, []);

  const login = async () => {
    try {
      if (!oauth2) {
        throw new Error("oauth2 library not initialized");
      }

      const { code } = await oauth2.grantOfflineAccess();
      props.onLogin(code);
    } catch (error) {
      console.error(error.message);
      antMessage.error(`Google login failed (${error.message})`);
      logger.log("failed to log in user via Google", { level: "error", error });
    }
  };

  return (
    <button
      id="googleLoginBtn"
      className="btn btn-outlined btn-block"
      disabled={props.disabled || false}
      onClick={login}
      style={{ position: "relative" }}
    >
      <i style={{ position: "absolute", left: 5, top: 5 }}>
        <img src={icon} alt="logo" style={{ width: 30 }} />
      </i>
      Sign in with Google
    </button>
  );
};
export default GoogleLoginButton;
