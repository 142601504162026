import React, { Component } from "react";
import { mailbotsAdminBrowser } from "./lib/utils";
import Loading from "./Loading";
import Card from "./Card";
import { Link } from "react-router-dom";
import Logs from "./Logs";

class MailBotUsageLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userUsage: null
    };
  }

  render = () => {
    return (
      <div>
        <Card style={{ padding: 5 }}>
          <p className="text-muted text-right">
            Showing abbreviated logs across all users. For development, use the{" "}
            <Link
              style={{ color: "#b4bcc2", textDecoration: "underline" }}
              to={`/sandbox/${this.props.mailbotid}`}
            >
              Sandbox
            </Link>
          </p>
          <Logs mailbotid={this.props.mailbotid} role="owner" />
        </Card>
      </div>
    );
  };
}

export default MailBotUsageLogs;
