import React, { useEffect, useState } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import _ from "lodash";
import futLogo from "./images/futLogo.svg";
import futIcon from "./images/futIcon.svg";
import { UpgradeLink } from "./Billing/UpgradeDialogs";
import {
  getStoreCredit,
  isEmployee,
  isOnFreePlan,
  isOnLegacyPlan,
  isTeamOwner,
  planKeyExists,
  referralCreditWaitingForFreeUpgrade,
  userPaidForSkill
} from "./Billing/billingHelpers";
import * as queryString from "query-string";
import { AffiliateDialog } from "./AffiliateDialog";
import { useHistory } from "react-router-dom";
import {
  BellOutlined,
  ClearOutlined,
  CodeOutlined,
  DollarOutlined,
  EllipsisOutlined,
  HeartOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { ButtonOnBg } from "./ButtonOnBg";
import { isOnMobile } from "./lib/hookisMobile";

function Nav(props) {
  const [affiliateVisible, setAffiliateVisible] = useState(false);
  const history = useHistory();

  const AFFILIATE_ENABLED = false;

  useEffect(() => {
    const { showAffiliate } = queryString.parse(window.location.search);
    if (showAffiliate) {
      setAffiliateVisible(true);
      history.push(window.location.pathname);
    }
  });

  return (
    <div
      className="navbar-container"
      style={{
        height: 32,
        margin: 16,
        zIndex: 3,
        display: "flex",
        alignItems: "center"
      }}
    >
      {/* Left side logo */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <a href={process.env.REACT_APP_MAILBOTS_WEB_APP_BASE}>
          {isOnMobile() ? (
            <img src={futIcon} alt="FollowUpThen Logo" width={25} />
          ) : (
            <img src={futLogo} alt="FollowUpThen Logo" width="150px" />
          )}{" "}
        </a>
      </div>
      {/* Right Side Menu */}
      {props.loggedInUser && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 8
          }}
        >
          {process.env.REACT_APP_BILLING_AVAILABLE === "yes" &&
          (isOnFreePlan(props.loggedInUser) ||
            isOnLegacyPlan(props.loggedInUser)) &&
          !isEmployee(props.loggedInUser) &&
          planKeyExists("v3_base") &&
          planKeyExists("personalAst") ? (
            <UpgradeLink
              text={
                referralCreditWaitingForFreeUpgrade(props.loggedInUser)
                  ? `🚀 Upgrade (+$${getStoreCredit(props.loggedInUser)})`
                  : "🚀 Upgrade"
              }
              loggedInUser={props.loggedInUser}
            />
          ) : null}
          {false /*  Emergency Status Notification. Set to 'true' and edit below. */ ? (
            <span style={{ padding: 4 }}>
              ⚠️ System Impaired.{" "}
              <a
                target="_blank"
                rel="nofollower noopener noreferrer"
                href="https://twitter.com/followupthen/status/1468251281026269184"
              >
                {" "}
                Details
              </a>
            </span>
          ) : null}
          <div
            style={{
              position: "absolute",
              right: 12,
              display: "flex",
              alignItems: "center"
            }}
          >
            <ButtonOnBg
              onClick={() => {
                history.push("/tasks");
              }}
              icon="BellOutlined"
              text="Followups"
            />
            {userPaidForSkill(props.loggedInUser, "p") &&
            process.env.REACT_APP_SHOW_PEOPLE === "yes" ? (
              <ButtonOnBg
                onClick={() => {
                  history.push("/people");
                }}
                icon="UserOutlined"
                text="People"
              />
            ) : null}
            <ButtonOnBg
              onClick={() => {
                history.push("/skills");
              }}
              icon="BulbOutlined"
              text="Skills"
            />
            <a
              href=""
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              {" "}
              <ButtonOnBg style={{ fontSize: 22 }} icon="EllipsisOutlined" />
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              {process.env.REACT_APP_BILLING_AVAILABLE === "yes" &&
              !isTeamOwner(props.loggedInUser) &&
              "budget_remaining_cent" in props.loggedInUser.billing
                ? [
                    <li key="budget">
                      <NavLink to="/skills">
                        Available Skill Budget:
                        <span style={{ color: "green" }}>
                          {" "}
                          $
                          {props.loggedInUser.billing.budget_remaining_cent /
                            100}
                        </span>
                      </NavLink>
                    </li>,
                    <li key="budget-divider" className="divider" />
                  ]
                : null}
              {props.loggedInUser.is_validated ? (
                <>
                  <li key="settings">
                    <NavLink to="/settings" exact={true}>
                      <SettingOutlined /> Settings
                    </NavLink>
                  </li>
                  <li key="notifications">
                    <NavLink to="/settings/notifications">
                      <BellOutlined /> Notifications
                    </NavLink>
                  </li>
                  <li key="customize">
                    <NavLink to="/settings/customize">
                      <ClearOutlined /> Customize
                    </NavLink>
                  </li>
                  <li key="billing">
                    <NavLink to="/billing">
                      <DollarOutlined /> Billing
                    </NavLink>
                  </li>
                  {isTeamOwner(props.loggedInUser) ? (
                    <li key="team">
                      <NavLink to="/team">
                        <TeamOutlined /> Team
                      </NavLink>
                    </li>
                  ) : null}
                  {/* <li key="billing">
                      <NavLink to="/billing">Billing</NavLink>
                    </li> */}
                </>
              ) : null}
              {process.env.REACT_APP_SHOW_SKILL_DEV === "yes" ? (
                <li>
                  <Link to="/developer/skills">
                    <CodeOutlined /> Build Skill
                  </Link>
                </li>
              ) : null}
              {!isEmployee(props.loggedInUser) && AFFILIATE_ENABLED
                ? [
                    <li key="refer">
                      <a onClick={() => setAffiliateVisible(true)}>
                        <HeartOutlined /> Affiliates
                      </a>
                    </li>
                  ]
                : null}
              <li key="help">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://help.followupthen.com"
                >
                  <QuestionCircleOutlined /> Help
                  <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="divider" />
              <li>
                <a id="logout" onClick={props.handleLogout}>
                  <LogoutOutlined /> Log out{" "}
                  {_.get(props.loggedInUser, "email")
                    ? "(" + _.get(props.loggedInUser, "email") + ")"
                    : ""}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      <AffiliateDialog
        visible={affiliateVisible}
        onClose={() => setAffiliateVisible(false)}
      />
    </div>
  );
}

export default withRouter(Nav);
