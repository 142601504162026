import { userDate } from "./utils";
import _ from "lodash";

/**
 * Transform person tag options for autocomplete widget
 * @param {Array} allPeopleAttributes
 */
export const getPersonTagOptions = allPeopleAttributes => {
  try {
    if (!allPeopleAttributes) return [];
    const tagOption = allPeopleAttributes.find(
      attr => attr.attribute === "tags"
    );
    if (!tagOption || !tagOption.value || !tagOption.value.length) return [];
    const tags = tagOption.value.reduce(
      (acc, currTag) => acc.concat({ label: currTag, value: currTag }),
      []
    );
    return tags;
  } catch (e) {
    console.error(e);
    console.error("Error getting tag options");
    return [];
  }
};

/**
 * Private function to normalize person attribute value
 */
const getNormalizedAttrValue = (
  attr,
  momentDateFormat = "ddd, D MMMM YYYY h:mma",
  timezone = "GMT"
) => {
  switch (attr.type) {
    case "global_multiselect":
      return attr.value && attr.value.join(", ");
    case "checkbox":
      return attr.value.toString();
    case "date":
      if (!attr.value) return "(Not Scheduled)";
      return userDate({
        timestamp: attr.value,
        momentDateFormat,
        timezone
      });
    default:
      // input, textarea, etc
      return attr.value;
  }
};

/**
 * Get normalized value of a person attribute
 */
export const getPersonAttribute = ({
  key,
  personAttributes,
  momentDateFormat = "ddd, D MMMM YYYY h:mma",
  timezone = "GMT"
}) => {
  if (!personAttributes) return "";
  const attributeObj = personAttributes.find(a => a.attribute === key);
  if (attributeObj && attributeObj.value) {
    return getNormalizedAttrValue(attributeObj, momentDateFormat, timezone);
  } else {
    return "";
  }
};

/**
 * Strip surrounding quotes from a name string.
 */
export const stripQuotes = name => {
  if (
    typeof name === "string" &&
    name.length > 2 &&
    name[0] === '"' &&
    name[name.length - 1] === '"'
  ) {
    return name.slice(1, name.length - 1);
  }

  return name;
};

/**
 * Shorthand method for getting full_name attribute.
 * If the value is null it will return the person's email.
 */
export const getPersonName = person => {
  if (!person) return "";
  let name = getPersonAttribute({
    key: "full_name",
    personAttributes: person.attributes
  });

  // for some people, the name attribute can
  // be wrapped in quotes so we need to strip them
  // https://github.com/mailbots/fut-core-api/issues/2636
  name = stripQuotes(name);

  const email = getPersonEmail(person);
  return name || email;
};

/**
 * Get the first email of a person from the emails array.
 */
export const getPersonEmail = person => {
  const emails = getAllPersonEmails(person);
  return Array.isArray(emails) ? emails[0] : "(no email)";
};

/**
 * Get a person's emails attribute.
 * Returns empty array if the attribute is null.
 */
export const getAllPersonEmails = person => {
  if (!person) return [];
  const emails = getPersonAttribute({
    key: "emails",
    personAttributes: person.attributes
  });
  if (Array.isArray(emails)) return emails;
  return [];
};

/**
 * Get normalized value of a person attribute
 */
export const setPersonAttribute = (person, attribute, value) => {
  if (!person.attributes) {
    console.warn("No attributes yet on person");
    return;
  }

  // update things
  const targetAttrObj = person.attributes.find(a => a.attribute === attribute);
  targetAttrObj.value = value;
  // slice out old one
  let newAttrs = person.attributes.filter(a => a.attribute !== attribute);
  // add the new one
  newAttrs.push(targetAttrObj);
  // return the new person
  person.attributes = newAttrs;
  return person;
};

/**
 * Nondestructively initialze person attributes
 * @param {*} person
 */
export const initPersonAttributes = person => {
  const newPerson = _.cloneDeep(person);
  const initialAttributes = [
    {
      attribute: "full_name",
      title: "Name",
      type: "text",
      value: "",
      display_order: 1
    },
    {
      attribute: "emails",
      title: "Emails",
      type: "list",
      value: [],
      display_order: 2
    },
    {
      attribute: "organization",
      title: "Organization",
      type: "text",
      value: "",
      display_order: 3
    },
    {
      attribute: "tags",
      title: "Tags",
      type: "global_multiselect",
      value: [],
      display_order: 4
    },
    {
      attribute: "notes",
      title: "Notes",
      type: "text",
      value: "",
      display_order: 4
    }
  ];

  // no attributes, add defaults
  if (!Array.isArray(newPerson.attributes) || !newPerson.attributes.length) {
    newPerson.attrinbutes = initialAttributes;
    return person;
  }

  // add only missing attributes
  initialAttributes.forEach(initialAttribute => {
    const alreadyHasAttr = newPerson.attributes.find(
      a => a.attribute === initialAttribute.attribute
    );
    if (!alreadyHasAttr) newPerson.attributes.push(initialAttribute);
  });
  return newPerson;
};
