import React, { useEffect, useState, useRef } from "react";
import Card from "../Card";
import {
  checkPeopleBillingLimits,
  confirmBillingAmount,
  employeeHasBudgetForSkill,
  getDedicatedPlansForSkill,
  getPriceFromPlanKey,
  getRemainingBudgetCent,
  getTeamAdmin,
  getUserPlanNames,
  hasEmployees,
  hasPackagePlan,
  isEmployee,
  isOnBasicPlan,
  isOnFreePlan,
  isOnLegacyPlan,
  isOnPersonalAstPlan,
  isTeamOwner,
  subscribeToAddonPlan,
  userHasStripePlanId,
  userIsBillingAnnually
} from "./billingHelpers";
import { ChoosePlanDialog } from "./ChoosePlanDialog";
import { Link, useHistory } from "react-router-dom";
import { planKeyExists } from "./billingHelpers";
import { ExpandableStateful } from "../Expandable";
import queryString from "query-string";
import { GlobalContext } from "../App";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import EventListener from "react-event-listener";
import { Popover } from "antd";
import { BlueCallout, GreyCallout, PopoverStyled } from "../Layout";
import { WelcomeToV3Tour } from "../ModalTours/TourWelcomeToV3";
import { logger } from "../lib/Logger";
import nprogress from "nprogress";
import { message as antMessage } from "antd";

// PLAN:
// 1. Categorize (dialogs, helpers, factory) - DONE
// 2. Add plan limits to user object in app.js - DONE
// 3. Add Main Upgrade Dialog - Team Owner
// 4. Add Main Upgrade Dialog - Employee Variant
// 5. billingPlanHas({skills:["people"], active_futs: 322, active_people: 334); (single source of feature switching - LIMITS)
// 6. Dialogs:
//    - Ugprade for more FUTs
//    - Upgrade for more People
//    - Upgrade to Personal Assistant / Professional Package
//    - General Upgrade Options
//    - Within Skill - Just show a generic "not included in your current plan: Upgrade!"
// 7. Simple <UpgradeDialogFactory limits={['people]} activeFuts={332} people={334} loggedInUser={this.props.LoggedInuser} />
// 7a. (Alt) Simple
//        - <UpgradeForPeople people={334} loggedInUser={this.props.LoggedInuser} />
//        - <MoreFutsSmall activeFuts={322} loggedInUser={this.props.LoggedInuser} />
//        - <UpgradeForThisSkill skillFlag="r" loggedInUser={this.props.LoggedInuser} />

// NOTE: Allow webhook response to delete attachments (if not paid)

/**
 *
 *  Abstractions
 *
 */
const UpgradeCard = ({ children, style = {} }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => setWindowWidth(window.innerWidth);
  return (
    <Card
      type="large"
      className="info-dialog"
      innerStyle={{
        display: windowWidth > 768 ? "flex" : "block",
        flexDirection: "row", // @todo: Make responsive...
        alignItems: "center",
        ...style
      }}
    >
      <EventListener target="window" onResize={handleResize} />
      {children}
    </Card>
  );
};

/**
 *
 *  Skill Dialog Cards that show above skill content
 *
 */
const BillingComingSoon = ({ priceOnAnnualPlan }) => {
  const START_BILLING_DATE = "August 1st";
  if (process.env.REACT_APP_BILLING_ENFORCED === "yes") return null;
  return (
    <BlueCallout style={{ margin: 0, width: "90%" }}>
      Billing limits are not yet in effect.{" "}
      {priceOnAnnualPlan
        ? `This skill costs $${priceOnAnnualPlan}/mo (billed annually).`
        : ""}{" "}
      <Popover
        content={
          <p style={{ margin: 0, maxWidth: 300 }}>
            Your legacy plan may not include this skill. Verify your plan limits{" "}
            <Link to="/billing">here</Link> or read more about our{" "}
            <a
              href="https://help.followupthen.com/2020/12/30/new-pricing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              new pricing
            </a>
            .
          </p>
        }
      >
        <span style={{ color: "#11A2d2", cursor: "pointer" }}>Read more</span>
      </Popover>
    </BlueCallout>
  );
};

export const SkillTopYouHaveSkill = ({ skillFlag }) => (
  <UpgradeCard>
    <div style={{ flex: 3 }}>
      <h2>Skill Active ✅</h2>
      <p>This skill is active on your account.</p>
      <BillingComingSoon
        priceOnAnnualPlan={getPriceFromPlanKey(skillFlag, true)}
      />
    </div>
  </UpgradeCard>
);

/**
 * An addon plan can have multiple subscription levels. When a plan's plan_key exactly
 * corresponds with the flag (ex: "r") and there are exactly two plans like this for
 * the skill, it is assumed this is a monthly / yearly variatnt of a single plan.
 * When there are more than two single-flag plans however, it is assumed there are multiple
 * subscription tiers available to that plan. Response Detection is a good example of this, where
 * different plans allow more connected inboxes.
 */
export const SkillTopYouHaveSkillMorePlans = ({ skillFlag }) => {
  const { loggedInUser } = useContext(GlobalContext);

  // find which plan user currently has selected
  const getSelectedPlan = () => {
    const allPlansForSkill = getDedicatedPlansForSkill(loggedInUser, skillFlag);
    const selectedPlans = allPlansForSkill.filter(plan =>
      userHasStripePlanId(loggedInUser, plan.stripe_id)
    );
    if (selectedPlans.length > 1) {
      // automatically downgrade plan
      logger.log("billing: duplicate plans found for same key", {
        level: "error",
        data: {
          plans_obj: selectedPlans
        }
      });
    } else if (selectedPlans.length === 0) {
      return null;
    } else if (selectedPlans.length === 1) {
      return selectedPlans[0];
    }
  };

  // get only planId of selected plan, or "default"
  const getSelectedPlanId = () =>
    getSelectedPlan() ? getSelectedPlan().stripe_id : "default";

  const getSelectedPlanName = () =>
    getSelectedPlan() ? getSelectedPlan().name : "";

  const [selectedPlanId, setSelectedPlanId] = useState(getSelectedPlanId());

  const handleSubscriptionPlanChange = async e => {
    const oldStripePlanId = selectedPlanId;
    const stripePlanId = e.target.value;
    setSelectedPlanId(stripePlanId);
    try {
      logger.log("billing: user selected higher skill plan");
      nprogress.start();
      if (!confirmBillingAmount({ stripePlanId })) {
        logger.log("billing: user cancelled upgrade in confirm dialog");
        nprogress.done();
        return;
      }
      await subscribeToAddonPlan({
        loggedInUser,
        stripePlanId,
        planKey: skillFlag
      });
      setSelectedPlanId(stripePlanId);
      antMessage.success("Plan successfully changed");
      window.setTimeout(() => window.location.reload(), 1500); // wait for dialog
    } catch (e) {
      setSelectedPlanId(oldStripePlanId);
      console.error(e);
      logger.log("billing: error selected higher skill plan", {
        level: "error",
        error_str: e.message
      });
      antMessage.error(
        "Error changing plan. Please contact help@humans.fut.io for help"
      );
    }
    nprogress.done();
  };

  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <h2>Skill Active ✅</h2>
        <p>
          This skill is part of your <strong>{getSelectedPlanName()}</strong>{" "}
          plan.
        </p>
        <ExpandableStateful more="View / Change Skill Subscription">
          <p>Change subscription level:</p>
          <select
            onChange={handleSubscriptionPlanChange}
            value={selectedPlanId}
            className="form-control"
            style={{ maxWidth: 400 }}
          >
            <option value={"default"}>Select New Plan</option>
            {getDedicatedPlansForSkill(loggedInUser, skillFlag)
              .filter(plan =>
                userIsBillingAnnually(loggedInUser)
                  ? plan.billing_interval === "yearly"
                  : plan.billing_interval === "monthly"
              )
              .filter(plan => !plan.legacy)
              .map((plan, i) => (
                <option key={i} value={plan.stripe_id}>
                  {plan.name} (${plan.monthly_price_cent / 100}/mo)
                </option>
              ))}
          </select>
        </ExpandableStateful>
      </div>
    </UpgradeCard>
  );
};

export const SkillTopInstallSkill = ({
  skillName,
  onActivate,
  isActivating = false,
  skillFlag
}) => (
  <UpgradeCard>
    <div style={{ flex: 3 }}>
      <h2>Activate {skillName}</h2>
      <p>Skill available 🎉 Activate it to get started.</p>
      <BillingComingSoon
        priceOnAnnualPlan={getPriceFromPlanKey(skillFlag, true)}
      />
    </div>
    <div style={{ flex: 1 }}>
      <button onClick={onActivate} className="btn btn-primary">
        {!isActivating ? "Activate" : "Activating..."}
      </button>
    </div>
  </UpgradeCard>
);

export const SkillTopSetupNeeded = ({ skillName, settingsUrl, skillFlag }) => {
  const isOnSkillSettigs = () =>
    window.location.pathname.includes("/settings/");
  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <h2>Set Up {skillName}</h2>
        <p>
          This skill requires additional setup before you can start using it.
        </p>
        <BillingComingSoon
          priceOnAnnualPlan={getPriceFromPlanKey(skillFlag, true)}
        />
      </div>
      <div style={{ flex: 1 }}>
        {isOnSkillSettigs() ? (
          <span
            style={{
              display: "inline-block",
              textAlign: "center",
              backgroundColor: "rgb(245,245,245)",
              padding: 9
            }}
          >
            Complete setting up this skill below
          </span>
        ) : (
          <Link to={settingsUrl} className="btn btn-primary">
            Complete Setup
          </Link>
        )}
      </div>
    </UpgradeCard>
  );
};

export const EmployeeAskForMoreBudget = ({ mailbot }) => {
  const { loggedInUser } = useContext(GlobalContext);
  const loggedInUserName = loggedInUser.name;
  const employeeEmail = loggedInUser.email;
  const teamAdminName = getTeamAdmin(loggedInUser).name;
  const teamAdminEmail = getTeamAdmin(loggedInUser).email;
  const skillPrice = getPriceFromPlanKey(
    mailbot.subdomain,
    userIsBillingAnnually(loggedInUser)
  );
  const employeeBudgetDollars = getRemainingBudgetCent(loggedInUser) / 100;

  const qs = {
    dialogState: "budget_approve",
    editTeamMember: employeeEmail,
    skillPrice,
    skillName: mailbot.name,
    skillId: mailbot.id
  };
  const approvalLink = `${
    process.env.REACT_APP_MAILBOTS_WEB_APP_BASE
  }/team?${queryString.stringify(qs)}`;
  return (
    <UpgradeCard>
      <div style={{ width: "95%" }}>
        <h2>Request Administrator Approval</h2>
        <div>
          This skill costs ${skillPrice}/mo. You have ${employeeBudgetDollars}
          /mo left in your monthly skills budget.
          <br />
          <br />
          <ExpandableStateful
            style={{ width: "95%" }}
            more="Request Budget Increase"
          >
            <hr />
            <p>
              Here is a message you can send to your team administrator{" "}
              {teamAdminName} (
              <a href={`mailto:${teamAdminEmail}?Subject=FollowUpThen Skill`}>
                {teamAdminEmail}
              </a>
              )
            </p>
            <div
              style={{
                backgroundColor: "#fafafa",
                padding: 20,
                margin: 20,
                fontFamily: "monospace",
                whiteSpace: "normal",
                overflow: "auto",
                width: "95%"
              }}
            >
              Hi{teamAdminName ? " " + teamAdminName.split(" ")[0] : ""},
              <br />
              <br />
              The {mailbot.name} FollowUpThen skill would improve my
              productivity. Would you mind approving a budget increase? Here is
              a direct link to edit my FollowUpThen Skills budget:
              <br />
              <br />
              {approvalLink}
              <br />
              <br />
              Thanks!
              <br />
              {loggedInUserName}
            </div>
          </ExpandableStateful>
        </div>
      </div>
    </UpgradeCard>
  );
};

/**
 * @todo - DRY this and <EmployeeAskForMoreBudget /> above
 */
export const RequestAdminPermission = ({
  linkText = "Contact Company Administrator",
  linkStyle
}) => {
  const { loggedInUser } = useContext(GlobalContext);
  const loggedInUserName = loggedInUser.name;
  const employeeEmail = loggedInUser.email;
  const teamAdminName = getTeamAdmin(loggedInUser).name;
  const teamAdminEmail = getTeamAdmin(loggedInUser).email;

  const qs = {
    dialogState: "budget_approve",
    editTeamMember: employeeEmail,
    name: loggedInUserName
  };

  const approvalLink = `${
    process.env.REACT_APP_MAILBOTS_WEB_APP_BASE
  }/billing?${queryString.stringify(qs)}`;

  return (
    <ExpandableStateful
      containerStyle={{ display: "inline" }}
      more={linkText}
      style={linkStyle}
    >
      <hr />
      <p>
        Here is a message you can send to {teamAdminName} (
        <a
          href={`mailto:${teamAdminEmail}?Subject=FollowUpThen Budget Request`}
        >
          {teamAdminEmail}
        </a>
        )
      </p>
      <div
        style={{
          backgroundColor: "#fafafa",
          padding: 20,
          margin: 20,
          fontFamily: "monospace",
          whiteSpace: "normal",
          overflow: "auto",
          width: "95%",
          textAlign: "left"
        }}
      >
        Hi{teamAdminName ? " " + teamAdminName.split(" ")[0] : ""},
        <br />
        <br />
        There are some great premium FollowUpThen features I'd love to try.
        Would you mind approving a budget increase on my account? Below is a
        direct link to approve.
        <br />
        <br />
        {approvalLink}
        <br />
        <br />
        Thanks!
        <br />
        {loggedInUserName}
      </div>
    </ExpandableStateful>
  );
};

// BlueCallout saying how much employee has left. Consolidate with above.
export const EmployeeBudget = loggedInUser =>
  isEmployee(loggedInUser) &&
  !isOnLegacyPlan(loggedInUser) &&
  getRemainingBudgetCent(loggedInUser) < 800 ? (
    <BlueCallout>
      You have ${getRemainingBudgetCent(loggedInUser) / 100} in your skills
      budget. <RequestAdminPermission linkText="Budget Request" />
      <a
        target="_blank"
        rel="nofollower noopener noreferrer"
        href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
        style={{ marginLeft: 12, float: "right" }}
      >
        Read about budgets
      </a>
    </BlueCallout>
  ) : null;

{
  /** Has base plan, needs upgrade */
}
export const SkillTopPayForAddOn = ({
  skillName = "this skill",
  skillPrice,
  billAnnually,
  onActivate,
  skillFlag
}) => {
  const [isActivating, setIsActivating] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { loggedInUser } = useContext(GlobalContext);
  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <strong>
          Activate {skillName} for ${skillPrice}/mo
        </strong>
        <br />
        {!isEmployee(loggedInUser) ? (
          <p>
            {/* {hasPackagePlan(skillFlag) ? (
              <>
                Buy just this skill, or{" "}
                <a onClick={() => setModalVisible(true)}>choose a package</a>.
              </>
            ) : null}{" "} */}
            {billAnnually ? "Billed annually." : null}{" "}
            <PopoverStyled
              content={
                <div style={{ maxWidth: 300 }}>
                  Remove this skill any time to receive an account credit for
                  any unused portion of this subscription addon.{" "}
                  <a
                    className="text-muted"
                    href="https://help.followupthen.com/knowledge-base/pricing-guide/"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Read more
                  </a>
                </div>
              }
            >
              Fair billing policy
            </PopoverStyled>
            .
          </p>
        ) : null}

        {isEmployee(loggedInUser) &&
        employeeHasBudgetForSkill(loggedInUser, skillFlag) ? (
          <div style={{}}>
            Good news! You have ${getRemainingBudgetCent(loggedInUser) / 100}{" "}
            left in your skills budget.
          </div>
        ) : null}
      </div>
      <div style={{ flex: 1 }}>
        <button
          onClick={async () => {
            setIsActivating(true);
            await onActivate();
            setIsActivating(false);
          }}
          className={
            !isActivating ? `btn btn-primary` : `btn btn-primary disabled`
          }
        >
          {!isActivating ? "Activate" : "Activating..."}
        </button>
      </div>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </UpgradeCard>
  );
};

{
  /** On free plan */
}
export const SkillTopAddBasePlan = ({
  skillName = "this skill",
  skillPrice,
  loggedInUser
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <h2>Upgrade to Enable {skillName}</h2>
        <p>
          After upgrading to the FollowUpThen Base plan,
          <br />
          add {skillName} for only <strong>${skillPrice}/mo</strong>
        </p>
      </div>
      <div style={{ flex: 1 }}>
        <button
          onClick={() => setModalVisible(true)}
          className="btn btn-primary"
        >
          Upgrade
        </button>
      </div>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </UpgradeCard>
  );
};

{
  /** Paid user, but not on v3_base plan */
}
export const SkillTopLegacyPlan = ({
  skillName = "this skill",
  skillPrice,
  loggedInUser
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <h2>Enable {skillName}</h2>
        <p>
          It looks like you are on a legacy plan. After selecting one of our new
          plans, you can enable {skillName} for only{" "}
          <strong>${skillPrice}/mo</strong>{" "}
          {isEmployee(loggedInUser) ? (
            <>
              from your{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                rel="noreferrer noopener"
                target="_blank"
              >
                team member budget
              </a>
            </>
          ) : null}
        </p>
      </div>
      <div style={{ flex: 1 }}>
        <button
          onClick={() => setModalVisible(true)}
          className="btn btn-primary"
        >
          Plan Options
        </button>
      </div>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </UpgradeCard>
  );
};

/**
 * Render choose plan modal based the presence of ?choosePlan in the URL
 */
export const ShowChoosePlanDialogIfInQS = () => {
  const { choosePlan } = queryString.parse(window.location.search);
  const [modalVisible, setModalVisible] = useState(!!choosePlan);

  return (
    <>
      {modalVisible ? (
        <ChoosePlanDialog
          isModalVisible={modalVisible}
          setIsModalVisible={setModalVisible}
          onClose={() => setModalVisible(false)}
        />
      ) : null}
    </>
  );
};

// the skill is only available with a package
export const SkillTopAddPackage = ({ headline, text, loggedInUser }) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <UpgradeCard>
      <div style={{ flex: 3 }}>
        <strong>{headline}</strong>
        <br />
        <p>{text}</p>
      </div>
      <div style={{ flex: 1 }}>
        <button
          onClick={() => setModalVisible(true)}
          className="btn btn-primary"
        >
          Upgrade Options
        </button>
      </div>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </UpgradeCard>
  );
};

export const AddBasePlan = ({
  upgradeText = "You are approaching your plan limits",
  loggedInUser
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Card
      innerStyle={{
        margin: "50px 20px",
        width: "95%",
        textAlign: "center",
        maxWidth: 1100,
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        borderRadius: 7
      }}
    >
      <span style={{ marginRight: 10 }}>{upgradeText}</span>
      <a style={{ fontWeight: "bold" }} onClick={() => setModalVisible(true)}>
        Upgrade 🚀
      </a>
      <Link
        className="hide-for-mobile"
        style={{ color: "#aaa", position: "absolute", right: 20 }}
        to="/billing"
      >
        Account Details
      </Link>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </Card>
  );
};
export const UpgradeLink = ({
  loggedInUser,
  text = "Upgrade 🚀",
  style = {}
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <a
        style={style}
        className="hide-for-mobile"
        onClick={() => setModalVisible(true)}
      >
        {text}
      </a>
      <ChoosePlanDialog
        isModalVisible={modalVisible}
        setIsModalVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        loggedInUser={loggedInUser}
      />
    </>
  );
};

export const SkillStatusLoading = () => (
  <UpgradeCard style={{ minHeight: 111 }}>
    <div style={{ width: "95%" }}>
      <h2 style={{ width: 200 }}>
        <Skeleton />
      </h2>
      <div style={{ width: 400 }}>
        <Skeleton count={2} />
      </div>
    </div>
  </UpgradeCard>
);

export const AlmostOutOfFutsBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const alreadyLogged = useRef(false);
  useEffect(() => {
    if (!alreadyLogged.current) {
      logger.log("billing: almost out of futs banner shown");
      alreadyLogged.current = true;
    }
  });
  return (
    <Card
      innerStyle={{
        margin: 20,
        width: "95vw",
        maxWidth: 1100,
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <span style={{ marginRight: 10 }}>
        You are almost out of followup for the month. Upgrade for only{" "}
        <strong>$4/mo</strong>
      </span>

      <a className="btn btn-primary" onClick={() => setIsModalVisible(true)}>
        Start Free Trial
      </a>

      <ChoosePlanDialog
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onClose={() => setIsModalVisible(false)}
      ></ChoosePlanDialog>
    </Card>
  );
};

export const UpgradeForPeople = ({ loggedInUser, style = {} }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companyMigrationModalVisible, setCompanyMigrationModalVisible] =
    useState(false);
  const subscribedToPeople = checkPeopleBillingLimits(loggedInUser);
  const history = useHistory();

  // the People resource was associated with another plan earlier. As of Oct, 2021 it's just another skill.
  const PEOPLE_AS_SKILL = true;

  return (
    <Card type="large" style={{ ...style }}>
      <div className="info-dialog">
        {PEOPLE_AS_SKILL ? (
          <div>
            <h2>Upgrade for People</h2>
            <p>
              Add the People skill to view the people from your active
              follouwps.
            </p>
            <a
              className="btn btn-primary"
              onClick={() => history.push("/skills/p")}
            >
              People Skill
            </a>
          </div>
        ) : (
          <div>
            <h2>Upgrade Your Account</h2>
            <p>
              {isOnLegacyPlan(loggedInUser)
                ? "You are on a legacy billing plan. "
                : null}{" "}
              View and manage the people in your followups and much more!
            </p>
            <a
              className="btn btn-primary"
              onClick={() => {
                if (isTeamOwner(loggedInUser) && hasEmployees(loggedInUser)) {
                  setCompanyMigrationModalVisible(true);
                } else {
                  setIsModalVisible(true);
                }
              }}
            >
              Upgrade Options
            </a>
          </div>
        )}
      </div>
      {/* {companyMigrationModalVisible ? (
        <WelcomeToV3Tour
          switchToV3={() => {}}
          onClose={() => setCompanyMigrationModalVisible(false)}
          loggedInUser={loggedInUser}
          currentStepId="new-pricing"
        />
      ) : null} */}
      <ChoosePlanDialog
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onClose={() => setIsModalVisible(false)}
      ></ChoosePlanDialog>
    </Card>
  );
  // ) : null;
};

export const NewTeamUPgradeCard = () => (
  <Card type="large">
    <p>
      <strong> ⚠️ Move Your Team</strong>. Our new team billing system is
      simpler, more flexible and affordable than before. First{" "}
      <a>select your new plan</a>, then invite your team to visit{" "}
      <em>app.followupthen.com</em> to choose theirs.{" "}
      <a href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/">
        Read more
      </a>
    </p>
  </Card>
);

export const TeamOwnerUpdatePlan = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div>
      <p>
        <strong> We have new and improved team plans</strong>. Our new{" "}
        <a
          rel="noopener noreferrer"
          href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
        >
          budget-based billing system
        </a>{" "}
        for teams is simpler, more flexible and more economical.
      </p>
      <p>Enable the new system for Your team by:</p>
      <ol>
        <li>
          <a onClick={() => setIsModalVisible(true)}>chooing a new plan</a> for
          your account
        </li>
        <li>setting budgets for each team member below</li>
        <li>
          directing your team to <em>https://app.followupthen.com</em> to
          migrate their own individual accounts
        </li>
      </ol>
      <p>
        Feel free to{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.followupthen.com/contact-support/"
        >
          contact us
        </a>{" "}
        if you need help.
      </p>

      <ChoosePlanDialog
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onClose={() => setIsModalVisible(false)}
      ></ChoosePlanDialog>
    </div>
  );
};

// Whatever the person's Base Plan, show them the next one up...
export const UpgradeBasePlanCard = ({
  loggedInUser,
  FooterCallout,
  style = {}
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // if ?upgradeOptions in the URL,
  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    if ("upgradeOptions" in qs) setIsModalVisible(true);
  }, []);

  const BasicPlanFeatures = () => (
    <div>
      <h2>Upgrade FollowUpThen</h2>
      <i className="glyphicon glyphicon-check"></i> unlock more followups
      <br />
      <i className="glyphicon glyphicon-check"></i> bulk actions
      <br />
      <i className="glyphicon glyphicon-check"></i> pay for only the skills you
      need
      <br />
    </div>
  );

  const AstPlanFeatures = () => (
    <div>
      <h2>Professional Package</h2>
      <p>Choose individual skills below, or a package for the best value.</p>
      <br />
    </div>
  );

  const GenericUpgrade = () => (
    <div>
      <h2>Add Skills</h2>
      <p>
        Premium a-la-carte using skills below, or choose a package to purchase
        multiple at a discount.
      </p>
      <br />
    </div>
  );

  const LegacyPlanUpgrade = () => (
    <div>
      <h2>Explore New Billing Plans</h2>
      <p>
        You are on a legacy billing plan (
        {getUserPlanNames(loggedInUser).join(",")}).
      </p>
    </div>
  );

  const getUpgradeMessage = () => {
    if (isOnFreePlan(loggedInUser)) {
      return <BasicPlanFeatures />;
    } else if (isOnLegacyPlan(loggedInUser)) {
      return <LegacyPlanUpgrade />;
    } else if (!isOnPersonalAstPlan(loggedInUser)) {
      return <AstPlanFeatures />;
    } else {
      return <GenericUpgrade />;
    }
  };

  // no upgrades after pesonal assistant or base plan users for now
  if (isOnPersonalAstPlan(loggedInUser) || isOnBasicPlan(loggedInUser))
    return null;

  // hide upgrade window for employees for now
  if (isOnLegacyPlan(loggedInUser) && isEmployee(loggedInUser)) return null;

  // only show dialogs if a v3_base and personalAst keys exist
  if (!planKeyExists("v3_base") || !planKeyExists("personalAst")) return null;
  const ResponsiveUpgradeDialog = styled.div.attrs({
    className: "info-dialog"
  })`
    display: "flex";
    @media (max-width: 768px) {
      display: "flex row";
    }
  `;

  const ResponsiveUpgradeBtn = styled.a.attrs({
    className: "btn btn-primary"
  })`
    @media (max-width: 768px) {
      width: 100%;
    }
  `;
  return (
    <Card type="large" style={{ ...style }}>
      <ResponsiveUpgradeDialog>
        {getUpgradeMessage()}
        <br />
        <div>
          <ResponsiveUpgradeBtn onClick={() => setIsModalVisible(true)}>
            Upgrade Options
          </ResponsiveUpgradeBtn>
          {FooterCallout ? <FooterCallout /> : null}
        </div>
        <ChoosePlanDialog
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          loggedInUser={loggedInUser}
          onClose={() => setIsModalVisible(false)}
        ></ChoosePlanDialog>
      </ResponsiveUpgradeDialog>
      <div></div>
    </Card>
  );
};

export const InviteYourTeam = () => (
  <Card className="settings-card" type="large">
    <h2>Invite Your Team</h2>
    <p>
      If you work in a team, you can consolidate billing under one account and
      manage spending.
      <a
        href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        Learn more
      </a>{" "}
      or invite team members <Link to="team">here</Link>.{" "}
    </p>
  </Card>
);

export const InviteTeamGreyCallout = ({ loggedInUser }) => (
  <GreyCallout>
    <h2>FollowUpThen for Teams 🙌</h2>
    <div>
      Give your team followup superpowers.{" "}
      {isOnFreePlan(loggedInUser) ? (
        <span>
          Once upgraded, add team members from <em>Account &gt; Team</em>
        </span>
      ) : (
        <span>
          Add team members from your <Link to="/team">team page</Link>.
        </span>
      )}
    </div>
  </GreyCallout>
);
