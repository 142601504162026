import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { mailbotsAdminBrowser } from "./lib/utils";
import ReactJson from "react-json-view";
import escapeHtml from "escape-html";
import { message as antMessage } from "antd";
import DocsLink from "./DocsLink";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { PopoverStyled } from "./Layout";

// this will escape all the values from the object or string
const escapeObjectValues = function (obj) {
  if (typeof obj === "number" || typeof obj === "boolean" || !obj) return obj;
  if (typeof obj !== "object" || !obj) return escapeHtml(obj);
  Object.keys(obj).forEach(function (prop) {
    if (typeof obj[prop] === "object") return escapeObjectValues(obj[prop]);
    if (
      typeof obj[prop] === "number" ||
      typeof obj[prop] === "boolean" ||
      !obj[prop]
    )
      return;
    obj[prop] = escapeHtml(obj[prop]);
  });
  return obj;
};

const parseJson = function (json) {
  if (!json) return; // whitespace-only (falsy)

  if (typeof json === "string") {
    try {
      const parsedJson = JSON.parse(json);
      return parsedJson;
    } catch (e) {
      // console.log("json parsing error", e)
      // console.log(json);
      // console.log("\n\n");
      return json;
    }
  }
  return json;
};

const getHeaderText = function (json) {
  json = escapeObjectValues(parseJson(json));
  let res = "";
  for (let prop in json) {
    res += prop + ": " + json[prop] + "\n";
  }

  return res;
};

const getTaskId = logItem => {
  if (logItem.taskId) return logItem.taskId;
  const requestBody = parseJson(logItem.http_request_body);
  if (!requestBody) return null;
  const taskId =
    requestBody.payload &&
    requestBody.payload.task &&
    requestBody.payload.task.id;
  return taskId;
};

const getLogSummary = logItem => {
  const requestBody = parseJson(logItem.http_request_body);
  if (requestBody && logItem.event.includes("mailbot.interbot_event")) {
    const originMailBot = requestBody.source.mailbot;
    const receiptMailBot = requestBody.mailbot;
    const futHook = requestBody.payload && requestBody.payload.fut_hook;
    return (
      <div style={{ display: "flex" }}>
        <div
          title={logItem.event}
          style={{
            flex: "1 1 auto",
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
        >
          {futHook}
        </div>
        <div style={{ flex: "0 0 25px" }}>
          <img
            style={{ width: 15, margin: 3 }}
            title={originMailBot.name}
            src={originMailBot.icon}
          />
          &#x2192;
          <img
            style={{ width: 15, margin: 3 }}
            title={receiptMailBot.name}
            src={receiptMailBot.icon}
          />
        </div>
      </div>
    );
  } else {
    return logItem.event;
  }
};

const getGlyphIcon = function (logLine) {
  switch (logLine.level) {
    case "error":
      return "glyphicon-exclamation-sign icon-red";
    case "warning":
      return "glyphicon glyphicon-warning-sign icon-orange";
    default:
      // warn for any non 2xx response that was not shown as an error
      if (
        typeof logLine.http_response_statuscode === "string" &&
        logLine.http_response_statuscode.slice(0, 1) !== "2"
      ) {
        return "glyphicon glyphicon-warning-sign icon-orange";
      }
      return "glyphicon-ok-circle icon-green";
  }
};

class LogRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      task: null,
      triggerPayload: "",
      triggerAsyncMode: false
    };
  }

  loadTaskFromLogMessage() {
    // render task and messages_sent
    let taskLog = {};
    try {
      if (this.props.data.http_request_body) {
        taskLog = JSON.parse(this.props.data.http_request_body);
      }
      const task = taskLog.task || {};
      if (!task.created) task.created = this.props.data.date; // task not always present, but we need date: TODO: fix abstraction
      const messages = this.props.data.messages_sent || [];
      task.messages = messages; // needs to be within the task to update
      task.relationships = { user: { emails: this.props.loggedInUser.emails } };
      this.props.updateTasks([task]);

      // Update URL with logid for easy referencing
      const qs = queryString.parse(window.location.search);
      qs.logid = this.props.data.id;
      this.props.history.push(
        window.location.pathname + "?" + queryString.stringify(qs)
      );
    } catch (e) {
      console.log("Error rendering log row", e);
      // silent fail for now...
    }
  }

  handleToggleCollapse = () => {
    this.loadTaskFromLogMessage();
    this.setState({ collapsed: !this.state.collapsed });
  };

  componentDidMount() {
    // Open the relevant log message if present in query string
    const qs = queryString.parse(window.location.search);
    if (Number(qs.logid) === Number(this.props.data.id)) {
      this.handleToggleCollapse();
    }
  }

  handleTriggerPayloadChange = event => {
    this.setState({ triggerPayload: event.target.value });
  };

  handleTriggerTask = async () => {
    if (!this.props.updateTasks) {
      console.log(
        "Tasks was triggered without an updateTasks prop passed to Logs"
      );
      return;
    }
    const { data, updateTasks, refreshLogs, updateLogsLoading } = this.props;
    try {
      const { task, triggerPayload } = this.state;
      updateLogsLoading(true);
      const payload = JSON.parse(triggerPayload || "{}");
      let triggerTaskResponse = null;
      if (!task) {
        const res = await mailbotsAdminBrowser.getTask({ id: getTaskId(data) });
        if (!res.task) {
          throw new Error("This task no longer exists");
        }
        this.setState({ task: res.task });
        // we are getting the trigger_url from the response because we dont have task in the state
        triggerTaskResponse = await mailbotsAdminBrowser.triggerTask({
          trigger_url: res.task.trigger_url,
          payload: payload,
          verbose: 1,
          webhook: this.state.triggerAsyncMode ? "async" : "sync"
        });
      } else {
        triggerTaskResponse = await mailbotsAdminBrowser.triggerTask({
          trigger_url: task.trigger_url,
          payload: payload,
          verbose: 1,
          webhook: this.state.triggerAsyncMode ? "async" : "sync"
        });
      }

      // TODO: DRY. This is currently duplciated in Emulator.jsx.
      const showWebhookStatusMessages = responses => {
        responses.map(response => {
          if (!response.webhook && this.state.triggerAsyncMode) {
            antMessage.info(
              "Webhook triggered asynchronously. Response body logged but not processed."
            );
          }
          let status = response.webhook && response.webhook.status;
          const message = response.webhook && response.webhook.message;
          if (!message) return;
          const statusHashMap = {
            success: "success",
            warn: "warn",
            warning: "warn",
            fail: "error",
            failed: "error",
            info: "info"
          };
          const toastMethod = statusHashMap[status] || "info"; // ex: antMessage.warn('message');
          antMessage[toastMethod](String(message));
        });
      };
      showWebhookStatusMessages([triggerTaskResponse]);

      //TODO: Fix core API to have 'messages' go inside "task"
      triggerTaskResponse.task.messages = triggerTaskResponse.messages;
      if (this.props.updateTasks) {
        updateTasks([triggerTaskResponse.task] || []);
      }
      refreshLogs();
    } catch (e) {
      antMessage.error("Error:" + (e.message || e));
      updateLogsLoading(false);
      console.log(e);
    }
  };

  handleTaskDetailsOnClick = async () => {
    const { data } = this.props;
    const res = await mailbotsAdminBrowser.getTask({ id: getTaskId(data) });
    this.setState({ task: res.task });
  };

  showIfEmailsWereSent = () => {
    if (this.props.data.messages_sent && this.props.data.messages_sent.length) {
      return (
        <span
          className="glyphicon glyphicon-send"
          style={{ height: 10, color: "#aaa", margin: 3 }}
        />
      );
    }
    return null;
  };

  // ex: task.created
  getWebhookEventName() {
    const requestBody = parseJson(this.props.data.http_request_body);
    if (requestBody) return requestBody.event;
    return null;
  }

  // This is only for webhook events from external systems (ie, Github issue created)
  getRepeatWebhookPayload() {
    const requestBody = parseJson(this.props.data.http_request_body);
    if (!requestBody)
      return antMessage.error("There was a problem repeating this event.");
    let data = {};
    data.type = requestBody.payload.type;
    data.payload = requestBody.payload.body_json || {};
    data.event_url = requestBody.mailbot.event_url;
    this.props.broadcastEvent(data);
  }

  render() {
    const { data } = this.props;

    //Show full event if it's an erro and there are no details
    if (data.level === "error" && !data.details) {
      data.details = data.event;
    }

    const { collapsed, triggerPayload, task } = this.state;
    const getLogDate = gmtDate => moment.utc(gmtDate).fromNow();

    // TODO: Copy JSON path for each element so it can easily be pasted into
    // code: ex: mailbots.get("task.stored_data.mailbot.some_data");
    // https://github.com/mac-s-g/react-json-view/issues/167#issuecomment-406380281
    // When this works, pass the below handler instead of "true"
    // function enableClipboardHandler(e) {
    //   // Wishful thinking...event does not yet provide an easy way to get json path
    //   const jsonPath = e.namespace.join(".").substring(1);
    //   copyToClipboard(jsonPath);
    // }

    // function copyToClipboard(text) {
    //   var tmpDomNode = document.createElement("input");
    //   document.body.appendChild(tmpDomNode);
    //   tmpDomNode.setAttribute("value", text);

    //   tmpDomNode.select();
    //   document.execCommand("copy");
    //   document.body.removeChild(tmpDomNode);
    // }

    const displayJson = json => {
      if (!json) return null;

      let request_body = parseJson(json);
      return typeof request_body === "object" ? (
        <ReactJson
          collapsed={1}
          displayDataTypes={false}
          displayObjectSize={false}
          name={null}
          src={request_body}
          enableClipboard={true}
        />
      ) : (
        <div>
          <p>
            <span className="glyphicon glyphicon-warning-sign icon-orange" />
            &nbsp; Not valid JSON
          </p>
          <pre>{request_body}</pre>
        </div>
      );
    };

    return [
      <tr
        key={`${data.id}a`}
        className="log-line fade-in-out"
        id={`taskLog${data.id}`}
        onClick={this.handleToggleCollapse}
      >
        <td>
          <span className={`glyphicon ${getGlyphIcon(data)}`} /> &nbsp;
          {data.http_response_statuscode}
          {this.showIfEmailsWereSent()}
        </td>
        <td className="log-summary" colSpan={3}>
          {getLogSummary(data)}
        </td>
        <td className="task-id">
          {getTaskId(data) ? (
            <span>
              {getTaskId(data)}{" "}
              <a
                id={`triggerAction${data.id}`}
                onClick={this.handleTriggerTask}
                style={{ textDecoration: "none" }}
                title="Trigger task becoming due"
              >
                <span className="glyphicon glyphicon-flash" />
              </a>
            </span>
          ) : (
            ""
          )}{" "}
        </td>
        <td className="user-id">{data.userId || ""}</td>
        <td className="log-column">{data.mailbot || ""}</td>
        <td
          className="log-date log-column"
          data-date={`${encodeURIComponent(data.date)}`}
        >
          {getLogDate(data.date)}
        </td>
      </tr>,
      <tr
        key={`${data.id}b`}
        className={`log-details ${collapsed ? "hide" : ""}`}
        id={`taskLogDetails${data.id}`}
      >
        <td colSpan={7}>
          <h2>{getTaskId(data) ? `Taskid: ${getTaskId(data)}` : ""}</h2>
          <div className="row">
            {/* Only show task action buttons if updataeTasks method is passesd. Else, assume only informational */}
            {getTaskId(data) && this.props.updateTasks ? (
              <ul className="nav nav-pills">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 50
                  }}
                >
                  <a
                    id={`triggerAction${data.id}`}
                    onClick={this.handleTriggerTask}
                    style={{ textDecoration: "none" }}
                    title="Trigger task becoming due (does not change actual due date)"
                  >
                    <span className="glyphicon glyphicon-time" /> Trigger
                  </a>
                  <span>
                    Async:{" "}
                    <input
                      type="checkbox"
                      value={this.state.triggerAsyncMode}
                      onChange={e => {
                        this.setState({ triggerAsyncMode: e.target.checked });
                      }}
                    />
                  </span>
                  <PopoverStyled
                    content={
                      <>
                        For long-running trigger handlers (Ex: Interacting with
                        slow systems, waiting for APIs, etc). The Core API will
                        not wait for the webhook response, but logs it in the
                        sandbox for reference. The MailBot must make use of the
                        <pre>POST /webhook-response </pre> after it is done with
                        the handler. The Core API re-posts the webhook a few
                        times if it has not received a /webhook-response POST to
                        an async webhook.
                      </>
                    }
                  >
                    {" "}
                    ?
                  </PopoverStyled>
                </li>
                {getTaskId(data) ? (
                  <React.Fragment>
                    <li>
                      <a
                        onClick={e => e.stopPropagation()}
                        style={{ textDecoration: "none" }}
                        href={`/tasks/${getTaskId(data)}`}
                      >
                        <span className="glyphicon glyphicon-arrow-right" />{" "}
                        User View
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={e => {
                          e.stopPropagation();
                          this.handleTaskDetailsOnClick();
                        }}
                        data-toggle="collapse"
                        style={{ textDecoration: "none" }}
                        href={`#task-details${data.id}`}
                      >
                        <span className="glyphicon glyphicon-list" /> Task
                        Details
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </ul>
            ) : (
              ""
            )}
            {this.getWebhookEventName() === "mailbot.event_received" && (
              <div>
                <h2>External Webhook</h2>
                <ul className="nav nav-pills">
                  <li>
                    <a
                      onClick={() =>
                        this.props.broadcastEvent(
                          this.getRepeatWebhookPayload()
                        )
                      }
                      style={{ textDecoration: "none" }}
                      title="Trigger task becoming due (does not change actual due date)"
                    >
                      <span className="glyphicon glyphicon-refresh" /> Repeat
                      Webhook
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="log-details-contents">
            {data.details && (
              <div className="alert alert-dismissible alert-warning" id="error">
                <button type="button" className="close" data-dismiss="alert">
                  &times;
                </button>
                <strong>{data.details}</strong>
              </div>
            )}

            <div className="alert alert-warning hidden">
              Your JSON response contained values that were not recognized by
              MailBots. See documentation.
            </div>

            {/* {'<!-- Task Details -->'} */}
            {Boolean(getTaskId(data)) && (
              <div>
                <div
                  id={`task-details${data.id}`}
                  className="panel-collapse collapse"
                >
                  <div>
                    <h3>Task Details</h3>
                    {task ? (
                      displayJson(task)
                    ) : (
                      <div
                        id={`loader${data.id}`}
                        className="loader loader-dark-gray"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* "<!-- Request -->" */}

            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a data-toggle="collapse" href={`#request${data.id}`}>
                    Request&nbsp;
                  </a>
                </h4>
              </div>
              <div id={`request${data.id}`} className="panel-collapse collapse">
                <div className="panel-body">
                  BODY:&nbsp;
                  <DocsLink
                    linkText="webhook request docs"
                    href="https://fut.readme.io/v1.0/reference#webhook-request-reference"
                  />
                  {displayJson(data.http_request_body)}
                  HEADERS:
                  <pre>
                    {data.http_request_method} {data.http_request_url}
                    <br />
                    {getHeaderText(data.http_request_headers)}
                  </pre>
                </div>
              </div>
            </div>

            {/* {'<!-- Response -->'} */}

            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title">
                  <a data-toggle="collapse" href={`#response${data.id}`}>
                    Response&nbsp;
                  </a>
                </h4>
              </div>
              <div
                id={`response${data.id}`}
                className="panel-collapse collapse"
              >
                <div className="panel-body">
                  BODY:&nbsp;
                  <DocsLink
                    linkText="webhook response docs"
                    href="https://fut.readme.io/v1.0/reference#webhook-response"
                  />
                  {displayJson(data.http_response_body) === null ? (
                    <pre>
                      ⚠️ Your MailBot did not respond. Did you call
                      bot.webhook.respond()?
                    </pre>
                  ) : (
                    displayJson(data.http_response_body)
                  )}
                  HEADERS:
                  <pre>
                    {`Status Code: ${data.http_response_statuscode}

${getHeaderText(data.http_response_headers)}`}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    ];
  }
}

LogRow.propTypes = {
  data: PropTypes.object,
  loggedInUser: PropTypes.object
};

export default withRouter(LogRow);
