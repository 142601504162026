import React from "react";
import Email from "./Email";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "./Card";
import { userDate, mailbotsAdminBrowser } from "./lib/utils";
import { PopoverStyled } from "./Layout";

/**
 * Renders all the email messages for an array of Tasks. Each
 * task can have zero or multiple email messages.
 *
 * Each email message is rendered using the Email component,
 * which handles the logic and UI of mailto action links. To
 * receive the API response from the Mailto actions set to
 * MailBots, use the callback functions listed.
 *
 * This receives an onMailtoSendSuccess callback since within:
 * Emais > Email > Emulator there is an API call made to MailBots
 * which simulates the mailto link being sent. This callback
 * function is passed some version of the api response.
 *
 * @param {Array} tasks Array of MailBots Task objects
 */
const style = {
  triggerPreText: {
    textAlign: "center",
    fontSize: 11,
    color: "#aaa",
    marginRight: 15
  }
};

const Emails = ({
  tasks,
  onMailtoSendSuccess,
  onMailtoSendError,
  loggedInUser,
  onMailtoPreSend,
  displayFields,
  handleOnMessageRemove
}) => {
  return (
    !isEmpty(tasks) && (
      <div style={{ marginRight: "auto", marginLeft: "auto" }}>
        {tasks.map((task, index) => (
          <div key={task.id + task.created}>
            {tasks.length > 1 && (
              <h4>
                <Link to={`/tasks/${task.id}`}>
                  View Task {task.id}{" "}
                  <span className="glyphicon glyphicon-menu-right" />{" "}
                </Link>
              </h4>
            )}

            {!task.messages || !task.messages.length ? (
              <Card innerStyle={{ marginTop: 20 }}>
                <h5 style={{ marginTop: 10 }}>
                  <span className="glyphicon glyphicon-ok-circle" /> No emails
                  would be sent.{" "}
                  <PopoverStyled
                    content={
                      <div>
                        <p>
                          The sandbox honors the settings in your FollowUpThen
                          account. If your{" "}
                          <Link to="/settings/notifications">
                            scheduling confirmations
                          </Link>{" "}
                          are turned off, emails will not show up here when new
                          FUTs are created.
                        </p>
                        <p>
                          If that's not the problem, check your application, and
                          webhook request / response logs.
                        </p>
                      </div>
                    }
                  >
                    Expecting an email?
                  </PopoverStyled>{" "}
                  <br />
                </h5>
              </Card>
            ) : null}
            {displayFields && displayFields.includes("scheduled_for") && (
              <p style={style.triggerPreText}>
                Scheduled for{" "}
                <strong>
                  {task.trigger_time
                    ? userDate({
                        timestamp: task.trigger_time,
                        timezone: loggedInUser.timezone
                      })
                    : "(Not Scheduled)"}
                </strong>
              </p>
            )}
            {task.messages &&
              task.messages.map((message, index) => (
                /**
                 * Both task and message is required since the Emulator performs API calls against the task.
                 */
                <Email
                  key={index}
                  task={task}
                  message={message}
                  onMailtoSendSuccess={onMailtoSendSuccess}
                  onMailtoSendError={onMailtoSendError}
                  onMailtoPreSend={onMailtoPreSend}
                  loggedInUser={loggedInUser}
                  handleOnMessageRemove={handleOnMessageRemove}
                />
              ))}
          </div>
        ))}
      </div>
    )
  );
};

export default Emails;

Emails.propTypes = {
  onMailtoSendSuccess: PropTypes.func
  // tasks: PropTypes.Array | undefined
};
