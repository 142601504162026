import React from "react";
import FilterLink from "../FilterLink";

export default props => {
  return (
    <React.Fragment>
      <li className="subnav-header">Sort Order</li>

      <li>
        <FilterLink
          id="asc"
          filterObj={{
            order_dir: "asc"
          }}
          onFilter={props.applyFilter}
        >
          Ascending
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="desc"
          filterObj={{
            order_dir: "desc"
          }}
          onFilter={props.applyFilter}
        >
          Descending
        </FilterLink>
      </li>
    </React.Fragment>
  );
};
