import React from "react";

import { getPersonTagOptions } from "../lib/peopleUtils";
import { MultiSelectInput } from "../MultiSelectInput";

const EditPersonForm = props => {
  const tags = Array.isArray(props.value.tags)
    ? props.value.tags
    : (props.value.tags || "").split(",").filter(tag => !!tag);
  const personInfo = {
    name: props.value.name,
    tags,
    emails: props.value.emails,
    organization: props.value.organization,
    notes: props.value.notes
  };

  const copyToClipboard = (e, value) => {
    e.persist();
    navigator.clipboard.writeText(value).then(() => {
      const initialHtml = e.target.innerHTML;
      e.target.textContent = "Copied to clipboard";
      setTimeout(() => {
        e.target.innerHTML = initialHtml;
      }, 1000);
    });
  };

  return (
    <form>
      {/* name */}
      <div className="form-group">
        <label htmlFor="full_name" className="control-label">
          Name
        </label>
        <input
          name="full_name"
          type="text"
          value={personInfo.name}
          onChange={e =>
            props.onChange({ ...personInfo, name: e.target.value })
          }
          className="form-control"
          key="full_name"
        />
      </div>
      {/* tags */}
      <div className="form-group">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="tags" className="control-label">
            Tags
          </label>
          <a
            style={{ color: "#aaa", marginTop: "10px" }}
            onClick={e => {
              copyToClipboard(e, personInfo.tags.join(","));
            }}
          >
            Copy <span className="glyphicon glyphicon-copy"></span>
          </a>
        </div>

        <div style={{ border: "1px solid #ddd" }}>
          <MultiSelectInput
            controlStyle={{
              paddingTop: 4
            }}
            name="tags"
            options={getPersonTagOptions(props.allPeopleAttributes)}
            value={personInfo.tags.map(tag => ({ label: tag, value: tag }))}
            onChange={event =>
              props.onChange({
                ...personInfo,
                tags: event.map(evt => evt.value)
              })
            }
          />
        </div>
      </div>

      {/* emails */}
      <div className="form-group">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label htmlFor="emails" className="control-label">
            Emails
          </label>
          <a
            style={{ color: "#aaa", marginTop: "10px" }}
            onClick={e => {
              copyToClipboard(e, personInfo.emails.join(", "));
            }}
          >
            Copy <span className="glyphicon glyphicon-copy"></span>
          </a>
        </div>
        <div style={{ border: "1px solid #ddd" }}>
          <MultiSelectInput
            controlStyle={{
              paddingTop: 4
            }}
            name="emails"
            hideAutocomplete={true}
            value={personInfo.emails.map(email => ({
              label: email,
              value: email
            }))}
            onChange={event => {
              const newChips = event.map(evt => evt.value);
              // make sure to split items by comma
              const newEmails = newChips
                .map(chip =>
                  chip
                    .split(",")
                    .map(part => part.trim())
                    .filter(part => !!part)
                )
                .reduce((bucket, current) => bucket.concat(current), []);

              props.onChange({
                ...personInfo,
                emails: newEmails
              });
            }}
          ></MultiSelectInput>
        </div>
      </div>
      {/* organization */}
      <div className="form-group">
        <label htmlFor="organization" className="control-label">
          Organization
        </label>
        <input
          name="organization"
          type="text"
          value={personInfo.organization || ""}
          onChange={e =>
            props.onChange({ ...personInfo, organization: e.target.value })
          }
          className="form-control"
          key="organization"
        />
      </div>
      {/* notes */}
      <div className="form-group">
        <label htmlFor="notes" className="control-label">
          Notes
        </label>
        <textarea
          name="notes"
          value={personInfo.notes || ""}
          onChange={e =>
            props.onChange({ ...personInfo, notes: e.target.value })
          }
          className="form-control"
          key="notes"
        />
      </div>
      {/* <button
        className="btn btn-primary"
        type="submit"
        onClick={submitAddPersonForm}
        disabled={isLoading}
      >
        Add Person
      </button>{" "} */}
    </form>
  );
};

export default EditPersonForm;
