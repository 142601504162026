import React from "react";
import _ from "lodash";

const Alert = ({ level = "info", style = {}, children }) => {
  const levelStyles = {
    grey: {
      backgroundColor: "#eaeaea",
      border: "1px solid #dedede",
      color: "#333"
    },
    warning: {
      backgroundColor: "#f39c12",
      border: "1px solid #f39c12",
      color: "#fff"
    },
    danger: {
      backgroundColor: "#e74c3c",
      border: "1px solid #e74c3c",
      color: "#fff"
    },
    info: {
      backgroundColor: "#3498db",
      border: "1px solid #3498db",
      color: "#fff"
    }
  };
  const defaultStyles = {
    padding: 15,
    marginBottom: 21,
    borderRadius: 4,
    maxWidth: 1200,
    marginLeft: "auto",
    marginRight: "auto"
  };
  return (
    <div style={{ ...levelStyles[level], ...defaultStyles, ...style }}>
      {children}
    </div>
  );
};

export default Alert;
