import { HeartTwoTone } from "@ant-design/icons";
import React, { useContext } from "react";
import { GlobalContext } from "./App";
import {
  getStoreCredit,
  isTrialing,
  referralCreditWaitingForFreeUpgrade
} from "./Billing/billingHelpers";
import { BlueCallout } from "./Layout";
import { getAffiliateLink } from "./lib/utils";
import ModalSimple from "./ModalSimple";

export function AffiliateDialog({ visible, onClose }) {
  return (
    <ModalSimple visible={visible} onClose={onClose} style={{ maxWidth: 500 }}>
      <h1>
        Share the <HeartTwoTone />
      </h1>
      <h3>Give $5 Receive $5</h3>
      <p>
        When you refer a paying subscriber to FollowUpThen using the link below,
        both of you receive a $5 credit.
      </p>
      <AffiliateLinkCopyInput />
      <AffiliateStats />
    </ModalSimple>
  );
}

export const AffiliateLinkCopyInput = ({ style = {} }) => {
  const { loggedInUser } = useContext(GlobalContext);
  return (
    <input
      onClick={e => e.target.select()}
      className="form-control"
      value={getAffiliateLink(loggedInUser)}
      style={{ marginBottom: 40, ...style }}
      readOnly={true}
    />
  );
};

export const AffiliateStats = () => {
  const { loggedInUser } = useContext(GlobalContext);
  let storeCredit = getStoreCredit(loggedInUser);
  if (referralCreditWaitingForFreeUpgrade(loggedInUser)) {
    storeCredit = +5;
  }
  return (
    <div>
      <strong>My Credits: ${storeCredit}</strong>
      {isTrialing(loggedInUser) && storeCredit > 0 ? (
        <BlueCallout>
          This credit will be applied towards future invoices after your free
          trial is over.
        </BlueCallout>
      ) : null}
    </div>
  );
};
