import queryString from "query-string";
/**
 * Used for user confirm dialogs before (for example)
 * sending user to disconnect URL to remove an email account
 * @todo turn this into an nicer alert.
 */
let showedAlert;
export function renderHandleConfirm({ location, history }) {
  const qs = queryString.parse(location.search);
  const destinationLink = decodeURI(qs.to);
  const msg = qs.msg ? decodeURI(qs.msg) : "Are you sure?";
  // @todo handle double-redirect https://github.com/mailbots/fut-admin-ui/issues/158
  if (showedAlert) {
    return null;
  } else if (window.confirm(msg)) {
    showedAlert = true;
    window.location.href = destinationLink;
    return null;
  } else {
    history.goBack();
  }
  return null;
}
