import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { message as antMessage } from "antd";
import IframeComm from "react-iframe-comm";
import { userDate } from "./lib/utils";
import queryString from "query-string";
import nprogress from "nprogress";
import _ from "lodash";
import Modal from "./Modal";
import Emulator from "./Emulator";
import { withRouter } from "react-router-dom";
import DOMPurify from "dompurify";

/**
 * This component renders an email, complete with working mailto
 * links that use the Emulator component for submitting the email
 * results to MailBots. It also sandboxes the email content, allowing
 * developers to render custom HTML, and even (eventually) JS securely.
 */

// Currently, when a message is fired in one iframe, all instances of iFrame
// fire the message to the parent. To get around this, we assign each iFrame
// its own id. Messages from each iframe are sent their respective ids
let iframeId = 1;

const VisibilityLink = ({ children, onClick, style }) => (
  <a
    style={{
      float: "right",
      color: "#aaaaaa",
      position: "absolute",
      top: 0,
      right: 5,
      margin: 4,
      padding: 4,
      fontSize: "1em",
      textDecoration: "none",
      backgroundColor: "#fff",
      // zIndex: 10,
      opacity: 0.9,
      ...style
    }}
    onClick={onClick}
    href=""
  >
    {children}
  </a>
);

class Email extends Component {
  constructor(props) {
    super(props);
    this.lastIframeMessage = "";
    this.iframeId = iframeId++;
    this.state = {
      mailtoWindow: false,
      mailtoEmail: {},
      mailtoEmailStatus: null,
      showHeaderFields:
        !_.isEmpty(props.message.cc) || !_.isEmpty(props.message.bcc),
      sandboxedHtml: "",
      errorMessage: null,
      infoMessage: null
    };
  }

  updateSandboxedHtml(message) {
    this.setState({
      sandboxedHtml: this.securelySandboxifyHtmlEmail(message.html)
    });
  }

  componentDidMount() {
    this.updateSandboxedHtml(this.props.message);
    const moreThanOneRecipient =
      this.props.message &&
      this.props.message.to &&
      this.props.message.to.length > 1;
    if (moreThanOneRecipient && this.isWhitelabelMessage(this.props.message)) {
      this.setState({
        infoMessage: "Sent separately to each recipient"
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      this.updateSandboxedHtml(this.props.message);
    }
  }

  debouncedError = _.debounce(({ level, message }) => {
    antMessage[level](message);
  });

  // Is this email being received by the task owner? Or
  // a separate another person? (ie, a non-MailBots user)
  // Accounts for the possibility of multiple recipients,
  // some MailBots users, some not.
  recipientOwnsTask(message, task) {
    if (!task.relationships) {
      this.debouncedError({
        level: "warn",
        message:
          "Task not found. If this email is sent to a non-user, their email actions will not work"
      });

      return true;
    }
    const allUserEmails =
      task.relationships.user && task.relationships.user.emails;
    const thisRecipients = message.to;
    if (!allUserEmails || !thisRecipients) {
      antMessage.error(
        "MailBots expected task information to be included with this action. None was found. If you keep getting this can you let the MailBots team know?"
      );
    }
    return thisRecipients.every(email => allUserEmails.includes(email));
  }

  // Prepares HTML email to be renderd into the iframe by
  // replacing action mailto links with links to open dialogs
  securelySandboxifyHtmlEmail(unsanitizedHtml) {
    // Handle email-based actions
    // https://fut.readme.io/reference#email-based-actions
    // Strip any JS to prevent injected XSS script firing the limited number
    // of email-based actions that are available.
    const html = DOMPurify.sanitize(unsanitizedHtml);
    // After JS is stripped, add our own JS

    const isClosedActionLink = href => {
      return !!href && href.includes("mailto:a+");
    };

    const isOpenActionLink = href => {
      return !!href && href.includes("mailto:ao+");
    };

    const isMailBotsAdminLink = href => {
      // checks the link href is pointing to the exact same prototol and host as current window
      return (
        !!href &&
        href.search(window.location.protocol + "//" + window.location.host) ===
          0
      );
    };

    const isMailBotsActionUrl = href => {
      return href && href.search("handle-mailto") !== -1;
    };

    const getMailtoActionFromUri = href => {
      return href.split("handle-mailto/")[1];
    };

    // Replace mailto: with a JS dialog that simulates a mailto
    const linkOpensMailtoDialog = link => {
      $(link).attr(
        "onclick",
        `sendMessage(this.href, ${this.iframeId}); return false;`
      );
    };

    // If they are linking within mailbots admin UI, efficiently send them along (todo..close dialogs and things)
    const linkOpensMailBotsAdminUrl = link => {
      $(link).attr(
        "onclick",
        `sendMailBotsAdminUrl(this.href, ${this.iframeId}); return false;`
      );
    };

    const linkFails = link => {
      $(link).attr("onclick", `return false;`);
    };

    const parsedHtml = $(
      $.parseHTML("<div data-iframeid=" + this.iframeId + ">" + html + "</div>")
    );
    const allLinks = parsedHtml.find("a");
    $(allLinks).each((index, link) => {
      let href = $(link).attr("href");

      if (isOpenActionLink(href)) {
        linkOpensMailtoDialog(link);

        // it's a closed action, but recipient is not the owner (error)
      } else if (
        isClosedActionLink(href) &&
        !this.recipientOwnsTask(this.props.message, this.props.task)
      ) {
        // give user some obvious error message in the email
        const errorMessage = `A recipient of this email will be unable
        to use one or more of its Action Emails because they are
        not the task owner. Fix this by setting 'open' to true in the
        email-based action JSON. Search "Action Emails" at fut.readme.io for more.`;
        this.setState({ errorMessage });
        linkFails(link);

        // it's a closed action, handle it
      } else if (isClosedActionLink(href)) {
        linkOpensMailtoDialog(link);

        // links to other parts of admin UI need to be handled by outer app
        // to protect against iframe standboxing restritions
        // links that point to action URLs are considered external links
      } else if (isMailBotsActionUrl(href)) {
        // extract the mailto action from the uri and replace
        // the href on the link with a proper mailto: address
        const actionEmailHref = `mailto:${getMailtoActionFromUri(href)}`;
        $(link).attr("href", actionEmailHref);
        linkOpensMailtoDialog(link);

        // if the user opted for urls instead of mailto links
        // handle those action urls by opening the mailto dialog
      } else if (isMailBotsAdminLink(href) && !isMailBotsActionUrl(href)) {
        linkOpensMailBotsAdminUrl(link);

        // Target blank, prevent link target from accessing window.opener
      } else {
        $(link).attr("target", "_blank").attr("rel", "noopener noreferrer");
      }
    });
    return parsedHtml.prop("outerHTML");
  }

  handleMessageReply(target) {
    let email = target.target.innerText;
    let baseUrl = window.location.href.split("?")[0];
    window.location.href = `${baseUrl}?to=${email}`;
  }

  // This window (parent window) received a message from the
  // sandboxed iframe that contains the rendered HTML email
  handleMessageFromIframe = e => {
    try {
      if (this.shouldIgnoreEvent(e)) {
        return;
      }
      const eventData = JSON.parse(e.data);
      const { name, iframeId } = eventData;
      const thisIframeId = this.iframeId;
      if (iframeId != thisIframeId) return; // message is not intended for this iframe

      const EVENT_HANDLERS = {
        IframeHeight: this.handle_IframeHeight_Event,
        actionEmail: this.handle_ActionEmail_Event,
        mailbotsAdminUrl: this.handle_MailBotsAdminUrl_Event
      };

      if (EVENT_HANDLERS[name]) {
        return EVENT_HANDLERS[name](e, eventData);
      }
    } catch (e) {
      console.error(e);
      antMessage.error(e.message);
    }
  };

  /**
   * Verify trusted origin and that that is is a message we want.
   * Chrome mailbots post extra message events – ie, chrome dev tools,
   * MetaMask (Etherium wallet), etc post messages from proper origins, but
   * we want to discard them.
   * @param {object} event event object
   */
  shouldIgnoreEvent = event => {
    // verify same origin
    if (event.target.origin.split("://")[1] !== window.location.host) {
      console.log(
        "MailBots Email is ignoring postMessage from unverified origin"
      );
      return true;
    }

    // events must be JSON.parsable strings with a "name" key
    let parsedJson;
    try {
      parsedJson = JSON.parse(event.data);
      if (!parsedJson["name"]) {
        console.log("MailBots Email ignoring nameless postMessage event");
        return true;
      }
    } catch (e) {
      // ignore JSON parse errors
      return true;
    }

    // this is fired by chrome dev tools, so discard...
    if (event.data.payload) {
      console.log("MailBots Email ignoring Chrome dev tools evenet");
      return true;
    }

    // verify event data exists and is a string
    if (!event.data || typeof event.data !== "string") {
      console.log(
        "MailBots Email ignoring email with unexpected type" + typeof event.data
      );
      return true;
    }

    // success
    return false;
  };

  // An email-based action was clicked
  handle_ActionEmail_Event = (e, { message: actionHref }) => {
    const email = this.deserializeMailto(actionHref);
    this.showMailToDialog(email);
  };

  // When the iframe first loads, it sends the height
  // of its contents so the email can be displayed without
  // vertical scrollbars
  handle_IframeHeight_Event = (e, { height }) => {
    this.setState({
      iframeHeight: height + 100
    });
  };

  // When user clicks on link to another MailBots Admin URL
  // destination,   it needs to be handled by outer app
  // to protect against iframe iframe sandbox restritionss
  handle_MailBotsAdminUrl_Event = (e, { url }) => {
    // window.open(url, "_blank");
    const parser = document.createElement("a");
    parser.href = url;
    this.props.history.push(parser.pathname + parser.search);
  };

  // Separate email and query params from mailto link
  // @param mailtoString { string } - a mailto link with or without the "mailto" prefix
  getEmailParts(mailtoString) {
    const emailOnly = mailtoString.replace("mailto:", "");
    let fullString, email, query;
    if (emailOnly && emailOnly.indexOf("?") > -1) {
      [fullString, email, query] = emailOnly.match(/(.*)\?(.*)/); // eslint-disable-line
    } else {
      [email, query] = [emailOnly, null];
    }
    return {
      email,
      query
    };
  }

  // Turn a mailto string into an email object
  deserializeMailto(actionHref) {
    const actionEmail = this.getEmailParts(actionHref).email;
    const query = this.getEmailParts(actionHref).query;
    let email = queryString.parse(query);
    if (typeof email !== "object") {
      email = {};
    }
    if (email.body) email.html = email.body;
    email.to = actionEmail;
    return email;
  }

  showMailToDialog = email => {
    this.setState({
      mailtoWindow: true,
      mailtoEmail: email
    });
  };

  hideMailtoDialog = () => {
    this.setState({
      mailtoWindow: false
    });
  };

  onPreMailtoSend = (e, referenceEmail) => {
    nprogress.start();
    this.setState({
      mailtoEmailStatus: "loading"
    });
    if (typeof this.props.onMailtoPreSend === "function") {
      const presendResult = this.props.onMailtoPreSend(e, referenceEmail);
      if (presendResult === null) {
        // sending was cancelled so the success handler won't be called
        // reset the mail state now
        nprogress.done();
        this.setState({
          mailtoEmailStatus: "done",
          mailtoWindow: false
        });
        return null;
      } else {
        return presendResult;
      }
    }
  };

  /**
   * When user clicks on Mailto link from the an email message
   * rendered by this component, it brings up the Emulator component
   * to send that email to MailBots. Upon successful send, this function
   * is called with the callback response.
   */
  onMailtoSendSuccess = tasks => {
    setTimeout(() => nprogress.done(), 2000); //TODO Findout why the UI delay when updating email
    this.setState({
      mailtoEmailStatus: "done",
      mailtoWindow: false
    });

    if (typeof this.props.onMailtoSendSuccess === "function") {
      this.props.onMailtoSendSuccess(tasks);
    }
  };

  onMailtoSendError = e => {
    antMessage.error(e.message);
    nprogress.done();
    this.setState({
      mailtoEmailStatus: "done"
    });
    this.hideMailtoDialog();
    console.log(e);
  };

  // Handles the "more" link to toggle the cc/bcc field visibility
  toggleHeaderFieldVisibility = e => {
    e.preventDefault();
    this.setState({
      showHeaderFields: !this.state.showHeaderFields
    });
  };

  // Emails are sometimes rendered with to, cc and bcc as arrays, other times they
  // comma separated strings.
  normalizeMessage(message) {
    if (typeof message !== "object" || _.isArray(message)) {
      return null;
    }
    // turne all recipient fields into array
    ["to", "cc", "bcc"].forEach(field => {
      if (_.isEmpty(field)) message[field] = [];
      if (typeof message[field] === "string")
        message[field] = message[field].split(",");
    });
    return message;
  }

  // Currently we always send a message to FUT user email address that scheduled the followup (always).
  // This means the user can remove any message that is not being sent to one of their addresses
  isRemovableMessage(message) {
    if (!message || !message.to) return false;
    return message.to[0] !== this.props.task.reference_email.from; // Message to user must be sent for now.
  }

  isExternal(message) {
    if (!this.props.task || !this.props.task.reference_email) return false;
    return message.to[0] !== this.props.task.reference_email.from;
  }

  isWhitelabelMessage(message) {
    const isDelayedSend = this.props.task.command.includes("-wl");
    return isDelayedSend && this.isExternal(message); // Message to user must be sent for now.
  }

  getEmailHeader(message, headerName) {
    if (!message || typeof message.headers !== "object") return "";
    return message.headers[headerName];
  }

  render = () => {
    const message = this.normalizeMessage(this.props.message);
    if (!message) return null;
    const style = {
      emailMessage: {
        padding: "10px 0px",
        overflow: "hidden"
      },
      emailMessageContainer: {
        marginLeft: 25,
        overflow: "none"
      },

      emailMessageField: {
        listStyle: "none",
        margin: "4px 0",
        padding: 0,
        whiteSpace: "nowrap"
      },

      emailMessageLabel: {
        color: "#aaa",
        margin: "3px 8px"
      },

      iFrameBody: {
        border: 0,
        overflow: "wrap",
        marginLeft: -10,
        borderTop: "1px solid #efefef",
        padding: 10,
        marginTop: 6,
        width: "100%",
        height: 600
      },

      subject: {
        fontSize: "1.1em",
        fontWeight: "bold",
        padding: 7
      },

      triggerPreText: {
        textAlign: "center",
        fontSize: 11,
        color: "#aaa",
        marginRight: 15
      },

      removeMessageLink: {
        textAlign: "right",
        display: "block",
        marginBottom: -17,
        marginTop: 20,
        fontSize: 11,
        color: "#aaa"
      }
    };

    const baseUrl = window.location.href.split("?")[0];
    return (
      <div style={{ marginTop: 20, marginBottom: 30 }}>
        <div className="main-panel email-message" style={style.emailMessage}>
          {this.props.handleOnMessageRemove &&
            this.isRemovableMessage(message) && (
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  margin: "10px 20px 20px 20px",
                  borderRadius: 4,
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                ⚠️ External followup.
                <a
                  onClick={e => this.props.handleOnMessageRemove(e, message)}
                  style={{ color: "#aaa", textDecoration: "underline" }}
                >
                  Remove
                </a>
              </div>
            )}
          <Modal
            visible={this.props.task && this.state.mailtoWindow}
            onClose={this.hideMailtoDialog}
          >
            <Emulator
              email={this.state.mailtoEmail}
              loggedInUser={this.props.loggedInUser}
              status={this.state.mailtoEmailStatus}
              autocompleteEmails={["user@email.com"]}
              onPreSend={this.onPreMailtoSend} //this.beforeMailtoSend // Set mailtoEmailStatus to loading...
              onSendSuccess={this.onMailtoSendSuccess} // Set status to loaded, close dialog and fire callback
              onSendError={this.onMailtoSendError} // Set status to loaded, close dialog and fire callback
              style={{ width: "80vw", maxWidth: 600 }}
              sendButtonContent={
                <span>
                  <span
                    className="glyphicon glyphicon-send"
                    style={{ zIndex: 500 }}
                  >
                    {" "}
                  </span>{" "}
                  SEND
                </span>
              }
            />
          </Modal>
          <ul>
            <li style={{ position: "relative" }}>
              <VisibilityLink
                onClick={this.toggleHeaderFieldVisibility}
                style={{ top: -5 }}
              >
                {this.state.showHeaderFields ? "less" : "more..."}
              </VisibilityLink>
            </li>
            <li>
              <span className="field-label">To:</span> {message.to.join(", ")}
            </li>
            {this.state.showHeaderFields || this.isExternal(message) ? (
              <React.Fragment>
                <li>
                  <span className="field-label">From:</span> {message.from}
                </li>
                <li>
                  <span className="field-label">Reply-to:</span>
                  {this.getEmailHeader(message, "Reply-To") ||
                    "noreply@followupthen.com"}{" "}
                </li>
              </React.Fragment>
            ) : null}
            {this.state.showHeaderFields && (
              <React.Fragment>
                {message.cc && (
                  <li>
                    <span className="field-label">Cc:</span>{" "}
                    {message.cc.join(", ")}
                  </li>
                )}
                {message.bcc && (
                  <li>
                    <span className="field-label">Bcc:</span>{" "}
                    {message.bcc.join(", ")}
                  </li>
                )}
                <li>
                  <span className="field-label">MessageID:</span>
                  {this.getEmailHeader(message, "Message-ID")}{" "}
                </li>
                <li>
                  <span className="field-label">References:</span>
                  {this.getEmailHeader(message, "References")}{" "}
                </li>
                <li>
                  <span className="field-label">In-Reply-To:</span>
                  {this.getEmailHeader(message, "In-Reply-To")}{" "}
                </li>
                <li>
                  <span className="field-label">All Headers:</span>
                  {JSON.stringify(message["headers"])}{" "}
                </li>
                <li>
                  <span className="field-label">Created:</span>{" "}
                  {this.props.task.created
                    ? userDate({
                        timestamp: this.props.task.created,
                        timezone: this.props.loggedInUser.timezone
                      })
                    : "(No task set)"}
                </li>

                <li>
                  <span className="field-label">Triggers:</span>{" "}
                  {this.props.task.trigger_time
                    ? userDate({
                        timestamp: this.props.task.trigger_time,
                        timezone: this.props.loggedInUser.timezone
                      })
                    : "(Not set)"}
                </li>
              </React.Fragment>
            )}
            <li>
              <span className="field-label">Subject:</span> {message.subject}
            </li>
          </ul>
          {this.state.errorMessage && (
            <div
              className="alert alert-dismissible alert-warning"
              style={{ margin: 10 }}
            >
              {this.state.errorMessage}
            </div>
          )}
          {this.state.infoMessage && (
            <div className="alert alert-grey" style={{ margin: 10 }}>
              {this.state.infoMessage}
            </div>
          )}
          <IframeComm
            attributes={{
              src:
                process.env.REACT_APP_SANDBOX_URL ||
                "http://localhost:3001/sandbox-iframe",
              sandbox:
                "allow-scripts allow-popups allow-popups-to-escape-sandbox",
              scrolling: "no",
              width: "100%",
              height: this.state.iframeHeight || "400",
              style: {
                ...style.iFrameBody,
                padding: 25,
                height: this.state.iframeHeight || style.iFrameBody.height,
                visibility: this.state.iframeHeight ? "visible" : "hidden"
              }
            }}
            postMessageData={this.state.sandboxedHtml}
            handleReceiveMessage={this.handleMessageFromIframe}
          />
        </div>
      </div>
    );
  };
}

Email.propTypes = {
  onMailtoSendSuccess: PropTypes.func,
  onPreMailtoSend: PropTypes.func
};

export default withRouter(Email);
