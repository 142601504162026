import React, { useContext, useState } from "react";
import { message as antMessage } from "antd";
import { mailbotsAdminBrowser } from "../lib/utils";
import { isOnFreePlan } from "../Billing/billingHelpers";
import { GlobalContext } from "../App";
import { BlueCallout } from "../Layout";
import ModalSimple from "../ModalSimple";
import { Popover } from "antd";
import { logger } from "../lib/Logger";

export const SetUpActionEmails = ({ onClose, visible }) => {
  // Visibility controlled by parent
  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const [settingsSaved, setSettingsSaved] = useState(false);

  if (!loggedInUser) return null;

  const setEmailBasedActions = async yes => {
    try {
      if (yes) {
        await mailbotsAdminBrowser.userUpdateSelf({
          user: {
            action_email_handler: "mailto"
          }
        });
        reloadUser(); // refresh user
        setSettingsSaved(true);
        logger.log("email based actions dialog - enabled");
      } else {
        // do not await - save prefs in background
        mailbotsAdminBrowser.userUpdateSelf({
          user: {
            action_email_handler: "url"
          }
        });
        reloadUser(); // refresh user
        onClose();
        logger.log("email based actions dialog - disabled");
      }
    } catch (e) {
      antMessage.error(
        "something went wrong trying to save your settings. Please email help@humans.fut.io for help"
      );
    }
  };

  return (
    <ModalSimple
      onClose={() => {
        logger.log("email based actions dialog - dismissed");
        onClose();
      }}
      style={{
        maxWidth: 600
      }}
      visible={visible}
    >
      <div className="modal-tour" style={{ marginBottom: 20 }}>
        <h2>Enable Email-Based Actions?</h2>
        <p style={{ textAlign: "center" }}>
          Get things done without leaving your inbox.
        </p>
        {isOnFreePlan(loggedInUser) &&
        process.env.REACT_APP_BILLING_ENFORCED !== "yes" ? (
          <BlueCallout>
            Beta update: This is turning into a premium feature.
          </BlueCallout>
        ) : null}

        <div style={{ margin: "40px 0 0 0" }}>
          {settingsSaved ? (
            <BlueCallout style={{ textAlign: "left" }}>
              <strong>Setting Saved!</strong>
              <br />
              If this does not work as expected, you can change this back on
              your settings page, or search for "actions" on our help site.{" "}
              <br />
              <p style={{ textAlign: "center" }}>
                <a className="btn btn-secondary" onClick={onClose}>
                  Close
                </a>
              </p>
            </BlueCallout>
          ) : (
            <>
              <button
                style={{ padding: "10px 0px", margin: 5, width: 135 }}
                className="btn btn-primary"
                onClick={async e => {
                  e.target.textContent = "...saving";
                  await setEmailBasedActions(true);
                }}
              >
                Enable
              </button>
              <button
                style={{ padding: "10px 0px", marign: 5, width: 135 }}
                className="btn btn-secondary"
                onClick={async e => {
                  e.target.buttonText = "...saving";
                  setEmailBasedActions(false);
                }}
              >
                Not Now
              </button>
            </>
          )}
          <Popover
            trigger="click"
            content={
              <div style={{ maxWidth: 250 }}>
                Email-based actions use a special type of link (called a
                "mailto" link) which composes an email instead of opening a URL.{" "}
                <a
                  href="https://help.followupthen.com/knowledge-base/email-base-actions-troubleshooting-and-faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more
                </a>
              </div>
            }
          >
            <span
              className="muted-text"
              style={{
                color: "#aaa",
                textAlign: "center",
                textDecoration: "none",
                display: "block",
                marginTop: 40,
                cursor: "pointer"
              }}
            >
              What is this wizardry?
            </span>
          </Popover>
        </div>
      </div>
      <div style={{ marginBottom: -30 }}>
        <img
          src="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
          style={{
            maxHeight: 310,
            objectFit: "cover",
            width: "100%",
            maxWidth: 450
          }}
        />
      </div>
    </ModalSimple>
  );
};
