import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { message as antMessage } from "antd";
import * as nprogress from "nprogress";
import { mailbotsAdminBrowser } from "./lib/utils";
import Card from "./Card";
import Loading from "./Loading";
import {
  calculateTeamTotalPrice,
  getPriceForAugmentedPlans,
  getTeamSummary,
  hasEmployees,
  isOnLegacyPlan,
  isSoloPaidUser,
  isTeamOwner,
  userIsBillingAnnually
} from "./Billing/billingHelpers";
import { SettingsMenu } from "./SettingsMenu";
import Alert from "./Alert";
import { PopoverStyled, ResponsiveSettingsSubnav } from "./Layout";
import { Popover } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { ChoosePlanDialog } from "./Billing/ChoosePlanDialog";
import { GlobalContext } from "./App";
import urljoin from "url-join";
import queryString from "query-string";
import ModalSimple from "./ModalSimple";
import { PlanBreakdown } from "./Billing/SettingsAccount";
import { InviteYourTeam, TeamOwnerUpdatePlan } from "./Billing/UpgradeDialogs";
import styled from "styled-components";
import { logger } from "./lib/Logger";

const SettingsTeam = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState();
  const [teamSettings, setTeamSettings] = useState();
  const [choosePlanVisible, setChoosePlanVisible] = useState(false);
  const [teamMemberModalVisible, setTeamMemberModalVisible] = useState(false);
  const [modalTeamMember, setModalTeamMember] = useState(null);
  const [skillsList, setSkillsList] = useState([]);

  const { loggedInUser } = React.useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    loadPage();
  }, [JSON.stringify(teamMembers)]);

  // Open employee edit dialog based on URL
  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    let thisTeammember;
    if (qs.editTeamMember && loggedInUser) {
      thisTeammember = getTeamSummary(loggedInUser, teamMembers).find(
        t => t.email == qs.editTeamMember
      );
      showEditTeamMember(thisTeammember);
    }
  }, [JSON.stringify(modalTeamMember), JSON.stringify(teamMembers)]);

  // await to prevent race conditions
  const loadPage = async () => {
    try {
      await loadTeamMembers();
    } catch (error) {
      antMessage.error(error.message);
    }
    setIsPageLoading(false);
  };

  const showEditTeamMember = teamMember => {
    if (teamMember) {
      let qs = queryString.parse(window.location.search);
      qs.editTeamMember = teamMember.email;
      history.push("?" + queryString.stringify(qs));
    }
    setModalTeamMember(teamMember);
    setTeamMemberModalVisible(true);
  };

  const hideEditTeamMember = teamMember => {
    if (teamMember) {
      // clear url params
      history.push(window.location.pathname);
    }
    setModalTeamMember(null);
    setTeamMemberModalVisible(false);
  };

  // refresh the team member in the modal (stored in a different state)
  const refreshModalTeamMember = refreshedTeamMembers => {
    if (!Array.isArray(refreshedTeamMembers)) return;
    if (teamMemberModalVisible && modalTeamMember) {
      const refreshedModalTeamMember = refreshedTeamMembers.find(
        t => t.email === modalTeamMember.email
      );
      setModalTeamMember(refreshedModalTeamMember);
    }
  };

  const loadTeamMembers = async () => {
    try {
      let res = await mailbotsAdminBrowser.getTeamMembersAndSettings();
      let members = res && res.team && res.team.members;
      if (!members) members = [];
      setTeamMembers(members);

      let settings = (res && res.team && res.team.settings) || {};
      setTeamSettings(settings);

      refreshModalTeamMember(members);
    } catch (e) {
      console.error("Error loading team:", e);
      antMessage.error(e);
    }
  };

  // Open employee edit dialog based on URL
  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    let thisTeammember;
    if (qs.editTeamMember && loggedInUser) {
      thisTeammember = getTeamSummary(loggedInUser, teamMembers).find(
        t => t.email == qs.editTeamMember
      );
      showEditTeamMember(thisTeammember);
    }
  }, [JSON.stringify(modalTeamMember), JSON.stringify(teamMembers)]);

  return (
    <div className="main-container" style={{ maxWidth: 600 }}>
      <ChoosePlanDialog
        isModalVisible={choosePlanVisible}
        setIsModalVisible={setChoosePlanVisible}
        onClose={() => setChoosePlanVisible(false)}
      />
      <div className="main-middle">
        {isOnLegacyPlan(loggedInUser) && !hasEmployees(loggedInUser) ? (
          <Card
            type="large"
            innerStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <p>
              <strong>⚠️ Update Billing</strong>
              <br />
              Update your billing to enable the new{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                team plan
              </a>
              .
            </p>
            <a
              onClick={() => setChoosePlanVisible(true)}
              className="btn btn-default"
            >
              Update Billing
            </a>
          </Card>
        ) : null}

        {isOnLegacyPlan(loggedInUser) && hasEmployees(loggedInUser) ? (
          <Card type="large">
            <p>
              <strong> ⚠️ Move Your Team</strong>. Our new team billing system
              is simpler, more flexible and affordable than before. First{" "}
              <a onClick={() => setChoosePlanVisible(true)}>
                select your new plan
              </a>
              , then invite your team to visit <em>app.followupthen.com</em> to
              choose theirs.{" "}
              <a
                target="_blank"
                rel="nofollower noopener noreferrer"
                href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
              >
                Read more
              </a>
            </p>
          </Card>
        ) : null}
        {!isPageLoading ? (
          <>
            <TeamMemberDetailsModal
              teamMember={modalTeamMember}
              visible={teamMemberModalVisible}
              skillsList={skillsList}
              onClose={hideEditTeamMember}
              loadTeamMembers={loadTeamMembers}
            />
            <InviteTeamMember
              defaultBudgetCent={
                (teamSettings && teamSettings.default_budget_cent) || 0
              }
              onInviteSent={loadTeamMembers}
            />
            <TeamStats />
            <TeamMembers
              teamMembers={teamMembers}
              showEditTeamMember={showEditTeamMember}
              isLoading={false}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default SettingsTeam;

const InviteTeamResponsiveForm = styled.div.attrs({
  className: "form-group"
})`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-column-gap: 5px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
  }
`;

export const InviteTeamMember = ({
  defaultBudgetCent,
  onInviteSent = () => {}
}) => {
  const [inviteLinkVisible, setInviteLinkVisible] = useState(false);
  const [teamSignupHash, setTeamSignupHash] = useState("");
  const [invitePending, setInvitePending] = useState(false);
  const [newTeamMemberEmail, setNewTeamMemberEmail] = useState("");
  const [newTeamMemberBudgetDollars, setNewTeamMemberBudgetDollars] =
    useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const getTeamSignupHash = () =>
    urljoin(
      process.env.REACT_APP_MAILBOTS_WEB_APP_BASE,
      "login/signup",
      "?" + queryString.stringify({ tid: loggedInUser.id, h: teamSignupHash })
    );

  const addTeamMembers = async () => {
    try {
      if (isOnLegacyPlan(loggedInUser)) {
        logger.log("billing: team admin legacy trying to add members", {
          level: "error"
        });
        throw Error(
          "Please update your billing account to our latest version before adding team members."
        );
      }
      if (
        !window.confirm(`You will only be billed for the base account(s) now (pro-rated to your billing cycle). \
 Skills are billed only as used, up the allowed budget. Skill budget can be adjusted any time.`)
      ) {
        return;
      }
      setInvitePending(true);
      nprogress.start();
      const budgetCent =
        newTeamMemberBudgetDollars * 100 || defaultBudgetCent || 0;
      const ctEmails = await mailbotsAdminBrowser.addTeamMembersByCsv({
        teamMemberEmailsCsv: newTeamMemberEmail,
        budgetCent
      });
      await reloadUser();
      logger.log("billing: team admin invited team members", {
        data: { invited_str: newTeamMemberEmail }
      });
      setNewTeamMemberEmail("");
      if (errorMessages.length) setErrorMessages(errorMessages);
      antMessage.success(
        `${ctEmails} team ${ctEmails > 1 ? `members` : `member`} invited`
      );
      setInvitePending(false);
      if (typeof onInviteSent === "function") {
        onInviteSent();
      }
    } catch (e) {
      setInvitePending(false);
      console.error(e);
      antMessage.error(e.message);
    }

    nprogress.done();
  };

  useEffect(() => {
    getOrGenerateSignupHash();
  }, []);

  const regenerateTeamHashLink = async e => {
    try {
      nprogress.start();
      e.preventDefault();
      const res = await mailbotsAdminBrowser.createTeamSignupHash();
      setTeamSignupHash(res.signup_hash);
      nprogress.done();
    } catch (error) {
      antMessage.error(e.message);
      nprogress.done();
    }
  };

  const getOrGenerateSignupHash = async e => {
    try {
      nprogress.start();
      if (e) e.preventDefault();
      let res = await mailbotsAdminBrowser.getTeamSignupHash();
      if (!res && !res.signup_hash)
        res = await mailbotsAdminBrowser.createTeamSignupHash();
      setTeamSignupHash(res.signup_hash);
      nprogress.done();
    } catch (error) {
      debugger;
      antMessage.error(error.message);
      nprogress.done();
    }
  };

  const InviteLinkContent = () => (
    <div>
      <p style={{ fontWeight: "strong" }}>
        Send this link to your team (expires in 30 days)
      </p>
      <input
        className="readonly"
        style={{ width: "100%", marginBottom: 15 }}
        disabled={true}
        value={getTeamSignupHash()}
      />
      <a>Copy</a> | <a onClick={regenerateTeamHashLink}>Regenerate</a>{" "}
      <span className="text-muted">(Disables earlier links)</span>
    </div>
  );

  return (
    <Card type="large" className="settings-card">
      {errorMessages && errorMessages.length ? (
        <Alert level="warning">
          {errorMessages.map(e => (
            <p>{e}</p>
          ))}
        </Alert>
      ) : null}
      <label>Invite team members</label>
      <span>
        {" "}
        {/*  WIP: Team invite link WIP, pending  https://github.com/mailbots/fut-core-api/issues/2690#issuecomment-763218351
        <Popover
          content={InviteLinkContent}
          onClick={() => setInviteLinkVisible(true)}
          visible={inviteLinkVisible}
          trigger="click"
          onVisibleChange={visible => setInviteLinkVisible(visible)}
        >
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#aaa"
            }}
          >
            get an invite link <LinkOutlined />
          </span>
        </Popover> */}
      </span>
      <InviteTeamResponsiveForm>
        <input
          name="email"
          key="invite-team-member"
          type="email"
          className="form-control"
          placeholder="email@example.com, email2@example.com, etc."
          value={newTeamMemberEmail}
          onChange={e => setNewTeamMemberEmail(e.target.value)}
        />
        {/* <input
          name="budget"
          key="budget"
          type="number"
          className="form-control"
          placeholder="budget/mo"
          value={newTeamMemberBudgetDollars}
          onChange={e => setNewTeamMemberBudgetDollars(e.target.value)}
        /> */}

        <button
          className="btn btn-mailbots btn-primary"
          onClick={addTeamMembers}
          disabled={invitePending ? true : false}
        >
          {!invitePending ? "Invite" : "Inviting"}
        </button>
      </InviteTeamResponsiveForm>
      <p>
        {" "}
        Adds {userIsBillingAnnually ? `$4/month` : `$5/month`} per member
        {userIsBillingAnnually ? ` (billed annually)` : null}. Add optional
        <PopoverStyled
          content={
            <div>
              <h4 style={{ marginTop: 8 }}>Skill Budget</h4>
              <div style={{ display: "flex", margin: "8px 0" }}>
                <input
                  name="budget"
                  key="budget"
                  type="number"
                  className="form-control"
                  placeholder="budget/mo"
                  value={newTeamMemberBudgetDollars}
                  onChange={e => setNewTeamMemberBudgetDollars(e.target.value)}
                  style={{ flex: 4, marginRight: 5 }}
                />
                <button
                  style={{
                    flex: 1
                  }}
                  className="btn btn-primary"
                  onClick={e => {
                    e.persist();
                    e.preventDefault();
                    e.target.textContent = "✔";
                    window.setTimeout(() => {
                      e.target.textContent = "Set";
                    }, 1500);
                  }}
                >
                  Set
                </button>
              </div>
              <p>
                Pre-approve invited members to install premium skills up to an
                allowed budget.
              </p>
              <p>
                Anything above {userIsBillingAnnually ? `$4` : `$5`} (base plan
                cost) is available to the team member for premium skills (ex,
                SMS, response detection and Google Calendar).{" "}
              </p>
              <p>
                Unused budget is not billed. Team members can request a budget
                increase any time. Remove this team member or their skills at
                any time to receive a credit for any unused portion of their
                subscription.
              </p>
              <p>
                Read more about{" "}
                <a
                  href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Skill budget
                </a>
                .
              </p>
            </div>
          }
        >
          {" "}
          Skill budget
        </PopoverStyled>
      </p>
    </Card>
  );
};

export const TeamStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [teamStats, setTeamStats] = useState({
    arriving_this_week: 0,
    pending_followups: 0,
    team_members: 0
  });

  useEffect(() => {
    loadTeamStats();
  }, []);

  const loadTeamStats = async () => {
    try {
      const { stats } = await mailbotsAdminBrowser.getTeamStats();
      setTeamStats(stats);
    } catch (e) {
      antMessage.error(e.message);
    }
    setIsLoading(false);
  };
  return (
    <Card type="large">
      <h2>Stats</h2>
      {isLoading ? (
        <div>...loading stats</div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "70% 30%",
            margin: "40px 0"
          }}
        >
          <p>Members:</p>
          <p>{teamStats.team_members}</p>

          <p>Pending followups:</p>
          <p>{teamStats.pending_followups}</p>

          <p>Arriving this week:</p>
          <p>{teamStats.arriving_this_week}</p>
        </div>
      )}
    </Card>
  );
};

/**
 * Supporting components + functions
 */

export const resendInvite = async email => {
  try {
    nprogress.start();
    await mailbotsAdminBrowser.resendTeamInvite({ email });
    logger.log("billing: team admin resent invitation", { level: "warn" });
    antMessage.success(`We just re-sent a company invitation link to ${email}`);
  } catch (e) {
    logger.log("billing: team admin resent invitation error", {
      level: "error"
    });
    console.error(e);
    antMessage.error(e.message);
  }
  nprogress.done();
};

export const ResendLink = props => (
  <a className="text-muted" onClick={e => resendInvite(props.email)}>
    Resend Invitation
  </a>
);

export const TeamMemberDetailsModal = ({
  teamMember,
  onClose,
  visible = false,
  skillsList,
  loadTeamMembers
}) => {
  const [budgetEditorVisible, setBudgetEditorVisible] = useState(false);
  const { dialogState, skillPrice, skillName, skillId } = queryString.parse(
    window.location.search
  );

  useEffect(() => {
    if (visible) {
      logger.log("billing: team admin viewed member details", {
        data: { email_str: teamMember && teamMember.email }
      });
    }
  });

  const removeTeamMember = async ({ teamMember }) => {
    try {
      if (teamMember.verified) {
        if (
          !window.confirm(
            `Removing ${teamMember.email} and will disable all premium features on their account. Pending email \
followups will still be sent, but without premium feautres. Are you sure you would like to proceed?`
          )
        )
          return;
      }

      nprogress.start();
      await mailbotsAdminBrowser.deleteTeamMember({ email: teamMember.email });
      logger.log("billing: team admin deleted team member", {
        email_str: teamMember.email
      });
      antMessage.success(`${teamMember.email} was removed from your team`);
      window.setTimeout(
        // reload without querystring
        () => (window.location = window.location.href.split("?")[0]),
        1000
      );
    } catch (e) {
      console.error(e);
      antMessage.error(e.message);
    }

    nprogress.done();
  };

  return (
    <ModalSimple visible={visible} onClose={onClose} style={{ maxWidth: 800 }}>
      {teamMember ? (
        <div style={{ textAlign: "left" }}>
          {dialogState === "budget_approve" ? (
            <div
              style={{
                padding: 15,
                margin: "15px 0px",
                backgroundColor: "rgb(235, 245, 255)",
                display: "flex"
              }}
            >
              <div>
                {skillName ? (
                  <p>
                    <strong>Budget Request</strong>
                    <br />
                    {teamMember.name || teamMember.email} is requesting
                    permission to install{" "}
                    <a href={`/skills/${skillId}`} target="_blank">
                      <strong>{skillName}</strong>
                    </a>
                    , which costs <strong>${skillPrice}/mo</strong>. The team is
                    only billed while the user has this skill installed. If this
                    skill is uninstalled, any unused time is credited back to
                    your team account.
                  </p>
                ) : (
                  <p>
                    <strong>Budget Request</strong>
                    <br />
                    {teamMember.name || teamMember.email} is requesting a budget
                    increase to install additional skills on their FollowUpThen
                    account. If the budget it not used, you will not be billed.
                    If the skill is uninstalled before the end of the billing
                    cycle, any unused time is credited back to your team
                    account.
                  </p>
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                <a
                  onClick={() => setBudgetEditorVisible(true)}
                  className="btn btn-secondary"
                  style={{ padding: "40px 40px 10px 40px" }}
                >
                  Edit Budget
                </a>
                {budgetEditorVisible ? (
                  <span className="text-muted">(Edit below)</span>
                ) : null}
              </div>
            </div>
          ) : null}
          <h2>{teamMember.name || teamMember.email}</h2>
          <div>
            <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
          </div>
          {!teamMember.verified ? (
            <div>
              <span className="text-warning">(invite pending)</span>{" "}
              <ResendLink email={teamMember.email} />
            </div>
          ) : null}{" "}
          <div>
            <a
              style={{ color: "#aaa" }}
              onClick={e => removeTeamMember({ teamMember })}
            >
              Remove Member
            </a>
          </div>
          <hr />
          {Array.isArray(teamMember.plans) && teamMember.plans.length ? (
            <PlanBreakdown
              plans={teamMember.plans}
              userId={teamMember.id}
              skillsList={skillsList}
              onPlanChanged={() => {
                loadTeamMembers();
              }}
            />
          ) : null}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "60% 40%",
              fontStyle: "italic",
              color: "#333",
              rowGap: 20
            }}
          >
            <div style={{ paddingLeft: 10 }}>Remaining budget:</div>
            <div style={{ paddingLeft: 10 }}>
              ${teamMember.availableBudget}/mo
            </div>

            <div style={{ paddingLeft: 10 }}>
              Total Budget: (
              <Popover
                content={
                  <div style={{ maxWidth: 300 }}>
                    Base subscription included in total budget. Canceling a
                    subscription applies a pro-rated credit to the team account.{" "}
                    <a
                      target="_blank"
                      rel="nofollower noopener noreferrer"
                      href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                    >
                      Read more
                    </a>
                    .
                  </div>
                }
              >
                <span style={{ color: "#11a2d2" }}>?</span>
              </Popover>
              )
            </div>
            <div style={{ paddingLeft: 10 }}>
              ${teamMember.budget_cent ? teamMember.budget_cent / 100 : 0}/mo{" "}
              <a onClick={() => setBudgetEditorVisible(!budgetEditorVisible)}>
                Update
              </a>
            </div>
          </div>
          {budgetEditorVisible ? (
            <BudgetEditor
              setBudgetEditorVisible={setBudgetEditorVisible}
              teamMember={teamMember}
              loadTeamMembers={loadTeamMembers}
            />
          ) : null}
        </div>
      ) : (
        <Loading loadingText="...loading team member" />
      )}
    </ModalSimple>
  );
};

const subHeaderStyle = { fontWeight: "bold", marginTop: 35, marginBottom: 2 };

const BudgetEditor = ({
  loadTeamMembers,
  teamMember,
  setBudgetEditorVisible
}) => {
  const [newBudget, setNewBudget] = useState(
    (teamMember.budget_cent && teamMember.budget_cent) / 100
  );

  useEffect(() => {
    logger.log("billing: team admin opened budget editor for member", {
      data: { email_str: teamMember.email }
    });
  }, []);

  const setBudget = async e => {
    if (!newBudget)
      return antMessage.error(
        "Did you update the budget? Or did we miss something?"
      );
    // if (typeof newBudget !== "number")
    //   return antMessage.error("Please enter numbers only for the budget");
    nprogress.start();
    mailbotsAdminBrowser
      .updateTeamMember({
        email: teamMember.email,
        budgetCent: newBudget * 100
      })
      .then(async () => {
        await loadTeamMembers();
        antMessage.success("Budget updated!");
        logger.log("billing: team admin changed budget for member", {
          data: { email_str: teamMember.email, budget_num: newBudget }
        });
        setBudgetEditorVisible(false);
      })
      .catch(e => {
        logger.log("billing: team admin budget change error", {
          level: "error",
          data: { email_str: teamMember.email, budget_num: newBudget }
        });
        console.error(e);
        antMessage.error("billing: error updating budget: " + e.message);
      })
      .finally(() => {
        nprogress.done();
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#fafafa",
        padding: 15,
        margin: "8px 0"
      }}
    >
      <label>Budget</label>
      <p>
        Budget includes base plan, plus paid addons. Ex: A $20/mo budget allows
        for a $4/mo annual Base Plan, plus $16/mo in optional upgrades. (Unused
        budget is not billed)
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 25% 25%"
        }}
      >
        <div className="form-group">
          <input
            name="budget"
            type="text"
            className="form-control"
            id="name"
            value={newBudget}
            onChange={e => setNewBudget(e.target.value)}
            placeholder="budget"
          />
        </div>
        <a onClick={setBudget} className="btn btn-secondary">
          Update Budget
        </a>
      </div>
    </div>
  );
};

export const TeamMembers = ({ teamMembers, showEditTeamMember, isLoading }) => {
  const [teamFilter, setTeamFilter] = useState("");
  const { loggedInUser } = useContext(GlobalContext);

  const filterHasNameOrEmail = teamMember =>
    (teamMember.email &&
      teamMember.email.toLowerCase().includes(teamFilter.toLowerCase())) ||
    (teamMember.name &&
      teamMember.name.toLowerCase().includes(teamFilter.toLowerCase()));

  const ellipsisStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  };

  if (!teamMembers || !teamMembers.length) {
    return <InviteYourTeam />;
  }

  return (
    <Card type="large">
      {!getTeamSummary(loggedInUser, teamMembers).length && isLoading ? (
        "...loading"
      ) : (
        <div style={{ position: "relative" }}>
          <h2>My Team</h2>
          {/* @todo work out UI to filter team members (if needed) */}
          {/* <div
            className="form-group"
            style={{
              margin: "15px, 0",
              width: 150,
              height: 35,
              top: 0,
              right: 0,
              position: "absolute"
            }}
          >
            <input
              name="filter"
              key="invite-team-member"
              type="text"
              className="form-control"
              placeholder="filter team member"
              value={teamFilter}
              onChange={e => setTeamFilter(e.target.value)}
            />
          </div> */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50% 15% 20% 15%",
              borderBottom: "1px solid #ecf0f1",
              margin: 10,
              alignItems: "center"
            }}
          >
            <div>Team Member</div>
            <div>Cost/mo</div>
            <div>Unused Budget</div>
          </div>
          {getTeamSummary(loggedInUser, teamMembers)
            .filter(filterHasNameOrEmail)
            .map(teamMember => {
              return (
                <div
                  key={teamMember.id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "50% 15% 15% 20%",
                    margin: 10
                  }}
                >
                  <div style={ellipsisStyle}>
                    <a onClick={e => showEditTeamMember(teamMember)}>
                      {teamMember.name || teamMember.email}
                    </a>
                  </div>
                  <div style={{ textAlign: "center", ...ellipsisStyle }}>
                    ${teamMember.usedBudget}/mo
                  </div>
                  {/* is team owner */}
                  {teamMember.id == loggedInUser.id ? (
                    <span style={{ textAlign: "center" }}>∞</span>
                  ) : (
                    <div style={{ textAlign: "center", ...ellipsisStyle }}>
                      ${teamMember.availableBudget}
                      /mo
                    </div>
                  )}

                  <div style={{ textAlign: "center", ...ellipsisStyle }}>
                    {!teamMember.verified ? (
                      <span className="text-warning">(invite pending)</span>
                    ) : null}{" "}
                  </div>
                </div>
              );
            })}
          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              paddingTop: 40,
              borderTop: "1px solid #eee"
            }}
          >
            Monthly Total
            {userIsBillingAnnually(loggedInUser)
              ? " (billed annually)"
              : ""}:{" "}
            <strong>${calculateTeamTotalPrice(loggedInUser)}/mo</strong>
            <p
              style={{
                color: "#aaa",
                margin: 10
              }}
            >
              Credits not reflected. View upcoming invoice on your{" "}
              <Link
                style={{ color: "#aaa", textDecoration: "underline" }}
                to="/billing"
              >
                billing page
              </Link>
            </p>
          </div>
        </div>
      )}
    </Card>
  );
};
