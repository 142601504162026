import React, { Component } from "react";
import FooterMessage from "./FooterMessage";
import rocketIcon from "./images/rocketIcon.png";
import { InputButtonSet } from "./MinForm";
import nprogress from "nprogress";
import cookie from "js-cookie";
import Card from "./Card";
import { withRouter } from "react-router-dom";
import { message as antMessage } from "antd";
import {
  mailbotsAdminBrowser as mailbotsClient,
  getAccessToken
} from "./lib/utils";

class DevRequestInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devInvite: cookie.get("devInvite") || null,
      alreadyHasAccessCode: cookie.get("devInvite")
    };
  }

  handleOnChange = e => {
    e.preventDefault();
    console.log(e.target.value);
    console.log(e.target.name);
    this.setState({
      devInvite: e.target.value
    });
  };

  hanldeOnSubmit = async e => {
    try {
      e.preventDefault();
      nprogress.start();
      const requestParams = {
        user: {
          gopher_dev: true,
          key: this.state.devInvite
        }
      };
      console.log(requestParams);
      await mailbotsClient.userUpdateSelf(requestParams);

      // Update global user state and pull fresh user
      const refreshedUser = await this.props.reloadUser({
        accessToken: getAccessToken()
      });

      nprogress.done();
      cookie.remove("devInvite");
      if (refreshedUser.gopher_dev !== true) {
        antMessage.error(
          "Sorry, that access code isn't working. Feel free to contact us if you need any help: help+humans@fut.io"
        );
      } else {
        antMessage.success(
          "Welcome aboard! If you have any questions or need any help contact us!"
        );
      }
    } catch (e) {
      cookie.remove("devInvite");
      nprogress.done();
      antMessage.error(e.message);
    }
  };

  render() {
    return (
      <div>
        {!this.state.alreadyHasAccessCode && (
          <FooterMessage style={{ border: 0, textAlign: "center" }}>
            <img
              style={{
                opacity: 0.5,
                width: 50,
                margin: 10
              }}
              src={rocketIcon}
            />
            <h2 className="serif">Request Access</h2>
            <p>We'd love your input. Take a quick survey below.</p>
            <br />
            <p>
              <a
                className="btn btn-secondary"
                href="https://rsweetland1.typeform.com/to/amsj7O"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developer Survey
              </a>
              &nbsp; &nbsp;
              {/* <a
                className="btn btn-secondary"
                href="https://fut.readme.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a> */}
            </p>
          </FooterMessage>
        )}

        <FooterMessage>
          {this.state.alreadyHasAccessCode ? (
            <div>
              <h3>Welcome!</h3>
              <p>We noticed you already have an access code.</p>
            </div>
          ) : (
            <p>Already have an access code?</p>
          )}
          <InputButtonSet
            placeholder="Enter access code"
            buttonBackgroundColor="#999"
            onChange={this.handleOnChange}
            onSubmit={this.hanldeOnSubmit}
            name="devInvite"
            value={this.state.devInvite}
            buttonContent={
              <span
                className="glyphicon glyphicon-arrow-right"
                style={{
                  width: 20,
                  color: "#white"
                }}
              />
            }
          />
          <p className="text-muted">
            Having trouble? Email us:{" "}
            <a href="mailto:help@humans.fut.io">help@humans.fut.io</a>
          </p>
          {/* <hr style={{ margin: "100px 0", color: "#e6e6e6" }} /> */}
        </FooterMessage>
      </div>
    );
  }
}

export default withRouter(DevRequestInvite);
