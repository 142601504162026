import $ from "jquery";

class GlitchHelper {
  constructor(mailbot) {
    if (!mailbot) throw new Error("No MailBot  provided");
    this.mailbot = mailbot;

    //config
    this.GLITCH_DEFAULT_PJT = "https://glitch.com/edit/#!/remix/fut-skill-hi5";
    this.GLITCH_ENV = {
      CLIENT_ID: this.mailbot.oauth2_client_id || "",
      CLIENT_SECRET: this.mailbot.oauth2_client_secret || "",
      SCOPE:
        `"${this.mailbot.oauth2_scope}"` ||
        "get_user_info extension_manage_self read_own_tasks manage_own_tasks",
      MAILBOT_ID: `"${this.mailbot.id}"` || "",
      MAIL_DOMAIN: `"${this.mailbot.subdomain}.eml.bot"` || "", //MailBots can have their own domains
      MAILBOT_SUBDOMAIN: `"${this.mailbot.subdomain}"` || "",
      PJT_CREATED: Date.now() // So we can tell mailbot to stop hitting /extensins/init endpoint
    };

    switch (process.env.REACT_APP_NODE_ENV) {
      case "dev":
        this.GLITCH_ENV = {
          ...this.GLITCH_ENV,
          // TODO: move to .env.local
          API_HOST: "https://fut-reilly-22.mailbots.com/",
          MAILBOTS_ADMIN: "http://localhost:3000/"
        };
        break;
      case "staging":
        this.GLITCH_ENV = {
          ...this.GLITCH_ENV,
          // TODO: move to .env.local
          API_HOST: "http://staging1.www.fut.io",
          MAILBOTS_ADMIN: "http://localhost:3000/"
        };
        break;
      default:
        // nothing
        break;
    }
  }

  // Experimental function to programatically create Glitch pjt: https://support.glitch.com/t/can-a-platform-programmatically-get-a-users-glitch-url/3502
  // createGlitchProject = () => {
  //   return $.ajax({
  //     type: "POST",
  //     url: "https://api.glitch.com/projects/mailbots-express/remix",
  //     data: { env: this.GLITCH_ENV },
  //     contentType: "application/json; charset=utf-8",
  //     dataType: "json"
  //   });
  // };

  // eslint-disable-next-line
  getRemixUrl = baseUrl => {
    const glitchBaseUrl = baseUrl || this.GLITCH_DEFAULT_PJT;
    return glitchBaseUrl + "?" + $.param(this.GLITCH_ENV);
  };

  getGlitchSubdomain = glitchUrl => {
    const startSplit = glitchUrl.indexOf("://") + 3;
    const endSplit = glitchUrl.indexOf(".glitch.me");
    const glitchSubdomain = glitchUrl.substring(startSplit, endSplit);
    return glitchSubdomain;
  };

  getEditUrl = () => {
    if (!this.mailbot) return;
    const glitchSubdomain = this.getGlitchSubdomain(this.mailbot.base_url);
    return "https://glitch.com/edit/#!/" + glitchSubdomain;
  };

  getIframeEmbedUrl = () => {
    if (!this.mailbot) return;
    const glitchSubdomain = this.getGlitchSubdomain(this.mailbot.base_url);
    return `https://glitch.com/embed/#!/embed/${glitchSubdomain}?path=app.js&previewSize=0&sidebarCollapsed=true`;
  };

  isGlitch = () => {
    if (!this.mailbot) return;
    return this.mailbot.base_url.indexOf("glitch") > -1;
  };
}

export default GlitchHelper;
