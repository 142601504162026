import React, { Component, createRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { mailbotsAdminBrowser } from "../lib/utils";
import { message as antMessage, Tooltip } from "antd";
import queryString from "query-string";
import _ from "lodash";
import NavLeft from "../NavLeft";
import Loading from "../Loading";
import nProgress from "nprogress";
import FilterLink from "../FilterLink";
import Card from "../Card";
import ModalSimple from "../ModalSimple";
import { SearchBox } from "../SearchBox";
import {
  getPersonAttribute,
  getPersonName,
  getAllPersonEmails,
  getPersonEmail
} from "../lib/peopleUtils";
import { BulkActions } from "../BulkActions";
import TagModal from "./TagModal";
import CreateFutModal from "../Tasks/CreateFutModal";
import FooterMessage from "../FooterMessage";
import FilterByTag from "./FilterByTag";
import FilterByNextFollowup from "./FilterByNextFollowup";
import FilterByTotalFollowups from "./FilterByTotalFollowups";
import MergeModal from "./MergeModal";
import ImportCsvModal from "./ImportCsvModal";
import { CSVLink } from "react-csv";
import { UpgradeForPeople } from "../Billing/UpgradeDialogs";
import {
  isOnLegacyPlan,
  isOnPersonalAstPlan,
  isPaying,
  userPaidForSkill
} from "../Billing/billingHelpers";
import { logger } from "../lib/Logger";
import * as neatCsv from "neat-csv";
import { notification } from "antd";
import { ButtonOnBg } from "../ButtonOnBg";
import { isOnMobile } from "../lib/hookisMobile";
import {
  DownloadOutlined,
  ExportOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  SendOutlined,
  TagOutlined
} from "@ant-design/icons";

class People extends Component {
  modalPreRunDone;

  constructor(props) {
    super(props);
    this.PEOPLE_LIST_INCREMENT_LENGTH = 100;
    this.state = {
      people: null,
      peopleFilter: [],
      allPeopleAttributes: [], // list of all possible searchable attributes
      peopleListLength: this.PEOPLE_LIST_INCREMENT_LENGTH, // naive pagination @todo: properly paginate with offset + limit
      searchText: "",
      showAddPeopleDialog: false,
      showCreateFutModal: false,
      showImportCsvModal: false,
      createFutRelatedPeople: [],
      showTagsModal: false,
      showMergeModal: false,
      bulkActionIsRunning: false
    };
  }

  /**
   * Note: "search" applies to url query. (see above)
   */
  componentDidMount = async () => {
    try {
      const peopleFilter = this.getPeopleFilterFromUrl();
      this.updateSearchBoxFromFilter(peopleFilter);
      nProgress.start();
      await this.loadPeople(peopleFilter);
      await this.loadAllAttributes();
      nProgress.done();
      logger.log("people screen shown");
      logger.log("user viewed people");
    } catch (e) {
      antMessage.error(e.message);
      console.error(e);
      nProgress.done();
    }
  };

  /**
   * Note: The the "search" variable confusingly applies
   * to he query param of the URL here, not the search
   * text of the input box or fitler.
   */
  UNSAFE_componentWillReceiveProps = nextProps => {
    const search = this.props.location.search;
    const newSearch = nextProps.location.search;
    if (newSearch !== search) {
      const peopleFilter = this.getPeopleFilterFromUrl();
      this.updateSearchBoxFromFilter(peopleFilter);
      this.setState({ peopleFilter });
      this.loadPeople(peopleFilter);
    }
  };

  /**
   * This component treats the URL as the single source of truth. Actions first
   * change the URL, which fires componentWillReceiveProps, which calls this method.
   */
  getPeopleFilterFromUrl = () => {
    const qs = queryString.parse(window.location.search);
    const encodedFilter = qs["peopleFilter"];
    try {
      const peopleFilter = JSON.parse(decodeURIComponent(encodedFilter));
      return peopleFilter;
    } catch (e) {
      return null;
    }
  };

  // persist JSON encoded filter object in URL
  setPeopleFilterInUrl = filterJson => {
    // remove url param for empty filter
    if (!filterJson || !filterJson.filter || !filterJson.filter.length) {
      this.props.history.push("/people"); // @todo: It leaves query param null
      return;
    }
    let encodedFilter;
    try {
      encodedFilter = encodeURIComponent(JSON.stringify(filterJson));
      this.props.history.push(`?peopleFilter=${encodedFilter}`);
    } catch (e) {
      return null;
    }
  };

  applyFilter = (e, filter = {}) => {
    if (e) e.preventDefault();
    let newFilter = { ...this.state.peopleFilter, ...filter };
    console.log("loading people with filter", newFilter);
    this.loadPeople(newFilter);
    this.setState({ peopleFilter: newFilter });
    this.setPeopleFilterInUrl(newFilter);
    // changing URL fires componentDidMount, thus reloading people
    logger.log("user is filtering people");
  };

  /**
   * @todo
   * - When removing all text in search box, reset filter url
   * - Preserve existing tag filters when removing
   * - Extract the below into "removeOneFilter(filterObj)"
   * - Make clean url reset if there are no filteres
   */
  handleSearchOnChange = e => {
    const searchText = e.target.value || null;
    this.setState({ searchText });
    // if search empty, remove the "full_email" attribute from url param
    if (!searchText) {
      const peopleFilter = this.getPeopleFilterFromUrl() || {};
      let newFilter;
      if (Array.isArray(peopleFilter.filter)) {
        newFilter = peopleFilter.filter.filter(
          a => a.attribute !== "full_email"
        );
      }
      const newPeopleFilter =
        newFilter && newFilter.length ? { filter: newFilter } : null;
      this.setPeopleFilterInUrl(newPeopleFilter);
    }
  };

  /**
   * Searching tokens roughly works like this:
   * - Split on spaces.
   * - Filter for "filterPhrases" – tokens like key:value (spaces not supported)
   * - If no no colon, we assume "nonFilterPhrase"  and just look in full_email attribute
   * @todo: This should work like Stripe: https://www.notion.so/followupthen/People-API-e69afed50fe641f1b2a7a352b7b0fd31#d8f03b6d32e7482a87a7fd5349825bfc
   *
   */
  handleSearch = e => {
    e.preventDefault();
    if (this.state.searchText) {
      const words = this.state.searchText.split(" ");

      // search non-fitler phrases
      const nonFilterPhrases = words.filter(s => !s.includes(":")); // ex: person name
      let filter = [];
      if (nonFilterPhrases.length) {
        // naive – this would combine "joe tag:foo bar" to "joe bar". Also just accounts for common query: "joe blow"
        const nameOrEmailTextQuery = nonFilterPhrases.join(" ");
        filter.push({
          attribute: "full_email",
          operator: "inc",
          value: nameOrEmailTextQuery
        });
      }

      // search filter phrases
      const filterPhrases = words.filter(s => s.includes(":")); // ex: tag:foo
      if (filterPhrases.length) {
        const allAttributeNames = this.getAllPeopleAttributeNames();
        const operatorMap = {
          ":": "inc",
          "<": "lt",
          ">": "gt",
          "=": "eq"
        };
        filterPhrases.forEach(filterPhrase => {
          const [attribute, searchPhrase] = filterPhrase.split(":");
          if (!allAttributeNames.includes(attribute)) return;
          let operator; // > < =
          let value; // search value
          // comparison operators – ex: num_futs:>3
          if (searchPhrase && searchPhrase.search(/>|<|=/) > -1) {
            const [match, opSymbol, val] =
              searchPhrase.match(/(>|<|=)(.*)/) || [];
            value = val;
            operator = operatorMap[opSymbol];
            // simple operators – ex: company:acme
          } else {
            operator = "inc";
            value = searchPhrase;
          }
          // add to filter
          filter.push({
            attribute,
            operator,
            value
          });
        });
      }

      this.applyFilter(null, { filter });
      this.setState({ isSearching: true });
      logger.log("user is searching people");
    }
  };

  // This does not take into account custom attribute filters like <, > and +
  updateSearchBoxFromFilter(fullFilter) {
    if (!fullFilter || !fullFilter.filter) return;
    const fullEmailSearch =
      fullFilter.filter.find(a => a.attribute === "full_email") || {};
    let searchText = fullEmailSearch.value || "";
    this.setState({ searchText });
  }

  loadPeople = async peopleFilter => {
    try {
      nProgress.start();
      if (!peopleFilter) peopleFilter = {};
      peopleFilter.limit = this.state.peopleListLength;
      let people = [];
      let res = await mailbotsAdminBrowser.getPeople(peopleFilter);
      people = people.concat(res.people);

      // when searching by a string, we need to perform two
      // separate searches and deduplicate results by id
      const isSearchByEmail =
        peopleFilter &&
        peopleFilter.filter &&
        Array.isArray(peopleFilter.filter) &&
        peopleFilter.filter.some(f => f.attribute === "full_email");
      if (isSearchByEmail) {
        const duplicateFilter = { ...peopleFilter };
        const emailFilter = duplicateFilter.filter.find(
          f => f.attribute === "full_email"
        );
        if (emailFilter) {
          emailFilter.attribute = "full_name";
        }

        res = await mailbotsAdminBrowser.getPeople(duplicateFilter);
        const duplicatePeople = people.concat(res.people);

        people = _.uniqBy(duplicatePeople, "id");
      }

      this.setState({ people, isSearching: false });
      nProgress.done();
    } catch (e) {
      nProgress.done();
      console.error(e);
      antMessage.error("Error:" + e.message);
    }
  };

  // Naively increases list limit for now
  loadMorePeople = () => {
    this.setState(
      {
        peopleListLength:
          this.state.peopleListLength + this.PEOPLE_LIST_INCREMENT_LENGTH
      },
      () => {
        const peopleFilter = this.getPeopleFilterFromUrl();
        this.loadPeople(peopleFilter);
      }
    );
  };

  loadAllAttributes = async () => {
    try {
      const res = await mailbotsAdminBrowser.getAllPeopleAttributes();
      this.setState({ allPeopleAttributes: res.attributes });
    } catch (e) {
      console.error(e);
      antMessage.error("Error:" + e.message);
    }
  };

  getAllPeopleAttributeNames = () => {
    if (!this.state.allPeopleAttributes) return [];
    return this.state.allPeopleAttributes.reduce((acc, curr) => {
      return acc.concat([curr.attribute]);
    }, []);
  };

  toggleAddPeopleDialog = e => {
    e.preventDefault();
    this.setState({ showAddPeopleDialog: !this.state.showAddPeopleDialog });
  };

  getSelectedPeople = () => {
    return this.state.people.filter(person => person.selected);
  };

  toggleAllPeople(selected) {
    const newPeople = this.state.people.map(person => {
      person.selected = selected;
      return person;
    });
    this.setState({ people: newPeople });
  }

  selectPersonId(id, selected) {
    const newPeople = this.state.people.map(person => {
      if (person.id === id) person.selected = selected;

      return person;
    });
    this.setState({ people: newPeople });
  }

  finishCreateFollowupPreRun;
  handleCreateFollowupPreRun = finishPreRun => {
    const people = this.getSelectedPeople();
    this.setState({
      showCreateFutModal: true,
      createFutRelatedPeople: people.map(person => getPersonEmail(person))
    });
    this.finishCreateFollowupPreRun = finishPreRun;
    logger.log("bulk people followup clicked");
  };

  handleCreateFollowupSubmit = newTask => {
    this.setState({ bulkActionIsRunning: true });
    this.finishCreateFollowupPreRun(newTask);
    logger.log("bulk people followup running");
  };

  finishTagsPreRun;
  handleTagsPreRun = finishPreRun => {
    this.setState({ showTagsModal: true });
    this.finishTagsPreRun = finishPreRun;
    logger.log("bulk people tag clicked");
  };

  handleTagsDialogSubmit = newTags => {
    this.setState({ bulkActionIsRunning: true });
    this.finishTagsPreRun(newTags);
    logger.log("bulk people tag running");
  };

  finishMergePreRun;
  handleMergePreRun = finishPreRun => {
    this.setState({ showMergeModal: true });
    this.finishMergePreRun = finishPreRun;
    logger.log("bulk people tag clicked");
  };

  handleMergeDialogSubmit = shouldMerge => {
    this.setState({ bulkActionIsRunning: true });
    this.finishMergePreRun(shouldMerge);
    logger.log("bulk people tag running");
  };

  finishImportPreRun;
  handleImportPreRun = finishPreRun => {
    this.setState({ showImportCsvModal: true });
    this.finishImportPreRun = finishPreRun;
  };
  handleImportCsvDialogSubmit = async parsedCsv => {
    try {
      this.setState({ bulkActionIsRunning: true });
      nProgress.start();
      let importErrors = [];
      let successfulImportCt = 0;

      // iterate file content and create people
      for (let personImport of parsedCsv) {
        const emails = (personImport.emails?.split(",") ?? []).map(e =>
          e.trim()
        );
        const tags = personImport.tags?.split(",") ?? [];
        try {
          await mailbotsAdminBrowser.createPerson({
            person: {
              email: emails[0],
              attributes: [
                {
                  attribute: "full_name",
                  title: "Name",
                  type: "text",
                  value: personImport.name
                },
                {
                  attribute: "emails",
                  title: "Name",
                  type: "list",
                  value: emails
                },
                {
                  attribute: "tags",
                  title: "Tags",
                  type: "global_multiselect",
                  value: tags
                }
              ]
            }
          });
          ++successfulImportCt;
        } catch (e) {
          importErrors.push(e.message);
        }
        // prevent thrashing of button as modal fades out
        setTimeout(() => this.setState({ bulkActionIsRunning: false }), 200);
      }

      // reload people list
      const peopleFilter = this.getPeopleFilterFromUrl();
      await this.loadPeople(peopleFilter);

      if (successfulImportCt)
        antMessage.success(`${successfulImportCt} people imported successfuly`);

      if (importErrors.length)
        antMessage.warn(`We ran into errors importing one or more people:
              ${importErrors.join(",")}`);

      this.setState({ showImportCsvModal: false });
    } catch (error) {
      antMessage.error(error.message);
      console.error(error);
    }

    nProgress.done();
  };

  render() {
    const selectedPeopleToCSV = () =>
      this.getSelectedPeople().map(person => ({
        id: person.id,
        name: getPersonName(person),
        emails: (getAllPersonEmails(person) || []).join(" "),
        organization: getPersonAttribute({
          key: "organization",
          personAttributes: person.attributes
        }),
        notes: (
          getPersonAttribute({
            key: "notes",
            personAttributes: person.attributes
          }) || ""
        ).replace("\n", "")
      }));

    const csvHeaders = [
      { label: "Person ID", key: "id" },
      { label: "Name", key: "name" },
      { label: "Emails", key: "emails" },
      { label: "Organization", key: "organization" },
      { label: "Notes", key: "notes" }
    ];

    const bulkActions = [
      {
        name: "Follow up",
        glyph: <SendOutlined />,
        preRun: finishPreRun => {
          this.handleCreateFollowupPreRun(finishPreRun);
        },
        bulkIterator: () => {},
        postRun: async (_errors, _warnings, people) => {
          this.setState({ showCreateFutModal: false });
        }
      },
      {
        name: "Tag",
        glyph: <TagOutlined />,
        preRun: finishPreRun => {
          this.handleTagsPreRun(finishPreRun);
        },
        bulkIterator: (person, newTags) => {
          const found = person.attributes.find(att => att.attribute === "tags");
          const existingTags = found ? found.value : [];
          const uniqueTags = [...new Set(existingTags.concat(newTags))];

          return mailbotsAdminBrowser.updatePersonTags({
            person: {
              id: person.id,
              attributes: person.attributes
            },
            newTags: uniqueTags
          });
        },
        postRun: async (errors, _warnings, people) => {
          this.setState({ showTagsModal: false });
          this.setState({ bulkActionIsRunning: false });
          if (errors.length) {
            antMessage.error("Failed to add tags to one or more people.");
          } else {
            antMessage.success(
              `Tags successfully updated tags for ${people.length} people.`
            );
          }

          const peopleFilter = this.getPeopleFilterFromUrl();
          await this.loadPeople(peopleFilter);
        }
      },
      {
        name: "Merge",
        glyph: <MergeCellsOutlined />,
        preRun: finishPreRun => {
          if (this.getSelectedPeople().length !== 2) {
            antMessage.warn("This action can only merge two people at a time");
            return finishPreRun(false);
          }
          this.handleMergePreRun(finishPreRun);
        },
        bulkIterator: async (person, preRunRes, index) => {
          const people = this.getSelectedPeople();
          if (index < people.length - 1) return;

          const { primaryPerson, secondaryPerson } = preRunRes;

          // update first person
          await mailbotsAdminBrowser.updatePerson({
            person: {
              id: primaryPerson.id,
              attributes: primaryPerson.attributes
            }
          });

          // merge people
          await mailbotsAdminBrowser.mergePeople({
            basePersonId: primaryPerson.id,
            mergePersonIds: [secondaryPerson.id]
          });

          // delete second person
          await mailbotsAdminBrowser.deletePerson(secondaryPerson.id);
        },
        postRun: async (errors, _warnings, people) => {
          this.setState({ showMergeModal: false });
          this.setState({ bulkActionIsRunning: false });
          if (errors.length) {
            antMessage.error("Failed to merge people.");
          } else {
            antMessage.success(`People merged successfuly.`);
          }

          const peopleFilter = this.getPeopleFilterFromUrl();
          await this.loadPeople(peopleFilter);
        }
      },
      {
        name: "Download (.csv)",
        glyph: <DownloadOutlined />,
        bulkIterator: () => {},
        customJsx: (props = {}) => (
          <CSVLink
            key={this.getSelectedPeople().length}
            filename="my-people.csv"
            data={selectedPeopleToCSV()}
            headers={csvHeaders}
            style={{ width: "100%" }}
            {...props}
          >
            <DownloadOutlined /> <span>Download (.csv)</span>
          </CSVLink>
        )
      }
      // Removing this in anticipation of https://github.com/mailbots/fut-core-api/issues/2860
      // the only to add people is to schedule followups with them
      // {
      //   name: "Import",
      //   glyph: "list-alt",
      //   preRun: finishPreRun => {
      //     this.handleImportPreRun(finishPreRun);
      //   },
      //   bulkIterator: () => {},
      //   customJsx: ({ action }) => (
      //     <a
      //       onClick={() => {
      //         action.preRun();
      //       }}
      //     >
      //       <span className={"glyphicon glyphicon-list-alt"}> </span>
      //       <span> Import CSV</span>
      //     </a>
      //   )
      // }
    ];

    const AddPeople = () => (
      <div style={{ position: "relative" }}>
        <a
          style={{
            color: "#11a2d2",
            padding: "30px 0px 55px 0px",
            display: "inline-block"
          }}
          onClick={this.toggleAddPeopleDialog}
        >
          {this.state.showAddPeopleDialog && (
            <ModalSimple>
              Add People. Import from CSV. Import from Address Book.
            </ModalSimple>
          )}
          + People
        </a>
      </div>
    );

    const getPeopleTags = attributes => {
      const tags = attributes.find(a => a.attribute === "tags");
      if (!tags) return [];
      return tags.value;
    };

    const FilterPeopleByTag = ({ tags = [], onApplyFilter = null }) => (
      <React.Fragment>
        <li className="subnav-header">Tags</li>
        <li>
          <FilterLink
            id="any"
            filterObj={{ filter: [] }}
            onFilter={this.applyFilter}
          >
            Any
          </FilterLink>
        </li>
        {tags.map(tag => (
          <li key={tag}>
            <FilterLink
              id="tag"
              filterObj={{
                filter: [{ attribute: "tags", operator: "inc", value: tag }]
              }}
              isActive={() => window.location.search.indexOf(tag) > -1}
              onFilter={this.applyFilter}
            >
              {tag}
            </FilterLink>
          </li>
        ))}
      </React.Fragment>
    );

    const hasPeopleAccess = () => {
      // return true; // people access allowed for all for now...
      // if (process.env.REACT_APP_BILLING_ENFORCED !== "yes") return true;
      // return isOnPersonalAstPlan(this.props.loggedInUser);
      return userPaidForSkill(this.props.loggedInUser, "p");
    };

    return (
      <div className="main-container">
        {this.state.people ? (
          <React.Fragment>
            <TagModal
              isVisible={this.state.showTagsModal}
              isBusy={this.state.bulkActionIsRunning}
              allPeopleAttributes={this.state.allPeopleAttributes}
              onCloseModal={() => this.setState({ showTagsModal: false })}
              onSubmit={this.handleTagsDialogSubmit}
            />
            <CreateFutModal
              isVisible={this.state.showCreateFutModal}
              loggedInUser={this.props.loggedInUser}
              relatedPeople={this.state.createFutRelatedPeople}
              onCloseModal={() => this.setState({ showCreateFutModal: false })}
              onStart={() => {
                nProgress.start();
              }}
              onSuccess={() => {
                nProgress.done();
                antMessage.info("We've got you covered");
              }}
              onError={e => {
                console.error(e);
                antMessage.error(
                  e.message + " Need help? Feedback? Email help@humans.fut.io"
                );
                nProgress.done();
              }}
            />

            <MergeModal
              isVisible={this.state.showMergeModal}
              isBusy={this.state.bulkActionIsRunning}
              people={this.getSelectedPeople()}
              allPeopleAttributes={this.state.allPeopleAttributes}
              onCloseModal={() => this.setState({ showMergeModal: false })}
              onSubmit={this.handleMergeDialogSubmit}
            />

            <ImportCsvModal
              isVisible={this.state.showImportCsvModal}
              isBusy={this.state.bulkActionIsRunning}
              onCloseModal={() => this.setState({ showImportCsvModal: false })}
              onSubmit={this.handleImportCsvDialogSubmit}
            />

            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 10,
                height: 35
              }}
            >
              <BulkActions
                actions={bulkActions}
                items={this.getSelectedPeople()}
                totalItemsCount={this.state.people.length}
                toggleAll={checked => this.toggleAllPeople(checked)}
              />
              {/* right-side actions */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <ButtonOnBg
                  icon="PlusOutlined"
                  text={isOnMobile() ? null : "Person"}
                  onClick={e => {
                    if (!hasPeopleAccess()) {
                      alert(
                        "Explore choose a billing plan to enable this useful feature"
                      );
                      e.preventDefault();
                      return false;
                    }
                    this.props.history.push("/people/new");
                  }}
                />
                <div style={{ position: "relative" }}>
                  <a
                    href=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    <ButtonOnBg
                      text={isOnMobile() ? `` : `Filter`}
                      icon={
                        !_.isEmpty(this.state.taskFilter)
                          ? "FilterFilled"
                          : "FilterOutlined"
                      }
                    />
                  </a>
                  <ul
                    className={`dropdown-menu ${
                      isOnMobile()
                        ? `dropdown-menu-right`
                        : `dropdown-menu-left`
                    }`}
                    style={{ padding: 15 }}
                    role="menu"
                  >
                    {this.state.allPeopleAttributes && this.state.people ? (
                      <FilterByTag
                        onApplyFilter={this.applyFilter}
                        tags={getPeopleTags(this.state.allPeopleAttributes)}
                        existingPeopleFilter={this.state.peopleFilter}
                      />
                    ) : null}
                  </ul>{" "}
                </div>
                <SearchBox
                  placeholder={`Name or Email`}
                  handleOnChange={this.handleSearchOnChange}
                  handleSearch={this.handleSearch}
                  value={this.state.searchText}
                  isSearching={this.state.isSearching}
                  style={{ maxWidth: 180, marginLeft: 4 }}
                />
              </div>
            </div>
            {!hasPeopleAccess() ? (
              <UpgradeForPeople loggedInUser={this.props.loggedInUser} />
            ) : (
              <div className="list-container" style={{ marginTop: 0 }}>
                {this.state.people.map(person => (
                  <div
                    className="people-list-item"
                    onClick={() =>
                      this.props.history.push(`/people/${person.id}`)
                    }
                    key={person.id}
                  >
                    <span style={{ padding: "0 10px 0 2px" }}>
                      <input
                        type="checkbox"
                        onClick={e => e.stopPropagation()}
                        onChange={e =>
                          this.selectPersonId(person.id, e.target.checked)
                        }
                        checked={person.selected || false}
                        style={{ transform: "scale(1.3)" }}
                      ></input>
                    </span>
                    <span style={{ color: "#333" }}>
                      {getPersonName(person)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <Loading />
        )}
        {/** If total num is evenly divisible by increment length, assume more.
           @todo when we're actually paginating, use num of last query results to show/hide this  */}
        {this.state.people &&
        this.state.people.length &&
        this.state.people.length % this.PEOPLE_LIST_INCREMENT_LENGTH === 0 ? (
          <div
            style={{
              padding: 50,
              textAlign: "center"
            }}
          >
            <a onClick={() => this.loadMorePeople()}>load more...</a>
          </div>
        ) : null}
        {hasPeopleAccess() && this.state.people && !this.state.people.length ? (
          <FooterMessage>
            <h2>People</h2>
            <p>People related to your followups appear here.</p>
          </FooterMessage>
        ) : null}
      </div>
    );
  }
}

export default withRouter(People);
