import React from "react";
import Form from "react-jsonschema-form";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import _ from "lodash";

const IFRAME_WIDGET_ALLOWED_DOMAINS = (
  process.env.REACT_APP_SETTINGS_PAGE_IFRAME_WIDGET_ALLOWED_DOMAINS || ""
).split(",");

/**
 * This is a customized renderer for Mozilla's React JSON Form Schema. It accepts their standard JSON,
 * plus some additional custom widgets. We override their single, limiting submit buttons, allowing for
 * multiple, custom submit buttons that perform different actions via URL params. Only one set of
 * custom submit buttons can exist per form
 *
 * Example settingsform.form object
 * (Note that uiSchema and JSONSchema require corresponding tokens, hence the token placeholders)

    {
    "form": {
        "JSONSchema": {
            "title": "SMS Reminders",
            "type": "object",
            "properties": {
                "mobile_phone": {
                    "type": "string",
                    "title": "Mobile Phone"
                },
                "_md_5617682580": {
                    "type": "string"
                }
            }
        },
        "uiSchema": {
            "mobile_phone": {
                "ui:emptyValue": ""
            },
            "_md_5617682580": {
                "ui:widget": "customButtonWidget",
                "ui:options": {
                    "text": "Submit",
                    "href": "",
                    "label": false,
                    "type": "submit",
                    "namespace": "sms"
                },
                "ui:emptyValue": ""
            }
        },
        "formData": {
            "mobile_phone": "4085156995"
        },
        "formMeta": {
            "menuTitle": "SMS"
        }
    },
    "namespace": "sms"
}

 *
 * @todo Extract custom URL handling from MailBotSettings if / when needed for People or other dynamic forms.
 * @param {*} param0
 *
 *
 */
export const DynamicJsonForm = ({
  settingForm,
  loggedInUser,
  handleOnSubmit,
  setUrlParams // @todo - move to lib
}) => {
  const { JSONSchema, uiSchema, formData, formMeta } = settingForm.form;

  // a mailbot can set url params in the mailbot.setting_viewed webhook response
  // the url params can later be updated by submit buttons, so URL params are handled as the last step
  // in submitting the form (in the handleOnSubmit handler)
  const preSetUrlParams = _.get(formMeta, "urlParams");
  if (preSetUrlParams && this) {
    setUrlParams({ urlParams: preSetUrlParams });
  }

  const customTextWidget = formProps => {
    const { options } = formProps;
    return (
      <ReactMarkdown
        source={options.text}
        className="markdown-container"
        plugins={[remarkBreaks]}
      />
    );
  };

  const customYouTubeEmbedWidget = formProps => {
    const { options } = formProps;
    return (
      <iframe
        src={options.url}
        scrolling="no"
        className="embed-responsive-item"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    );
  };

  const customIframeEmbedWidget = formProps => {
    const { options } = formProps;
    const url = options.url;

    // check if the url is from an allowed domain
    if (
      !IFRAME_WIDGET_ALLOWED_DOMAINS.some(allowedDomain =>
        url.startsWith(allowedDomain)
      )
    ) {
      // if this is not an allowed URL, render an empty div
      return (
        <div>This URL {url} is not allowed to be embedded in an iframe</div>
      );
    }

    return (
      <iframe
        src={url}
        scrolling="yes"
        className="embed-responsive-item"
        sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox"
      />
    );
  };

  // To submit the form using custom submit buttons, we need to store DOM ref to current settins form
  // DOM Refs to settings forms, with their namespace as key (set via JSON form schema refs)
  // Do not update ref if it's already been stored (working around weird bug where refs were being lost)
  let settingsFormDom;
  const storeSettingsFormRef = form => {
    if (form === null) return; // https://stackoverflow.com/questions/38401879/react-losing-ref-values
    settingsFormDom = form;
  };

  // shared method for rendering custom buttons (alert, customButton widget, etc can share)
  const _renderCustomButton = options => {
    const attr = {};
    if (options.href) attr.href = options.href;
    if (options.style) attr.style = options.style;
    if (options.type === "submit") {
      attr.onClick = e => {
        e.target.text = "...Saving";
        e.target.style.opacity = 0.6;
        // if URL params are updated, replace existing URL params with the new ones.
        if (options.urlParams) {
          setUrlParams({
            urlParams: options.urlParams,
            updateQS: true
          }); // updating triggers rerender and breaks refs to the form
        }
        settingsFormDom.submit();
      };
    }

    attr.className = options.className || "btn btn-primary btn-mailbots";
    return (
      <a key={options.text} {...attr}>
        {options.text}
      </a>
    );
  };

  const customButtonWidget = formProps => {
    const { options } = formProps;
    options.style = { marginTop: 27 };
    return _renderCustomButton(options);
  };

  const customAlertWidget = ({ options }) => (
    <div className={`jumbotron`} style={{ padding: 40, paddingBottom: 80 }}>
      <h2>{options.title}</h2>
      {options.text && (
        <ReactMarkdown
          source={options.text}
          className="markdown-container"
          plugins={[remarkBreaks]}
        />
      )}
      {options.buttons &&
        options.buttons
          .map(button => {
            button.namespace = options.namespace;
            return button;
          })
          .map(button => _renderCustomButton(button))}
    </div>
  );
  const formWidgets = {
    customAlertWidget,
    customTextWidget,
    customYouTubeEmbedWidget,
    customButtonWidget,
    customIframeEmbedWidget
  };

  const CustomDescriptionField = ({ id, description }) => {
    return (
      <ReactMarkdown
        source={description}
        className="field-description"
        plugins={[remarkBreaks]}
      />
    );
  };

  const formFields = {
    DescriptionField: CustomDescriptionField
  };

  return (
    /** NOTE: If / when we reuse our customized form in another part of the app task settings,
     * user attributes, admin UI, etc, create a HOC that wraps our custom UI and widgets and that exports
     * a simple <Form .. /> element. https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/#customizing-the-default-fields-and-widgets
     */
    <div key={settingForm.namespace}>
      <Form
        key={settingForm.namespace}
        uiSchema={uiSchema}
        loggedInUser={loggedInUser}
        schema={JSONSchema}
        formData={formData}
        widgets={formWidgets}
        fields={formFields}
        onSubmit={({ formData }) =>
          handleOnSubmit(settingForm.namespace, formData)
        }
        onError={console.error}
        // react-json-form must have one and only one submit button. By contrast, our settings
        // pages can have between zero and several submit buttons (one-click links to take action).
        // To work around this we have a useless submit button always hidden with css and a custom button
        // element that can optionally submit the form at any time. The button keeps track of which
        // form its submitting. See the below method for quirks on how this ref is stored.
        noValidate={true}
        ref={form => {
          storeSettingsFormRef(form);
        }}
      >
        <button type="submit" style={{ display: "none" }} />
      </Form>
    </div>
  );
};
