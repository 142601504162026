import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import _ from "lodash";
import moment from "moment";
import nprogress from "nprogress";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { message as antMessage } from "antd";
import { GlobalContext } from "./App";
import { isPaying } from "./Billing/billingHelpers";
import { BlueCallout } from "./Layout";
import { logger } from "./lib/Logger";
import { mailbotsAdminBrowser } from "./lib/utils";
import { SelectTimezone } from "./SelectTimezone";
import { ManageEmails } from "./Settings/ManageEmails";

export const GeneralSettings = () => {
  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const [settings, setSettings] = useState({});
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const HR_12_FORMAT = {
    js: "ddd, D MMMM YYYY h:mma",
    php: "D, F jS Y g:ia T"
  };
  const HR_24_FORMAT = {
    js: "ddd, D MMMM YYYY H:mm",
    php: "D, F jS Y H:i T"
  };

  // settings are just the values of loggedInUser
  useEffect(() => {
    if (loggedInUser) setSettings(loggedInUser);
    console.log({ loggedInUser }, { settings });
  }, [loggedInUser]);

  useEffect(() => {
    const loadEmailAddresses = async () => {
      nprogress.start();
      try {
        const res = await mailbotsAdminBrowser.getEmailAddresses();
        if (res.status !== "success" || !(res.email_addresses instanceof Array))
          throw Error(
            res.message || "Something went wrong loading email addresses"
          );
        setEmailAddresses(res.email_addresses.reverse());
        nprogress.done();
      } catch (e) {
        antMessage.error(e.message);
        nprogress.done();
      }
    };
    loadEmailAddresses();
  }, [loggedInUser]);

  const handleOnChange = event => {
    // event.preventDefault();
    let type = event.target.type;
    let key = event.target.name;
    let value = type === "checkbox" ? event.target.checked : event.target.value;
    setSettings({ ...settings, [key]: value });
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      nprogress.start();

      let newSettings = {
        name: settings.name,
        timezone: settings.timezone,
        default_daily_reminder_time:
          settings.default_daily_reminder_time || "6",
        action_email_handler: settings.action_email_handler,
        privacy_mode: settings.privacy_mode
      };

      switch (settings.preferred_date_format_js) {
        case HR_24_FORMAT.js:
          newSettings.preferred_date_format = HR_24_FORMAT.php;
          newSettings.preferred_date_format_js = HR_24_FORMAT.js;
          break;
        case HR_12_FORMAT.js:
          //12 hr
          newSettings.preferred_date_format_js = HR_12_FORMAT.js;
          newSettings.preferred_date_format = HR_12_FORMAT.php;
          break;
        default:
          logger.log("unexpected date format setting", {
            level: "error",
            data: { setting_str: settings.preferred_date_format_js }
          });
          //Fall back to 12 hr
          newSettings.preferred_date_format_js = HR_12_FORMAT.js;
          newSettings.preferred_date_format = HR_12_FORMAT.php;
      }

      if (settings.password) {
        if (settings.password !== settings.password_confirm) {
          throw new Error("Passwords do not match. Please try again.");
        } else {
          newSettings.password = settings.password;
        }
        logger.log("settings password changed");
      }

      await mailbotsAdminBrowser.userUpdateSelf({ user: newSettings });
      await reloadUser();
      antMessage.success("Settings saved!");

      logger.log("general settings saved");
    } catch (e) {
      logger.log("error saving general settings", {
        data: { error: e.message }
      });
      antMessage.error(e.message);
    }
    nprogress.done();
  };

  return (
    <div>
      <h2>General Settings</h2>
      <div className="form-group">
        <label htmlFor="name" className="control-label">
          Name
        </label>
        <input
          name="name"
          type="text"
          onChange={handleOnChange}
          value={settings.name || ""}
          className="form-control"
          id="name"
          placeholder="Your name"
          required
        />
      </div>

      <br />
      <ManageEmails emailAddresses={emailAddresses} />
      <br />

      <div className="form-group">
        <label htmlFor="timezone" className="control-label">
          Time Zone
        </label>
        {!settings.timezone ? (
          <BlueCallout style={{ marginLeft: 0 }}>
            ⚠️ Set your timezone for accurate timing.
          </BlueCallout>
        ) : null}
        <SelectTimezone
          onChange={handleOnChange}
          name="timezone"
          value={settings.timezone || "GMT"}
          className="mailbots-data"
        />
      </div>

      <div className="form-group">
        <label htmlFor="select" className="control-label">
          Preferred Date Format
        </label>
        <select
          name="preferred_date_format_js"
          className="form-control"
          value={settings.preferred_date_format_js}
          onChange={handleOnChange}
          id="preferred_date_format"
        >
          <option value={HR_12_FORMAT.js}>
            12 Hour ({moment().format(HR_12_FORMAT.js)})
          </option>
          <option value={HR_24_FORMAT.js}>
            24 Hour ({moment().format(HR_24_FORMAT.js)})
          </option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="select" className="control-label">
          Default Reminder Time{" "}
          <Popover
            content={
              <div style={{ maxWidth: 500 }}>
                <p>
                  When date formats do not use a time of day (ex:{" "}
                  <strong>tuesday@fut.io</strong>), we will default to this.
                  Note that this does not apply to <em>relative</em> time
                  formats (ex: 1week@fut.io) which add an exact amount of time
                  relative to now.
                </p>
                <a
                  href="https://help.followupthen.com/knowledge-base/absolute-vs-relative-time-formats/"
                  target="_blank"
                  rel="follower noreferrer"
                >
                  Read more
                </a>
              </div>
            }
          >
            <QuestionCircleOutlined />
          </Popover>
        </label>
        <select
          name="default_daily_reminder_time"
          className="form-control"
          value={settings.default_daily_reminder_time}
          onChange={handleOnChange}
          id="default_daily_reminder_time"
        >
          {_.range(0, 24).map(hour => {
            // display value in 12 / 24 hr time
            let displayDate;
            if (
              settings.preferred_date_format_js &&
              settings.preferred_date_format_js.includes("H:mm") // 24 hour time
            ) {
              displayDate = hour + ":00";
            } else {
              const to12Hr = hr24 => {
                switch (hr24) {
                  case 0:
                    return "midnight";
                  case 12:
                    return "12pm";
                  default:
                    return hr24 < 12 ? hr24 + "am" : hr24 - 12 + "pm";
                }
              };

              displayDate = to12Hr(hour);
            }

            // populate which the user has selected
            const currentVal = settings.default_daily_reminder_time || "6";

            // render
            return (
              <option key={hour} value={hour}>
                {displayDate}
              </option>
            );
          })}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="select" className="control-label">
          Email-Based Actions
        </label>{" "}
        <Popover
          content={
            <div style={{ maxWidth: 500 }}>
              <img
                src="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
                style={{ width: 250, float: "right" }}
              />
              <p>
                "Actions” are the buttons in your emails that that get things
                done, for example, postponing a followup.
              </p>
              <p>
                Email-Based Actions (shown here), may require that you configure
                your device to open the proper email program when these links
                are clicked.
              </p>
              <a
                href="https://help.followupthen.com/knowledge-base/email-base-actions-troubleshooting-and-faq/"
                target="_blank"
                rel="follower noreferrer"
              >
                Read more
              </a>
            </div>
          }
        >
          <QuestionCircleOutlined />
        </Popover>
        {(process.env.REACT_APP_BILLING_ENFORCED && isPaying(loggedInUser)) ||
        settings.action_email_handler === "mailto" ? (
          <select
            name="action_email_handler"
            className="form-control"
            value={settings.action_email_handler || ""}
            onChange={handleOnChange}
            id="action_email_handler"
          >
            <option value="mailto">Enable Email-Based Actions</option>
            <option value="url">Handle Action via URL</option>
          </select>
        ) : (
          <div>
            To enable email-based actions,{" "}
            <Link to="/skills?upgradeOptions">upgrade your account 🚀</Link>
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Privacy Mode</label>{" "}
        <Popover
          content={
            <p style={{ maxWidth: 500 }}>
              In privacy mode, FollowUpThen discards email message bodies and
              attachments when new reminders are scheduled. These followups
              still arrive at the scheduled time, but only with the subject and
              headers.{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/privacy-mode/"
                target="_blank"
                rel="follower noreferrer"
                data-toggle="tooltip"
              >
                Read more
              </a>
            </p>
          }
        >
          <QuestionCircleOutlined />
        </Popover>
        <div className="form-group">
          <input
            type="checkbox"
            name="privacy_mode"
            checked={!!settings.privacy_mode}
            onChange={handleOnChange}
            id="privacy_mode"
            style={{ margin: 5, display: "inline" }}
          />
          <label
            style={{ fontWeight: "normal", display: "inline" }}
            htmlFor="privacy_mode"
          >
            Do not store message bodies or attachments{" "}
          </label>
        </div>
      </div>

      {changePasswordVisible ? (
        <div className="form-group">
          <label htmlFor="select" className="control-label ">
            Change Password
          </label>
          <input
            name="password"
            type="password"
            onChange={handleOnChange}
            value={settings.password || ""}
            className="form-control"
            id="password"
            placeholder="New password"
          />
          <br />
          <input
            name="password_confirm"
            type="password"
            onChange={handleOnChange}
            value={settings.password_confirm || ""}
            className="form-control"
            id="password_confirm"
            placeholder="Confirm password"
          />
        </div>
      ) : (
        <div>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              setChangePasswordVisible(!changePasswordVisible);
            }}
          >
            Change Password
          </a>
        </div>
      )}
      <br />

      <button
        type="submit"
        style={{ marginTop: 27 }}
        className="btn btn-mailbots btn-primary"
        onClick={handleSubmit}
      >
        Save
      </button>
      {/* <a
        style={{
          color: "#aaa",
          position: "absolute",
          bottom: 72,
          right: 10,
          width: 200
        }}
        onClick={e => {
          e.persist();
          e.target.text = "...migrating";
          mailbotsAdminBrowser.userMigrateToV2({
            statusUpdateCb: migrationStatus => {
              const total =
                parseInt(migrationStatus.v3) + parseInt(migrationStatus.v2);
              e.target.text = `...migrating ${migrationStatus.v2} / ${total}`;
            },
            doneCb: () => {
              window.alert(
                "Migration done. Please go www.followupthen.com and login"
              );
              e.target.text = "Migration done";
            }
          });
        }}
      >
        Migrate back to v2
      </a> */}
      <div style={{ height: 20 }} />
    </div>
  );
};
