import React, { Component } from "react";
import cookie from "js-cookie";
import "./style.css";
import "../node_modules/nprogress/nprogress.css";
import "../node_modules/nprogress/nprogress.js";
import { mailbotsAdminBrowser, setAccessToken } from "./lib/utils";
import queryString from "query-string";
import { message as antMessage } from "antd";
import { withRouter } from "react-router-dom";
import nprogress from "nprogress";
import Loading from "./Loading";
import { logger } from "./lib/Logger";

class getAuthToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      loading: true
    };
  }

  // Handle redirect logic
  async handleRedirect() {
    const params = queryString.parse(window.location.search);

    // If we're mid-merging accounts, pause automatic redirects
    // They will be picked up after new emails are confirmed
    if (
      window.location.pathname.startsWith("/merge-accounts") ||
      window.location.pathname.startsWith("/settings")
    ) {
      return false;
    }

    let redirect;
    if (cookie.get("mailbotsRedirect")) {
      redirect = cookie.get("mailbotsRedirect");
      cookie.remove("mailbotsRedirect");
    } else if (params.redirect) {
      redirect = params.redirect;
    } else {
      redirect = "/skills";
    }

    async function noMailBotsInstalled() {
      try {
        nprogress.start();
        const extRes = await mailbotsAdminBrowser.getMailBots({ installed: 1 });
        nprogress.done();
        return (
          !Array.isArray(extRes.mailbots) ||
          (Array.isArray(extRes.mailbots) && extRes.mailbots.length === 0)
        );
      } catch (e) {
        nprogress.done();
        console.log(e);
        antMessage.error(e.message);
      }
    }

    // If they are a new user, show welcome dialog.
    // if (await noMailBotsInstalled()) {
    //   redirect += "?welcome=1";
    // }

    // Verify redirect is to a trusted url
    // TODO: Should there by any URL redirect protection?
    // if (
    //   (!redirect.includes("mailbots") && !redirect.includes("register")) ||
    //   redirect.includes("//")
    // ) {
    //   console.error("Redirect to an unauthorized page detected: ", redirect);
    //   return;
    // }

    this.props.history.push(redirect);
    return;
  }

  sleep = ms => setTimeout(Promise.resolve, ms);

  async componentDidMount() {
    const params = queryString.parse(window.location.search);
    const code = params.code;
    const error = params.error_description;
    const redirect = params.redirect;
    if (redirect) cookie.set("mailbotsRedirect", redirect); // Invoked in GetAuthToken.js

    if (error) return this.setState({ error });
    try {
      nprogress.start();
      const res = await mailbotsAdminBrowser.getAccessToken(code);
      nprogress.done();
      const userRes = await this.props.reloadUser({
        accessToken: res.mailbotsToken
      });
      this.handleRedirect();
    } catch (err) {
      logger.log("/auth/callback error", {
        level: "warn",
        data: { error_str: err.message }
      });
      nprogress.done();
      this.setState({
        error: `⚠️ Sorry, something went wrong while logging you in (${err.message}). Please try again. If this keeps happing, contact help@humans.fut.io for help.`
      });
      console.log(err);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ padding: 30 }}>
          <div className="alert alert-grey">{this.state.error}</div>
        </div>
      );
    } else return <Loading />;
  }
}

export default withRouter(getAuthToken);
