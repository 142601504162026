import React, { Component } from "react";
import { mailbotsAdminBrowser } from "./lib/utils";
import Emails from "./Emails";
import Logs from "./Logs";
import { HashLink as Link } from "react-router-hash-link";
import { withRouter } from "react-router-dom";
import GlitchHelper from "./lib/GlitchHelper";
import { message as antMessage } from "antd";
import MailBotDevInfoPanel from "./MailBotDevInfoPanel";
import $ from "jquery";
import nprogress from "nprogress";
import DocsLink from "./DocsLink";
import Emulator from "./Emulator";
import Modal from "./Modal";
import Card from "./Card";
import queryString from "query-string";

class Sandbox extends Component {
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      tasks: [],
      mailbot: null,
      broadcastEventData: {
        type: "",
        payload: "",
        task_hash: ""
      },
      logsUpdateTimestamp: d.getTime(),
      emulatorEmail: {
        to: "",
        cc: "",
        bcc: "",
        from: "",
        subject: "",
        html: ""
      },
      showExt404: false,
      showEmbeddedGlitchIDE: false
    };
  }

  welcomeDev = () => {
    const qs = queryString.parse(window.location.search);
    if (qs.welcome_dev) {
      this.setState({
        showWelcomeDevModal: true
      });
    }
  };

  closeSettingUpMailBotModal = () => {
    this.setState({
      showWelcomeDevModal: false
    });
  };

  async componentDidMount() {
    try {
      this.welcomeDev();
      if (!this.props.match.params.mailbotId)
        throw new Error("Please choose a MailBot  to work in the Sandbox");

      // Sandbox always interacts with the FUT MailBot, however, the Skill Mailabot is loaded to show
      // a banner of the skill MailBot's state – installed, not found, error
      let skillMailBot;
      try {
        const skillMailBotId = this.props.match.params.mailbotId;
        const resSkillMailBot = await mailbotsAdminBrowser.getMailBot({
          mailbotid: skillMailBotId
        });
        skillMailBot = resSkillMailBot.mailbot;
      } catch (e) {
        // gracefully handle if user does not have permission to view this mailbot
        console.error(e);
        this.setState({ showExt404: true });
      }

      const futMailBotId = process.env.REACT_APP_FUT_MAILBOT_ID;
      const resFutMailBot = await mailbotsAdminBrowser.getMailBot({
        mailbotid: futMailBotId
      });
      let futMailBot = resFutMailBot.mailbot;

      if (skillMailBot) {
        this.glitch = new GlitchHelper(skillMailBot);
        skillMailBot = this.populateEmptyTestEmail(skillMailBot, futMailBot);
        document.title = `Skill: ${skillMailBot.name}`;
      }

      if (this.glitch.isGlitch())
        this.setState({ showEmbeddedGlitchIDE: true });

      this.setState({ skillMailBot, futMailBot });
    } catch (err) {
      antMessage.error(err.message || err);
      console.log(err);
    }
  }

  componentWillUnmount = () => {
    // TODO: Set titles for various pages
    document.title = "MailBots Sandbox";
  };

  // If there are no autocomple addresses, create one
  populateEmptyTestEmail(skillMailBot, futMailBot) {
    let newSkillMailBot = skillMailBot;
    if (!skillMailBot.autocomplete_examples) {
      newSkillMailBot.autocomplete_examples = `1min-${skillMailBot.subdomain}@followupthen.com`;
      return newSkillMailBot;
    } else {
      return skillMailBot;
    }
  }

  refreshLogs = () => {
    this.logs.refreshLogs();
  };

  updateTasks = tasks => {
    if (!tasks instanceof Array || tasks.length === 0) {
      //prettier-ignore
      return antMessage.info("No MailBot tasks were created. Did you include a MailBot email address? Only your MailBot can send emails, not the emulator itself. No emails were sent.");
    }
    this.setState({ tasks });
    // triggered tasks don't have a source attribute
    // TODO: Test with other event types
    if (tasks[0].source) {
      this.handlePopulateEmulator(tasks[0].source); // the source email may have created multiple tasks, but it's still the same email
    }
  };

  onMailtoSendSuccess = tasks => {
    this.updateTasks(tasks);
    this.refreshLogs();
  };

  /**
   *
   * MailBot  Triggering
   *
   */
  handleBrodcastEventFormOnChange = e => {
    e.preventDefault();
    // only text input
    const name = e.target.name;
    const val = e.target.value;
    if (!e.target.name) throw new Error("Field name not provided");
    this.setState(state => {
      state.broadcastEventData[name] = val;
      return state;
    });
  };

  // Handle form UI
  handleBroadcastEvent = async e => {
    e.preventDefault();
    if (!this.state.skillMailbot.event_url)
      return antMessage.error(
        "Extension event_url missing. Please make sure it's installed."
      );
    const data = {
      type: this.state.broadcastEventData.type,
      payload: this.state.broadcastEventData.payload || "{}",
      event_url: this.state.skillMailbot.event_url
    };
    return this.broadcastEvent(data);
  };

  // General function to pass into other components
  broadcastEvent = async ({ type, payload, event_url } = {}) => {
    // TODO: (@radu) broadcastEvent is being called twice..why?
    if (!type || !payload || !event_url)
      return console.error("Broadcast event missing required parameters");

    let parsedPayload;
    try {
      if (typeof payload === "object") {
        parsedPayload = payload;
      } else {
        parsedPayload = JSON.parse(payload);
      }
    } catch (e) {
      console.log(payload);
      antMessage.error(`Event payload must be valid JSON \n\n ${e.message}`);
      return;
    }

    try {
      nprogress.start();
      let res = await mailbotsAdminBrowser.sendEvent({
        type,
        payload: parsedPayload,
        event_url
      });
      this.refreshLogs();
      nprogress.done();
    } catch (e) {
      antMessage.error(e.message || "Error sending event");
      this.refreshLogs();
      nprogress.done();
    }
  };

  // Legacy
  handleTriggerMailBot = e => {
    e.preventDefault();
    try {
      let triggerJson = this.state.futMailbot.triggerJson || `{}`;
      let triggerPayload = JSON.parse(triggerJson);
      var settings = {
        async: true,
        crossDomain: true,
        url: this.state.futMailbot.trigger_url,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(triggerPayload)
      };
      nprogress.start();
      $.ajax(settings)
        .done(response => {
          this.refreshLogs();
          console.log(response);
          nprogress.done();
        })
        .fail(error => {
          this.refreshLogs();
          antMessage.error(error.message || JSON.stringify(error));
          nprogress.done();
        });
    } catch (e) {
      antMessage.error("Invalid JSON");
    }
  };

  handleTriggerMailBotOnChange = e => {
    e.preventDefault();
    const triggerJson = e.target.value;
    const futMailBot = { ...this.state.futMailBot, triggerJson };
    this.setState({ futMailBot });
  };

  getFutEmailDomain() {
    return process.env.REACT_APP_FUT_MAILBOT_EMAIL_DOMAIN || "followupthen.com";
  }

  getAutocompleteEmails = () => {
    if (!this.state.skillMailBot) return;
    return this.state.skillMailBot.autocomplete_examples
      .split(",")
      .map(email => {
        const fullEmail = email.includes("@")
          ? email
          : `${email}@${this.getFutEmailDomain()}`;
        return fullEmail;
      });
  };

  /**
   * This re-saves the mailbot's autocomplete list with every send
   */
  saveNewAutocompleteExamples(allEmails, existingAutocomplete = []) {
    if (!this.state.skillMailBot) return;
    if (!this.userOwnsMailBot(this.state.skillMailBot)) return; // eventually we will store these with user
    // These autocomplete will be prioritized since they were just used
    const priorityAutocompleteEmails = allEmails
      .map(email => {
        email = email.trim();
        // if (existingAutocomplete.includes(email)) return false;
        if (email.indexOf("a+") === 0) return false; // filter action-email addresses
        if (email.search(/^\S+@\S+$/) === -1) return false; // simple email validation
        return email;
      })
      .filter(email => email);

    // For emails at the bot email domain, we only save the local part (local@). This allows
    // email domain to be updated
    const stripMailBotsDomain = email => {
      if (!email) return;
      const [local, domain] = email.split("@");
      if (domain.includes(this.state.futMailBot.subdomain)) return local;
      return email;
    };

    // Current list of autocompletes, minus the ones just used (since they are prioritzed)
    const remainingAutocompleteEmails = existingAutocomplete
      .map(email => {
        if (priorityAutocompleteEmails.includes(email)) return false;
        return email;
      })
      .map(stripMailBotsDomain)
      .map(email => (email ? email.trim() : null))
      .filter(email => email) // filter empties
      .slice(0, 50); // only save the last 50

    // Re-stack autocomplete list, starting iwth priority emails
    const sortedAutocompleteEmails = priorityAutocompleteEmails.concat(
      remainingAutocompleteEmails
    );

    const uniqueAutocompleteEmails = [...new Set(sortedAutocompleteEmails)];

    mailbotsAdminBrowser
      .updateMailBot({
        mailbot: {
          mailbotid: this.state.skillMailBot.id,
          autocomplete_examples: uniqueAutocompleteEmails.join(", ")
        }
      })
      .then(res => {
        this.setState({ skillMailBot: res.mailbot });
        console.log(
          "updated mailbot autocomplete examples: ",
          res.mailbot.autocomplete_examples
        );
      })
      .catch(err => {
        console.log(err);
        antMessage.error(
          `Error updating autocomplete addresses. View console for details.`
        );
      });
  }

  onPreSendEmulator = (event, referenceEmail) => {
    this.setState({ emulatorStatus: "loading" });
    this.saveNewAutocompleteExamples(
      [...referenceEmail.to, ...referenceEmail.cc, ...referenceEmail.bcc],
      this.getAutocompleteEmails()
    );
  };

  onSendSuccessEmulator = tasks => {
    this.setState({ emulatorStatus: "done" });
    this.updateTasks(tasks);
    this.refreshLogs();
  };

  onSendErrorEmulator = error => {
    console.error(error);
    antMessage.error(error.message);
    this.setState({ emulatorStatus: "loaded" });
  };

  onMailtoSendError = e => {
    console.error(e);
    nprogress.done();
    antMessage.error(e.message);
    this.refreshLogs();
  };

  onMailtoPreSend = (e, referenceEmail) => {
    this.setState({
      emulatorEmail: referenceEmail
    });
  };

  handlePopulateTestCommand = e => {
    e.preventDefault();
    this.setState({
      emulatorEmail: {
        to: e.target.text
      }
    });
  };

  handlePopulateEmulator = email => {
    if (email) {
      this.setState({ emulatorEmail: email });
    }
  };

  userOwnsMailBot = mailbot => {
    return Boolean(mailbot.oauth2_client_secret);
  };

  render() {
    const WelcomeDevModal = ({ visible }) => (
      <Modal
        visible={this.state.showWelcomeDevModal}
        onClose={this.closeSettingUpMailBotModal}
      >
        <Card
          type="medium"
          style={{
            padding: 30,
            maxWidth: 600,
            width: "80vw",
            textAlign: "center"
          }}
        >
          <p className="lead">Welcome To The Sandbox</p>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/y1GyXuU2J5k"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <div style={{ padding: 20 }}>
            <a
              className="btn btn-primary"
              onClick={e => {
                e.preventDefault();
                this.setState({ showWelcomeDevModal: false });
              }}
              href={""}
              rel="noopener noreferrer"
            >
              Start Building
            </a>
          </div>
        </Card>
      </Modal>
    );

    const ExtNotInstalledBanner = () => (
      <div className="alert alert-dismissible alert-grey">
        Skill not activated.{" "}
        <a href={this.state.skillMailBot.install_url}>Activate skill</a>, or
        test onboarding below.
      </div>
    );

    const Ext404 = () => (
      <div className="alert alert-dismissible alert-grey">
        <p>
          <strong>Error</strong>
          <br />
          This skill either does not exist or has not been published.
        </p>
        <p>
          <span className="glyphicon glyphicon-wrench"></span> Building a new
          skill with someone? Ask the the skill owner to "enable" it and send
          you directly to the install URL. Once installed, interact with the
          skill as usual here.
        </p>
      </div>
    );

    return (
      <div style={{ width: "98%", margin: "auto" }}>
        {!this.state.skillMailBot && this.state.showExt404 && <Ext404 />}
        {this.state.skillMailBot && (
          <div className="row">
            <div className="col-sm-12 menu-panel" style={{ margin: 20 }}>
              <WelcomeDevModal />
              {!this.state.skillMailBot.installed && <ExtNotInstalledBanner />}
              {this.userOwnsMailBot(this.state.skillMailBot) ? (
                <React.Fragment>
                  <h2>
                    <Link
                      to={`/developer/skills/${this.state.skillMailBot.id}`}
                    >
                      <span
                        className="glyphicon glyphicon-chevron-left"
                        style={{ fontSize: 22 }}
                      />
                    </Link>{" "}
                    {this.state.skillMailBot.name}
                  </h2>
                  <ul className="nav nav-pills nav-mailbot">
                    <li className="dropdown" id="test-menu">
                      <a
                        href=""
                        className="dropdown-toggle btn-secondary btn"
                        data-toggle="dropdown"
                        role="button"
                        aria-expanded="false"
                      >
                        <span className="glyphicon" /> Info
                        <span className="caret" />{" "}
                      </a>
                      <ul
                        className="dropdown-menu"
                        role="menu"
                        style={{
                          width: 600,
                          zIndex: 3,
                          padding: 30
                        }}
                      >
                        <li>
                          <MailBotDevInfoPanel
                            mailbot={this.state.skillMailBot}
                            uninstallMailBot={() => {}}
                            style={{ padding: 35 }}
                          />
                        </li>
                        <li>
                          <h4>Test Webhooks</h4>{" "}
                          <form style={{ margin: 15, minWidth: 350 }}>
                            <input
                              type="text"
                              onChange={this.handleBrodcastEventFormOnChange}
                              name="type"
                              value={this.state.broadcastEventData.type}
                              className="form-control"
                              placeholder="event name (ex: issue.created)"
                            />
                            <br />
                            <textarea
                              onChange={this.handleBrodcastEventFormOnChange}
                              name="payload"
                              value={this.state.broadcastEventData.payload}
                              className="form-control"
                              placeholder="payload (json)"
                            />
                            <br />
                            <button
                              type="submit"
                              onClick={this.handleBroadcastEvent}
                              className="btn btn-primary btn-block"
                            >
                              Send Event
                            </button>
                            <span className="help-block mailbot-settings">
                              <hr />
                              Your Webhook URL:
                              <input
                                style={{ width: "100%" }}
                                readOnly
                                value={this.state.skillMailBot.event_url || ""}
                              />
                            </span>
                          </form>
                        </li>
                      </ul>
                    </li>
                    {this.glitch.isGlitch() && (
                      <li className="dropdown" id="test-menu">
                        <a
                          href=""
                          className="dropdown-toggle btn-secondary btn"
                          data-toggle="dropdown"
                          role="button"
                          aria-expanded="false"
                        >
                          <span className="glyphicon glyphicon-wrench" /> Code{" "}
                          <span className="caret" />{" "}
                        </a>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <a target="_blank" href={this.glitch.getEditUrl()}>
                              <span className="glyphicon glyphicon-new-window" />{" "}
                              Open On Glitch
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              onClick={e =>
                                this.setState({
                                  showEmbeddedGlitchIDE:
                                    !this.state.showEmbeddedGlitchIDE
                                })
                              }
                            >
                              {" "}
                              <span className="glyphicon glyphicon-sunglasses" />
                              {this.state.showEmbeddedGlitchIDE
                                ? "Hide"
                                : "Show"}{" "}
                              Glitch Editor
                            </a>
                          </li>
                        </ul>
                      </li>
                    )}
                    <li className="dropdown" id="test-menu">
                      <a
                        href=""
                        className="dropdown-toggle btn-secondary btn"
                        data-toggle="dropdown"
                        role="button"
                        aria-expanded="false"
                      >
                        <span className="glyphicon glyphicon-list-alt" /> Docs{" "}
                        <span className="caret" />{" "}
                      </a>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          <a
                            href="https://fut.readme.io/reference/email-ui-component-reference"
                            target="blank"
                            rel="noreferrer nofollow"
                          >
                            <span className="glyphicon glyphicon-list-alt" /> UI
                            Components
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/mailbots/mailbots#followupthen-lifecycle-hook-handlers"
                            target="blank"
                            rel="noreferrer nofollow"
                          >
                            <span className="glyphicon glyphicon-list-alt" />{" "}
                            Lifecycle Handlers
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://mailbots-app.mailbots.com/#botwebhook"
                            target="blank"
                            rel="noreferrer nofollow"
                          >
                            <span className="glyphicon glyphicon-list-alt" />{" "}
                            Bot Object
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://mailbots-app.mailbots.com/#botwebooksettingspage"
                            target="blank"
                            rel="noreferrer nofollow"
                          >
                            <span className="glyphicon glyphicon-list-alt" />{" "}
                            Settings Page Elements
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2>{this.state.skillMailBot.name}</h2>{" "}
                  <Link to={`/skills/${this.state.skillMailBot.id}/settings`}>
                    <span
                      style={{ margin: 13 }}
                      className="glyphicon glyphicon glyphicon glyphicon-cog"
                      title="Settings"
                    ></span>
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {this.state.futMailBot && (
          <div className="row">
            <div className="col-sm-12 col-md-4" style={{ marginBottom: 40 }}>
              <Emulator
                onSendSuccess={this.onSendSuccessEmulator}
                onSendError={this.onSendErrorEmulator}
                onPreSend={this.onPreSendEmulator}
                email={this.state.emulatorEmail}
                autocompleteEmails={this.getAutocompleteEmails()}
                status={this.state.emulatorStatus}
                showAddressFields={true}
                showHeaderFields={false}
                loggedInUser={this.props.loggedInUser}
                mailDomain={this.getFutEmailDomain()}
              />
              <Emails
                key="sandbox-emails"
                tasks={this.state.tasks}
                mailtoEmailStatus={this.state.emulatorStatus}
                onMailtoPreSend={this.onMailtoPreSend}
                onMailtoSendSuccess={this.onMailtoSendSuccess}
                onMailtoSendError={this.onMailtoSendError}
                loggedInUser={this.props.loggedInUser}
              />
            </div>

            <div className="col-sm-12 col-md-8">
              {this.state.showEmbeddedGlitchIDE ? (
                <div
                  style={{ height: "420px", width: "100%", marginBottom: 35 }}
                >
                  <iframe
                    src={this.glitch.getIframeEmbedUrl()}
                    title="Your skill on Glitch"
                    style={{ height: "100%", width: "100%", border: 0 }}
                  ></iframe>
                </div>
              ) : null}
              <Logs
                ref={logs => (this.logs = logs)}
                updateTasks={this.updateTasks}
                futMailBot={this.state.futMailBot}
                skillMailBot={this.state.skillMailBot}
                loggedInUser={this.props.loggedInUser}
                broadcastEvent={this.broadcastEvent}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Sandbox);
