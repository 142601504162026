import React, { Component } from "react";
import { MinInput } from "./MinForm";
import { MultiSelectInput } from "./MultiSelectInput";
import CardBase from "./Card";
import ReactQuill from "react-quill";
import _ from "lodash";
import * as domPurify from "dompurify";

const Card = props => <CardBase type="medium" {...props} />;

/**
 * A dumb'ish component that manages its own form state. When the
 * form is submitted, the this.props.handleSubmitEmail function
 * is called which is passed the value of the form fields.
 */
const VisibilityLink = ({ children, onClick }) => (
  <a
    style={{
      float: "right",
      color: "#aaaaaa",
      position: "absolute",
      top: 0,
      right: 5,
      margin: 4,
      padding: 4,
      fontSize: "1em",
      textDecoration: "none",
      backgroundColor: "#fff",
      zIndex: 1
    }}
    onClick={onClick}
    href=""
  >
    {children}
  </a>
);

/**
 * Wraps generalized MultSelectInput input field
 * @param {*} param0
 */
export const AddressField = ({
  name,
  value,
  onChange,
  autocomplete = true, // should it be an autocompleting field, or not?
  onAutocompleteChange,
  autocompleteEmails,
  label,
  mailDomain,
  loadOptions,
  fieldHelp,
  inputStyle = {},
  ...rest
}) => {
  const formatAutocompleteOptions = emails => {
    if (!emails) return [];
    return emails.map(e => ({
      label: e,
      value: e
    }));
  };

  const labelLengthOffset =
    label && (label.split("").length - 1).toString() + "ch";

  const style = {
    fieldContainer: {
      position: "relative",
      lineHeight: 1.5,
      paddingTop: autocomplete ? 4 : 0,
      ...inputStyle
    },
    fieldLabel: {
      position: "absolute",
      top: 11,
      zIndex: 2,
      color: "#aaa",
      background: 0,
      fontSize: "1em"
    },
    fieldInput: {
      paddingLeft: labelLengthOffset,
      border: 0,
      borderBottom: "1px solid #efefef"
    }
  };

  return (
    <div style={style.fieldContainer}>
      <span style={style.fieldLabel}>{label}</span>
      {autocomplete ? (
        <MultiSelectInput
          value={value}
          formatCreateLabel={str => {
            if (!mailDomain) return str;
            return !str.includes("@") ? `${str}@${mailDomain}` : str; // for emulator..append @my.eml.bot
          }}
          onChange={onAutocompleteChange}
          options={formatAutocompleteOptions(autocompleteEmails)}
          loadOptions={loadOptions}
          labelLengthOffset={
            label && (label.split("").length - 1).toString() + "ch"
          }
          fieldHelp={fieldHelp}
        />
      ) : (
        <MinInput
          style={style.fieldInput}
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
        />
      )}
    </div>
  );
};

const ButtonsBar = ({
  sendButtonContent,
  handleOnPreview,
  previewEmail,
  contentChanged,
  isBusy,
  handleOnClick
}) => (
  <React.Fragment>
    {handleOnPreview && (
      <a className="text-muted" onClick={previewEmail}>
        Preview
      </a>
    )}
    {contentChanged || sendButtonContent ? (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn btn-primary btn-mailbots"
          style={{ margin: "0 10px" }}
          title="⌘+↵"
          id="email-editor-send-btn"
          disabled={isBusy}
          onClick={handleOnClick}
        >
          {!isBusy ? sendButtonContent || "SAVE" : "...SAVING"}
        </button>
      </div>
    ) : null}
  </React.Fragment>
);

class EmailEditor extends Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props);

    const newEmail = props.email
      ? {
          to: props.email.to,
          cc: props.email.cc,
          bcc: props.email.bcc,
          subject: props.email.subject,
          html: domPurify.sanitize(props.email.html),
          headers: {}
        }
      : {};

    this.state = {
      showAddressFields: props.showAddressFields,
      showHeaderFields: props.showHeaderFields,
      showPeople: props.showPeople,
      newEmail,
      isBusy: false,
      contentChanged: props.contentChanged // used for showing / hiding save button
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      !_.isEqual(nextProps.email, this.props.email) ||
      !_.isEqual(nextProps.contentChanged, this.props.contentChanged)
    ) {
      this.setState(state => {
        let newState = { ...state, newEmail: { ...nextProps.email } };
        newState.newEmail.html = domPurify.sanitize(newState.newEmail.html); // sanitize
        newState.contentChanged = nextProps.contentChanged;
        return newState;
      });
    }
    if (nextProps.showPeople !== this.props.showPeople) {
      this.setState(state => {
        state.showPeople = nextProps.showPeople;
        return state;
      });
    }
    if (nextProps.loadingOverlay !== this.props.loadingOverlay) {
      this.setState(state => {
        state.loadingOverlay = nextProps.loadingOverlay;
        return state;
      });
    }
  };

  toggleVisibility = e => {
    e.preventDefault();
    const display = { ...this.state.display }; //copy state
    const field = e.target.name;
    const isVisible = !this.state.display[field];
    display[field] = isVisible;
    this.setState({ display });
  };

  toggleAddressFields = e => {
    e.preventDefault();
    if (!this.state.showAddressFields) {
      this.setState({ showAddressFields: true });
    } else if (this.state.showAddressFields && !this.state.showHeaderFields) {
      this.setState({ showHeaderFields: true });
    } else {
      this.setState({
        showHeaderFields: false,
        showAddressFields: false
      });
    }
  };

  handleOnChange = event => {
    let key = event.target.name;
    let value = event.target.value;
    if (typeof value === "string")
      value = event.target.value.replace(/^\s+/g, "");
    this.setState(
      prevState => {
        let newState = { ...prevState };
        newState = _.set(newState, key, value); //works with nested obj like task.reference_email.bcc
        return newState;
      },
      () => {
        // fire callback if parent wants to keep stay updated (ie, in createTask page)
        if (typeof this.props.handleOnChange === "function") {
          this.props.handleOnChange(this.state.newEmail);
        }
      }
    );
  };

  // mailbots are identified by their domain. If the edidtor is passed an email domain, it
  // autopopulates this for us, saving the developer from retyping it each time.
  populateEmailDomain = email => {
    if (!this.props.mailDomain) return email;
    if (email.includes("@")) return email;
    return `${email}@${this.props.mailDomain}`;
  };

  // format the the autocomplete value
  formatAutocompleteValue(value) {
    let newValue = _.clone(value);

    // convert into array of strings with emailDomain (default)
    if (Array.isArray(value)) {
      const emailsOnly = newValue.map(selectObj => selectObj.label);
      if (this.props.mailDomain) {
        newValue = emailsOnly.map(this.populateEmailDomain);
      } else {
        newValue = emailsOnly;
      }
    } else {
      console.warn(
        "Unrecognized type in formatAutocompleteValue: ",
        typeof value
      );
      newValue = String(value);
    }
    return newValue;
  }

  onAutocompleteChange = fieldName => newValue => {
    if (!newValue) return;
    const formattedValue = this.formatAutocompleteValue(newValue);
    let event = {};
    _.set(event, `target.name`, fieldName);
    _.set(event, `target.value`, formattedValue);
    this.handleOnChange(event);
  };

  convertToAutocompleteLabel = email => ({ label: email, value: email });

  getAutocompleteValue = fieldName => {
    let emails = _.get(this.state, fieldName); // ex newEmail.to
    emails = emails || []; // accounts for emails being empty string
    if (typeof emails === "string") emails = [emails];
    return emails.map(this.convertToAutocompleteLabel).filter(email => email); // non-empties
  };

  // Adapter for Quill's onChange's event to make it work with handleOnChange()
  handleQuillChange = quillContent => {
    let event = {};
    _.set(event, "target.value", quillContent);
    _.set(event, "target.name", "newEmail.html");
    this.handleOnChange(event);
  };

  submitEmail = async e => {
    try {
      this.setState({ isBusy: true });
      const res = await this.props.handleSubmitEmail(e, this.state.newEmail);
      this.setState({ isBusy: false, contentChanged: false });
    } catch (e) {
      this.setState({ isBusy: false });
    }
  };

  submitAttachment = e => {
    this.props.handleSubmitAttachment(e.target.files);
  };

  previewEmail = e => {
    if (typeof this.props.handleOnPreview !== "function")
      throw "handleOnPreview is not defined or the right type";
    this.props.handleOnPreview(e, this.state.newEmail);
  };

  showAddressFields = e => {
    this.setState({
      showAddressFields: true
    });
  };

  addressFieldsVisible = () =>
    Boolean(
      this.state.showAddressFields ||
        (this.state.newEmail.cc && this.state.newEmail.cc.length) ||
        (this.state.newEmail.bcc && this.state.newEmail.bcc.length)
    );

  showTo = () => (!_.isUndefined(this.props.showTo) ? this.props.showTo : true);

  showCc = () =>
    !_.isUndefined(this.props.showCc)
      ? this.props.showCc
      : this.addressFieldsVisible();

  showBcc = () =>
    !_.isUndefined(this.props.showBcc)
      ? this.props.showBcc
      : this.addressFieldsVisible();

  render() {
    let { status, style, innerStyle, sendButtonContent } = this.props;

    //TODO: Use bootstrap breakpoints to set modal width
    return (
      <Card
        style={{
          maxWidth: 600,
          minWidth: 350,
          position: "relative",
          ...style
        }}
        innerStyle={{
          margin: 0,
          marginTop: 5,
          maxHeight: "60vh",
          overflowY: "auto",
          ...innerStyle
        }}
      >
        {this.state.newEmail && (
          <div
            className="email-editor"
            onClick={e => {
              // wait to show save button until card has been focused somewhere
              this.setState({ contentChanged: true });
              if (this.props.onClick) this.props.onClick(e);
            }}
          >
            {!this.props.contentOnly && (
              <React.Fragment>
                {!this.props.noToggle && (
                  <VisibilityLink onClick={this.toggleAddressFields}>
                    ...
                  </VisibilityLink>
                )}
                {this.showTo() ? (
                  <AddressField
                    name="newEmail.to"
                    value={this.getAutocompleteValue("newEmail.to")}
                    autocompleteEmails={this.props.autocompleteEmails}
                    loadOptions={this.props.loadOptions}
                    mailDomain={this.props.mailDomain || false}
                    onAutocompleteChange={this.onAutocompleteChange(
                      "newEmail.to"
                    )}
                    label="To:"
                  />
                ) : null}
                {this.showCc() ? (
                  <AddressField
                    name="newEmail.cc"
                    value={this.getAutocompleteValue("newEmail.cc")}
                    autocompleteEmails={this.props.autocompleteEmails}
                    loadOptions={this.props.loadOptions}
                    mailDomain={this.props.mailDomain || false}
                    onAutocompleteChange={this.onAutocompleteChange(
                      "newEmail.cc"
                    )}
                    label="Cc:"
                  />
                ) : null}
                {this.showBcc() ? (
                  <AddressField
                    name="newEmail.bcc"
                    value={this.getAutocompleteValue("newEmail.bcc")}
                    autocompleteEmails={this.props.autocompleteEmails}
                    loadOptions={this.props.loadOptions}
                    mailDomain={this.props.mailDomain || false}
                    onAutocompleteChange={this.onAutocompleteChange(
                      "newEmail.bcc"
                    )}
                    label="Bcc:"
                  />
                ) : null}
                {this.state.showPeople ? (
                  <AddressField
                    name="newEmail.people"
                    value={this.getAutocompleteValue("newEmail.people")}
                    loadOptions={this.props.loadOptions}
                    mailDomain={this.props.mailDomain || false}
                    onAutocompleteChange={this.onAutocompleteChange(
                      "newEmail.people"
                    )}
                    label="Linked People:"
                    fieldHelp="Receive information about people with your followup. (Hidden from everyone but you)."
                  />
                ) : null}
                {this.state.showHeaderFields && (
                  <React.Fragment>
                    <AddressField
                      autocomplete={false}
                      name="newEmail.from"
                      value={this.state.newEmail.from || ""}
                      onChange={this.handleOnChange}
                      label="From:"
                    />
                    <AddressField
                      autocomplete={false}
                      name="newEmail.headers['Message-ID']"
                      value={this.state.newEmail.headers["Message-ID"] || ""}
                      onChange={this.handleOnChange}
                      label="Message-ID:"
                    />
                    <AddressField
                      autocomplete={false}
                      name="newEmail.headers['In-Reply-To']"
                      value={this.state.newEmail.headers["In-Reply-To"] || ""}
                      onChange={this.handleOnChange}
                      label="In-Reply-To:"
                    />
                    <AddressField
                      autocomplete={false}
                      name="newEmail.headers['References']"
                      value={this.state.newEmail.headers["References"] || ""}
                      onChange={this.handleOnChange}
                      label="References:"
                    />
                    <AddressField
                      autocomplete={false}
                      name="newEmail.customHeaders"
                      value={this.state.newEmail.customHeaders || ""}
                      onChange={this.handleOnChange}
                      label="Custom Headers (JSON):"
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <AddressField
              autocomplete={false}
              name="newEmail.subject"
              value={this.state.newEmail.subject || ""}
              onChange={this.handleOnChange}
              label="Subject:"
            />

            {this.state.loadingOverlay ? (
              <span
                style={{
                  position: "relative",
                  bottom: -18
                }}
                className="text-muted"
              >
                {this.state.loadingOverlay}
              </span>
            ) : null}
            <ReactQuill
              rows="10"
              name="newEmail.html"
              theme="bubble"
              style={{ fontSize: "1em", minHeight: 200 }}
              onChange={this.handleQuillChange}
              value={this.state.newEmail.html || ""}
            />
          </div>
        )}
        <div
          style={{
            position: "absolute",
            textAlign: "right",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            padding: 10,
            zIndex: 2,
            borderRadius: 7
          }}
        >
          {status === "loading" ? (
            <div
              id="initial-loader"
              className="loader"
              style={{ marginRight: 65 }}
            >
              {" "}
            </div>
          ) : (
            <ButtonsBar
              sendButtonContent={sendButtonContent}
              handleOnPreview={this.props.handleOnPreview}
              previewEmail={this.previewEmail}
              handleOnClick={this.submitEmail}
              contentChanged={this.state.contentChanged}
              isBusy={this.state.isBusy}
            />
          )}
        </div>
      </Card>
    );
  }
}

export default EmailEditor;
