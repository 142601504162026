import {
  EllipsisOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  StarOutlined
} from "@ant-design/icons";
import { List, Popover, Radio } from "antd";
import nProgress from "nprogress";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { message as antMessage } from "antd";
import styled from "styled-components";
import { GlobalContext } from "../App";
import { getTeamAdmin, isTeamOwner } from "../Billing/billingHelpers";
import { InputIconSet } from "../Form";
import { logger } from "../lib/Logger";
import { mailbotsAdminBrowser } from "../lib/utils";

export const ManageEmails = ({ emailAddresses = [] }) => {
  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const [newLinkedEmail, setNewLinkedEmail] = useState("");
  const [linkEmailLoading, setLinkEmailLoading] = useState(false);

  const handleLinkEmail = async event => {
    event.preventDefault();
    const email = newLinkedEmail;
    try {
      if (!email) return;
      setLinkEmailLoading(true);
      const result = await mailbotsAdminBrowser.userLinkEmail({ email });
      if (result.status === "error") throw Error(result.message);
      await reloadUser();
      antMessage.success(
        `We just sent a confirmation email to ${email}. Click the link in that confirmation to complete the linking process.`
      );
      setLinkEmailLoading(false);
      setNewLinkedEmail("");
      logger.log("settings new email linked");
    } catch (e) {
      setLinkEmailLoading(false);
      logger.log("error linking new email", { data: { error_obj: e } });
      antMessage.error(e.message);
    }
  };

  const handleUnlinkEmail = async (event, email) => {
    logger.log("unlink email button clicked", { data: { email } });
    event.preventDefault();
    if (
      !window.confirm(`Are you sure you would like to unlink this email? Existing followups scheduled to \
 be sent this address will be re-routed to your primary email address.`)
    )
      return;
    try {
      nProgress.start();
      const res = await mailbotsAdminBrowser.userUnlinkEmail({ email });
      if (res.status === "error") throw Error(res.message);
      await reloadUser();
      antMessage.success("Email successfully unlinked");
    } catch (e) {
      antMessage.error(e.message);
      logger.log("error unlinking email", { data: { error_obj: e } });
      // @todo
      // Core API is not returning a status code for this action that is reconizable by
      // our requests lib. Possibly related to  https://github.com/rsweetland/followupthen/issues/2407
    }
    nProgress.done();
  };

  const makePrimaryEmail = async (event, email) => {
    event.preventDefault();
    logger.log("settings make primary email button clicked");
    try {
      nProgress.start();
      const res = await mailbotsAdminBrowser.updateEmail({
        email,
        data: { primary: 1 }
      });
      if (res.status === "error") throw Error(res.message);
      await reloadUser();
      antMessage.success(
        `Primary email switched. Tip: Change your delivery address for pending followups from your followups page.`
      );
    } catch (e) {
      antMessage.error(e.message);
      logger.log("error making primary email", { data: { error_obj: e } });
    }
    nProgress.done();
  };

  const updateSenderValidation = async (event, email) => {
    event.preventDefault();
    const sender_validation = event.target.value || "warn";
    logger.log("sender validation updated", {
      data: { validation_level: sender_validation }
    });
    try {
      nProgress.start();
      const res = await mailbotsAdminBrowser.updateEmail({
        email: email.email,
        data: { sender_validation }
      });
      if (res.status === "error") throw Error(res.message);
      await reloadUser();
      antMessage.success(`Sender validation changed to "${sender_validation}"`);
    } catch (e) {
      antMessage.error(e.message);
      logger.log("error updating sender validation", {
        data: { error_obj: e }
      });
    }
    nProgress.done();
  };

  const resendLinkEmail = async (event, emailInfo = {}) => {
    event.preventDefault();
    logger.log("resent link email");
    try {
      nProgress.start();
      await mailbotsAdminBrowser.resendLinkValidationEmail({
        email: emailInfo.email
      });
      await reloadUser();
      antMessage.success(
        `Sent to ${emailInfo.email} ✓ Make sure to check your spam filter!`
      );
    } catch (e) {
      antMessage.error(e.message);
      logger.log("error resending link email", { data: { error_obj: e } });
    }
    nProgress.done();
  };

  const sortEmails = (email1, email2) => {
    if (email1.primary) return -1; // sort earlier
    if (email1.status !== "verified") return 1; // sort later
    return 0; // leave sort index unchanged
  };

  //   const showLinks = (emailAddress, visibility) => {
  //     this.setState(state => {
  //       const thisEmail = _.find(
  //         state.settings.email_addresses,
  //         emailObj => emailObj.email === emailAddress
  //       );
  //       thisEmail.showLinks = visibility;
  //       return state;
  //     });
  //   };

  //   const linksAreShown = emailAddress => {
  //     const thisEmail = _.find(
  //       this.state.settings.email_addresses,
  //       emailObj => emailObj.email === emailAddress
  //     );
  //     if (!thisEmail) return false;
  //     return thisEmail.showLinks;
  //   };

  const LinksSubmenu = ({ emailItem }) => {
    const email = emailItem;
    if (!email) return null;

    return (
      <React.Fragment>
        {email.primary && (
          <li>
            <Popover
              trigger="hover"
              content={
                <div style={{ maxWidth: 300 }}>
                  Use this email to login, and for your billing address. Change
                  primary address by selecting another below.{" "}
                  <a
                    href="https://help.followupthen.com/knowledge-base/switch-account/"
                    target="_blank"
                    rel="follower noreferrer"
                  >
                    Read more
                  </a>
                </div>
              }
            >
              {" "}
              (Primary Address)
            </Popover>
          </li>
        )}
        {email.status === "unverified" && (
          <li>
            <a href="" onClick={e => resendLinkEmail(e, email)}>
              <RedoOutlined /> resend verification
            </a>
          </li>
        )}
        {!email.primary && email.status === "verified" && (
          <li>
            <a
              href=""
              data={email}
              onClick={e => makePrimaryEmail(e, email.email)}
            >
              <StarOutlined /> make primary
            </a>
          </li>
        )}
        {!email.primary && (
          <li>
            <a
              href=""
              data={email}
              onClick={e => handleUnlinkEmail(e, email.email)}
            >
              <ExportOutlined /> unlink email
            </a>
          </li>
        )}
        {email.status === "verified" && (
          <React.Fragment>
            <li className="divider"></li>
            <li>
              Sender Validation{" "}
              <Popover
                content={
                  <div style={{ maxWidth: 300 }}>
                    When we receive an email that comes from your address, we
                    can perform additional checks to ensure someone is not
                    spoofing your email address. We can warn you if a (possibly)
                    spoofed email arrives, or (fail) ignore the incoming email
                    entirely.{" "}
                    <a
                      href="http://help.followupthen.com/knowledge-base/sender-validation/"
                      target="_blank"
                      rel="follower noreferrer"
                    >
                      Read more
                    </a>
                  </div>
                }
              >
                {" "}
                <QuestionCircleOutlined />
              </Popover>
              <Radio.Group
                name="sendervalidation"
                onChange={e => updateSenderValidation(e, email)}
                defaultValue="none"
                value={email.sender_validation}
                style={{ display: "flex", flexDirection: "row" }}
                onClick={e => e.stopPropagation()}
              >
                <Radio value="none">None</Radio>
                <Radio value="warn">Warn</Radio>
                <Radio value="fail">Fail</Radio>
              </Radio.Group>
            </li>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const StyledEllipsisOutlined = styled(EllipsisOutlined)`
    font-size: 17px;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 0.1s ease;
    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #e6e6e6;
    }
  `;

  const EmailSubmenu = ({ emailItem }) => (
    <span>
      <a className="dropdown-toggle nav-link" data-toggle="dropdown">
        <StyledEllipsisOutlined />
      </a>
      <ul
        className="dropdown-menu dropdown-menu-right"
        role="menu"
        style={{ padding: 15 }}
      >
        <LinksSubmenu emailItem={emailItem} />
      </ul>
    </span>
  );

  return (
    <div className="form-group">
      <label htmlFor="inputEmail" className="control-label">
        My Email Addresses{" "}
        {isTeamOwner(loggedInUser) ? (
          <React.Fragment>
            |{" "}
            <Link
              to="/team"
              style={{
                fontWeight: "normal",
                color: "#11A2D2",
                cursor: "pointer"
              }}
            >
              Invite Team Member
            </Link>
          </React.Fragment>
        ) : null}{" "}
        <Popover
          content={
            <div style={{ maxWidth: 500 }}>
              <p>
                Link your own addresses below.{" "}
                {isTeamOwner(loggedInUser) ? (
                  <>
                    Link team members from <Link to="/team">team settings</Link>
                    .
                  </>
                ) : (
                  <>
                    The team administrator
                    {getTeamAdmin(loggedInUser).email} can add additional team
                    members.
                  </>
                )}
              </p>
              <p>
                <strong>What's the difference?</strong>
                <br />
                Your emails (below) share settings and personal information.
              </p>
              <p>
                Team members accounts have their own accounts, complete with
                their own linked addresses, budgets and limits.
              </p>
              <a
                href="https://help.followupthen.com/knowledge-base/linking-details//"
                target="_blank"
                rel="follower noreferrer"
              >
                Read more
              </a>
            </div>
          }
        >
          <QuestionCircleOutlined />
        </Popover>
      </label>

      <List
        size="small"
        itemLayout="horizontal"
        dataSource={emailAddresses && emailAddresses.sort(sortEmails)}
        renderItem={emailItem => (
          <List.Item actions={[<EmailSubmenu emailItem={emailItem} />]}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "75%"
              }}
            >
              {" "}
              {emailItem.email}
              {emailItem.primary && (
                <span className="hide-for-mobile"> (Primary) </span>
              )}
            </span>
            {emailItem.status === "unverified" && (
              <span>
                <span className="label label-default">unverified</span>
              </span>
            )}
          </List.Item>
        )}
      />
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>
          <InputIconSet
            name="newLinkedEmail"
            placeholder="Link another email address you own"
            onChange={e => setNewLinkedEmail(e.target.value)}
            onSubmit={handleLinkEmail}
            value={newLinkedEmail}
            loading={linkEmailLoading}
            iconRight={
              <>
                {linkEmailLoading ? (
                  <div
                    id="initial-loader"
                    className="loader"
                    style={{ height: 14, width: 14 }}
                  />
                ) : (
                  <span
                    onClick={handleLinkEmail}
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: 12,
                      color: "#11a2d2"
                    }}
                  >
                    LINK
                  </span>
                )}
              </>
            }
          />
        </li>
      </ul>
    </div>
  );
};
