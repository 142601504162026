import React, { Component } from "react";
import FloatingCard from "./FloatingCard";
import { mailbotsAdminBrowser, getAccessToken } from "./lib/utils";
import queryString from "query-string";
import nprogress from "nprogress";
import Alert from "./Alert";
import FooterMessage from "./FooterMessage";
import futLogo from "./images/futLogo.svg";
import { Link, withRouter } from "react-router-dom";
import cookie from "js-cookie";
import { message as antMessage } from "antd";

class AuthorizeMailBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailbot: {}
    };
  }

  isValidatedUserWithIncompleteSetup = () =>
    !this.props.loggedInUser.name || !this.props.loggedInUser.timezone;

  async componentDidMount() {
    this.extAuthParams = queryString.parse(window.location.search);
    this.setState({
      isTrusted: this.props.isTrustedMailBot(this.extAuthParams.client_id)
    });

    nprogress.start();
    try {
      let res = await mailbotsAdminBrowser.getMailBot({
        mailbotid: this.extAuthParams.client_id
      });
      if (res instanceof Error) throw res;

      if (!getAccessToken()) {
        this.props.history.push(
          "/login?mid-auth&redirect=" +
            encodeURIComponent(
              window.location.pathname + window.location.search
            )
        );
      }

      nprogress.done();

      this.setState({ mailbot: res.mailbot });
      window.activateTooltips();
    } catch (e) {
      nprogress.done();
      console.log(e);
      this.setState({
        errorMessage: `There was an error loading this mailbot (${e.message}) Please contact us if you need assistance.`
      });
    }
  }

  handleAuthorize = async e => {
    try {
      e.preventDefault();
      nprogress.start();
      let res = await mailbotsAdminBrowser.authorizeMailBot(this.extAuthParams);
      window.location.assign(res.redirect_uri);
    } catch (e) {
      antMessage.error(e.message || JSON.stringify(e));
    }
  };

  hasLoaded = () => {
    return this.state.mailbot && this.state.mailbot.hasOwnProperty("name");
  };

  oAuthScopes = {
    get_user_info: {
      short: "View account information",
      detail:
        "It can read account information, including email addresses, timezone and subscription plan."
    },
    mailbot_manage_self: {
      short: "Store data",
      detail: "It can create, read, edit or delete only data it creates."
    },
    manage_reminders: {
      short: "Read and write all reminders",
      detail: "It will be able to read and write all of your reminders."
    },
    read_reminders: {
      short: "Read all reminders",
      detail: "It will be able to read all of your reminders."
    },
    read_services: {
      short: "Read services",
      detail: "It will be able to read your connected services."
    },
    manage_own_tasks: {
      short: "Manage followups it creates",
      detail: "It can create, edit or delete only the followups it creates."
    },
    manage_all_tasks: {
      short: "Manage all followups",
      detail:
        "It will be able to read, edit or delete all tasks on your account."
    },
    read_own_tasks: {
      short: "Read followups it creates",
      detail: "It can create and read only the followups it creates."
    },
    read_all_tasks: {
      short: "Read all followups",
      detail: "It can read all followups on your account."
    },
    read_logs: {
      short: "Read activity logs",
      detail: "It can read the log events for all skills."
    },
    manage_logs: {
      short: "Manage activity logs",
      detail: "It can manage log events for all skills."
    },
    manage_mailbots: {
      short: "Read and write skills",
      detail: "It can create and edit skills."
    },
    interbot_events: {
      short: "Interact with other skills",
      detail: "It can interact with other skills."
    },
    read_mailbots: {
      short: "Read skills",
      detail: "It can read information about all skills."
    },
    manage_user_self: {
      short: "Manage your account settings",
      detail: "It can view and change your MailBots account settings."
    },
    manage_people: {
      short: "Manage people",
      detail:
        "It will be able to read, edit or delete all people on your account."
    }
  };

  render() {
    // render "trust mailbot warning"
    const requestedScope = this.extAuthParams ? this.extAuthParams.scope : "";

    let style = {
      ul_lead: {
        paddingLeft: 10
      },
      li: {
        listStyle: "none",
        textAlign: "left",
        whiteSpace: "nowrap"
      }
    };

    return (
      <div className="text-center">
        {this.state.errorMessage && (
          <Alert level="warning">{this.state.errorMessage}</Alert>
        )}

        {this.hasLoaded() ? (
          <div style={{ paddingTop: "10vh" }}>
            <a href="/skills">
              <img src={futLogo} alt="MailBots Logo" width="150px" />
            </a>
            <FloatingCard
              header={
                this.state.mailbot.icon && (
                  <img
                    src={this.state.mailbot.icon}
                    alt="MailBot  Logo"
                    width="120px"
                    style={{ padding: 20 }}
                  />
                )
              }
              footer={
                <p style={{ margin: 10, marginBottom: 50 }}>
                  {this.state.mailbot.privacy_policy_url && (
                    <span>
                      <a
                        href={this.state.mailbot.privacy_policy_url}
                        rel="nofollow noreferrer"
                        className="muted-link"
                      >
                        This MailBot's Privacy Policy
                      </a>{" "}
                      |
                    </span>
                  )}{" "}
                  {this.state.mailbot.learn_more_url && (
                    <span>
                      <a
                        href={this.state.mailbot.learn_more_url}
                        rel="nofollow noreferrer"
                        className="muted-link"
                      >
                        Developer Website
                      </a>{" "}
                      |
                    </span>
                  )}{" "}
                  {this.state.mailbot.help_url && (
                    <span>
                      <a
                        href={this.state.mailbot.help_url}
                        rel="nofollow noreferrer"
                        className="muted-link"
                      >
                        Contact Developer
                      </a>{" "}
                    </span>
                  )}{" "}
                </p>
              }
            >
              <div className="text-left">
                <p className="lead">
                  <strong>{this.state.mailbot.name}</strong>{" "}
                  {this.state.mailbot.developer_name && (
                    <span>by {this.state.mailbot.developer_name}</span>
                  )}{" "}
                  is requesting these permissions to your FollowUpThen account:{" "}
                </p>

                <ul className="lead" style={style.ul_lead}>
                  {requestedScope.split(" ").map(scope =>
                    this.oAuthScopes[scope] ? (
                      <li
                        className="scopes"
                        style={style.li}
                        key={scope}
                        data-toggle="tooltip"
                        data-placement="left"
                        title={this.oAuthScopes[scope].detail}
                      >
                        {this.oAuthScopes[scope].short}
                      </li>
                    ) : null
                  )}
                </ul>
                <button
                  onClick={async e => {
                    e.target.innerText = "Connecting...";
                    e.target.disabled = true;
                    await this.handleAuthorize(e);
                  }}
                  type="submit"
                  className="btn btn-primary btn-mailbots btn-block"
                >
                  Connect
                </button>
                <br />
                <div
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    color: "grey"
                  }}
                >
                  <Link to={`/skills/${this.state.mailbot.id}`}>
                    <i className="glyphicon glyphicon-chevron-left" />
                    Cancel connection
                  </Link>
                </div>
              </div>
            </FloatingCard>
            {this.props.loggedInUser && (
              <div className="text-muted" style={{ marginTop: 15 }}>
                You're logged in as {this.props.loggedInUser.email}{" "}
                <Link
                  style={{ textDecoration: "underline", color: "#b4bcc2" }}
                  className="text-muted"
                  to={`/login?redirect=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`}
                >
                  Switch Users
                </Link>
              </div>
            )}
            {this.state.isTrusted && (
              <FooterMessage type="warning">
                <p>
                  Please make sure you trust this developer before continuing.
                  MailBots does not endorse or certify specific bots.{" "}
                  <a href="">Read More</a>
                </p>
              </FooterMessage>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(AuthorizeMailBot);
