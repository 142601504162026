import React from "react";
import { GreyLink } from "./MinForm";
import { withRouter } from "react-router-dom";
import { AppleFilled } from "@ant-design/icons";

class SkillsApplied extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSimpleSkillDescriptionModal: false,
      selectedSimpleSkill: {},
      showMailBotDetailsModal: false,
      selectedMailbot: {}
    };
  }

  showSimpleSkillDescription = (e, appliedSkill) => {
    e.preventDefault();
    this.setState({
      showSimpleSkillDescriptionModal: true,
      selectedSimpleSkill: appliedSkill
    });
  };

  showMailBotDetails = (e, appliedSkill) => {
    e.preventDefault();
    this.setState({
      showMailBotDetailsModal: true,
      selectedMailbot: appliedSkill
    });
  };

  // @todo: merge the messages from the current skill into the consolidated skill in case there.
  consolidateDuplicateSkills = (consolidatedSkills, currentSkill) => {
    if (consolidatedSkills.some(skill => skill.name === currentSkill.name)) {
      console.log("Hiding skill with duplicate name", currentSkill);
      return consolidatedSkills; // return @todo: consolidatedSkills.map(mergeDuplicateSkill)
    } else {
      consolidatedSkills.push(currentSkill);
      return consolidatedSkills;
    }
  };

  render = () => (
    <>
      {this.props.skillsLog
        .reduce(this.consolidateDuplicateSkills, [])
        .map(appliedSkill => {
          if (appliedSkill.mailbotid) {
            const renderErrors =
              appliedSkill.status !== "success" ? (
                <span>
                  <span
                    data-toggle="tooltip"
                    title={
                      appliedSkill.skillResponse ||
                      `There was an error with ${appliedSkill.name}`
                    }
                    className="glyphicon glyphicon-exclamation-sign"
                    style={{
                      color: "#d02963",
                      marginLeft: 10
                    }}
                  ></span>
                </span>
              ) : (
                ""
              );
            return (
              <GreyLink
                key={`remote_${appliedSkill.name}`}
                onClick={e =>
                  this.props.history.push(`/skills/${appliedSkill.mailbotid}`)
                }
                style={{ marginTop: 3 }}
              >
                <img
                  src={
                    appliedSkill.icon_url ||
                    "https://files-38348em97-mailbots.vercel.app/"
                  }
                  width="20px"
                  style={{ float: "left", marginRight: 3 }}
                  alt={`Icon: ${appliedSkill.name}`}
                />{" "}
                <span>{appliedSkill.name}</span>
                {renderErrors}
              </GreyLink>
            );
          } else {
            //  @todo remove local skill view option once we consolidate to requirement that all skills are in the db
            console.log("local simple appliedSkill", appliedSkill);
            return (
              <GreyLink
                key={`local_${appliedSkill.name}`}
                style={{ marginTop: 10 }}
                onClick={e => this.showSimpleSkillDescription(e, appliedSkill)}
              >
                <img
                  src={
                    appliedSkill.icon_url ||
                    "https://files-38348em97-mailbots.vercel.app/"
                  }
                  width="20px"
                  style={{ float: "left", marginRight: 3 }}
                  alt={`Icon: ${appliedSkill.name}`}
                />{" "}
                <span className="hidden-xs">{appliedSkill.name}</span>
              </GreyLink>
            );
          }
        })}
    </>
  );
}

export default withRouter(SkillsApplied);
