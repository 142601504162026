import React, { useContext, useReducer } from "react";
import { SelectTimezone } from "./SelectTimezone";
import { GlobalContext } from "./App";

export const RegisterForm = ({
  handleSubmit,
  nextText,
  hideFields = [],
  style = {}
}) => {
  let initialState = {
    fullName: "",
    email: "",
    password: "",
    termsChecked: false,
    timezone: "GMT"
  };

  const reducer = (state, action) => {
    return { ...state, ...action.partialState };
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { loggedInUser } = useContext(GlobalContext);

  /**
   * Workaround to capture fields the user did not touch,
   * (ie, the timezone option), thus not firing an onChange event.
   */
  const captureWholeForm = function () {
    let fields = document.getElementsByClassName("mailbots-data");
    let wholeForm = {};
    for (let prop in fields) {
      let k = fields[prop].name;
      let v = fields[prop].value;
      if (k && v) {
        // this assumes element ids exactly match state var names
        wholeForm[k] = v;
      }
    }
    return { ...state, ...wholeForm };
  };

  return (
    <div style={style}>
      <form
        method="post"
        onSubmit={e => {
          e.preventDefault();
          const wholeRegForm = captureWholeForm();
          handleSubmit(wholeRegForm);
        }}
        style={{ textAlign: "left" }}
      >
        <fieldset>
          <div className="form-group">
            {hideFields.includes("fullName") ? null : (
              <input
                required
                onChange={e =>
                  dispatch({ partialState: { fullName: e.target.value } })
                }
                value={state.fullName}
                name="fullName"
                id="fullName"
                type="text"
                placeholder="Name"
                className="form-control mailbots-data"
              />
            )}
          </div>

          {hideFields.includes("email") ? null : (
            <div className="form-group">
              <input
                onChange={e =>
                  dispatch({ partialState: { email: e.target.value } })
                }
                value={state.email}
                name="email"
                id="email"
                type="text"
                placeholder="Email"
                className="form-control mailbots-data"
              />
            </div>
          )}

          <div className="form-group">
            <label>Is this your timezone?</label>
            <SelectTimezone
              onChange={e =>
                dispatch({ partialState: { timezone: e.target.value } })
              }
              className="mailbots-data"
            />
          </div>
          {!loggedInUser.password_set ? (
            <React.Fragment>
              <label>Choose a password</label>
              <input
                onChange={e =>
                  dispatch({ partialState: { password: e.target.value } })
                }
                value={state.password}
                name="password"
                type="password"
                placeholder="Password"
                className="form-control mailbots-data"
                id="password"
              />
            </React.Fragment>
          ) : null}
          <label
            className="form-check"
            htmlFor="terms"
            style={{ fontWeight: "normal", marginTop: 10, marginBottom: 30 }}
          >
            <input
              type="checkbox"
              required
              onChange={e =>
                dispatch({
                  partialState: { termsChecked: !state.termsChecked }
                })
              }
              checked={state.termsChecked}
              name="terms"
              id="terms"
            />{" "}
            I agree to the{" "}
            <a
              className="muted"
              href="https://www.followupthen.com/terms"
              target="_blank"
            >
              terms
            </a>{" "}
            and{" "}
            <a
              className="muted"
              href="https://www.followupthen.com/privacy"
              target="_blank"
            >
              privacy policy
            </a>
            .
          </label>
          <div>
            <button
              type="submit"
              className="btn btn-primary btn-mailbots btn-block"
            >
              {nextText ? nextText : "Sign Up 🚀"}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};
