import React, { Component } from "react";
import jstz from "jstz";
import _ from "lodash";

export const TIMEZONES = [
  {
    value: "",
    offset: "",
    displayText: "Select Timezone"
  },
  {
    value: "Pacific/Midway",
    offset: "-11:00",
    displayText: "(-11:00) Midway Island, Samoa"
  },
  {
    value: "Pacific/Honolulu",
    offset: "-10:00",
    displayText: "(-10:00) Hawaii"
  },
  {
    value: "America/Adak",
    offset: "-10:00",
    displayText: "(-10:00) Aleutian-Adak"
  },
  {
    value: "Pacific/Marquesas",
    offset: "-09:30",
    displayText: "(-09:30) Marquesas Islands"
  },
  {
    value: "Pacific/Gambier",
    offset: "-09:00",
    displayText: "(-09:00) Gambier Islands"
  },
  {
    value: "America/Anchorage",
    offset: "-09:00",
    displayText: "(-09:00) Alaska"
  },
  {
    value: "America/Los_Angeles",
    offset: "-08:00",
    displayText: "(-08:00) Pacific Time (US &amp; Canada)"
  },
  {
    value: "America/Ensenada",
    offset: "-08:00",
    displayText: "(-08:00) Tijuana, Baja California"
  },
  {
    value: "Etc/GMT+8",
    offset: "-08:00",
    displayText: "(-08:00) Pitcairn Islands"
  },
  {
    value: "America/Denver",
    offset: "-07:00",
    displayText: "(-07:00) Mountain Time (US &amp; Canada)"
  },
  {
    value: "America/Phoenix",
    offset: "-07:00",
    displayText: "(-07:00) Mountain Standard Time (Arizona)"
  },
  {
    value: "America/Chihuahua",
    offset: "-07:00",
    displayText: "(-07:00) Chihuahua, La Paz, Mazatlan"
  },
  {
    value: "America/Dawson_Creek",
    offset: "-07:00",
    displayText: "(-07:00) Arizona"
  },
  {
    value: "America/Chicago",
    offset: "-06:00",
    displayText: "(-06:00) Central Time (US &amp; Canada)"
  },
  {
    value: "America/Belize",
    offset: "-06:00",
    displayText: "(-06:00) Saskatchewan, Central America"
  },
  {
    value: "America/Cancun",
    offset: "-06:00",
    displayText: "(-06:00) Guadalajara, Mexico City, Monterrey"
  },
  {
    value: "Chile/EasterIsland",
    offset: "-06:00",
    displayText: "(-06:00) Easter Island"
  },
  {
    value: "America/New_York",
    offset: "-05:00",
    displayText: "(-05:00) Eastern Time (US &amp; Canada)"
  },
  {
    value: "America/Havana",
    offset: "-05:00",
    displayText: "(-05:00) Cuba"
  },
  {
    value: "America/Bogota",
    offset: "-05:00",
    displayText: "(-05:00) Bogota, Lima, Quito, Rio Branco"
  },
  {
    value: "Etc/GMT-4",
    offset: "-04:00",
    displayText: "(-04:00) Arabian Standard Time"
  },
  {
    value: "America/Caracas",
    offset: "-04:30",
    displayText: "(-04:30) Caracas"
  },
  {
    value: "America/Santiago",
    offset: "-04:00",
    displayText: "(-04:00) Santiago"
  },
  {
    value: "America/La_Paz",
    offset: "-04:00",
    displayText: "(-04:00) La Paz"
  },
  {
    value: "Atlantic/Stanley",
    offset: "-04:00",
    displayText: "(-04:00) Faukland Islands"
  },
  {
    value: "America/Campo_Grande",
    offset: "-04:00",
    displayText: "(-04:00) Brazil"
  },
  {
    value: "America/Goose_Bay",
    offset: "-04:00",
    displayText: "(-04:00) Atlantic Time (Goose Bay)"
  },
  {
    value: "America/Glace_Bay",
    offset: "-04:00",
    displayText: "(-04:00) Atlantic Time (Canada)"
  },
  {
    value: "America/St_Johns",
    offset: "-03:30",
    displayText: "(-03:30) Newfoundland"
  },
  {
    value: "America/Araguaina",
    offset: "-03:00",
    displayText: "(-03:00) UTC-3"
  },
  {
    value: "America/Montevideo",
    offset: "-03:00",
    displayText: "(-03:00) Montevideo"
  },
  {
    value: "America/Miquelon",
    offset: "-03:00",
    displayText: "(-03:00) Miquelon, St. Pierre"
  },
  {
    value: "America/Godthab",
    offset: "-03:00",
    displayText: "(-03:00) Greenland"
  },
  {
    value: "America/Argentina/Buenos_Aires",
    offset: "-03:00",
    displayText: "(-03:00) Buenos Aires"
  },
  {
    value: "America/Sao_Paulo",
    offset: "-03:00",
    displayText: "(-03:00) Brasilia"
  },
  {
    value: "America/Noronha",
    offset: "-02:00",
    displayText: "(-02:00) Mid-Atlantic"
  },
  {
    value: "Atlantic/Cape_Verde",
    offset: "-01:00",
    displayText: "(-01:00) Cape Verde Is."
  },
  {
    value: "Atlantic/Azores",
    offset: "-01:00",
    displayText: "(-01:00) Azores"
  },
  {
    value: "GMT",
    offset: "00:00",
    displayText: "(00:00) Greenwich Mean Time"
  },
  {
    value: "Europe/Belfast",
    offset: "00:00",
    displayText: "(00:00) Greenwich Mean Time : Belfast"
  },
  {
    value: "Europe/Dublin",
    offset: "00:00",
    displayText: "(00:00) Greenwich Mean Time : Dublin"
  },
  {
    value: "Europe/Lisbon",
    offset: "00:00",
    displayText: "(00:00) Greenwich Mean Time : Lisbon"
  },
  {
    value: "Europe/London",
    offset: "00:00",
    displayText: "(00:00) Greenwich Mean Time : London"
  },
  {
    value: "Africa/Abidjan",
    offset: "00:00",
    displayText: "(00:00) Monrovia, Reykjavik"
  },
  {
    value: "Europe/Amsterdam",
    offset: "+01:00",
    displayText: "(+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
  },
  {
    value: "Europe/Belgrade",
    offset: "+01:00",
    displayText: "(+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
  },
  {
    value: "Europe/Brussels",
    offset: "+01:00",
    displayText: "(+01:00) Brussels, Copenhagen, Madrid, Paris"
  },
  {
    value: "Africa/Algiers",
    offset: "+01:00",
    displayText: "(+01:00) West Central Africa"
  },
  {
    value: "Africa/Windhoek",
    offset: "+01:00",
    displayText: "(+01:00) Windhoek"
  },
  {
    value: "Europe/Istanbul",
    offset: "+02:00",
    displayText: "(+02:00) Istanbul"
  },
  {
    value: "Europe/Helsinki",
    offset: "+02:00",
    displayText: "(+02:00) Helsinki"
  },
  {
    value: "Asia/Beirut",
    offset: "+02:00",
    displayText: "(+02:00) Beirut"
  },
  {
    value: "Africa/Cairo",
    offset: "+02:00",
    displayText: "(+02:00) Cairo"
  },
  {
    value: "Africa/Johannesburg",
    offset: "+02:00",
    displayText: "(+02:00) Johannesburg, Pretoria"
  },
  {
    value: "Asia/Gaza",
    offset: "+02:00",
    displayText: "(+02:00) Gaza"
  },
  {
    value: "Asia/Jerusalem",
    offset: "+02:00",
    displayText: "(+02:00) Jerusalem"
  },
  {
    value: "Europe/Kiev",
    offset: "+02:00",
    displayText: "(+02:00) Kiev"
  },
  {
    value: "Europe/Minsk",
    offset: "+02:00",
    displayText: "(+02:00) Minsk"
  },
  {
    value: "Asia/Damascus",
    offset: "+02:00",
    displayText: "(+02:00) Syria"
  },
  {
    value: "Africa/Addis_Ababa",
    offset: "+03:00",
    displayText: "(+03:00) Nairobi"
  },
  {
    value: "Asia/Tehran",
    offset: "+03:30",
    displayText: "(+03:30) Tehran"
  },
  {
    value: "Europe/Moscow",
    offset: "+03:00",
    displayText: "(+03:00) Moscow, St. Petersburg, Volgograd"
  },
  {
    value: "Asia/Dubai",
    offset: "+04:00",
    displayText: "(+04:00) Abu Dhabi, Muscat"
  },
  {
    value: "Asia/Yerevan",
    offset: "+04:00",
    displayText: "(+04:00) Yerevan"
  },
  {
    value: "Asia/Kabul",
    offset: "+04:30",
    displayText: "(+04:30) Kabul"
  },
  {
    value: "Asia/Yekaterinburg",
    offset: "+05:00",
    displayText: "(+05:00) Ekaterinburg"
  },
  {
    value: "Asia/Tashkent",
    offset: "+05:00",
    displayText: "(+05:00) Tashkent"
  },
  {
    value: "Asia/Karachi",
    offset: "+05:00",
    displayText: "(+05:00) Islamabad, Karachi, Pakistan"
  },
  {
    value: "Asia/Kolkata",
    offset: "+05:30",
    displayText: "(+05:30) Chennai, Kolkata, Mumbai, New Delhi"
  },
  {
    value: "Asia/Katmandu",
    offset: "+05:45",
    displayText: "(+05:45) Kathmandu"
  },
  {
    value: "Asia/Dhaka",
    offset: "+06:00",
    displayText: "(+06:00) Astana, Dhaka"
  },
  {
    value: "Asia/Novosibirsk",
    offset: "+06:00",
    displayText: "(+06:00) Novosibirsk"
  },
  {
    value: "Asia/Rangoon",
    offset: "+06:30",
    displayText: "(+06:30) Yangon (Rangoon)"
  },
  {
    value: "Asia/Bangkok",
    offset: "+07:00",
    displayText: "(+07:00) Bangkok, Hanoi, Jakarta"
  },
  {
    value: "Asia/Krasnoyarsk",
    offset: "+07:00",
    displayText: "(+07:00) Krasnoyarsk"
  },
  {
    value: "Asia/Hong_Kong",
    offset: "+08:00",
    displayText: "(+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
  },
  {
    value: "Asia/Irkutsk",
    offset: "+08:00",
    displayText: "(+08:00) Irkutsk, Ulaan Bataar"
  },
  {
    value: "Asia/Taipei",
    offset: "+08:00",
    displayText: "(+08:00) Taipei"
  },
  {
    value: "Australia/Perth",
    offset: "+08:00",
    displayText: "(+08:00) Perth"
  },
  {
    value: "Australia/Eucla",
    offset: "+08:45",
    displayText: "(+08:45) Eucla"
  },
  {
    value: "Asia/Tokyo",
    offset: "+09:00",
    displayText: "(+09:00) Osaka, Sapporo, Tokyo"
  },
  {
    value: "Asia/Seoul",
    offset: "+09:00",
    displayText: "(+09:00) Seoul"
  },
  {
    value: "Asia/Yakutsk",
    offset: "+09:00",
    displayText: "(+09:00) Yakutsk"
  },
  {
    value: "Australia/Adelaide",
    offset: "+09:30",
    displayText: "(+09:30) Adelaide"
  },
  {
    value: "Australia/Darwin",
    offset: "+09:30",
    displayText: "(+09:30) Darwin"
  },
  {
    value: "Australia/Melbourne",
    offset: "+10:00",
    displayText: "(+10:00) Melbourne"
  },
  {
    value: "Australia/Brisbane",
    offset: "+10:00",
    displayText: "(+10:00) Brisbane"
  },
  {
    value: "Australia/Hobart",
    offset: "+10:00",
    displayText: "(+10:00) Hobart"
  },
  {
    value: "Asia/Vladivostok",
    offset: "+10:00",
    displayText: "(+10:00) Vladivostok"
  },
  {
    value: "Australia/Lord_Howe",
    offset: "+10:30",
    displayText: "(+10:30) Lord Howe Island"
  },
  {
    value: "Etc/GMT-11",
    offset: "+11:00",
    displayText: "(+11:00) Solomon Is., New Caledonia"
  },
  {
    value: "Asia/Magadan",
    offset: "+11:00",
    displayText: "(+11:00) Magadan"
  },
  {
    value: "Pacific/Norfolk",
    offset: "+11:30",
    displayText: "(+11:30) Norfolk Island"
  },
  {
    value: "Asia/Anadyr",
    offset: "+12:00",
    displayText: "(+12:00) Anadyr, Kamchatka"
  },
  {
    value: "Pacific/Auckland",
    offset: "+12:00",
    displayText: "(+12:00) Auckland, Wellington"
  },
  {
    value: "Etc/GMT-12",
    offset: "+12:00",
    displayText: "(+12:00) Fiji, Kamchatka, Marshall Is."
  },
  {
    value: "Pacific/Chatham",
    offset: "+12:45",
    displayText: "(+12:45) Chatham Islands"
  },
  {
    value: "Pacific/Tongatapu",
    offset: "+13:00",
    displayText: "(+13:00) Nuku'alofa"
  },
  {
    value: "Pacific/Kiritimati",
    offset: "+14:00",
    displayText: "(+14:00) Kiritimati"
  }
];

/**
 * Use to verify user's timezone (if they are traveling, etc).
 */
export const guessTimezone = () => {
  return jstz.determine().name() || "UTC";
};

export function SelectTimezone({ onChange, value, name, ...props }) {
  const userTimezone = value || guessTimezone();
  return (
    <select
      id="timezone"
      className="form-control mailbots-data"
      name={`${name || "timezone"}`}
      onChange={onChange}
      value={userTimezone}
    >
      {TIMEZONES.map((timezone, i) => (
        <option value={timezone.value} key={i}>
          {timezone.value}
        </option>
      ))}
    </select>
  );
}
