//Components to build a minimal-style form as shown in the right-column of task details
import styled from "styled-components";
import React from "react";

export const Input = styled.input.attrs({ className: "form-control" })`
  border-radius: 4px !important;
`;

export const TextArea = styled.textarea.attrs({ className: "form-control" })`
  border: 0;
`;

export const InputButton = styled.button.attrs({
  className: "btn btn-mailbots"
})`
  height: 45px;
  font-size: 11px;
  padding: 5px;
  color: #aaa;
  &:active {
    background: #ddd;
  }
  &:hover {
    background: #d0d0d0d0 !important;
    text-decoration: none;
    color: #aaa;
    cursor: pointer;
  }
`;

export const InputButtonSet = ({
  style = {},
  placeholder,
  innerRef = null,
  onChange,
  onSubmit,
  value,
  loading,
  name,
  buttonContent
}) => (
  <div
    className="input-group"
    style={{ width: "100%", borderRadius: 4, ...style }}
  >
    <Input
      placeholder={placeholder}
      innerRef={innerRef || null}
      name={name}
      onChange={onChange}
      value={value || ""}
    />
    <span className="input-group-btn">
      {loading ? (
        <div
          id="initial-loader"
          className="loader"
          style={{ marginLeft: -30, zIndex: 999 }}
        >
          {" "}
        </div>
      ) : (
        <InputButton
          onClick={onSubmit}
          style={{ backgroundColor: "#ededed", borderRadius: 0 }}
        >
          {buttonContent || (
            <span
              className="glyphicon glyphicon-plus"
              style={{ width: 20, color: "#aaaaaa" }}
            />
          )}
        </InputButton>
      )}
    </span>
  </div>
);

export const InputIconSet = ({
  style = {},
  inputStyle = {},
  placeholder,
  setRef = null,
  onChange,
  onSubmit,
  value,
  name = "",
  iconRight = null,
  iconLeft = null
}) => {
  return (
    <div
      className="input-group"
      style={{ width: "100%", borderRadius: "4px !important", ...style }}
    >
      <form onSubmit={onSubmit}>
        {iconLeft ? (
          <div
            style={{
              position: "absolute",
              left: 8,
              top: 0,
              display: "inline-flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            {iconLeft}
          </div>
        ) : null}
        <input
          className="form-control"
          placeholder={placeholder}
          ref={ref => {
            if (!setRef) return;
            setRef(ref);
          }}
          name={name}
          onChange={onChange}
          value={value || ""}
          style={inputStyle}
        />
        <span
          style={{
            position: "absolute",
            top: 0,
            right: 10,
            height: "100%",
            display: "flex",
            alignItems: "center",
            zIndex: 1
          }}
        >
          {iconRight}
        </span>
      </form>
    </div>
  );
};
