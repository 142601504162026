import ModalSimple from "../ModalSimple";
import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";

/**
 * Generic tour component. See ModalTours.jsx for example implementation
 */
class ModalTour extends React.Component {
  constructor(props) {
    super(props);
    this.tourSteps = this.getTourSteps() || [];
    this.state = {
      tourStep: this.getStepNumFromId(props.currentStepId) || 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.tourSteps = this.getTourSteps() || [];
  }

  getTourSteps = () => {
    if (Array.isArray(this.props.tourSteps)) return this.props.tourSteps;
    if (typeof this.props.tourSteps === "function") {
      const tourSteps = this.props.tourSteps(this.tourControls);
      return tourSteps;
    }
    throw new Error("tourSteps must be an array or function");
  };

  tourNext = e => {
    if (e && e.preventDefault) e.preventDefault();
    const tourStep =
      this.state.tourStep == this.tourSteps.length - 1
        ? 0
        : ++this.state.tourStep;
    this.setState({ tourStep });
  };

  tourBack = e => {
    if (e && e.preventDefault) e.preventDefault();
    // current step can override the "back" click with a function
    if (typeof this.getCurrentStep().onBackClick === "function")
      return this.getCurrentStep().onBackClick();
    const tourStep =
      this.state.tourStep == 0
        ? this.tourSteps.length - 1
        : --this.state.tourStep;
    this.setState({ tourStep });
  };

  isOnFirstStep = e => this.state.tourStep === 0;

  getCurrentStep = () => this.tourSteps[this.state.tourStep];

  close = e => {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({ visible: false });
  };

  getStepNumFromId = stepId => {
    if (!this.tourSteps) return null;
    const index = this.tourSteps.findIndex(s => s.id === stepId);
    if (index === -1) return null;
    return index;
  };

  getAllStepIds = () => this.tourSteps.map(s => s.id);

  // check if stepId is included among allStepIds
  hasStepId = stepId => this.getAllStepIds().includes(stepId);

  // current descriptive id of step ex: ("upgrade")
  getCurrentStepId = () => this.getCurrentStep().id;

  // give the parent component a handy API to control the tour. This requires that tourSteps
  // is passed as a function that returns an array of steps. See ModalTours.jsx for an example
  tourControls = {
    next: this.tourNext,
    back: this.tourBack,
    goToStepNum: tourStep => this.setState({ tourStep }),
    goToStepId: stepId => {
      if (!this.hasStepId(stepId)) {
        console.error(`'${stepId}' not found in tour: ${this.getAllStepIds()}`);
        return;
      }
      this.setState({ tourStep: this.getStepNumFromId(stepId) });
    },
    getCurrentStep: () => this.state.tourStep,
    closeTour: this.close,
    history: this.props.history,
    getAllStepIds: this.getAllStepIds,
    getCurrentStepId: this.getCurrentStepId
  };

  handleOnClick = e => {
    e.preventDefault();
    if (this.getCurrentStep().onClick) {
      this.getCurrentStep().onClick(this);
    } else {
      this.tourNext();
    }
  };

  render() {
    // exposes a global "cli" for tour in console for debugging + experimentation
    window.tourControlsGlobal = this.tourControls;

    const currentStep = this.getCurrentStep() || {};
    return (
      <ModalSimple
        style={{ width: "95vw", maxWidth: 600 }}
        containerStyle={this.props.containerStyle}
        onClose={this.props.onClose}
        visible={
          this.props.visible === undefined
            ? this.state.visible
            : this.props.visible
        }
      >
        <div
          className={
            _.isUndefined(currentStep.className)
              ? "modal-tour"
              : currentStep.className
          }
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: 620,
            width: "100%",
            justifyContent: "space-between",
            transition: "all 0. ease",
            ...(this.props.cardStyle || {})
          }}
        >
          <div style={{ flex: 3 }}>{currentStep.content}</div>
          <div style={{ flex: 1, flexGrow: 0 }}>
            {!_.isUndefined(currentStep.tourNavBar) ? (
              currentStep.tourNavBar
            ) : (
              <div>
                {currentStep.buttonText ? (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleOnClick} // call any of the methods from callbacks (ex: onClose=> modal => modal.close())
                  >
                    {currentStep.buttonText || "next"}
                  </button>
                ) : null}
                <br />
                <a
                  href=""
                  onClick={this.tourBack}
                  style={{
                    color: "#aaa",
                    display: "block",
                    padding: 15,
                    textDecoration: "none",
                    visibility: this.isOnFirstStep() ? "hidden" : "visible"
                  }}
                >
                  ⬅ Back
                </a>
                {currentStep.stepFooter}
              </div>
            )}

            {typeof this.props.footer === "function"
              ? this.props.footer(this.tourControls)
              : this.props.footer}
          </div>
        </div>
      </ModalSimple>
    );
  }
}

export default withRouter(ModalTour);
