import React, { useContext, useReducer, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CardBase from "./Card";
import nprogress from "nprogress";
import { message as antMessage } from "antd";
import _ from "lodash";
import "../node_modules/react-quill/dist/quill.bubble.css";
import Loading from "./Loading";
import Emails from "./Emails";
import ScheduleWidget from "./ScheduleWidget";
import EmailEditor from "./EmailEditor";
import Modal from "./Modal";
import FooterMessage from "./FooterMessage";
import { TaskPreview } from "./TaskPreview";
import { Label, GreyLink, GreyLinkNarrow } from "./MinForm";
import {
  mailbotsAdminBrowser,
  isFutAddress,
  userDate,
  isMailBotsEmail,
  incompleteAndPastDue,
  handleWebhookResponseStatus,
  getRecipientTypes,
  getEmailMethod,
  setRecentlyUsedFormat,
  updateTaskAndReferenceEmailWithNewCommand,
  conversationalDate,
  moveFutCommand
} from "./lib/utils";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton";
import SkillsApplied from "./SkillsApplied";
import {
  getCachedTaskById,
  getCachedVerboseTaskById,
  cacheVerboseTask,
  setTaskCacheIsStale,
  invalidateTaskCache
} from "./Tasks/TaskCache";
import ManageRecipientsModal from "./TaskDetails/ManageRecipientModal";
import ExpandableList from "./ExpandableList";
import { returnableLink } from "./BackButton";
import { logger } from "./lib/Logger";
import Alert from "./Alert";
import { GlobalContext } from "./App";
import { userPaidForSkill } from "./Billing/billingHelpers";
import { ButtonOnBg } from "./ButtonOnBg";
import { isOnMobile } from "./lib/hookisMobile";
import {
  DeleteOutlined,
  HistoryOutlined,
  LeftCircleOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { SkillsAppliedToTask } from "./Tasks/SkillsAppliedToTask";
import { ReactComponent as Exclam } from "./images/Exclam.svg";
import { useEffect } from "react";

const Card = props => <CardBase type="medium" {...props} />;

export default function TaskContainer(props) {
  const initState = {
    task: {},
    taskHistory: [],
    showHistoryModal: false,
    scheduleData: null,
    outboundMessages: [],
    referenceEmailStatus: null,
    emailEditorDialog: {
      visibility: false
    },
    mailbotWebhookState: "loading",
    previewTask: null,
    showPreview: false,
    skillsLog: [],
    showSimpleSkillDescriptionModal: false,
    selectedSimpleSkill: {},
    actionDialogMessage: null,
    showRecipientModal: false,
    isBusy: false,
    busyResource: -1,
    expandTaskDetailsSidebar: false,
    showScheduleWidget: false,
    showFromEmailAddressModal: false,
    taskErrorMessage: null
  };

  // setState replacement
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty
  });
  const [state, setState] = useReducer(reducer, initState);

  const { loggedInUser } = useContext(GlobalContext);
  const history = useHistory();

  const fileInputRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    // offerEmailBasedActionsIfNoPref(); // @todo: Possibly move to new action page
    applyEmailActionFromUrl().then(() => {
      loadBasicTask(props.taskId);
      loadRenderedTask(props.taskId);
      logger.log("followups details screen shown");
    });
  }, []);

  const qs = queryString.parse(window.location.search);
  useEffect(() => {
    if (qs.reschedule) {
      setState({ showScheduleWidget: true });
    }
  }, [JSON.stringify(qs)]);

  // navigating with react router changes props
  // currentProps;
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   debugger;
  // const qs = queryString.parse(window.location.search);
  // if (qs.reschedule) {
  //   // wait for ref to be available
  //   setTimeout(() => {
  //     setState({ showScheduleWidget: true });
  //     scheduleWidgetRef.selectRef.current.focus();
  //   }, 1000);
  // }
  // if (nextProps.taskId !== props.taskId) {
  //   setState({ mailbotWebhookState: "loading" }); // Prevents UI flickering while switching tasks
  // }
  // if (_.isEqual(currentProps, nextProps)) return;
  // currentProps = nextProps;
  // loadRenderedTask(nextProps.taskId);
  // }

  // Fetch task.viewed data from the mailbot (takes more time)
  // Loading the task verbosely fires the mailbot.viewed webhook
  // and uses the webhook response JSON to render the task preview.
  // Account for the mailbot being down / overloaded, etc.
  // Note: This component used to perform two requests: in CDM, it would
  // pull only info from MailBots. loadRendered task would then
  // contact the mailbot. This faster loading method may yet be needed.
  const loadBasicTask = async taskId => {
    try {
      // check if the task is in the cache
      const cachedTask = getCachedTaskById(taskId);
      if (cachedTask) {
        setState({
          task: cachedTask
        });
        console.log("task loaded from cache");
        return;
      }
      setState({ isBusy: true });
      const basicTask = await mailbotsAdminBrowser.getTask({
        id: parseInt(taskId, 10),
        verbose: 0
      });
      setState({
        task: basicTask.task,
        isBusy: false
      });
      console.log("task loaded by http request");
    } catch (e) {
      setState({ isBusy: false });
      console.error("loadBasicTask error", e);
      // for all known cases, the error handling is identical to loadRenderedTask
      // handleApiTaskError(e);
    }
  };

  const loadRenderedTask = async taskId => {
    try {
      nprogress.start();
      let verboseResponse = getCachedVerboseTaskById(taskId);

      setState({ isBusy: true });
      if (!verboseResponse) {
        verboseResponse = await mailbotsAdminBrowser.getTask({
          id: parseInt(taskId, 10),
          payload: {},
          verbose: 1
        });
        console.log("verbose task loaded by http request");

        cacheVerboseTask(verboseResponse);
      } else {
        console.log("verbose task loaded from cache");
      }

      const skillsLog = _.get(
        verboseResponse,
        "webhook.passthrough_data.skillsLog"
      );
      handleWebhookResponseStatus(verboseResponse);

      const mailbotWebhookState = _.get(
        verboseResponse,
        "webhook.status",
        "failed"
      );
      const mailbotWebhookStatusMessage = _.get(
        verboseResponse,
        "webhook.message",
        null
      );

      const task = verboseResponse.task;
      task.messages = verboseResponse.messages;
      setState({
        task,
        mailbotWebhookState,
        mailbotWebhookStatusMessage,
        skillsLog,
        isBusy: false
      });
      nprogress.done();
    } catch (e) {
      setState({ isBusy: false });
      handleApiTaskError(e);
    }
  };

  const handleApiTaskError = e => {
    const notYourTaskError =
      e.message === "task_not_yours"
        ? "This task doesn't belong to your account. Do you have multiple email addresses? Combine them on your settings page."
        : null;
    const taskNotFoundError =
      e.message === "task_not_found"
        ? "Task not found. It may have been already deleted, or this URL is pointing to the wrong place."
        : null;
    const errorMessage =
      notYourTaskError || taskNotFoundError || e.message || JSON.stringify(e);
    setState({ taskErrorMessage: errorMessage });
    nprogress.done();
  };

  const reloadRenderedTask = () => {
    invalidateTaskCache();
    loadRenderedTask(state.task.id);
  };

  const handleMailtoError = e => {
    console.error(e);
    nprogress.done();
    antMessage.error(e.message);
  };

  const handleOnChange = event => {
    const key = event.target.name;
    const value = event.target.value;
    setState(prevState => {
      let newState = { ...prevState };
      newState = _.set(newState.task, key, value); //works with nested obj like task.reference_email.bcc
      return newState;
    });
  };

  const applyEmailActionFromUrl = async () => {
    nprogress.start();
    let actionDialogMessage;
    try {
      const qs = queryString.parse(window.location.search);
      let payload = {};
      // "action" implies a one-click action. Something like "action-compose" would prompt an action email
      if (qs.behavior == "action") {
        payload.verbose = 1;
        payload.webhook = true;
        payload.reference_email = {
          to: [qs.to],
          server_recipient: qs.to,
          // from: qs.from || loggedInUser.primary_email,
          // @todo https://github.com/mailbots/fut-core-api/issues/2749
          from: loggedInUser.primary_email,
          subject: qs.subject,
          html: qs.html
        };
        const res = await mailbotsAdminBrowser.sendAction(payload);
        handleWebhookResponseStatus(res); // partially applied skills can trigger warnings

        // @todo find a better way to give user feedback
        actionDialogMessage = qs.to.includes("postpone")
          ? "Task Postponed ✅"
          : "Action Applied ✅";
        setState({ message: "Action Applied ✅" });
      }
    } catch (e) {
      console.error(e);
      actionDialogMessage = `Something went wrong trying to apply this action. Please forward this URL to
      help@humans.fut.io for assistance.`;
    }
    nprogress.done();
    setState({
      actionDialogMessage
    });
  };

  const handleCompleteToggle = async e => {
    e.preventDefault();
    await updateTask({
      completed: !state.task.completed
    });
    await loadRenderedTask(state.task.id);
    nprogress.done();
    antMessage.success("Task completed");

    logger.log("followup completed from web app", {
      data: {
        taskId: state.task.id
      }
    });
  };

  const handleFollowUpNow = async e => {
    nprogress.start();
    try {
      await mailbotsAdminBrowser.triggerTask({
        trigger_url: state.task.trigger_url
      });
      logger.log("follow up now button clicked");
      antMessage.info("Followup queued for delivery");
    } catch (e) {
      antMessage.error(e.message);
    }
    nprogress.done();
  };

  const handleDeleteOnClick = async e => {
    logger.log("followups details delete button clicked");
    e.preventDefault();
    if (
      !window.confirm(
        "Are you sure? This will permanently delete your followup."
      )
    )
      return;
    nprogress.start();
    try {
      let res = await mailbotsAdminBrowser.deleteTask({
        webhook: true,
        task: {
          id: state.task.id
        }
      });
      if (res instanceof Error) throw res;
      nprogress.done();
      antMessage.success("Task successfully deleted");
      setTaskCacheIsStale();
      setTimeout(() => history.push("/tasks"), 500);
      logger.log("followups details delete done", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (e) {
      nprogress.done();
      console.log(e);
      antMessage.error(e);
    }
  };

  const handleShowHistory = async e => {
    try {
      logger.log("followups details show history button clicked", {
        data: {
          taskId: state.task.id
        }
      });
      nprogress.start();
      const res = await mailbotsAdminBrowser.getTaskHistory(props.taskId);
      let events = res.events;
      setState({
        taskHistory: events,
        showHistoryModal: true
      });
      nprogress.done();
    } catch (e) {
      nprogress.done();
      antMessage.error("Sorry, we could not fetch this task");
      console.error(e);
    }
  };

  const handleHideHistory = () => {
    setState({
      showHistoryModal: false
    });
  };

  const mailbotSettingsJSONExample = {
    reminder_emails: 0,
    frequency_pref: "0.5",
    followup_text: "Hi there, just making sure you know xyz",
    sms: 1
  };

  const mailbotSettingsDefinition = [
    {
      name: "textarea",
      label: "Followup Message",
      type: "textarea",
      default: "foo",
      help: "This is help text, rendered below the links",
      placeholder: "Hi, just making sure you received that last email."
    },
    {
      name: ""
    }
  ];

  const getTaskFromReferenceEmail = referenceEmail => {
    //to, cc, bcc must be array values
    // @todo: DRY these methods. Shared in ScheduleWidget
    function emailStringToArray(emailString) {
      if (emailString && typeof emailString === "string") {
        return emailString.split(",");
      } else if (emailString && emailString instanceof Array) {
        return emailString;
      } else {
        return [];
      }
    }

    const newReferenceEmail = _.cloneDeep(referenceEmail);
    newReferenceEmail.to = emailStringToArray(newReferenceEmail.to);
    newReferenceEmail.cc = emailStringToArray(newReferenceEmail.cc);
    newReferenceEmail.bcc = emailStringToArray(newReferenceEmail.bcc);

    // prevent task command from changing here (for simplicity)
    // prevent more than one email command from being added here
    const allEmails = newReferenceEmail.to
      .concat(newReferenceEmail.cc)
      .concat(newReferenceEmail.bcc);
    const emailCommands = allEmails.filter(email =>
      isMailBotsEmail(email.toString())
    );
    const emailCommand = (emailCommands[0] || "").toLowerCase();
    const taskCommand = (state.task.command || "").toLowerCase();
    const emailCommandDiffers = emailCommand !== taskCommand;
    if (emailCommands.length > 1 || emailCommandDiffers) {
      antMessage.warn(`We do not yet support changing or adding commands here, only editing the original
      email. Use the "Reschedule" link instead. Share your feedback! help@humans.fut.io`);
      return;
    }

    const task = {
      id: state.task.id,
      task: { command: taskCommand },
      reference_email: newReferenceEmail
    };

    return task;
  };

  const handleClosePreview = e => {
    setState({ showPreview: false });
  };

  const handleSaveReferenceEmail = async email => {
    //to, cc, bcc must be array values
    function emailStringToArray(emailString) {
      if (emailString && typeof emailString === "string") {
        return emailString.split(",");
      } else if (emailString && emailString instanceof Array) {
        return emailString;
      } else {
        return [];
      }
    }
    let newReferenceEmail = email;
    newReferenceEmail.to = emailStringToArray(newReferenceEmail.to);
    newReferenceEmail.cc = emailStringToArray(newReferenceEmail.cc);
    newReferenceEmail.bcc = emailStringToArray(newReferenceEmail.bcc);

    setState({ referenceEmailStatus: "loading" });
    await updateTask({
      reference_email: newReferenceEmail
    });
    await loadRenderedTask(state.task.id);
    // hideReferenceEmail();
    setState({ referenceEmailStatus: null });
    antMessage.success("Task updated ✔");
    nprogress.done();
    logger.log("followups details reference email saved", {
      data: {
        taskId: state.task.id
      }
    });
  };

  // in schedule widget
  const handleCommandOnSubmit = async newCommand => {
    // if command has changed, update reference-email with the new email in to/cc/bcc (deep copy)
    const newTask = updateTaskAndReferenceEmailWithNewCommand(
      state.task,
      newCommand
    );

    // handling task scheduling, completing, etc happens within fut-mailbot https://github.com/mailbots/fut-core-api/issues/2910
    const res = await updateTask({
      command: newCommand,
      reference_email: newTask.reference_email
    });
    await loadRenderedTask(state.task.id);
    antMessage.success("Task updated ✔");

    nprogress.done();
    history.push(`/tasks/${state.task.id}`); // remove url params
    setState({ savingThinking: false });
    setState({ formatHasChanged: false }); // hide save button
    setState({ showScheduleWidget: false }); // hide save button

    // only present if updateTask worked
    if (res && res.task) {
      setRecentlyUsedFormat(res.task.command.split("@")[0]);
    }
  };

  // utility method used for various updates
  const updateTask = async taskPartial => {
    nprogress.start();
    try {
      const taskPayload = {
        webhook: true,
        task: {
          id: state.task.id,
          ...taskPartial
        }
      };
      const updateRes = await mailbotsAdminBrowser.updateTask(taskPayload);
      handleWebhookResponseStatus(updateRes);
      nprogress.done();
      setState({
        task: updateRes.task
      });
      invalidateTaskCache();
      return updateRes;
    } catch (e) {
      antMessage.error("We ran into an error updating your task " + e.message);
      logger.log("Error updating task", {
        level: "warn",
        data: {
          error_str: e.message,
          taskId: state.task.id
        }
      });
    }
  };

  // For now we take the naive approach of editing the task and moving the FUT format to the
  // Eventually we allow the user to populate additional future messages.
  // BCC field.
  const toggleCcBcc = async (e, message) => {
    e.preventDefault();
    if (message && message.to[0] === state.task.reference_email.from) {
      antMessage.error("This message cannot be removed");
      return;
    }
    try {
      const emailMethod = getEmailMethod(
        state.task.reference_email,
        state.task.command
      );

      let updatedReferenceEmail = { ...state.task.reference_email };
      if (emailMethod === "bcc" || emailMethod === "to") {
        updatedReferenceEmail = moveFutCommand(updatedReferenceEmail, "cc");
      } else {
        updatedReferenceEmail = moveFutCommand(updatedReferenceEmail, "bcc");
      }
      await updateTask({ reference_email: updatedReferenceEmail });
      await loadRenderedTask(state.task.id);
      antMessage.success("Task updated ✔");
      logger.log("cc message removed", {
        level: "warn",
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      antMessage.info("There was an error removing this message");
      console.error(error);
      logger.log("error removing cc message", {
        level: "warn",
        data: {
          taskId: state.task.id
        }
      });
    }
  };

  const showMailBotDetails = (e, mailbotid) => {
    e.preventDefault();
    history.push(`/skills/${mailbotid}`);
    // @todo Show as modal
    // setState(
    //   {
    //     modalMailBotId: mailbotid
    //   },
    //   () => setState({ showMailBotModal: true })
    // );
  };

  const showSimpleSkillDescription = (e, appliedSkill) => {
    e.preventDefault();
    setState({
      showSimpleSkillDescriptionModal: true,
      selectedSimpleSkill: appliedSkill
    });
  };

  // @todo: merge the messages from the current skill into the consolidated skill in case there.
  const consolidateDuplicateSkills = (consolidatedSkills, currentSkill) => {
    if (consolidatedSkills.some(skill => skill.name === currentSkill.name)) {
      console.log("Hiding skill with duplicate name", currentSkill);
      return consolidatedSkills; // return @todo: consolidatedSkills.map(mergeDuplicateSkill)
    } else {
      consolidatedSkills.push(currentSkill);
      return consolidatedSkills;
    }
  };

  const handleAttachmentUpload = async e => {
    try {
      const files = e.target.files;
      nprogress.start();

      // check if total size exceeds the limit
      const existingFilesSize = state.task.reference_email.attachments.reduce(
        (sum, crt) => sum + parseInt(crt.size),
        0
      );

      let newFilesSize = 0;
      for (let i = 0; i < files.length; i++) newFilesSize += files[i].size;

      if (existingFilesSize + newFilesSize > 8 * 1000 * 1000) {
        // 8mb
        antMessage.error(
          "Could not upload attachments because the total size would exceed 8 megabytes"
        );
        nprogress.done();
        return;
      }

      const formData = new FormData();
      for (let i = 0; i < files.length; i++)
        formData.append(`file${i}`, files[i]);

      const res = await mailbotsAdminBrowser.uploadTaskAttachment({
        task: { id: state.task.id },
        formData
      });

      const task = { ...state.task };
      task.reference_email.attachments = res.attachments;
      setState({ task });
      logger.log("followups details attachment added", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      antMessage.error("Error uploading attachment: " + error.message);
      logger.log("error uploading attachment", {
        level: "warn",
        data: {
          taskId: state.task.id
        }
      });
    }

    nprogress.done();
  };

  const addRecipient = async newRecipient => {
    try {
      nprogress.start();

      setState({
        isBusy: true
      });

      const task = state.task;

      let to = task.reference_email.to || [];
      let cc = task.reference_email.cc || [];
      let bcc = task.reference_email.bcc || [];

      // if not already a cc fut, make it one by removing it from bcc / to and adding it to cc
      if (!cc.some(ccRecipient => isFutAddress(ccRecipient))) {
        const futAddress = task.command;
        bcc = bcc.filter(r => r !== futAddress);
        to = to.filter(r => r !== futAddress);
        cc = cc.concat(futAddress);
      }

      // prevent duplicates email in 'to' field
      to = to.filter(toRecipient => toRecipient !== newRecipient);

      // add the new recipient and make sure they're unique
      to = [...new Set(to.concat([newRecipient]))];

      const newReferenceEmail = { ...task.reference_email, to, cc, bcc };

      await handleSaveReferenceEmail(newReferenceEmail);
      setState({
        showRecipientModal: false
      });
      logger.log("followups details recipient added", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      logger.log("error adding followup recipient", {
        level: "error",
        data: {
          taskId: state.task.id
        }
      });
      antMessage.error(error.message);
    }

    setState({
      isBusy: false
    });
    nprogress.done();
  };

  const removeRecipient = async recipient => {
    try {
      setState({
        isBusy: true,
        busyResource: recipient
      });
      nprogress.start();

      const task = state.task;
      const newReferenceEmail = {
        ...task.reference_email,
        to: task.reference_email.to.filter(email => email !== recipient)
      };

      if (newReferenceEmail.to.length === 0) {
        // if the last recipient has been removed,
        // move the command to the "to" field
        const foundFutAddresses = newReferenceEmail.cc.filter(email =>
          isFutAddress(email)
        );
        newReferenceEmail.to = newReferenceEmail.to.concat(foundFutAddresses);

        // remove it from cc
        newReferenceEmail.cc = newReferenceEmail.cc.filter(
          email => !isFutAddress(email)
        );
      }

      await handleSaveReferenceEmail(newReferenceEmail);
      logger.log("followups details recipient removed", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      logger.log("error in followups details recipient removed", {
        level: "error",
        data: {
          taskId: state.task.id
        }
      });
      antMessage.error(error.message);
    }

    setState({
      isBusy: false,
      busyResource: -1
    });
    nprogress.done();
  };

  const addNonRecipient = async recipient => {
    nprogress.start();

    setState({
      isBusy: true
    });

    try {
      let people = state.task.relationships.people;
      people.push({ email: recipient });

      await updateTask({ people });

      await loadRenderedTask(state.task.id);
      // hideReferenceEmail();
      antMessage.success("Task updated ✔");
      setState({ referenceEmailStatus: null });
      antMessage.success("Task Saved ✔");

      setState({
        showRecipientModal: false
      });
      logger.log("followups details non-recipient added", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      antMessage.error(error.message);
    }

    setState({
      isBusy: false
    });
    nprogress.done();
  };

  const removeNonRecipient = async recipient => {
    nprogress.start();

    setState({
      isBusy: true,
      busyResource: recipient
    });

    try {
      const peopleToKeep = state.task.relationships.people.filter(
        person => person.email !== recipient
      );

      await updateTask({ people: peopleToKeep });
      await loadRenderedTask(state.task.id);
      setState({ referenceEmailStatus: null });
      antMessage.success("Task Saved ✔");

      console.log("non recipient removed");
      logger.log("followups details non-recipient removed", {
        data: {
          taskId: state.task.id
        }
      });
    } catch (error) {
      antMessage.error(error.message);
    }

    setState({
      isBusy: false,
      busyResource: -1
    });
    nprogress.done();
  };

  const PersonLink = ({ email }) => {
    if (!state.task || !state.task.reference_email) return null;
    const backText = encodeURIComponent(
      state.task.reference_email.subject.substring(0, 20) + "..."
    );
    const backablePersonLink = returnableLink({
      toPath: `/people/e/${email || ""}`,
      backPath: window.location.pathname,
      backText
    });
    return (
      <GreyLinkNarrow
        onClick={() => history.push(backablePersonLink)}
        style={{
          overflow: "hidden",
          color: "#5d5d5d",
          textOverflow: "ellipsis"
        }}
      >
        {email || ""}
      </GreyLinkNarrow>
    );
  };

  const inReferenceEmail = (thisEmail, referenceEmail) =>
    referenceEmail.to.includes(thisEmail) ||
    referenceEmail.cc.includes(thisEmail) ||
    referenceEmail.bcc.includes(thisEmail);

  if (state.taskErrorMessage) {
    return (
      <div
        style={{
          width: "90vw",
          maxWidth: 500,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "20vh",
          textAlign: "center",
          fontSize: "2rem"
        }}
      >
        <Exclam style={{ width: 100, margin: 25 }} />
        <p>{state.taskErrorMessage}</p>
        <Link to="/tasks">
          <LeftCircleOutlined /> View Followups{" "}
        </Link>
      </div>
    );
  }

  if (!state.task) return null;

  const emailMethod = getEmailMethod(
    state.task.reference_email,
    state.task.command
  );
  const recipientTypes = getRecipientTypes(
    state.task.reference_email,
    emailMethod
  );
  const { nonRecipients, extRecipients, ownerEmail } = recipientTypes
    ? recipientTypes
    : {};

  const attachments =
    state.task.reference_email &&
    !!state.task.reference_email.attachments.length &&
    state.task.reference_email.attachments;

  const renderConversationalDate = () => {
    return (
      <>
        <Tooltip
          title={userDate({
            timestamp: state.task.trigger_time,
            timezone: state.task.timezone
          })}
        >
          {conversationalDate({
            timestamp: state.task.trigger_time
          })}
        </Tooltip>
      </>
    );
  };

  const renderArchivedTaskBar = () => {
    return (
      <GreyLink onClick={handleCompleteToggle} style={{ color: "grey" }}>
        <i className="glyphicon glyphicon-repeat" />{" "}
        <span className="hidden-xs">Restore</span>
      </GreyLink>
    );
  };

  /**
   * If we have preview messages (success case), show those
   * If we have mailbot webhook failure, show error message
   * If we have webhook success but zero messages show another message
   */
  const MainContent = () => {
    if (state.mailbotWebhookState === "failed") {
      return <WebhookFailed />;
    } else if (state.mailbotWebhookState === "loading") {
      return <Loading loadingText="Loading preview..." />;
    } else if (
      state.task.id &&
      state.task.messages &&
      state.task.messages.length > 0
    ) {
      return (
        <div style={{ marginTop: 50 }}>
          <div style={{ textAlign: "center" }}>
            {state.task.trigger_time ? (
              <>
                On{" "}
                <span style={{ color: "#11a2d2" }}>
                  {userDate({
                    timestamp: state.task.trigger_time,
                    timezone: state.task.timezone
                  })}
                </span>
                ,{" "}
              </>
            ) : (
              "When triggered (no specific time set)"
            )}
            {state.task.messages.length} message
            {state.task.messages.length > 1 ? "s" : ""} will be sent
            {state.skillsLog && state.skillsLog.length > 0
              ? " with skills"
              : ""}
            <SkillsAppliedToTask task={state.task} history={history} />
          </div>
          <Emails
            tasks={[state.task]}
            onMailtoSendSuccess={reloadRenderedTask}
            onMailtoSendError={handleMailtoError}
            loggedInUser={props.loggedInUser}
            handleOnMessageRemove={toggleCcBcc}
          />

          <div style={{ padding: "0 15px" }}>
            {attachments ? (
              <div>
                <Label>Attachments</Label>
                <ul
                  style={{
                    padding: 0,
                    listStyleType: "none"
                  }}
                >
                  <ExpandableList
                    items={attachments}
                    maxItems={5}
                    renderItem={attachment => (
                      <li
                        key={attachment.name}
                        style={{
                          padding: "2px 0",
                          marginBottom: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <GreyLink
                          href={`${
                            process.env.REACT_APP_DEV_PROXY_SERVER || ""
                          }/attachment/${state.task.id}/${encodeURIComponent(
                            attachment.name
                          )}/${attachment.token}`}
                        >
                          <span className="glyphicon glyphicon-file"></span>{" "}
                          {attachment.name}
                        </GreyLink>
                      </li>
                    )}
                  ></ExpandableList>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      );

      // Fail if there are no messages. This is deliberate. All mailbots to
      // implement a view handler.
    } else {
      return <WebhookFailed />;
    }
  };

  const hasMessages = () => state.task.messages && state.task.messages.length;

  const WebhookFailed = () => (
    <FooterMessage>
      <h3>Could Not Load Preview</h3>
      <p>
        We had trouble generating a preview of this followup. You can, still
        edit, complete or delete this followup using the links provided on this
        page.
      </p>
    </FooterMessage>
  );

  const AlertBanner = props => (
    <Alert
      style={{
        margin: "20px auto 20px",
        position: "relative",
        top: 18,
        width: "92%",
        textAlign: "center"
      }}
      level={props.level || "grey"}
    >
      {props.children}
    </Alert>
  );

  const TaskPastDueBanner = () => (
    <AlertBanner>
      ⚠️ It appears the due date for this task has passed. Refresh the page for
      updated information, or contact{" "}
      <a href="mailto:help@humans.fut.io">help@humans.fut.io</a> for help.
    </AlertBanner>
  );

  const reschedulingFromLink = () => {
    const qs = queryString.parse(window.location.search);
    if (qs.behavior === "action") return true;
    return false;
  };

  const CustomizeShortcutsTip = () => (
    <AlertBanner>
      ⚡️ Tip: Streamline your workflow with{" "}
      <Link to="/settings/customize">custom postpone options</Link> and{" "}
      <a
        onClick={() =>
          setState(state => {
            state.actionEmailsDialogVisible = true;
            return state;
          })
        }
      >
        email based actions
      </a>
      .
    </AlertBanner>
  );

  const getWarningMessage = () => {
    const warning = queryString.parse(window.location.search).warning;
    return warning ? window.decodeURIComponent(warning) : "";
  };

  const WarningBanner = ({ message }) => (
    <AlertBanner>⚠️ {message}</AlertBanner>
  );

  const getLoadingOverlay = () => {
    const verboseBodyLoading = !state.task.reference_email.html && state.isBusy;
    return verboseBodyLoading ? "...loading" : null;
  };

  const showTaskDetailsSidebar = () => {
    if (isOnMobile()) {
      return false;
    } else if (state.expandTaskDetailsSidebar) {
      return true;
    } else {
      return false;
    }
  };
  const TaskDetailsSidebar = () => (
    <>
      <li>
        <a
          onClick={() => {
            setState({
              showRecipientModal: true,
              isAddingNonRecipient: false
            });
            logger.log("followups details add recipient button clicked");
          }}
        >
          <UserOutlined /> Manage Recipients
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            fileInputRef.current.click();
            logger.log("followups details add attachment button clicked");
          }}
        >
          <PaperClipOutlined /> <span>Add Attachment</span>{" "}
        </a>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={e => handleAttachmentUpload(e)}
        ></input>
      </li>
      <li>
        <a onClick={handleShowHistory}>
          <HistoryOutlined /> History
        </a>
      </li>
      <li>
        <a className="red-on-hover" onClick={handleDeleteOnClick}>
          <DeleteOutlined /> Delete
        </a>
      </li>
      {/* Show linked people in addition to non-recipients */}
      {userPaidForSkill(props.loggedInUser, "p") &&
      process.env.REACT_APP_SHOW_PEOPLE === "yes" &&
      false ? ( // @todo
        <li className="subnav-header">
          <p>
            Related People{" "}
            <a
              style={{ color: "#bbb" }}
              onClick={() => {
                setState({
                  showRecipientModal: true,
                  isAddingNonRecipient: true
                });
                logger.log(
                  "followups details add non-recipient button clicked"
                );
              }}
            >
              <PlusOutlined />
            </a>
          </p>
        </li>
      ) : null}
      {state.skillsLog && state.skillsLog.length ? (
        <>
          <li className="subnav-header" style={{ paddingTop: 20 }}>
            Skills
          </li>
          <li>
            <SkillsApplied skillsLog={state.skillsLog} />
          </li>
        </>
      ) : null}
    </>
  );

  return (
    <div>
      {/* task container */}
      {state.task.id ? (
        <div className="main-container" style={{ maxWidth: 550 }}>
          {/* // banners */}
          {state.task.iscompleted && renderArchivedTaskBar()}
          {state.actionDialogMessage &&
            state.task.id /** Prevent UI thrashing when basicTask loads */ && (
              <AlertBanner>{state.actionDialogMessage}</AlertBanner>
            )}
          {/* {state.task.completed && <TaskArchivedBanner />} */}
          {incompleteAndPastDue(state.task) && <TaskPastDueBanner />}
          {reschedulingFromLink() && <CustomizeShortcutsTip />}
          {getWarningMessage() && (
            <WarningBanner message={getWarningMessage()} />
          )}
          {state.showPreview && (
            <Modal
              visible={true} // parent hides and shows this component
              onClose={handleClosePreview}
              style={{ zIndex: 1100 }}
            >
              <TaskPreview
                task={state.previewTask}
                loggedInUser={props.loggedInUser}
                handleOnClose={handleClosePreview}
              />
            </Modal>
          )}
          {state.task.delivery_status === "error" && (
            <AlertBanner>
              ⚠️ Email delivery errors occurred for this task{" "}
              <Link to={`/delivery-logs?taskid=${state.task.id}`}>Details</Link>
            </AlertBanner>
          )}

          {/* task top menubar */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              alignItems: "center",
              height: 43
            }}
          >
            {/* editing date format state  */}
            {state.showScheduleWidget ? (
              <>
                <ScheduleWidget
                  style={{
                    flexGrow: 1,
                    marginRight: 4,
                    marginLeft: 4,
                    borderRadius: 4,
                    boxShadow:
                      "0 1px 20px hsl(0deg 0% 0% / 5%), 0 2px 4px rgb(0 0 0 / 5%)",
                    overflow: "hidden"
                  }}
                  getTask={() => state.task}
                  onChange={cmd =>
                    setState(state => {
                      state.task.command = cmd;
                      return state;
                    })
                  }
                  onSubmit={handleCommandOnSubmit}
                  submitOnSelect={true}
                  inputRef={ref => {
                    // auto-open and focus on mount
                    if (ref) {
                      ref.focus();
                    }
                  }}
                />
                <Tooltip title="Close scheduler">
                  <ButtonOnBg
                    icon="CloseOutlined"
                    onClick={() => {
                      setState({ showScheduleWidget: false });
                      history.push(`/tasks/${state.task.id}`); // clear url params for ?reschedule=true scenario
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
            {!state.showScheduleWidget ? (
              <>
                <a
                  onClick={() => history.goBack()}
                  style={{
                    color: "#333",
                    display: "inline-block"
                  }}
                >
                  <ButtonOnBg icon="LeftOutlined" />
                </a>
                {state.task.completed ? (
                  <Tooltip
                    title={
                      <>
                        Completed on:
                        <br />
                        {userDate({
                          timestamp: state.task.trigger_time
                        })}
                        . <br />
                        Reschedule to restore. Completed followups are retained
                        for 6 weeks.
                      </>
                    }
                  >
                    <ButtonOnBg
                      style={{
                        backgroundColor: "rgb(230, 230, 230)",
                        marginLeft: 2
                      }}
                      text="Task Completed"
                    />
                  </Tooltip>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flex: 1
                  }}
                >
                  <Tooltip title="Reschedule">
                    <ButtonOnBg
                      icon="ClockCircleOutlined"
                      text={
                        state.task.command
                          ? state.task.command.split("@")[0]
                          : "(no command)"
                      }
                      onClick={() => {
                        // wait for ref to be available
                        setTimeout(() => {
                          setState({ showScheduleWidget: true });
                        }, 50);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Cancel Followup">
                    <a onClick={handleCompleteToggle}>
                      {/* <ButtonOnBg icon="CheckCircleOutlined" /> */}
                      <ButtonOnBg icon="StopOutlined" />
                    </a>
                  </Tooltip>{" "}
                  <Tooltip title="Follow up now">
                    <a onClick={handleFollowUpNow}>
                      <ButtonOnBg icon="SendOutlined" />
                    </a>
                  </Tooltip>{" "}
                  <a
                    href=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    <Tooltip title="Recipients, history and more">
                      <ButtonOnBg
                        style={{ fontSize: 22 }}
                        icon="EllipsisOutlined"
                      />
                    </Tooltip>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    {TaskDetailsSidebar()}
                  </ul>{" "}
                </div>
              </>
            ) : null}
          </div>
          <div>
            {/* editor and task preview */}
            <EmailEditor
              email={state.task.reference_email}
              contentOnly={true}
              loadingOverlay={getLoadingOverlay()}
              handleSubmitEmail={async (e, email) => {
                e.preventDefault();
                await handleSaveReferenceEmail(email);
                setState({ referenceEmailChanged: false });
              }}
              handleSubmitAttachment={handleAttachmentUpload}
              contentChanged={state.referenceEmailChanged}
              onClick={e => setState({ referenceEmailChanged: true })}
              innerStyle={{
                maxHeight: state.referenceEmailChanged ? "60vh" : "30vh",
                overflowY: state.referenceEmailChanged ? "auto" : "hidden",
                transition: "max-height 0.5s"
              }}
            />
            {state.task && state.task.messages && state.task.messages.length ? (
              MainContent()
            ) : (
              <Card style={{ marginTop: 75 }} innerStyle={{ margin: 0 }}>
                <h2 style={{ color: "#aaa" }}>Loading preview...</h2>
                <p style={{ color: "#aaa" }}>Followup actions are available.</p>
                <Skeleton count={10} />
              </Card>
            )}

            {/* task details sidebar */}
            {state.expandTaskDetailsSidebar && !isOnMobile() ? (
              <ul
                className="task-sidebar"
                style={{
                  position: "absolute",
                  top: 0,
                  right: -260,
                  listStyle: "none",
                  paddingLeft: 0
                }}
              >
                {TaskDetailsSidebar()}
              </ul>
            ) : null}
          </div>

          {/* Modals */}

          <Modal visible={state.showHistoryModal} onClose={handleHideHistory}>
            <Card
              type="large"
              style={{
                maxHeight: "90vh",
                width: "90vw",
                maxWidth: 600
              }}
            >
              <h2>Task History</h2>
              <div
                style={{
                  position: "absolute",
                  top: 60,
                  right: 120
                }}
              >
                <Link to={`/delivery-logs?taskid=${state.task.id}`}>
                  View Delivery Logs
                </Link>
              </div>
              <hr />
              <div style={{ height: "70vh", overflowY: "auto" }}>
                {state.taskHistory.length &&
                  state.taskHistory.map((event, i) => (
                    <EventItem event={event} key={i} />
                  ))}
              </div>
            </Card>
          </Modal>

          <ManageRecipientsModal
            referenceEmail={state.task.reference_email}
            command={state.task.command}
            visible={state.showRecipientModal}
            toggleCcBcc={toggleCcBcc}
            onCloseModal={() => setState({ showRecipientModal: false })}
            isAddingNonRecipient={state.isAddingNonRecipient}
            onSubmit={async refEmail => {
              await handleSaveReferenceEmail(refEmail);
              setState({ showRecipientModal: false });
            }}
          ></ManageRecipientsModal>
        </div>
      ) : (
        <Loading loadingText="Loading..." />
      )}
    </div>
  );
}

function EventItem({ event }) {
  const [open, setOpen] = useState(false);
  const { loggedInUser } = useContext(GlobalContext);
  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        key={event.created}
        className="delivery-log-item"
        style={{
          display: "grid",
          gridTemplateColumns: "65% 35%",
          alignItems: "left",
          padding: 4
        }}
      >
        <div className="text-muted">
          {userDate({
            timestamp: event.created,
            timezone: loggedInUser.timezone
          })}
        </div>{" "}
        <div>{event.type}</div>
        {/* <div>
          <a onClick={() => setOpen(!open)}>details...</a>
        </div> */}
      </div>
      {open ? (
        <pre style={{ backgroundColor: "#fafafa", padding: 20 }}>
          {JSON.stringify(event.data, null, 4)}
        </pre>
      ) : null}
    </>
  );
}

const ExpandableRecipientsList = ({
  recipients,
  removable = true,
  handleRemoveRecipient
}) => {
  const [busyResource, setBusyResource] = useState(); // clicked, spinner running
  const [hoveredResource, setHoveredResource] = useState(); // hovering, view "x"
  const history = useHistory();

  return (
    <ExpandableList
      items={recipients}
      maxItems={3}
      renderItem={recipient => (
        <div
          key={recipient}
          style={{ display: "flex" }}
          onMouseEnter={() => setHoveredResource(recipient)}
          onMouseLeave={() => setHoveredResource(null)}
        >
          <Tooltip title="View related followups">
            <a
              onClick={() => {
                history.push(`/tasks?search=${encodeURIComponent(recipient)}`);
              }}
              style={{
                overflow: "hidden",
                color: "#5d5d5d",
                textOverflow: "ellipsis"
              }}
            >
              {recipient || ""}
            </a>
          </Tooltip>
          {removable ? (
            <a
              style={{
                color: "#bbb",
                marginTop: 3,
                marginLeft: 3,
                textDecoration: "none",
                visibility: recipient === hoveredResource ? "visible" : "hidden"
              }}
              onClick={() => {
                handleRemoveRecipient(recipient);
                setBusyResource(recipient);
              }}
            >
              {busyResource === recipient ? (
                <div className="loader" style={{ margin: "0" }}></div>
              ) : (
                <Tooltip title="Remove recipient">
                  <span style={{ fontSize: "20px", lineHeight: "20px" }}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </a>
          ) : null}
        </div>
      )}
    ></ExpandableList>
  );
};
