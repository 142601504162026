import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Nav from "./Nav";
import Settings from "./Settings";
import MailBots from "./MailBots";
import MailBotDetailsContainer from "./MailBotDetailsContainer";
import DevPortal from "./DevPortal";
import Footer from "./Footer";
import Sandbox from "./Sandbox";
import Confirm from "./Confirm";
import LoginContainer from "./LoginContainer";
import DevRequestInvite from "./DevRequestInvite";
import cookie from "js-cookie";
import _ from "lodash";
import "./style.css";
import "../node_modules/nprogress/nprogress.css";
import "../node_modules/nprogress/nprogress.js";
import {
  mailbotsAdminBrowser,
  getAccessToken,
  setAccessToken,
  axiosCache,
  pruneAuthorizedAccounts,
  addAuthorizedAccount,
  getAuthorizedAccounts,
  getAuthAccountToken,
  setAuthAccouns,
  smartLogin
} from "./lib/utils";
import { logger } from "./lib/Logger";
import GetAuthToken from "./GetAuthToken";
import AuthorizeMailBot from "./AuthorizeMailBot";
import TaskContainer from "./TaskContainer";
import Tasks from "./Tasks/Tasks";
import People from "./People/People";
import Person from "./People/Person";
import { message as antMessage } from "antd";
import FooterMessage from "./FooterMessage";
import queryString from "query-string";
import ModalSimple from "./ModalSimple";
import nprogress from "nprogress";
import HandleMailto from "./HandleMailto";
import BannerFactory from "./BannerFactory";
import AddPerson from "./People/AddPerson";
import { invalidateTaskCache } from "./Tasks/TaskCache";
import SettingsAccount from "./Billing/SettingsAccount";
import SettingsTeam from "./SettingsTeam";
import {
  cacheStripeBillingPlans,
  calculateTeamTotalPrice,
  getMyPlans,
  getMySubscriberDetails,
  getPriceForAugmentedPlans,
  isEmployee,
  isTeamOwner
} from "./Billing/billingHelpers";
import { BannerUpgrade } from "./BannerUpgrade";
import { ModalTourFactory } from "./ModalTours/ModalTourFactory";
import { ModalManager, setShowUpgradeModal } from "./ModalManager";
import { renderHandleConfirm } from "./HandleConfirm";
import { DeliveryLogs } from "./DeliveryLogs";
import { ShowChoosePlanDialogIfInQS } from "./Billing/UpgradeDialogs";

export const GlobalContext = React.createContext({
  loggedInUser: undefined,
  reloadUser: undefined,
  availableSkills: []
});

// console.log(process.env.REACT_APP_FUT_MAILBOT_ID);
// debugger;
// TODO: Move to core API db
const TRUSTED_EXTENSIONS = process.env.REACT_APP_TRUSTED_EXTENSIONS
  ? process.env.REACT_APP_TRUSTED_EXTENSIONS.split(",")
  : [];

const emptyFn = () => {};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loggedInUser: null,
      loading: true,
      optedInToFutV3: true,
      migratedBackToV2: false,
      availableSkills: []
    };
  }

  // @todo: trusted is now a MailBot attribute in the db. No longer needs to be in .env
  isTrustedMailBot = clientId => {
    return TRUSTED_EXTENSIONS.includes(clientId);
  };

  loginRequired = () => {
    const unrestrictedUrls = [
      "/auth/callback",
      "/login",
      "/login/signup",
      "/auth",
      "/auth/signup",
      "/register",
      "/mailbots",
      "/handle-mailto"
    ];
    const isUnrestrictedUrl = unrestrictedUrls.some(url =>
      window.location.pathname.startsWith(url)
    );
    return !isUnrestrictedUrl;
  };

  initIntercomMessenger = () => {
    // Do not init Intercom on local dev (for now)
    if (process.env.REACT_APP_MAILBOTS_API_BASE.includes("ngrok")) return;
    const INTERCOM_APP_ID = "j0igcs5c";
    if (this.state.loggedInUser) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
        name: this.state.loggedInUser.name, // Full name
        user_id: this.state.loggedInUser.id, // Email address
        email: this.state.loggedInUser.email, // Email address
        created_at: this.state.loggedInUser.created, // Signup date as a Unix timestamp
        user_hash: this.state.loggedInUser.user_hash // Signup date as a Unix timestamp
      });
    }
  };

  /**
   * Init complete login state, accounting for a user being logged into more than one account
   * @global This method looks in the URL for a "gfr" value. If set, it sets the mailbotsToken
   * cookie to that gfr email.
   * @param {Object} params - Destructured params object
   * @param {string} params.accessToken
   * @param {boolean} params.anonUserOk don't show error and redirect user to login page, just return null
   * @returns {Promise} - Resolves to the logged in user object or null in the case of anon user
   */
  reloadUser = async ({
    accessToken: accessTokenParam = null,
    anonUserOk = false
  } = {}) => {
    invalidateTaskCache();
    axiosCache.store.clear();

    // Use ?grf=email@email.com to set proper accessToken cookie
    const qs = queryString.parse(window.location.search);
    const fromEmailQs = qs.gfr || null;
    const authAccounts = getAuthorizedAccounts();
    const accessToken =
      accessTokenParam || // access token from params
      getAuthAccountToken(fromEmailQs, authAccounts) || // access token from authAccounts cookie with corresponding ?gfr email
      getAccessToken(); // fall back to mailbotsToken cookie (the usual)
    if (accessToken) setAccessToken(accessToken); // reset cookie expiry

    try {
      if (!accessToken) throw Error("no access token");
      mailbotsAdminBrowser.setAccessToken(accessToken);
      let { user: loggedInUser } = await mailbotsAdminBrowser.getLoggedInUser();
      if (!loggedInUser || !loggedInUser.email) return Promise.resolve({});

      // maintain authAccount cookie for multi-account login
      pruneAuthorizedAccounts({ alreadyLinkedEmails: loggedInUser.emails });
      const authAccounts = addAuthorizedAccount({
        token: accessToken,
        emails: loggedInUser.emails,
        existingAccounts: getAuthorizedAccounts()
      });
      setAuthAccouns(authAccounts);
      // check if current account owns ?gfr=email@email.com param
      if (fromEmailQs && !this.accountOwnsEmail(loggedInUser, fromEmailQs)) {
        logger.log("account does not own gfr email", {
          data: {
            userId: loggedInUser.id,
            data: { fromEmailQs }
          }
        });
        smartLogin({
          email: fromEmailQs,
          redirect: window.location.pathname + window.location.search,
          message: `⚠️ Multiple FollowUpThen accounts detected. Please login with your ${fromEmailQs}
              address below.`
        });
        return;
      }
      // gfr param needed for anon users in actions page
      if (!anonUserOk) this.pruneFromEmailQs(loggedInUser, fromEmailQs);

      this.handleFutV3OptIn({ loggedInUser });

      // Append billing info to loggedInUser @todo - move to core-api
      const billingRes = await mailbotsAdminBrowser.getBillingInfo();
      if (!loggedInUser || !loggedInUser.billing) {
        return Promise.resolve();
      }
      loggedInUser.billing = billingRes.billing;

      // calculate remaining skill budget, add to loggedInUser
      if (isEmployee(loggedInUser)) {
        try {
          const mySubscriberDetails = getMySubscriberDetails(loggedInUser);
          if (!mySubscriberDetails)
            throw Error("No subscriber details available");
          const { budget_cent = 0, budget_used_cent = 0 } = mySubscriberDetails;
          let budget_remaining_cent = budget_cent - budget_used_cent;
          // prevent negative budget
          budget_remaining_cent =
            budget_remaining_cent < 0 ? 0 : budget_remaining_cent;
          loggedInUser.billing.budget_cent = budget_cent;
          loggedInUser.billing.budget_used_cent = budget_used_cent;
          loggedInUser.billing.budget_remaining_cent = budget_remaining_cent;
        } catch (e) {
          loggedInUser.billing.budget_cent = 0;
          loggedInUser.billing.budget_used_cent = 0;
          loggedInUser.billing.budget_remaining_cent = 0;
          console.log(e);
          // debugger;
        }
      }

      // const util = require("util");
      // console.log(
      //   "copy for tests - loggedInUser:",
      //   util.inspect(loggedInUser, { depth: null })
      // );
      this.setState(
        {
          loggedIn: true,
          loggedInUser,
          loading: false
        },
        () => {
          // this.initIntercomMessenger();
        }
      );
      logger.setUserId(loggedInUser.id);
      logger.log("authorized user");
      return Promise.resolve(loggedInUser);
    } catch (error) {
      if (anonUserOk) return null;

      console.error("unable to login", error.message);

      // for auth errors, we just ask to login and send along
      const onlyLoginProblem = error => {
        if (error.message === "Your login information was incorrect.")
          return true; // api returns unauthorized
        if (error.message === "no access token") return true; // browser
        return false;
      };

      if (onlyLoginProblem(error)) {
        cookie.remove("mailbotsToken");
        logger.log("not authorized, redirect to smart login", {
          data: {
            userEmail: fromEmailQs,
            redirect_str: window.location.pathname + window.location.search
          },
          error
        });
        smartLogin({
          redirect: window.location.pathname + window.location.search,
          email: fromEmailQs,
          message: "Login and we'll send you on your way..."
        });
      } else {
        console.error(error.message);
        antMessage.error(
          <span style={{ maxWidth: 300 }}>
            We ran into an unexpected error logging you in. Please try{" "}
            <a href="" onClick={this.handleLogout}>
              logging out
            </a>
            . If this persists, please{" "}
            <a
              href="https://help.followupthen.com/contact-support/"
              target="_blank"
              rel="nofollower noopener noreferrer"
            >
              {" "}
              contact us
            </a>
            . The error message was: {error.message}
          </span>
        );
        logger.log("unexpected reloadUser error", {
          level: "error",
          data: {
            userEmail: fromEmailQs,
            redirect_str: window.location.pathname + window.location.search
          },
          error
        });
        return Promise.reject(error.message);
      }
    }
  };

  // if they arrived with a link saying where they came from (ex: ?gfr=from@gmail.com)
  // try to log them in using that email and a previous auth token.
  pruneFromEmailQs = (loggedInUser, fromEmailQs) => {
    if (this.accountOwnsEmail(loggedInUser, fromEmailQs)) {
      // strip ?gfr= param
      let qs = queryString.parse(window.location.search);
      delete qs.gfr;
      const hasQs = Object.keys(qs).length;
      const baseUrl = window.location.pathname;
      const newPath = baseUrl + hasQs ? `?${queryString.stringify(qs)}` : "";
      this.props.history.push(newPath);
    }
  };

  handleLogout = e => {
    // invalidate caches
    invalidateTaskCache();
    axiosCache.store.clear();

    e.preventDefault();
    mailbotsAdminBrowser.logout(); // async invalidate user's access tokens
    cookie.remove("mailbotsToken");
    cookie.remove("authAccounts");
    cookie.remove("ignoreDupAccounts");
    this.props.history.push("/login");
    this.setState({
      loggedIn: false,
      loggedInuser: null,
      loading: false
    });
    logger.unSetUserId();
  };

  handleAnonUser = () => {
    mailbotsAdminBrowser.setAccessToken(undefined);
    this.setState({
      loggedIn: false,
      loggedInUser: undefined
    });
  };

  /**
   * When we email inks to admin UI, we append ?gfr=email@email.com so that we know which
   * email address we're interacting with. It's the only way of reliably juggling multiple
   * email accounts, due to forwarders. etc, Ref: https://github.com/mailbots/fut-core-api/issues/1346
   */

  accountOwnsEmail(loggedInUser, email) {
    if (!loggedInUser) return false;
    const linkedEmails = loggedInUser.emails;
    return linkedEmails.some(
      linkedEmail =>
        String(linkedEmail).toLowerCase() === String(email).toLowerCase()
    );
  }

  // Load context when coming from an auto-login link
  // https://github.com/mailbots/fut-admin-ui/issues/196
  async UNSAFE_componentWillReceiveProps() {
    if (this.state.availableSkills && this.state.availableSkills.length) return;
    this.initApp();
  }

  componentDidMount = async () => {
    // if we're getting an auth code, a new user may be getting initialized, so don't auto-init
    if (window.location.href.includes("/auth/callback")) return;
    this.initApp();
  };

  initApp = async () => {
    // keep dev + prod tabs sane during local dev
    if (process.env.REACT_APP_NODE_ENV === "dev") {
      setTimeout(() => (document.title = `(dev) ${document.title}`), 5000);
    }

    // cache all billing plans defined by core-api. Await here to prevent race conditions
    await cacheStripeBillingPlans();

    if (window.location.href.includes("/mailbots")) {
      const properLocation = window.location.href.replace("mailbots", "skills");
      console.warn("Redirecting from old MailBots URL"); // @todo track these down. Currently exists while installing
      window.location.href = properLocation;
    }

    // load user-facing skills into GlobalContext
    try {
      const availableSkills = await mailbotsAdminBrowser.getAvailableSkills();
      this.setState({ availableSkills });
    } catch (e) {
      logger.log("error loading available skills", {
        level: "error",
        data: { errorObj: e }
      });
    }

    if (this.loginRequired()) {
      try {
        this.reloadUser();

        // Invitation keys for developer access to MailBots beta.
        const { devInvite } = queryString.parse(window.location.search);
        if (devInvite) {
          cookie.set("devInvite", devInvite);
        }
      } catch (e) {
        console.error(e);
        antMessage.error(
          `Oops! We hit something unusual. Logout, and refresh. If this happens again, email help@humans.fut.io. The error was: ${e.message}`
        );
        // this.reloadUser();
        // this.setState({
        //   loggedIn: false
        // });
      }
      // only page where an anon user is allowed
    } else if (window.location.pathname.startsWith("/mailto")) {
      this.handleAnonUser();
    } else {
      // unauthorized user on routes where no auth is needed (no action)
    }
  };

  /** Tmp: Only for the transition to the new FUT */
  handleFutV3OptIn({ loggedInUser }) {
    if (!loggedInUser) return;
    let optedInToFutV3;
    if (loggedInUser.opt_into_fut_mailbot) {
      optedInToFutV3 = true;
    } else {
      optedInToFutV3 = false;
    }
    this.setState({ optedInToFutV3 });
  }

  switchFutVersions = async (e, optIn = true) => {
    if (e && e.preventDefault) e.preventDefault();
    nprogress.start();
    try {
      // const res1 = await new Promise(resolve => setTimeout(resolve, 20000));

      let user = { opt_into_fut_mailbot: optIn };
      if (isTeamOwner(this.state.loggedInUser))
        user.opt_into_billing_v3 = optIn;
      logger.log("switching fut versions", { data: { userObj: user } });
      const res = await mailbotsAdminBrowser.userUpdateSelf({ user });

      if (optIn) {
        this.setState({ optedInToFutV3: optIn });
        this.props.history.push("/skills?welcome=migrated-user");

        // they are opting out
      } else {
        this.setState({ optedInToFutV3: optIn, migratedBackToV2: true });
        // this.props.history.push("/tasks");
      }

      this.reloadUser();

      nprogress.done();
    } catch (e) {
      nprogress.done();
      console.error(e.message);
      if (e.message === "Only team owner can update opt_into_billing_v3") {
        antMessage.error(
          "It looks like you are a member of a Team plan. Please contact your plan administrator to migrate your account."
        );
      } else {
        antMessage.info(
          `Your account migration may either be still in progress, or we ran into an error. Please wait a few minutes,
        then refresh this page. If your account has not been migrated, contact help@humans.fut.io for help.`
        );
      }
    }
  };

  switchToFutV2 = e => this.switchFutVersions(e, false);
  switchToFutV3 = e => this.switchFutVersions(e, true);

  render() {
    const mainApp = (
      <div
        id="page-container"
        onMouseDown={
          emptyFn /* dont remove, since we want React to attach mousedown events earlier than some document.addEventListener code in our app */
        }
      >
        {this.state.migratedBackToV2 && (
          <ModalSimple canClose={false}>
            <h3>Everything Is Back to Normal 👍</h3>
            <p>
              {" "}
              We'd love to hear your feedback! Email:{" "}
              <a href="mailto:help+v3@humans.fut.io">help+v3@humans.fut.io</a>.
            </p>
            <a
              className="btn btn-primary"
              href="https://www.followupthen.com/login"
              rel="noopener noreferrer"
            >
              Back To Original FollowUpThen
            </a>

            <div
              style={{
                padding: 30,
                color: "#aaa"
              }}
            >
              <br />
              <a
                href="https://www.followupthen.com/pending"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.switchToFutV3}
              >
                Stay on FollowUpThen 3.0
              </a>
            </div>
          </ModalSimple>
        )}

        <ModalTourFactory switchToV3={this.switchToFutV3} />
        <ShowChoosePlanDialogIfInQS />

        <Nav
          loggedInUser={this.state.loggedInUser}
          handleLogout={this.handleLogout}
          loading={this.state.loading}
          switchToFutV3={this.switchToFutV3}
          switchToFutV2={this.switchToFutV2}
        />
        {this.state.loggedInUser && (
          <div>
            <section className="app-section">
              <BannerFactory loggedInUser={this.state.loggedInUser} />
              <BannerUpgrade loggedInUser={this.state.loggedInUser} />

              <Switch>
                <Route
                  path="/settings"
                  render={({ match }) => (
                    <Settings
                      reloadUser={this.reloadUser}
                      loggedInUser={this.state.loggedInUser}
                      match
                    />
                  )}
                />
                <Route
                  path="/billing"
                  render={({ match }) => (
                    <SettingsAccount
                      reloadUser={this.reloadUser}
                      loggedInUser={this.state.loggedInUser}
                      match
                    />
                  )}
                />
                <Route
                  path="/team"
                  render={({ match }) => (
                    <SettingsTeam
                      reloadUser={this.reloadUser}
                      loggedInUser={this.state.loggedInUser}
                      match
                    />
                  )}
                />
                <Route
                  path="/skills/installed"
                  render={({ match }) => (
                    <MailBots
                      installed="1"
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Route
                  path="/skills/tag/:tag"
                  render={({ match }) => (
                    <MailBots
                      tag={match.params.tag}
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Route
                  path="/skills/:id"
                  render={({ match }) => (
                    <MailBotDetailsContainer
                      mailbotid={match.params.id}
                      loggedInUser={this.state.loggedInUser}
                      reloadUser={this.reloadUser}
                    />
                  )}
                />
                <Route
                  path="/tasks/:taskId"
                  render={({ match }) => (
                    <TaskContainer
                      key={match.params.taskId}
                      taskId={match.params.taskId}
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Route
                  path="/tasks"
                  render={() => (
                    <Tasks loggedInUser={this.state.loggedInUser} />
                  )}
                />
                <Route
                  path="/people/new"
                  render={() => (
                    <AddPerson loggedInUser={this.state.loggedInUser} />
                  )}
                />
                <Route
                  path="/people/e/:personEmail"
                  render={({ match }) => (
                    <Person
                      personEmail={match.params.personEmail}
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Route
                  path="/people/:personId"
                  render={({ match }) => (
                    <Person
                      personId={match.params.personId}
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Route
                  path="/people"
                  render={() => (
                    <People loggedInUser={this.state.loggedInUser} />
                  )}
                />
                <Route
                  path="/developer"
                  render={() => {
                    if (
                      this.state.loggedInUser &&
                      this.state.loggedInUser.gopher_dev
                    ) {
                      return (
                        <DevPortal loggedInUser={this.state.loggedInUser} />
                      );
                    } else {
                      return (
                        <DevRequestInvite
                          loggedInUser={this.state.loggedInUser}
                          reloadUser={this.reloadUser}
                        />
                      );
                    }
                  }}
                />
                <Route
                  path="/skills"
                  render={() => (
                    <MailBots loggedInUser={this.state.loggedInUser} />
                  )}
                />
                <Route path="/delivery-logs" render={() => <DeliveryLogs />} />
                <Route
                  path="/sandbox/:mailbotId?"
                  render={({ match }) => (
                    <Sandbox
                      match={match}
                      loggedInUser={this.state.loggedInUser}
                    />
                  )}
                />
                <Redirect from="/" to="/tasks" />
              </Switch>
            </section>
          </div>
        )}
      </div>
    );

    return (
      <GlobalContext.Provider
        value={{
          reloadUser: this.reloadUser,
          loggedInUser: this.state.loggedInUser,
          availableSkills: this.state.availableSkills
        }}
      >
        <ModalManager />
        <React.Fragment>
          <Switch>
            <Route
              path="/request-beta-access"
              render={({ match }) => (
                <FooterMessage style={{ textAlign: "center" }}>
                  <img
                    style={{
                      float: "left",
                      opacity: 0.5,
                      width: 50,
                      margin: 10
                    }}
                    src="https://dl.dropbox.com/s/spn0pd5bpwkhvvp/icon_rocket.png"
                  />
                  <h1>Pre-Launch Access</h1>
                  <h4>
                    For early access, fill out our short survey to let us know
                    how you will use our platform.
                  </h4>
                  <p>
                    <br />
                    <button
                      className="btn btn-default"
                      onClick={() =>
                        (window.location.href =
                          "https://rsweetland1.typeform.com/to/amsj7O")
                      }
                    >
                      Developer Survey
                    </button>
                  </p>
                </FooterMessage>
              )}
            />
            <Route
              path="/authorize-mailbot"
              render={({ match }) => (
                <AuthorizeMailBot
                  {...match}
                  isTrustedMailBot={this.isTrustedMailBot}
                  loggedInUser={this.state.loggedInUser}
                />
              )}
            />
            <Route
              path="/auth/callback"
              render={({ match }) => (
                <GetAuthToken reloadUser={this.reloadUser} match />
              )}
            />
            <Route
              path="/confirm/:mailbotid?"
              render={({ match }) => (
                <Confirm
                  {...match}
                  isTrustedMailBot={this.isTrustedMailBot}
                  loggedInUser={this.state.loggedInUser}
                />
              )}
            />
            <Route
              path={["/login", "/auth"]}
              render={({ match }) => (
                <LoginContainer {...match} reloadUser={this.reloadUser} />
              )}
            />
            <Route
              path="/handle-mailto"
              render={({ match }) => <HandleMailto {...match} />}
            />
            <Route path="/handle-confirm" render={renderHandleConfirm} />
            <Route render={() => mainApp} />
          </Switch>
        </React.Fragment>
      </GlobalContext.Provider>
    );
  }
}

export default withRouter(App);
