import React, { useState } from "react";
import { ChoosePlanDialog } from "./Billing/ChoosePlanDialog";

/**
 * @todo
 * Refactor this to use a reducer. Allow any component to invoke a dialog, application-wide
 * by  const { ModalManager } = useContext(GlobalContext); ModalManager.show("modal-name");
 */

export let setShowUpgradeModal;

export const ModalManager = props => {
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
  setShowUpgradeModal = setUpgradeModalVisible;
  return (
    <>
      <ChoosePlanDialog
        isModalVisible={upgradeModalVisible}
        setIsModalVisible={setUpgradeModalVisible}
        onClose={() => setUpgradeModalVisible(false)}
      ></ChoosePlanDialog>
    </>
  );
};
