//Components to build a minimal-style form as shown in the right-column of task details
import styled from "styled-components";
import React from "react";

export const PostponeLink = styled.a`
  margin: 5px;
  background: #f1f1f1;
  color: #aaa;
  border-radius: 3px;
  padding: 5px 9px;
  display: inline-block;
  text-decoration: none;
  &:active {
    background: #ddd;
  }
  &:hover {
    background: #dfdfdfdf;
    text-decoration: none;
    color: #aaa;
    cursor: pointer;
  }
`;

export const Label = styled.span`
  text-transform: uppercase;
  color: #bbb;
  font-size: 11px;
  display: block;
  margin-top: 25px;
  margin-left: 1px;
  margin-bottom: 3px;
`;

export const Value = styled.p`
  color: #333;
  margin-left: 3px;
`;

export const ReadOnlyInput = styled.input`
  width: 100%;
  background-color: "transparent";
  padding: 7px;
  border: 0;
  font-family: monospace;
  color: #aaa;
  font-size: 12px;
`;

export const MinInput = styled.input.attrs({
  className: "form-control",
  autoComplete: "off"
  // spellCheck: props => props.spellCheck || true
})`
  border: 0;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
  &:focus {
    border-bottom: 1px solid #efefef;
  }
`;

export const MinTextarea = styled.textarea.attrs({ className: "form-control" })`
  border: 0;
`;

export const MicroHelpBlock = styled.span`
  font-size: 11px;
  color: #aaaaaa;
`;

export const InputButton = styled.button.attrs({
  className: "btn btn-primary btn-mailbots"
})`
  height: 45px;
  font-size: 11px;
  padding: 5px;
`;

export const InputButtonGrey = styled.button.attrs({
  className: "btn btn-primary"
})`
  height: 45px;
  font-size: 11px;
  padding: 5px;
  background-color: #aaa !important;
  &:hover: {
    background-color: #ccc !important;
  }
  &:focus: {
    background-color: #ccc !important;
  }
`;

export const GreyLink = styled.a`
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: #aaaaaa;
  margin: 10px 10px 10px 2px;
  font-size: "1em";
  cursor: pointer;
  transition: color 0.5s;
  &:visited {
    color: #aaaaaa;
  }
  &:hover {
    text-decoration: none;
    color: #555555;
  }
  @media screen and (max-width: 768px) {
    display: inline-block;
  }
`;

export const GreyLinkNarrow = styled(GreyLink)`
  margin: 2px;
`;

export const GreyNonLink = styled(GreyLink)`
  cursor: default;
  &:hover {
    cursor: default !important;
    color: #aaa;
    text-decoration: none;
  }
`;

export const GreyNonLinkSmall = styled(GreyNonLink)`
  overflow: hidden
  color: #5d5d5d;
  margin: 3px 3px;
  text-overflow: ellipsis;
`;

export const InputButtonSet = ({
  placeholder,
  innerRef,
  onChange,
  onSubmit,
  value,
  loading,
  name,
  buttonContent,
  buttonBackgroundColor
}) => (
  <form
    className="input-group"
    style={{ width: "100%", paddingBottom: 10 }}
    onSubmit={e => {
      e.preventDefault();
      onSubmit(e);
    }}
  >
    <MinInput
      placeholder={placeholder}
      innerRef={innerRef || null}
      name={name || "search"}
      onChange={onChange}
      value={value || ""}
    />
    <span className="input-group-btn">
      {loading ? (
        <div
          id="initial-loader"
          className="loader"
          style={{ marginLeft: -30, zIndex: 999 }}
        >
          {" "}
        </div>
      ) : (
        <InputButton
          onClick={onSubmit}
          style={{ backgroundColor: buttonBackgroundColor || "#ededed" }}
        >
          {buttonContent || (
            <span
              className="glyphicon glyphicon-search"
              style={{ width: 20, color: "#aaaaaa" }}
            />
          )}
        </InputButton>
      )}
    </span>
  </form>
);
