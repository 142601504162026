import React from "react";

// TODO: Use better dialog system
// import { ToastContainer, toast } from 'react-toastify';
// class Dialog extends Component {
//     notify = () => antMessage.error("Wow so easy !");

//     render(){
//       return (
//         <div>
//         <button onClick={this.notify}>Notify !</button>
//         {/* One container to rule them all! */}
//         <ToastContainer
//           position="top-right"
//           type="default"
//           autoClose={5000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick={false}
//           pauseOnHover
//         />

//         </div>
//       );
//     }
//   }

const Dialog = ({ dialogType, children }) => (
  <div className={`alert alert-dismissible ${dialogType}`}>
    <button type="button" className="close" data-dismiss="alert">
      &times;
    </button>
    {children}
  </div>
);

export default Dialog;
