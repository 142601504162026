import React, { Component } from "react";
import PropTypes from "prop-types";
import { mailbotsAdminBrowser } from "./lib/utils";
import LogRow from "./LogRow";
import { message as antMessage } from "antd";
import queryString from "query-string";
import _ from "lodash";
import Card from "./Card";
import { BlueCallout } from "./Layout";
import nprogress from "nprogress";

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      isLogsLoading: false,
      selectedFilter: {
        type: ["webhook", "submit_failed", "api"],
        level: [],
        mailbot: [],
        taskId: null
      },
      filterTaskId: ""
    };
  }

  async componentDidMount() {
    if (this.isRemoteMailBot()) this.setSimpleLogFilter();
    await this.populateFilterFromUrl();
    this.refreshLogs();
  }

  isRemoteMailBot = () =>
    this.props.skillMailBot.subdomain !== this.props.futMailBot.subdomain;

  setSimpleLogFilter = () => {
    this.setState(state => {
      state.selectedFilter.mailbot = this.props.skillMailBot.subdomain;
      state.selectedFilter.type = ["webhook", "submit_failed"];
      return state;
    });
  };

  updateLogsLoading = value => {
    this.setState({ isLogsLoading: value });
  };

  populateFilterFromUrl = () => {
    let qs = queryString.parse(window.location.search);
    if (!_.isEmpty(qs)) {
      return new Promise(resolve => {
        this.setState(state => {
          if (qs.role) state.role = qs.role;
          if (qs.userid) state.selectedFilter.userid = qs.userid;
          if (qs.taskid) state.selectedFilter.taskid = qs.taskid;
          if (qs.level) state.selectedFilter.level = qs.level;
        }, resolve);
      });
    }
  };

  refreshLogs = async () => {
    try {
      const { selectedFilter } = this.state;
      this.setState({
        isLogsLoading: true
      });

      let logRes;
      switch (this.props.role) {
        case "owner":
          // Events for all users for one mailbot (must be mailbot owner, and must include mailbotid)          if (this.props.mailbot)
          selectedFilter.mailbotid = this.props.mailbotid;
          logRes = await mailbotsAdminBrowser.getDeveloperLogs(selectedFilter);
          break;
        default:
          // Events for one user for all mailbots (can be any user);
          logRes = await mailbotsAdminBrowser.getLogs(selectedFilter);
          break;
      }

      // if (res instanceof Error) throw res;

      this.setState({
        logs: logRes.logs,
        isLogsLoading: false
      });
    } catch (e) {
      antMessage.error(e.message || JSON.stringify(e));
    }
  };

  handleFilterClick = e => {
    let selectedFilter = this.state.selectedFilter;
    let newFilter = e.target.hasAttribute("data-filter")
      ? e.target.getAttribute("data-filter")
      : null;
    let adding = e.target.checked;

    if (newFilter.indexOf("=") === -1) {
      throw Error("Filter condition must have equal sign");
      return;
    }

    let arr = newFilter.split("=");
    let key = arr[0];
    let val = arr[1];

    if (key.indexOf("[]") !== -1) {
      //handle checkboxes (always key=value pairs)
      key = key.replace(/\[\]/g, "");
      if (!selectedFilter.hasOwnProperty(key)) selectedFilter[key] = [];
      if (adding) {
        selectedFilter[key].push(val);
      } else {
        let i = selectedFilter[key].indexOf(val);
        // in corner case, where it is just a single key, unset the filter.
        if (typeof selectedFilter[key] === "string") selectedFilter[key] = [];
        selectedFilter[key].splice(i, 1);
      }
    } else {
      selectedFilter[key] = val;
    }
    this.setState({ selectedFilter }, () => {
      this.refreshLogs();
    });
  };

  handleFilterClickDebounced = _.debounce(this.handleFilterClick, 1000);

  handleRefreshLogsClick = async e => {
    this.refreshLogs();
  };

  render() {
    const { updateTasks, futMailBot, skillMailBot } = this.props;
    const { isLogsLoading, logs, selectedFilter } = this.state;
    return (
      <div
        className="panel-body main-panel"
        style={{ padding: "0 20px", marginTop: 5 }}
      >
        <ul className="nav nav-pills" style={{ marginTop: 20 }}>
          <li>
            <h2 style={{ marginRight: 20 }}>Logs</h2>
          </li>
          <li>
            <a
              className="btn btn-secondary"
              onClick={this.handleRefreshLogsClick}
            >
              Refresh <span className="glyphicon glyphicon-refresh" />
            </a>
          </li>
          <li className="dropdown filter">
            <a
              href=""
              className="dropdown-toggle btn-secondary btn"
              data-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              Filter
              <span className="caret" />{" "}
            </a>

            <ul className="dropdown-menu" role="menu">
              <React.Fragment>
                {/* <li className="section">taskId</li> */}
                <li>
                  <input
                    type="input"
                    placeholder="Filter by taskId"
                    style={{ margin: 5, width: "90%" }}
                    data-filter={`taskid=${this.state.filterTaskId}`}
                    value={this.state.filterTaskId}
                    onChange={e => {
                      e.persist();
                      this.setState(
                        {
                          filterTaskId: e.target.value
                        },
                        () => this.handleFilterClickDebounced(e)
                      );
                    }}
                  />{" "}
                </li>
              </React.Fragment>

              {futMailBot && (
                <React.Fragment>
                  <li className="section">MailBot </li>
                  <li>
                    <input
                      type="checkbox"
                      data-filter={`mailbot[]=${skillMailBot.subdomain}`}
                      value=""
                      onChange={this.handleFilterClick}
                      checked={
                        selectedFilter.mailbot.indexOf(
                          skillMailBot.subdomain
                        ) !== -1
                      }
                    />{" "}
                    &nbsp;
                    <label style={{ whiteSpace: "nowrap" }}>
                      only this skill
                    </label>
                  </li>
                </React.Fragment>
              )}
              <li className="section">TYPE</li>
              <li>
                <input
                  type="checkbox"
                  data-filter="type[]=webhook"
                  value=""
                  onChange={this.handleFilterClick}
                  checked={selectedFilter.type.indexOf("webhook") !== -1}
                />{" "}
                &nbsp;
                <label
                  data-toggle="tooltip"
                  title="Webhooks and errors on your account associated with any mailbot"
                >
                  webhooks
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  data-filter="type[]=api"
                  value="api"
                  onChange={this.handleFilterClick}
                  checked={selectedFilter.type.indexOf("api") !== -1}
                />{" "}
                &nbsp;
                <label>api calls</label>
              </li>
              <li className="section">LEVEL</li>
              <li>
                <input
                  type="checkbox"
                  data-filter="level[]=error"
                  value=""
                  onChange={this.handleFilterClick}
                  checked={selectedFilter.level.indexOf("error") !== -1}
                />{" "}
                &nbsp;
                <label
                  data-toggle="tooltip"
                  title="Webhooks and errors on your account associated with any mailbot"
                >
                  error
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  data-filter="level[]=success"
                  value="success"
                  onChange={this.handleFilterClick}
                  checked={selectedFilter.level.indexOf("success") !== -1}
                />{" "}
                &nbsp;
                <label>success</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  data-filter="level[]=info"
                  value="info"
                  onChange={this.handleFilterClick}
                  checked={selectedFilter.level.indexOf("info") !== -1}
                />{" "}
                &nbsp;
                <label>info</label>
              </li>
            </ul>
          </li>
        </ul>
        <div id="task-logs">
          {this.props.loggedInUser &&
          !this.props.loggedInUser.enable_gopherlog ? (
            <BlueCallout>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <p style={{ margin: 10 }}>
                  <strong>Enable Log Details</strong> <br />
                  This lets you inspect message bodies of your followups, but
                  stores followup information unencrypted (purged after two
                  weeks).{" "}
                </p>
                <button
                  onClick={async e => {
                    e.target.disabled = true;
                    e.target.innerText = "Enabling...";
                    nprogress.start();
                    await mailbotsAdminBrowser.enableGopherLogs();
                    antMessage.success("Logs enabled");
                    window.location.reload();
                    nprogress.done();
                  }}
                  className="btn btn-primary"
                  style={{ float: "right" }}
                >
                  Enable
                </button>
              </div>
            </BlueCallout>
          ) : null}
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="status">Status</th>
                <th className="summary" colSpan="3">
                  Summary
                </th>
                <th className="summary">Task</th>
                <th className="summary">User</th>
                <th className="summary">MailBot</th>
                <th className="date" style={{ width: 113 }}>
                  Date / Time
                </th>
              </tr>
            </thead>
            <tbody id="log-table">
              {isLogsLoading && (
                <tr>
                  <td colSpan={8}>
                    <div id="initial-loader" className="loader">
                      {" "}
                    </div>
                  </td>
                </tr>
              )}
              <LogList
                logs={logs}
                updateTasks={updateTasks}
                updateLogsLoading={this.updateLogsLoading}
                refreshLogs={this.handleRefreshLogsClick}
                loggedInUser={this.props.loggedInUser}
                broadcastEvent={this.props.broadcastEvent}
              />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class LogList extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.logs !== nextProps.logs;
  }
  render() {
    const { logs, updateTasks, updateLogsLoading, refreshLogs } = this.props;

    return (
      <React.Fragment>
        {logs.map(logRow => (
          <LogRow
            key={logRow.id}
            data={logRow}
            updateTasks={updateTasks}
            updateLogsLoading={updateLogsLoading}
            refreshLogs={refreshLogs}
            loggedInUser={this.props.loggedInUser}
            broadcastEvent={this.props.broadcastEvent}
          />
        ))}
      </React.Fragment>
    );
  }
}

Logs.propTypes = {
  logs: PropTypes.array
};

export default Logs;
