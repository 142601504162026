import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { logout } from "./lib/utils";
import { ButtonOnBg } from "./ButtonOnBg";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";

/**
 * NOTE:
 * Insert modals at this highest possible place in the DOM to avoid z-index stacking issues.
 *
 * Currently, this component at various depths within the DOM. Due to the nuances of z-index stacking,
 * a parent of this modal could create a new stacking context, effectively rendering stacking context
 * of this modal useless (outside of the newly created stacking context). See MDN docs on this.
 *
 * Note also that because this modal is positioned: fixed, it creates a new stacking context, which must
 * be explicitly set.
 *
 * Currently, Content should have z-index 0 and 1 (ie, button over text), Nav is z-index 2, This modal is z-index 3. Everything else is TBD.
 * */
const Modal = props => {
  let _modalContainer;

  // IF any modal is showing, hide global scrollbar
  let anyModalIsVisible = false;
  useEffect(() => {
    if (anyModalIsVisible || props.visible) {
      window.document.body.style.overflow = "hidden";
      anyModalIsVisible = true;
    } else {
      window.document.body.style.overflow = "initial";
    }
  }, [props.visible]);

  const handleClickOutsideModal = e => {
    if (e.target === _modalContainer) {
      if (props.onClose) props.onClose(e); // Let parent control visibility
    }
  };

  const StyledCloseButton = styled.a`
    position: absolute;
    top: 35px;
    right: 15px;
    color: #aaa;
    text-decoration: none;
    z-index: 1;
    transition: all 0.15s ease;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    &:hover {
      background-color: #f1f1f1;
      color: #333;
    }
    &:active {
      background-color: #e0e0e0;
      color: #333;
    }
  `;

  const styleProps = props.style || {};
  return (
    <div>
      {props.visible ? (
        <div
          ref={modalContainer => (_modalContainer = modalContainer)}
          onMouseDown={handleClickOutsideModal}
          style={{
            position: "fixed",
            zIndex: 4,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            height: "100vh",
            overflowY: "auto",
            backgroundColor: "rgba(0,0,0,0.2)",
            backdropFilter: "blur(2px)",
            ...styleProps
          }}
        >
          <div style={{ position: "relative" }}>
            {props.onClose && (
              <StyledCloseButton onClick={e => props.onClose(e)}>
                <CloseOutlined />
              </StyledCloseButton>
            )}
            {props.children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
