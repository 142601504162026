import React from "react";
import FilterLink from "../FilterLink";
import ExpandableList from "../ExpandableList";

const isAnyFilter = () => {
  return window.location.search.indexOf("tags") === -1;
};

const isFilterActive = (tag, existingFilter) => {
  if (
    !!existingFilter &&
    !!existingFilter.filter &&
    Array.isArray(existingFilter.filter)
  ) {
    return existingFilter.filter.some(filterObj => filterObj.value === tag);
  }

  return false;
};

export default ({
  tags = [],
  onApplyFilter = null,
  existingPeopleFilter = { filter: null }
}) => (
  <React.Fragment>
    <li className="subnav-header" style={{ paddingTop: 0 }}>
      Tags
    </li>
    <li>
      <FilterLink
        id="any"
        filterObj={{ filter: null }}
        isActive={() => isAnyFilter()}
        onFilter={onApplyFilter}
      >
        Any
      </FilterLink>
    </li>
    <ExpandableList
      items={tags}
      maxItems={10}
      renderItem={tag => (
        <li key={tag}>
          <FilterLink
            id="tag"
            filterObj={{
              filter: [{ attribute: "tags", operator: "inc", value: tag }]
            }}
            isActive={() => isFilterActive(tag, existingPeopleFilter)}
            onFilter={onApplyFilter}
          >
            {tag}
          </FilterLink>
        </li>
      )}
    />
  </React.Fragment>
);
