import React from "react";
import FilterLink from "../FilterLink";
import { naturalTs } from "../lib/utils";

const isAnyFilter = () => {
  return window.location.search.indexOf("filter_due") === -1;
};

const TaskFilterByDueDate = props => {
  return (
    <>
      <li className="subnav-header">Due</li>
      <li>
        <FilterLink
          id="any"
          filterObj={{
            filter_due: null
          }}
          onFilter={props.applyFilter}
          isActive={() => isAnyFilter()}
        >
          Any
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="due_today"
          filterObj={{
            filter_due: "due_today",
            due_after: naturalTs("now"),
            due_before: naturalTs("11:59pm tonight")
          }}
          onFilter={props.applyFilter}
        >
          Today
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="due_tomorrow"
          filterObj={{
            filter_due: "due_tomorrow",
            due_after: naturalTs("11:59pm tonight"),
            due_before: naturalTs("11:59pm tomorrow")
          }}
          onFilter={props.applyFilter}
        >
          Tomorrow
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="due_this_week"
          filterObj={{
            filter_due: "due_this_week",
            due_after: naturalTs("now"),
            due_before: naturalTs("saturday 11:59pm")
          }}
          onFilter={props.applyFilter}
        >
          This Week
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="due_next_week"
          filterObj={{
            filter_due: "due_next_week",
            due_after: naturalTs("saturday 11:59pm"),
            due_before: naturalTs("saturday 11:59pm") + 7 * 24 * 60 * 60 // 7 days
          }}
          onFilter={props.applyFilter}
        >
          Next Week
        </FilterLink>
      </li>
      {/* {MailBotFilter()} */}
      {/* <li>
    <Link to="/skills" style={{ color: "#aaaaaa" }}>
      Explore MailBots
    </Link>
  </li> */}
    </>
  );
};

export default TaskFilterByDueDate;
