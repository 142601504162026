let logdna;
if (process.env.NODE_ENV !== "testing") {
  logdna = require("@logdna/browser").default;
}

/**
 * Log things with LogDNA
 * pass object named 'error' to the output to capture stack trace
 * @example
 * const logger = Logger.getSingleton();
 * logger.setUserId(1234); // sets global context
 * logger.log('something happened', { level: 'warn', taskId: 1234, userId: 456, error });
 *
 */

// reference to logger, which can be rebuilt when setting userId
let logDnaSingleton = {};

function buildLogDnaSingleton(constructorParams = {}) {
  logDnaSingleton = new Logger({
    logDnaAppName: process.env.REACT_APP_LOGDNA_APP_NAME,
    logDnaAppKey: process.env.REACT_APP_LOGDNA_KEY,
    logToConsole: process.env.REACT_APP_LOGDNA_TO_CONSOLE === "true",
    enableStacktrace: false,
    ...constructorParams
  });
}
class Logger {
  // Caution: Keep constructorParams one level deep. Only shallow merge done when building singleton
  constructor(constructorParams) {
    this.meta = {};
    const logConfig = {
      hostname: getNormalizedUserAgent(),
      app: constructorParams.logDnaAppName,
      console: {
        enable: true,
        integrations: ["error"]
      },
      index_meta: true
    };

    // required to get userId to log under `meta.userId` See detailed comment below.
    if (constructorParams.userId) {
      logConfig.userId = constructorParams.userId;
    }

    this.logger =
      !!logdna && logdna.init(constructorParams.logDnaAppKey, logConfig);
    this.logToConsole = constructorParams.logToConsole;

    /**
        datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
        forwardErrorsToLogs: true,
        sampleRate: 100
        });
        datadogLogs.addLoggerGlobalContext("userId", loggedInUser.id);
        datadogLogs.removeLoggerGlobalContext("userId");
    */
  }
  setUserId(userId) {
    this.meta.userId = userId;
  }

  unSetUserId() {
    delete this.meta.userId;
  }

  /**
   * Ex: logger.log("foo", { level: warn, data: { taskId: 123 } })
   * @todo simplify signature Ex: logger.error("foo", { taskId: 123 }) https://github.com/mailbots/fut-mailbot/issues/390
   */
  async log(msg, options = {}) {
    let meta = {};
    if (options.userId) meta.userId = options.userId;
    // add debug data to indexed
    if (options.data) meta = { ...meta, ...options.data };

    // shallow merge local and persistent metadata stored in singleton (ie, userId)
    meta = { ...this.meta, ...meta };

    // apply log level
    let logMethod = options.level || "info";
    const okLogMethods = ["log", "error", "info", "debug", "warn"];
    if (!okLogMethods.includes(logMethod)) {
      this.logger.error(`Unsupported log method: ${logMethod}`, { ...meta });
      logMethod = "error";
    }

    if (this.logToConsole) console.log(msg, { ...meta });
    this.logger[logMethod](msg, { ...meta });
  }
}

buildLogDnaSingleton();
// Logger is a *static reference* to the logdnaSingleton var, which can be rebuilt when setting the userId context (see above)
export const logger = logDnaSingleton;

// Taken from https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator#example_1_browser_detect_and_return_a_string
function getNormalizedUserAgent() {
  if (typeof navigator === "undefined") return "unknown";
  var sBrowser,
    sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "samsung";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "internet-explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "chrome";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  return sBrowser;
}
