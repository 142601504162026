import React, { useEffect, useState, useContext, useRef } from "react";
import {
  getAccessToken,
  getAuthAccountToken,
  getFriendlyDates,
  handleWebhookResponseStatus,
  mailbotsAdminBrowser,
  smartLogin,
  userDate
} from "./lib/utils";
import nprogress from "nprogress";
import { withRouter, Link } from "react-router-dom";
import * as queryString from "query-string";
import { message as antMessage } from "antd";
import Loading from "./Loading";
import futLogo from "./images/futLogo.svg";
import { GlobalContext } from "./App";
import { isPaying } from "./Billing/billingHelpers";
import styled from "styled-components";
import Cookies from "js-cookie";
import { SetUpActionEmails } from "./ModalTours/TourSetUpActionEmails";

/**
 * This component offers a web-based alternative to handling an action mailto string. Theoretically
 * it could be made flexible enough to handle any mailto string, allowing us to register FUT as a mailto
 * handler, but for now it just handles and routes email-based actions.
 * Various user states:
 *  - Logged in user performing action on a fut on their account
 *  - Logged in user performing action on a fut another account in 'authAccounts' array. (reloadUser activates from ?gfr)
 *  - Logged in user performing action on another one of their accounts -- not logged in to that account at all
 *  - Logged in user performing action on another one of their accounts -- invalid auth token
 *  - Not logged in user, needing to login before performing action
 *  - Anonmyous user performing an option action (with only one email in gfr)
 *  - Anonmyous user performing an option action (with multiple emails in gfr)
 *  - Logged in user performing an open action (ie, set by another fut user)
 *  - Action error case. How the message looks, etc.
 *
 */
function HandleMailto(props) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [task, setTask] = useState();
  const [showEmailActionsDialog, setShowEmailActionsDialog] = useState(false);
  const { reloadUser } = useContext(GlobalContext);

  // parse url + init data
  const mailto = getMailToFromUrl();
  const referenceEmail = buildReferenceEmailFromMailto(mailto);
  const { open, taskId } = parseActionParamsFromEmailAddr(referenceEmail.to[0]);
  const { gfr, gfrs } = queryString.parse(window.location.search);
  const accessToken = getAccessToken(); // reloadUser sets the right one based on ?gfr

  // load user, could switch based on ?gfr email
  useEffect(() => {
    async function main() {
      if (open) return setUser(null); // no login if it's an open action
      const user = await reloadUser({ anonUserOk: true });
      if (!user) setUser(null); // anon user ok, but null
      setUser(user);
    }
    main();
  }, []);

  // after user loaded, do email action
  useEffect(() => {
    if (user === undefined) return; // not yet loaded
    async function main() {
      // The action can also performed on the task page as below...
      // if (!open) {
      //   const refEmailQS = queryString.stringify(referenceEmail);
      //   props.history.push(`/tasks/${taskId}?behavior=action&${refEmailQS}`);
      //   return;
      // }

      // right now, all actions are one-click buttons. To handle text input, call this fn
      // after exposing some text entry UI which populates the reference_email
      try {
        nprogress.start();
        const res = await doEmailAction({
          open,
          referenceEmail,
          gfr,
          gfrs,
          accessToken,
          loggedInUser: user, // from state
          taskId
        });
        setTask(res.task);
      } catch (e) {
        console.error("action link error", e);
        antMessage.error(e.message);
      }
      nprogress.done();
    }
    main();
  }, [user]);

  /**
   * Generic function to turn this URL into a mailto (our common abstraction).
   * /handle-mailto/to@email.com?subject=here&body=here
   **/
  function getMailToFromUrl() {
    const toParts = window.location.pathname.match(/handle\-mailto\/(.*)/); // email address
    if (!toParts[1])
      antMessage.error(
        "Sorry, we do not recognize that action. Please forward this url to help@humans.fut.io for assistance"
      );
    const to = toParts[1];
    const mailtoParams = {};
    const qs = queryString.parse(window.location.search); // ?subject=foo&body=bar, etc.
    if (qs.subject) mailtoParams.subject = qs.subject;
    if (qs.body) mailtoParams.body = qs.body;
    return `${to}?${queryString.stringify(mailtoParams)}`;
  }

  /**
   * Build reference email object from a mailto string
   * Accepts a mailto link as a common abstraction
   * @param {string} mailto full mailtostring ex: a+postpone-1d+t.566+mb-fut-rsweetland+805436@eml.bot?subject=foo&body=bar
   */
  function buildReferenceEmailFromMailto(mailto) {
    try {
      let referenceEmail = {};
      const encodedTo = mailto.split("?")[0];
      const to = decodeURIComponent(encodedTo);
      const urlParams = queryString.parse(window.location.search);
      referenceEmail.to = [to];
      referenceEmail.from = urlParams.gfr; // "gopher 'from' address"
      referenceEmail.html = urlParams.body;
      referenceEmail.subject = urlParams.subject;
      return referenceEmail;
    } catch (e) {
      antMessage.error(e.message);
      console.error(e);
    }
  }

  /**
   * Parse action params from an email address
   * @param {str} emailAddr - an action email like a+postpone-1d+t.566+mb-fut-rsweetland+805436@eml.bot
   */
  function parseActionParamsFromEmailAddr(emailAddr) {
    // always separated by "+" delimiter
    let actionParts = emailAddr.split("+");
    if (actionParts.length === 1) {
      actionParts = emailAddr.split("%2B"); // url encoded
    }
    // get taskId
    let taskId;
    for (let part of actionParts) {
      let res = part.match(/^t\.(\d+)$/);
      if (res) {
        taskId = res[1]; // first match group
        break;
      }
    }

    // get userId if present
    let userId;
    for (let part of actionParts) {
      let res = part.match(/^u\.(\d+)$/);
      if (res) {
        userId = res[1]; // first match group
        break;
      }
    }

    // remove taskId and userId params, leaving only positional arguments
    let posActionParts = actionParts.filter(
      item => !item.startsWith("t.") && !item.startsWith("u.")
    );

    // "open" action? (ie, can be performed by non-fut-user)
    const open = actionParts[0] === "ao";

    const actionStr = posActionParts[1];
    const mailBotSubdomain = posActionParts[2];

    return {
      actionStr,
      mailBotSubdomain,
      taskId,
      userId,
      open
    };
  }

  async function doEmailAction({
    referenceEmail,
    open,
    gfr,
    gfrs,
    accessToken = "",
    loggedInUser,
    taskId
  }) {
    let payload = {};
    // if open, link signature takes the place of access token
    if (open && gfr && gfrs) {
      payload.gfr = gfr;
      payload.gfrs = gfrs;
      // no loggedInUser means accessToken was not valid
    } else if (accessToken && loggedInUser) {
      mailbotsAdminBrowser.setAccessToken(accessToken);
    } else if (!open) {
      // trying to perform closed action, but not logged in
      smartLogin({
        email: gfr,
        redirect: window.location.pathname + window.location.search,
        message: `Please login first. You'll pick up right where you left off.`
      });
    }
    payload.open = open;
    payload.verbose = 1;
    payload.webhook = true;
    payload.action = referenceEmail.to[0];
    payload.reference_email = referenceEmail;
    // if login was successful, reloadUser strips the ?gfr address
    payload.reference_email.from =
      (gfr && gfr.split(",")[0]) || // ex: ?gfr=foo@bar.com,baz@buzz.com
      (loggedInUser && loggedInUser.primary_email) ||
      "(anonmyous)";
    payload.reference_email.server_recipient = referenceEmail.to[0];
    const res = await mailbotsAdminBrowser.sendAction(payload);
    handleWebhookResponseStatus(res); // partially applied skills can trigger warnings
    setLoading(false);
    return res;
  }

  const getMessage = () => {
    const action = referenceEmail?.to[0];
    if (action && action.includes("postpone") && task)
      return (
        <div>
          <h1 style={{ fontFamily: "Lora" }}>✓ Task Postponed</h1>
        </div>
      );
    return (
      <div>
        <h1 style={{ fontFamily: "Lora" }}>✓ Action Applied</h1>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90"
      }}
    >
      <SetUpActionEmails
        onClose={() => {
          setShowEmailActionsDialog(false);
        }}
        visible={showEmailActionsDialog}
      />
      <div style={{ width: 400, height: 400, textAlign: "center" }}>
        <a href="https://app.followupthen.com">
          <img
            src={futLogo}
            style={{ width: 150, marginTop: "5vh", marginBottom: "7vh" }}
          />
        </a>
        {loading ? (
          <Loading style={{ marginTop: -50 }} loadingText="Working..." />
        ) : (
          <div style={{ marginTop: 20 }}>
            <div>{getMessage()}</div>
            {user && task && task.id ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={{ maxWidth: "100%", display: "flex" }}>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "88%",
                      fontWeight: "bold",
                      display: "inlne-block",
                      marginRight: 4
                    }}
                  >
                    Re: {task.reference_email.subject}
                  </p>
                  <p>
                    (<Link to={`/tasks/${task.id}`}>edit</Link>)
                  </p>
                </div>
                <br />
                {
                  getFriendlyDates({
                    unixTime: task.trigger_time,
                    userTimezone: user.timezone,
                    format: user.preferred_date_format_js
                  }).friendlyDate
                }
              </div>
            ) : null}
          </div>
        )}
        {user !== null ? (
          <div style={{ marginTop: 20 }}>
            <a
              style={{
                color: "#aaa",
                textDecoration: "underline"
              }}
              onClick={() => setShowEmailActionsDialog(true)}
            >
              Enable email-based actions.
            </a>
          </div>
        ) : null}
      </div>
      <div
        style={{
          textAlign: "left",
          padding: 40,
          borderRadius: 20,
          backgroundColor: "white",
          position: "absolute",
          bottom: "10vh",
          width: "90vw",
          maxWidth: 600
        }}
      >
        <div style={{ color: "#333", fontSize: 18 }}>
          <GetFutTip loggedInUser={user} />
        </div>
        {open ? (
          <a
            style={{
              fontSize: 18,
              color: "#ddd",
              position: "absolute",
              bottom: -35,
              left: 0
            }}
            href={`mailto:abuse@followupthen.com?subject=Unwanted followup: ${taskId}`}
          >
            Unwanted Followup?
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(HandleMailto);

/**
 * Get a helpful tip! If an authtoken is present, assume they are are futuser.
 */
const GetFutTip = ({ loggedInUser }) => {
  const [randomFutTipIndex, setRandomFutTipIndex] = useState();
  const [audience, setAudience] = useState(null); // a function of the loggedInUser
  const randomTipIsSet = useRef(false);

  useEffect(() => {
    if (!randomTipIsSet.current) {
      const filteredTips = getFilteredFutTips(audience);
      setRandomTip(filteredTips);
    }
  }, [randomFutTipIndex, audience]);

  // setAudience, first from cache (to maximize the time the tip is shown)
  useEffect(() => {
    const cachedAudience = Cookies.get("tipAudience");
    if (cachedAudience) setAudience(cachedAudience);
    const saveAudience = audience => {
      Cookies.set("tipAudience", audience);
      setAudience(audience);
    };
    // target tips base on loggedInUser
    if (!cachedAudience && loggedInUser === undefined) {
      // saveAudience("nonuser"); // no cache, not loaded..just wait
    } else if (loggedInUser === null) {
      saveAudience("nonuser");
    } else if (isPaying(loggedInUser)) {
      saveAudience("paiduser");
    } else if (loggedInUser && !isPaying(loggedInUser)) {
      saveAudience("freeuser");
    }
  });

  // @todo: dynamically fetch these tips from somewhere
  const getFilteredFutTips = audience => {
    const TipContainer = styled.div`
      display: flex;
      flex-diretion: row;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    `;

    const GraphicalTip = ({
      title,
      body,
      imgSrc,
      buttonTxt,
      buttonHref,
      videoEmbed
    }) => (
      <TipContainer>
        <div>
          <strong>{title}</strong>
          <br />
          {body}
          {buttonHref && buttonTxt ? (
            <Link
              className="btn btn-primary"
              style={{ width: "100%", marginTop: 10 }}
              to={buttonHref}
            >
              {buttonTxt}
            </Link>
          ) : null}
        </div>
        {imgSrc ? (
          <img
            className="hide-for-mobile"
            src={imgSrc}
            style={{
              objectFit: "cover",
              float: "right",
              position: "relative",
              bottom: -35,
              right: -40,
              width: "100%",
              maxWidth: 250
            }}
          />
        ) : null}
        {videoEmbed ? videoEmbed : null}
      </TipContainer>
    );

    const futTips = [
      {
        id: "email-actions-2",
        tip: (
          <GraphicalTip
            title="Email-Based Actions"
            body="Get more done without leaving your inbox. One of many benefits
        from upgrading."
            imgSrc="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
            buttonHref="/skills?upgradeOptions"
            buttonTxt="Upgrade Options"
          />
        ),
        audience: ["freeuser"]
      },
      {
        id: "email-actions",
        tip: (
          <GraphicalTip
            title="Email-Based Actions"
            body={
              <p>
                Get more done without leaving your inbox. Turn this on in{" "}
                <Link to="/settings">settings</Link> under "email-based actions"
              </p>
            }
            imgSrc="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
          />
        ),
        audience: ["paiduser"]
      },
      {
        tip: (
          <div>
            <strong>🔁 Tip:</strong> Add "-r" to activate response detection
            (ex:
            <span style={{ whiteSpace: "nowrap" }}>3days-sms@fut.io</span>) This
            cancels a followup when a reply is received.{" "}
            <Link to="/skills/r">Read more</Link>.
          </div>
        ),
        audience: ["paiduser", "freeuser"]
      },
      {
        tip: (
          <div>
            <strong>💬 Tip:</strong> Add "-sms" to your date format to receive a
            text message with your followup.{" "}
            <Link to="/skills/sms">Read more</Link>.
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      },
      {
        tip: (
          <div>
            <strong>⚡️Tip:</strong> Use <em>@fut.io</em> instead of{" "}
            <em>@followupthen.com</em> to save keystrokes. For example,{" "}
            <em>3days@fut.io</em>.
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      },
      {
        tip: (
          <div>
            <strong>✅ Tip:</strong> Delegate tasks with ease. Send someone an
            email, put <em>3weekdays@fut.io</em> in the bcc (or cc) field. Add
            "-t" to keep following up until it is marked as done.{" "}
            <Link to="/skills/t">Read more</Link>.
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      },
      {
        tip: (
          <div>
            <strong>📆 Tip:</strong> Clear out your inbox by forwarding emails
            to the future.
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      },
      {
        tip: (
          <div>
            <strong>⚙️ Tip:</strong> Customize your postpone options in your{" "}
            <Link to="/settings/customize">settings</Link>
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      },
      {
        tip: (
          <div>
            👋 Hi, we're <a href="https://www.followUpthen.com">FollowUpThen</a>
            , the easiest way to schedule an email reminder.{" "}
            <a href="https://www.followUpthen.com">Create a free account</a>
          </div>
        ),
        audience: ["nonuser"]
      },
      {
        tip: (
          <div>
            💡 <a href="https://www.followUpthen.com">FollowUpThen</a> lets you
            create an email reminder by simply emailing
            [any-time]@followupthen.com, for example, weds2pm@followupthen.com{" "}
            <a href="https://www.followUpthen.com">Create a free account</a>
          </div>
        ),
        audience: ["nonuser"]
      },
      {
        tip: (
          <div>
            👋 <a href="https://www.followUpthen.com">FollowUpThen</a> is a free
            and easy email reminder. Use it to schedule followups for yourself
            and others.{" "}
            <a href="https://www.followUpthen.com">Create a free account</a>
          </div>
        ),
        audience: ["nonuser"]
      },
      {
        tip: (
          <div>
            <strong>Did you know?</strong> FollowUpThen automatically organizes{" "}
            <Link to="/people">the people</Link> in your followups.
          </div>
        ),
        audience: ["freeuser", "paiduser"]
      }
    ];
    return futTips.filter(t => t.audience.includes(audience));
    // .filter(t => t.id == "email-actions");
  };

  const setRandomTip = filteredTips => {
    const getRandomIndex = () => {
      let newRandomTipIndex = Math.floor(Math.random() * filteredTips.length); // ref from outside this fn
      // same tip as before? Roll the dice again...
      if (randomFutTipIndex === newRandomTipIndex && filteredTips.length > 1) {
        return getRandomIndex();
      } else {
        return newRandomTipIndex;
      }
    };
    if (!filteredTips.length) return;
    const newRandomTipIndex = getRandomIndex();
    randomTipIsSet.current = true;
    setRandomFutTipIndex(newRandomTipIndex);
  };

  return randomFutTipIndex === undefined || !audience ? null : (
    <div>
      <div>
        {getFilteredFutTips(audience)[randomFutTipIndex] &&
          getFilteredFutTips(audience)[randomFutTipIndex].tip}
        <a
          style={{
            color: "#ccc",
            position: "absolute",
            bottom: -35,
            right: 15
          }}
          onClick={() => setRandomTip(getFilteredFutTips(audience))}
        >
          More Tips ➡
        </a>
      </div>
    </div>
  );
};
