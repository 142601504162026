import React from "react";
const defaultStyle = {
  maxWidth: 500,
  textAlign: "center"
};

const FooterMessage = ({ children, style = {} }) => (
  <div className="footer-msg" style={{ ...defaultStyle, ...style }}>
    {children}
  </div>
);

export default FooterMessage;
