import React, { useState } from "react";
import Card from "../Card";
import Modal from "../Modal";
import { message as antMessage } from "antd";
import { mailbotsAdminBrowser } from "../lib/utils";
import nProgress from "nprogress";
import * as neatCsv from "neat-csv";

const readFile = csvFile => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = event => {
      const fileContent = event.target.result;
      resolve(fileContent);
    };
    fileReader.onerror = () => {
      reject("Could not read file");
    };
    fileReader.readAsText(csvFile);
  });
};

const importCsvFile = async file => {
  const csvContent = await readFile(file);
  const parsedCsv = await neatCsv(csvContent, {
    mapHeaders: ({ header }) => header.toLowerCase()
  });
  return parsedCsv;
};

const ImportCsvModal = props => {
  const importCsvInputRef = React.useRef();

  const handleFileChange = React.useCallback(
    async files => {
      try {
        const csvFile = files[0];
        if (!csvFile) return;

        const csvResult = await importCsvFile(csvFile);

        // reset file input
        importCsvInputRef.current.value = "";

        props.onSubmit(csvResult);
      } catch (error) {
        console.error(error);
      }
    },
    [props]
  );

  return (
    <Modal visible={props.isVisible} onClose={() => props.onCloseModal()}>
      <Card type="large" style={{ maxWidth: 500 }}>
        <h2>Import People</h2>
        <p>
          Upload a csv containing the columns "Name", "Emails" and "Tags".
          Separate multiple emails and tags using commas.{" "}
          <a
            href="https://fut-cdn.s3.amazonaws.com/fut-people-import-example.csv
"
          >
            Download example
          </a>
        </p>
        <button
          onClick={() => {
            importCsvInputRef.current.click();
          }}
          type="button"
          className="btn btn-primary"
          disabled={props.isBusy}
        >
          {!props.isBusy ? "Import CSV" : "Importing CSV..."}
          <input
            className="hidden"
            type="file"
            accept=".csv"
            ref={importCsvInputRef}
            onChange={e => {
              handleFileChange(e.target.files);
            }}
          ></input>
        </button>{" "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </button>
      </Card>
    </Modal>
  );
};

export default ImportCsvModal;
