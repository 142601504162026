import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { message as antMessage } from "antd";
import * as moment from "moment-timezone";
import nProgress, * as nprogress from "nprogress";
import { Link, useHistory } from "react-router-dom";
import * as striptags from "striptags";
import { ExpandableStateful } from "../Expandable";
import { GlobalContext } from "../App";
import { LoadingOutlined, QuestionCircleTwoTone } from "@ant-design/icons";

import {
  userIsBillingAnnually,
  getPriceFromStripeId,
  isOnFreePlan,
  isTeamOwner,
  getMyPlans,
  isBasePlan,
  getFeatureLimits,
  getFeatureUsage,
  isEmployee,
  getTeamSummary,
  isSoloPaidUser,
  getPriceForAugmentedPlans,
  calculateTeamTotalPrice,
  isOnCustomPlan,
  isOnLegacyPlan,
  getTeamAdmin,
  hasEmployees,
  numEmployees,
  getStoreCredit,
  getSubscriptionStatus,
  isTrialing,
  referralCreditWaitingForFreeUpgrade,
  isPaying,
  userPaidForSkill,
  userGetFeatureLimit
} from "./billingHelpers";

import {
  mailbotsAdminBrowser,
  getAccessToken,
  getFriendlyDates
} from "../lib/utils";
import Card from "../Card";
import Loading from "../Loading";
import { SettingsMenu } from "../SettingsMenu";
import queryString from "query-string";
import ModalSimple from "../ModalSimple";
import { useContext } from "react";
import {
  BlueCallout,
  PopoverStyled,
  ResponsiveSettingsSubnav
} from "../Layout";
import { ChoosePlanDialog } from "./ChoosePlanDialog";
import { Popover, Skeleton } from "antd";
import { TeamOwnerUpdatePlan } from "./UpgradeDialogs";
import { logger } from "../lib/Logger";
import Icons from "@ant-design/icons";

import { stringify as csvStringify } from "csv-stringify/lib";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const friendlyError = error =>
  `Upgrade error: ${
    error.message || "an unexpected billing error"
  }. Please contact help@humans.fut.io if you need help!`;

const BillingSettings = () => {
  const [paymentMethod, setPaymenetMethod] = useState([]);
  const [invoices, setInvoices] = useState(null);
  const [upcomingInvoiceVisible, setUpcomingInvoiceVisible] = useState(false);
  const [teamMembers, setTeamMembers] = useState(null);
  const [skillsList, setSkillsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTeamMember, setModalTeamMember] = useState(null);
  const [choosePlanDialogVisible, setChoosePlanDialogVisible] = useState(false);
  const [downgradeDialogVisible, setDowngradeDialogVisible] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);

  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    loadPage();
  }, [JSON.stringify(loggedInUser), JSON.stringify(teamMembers)]);

  // concurrent requests choking dev env, so we await a few
  const loadPage = async () => {
    handleUrlShortcuts();
    reloadBillingFromUser();
    loadSkillInfo();
    if (!isOnFreePlan(loggedInUser) && !isEmployee(loggedInUser)) {
      await loadPaymentMethod();
      await loadInvoices();
    }
    setIsLoading(false);
  };

  // Used only for displaying subscription plan features based on skill flag
  const loadSkillInfo = async () => {
    try {
      const { mailbots } = await mailbotsAdminBrowser.getMailBots();
      setSkillsList(mailbots);
    } catch (e) {
      antMessage.error("Error loading skills");
    }
  };

  // pass something like ?shortcut=updateCard in URL to directly help with that action
  const handleUrlShortcuts = () => {
    const { shortcut } = queryString.parse(window.location.search);
    if (!shortcut) return;
    switch (shortcut) {
      case "updateCard":
        if (
          !window.confirm(
            `Click 'ok' to be automatically redirected to our billing system.`
          )
        )
          return;
        redirectToUpdateCardStripe();
        break;
      default:
        window.alert("Unrecognized shortcut");
        break;
    }
  };

  const reloadBillingFromUser = async () => {
    // Update global user state and pull fresh user
    return reloadUser({ accessToken: getAccessToken() });
  };

  const loadPaymentMethod = async () => {
    try {
      const res = await mailbotsAdminBrowser.getPaymentMethod();
      setPaymenetMethod(res.payment_method || []);
    } catch (e) {
      antMessage.error(e.message);
    }
  };

  const hasPaymentMethod = () => !!paymentMethod.length;

  const loadInvoices = async () => {
    try {
      const { invoices } = await mailbotsAdminBrowser.getInvoices();
      setInvoices(invoices.filter(invoice => invoice.amount_paid !== 0));
    } catch (e) {
      antMessage.error(e.message);
    }
  };

  const hasInvoices = () => Array.isArray(invoices);

  const handleUpdateCardClick = async () => redirectToUpdateCardStripe();

  const redirectToUpdateCardStripe = async () => {
    try {
      const res = await mailbotsAdminBrowser.getStripeSetupCardSession({
        successUrl: window.location.href,
        cancelUrl: window.location.href
      });
      const sessionId = res.session_id;

      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });
      if (error) {
        antMessage.error(friendlyError(error));
      }
    } catch (e) {
      antMessage.error(friendlyError(e));
    }
  };
  /**
   * Switch the base plan. Currently only used to switch between monthly and yearly billing
   */
  const changeBasePlan = async ({ interval }) => {
    try {
      nprogress.start();
      await mailbotsAdminBrowser.changeBillingInterval({ interval });
      await reloadBillingFromUser();
      antMessage.success(`changed to ${interval} billing`);
    } catch (e) {
      antMessage.error(e.message);
    }
    nprogress.done();
  };

  const deleteCard = async ({ method }) => {
    try {
      nprogress.start();
      await mailbotsAdminBrowser.deletePaymentMethod({
        paymentMethodId: method.id
      });
      await loadPaymentMethod();
      antMessage.success(`Payment method deleted`);
    } catch (e) {
      antMessage.error(e.message);
    }
    nprogress.done();
  };

  const switchToAnnualBilling = () => {
    changeBasePlan({ interval: "yearly" });
  };

  const switchToMonthlyBilling = () => {
    changeBasePlan({ interval: "monthly" });
  };

  /**
   * User downgrading
   */
  const handleDowngradeClick = () => {
    setDowngradeDialogVisible(true);
    // doDowngrade() called within dialog
  };

  const doDowngrade = async () => {
    const confirmMessage =
      isTeamOwner(loggedInUser) && hasEmployees(loggedInUser)
        ? "This will cancel all subscription plans assocated with your account, including anyone on your team. Are you sure?"
        : "Are you sure you would like to cancel your subscription entirely? You can also lower your costs by cancelling add-ons.";
    if (!window.confirm(confirmMessage)) return;
    return mailbotsAdminBrowser
      .downgradeAllBilling()
      .then(() => {
        const urlParams = {
          email: loggedInUser.email,
          futs: loggedInUser.feature_usage_monthly.active_futs,
          team: numEmployees(loggedInUser),
          signed_up_on: loggedInUser.created
        };
        const qs = queryString.stringify(urlParams);
        window.location.href = `https://followupthen.typeform.com/to/CN82xLbr#${qs}`;
      })
      .catch(e => {
        console.error(e);
        antMessage.error(
          "Please contact help@humans.fut.io for help. An error occurred: " +
            e.message
        );
      });
  };

  /**
   * Dialogs
   * @param {*} param0
   */
  const PaymentMethod = ({ method }) => {
    if (!method || !method.card)
      return (
        <span>
          (Payment information not on file)
          <br />
          <a onClick={() => handleUpdateCardClick()}>Add Card</a>
        </span>
      );
    const created = method.created;
    const { brand, exp_month, exp_year, last4 } = method.card;
    return (
      <div>
        <pre style={{ margin: 0, padding: 0 }}>
          {brand} **** **** **** {last4}
          <br />
          Expires: {exp_month}/{exp_year}
        </pre>
        <a onClick={() => handleUpdateCardClick()}>Update</a>
        &nbsp;&nbsp;&nbsp;
        <a
          onClick={e => {
            if (!window.confirm("Are you sure you want to delete this card?"))
              return;
            e.preventDefault();
            deleteCard({ method });
          }}
          style={{ color: "#ccc" }}
        >
          Delete
        </a>
      </div>
    );
  };

  const getNextBillingDate = loggedInuser => {
    if (
      !loggedInuser ||
      !loggedInuser.billing ||
      !loggedInUser.billing.active_until ||
      loggedInUser.billing.active_until.startsWith("0000")
    ) {
      return "(Not scheduled)";
    } else {
      return (
        loggedInUser.billing.active_until &&
        loggedInUser.billing.active_until.split(" ")[0]
      );
    }
  };

  const getNextPrice = loggedInUser => {
    const monthlyTeamPrice = calculateTeamTotalPrice(loggedInUser);
    return userIsBillingAnnually(loggedInUser)
      ? monthlyTeamPrice * 12
      : monthlyTeamPrice;
  };

  const getNextChargeDate = time8601 => {
    let nextChargeDate = new Date(time8601);
    return nextChargeDate.toDateString();
  };

  const DowngradeDialog = () => {
    return (
      <ModalSimple
        style={{ maxWidth: 550 }}
        visible={downgradeDialogVisible}
        onClose={() => setDowngradeDialogVisible(false)}
      >
        <h1>Are you sure?</h1>
        {isTeamOwner(loggedInUser) && hasEmployees(loggedInUser) ? (
          <BlueCallout>
            ⚠️{" "}
            <strong>
              This will downgrade all of your team member accounts.
            </strong>{" "}
            Go to <Link to="/team">team settings</Link> to view usage.
          </BlueCallout>
        ) : null}

        <p>You will lose these features when you downgrade </p>
        <ul
          style={{
            textAlign: "left",
            maxWidth: 350,
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <li>
            <Link to="/skills/c">Cancellation skill (-c)</Link> to auto-cancel
            followups
          </li>
          <li>
            <Link to="/skills/mem">Memorize skill</Link> to memorize useful
            things
          </li>
          <li>
            <Link to="/skills/t">Task skill (-t)</Link> to assign tasks to
            yourself and others
          </li>
          <li>Higher followup limit</li>
          <li>Recurring followups</li>
          <li>Paid skills</li>
        </ul>
        <br />
        <a
          className="btn btn-primary"
          onClick={async e => {
            e.target.text = "...downgrading";
            await doDowngrade();
          }}
        >
          Yes, Cancel
        </a>
        <br />
        <a
          className="btn btn-secondary"
          onClick={() => setDowngradeDialogVisible(false)}
        >
          Nevermind
        </a>
      </ModalSimple>
    );
  };

  const BillingInfo = () => (
    <Card className="settings-card" type="large">
      <h2>Billing Information</h2>

      <p style={subHeaderStyle}>Active Card</p>
      {hasPaymentMethod && !isLoading ? (
        <PaymentMethod method={paymentMethod} />
      ) : (
        "...loading"
      )}

      <p style={subHeaderStyle}>Next Billing Date</p>
      <p>
        {getNextChargeDate(getNextBillingDate(loggedInUser))} <br />
        <a
          onClick={() => {
            setUpcomingInvoiceVisible(true);
          }}
        >
          Upcoming Invoice
        </a>
      </p>

      <p style={subHeaderStyle}>Billing Interval</p>
      {isOnLegacyPlan(loggedInUser) ? (
        <p>
          {userIsBillingAnnually(loggedInUser) ? (
            <span>Annual</span>
          ) : (
            <span>Monthly</span>
          )}
          <br />
          <em>
            You are on a legacy plan. Choose a new plan to change your billing
            interval.
          </em>
        </p>
      ) : (
        <p>
          {userIsBillingAnnually(loggedInUser) ? (
            <span>
              Annual <br />
              <a
                onClick={e => {
                  e.preventDefault();
                  switchToMonthlyBilling();
                }}
              >
                Switch to Monthly
              </a>
            </span>
          ) : (
            <span>
              Monthly <br />
              <a
                onClick={e => {
                  e.preventDefault();
                  switchToAnnualBilling();
                }}
              >
                Swith to Annual
              </a>{" "}
              <span
                style={{
                  color: "green",
                  backgroundColor: "#d6efd6",
                  padding: "2px 10px 2px 10px",
                  top: "-1px",
                  borderRadius: "21px"
                }}
              >
                save ~20%
              </span>
            </span>
          )}
        </p>
      )}

      <p style={subHeaderStyle}>Account Credit</p>
      <p>
        ${getStoreCredit(loggedInUser)} <br />
        <a onClick={() => history.push("?showAffiliate=1")}>Refer and Earn</a>
      </p>

      <p style={subHeaderStyle}>Subscription Status</p>
      <p>
        {getSubscriptionStatus(loggedInUser)} <br />
      </p>

      <p style={subHeaderStyle}>Cancel</p>
      <p>
        Cancel all billing (including any team members) below.
        <br />
        <a onClick={handleDowngradeClick}>Downgrade</a>
      </p>
    </Card>
  );

  const InvoicesCard = () => {
    return (
      <Card type="large" innerStyle={{ paddingBottom: 50 }}>
        <h2>Invoices</h2>
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          {hasInvoices() && !isLoading ? (
            <div>
              {invoices.length === 0 ? (
                <p className="text-muted">No charges have been made</p>
              ) : null}
              {invoices.map(invoice => (
                <li
                  key={invoice.id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                    maxWidth: 300,
                    marginBottom: 10
                  }}
                >
                  <a href={invoice.hosted_invoice_url}>
                    {moment
                      .utc(invoice.created * 1000)
                      .tz(loggedInUser.timezone || "GMT")
                      .format("YYYY-MM-DD")}
                  </a>
                  {"      "}
                  <span>${invoice.amount_paid / 100}</span>
                </li>
              ))}
            </div>
          ) : (
            <p>...loading</p>
          )}
        </ul>
      </Card>
    );
  };

  const Accountusage = () => {
    const getLimitResetDate = () => {
      let limitResetDate = new Date();
      limitResetDate.setDate(1);
      limitResetDate.setMonth(limitResetDate.getMonth() + 1);
      return limitResetDate.toDateString();
    };

    const activeFutsUsage = getFeatureUsage(loggedInUser).active_futs;

    const getActiveFutLimit = loggedInUser => {
      const UNLIMITED_FUT_NUM = 5000;
      const { limits } = getFeatureLimits(loggedInUser);
      const activeFutLiteralLimit = limits.active_futs;
      if (activeFutLiteralLimit === UNLIMITED_FUT_NUM) {
        return "unlimited";
      } else {
        return activeFutLiteralLimit;
      }
    };

    const UnusedBugetNotice = ({ budgetRemaining }) => (
      <div
        style={{
          backgroundColor: "rgb(235, 245, 255)",
          padding: 10,
          marginBottom: 20
        }}
      >
        You have{" "}
        <strong>
          ${budgetRemaining}
          /mo
        </strong>{" "}
        budget available. <Link to="/skills">Discover skills</Link> or
        <Popover
          content={
            <div style={{ maxWidth: 300 }}>
              This was provided by your team administrator to add premium
              features.
              <br />
              <Link to="/skills">Browse skills</Link> or
              <a
                href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                rel="noreferrer noopener"
                target="_blank"
              >
                read more
              </a>
            </div>
          }
        >
          {" "}
          <span style={{ color: "#11A2D2" }}>read about budgets</span>
        </Popover>
      </div>
    );

    return (
      <Card className="settings-card" type="large">
        <DowngradeDialog visible={downgradeDialogVisible} />
        <h2>My Account Usage</h2>
        <p>Account usage metrics reset on {getLimitResetDate()}.</p>
        <div
          style={{
            display: "grid",
            margin: "40px 0",
            gridTemplateColumns: "70% 30%"
          }}
        >
          {/* Active followups */}
          <p>
            Active Followups{" "}
            <Popover
              content={
                <div style={{ maxWidth: 300 }}>
                  The total pending followups this month.
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.followupthen.com/knowledge-base/how-are-active-followups-calculated/"
                  >
                    Read more
                  </a>
                </div>
              }
            >
              (<span style={{ color: "#11A2D2" }}>?</span>)
            </Popover>
          </p>
          <p>
            {activeFutsUsage} / {getActiveFutLimit(loggedInUser)}{" "}
            <Popover
              content={
                <div style={{ maxWidth: 300 }}>
                  Reasonable limits are in place to prevent automated, bulk
                  mailing, shared accounts and heavy programatic use.
                </div>
              }
            >
              <span style={{ color: "#11A2D2", cursor: "pointer" }}>*</span>
            </Popover>
          </p>

          {/* Budget */}
          {isEmployee(loggedInUser) ? (
            <>
              <p>
                Budget{" "}
                <Popover
                  content={
                    <div style={{ maxWidth: 300 }}>
                      Provided by your team administrator to add premium
                      features.
                      <br />
                      <a
                        href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Read more
                      </a>{" "}
                      or <Link to="/skills">browse skills</Link>.
                    </div>
                  }
                >
                  {" "}
                  (<span style={{ color: "#11A2D2" }}>?</span>)
                </Popover>
              </p>
              <p>
                ${loggedInUser.billing.budget_used_cent / 100} / $
                {loggedInUser.billing.budget_cent / 100}{" "}
                <span style={{ color: "#aaa" }}>
                  ($
                  {loggedInUser.billing.budget_remaining_cent / 100} left)
                </span>
              </p>
            </>
          ) : null}
        </div>
      </Card>
    );
  };

  const getPriceSubText = loggedInUser => {
    if (isEmployee(loggedInUser)) {
      return "(Budget set by team administrator)";
    } else if (isOnFreePlan(loggedInUser)) {
      return "";
    } else if (userIsBillingAnnually(loggedInUser)) {
      return "Billed annually";
    } else if (
      !isOnFreePlan(loggedInUser) &&
      !userIsBillingAnnually(loggedInUser)
    ) {
      return "Billed monthly";
    } else {
      return "(Unsupported pricing condition)";
    }
  };

  /**
   * Export all followups for the current loggedIn user.
   */
  const exportFollowups = async () => {
    try {
      let allTasks = [];
      let batch = [];
      let pageNo = 0;
      do {
        const result = await mailbotsAdminBrowser.getTasks({
          per_page: 200,
          due_after: Date.now() / 1000,
          order_by: "due",
          order_dir: "asc",
          page: pageNo++
        });

        batch = result.tasks;
        allTasks = allTasks.concat(batch);
      } while (batch.length > 0);

      const futExport = allTasks.map(task => {
        if (!task.id) return;
        let exportValue = {
          id: String(task.id),
          recipients: [],
          due: "",
          due_timestamp: task.trigger_time,
          subject: "",
          content: ""
        };

        // set due date in friendly format
        const userTimezone = loggedInUser.timezone; // bot.get("user.timezone");
        const userPreferredDateFormat = loggedInUser.preferred_date_format_js; //bot.get("user.preferred_date_format_js");
        const friendlyDate = getFriendlyDates({
          unixTime: task.trigger_time || 0,
          userTimezone,
          format: userPreferredDateFormat
        });
        exportValue.due = friendlyDate.friendlyDate;

        // set task info
        if (task.reference_email) {
          exportValue.subject = task.reference_email.subject;
          exportValue.content = striptags(
            (task?.reference_email?.html ?? "(no content)").replace(
              /[^\x00-\x7F]/g,
              ""
            )
          );

          // add recipients
          exportValue.recipients = task.reference_email.to;
          if (task.reference_email.cc)
            exportValue.recipients = exportValue.recipients.concat(
              task.reference_email.cc
            );
          if (task.reference_email.bcc)
            exportValue.recipients = exportValue.recipients.concat(
              task.reference_email.bcc
            );
        }

        return exportValue;
      });

      futExport.unshift({
        id: "ID",
        recipients: "Recipients",
        due: "Due",
        due_timestamp: "Due (timestamp)",
        subject: "Subject",
        content: "Body"
      });

      csvStringify(
        futExport,
        {
          columns: [
            "id",
            "recipients",
            "due",
            "due_timestamp",
            "subject",
            "content"
          ]
        },
        (err, output) => {
          if (err) {
            logger.log("error building export csv", {
              data: {
                userId: loggedInUser && loggedInUser.id,
                data: { err_obj: err }
              }
            });
            return antMessage.error(err.message);
          }

          // download the file
          const anchorEl = document.createElement("a");
          anchorEl.setAttribute("download", "followups.csv");
          anchorEl.setAttribute(
            "href",
            `data:application/csv;charset=utf-8,${encodeURIComponent(output)}`
          );
          anchorEl.click();
        }
      );
    } catch (error) {
      logger.log("error exporting or building csv", {
        data: {
          userId: loggedInUser && loggedInUser.id,
          data: { err_obj: error }
        }
      });
      antMessage.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className="main-container" style={{ maxWidth: 600 }}>
      <ChoosePlanDialog
        isModalVisible={choosePlanDialogVisible}
        onClose={() => setChoosePlanDialogVisible(false)}
      />

      <UpcomingInvoiceModal
        visible={upcomingInvoiceVisible}
        onClose={() => setUpcomingInvoiceVisible(false)}
      />

      <div className="main-middle">
        <Accountusage />
        {!isOnFreePlan(loggedInUser) || isOnCustomPlan(loggedInUser) ? (
          <MySubscriptions
            loggedInUser={loggedInUser}
            skillsList={skillsList}
            isLoading={isLoading}
            setChoosePlanDialogVisible={setChoosePlanDialogVisible}
            setUpcomingInvoiceVisible={setUpcomingInvoiceVisible}
          />
        ) : null}
        {isTeamOwner(loggedInUser) || isSoloPaidUser(loggedInUser) ? (
          <>
            <BillingInfo />
          </>
        ) : null}
        {isTeamOwner(loggedInUser) || isSoloPaidUser(loggedInUser) ? (
          <InvoicesCard />
        ) : null}
        {isEmployee(loggedInUser) ? <TeamAdminCard /> : null}
        <Card type="large" innerStyle={{ marginBottom: 40, paddingBottom: 50 }}>
          <h2>Export</h2>
          <div>
            You can export followups and people using the "download CSV" bulk
            actions, or download all your followups below.
            <br />
            {exportInProgress ? (
              <BlueCallout>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    marginBottom: 0
                  }}
                >
                  <div className="loader" style={{ marginRight: 8 }} />{" "}
                  Exporting...
                </div>
                <p>
                  Depending on how many followups you have this could take a
                  while. Please leave your browser open.
                </p>
              </BlueCallout>
            ) : (
              <a
                onClick={async e => {
                  setExportInProgress(true);
                  await exportFollowups();
                  setExportInProgress(false);
                }}
              >
                Export All Followups
              </a>
            )}
          </div>
          <p>
            <strong>Delete Account</strong>
            <br />
            To delete your account in its entirety, please{" "}
            <a href="https://help.followupthen.com/contact">contact us</a>.
            Alternatively, you can downgrade your account above to use our
            free-forever plan.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default BillingSettings;

const subHeaderStyle = { fontWeight: "bold", marginTop: 35, marginBottom: 2 };

// Subscription breakdown with controls to remove subscription items
export const PlanBreakdown = ({
  plans,
  userId,
  skillsList,
  onPlanChanged = () => {},
  showTeamTotal = false
}) => {
  const { loggedInUser } = useContext(GlobalContext);
  return (
    <div
      style={{
        width: "100%",
        margin: "40px 10px 5px 10px",
        textAlign: "left"
      }}
    >
      {plans.map(plan => (
        <Plan
          plan={plan}
          key={plan.stripe_id || plan.stripe_plan_id}
          userId={userId}
          skillsList={skillsList}
          onPlanChanged={onPlanChanged}
        />
      ))}
      <hr style={{ width: "75%", marginLeft: 0 }} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "60% 20% 20%"
        }}
      >
        <div style={{ fontWeight: "bold" }}>Total:</div>
        <div>
          <p style={{ fontWeight: "bold" }}>
            ${getPriceForAugmentedPlans(plans)}/mo
          </p>
        </div>
        <div>
          {isTeamOwner(loggedInUser) && userIsBillingAnnually(loggedInUser) ? (
            <span style={{ whiteSpace: "nowrap" }}>Billed annually</span>
          ) : null}{" "}
        </div>
        {showTeamTotal &&
        isTeamOwner(loggedInUser) &&
        hasEmployees(loggedInUser) ? (
          <>
            <div style={{ fontWeight: "bold" }}>Team Total:</div>
            <div style={{ fontWeight: "bold" }}>
              ${calculateTeamTotalPrice(loggedInUser)}/mo
            </div>
            <div style={{ textAlign: "left" }}>
              <Link
                style={{ color: "#aaa", textDecoration: "underline" }}
                to="/team"
              >
                team breakdown
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

// render plan, with details about which skills are included
const Plan = ({ plan, userId, skillsList, onPlanChanged = () => {} }) => {
  const { reloadUser, loggedInUser } = useContext(GlobalContext);
  const planName =
    plan.name ||
    (plan.stripe_plan_id && plan.stripe_plan_id + " (Legacy Plan)") ||
    "Free Plan";
  const planPrice = getPriceFromStripeId(plan.stripe_id || plan.stripe_plan_id);
  return (
    <div
      key={plan.name}
      style={{ display: "grid", gridTemplateColumns: "60% 20% 20%" }}
    >
      <ExpandableStateful
        overrides={{
          More: () => <>+ {planName}</>,
          Less: () => <>- {planName} </>
        }}
        style={{ textDecoration: "none", color: "black" }}
        togglePlacement="top"
      >
        <ul>
          {!plan.plan_key && plan.stripe_plan_id ? (
            <li>This is a legacy plan. View custom limits above.</li>
          ) : null}
          {plan.limits && plan.limits.active_futs ? (
            <li>Active Followup Increase: {plan.limits.active_futs}</li>
          ) : null}
          {plan.limits && plan.limits.skills && plan.limits.skills.length
            ? plan.limits.skills.map(flag => (
                <li key={flag}>
                  <SkillDetail flag={flag} skillsList={skillsList} />
                </li>
              ))
            : null}
        </ul>
      </ExpandableStateful>
      <p>${planPrice}/mo</p>
      {!isBasePlan(plan.stripe_id || plan.stripe_plan_id) && planPrice > 0 ? (
        <a
          title="Cancel plan"
          onClick={e => {
            handleRemoveSubscriptionClick(e, plan, userId, reloadUser);
            onPlanChanged();
          }}
          className="muted-link"
          style={{ display: "block" }}
        >
          x
        </a>
      ) : null}
    </div>
  );
};

const SkillDetail = ({ flag, skillsList }) => {
  if (!skillsList) throw Error("skillList not provided to SkilLDetail");
  const skill = skillsList.find(sk => sk.subdomain === flag);
  if (skill) return <Link to={`/skills/${skill.id}`}>{skill.name}</Link>;
  return <>{`-${flag}`}</>;
};

const handleRemoveSubscriptionClick = async (e, plan, userId, reloadUser) => {
  e.preventDefault();

  // Instruct a user to disable skills or delete followups before downgrading
  // @todo check if their account
  const okToDowngrade = () => {
    // check if this plan has skills that are still in use on their account
    // check number of followups
    return window.confirm(`Cancelling this plan may remove functionality from \
pending followups that rely on features in this plan. They will, however, still be sent on time. \
Your account will be credited for any unused time in this subscription. 

Are you sure you want to proceed?`);
  };

  const stripeId = plan.stripe_id;
  if (plan.base_plan) {
    window.alert(
      "To remove your base plan, cancel your account under the Billing Information card. (Or ask your Team Administrator)"
    );
    return;
  }
  if (!okToDowngrade()) return;

  if (!userId || !stripeId) console.error("userId or stripeId not provided");
  nProgress.start();
  try {
    await mailbotsAdminBrowser.removeSubscriptionAddons({
      userId: userId,
      plans: [stripeId]
    });
    antMessage.info(`Successfully removed plan. Your account has been credited with 
any unused portion of this subscription.`);
    await reloadUser();
  } catch (e) {
    console.error(e);
    antMessage.error("there was an error removing this plan...");
  }
  nProgress.done();
};

// export const TeamSubscriptions = ({
//   teamMembers,
//   showEditTeamMember,
//   isLoading
// }) => {
//   const [teamFilter, setTeamFilter] = useState("");
//   const { loggedInUser } = useContext(GlobalContext);

//   const filterHasNameOrEmail = teamMember =>
//     (teamMember.email &&
//       teamMember.email.toLowerCase().includes(teamFilter.toLowerCase())) ||
//     (teamMember.name &&
//       teamMember.name.toLowerCase().includes(teamFilter.toLowerCase()));

//   const teamCellStyle = {
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//     textOverflow: "ellipsis"
//   };

//   if (isSoloPaidUser(loggedInUser)) {
//     return <InviteYourTeam />;
//   }

//   return (
//     <Card className="settings-card" type="large">
//       <h2>My Team</h2>
//       <InviteTeamMember />
//       <TeamStats />
//       {!getTeamSummary(loggedInUser, teamMembers).length && isLoading ? (
//         "...loading"
//       ) : (
//         <div>
//           <h4>Subscribers</h4>
//           <p>Manage your existing subscribers.</p>
//           <div className="form-group" style={{ margin: "15px, 0", width: 300 }}>
//             <input
//               name="filter"
//               key="invite-team-member"
//               type="text"
//               className="form-control"
//               placeholder="filter team member"
//               value={teamFilter}
//               onChange={e => setTeamFilter(e.target.value)}
//             />
//           </div>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "50% 15% 15% 20%",
//               borderBottom: "1px solid #ecf0f1",
//               margin: 10
//             }}
//           >
//             <div>Team Member</div>
//             <div>Budget</div>
//             <div>Cost</div>
//             <div></div>
//           </div>
//           {getTeamSummary(loggedInUser, teamMembers)
//             .filter(filterHasNameOrEmail)
//             .map(teamMember => (
//               <div
//                 key={teamMember.id}
//                 style={{
//                   display: "grid",
//                   gridTemplateColumns: "50% 15% 15% 20%",
//                   margin: 10
//                 }}
//               >
//                 <div style={teamCellStyle}>
//                   <a onClick={e => showEditTeamMember(teamMember)}>
//                     {teamMember.name || teamMember.email}
//                   </a>
//                 </div>
//                 <div style={teamCellStyle}>
//                   ${teamMember.budget_cent ? teamMember.budget_cent / 100 : 0}
//                   /mo
//                 </div>
//                 <div style={teamCellStyle}>
//                   ${getPriceForAugmentedPlans(teamMember.plans)}/mo
//                 </div>
//                 <div style={teamCellStyle}>
//                   {!teamMember.verified ? (
//                     <span className="text-warning">(invite pending)</span>
//                   ) : null}{" "}
//                 </div>
//               </div>
//             ))}
//           <p>
//             Monthly Total
//             {userIsBillingAnnually(loggedInUser)
//               ? " (billed annually)"
//               : ""}:{" "}
//             <strong>${calculateTeamTotalPrice(loggedInUser)}/mo</strong>
//           </p>
//         </div>
//       )}
//     </Card>
//   );
// };

const UpcomingInvoiceModal = ({ onClose, visible }) => {
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const { loggedInUser } = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    mailbotsAdminBrowser
      .getUpcomingInvoices()
      .then(res => {
        setUpcomingInvoice(res.upcoming_invoice);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.message && error.message.startsWith("no upcoming invoices")) {
          console.log("no upcoming invoices");
          return;
        } else {
          antMessage.error(error.message);
        }
      });
  }, [visible]);

  const getInvoiceLines = upcomingInvoice => {
    if (!upcomingInvoice) return false;
    const existingLines = upcomingInvoice.lines_all
      ? upcomingInvoice.lines_all.data
      : [];
    const previousBalanceLine =
      upcomingInvoice.starting_balance != 0
        ? [
            {
              id: "acctBalance",
              description: `Account Balance`,
              amount: upcomingInvoice.starting_balance
            }
          ]
        : [];
    return [...previousBalanceLine, ...existingLines];
  };

  const getInvoiceLinesProrated = upcomingInvoice => {
    const invoiceLines = getInvoiceLines(upcomingInvoice);
    if (!invoiceLines) return false;
    const proratedLines = invoiceLines.filter(line => line.proration);
    if (!proratedLines.length) return false;
    return proratedLines;
  };

  const getInvoiceLinesNonProrated = upcomingInvoice => {
    const invoiceLines = getInvoiceLines(upcomingInvoice);
    if (!invoiceLines) return false;
    const nonProratedLines = invoiceLines.filter(line => !line.proration);
    if (!nonProratedLines.length) return false;
    return nonProratedLines;
  };

  return (
    <ModalSimple onClose={onClose} visible={visible} style={{ maxWidth: 700 }}>
      {upcomingInvoice ? (
        <div style={{ textAlign: "left", position: "relative" }}>
          <h2>Upcoming Invoice</h2>
          <p style={{ fontWeight: "bold" }}>
            {
              getFriendlyDates({
                unixTime: upcomingInvoice.period_end,
                format: "MMMM Do, YYYY"
              }).friendlyDate
            }{" "}
            <span className="text-muted">
              (
              {
                getFriendlyDates({
                  unixTime: upcomingInvoice.period_start,
                  format: "MMM Do, YYYY"
                }).friendlyDate
              }{" "}
              -{" "}
              {
                getFriendlyDates({
                  unixTime: upcomingInvoice.period_end,
                  format: "MMM Do, YYYY"
                }).friendlyDate
              }
              )
            </span>
          </p>
          <p>
            <br />
            <span style={{ fontStyle: "italic" }}>
              Your upcoming invoice shows any pro-rated credits and charges for
              the current period, plus scheduled charges for the next billing
              period. Click the <QuestionCircleTwoTone /> for more information.{" "}
              <a
                href="https://help.followupthen.com/?s=billing&ht-kb-search=1"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                Billing FAQ
              </a>
              .{" "}
              {isTrialing(loggedInUser)
                ? "(Not shown during free trial)"
                : null}
            </span>
          </p>
          {isTrialing(loggedInUser) &&
          referralCreditWaitingForFreeUpgrade(loggedInUser) ? (
            <BlueCallout>
              This invoice will be discounted by <strong>$5</strong> thanks to
              your affiliate credit.
            </BlueCallout>
          ) : null}
          <hr />

          {!loading && getInvoiceLinesProrated(upcomingInvoice) ? (
            <p
              style={{
                margin: "7px -1px",
                fontSize: 12,
                textTransform: "uppercase",
                color: "#b4bcc2"
              }}
            >
              Prorated Charges For Current Period
            </p>
          ) : null}
          {!loading &&
            getInvoiceLinesProrated(upcomingInvoice) &&
            getInvoiceLinesProrated(upcomingInvoice).map(line => (
              <InvoiceLine line={line} key={line.id} />
            ))}

          {!loading && getInvoiceLinesNonProrated(upcomingInvoice) ? (
            <p
              style={{
                fontSize: 12,
                margin: "7px -1px",
                textTransform: "uppercase",
                color: "#b4bcc2"
              }}
            >
              Charges for Next Billing Period
            </p>
          ) : null}
          {!loading &&
            getInvoiceLinesNonProrated(upcomingInvoice) &&
            getInvoiceLinesNonProrated(upcomingInvoice).map(line => (
              <InvoiceLine line={line} key={line.id} />
            ))}
          <hr />
          {/* coupon - amount off */}
          <div style={{ textAlign: "right", margin: 7 }}>
            {upcomingInvoice.discount &&
            upcomingInvoice.discount.coupon &&
            upcomingInvoice.discount.coupon.amount_off ? (
              <div
                style={{
                  fontStyle: "italic",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div>Coupon Applied</div>
                <div>
                  -$
                  {Math.floor(
                    upcomingInvoice.discount.coupon.amount_off / 100
                  )}{" "}
                </div>
              </div>
            ) : null}
          </div>
          {/* coupon - pct off */}
          <div style={{ textAlign: "right", margin: 7 }}>
            {upcomingInvoice.discount &&
            upcomingInvoice.discount.coupon &&
            upcomingInvoice.discount.coupon.percent_off ? (
              <div
                style={{
                  fontStyle: "italic",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div>Coupon Applied</div>
                <div>{upcomingInvoice.discount.coupon.percent_off}% off </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "grid",
              columnGap: 15,
              margin: 7,
              gridTemplateColumns: "90fr 10fr"
            }}
          >
            <div>
              <strong>Upcoming Invoice Total:</strong>
            </div>
            <div style={{ textAlign: "right", width: 150 }}>
              <strong>
                {upcomingInvoice.amount_due < 0 ? (
                  <span style={{ color: "green" }}>
                    - ${Math.abs(upcomingInvoice.amount_due / 100)} <br />
                    Credit for next period
                    <br />
                  </span>
                ) : (
                  <span>${upcomingInvoice.amount_due / 100}</span>
                )}
              </strong>{" "}
            </div>
          </div>
        </div>
      ) : null}
      {!loading && upcomingInvoice === null ? (
        <div>
          <h2>No upcoming invoice</h2>{" "}
          <p>
            Not what you were expecting? Please contact help@humans.fut.io for
            help.
          </p>
        </div>
      ) : null}
      {loading ? (
        <div>
          <Skeleton count={10} />{" "}
          <Loading loadingText="..loading upcoming invoice" />
        </div>
      ) : null}
    </ModalSimple>
  );
};

const InvoiceLine = ({ line }) => (
  <div
    key={line.id}
    style={{
      display: "grid",
      gridTemplateColumns: "80fr 5fr 15fr",
      columnGap: 15,
      margin: 7
    }}
  >
    <div
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
      }}
      title={line.description}
    >
      {line.description}
    </div>
    <div style={{ whiteSpace: "nowrap" }}>
      {line.description.includes("Remaining time") ? (
        <PopoverStyled
          content={
            <p>
              When you (or someone on your team) changed your subscription on
              the date shown, we issued this partial charge to account for the
              cost of the new product over the remaining time in your current
              billing period.{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/how-do-prorations-work-changes-to-billing/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                More
              </a>
            </p>
          }
        >
          {" "}
          <QuestionCircleTwoTone />
        </PopoverStyled>
      ) : null}
      {line.description.includes("Unused time") ? (
        <PopoverStyled
          content={
            <p>
              When you (or someone on your team) changed your subscription on
              the date shown, we issued this partial credit to account for any
              unused time for this product in your current billing period.{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/how-do-prorations-work-changes-to-billing/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                More
              </a>
            </p>
          }
        >
          <QuestionCircleTwoTone />
        </PopoverStyled>
      ) : null}
      {line.description && line.description.match(/^[1-9]+\s×.*/) !== null ? (
        <PopoverStyled
          content={<p>Cost of this product for the next billing period.</p>}
        >
          <QuestionCircleTwoTone />
        </PopoverStyled>
      ) : null}
    </div>
    <div style={{ textAlign: "right" }}>
      {line.amount < 0 ? (
        <span style={{ color: "green" }}>- ${Math.abs(line.amount) / 100}</span>
      ) : (
        <span> ${line.amount / 100}</span>
      )}
    </div>
  </div>
);

const TeamAdminCard = () => {
  const { loggedInUser } = useContext(GlobalContext);
  return (
    <Card className="settings-card" type="large">
      <h2>Team Administrator</h2>
      <p>
        {loggedInUser.billing.team_admin.name} <br />
        <a href={`mailto:${loggedInUser.billing.team_admin.email}`}>
          {loggedInUser.billing.team_admin.email}
        </a>
      </p>
    </Card>
  );
};

const getCustomizedExplanation = loggedInUser => {
  // high-volume free users were temporarily issued an increase so they can evaluate FUT v3.
  // https://github.com/mailbots/fut-core-api/issues/2823
  const TMP_FREE_FUT_LIMIT = 1000;
  const { limits } = getFeatureLimits(loggedInUser);
  if (
    limits &&
    limits.active_futs === TMP_FREE_FUT_LIMIT &&
    !isPaying(loggedInUser)
  ) {
    return `Welcome to the new FollowUpThen. You've been given a temporary increase in your followup limits.`;
  }
  return "Your plan limits have been customized."; // generic
};

const MySubscriptions = ({
  loggedInUser,
  skillsList,
  isLoading,
  setChoosePlanDialogVisible,
  setUpcomingInvoiceVisible
}) => (
  <Card className="settings-card" type="large">
    <h2>My Subscriptions</h2>

    {isOnLegacyPlan(loggedInUser) &&
    isTeamOwner(loggedInUser) &&
    hasEmployees(loggedInUser) ? (
      <BlueCallout style={{ marginLeft: 0 }}>
        <TeamOwnerUpdatePlan />
      </BlueCallout>
    ) : null}

    {isOnLegacyPlan(loggedInUser) && isEmployee(loggedInUser) ? (
      <BlueCallout style={{ marginLeft: 0 }}>
        <p>
          👋 <strong>Your team is on a legacy billing plan</strong>. Ask your
          team administrator ({getTeamAdmin(loggedInUser).email}) to migrate
          your team at <em>app.followupthen.com/billing</em>.{" "}
        </p>
        <p>In the meantime you can view your current plan limits below.</p>
      </BlueCallout>
    ) : null}

    {isOnLegacyPlan(loggedInUser) && !isEmployee(loggedInUser) ? (
      <BlueCallout style={{ marginLeft: 0 }}>
        <strong>You are on a legacy billing plan</strong> Custom plan limits
        have been assigned to your account to match your previous limits. To
        unlock our newest features update your plan.{" "}
        <a onClick={() => setChoosePlanDialogVisible(true)}>
          Migrate to New Plan
        </a>
      </BlueCallout>
    ) : null}

    {isOnCustomPlan(loggedInUser) ? (
      <BlueCallout style={{ marginLeft: 0 }}>
        {getCustomizedExplanation(loggedInUser)}
        {!isLoading ? (
          <ExpandableStateful
            style={{ marginTop: 20, display: "inline-block" }}
            more="View custom plan limits"
            less="Hide custom plan limits"
          >
            <hr />
            <PlanLimits
              limits={getFeatureLimits(loggedInUser).limits}
              skillsList={skillsList}
            />
          </ExpandableStateful>
        ) : (
          <div style={{ margin: 10 }}>
            <LoadingOutlined /> ...Loading plan limits
          </div>
        )}
      </BlueCallout>
    ) : null}
    <p>
      Plans are made of a{" "}
      <a onClick={() => setChoosePlanDialogVisible(true)}>Base Plan</a>, plus
      add-ons.{" "}
      <a
        className="text-muted"
        href="https://help.followupthen.com/knowledge-base/pricing-guide/"
        target="_blank"
        rel="nofollow noreferrer"
      >
        Pricing Guide.
      </a>{" "}
    </p>
    {getMyPlans(loggedInUser) &&
    Array.isArray(getMyPlans(loggedInUser)) &&
    (getMyPlans(loggedInUser).length ||
      getMyPlans(loggedInUser).length === 0) &&
    !isLoading ? (
      <PlanBreakdown
        plans={getMyPlans(loggedInUser)}
        userId={loggedInUser.id}
        skillsList={skillsList}
        showTeamTotal={true}
      />
    ) : (
      "..loading"
    )}
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          textAlign: "left",
          color: "#333",
          marginBottom: 20,
          marginLeft: 10,
          marginTop: 30
        }}
      >
        <p>
          View credits and current charges on your{" "}
          <a
            onClick={() => {
              setUpcomingInvoiceVisible(true);
            }}
          >
            upcoming invoice
          </a>
          .{" "}
        </p>
      </div>
    </div>
  </Card>
);

const LimitLine = ({ limitTitle, limitValue, explanation }) => (
  <div style={{ marginBottom: 10 }}>
    <strong>
      {limitTitle}{" "}
      <Popover
        content={() => <div style={{ maxWidth: 400 }}>{explanation}</div>}
      >
        <span style={{ color: "#11A2D2" }}>(?)</span>
      </Popover>
    </strong>
    <br /> {limitValue}
  </div>
);

export const PlanLimits = ({ limits, skillsList }) => {
  const { loggedInUser } = useContext(GlobalContext);
  if (!limits) return <p>No limits</p>;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        textAlign: "left",
        margin: 30
      }}
    >
      {/* Left Column */}
      <div>
        {limits.active_futs ? (
          <LimitLine
            limitTitle="Active Followups"
            limitValue={limits.active_futs}
            explanation={
              <>
                An <strong>Active Followup</strong> is one that is scheduled in
                the future. During any given month, a followup may be active for
                1 minute (1min@fut.io), or it may have been scheduled years ago,
                but still pending. Each counts as an active followup.
                <a
                  target="_blank"
                  href="https://help.followupthen.com/knowledge-base/how-are-active-followups-calculated/"
                  rel="nofollow noreferrer"
                >
                  Read more
                </a>
              </>
            }
          />
        ) : null}

        {limits.fut ? (
          <LimitLine
            limitTitle="New Followups/mo"
            limitValue={limits.fut}
            explanation={
              <>
                This is a legacy limitation. Upgrade to our new base plan to
                remove this limit.
              </>
            }
          />
        ) : null}

        {limits.iee_acct_limit ? (
          <LimitLine
            limitTitle="Connected Inboxes"
            limitValue={limits.iee_acct_limit}
            explanation="These are inboxes FollowUpThen is logged into in order to
        detect replies, or send out whitelabel email followups."
          />
        ) : null}

        <LimitLine
          limitTitle="Linked Email Addresses"
          limitValue={20}
          explanation="Manage followups from multiple email addresses (that you own)
          under one account. This is different from a connected inbox, which requires 
          FUT to be logged into the inbox. "
        />
      </div>
      {/* Right Column */}
      <div>
        <strong>Available Skills:</strong>
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          {limits &&
            Array.isArray(limits.skills) &&
            limits.skills.map(skill => (
              <li key={skill}>
                <SkillDetail flag={skill} skillsList={skillsList} />
              </li>
            ))}
        </ul>
        <strong>Bulk Actions:</strong>
        <p>
          {userGetFeatureLimit({ loggedInUser, feature: "bulk_actions" })
            ? "Yes"
            : "No"}
        </p>
      </div>
    </div>
  );
};
