import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import nprogress from "nprogress";
import { mailbotsAdminBrowser } from "./lib/utils";
import Dialog from "./Dialog";
import FooterMessage from "./FooterMessage";
import Loading from "./Loading";
import { message as antMessage } from "antd";
import { mailbotIsInstalled } from "./lib/utils";
import { UpgradeBasePlanCard } from "./Billing/UpgradeDialogs";
import { getSkillUpgradeState } from "./Billing/billingHelpers";
import { Card } from "antd";
import ScheduleWidget from "./ScheduleWidget";
import { PopoverStyled } from "./Layout";

class MailBotList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailbots: [],
      isLoading: true
    };
  }

  mailbotAd = () => {
    let ad;
    if (this.props.owner === "self") {
      ad = (
        <h5>
          Need some inspiration? Visit the{" "}
          <a href="https://trello.com/b/AhYnpKqa/email-bot-ideas">idea board</a>
          .
        </h5>
      );
    } else if (this.state.mailbots.length > 0) {
      ad = (
        <h5>
          Create your own MailBot . Visit the{" "}
          <a href="https://trello.com/b/AhYnpKqa/email-bot-ideas">idea board</a>
          .
        </h5>
      );
    } else {
      ad = (
        <p>
          Our community is still at work creating MailBots for this category.
          Visit the{" "}
          <a href="https://trello.com/b/AhYnpKqa/email-bot-ideas">idea board</a>
          .
        </p>
      );
    }
    return <FooterMessage>{ad}</FooterMessage>;
  };

  async refreshMailBots(params) {
    nprogress.start();
    try {
      const queryParams = {};
      if (params.tag) queryParams.tag = params.tag;
      if (params.owner) queryParams.owner = params.owner;
      if (params.installed) queryParams.installed = params.installed;
      if (params.searchString) queryParams.search = params.searchString;
      // see postman docs. These params add data to query
      if (params.incInstalled) queryParams.inc_installed = params.incInstalled;
      if (params.incPublished) queryParams.inc_published = params.incPublished;
      this.setState({ isLoading: true });
      let res = await mailbotsAdminBrowser.getMailBots(queryParams);
      if (!res.hasOwnProperty("mailbots")) {
        this.setState({
          // mailbots: [],
          isLoading: false
        });
      } else {
        this.setState({
          mailbots: res.mailbots,
          isLoading: false
        });
      }
      nprogress.done();
    } catch (e) {
      console.log(e);
      antMessage.error(e);
      let loginData = await mailbotsAdminBrowser.getLoginUri();
      let loginUri = loginData.login_uri;
      this.setState({
        dialogType: `alert-warning`,
        dialogText: `There appears to be an error.`,
        dialogLoginLink: loginUri
      });
      nprogress.done();
    }
  }

  componentDidMount() {
    this.refreshMailBots(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.refreshMailBots(nextProps);
  }

  getTagline(description) {
    const newLineIndex = description.indexOf("\n");
    const maxDescriptionLength = 140;
    const cutIndex =
      newLineIndex > maxDescriptionLength ? maxDescriptionLength : newLineIndex;
    return description.slice(0, cutIndex);
  }

  handleDeleteMailBot(params) {
    if (!window.confirm("Are you sure? This is a permanent action")) {
      return false;
    }
    return mailbotsAdminBrowser.deleteMailBot({
      mailbotid: params.mailbotid
    });
  }

  getPreviewSkills(tag) {
    const previewSkills = [
      // {
      //   preview: true,
      //   id: 1000001,
      //   name: "Salesforce",
      //   description:
      //     "Salesforce followups with everything you need in your inbox",
      //   icon: "https://dl.dropbox.com/s/33vf85iukih9sc0/salesforce-monochrome.png?dl=0",
      //   tags: ["relationships", "crm"],
      //   comingSoon: true
      // },
      // {
      //   preview: true,
      //   id: 1000003,
      //   name: "Evernote",
      //   description:
      //     "Easily create and retrieve notes without leaving your inbox",
      //   icon: "https://dl.dropbox.com/s/dpbsge4drqf7hos/evernote-monochrome.png",
      //   tags: ["productivity"],
      //   comingSoon: true
      // },
      // {
      //   preview: true,
      //   id: 1000004,
      //   name: "Github",
      //   description: "Manage Github issues without leaving your inbox",
      //   icon: "https://dl.dropbox.com/s/rquul71fgt7iov8/github-monochrome.png",
      //   tags: ["collaboration", "productivity"],
      //   comingSoon: true
      // },
      {
        preview: true,
        id: 1000005,
        name: "Request A Skill",
        description:
          "Email help@humans.fut.io to let us know what you're thinking!",
        icon: "http://dl.dropbox.com/s/o2cwfirc864tyc9/question-mark.png",
        tags: ["all"],
        comingSoon: false
      }
    ];

    if (tag) {
      return previewSkills.filter(
        sk => sk.tags.includes(tag) || sk.tags.includes("all")
      );
    }
    return previewSkills;
  }

  render() {
    const OwnerControls = ({ mailbot }) => (
      <div className={`bottom-bar`}>
        {" "}
        {/** TODO: Make visible on hover */}
        {/* Admin */}
        <React.Fragment>
          {"   "}
          <Link
            className="view-link"
            onClick={e => {
              e.stopPropagation();
            }}
            to={"/developer/skills/" + mailbot.id}
          >
            {" "}
            <i className="glyphicon glyphicon-cog" /> Admin{" "}
          </Link>
          &nbsp;&nbsp;
          <Link
            className="view-link"
            onClick={e => {
              e.stopPropagation();
            }}
            to={"/sandbox/" + mailbot.id}
          >
            &nbsp;&nbsp;
            <i className="glyphicon glyphicon-wrench" /> Sandbox{" "}
          </Link>
        </React.Fragment>
        {/* Delete Icon only on React Development Env (currently disabled) */}
        {process.env.NODE_ENV === "development" && (
          <a
            style={{
              color: "#ccc"
            }}
            onClick={e => {
              document
                .getElementById(mailbot.id + mailbot.name)
                .classList.add("hidden");
              e.preventDefault();
              e.stopPropagation();
              const mailbotid = mailbot.id;
              this.handleDeleteMailBot({ mailbotid });
            }}
          >
            &nbsp;&nbsp;
            <i className="glyphicon glyphicon-trash" />
          </a>
        )}
      </div>
    );

    const styles = {
      faded: {
        opacity: ".5"
      }
    };

    return (
      <div style={{ clear: "both" }}>
        {this.state.dialogText && (
          <Dialog {...this.state} dialogType="info">
            {this.state.dialogText}
            {this.state.dialogLoginLink && (
              <div>
                Please try{" "}
                <a className="alert-link" href={this.state.dialogLoginLink}>
                  logging in again
                </a>
              </div>
            )}
          </Dialog>
        )}

        <div className="mailbot-list-container">
          {this.props.topCards ? this.props.topCards : null}
          {this.state.isLoading && this.props.showLoading === false ? (
            <Loading loadingText="Loading..." />
          ) : (
            this.state.mailbots.map(mailbot => (
              <div
                key={mailbot.id + mailbot.name}
                id={mailbot.id + mailbot.name}
                className="hover-panel main-panel mailbots-list-item"
                onClick={() => {
                  this.props.history.push(`/skills/${mailbot.id}`);
                }}
              >
                {/* Installed Icon */}
                {getSkillUpgradeState(
                  this.props.loggedInUser,
                  mailbot
                ).startsWith("ok") && ( // "ok" or "ok_more_choices"
                  <span style={{ float: "right", color: "green" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="glyphicon glyphicon-check" title="Active" />
                  </span>
                )}
                <div style={{ height: 75 }}>
                  <img
                    alt={"Icon: " + mailbot.name}
                    src={
                      mailbot.icon ||
                      "https://files-38348em97-mailbots.vercel.app/"
                    }
                    className="mailbots-icon small"
                  />
                </div>

                <h2>{mailbot.name}</h2>
                <p>{this.getTagline(mailbot.description)}</p>
                {this.props.owner === "self" && (
                  <OwnerControls mailbot={mailbot} />
                )}
              </div>
            ))
          )}
          {!this.state.isLoading &&
            this.getPreviewSkills(this.props.tag)
              .filter(
                (
                  skill // since preview skills don't live in the db, we have to filter them out on front-end for now (hack)
                ) =>
                  this.props.searchString
                    ? skill.name.includes(this.props.searchString)
                    : true
              )
              .map(skill => (
                <div key={skill.id} className="main-panel mailbots-list-item">
                  {skill.comingSoon && (
                    <span style={{ float: "right", textAlign: "right" }}>
                      <span style={{ color: "grey" }}> Coming Soon!</span>
                      {/* <br /> */}
                      {/* <a>Vote For This</a> */}
                    </span>
                  )}
                  <img
                    alt={"Coming soon icon"}
                    src={skill.icon}
                    className="mailbots-icon small"
                    style={styles.faded}
                  />

                  <h2 style={styles.faded}>{skill.name}</h2>
                  <p style={styles.faded}>{skill.description}</p>
                </div>
              ))}

          {/* {this.mailbotAd()} */}
        </div>
        {process.env.REACT_APP_BILLING_AVAILABLE === "yes" ? (
          <UpgradeBasePlanCard
            loggedInUser={this.props.loggedInUser}
            style={{ marginBottom: 50 }}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(MailBotList);
