import React, { useContext, useState } from "react";
import _ from "lodash";
import Modal from "../Modal";
import {
  getEmailMethod,
  isMailBotsEmail,
  isValidEmail,
  moveFutCommand,
  normalizeEmail as normalizeFutEmail
} from "../lib/utils";
import { message as antMessage } from "antd";
import { useEffect } from "react";
import { GlobalContext } from "../App";
import { Link } from "react-router-dom";

const ManageRecipientsModal = props => {
  const getOrigToWithoutFut = () => {
    let origTo = props.referenceEmail && props.referenceEmail.to;
    if (origTo && origTo.length) {
      origTo = origTo.filter(e => !isMailBotsEmail(e));
    }
    if (!origTo || !origTo.length) return "";
    return origTo.join(",");
  };
  const [toField, setToField] = useState(getOrigToWithoutFut());
  const [fromAddress, setFromAddress] = useState(props.referenceEmail.from);
  const [newRefEmail, setNewRefEmail] = useState(props.referenceEmail);

  const { loggedInUser } = useContext(GlobalContext);

  useEffect(() => {
    /// close dialog on escape key
    const handleKeyDown = e => {
      if (e.key === "Escape") {
        props.onCloseModal();
        setToField(getOrigToWithoutFut());
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Modal visible={props.visible} onClose={() => props.onCloseModal()}>
      <div className="main-panel" style={{ maxWidth: 550, width: "95vw" }}>
        <h3>Manage Recipients</h3>
        {getEmailMethod(newRefEmail, props.command) == "cc" ? (
          <span>
            This is a group followup.{" "}
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                let newEmail = moveFutCommand(
                  newRefEmail,
                  "bcc",
                  props.command
                );
                setNewRefEmail(newEmail);
              }}
            >
              Change to private (bcc)
            </a>
            <br />
            <br />
            External Recipients{" "}
          </span>
        ) : (
          <span>
            {" "}
            This is a private followup.{" "}
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                let newEmail = moveFutCommand(newRefEmail, "cc", props.command);
                setNewRefEmail(newEmail);
              }}
            >
              Change to group (cc)
            </a>
            <br />
            <br />
            Original recipients (for reference).
          </span>
        )}
        <div className="form-group">
          <input
            ref={ref => ref && ref.focus()}
            name="to"
            type="input"
            value={toField}
            placeholder="(ex: email1@example.com, email2@example.com)"
            onChange={e => setToField(e.target.value)}
            className="form-control"
            key="email"
          />
          <span className="text-muted">Separate by comma</span>
        </div>
        <div className="form-group" style={{ marginTop: 20, marginBottom: 20 }}>
          <span>My Delivery Address</span>
          <select
            id="email"
            className="form-control"
            value={fromAddress}
            onChange={e => setFromAddress(e.target.value)}
          >
            {loggedInUser &&
              loggedInUser.emails.map(email => (
                <option key={email} value={email}>
                  {email}
                </option>
              ))}
          </select>
          <p className="text-muted">
            Link additional emails from{" "}
            <Link
              style={{ color: "#b4bcc2", textDecoration: "underline" }}
              to="/settings"
            >
              settings
            </Link>
          </p>
        </div>

        <button
          onClick={async e => {
            e.target.innerText = "Saving";
            e.target.disabled = true;

            let normRefEmail = Object.assign({}, newRefEmail);
            normRefEmail.to = toField.split(",");

            // validate emails
            if (normRefEmail.to.some(eml => eml && !isValidEmail(eml.trim()))) {
              antMessage.error(
                "Invalid email address. Please verify and try again. Contact help@humans.fut.io if needed."
              );
              return;
            }

            normRefEmail.from = fromAddress;
            normRefEmail = normalizeFutEmail(normRefEmail, props.command);

            await props.onSubmit(normRefEmail);
          }}
          type="button"
          className="btn btn-primary"
        >
          Save
        </button>
        {"  "}
      </div>
    </Modal>
  );
};

export default ManageRecipientsModal;
