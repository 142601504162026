import React, { useEffect, useState } from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import MailBotList from "./MailBotList";
import NavLeft from "./NavLeft";
import _ from "lodash";
import { SearchBox } from "./SearchBox";
import { ShowChoosePlanDialogIfInQS } from "./Billing/UpgradeDialogs";
import { logger } from "./lib/Logger";
import { PopoverStyled } from "./Layout";
import ScheduleWidget from "./ScheduleWidget";
import { Card } from "antd";

function MailBots(props) {
  const [inputSearchString, setInputSearchString] = useState(); // while typing
  const [finalSearchString, setFinalSearchString] = useState(); // after you hit 'search'

  const handleSearchOnChange = e => {
    const inputSearchString = e.target.value;
    setInputSearchString(inputSearchString);
    setFinalSearchString(inputSearchString);
    // reset list if input is cleared
    if (inputSearchString === "") setFinalSearchString("");
  };

  const DIRECTORY_NAV = true;
  return (
    <div className="mailbots-section-container">
      <ShowChoosePlanDialogIfInQS />
      <div
        className="main-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "1200px"
        }}
      >
        <div className="main-left subnav">
          <div className="subnav-fixed" style={{ paddingTop: 20 }}>
            {DIRECTORY_NAV && (
              <NavLeft>
                <li className="section">Category</li>
                <li>
                  <NavLink to="/skills/" exact>
                    All
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/tag/productivity">Productivity</NavLink>
                </li>
                <li>
                  <NavLink to="/skills/tag/crm">CRM</NavLink>
                </li>
                <li>
                  <NavLink to="/skills/tag/collaboration">
                    Collaboration
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skills/tag/notifications">
                    Notifications
                  </NavLink>
                </li>
                <li>
                  <hr />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://followupthen.typeform.com/to/bkVoBo?email=${
                      props.loggedInUser && props.loggedInUser.email
                    }`}
                  >
                    <i className="glyphicon glyphicon-wrench" /> Developers
                  </a>
                </li>
              </NavLeft>
            )}
          </div>
        </div>
        <div className="main-middle" style={{ paddingRight: 0 }}>
          <div className="list-utils" style={{ marginBottom: 10 }}>
            <SearchBox
              placeholder="Search Skills"
              handleOnChange={handleSearchOnChange}
              handleSearch={() => {}}
              value={inputSearchString}
            />
          </div>
          {/* <div className="header-section">
              <h1>Skills</h1>
              <p className="text-muted" style={{ marginTop: -20 }}>
                Make your followups more meaningful, effective and productive
              </p>
            </div> */}
          <Switch>
            <Route
              path="/skills/tag/:tag"
              render={({ match }) => (
                <div>
                  <MailBotList
                    tag={match.params.tag}
                    incInstalled={1} // includes installed in addition
                    loggedInUser={props.loggedInUser}
                    searchString={finalSearchString}
                  />
                </div>
              )}
            />
            <Route
              path="/skills/installed"
              render={({ match }) => (
                <div>
                  <h1>Installed MailBots</h1>
                  <MailBotList
                    installed="1"
                    searchString={finalSearchString}
                  />{" "}
                  {/*show only installed*/}
                </div>
              )}
            />
            <Route
              render={({ match }) => (
                <div>
                  <MailBotList
                    incInstalled={1} // includes installed in addition
                    loggedInUser={props.loggedInUser}
                    searchString={finalSearchString}
                    topCards={
                      <>
                        <Card
                          style={{ gridColumn: "1 / 3" }}
                          type="large"
                          className="main-panel mailbots-list-item"
                        >
                          <h2 style={{ fontFamily: "Lora", fontSize: 30 }}>
                            Date Formats
                          </h2>
                          <p>
                            Thousands of date formats are supported. Experiment
                            below. Type "-" for skills.
                          </p>
                          <ScheduleWidget
                            className="form-control"
                            placeholder="ex: mon8am-r"
                            getTask={() => ({
                              command: "",
                              reference_email: { to: [], subject: "Test email" }
                            })}
                          />
                          <span style={{ color: "#aaa" }}>
                            Ex: {"{time}"}@followupthen.com{" "}
                            <a
                              href="https://www.followupthen.com/how#timeformats"
                              target="_blank"
                              rel="nofollower noopener noreferrer"
                            >
                              reference
                            </a>
                          </span>
                        </Card>
                        <div className="main-panel mailbots-list-item">
                          <h2>Learn FollowUpThen</h2>
                          <p>
                            Start with our How To Use guide below, or just try
                            experimenting with date formats and skills. Join us
                            on{" "}
                            <a
                              href="https://www.twitter.com/followupthen"
                              target="_blank"
                              rel="nofollower noopener noreferrer"
                            >
                              twitter
                            </a>{" "}
                            or our{" "}
                            <a
                              href="https://followup.expert/"
                              target="_blank"
                              rel="nofollower noopener noreferrer"
                            >
                              blog
                            </a>{" "}
                            for updates. If you get stuck, visit our{" "}
                            <a
                              href="https://help.followupthen.com/"
                              target="_blank"
                              rel="nofollower noopener noreferrer"
                            >
                              help site
                            </a>{" "}
                            or{" "}
                            <a
                              href="https://help.followupthen.com/contact"
                              target="_blank"
                              rel="nofollower noopener noreferrer"
                            >
                              contact us
                            </a>
                            .
                          </p>
                          <a
                            href="https://www.followupthen.com/how"
                            target="_blank"
                            rel="nofollower noopener noreferrer"
                            className="btn btn-primary btn-block"
                          >
                            How to Use
                          </a>
                        </div>
                      </>
                    }
                  />
                  {/* <br /> */}
                  {/* <MailBotList
                      owner="self"
                      loggedInUser={props.loggedInUser}
                      showLoading={false}
                    /> */}
                </div>
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default MailBots;
