import React from "react";

const Card = ({
  children,
  onClick = null,
  type = "list",
  style = {},
  innerStyle = {},
  className = ""
}) => {
  const baseStyle = {
    common: {
      // shared between all types
    },
    type: {
      list: {
        margin: 0,
        borderRadius: 0,
        padding: 15,
        // boxShadow: "none"
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 4px"
      },
      medium: {
        padding: 15,
        margin: 10
      },
      large: {
        padding: "30px 50px",
        marginTop: "20px"
        // box-shadow: 0 1px 10px hsla(0, 0%, 0%, 0.1), 0 2px 4px rgba(0, 0, 0, 0.12);
        // borderRadius: 0
      }
    },
    card: {}
  };

  return (
    <div
      className={className + (onClick ? " hover-panel" : "")}
      onClick={onClick}
      style={{ ...baseStyle.card, ...style }}
    >
      <div
        className={"main-panel clearfix"}
        style={{ ...baseStyle.common, ...baseStyle.type[type], ...innerStyle }}
      >
        {children}
      </div>
    </div>
  );
};

export const FloatingCard = props => (
  <Card
    innerStyle={{
      marginBottom: 20,
      width: "95vw",
      maxWidth: 400,
      padding: 22
    }}
    {...props}
  />
);

export default Card;
