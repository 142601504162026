import React, { useContext, useState, useEffect } from "react";
import ModalTour from "./ModalTour";
import { message as antMessage } from "antd";
import nprogress from "nprogress";
import {
  isOnMobile,
  isOnV3,
  logout,
  mailbotsAdminBrowser,
  sendGtmEvent,
  stripAllQueryParams
} from "../lib/utils";
import { RegisterForm } from "../RegisterForm";
import {
  getMyPlans,
  getPriceFromPlanKey,
  getRemainingBudgetCent,
  getTeamAdmin,
  hasBasePlan,
  hasEmployees,
  hasLegacyCompanyPlan,
  hasLegacyPersonalPlan,
  hasLegacyProPlan,
  isEmployee,
  isOnBasicPlan,
  isOnFreePlan,
  isPaying,
  isTeamOwner,
  migrateBilling,
  userIsBillingAnnually
} from "../Billing/billingHelpers";
import { GlobalContext } from "../App";
import { BlueCallout, GreyMonoblock, PopoverStyled } from "../Layout";
import bannerFutureEmailImg from "../images/banner_fut_future_email.png";
import { ChoosePlanDialog } from "../Billing/ChoosePlanDialog";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import { logger } from "../lib/Logger";
import * as _ from "lodash";
import illustrationBcc from "../images/illustrationBcc.png";
import illustrationCc from "../images/illustrationCc.png";
import { RedoOutlined } from "@ant-design/icons";

/**
 * A collection of modal tours, some of which share screens
 * Eventually we should move this into separate components
 */

const stylesText = {
  fontSize: 18,
  textAlign: "left",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: 20
};

export const tourCardAutocomplete = ({ tourControls }) => [
  {
    id: "autocomplete",
    content: (
      <div>
        <h1>⚡️Autocomplete</h1>
        <img
          src="https://fut-cdn.s3.amazonaws.com/autocomplete-anim.gif"
          style={{ width: "90%" }}
        />
        <p>
          FollowUpThen is simple. With autocomplete, it is also effortless. Give
          your email program a head-start by emailing{" "}
          <a href="mailto:autocomplete@followupthen.com">
            autocomplete@followupthen.com
          </a>
        </p>
      </div>
    ),
    buttonText: "Next"
  }
];

export const tourCardBcc = ({ tourControls }) => [
  {
    id: "bcc-futs",
    content: (
      <div>
        <h1>Private Followups</h1>
        <p>
          “Bcc” keeps your followup entirely hidden. <br />
          Emails can also be directly sent or forwarded.
        </p>
        <br />
        <img
          src={illustrationBcc}
          style={{ width: "100%", margin: "40px 0" }}
        />
        <br />
        <p className="caption">Forgetful Bob sees no trace of FollowUpThen.</p>
      </div>
    ),
    buttonText: "Next"
  }
];

export const tourCardCc = ({ tourControls }) => [
  {
    id: "cc-futs",
    content: (
      <div>
        <h1>Remind Others</h1>
        <p>"Cc" reminds everyone in the "To" field.</p>
        <br />
        <img src={illustrationCc} style={{ width: "100%", margin: "40px 0" }} />
        <p className="caption">
          Forgetful Bob will also receive a followup Monday at 9am.
        </p>
      </div>
    ),
    buttonText: "Next"
  }
];

export const tourCardShortDomain = ({ tourControls }) => [
  {
    content: (
      <div>
        <h1>Short-Domain</h1>
        <p
          className="text-monospace"
          style={{
            fontSize: 35,
            whiteSpace: "nowrap",
            display: "block",
            fontFamily: "monospace",
            color: "#aaa",
            textAlign: "center",
            margin: "65px 30px"
          }}
        >
          tues@<code>fut.io</code>
        </p>

        <p>
          <strong>@fut.io</strong> and <strong>@followupthen.com</strong> work
          the same way. The longer form explains itself – handy when setting
          reminders for others.
        </p>
      </div>
    ),
    buttonText: "Ok ✅"
  }
];

export const tourCardBudget = ({ tourControls, loggedInUser }) => {
  const budgetCent = getRemainingBudgetCent(loggedInUser);
  return [
    {
      id: "team-budget",
      content: (
        <div>
          <h1>Skill Budget</h1>
          {budgetCent > 400 ? (
            <p>
              You have a{" "}
              <PopoverStyled
                content={
                  <div>
                    <p>
                      FollowUpThen for Teams allows the team administrator to
                      grant unique Skill budgets to each team member.{" "}
                    </p>
                    <p>
                      Any unused subscription time is credited back to your (and
                      your team's) account. This makes it cost-effective and
                      easy to try new Skills.
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="nofollower noopener noreferrer"
                        href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                      >
                        Read more
                      </a>
                    </p>
                  </div>
                }
              >
                Skills Budget
              </PopoverStyled>{" "}
              of{" "}
              <strong>
                ${budgetCent / 100}
                /mo
              </strong>
              , granted by your team administrator.{" "}
            </p>
          ) : (
            <p>
              Your account already has several skills. Your team administrator
              can assign additional budget for use in the Skills Marketplace.
              <PopoverStyled
                content={
                  <>
                    Your team administrator is:
                    <br />
                    <strong style={{ whiteSpace: "nowrap" }}>
                      {getTeamAdmin(loggedInUser).email}
                    </strong>
                    <br />
                    <br />
                    You can ask them to edit your budget from: <br />{" "}
                    <pre>app.followupthen.com/team</pre>
                    <br />
                    <br />
                    <a
                      href="https://help.followupthen.com/knowledge-base/billing-and-budgets-for-team-members/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More about team member budgets
                    </a>
                    .
                  </>
                }
              >
                Request Budget
              </PopoverStyled>
            </p>
          )}
        </div>
      ),
      buttonText: "Next",
      onClick: tourControls.next
    }
  ];
};

export const tourCardUpgrade = ({
  tourControls,
  loggedInUser,
  onUpgradeSuccess
}) => {
  return [
    {
      id: "upgrade",
      className: null,
      content: (
        <ChoosePlanDialog
          onSuccess={onUpgradeSuccess}
          overrides={{
            SubHeader: (
              <>
                {isOnFreePlan(loggedInUser) ? (
                  <div>
                    <p
                      style={{
                        color: "#aaa",
                        marginTop: -17,
                        marginBottom: 10
                      }}
                    >
                      or
                    </p>
                    <p>
                      <a
                        style={{ color: "#333", textDecoration: "underline" }}
                        onClick={() => {
                          logger.log("billing: chose free plan in onboarding");
                          tourControls.next();
                        }}
                      >
                        Continue with the free plan.
                      </a>{" "}
                      <br />
                      (50 active followups/mo)
                    </p>
                  </div>
                ) : null}
                {hasLegacyPersonalPlan(loggedInUser) ? (
                  <div>
                    <p>
                      You can also{" "}
                      <a
                        onClick={() => {
                          logger.log(
                            "billing: chose to keep legacy plan in onboarding"
                          );
                          tourControls.next();
                        }}
                      >
                        continue with your legacy plan
                      </a>
                      .
                    </p>
                  </div>
                ) : null}
                {hasBasePlan(getMyPlans(loggedInUser)) ? (
                  <div>
                    <p>
                      Here are the features of your upgraded plan <br />
                      <br />
                      <a
                        className="btn btn-primary"
                        onClick={() => tourControls.next()}
                      >
                        Next
                      </a>
                      .
                    </p>
                  </div>
                ) : null}
              </>
            ),
            Footer: (
              <div style={{ textAlign: "center" }}>
                <a
                  style={{ color: "rgb(170, 170, 170)", margin: 30 }}
                  onClick={() => tourControls.back()}
                >
                  ⬅ Previous Step
                </a>
              </div>
            )
          }}
        />
      )
    }
  ];
};

export const tourCardNewUserSubscribe = ({ tourControls, loggedInUser }) => {
  return [
    {
      id: "upgrade",
      className: null,
      content: (
        <ChoosePlanDialog
          overrides={{
            SubHeader: !hasBasePlan(getMyPlans(loggedInUser)) ? (
              <div>
                <p>
                  Start your free trial below, or{" "}
                  <a
                    onClick={() => {
                      logger.log("billing: new user continued with free plan");
                      tourControls.next();
                    }}
                  >
                    continue on the free plan
                  </a>{" "}
                  with 50 active followups per month .
                </p>
              </div>
            ) : (
              <p>
                Here are some of the features included in your plan. <br />
                <br />
                <a
                  className="btn btn-primary"
                  onClick={() => {
                    tourControls.next();
                  }}
                >
                  Next
                </a>
              </p>
            )
          }}
        />
      )
    }
  ];
};

export const tourCardSkills = ({ tourControls, loggedInUser }) => [
  {
    id: "skills",
    content: (
      <div>
        <h1 className="serif">Skills</h1>
        <p>
          <strong>"Skills"</strong> make your followups smarter. For example,
          this cancels a followup if your recipient replies.
          <br />
          {/* Add them after your date format like this: */}
        </p>
        <p
          className="text-monospace"
          style={{
            whiteSpace: "nowrap",
            display: "block",
            fontFamily: "monospace",
            color: "#aaa",
            textAlign: "center",
            margin: "40px 30px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          monday8am<code>-r</code>@fut.io
        </p>
      </div>
    ),
    buttonText: "Next",
    onClick: tourControls.next
  },
  {
    id: "skills-marketplace",
    content: (
      <div>
        <h1 className="serif">Explore Skills</h1>
        <p>
          Integrate other tools, customize your followups, streamline your
          workflow and more.
        </p>
        <br />
        <div
          style={{
            marginTop: 10,
            textAlign: "left",
            maxWidth: 450,
            backgroundColor: "#f9f9f9f9",
            padding: isOnMobile() ? 15 : 25,
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <span
            style={{
              position: "absolute",
              top: 8,
              right: 11,
              textTransform: "uppercase",
              color: "rgb(208, 208, 208)",
              fontSize: 10
            }}
          >
            Examples
          </span>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "15% 85%",
              rowGap: 24,
              alignItems: "center"
            }}
          >
            <img
              alt="Icon: Response Detection (-r)"
              src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-rd.png"
              className="mailbots-icon x-small"
            />
            <div>
              <strong>Response Detection</strong>
              <br /> Cancel a followup if a response is received
            </div>
            <img
              alt="Icon: Zapier"
              src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-zapier.png"
              className="mailbots-icon x-small"
            />
            <div>
              <strong>Zapier</strong>
              <br />
              Connect FUT to thousands of other apps
            </div>
            <img
              alt="Icon: Google Calendar"
              src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-cal.png"
              className="mailbots-icon x-small"
            />
            <div>
              <strong>Google Calendar</strong>
              <br />
              Manage followups directly on your calendar
            </div>
          </div>
          {/* <code>
            <strong>-sms</strong>
          </code>{" "}
          sends an sms
          <br />
          <code>
            <strong>-r</strong>
          </code>{" "}
          cancels followup if a reply is received
          <br />
          <code>
            <strong>-t</strong>
          </code>{" "}
          follow up up until a task is complete */}
        </div>
        <br />
        <br />
        {isOnMobile() ? null : (
          <p>Discover skills in the new Skills Marketplace.</p>
        )}
      </div>
    ),
    buttonText: "Next",
    onClick: tourControls.next
  }
];

// Account for email-only signup by auto-migrating any pending followups
// https://github.com/mailbots/fut-core-api/issues/2949
export const tourCardWelcomeNewUser = ({ tourControls, loggedInUser }) => {
  return [
    {
      id: "welcome-new-user",
      content: (
        <div>
          <h2 className="serif">Welcome to FollowUpThen</h2>
          <img
            style={{ width: "100%" }}
            src={bannerFutureEmailImg}
            // src="https://dl.dropboxusercontent.com/s/moalaxev21rdh9z/fut_future_email.png?dl=0"
          />
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 75
            }}
          >
            {getTeamAdmin(loggedInUser).email ? (
              <>
                {getTeamAdmin(loggedInUser).name ||
                  getTeamAdmin(loggedInUser).email}{" "}
                invited you to join their team.
              </>
            ) : (
              <span>Say hello to your new Followup Assistant 👋</span>
            )}
          </p>
          <RegisterForm
            nextText="Get Started"
            style={{
              position: "absolute",
              bottom: "9%",
              left: isOnMobile() ? 50 : 85,
              right: isOnMobile() ? 50 : 85
            }}
            hideFields={["email", "fullName"]}
            handleSubmit={async userData => {
              try {
                sendGtmEvent("set_timezone", {
                  category: "signup",
                  action: "set_timezone"
                });
                const res = await handleUpdateUser(userData, loggedInUser);
                tourControls.next();
              } catch (e) {
                console.error(e);
              }
            }}
          />
        </div>
      )
    }
  ];
};

export const TourCardTeamBillingInviteTeam = ({ tourControls }) => {
  const [teamMembers, setTeamMembers] = useState();
  const [isInviting, setIsInviting] = useState(false);
  const { loggedInUser } = useContext(GlobalContext);
  const monthlyPrice = getPriceFromPlanKey(
    "v3_base",
    userIsBillingAnnually(loggedInUser)
  );

  useEffect(() => {
    mailbotsAdminBrowser
      .getTeamMembersAndSettings()
      .then(res => {
        const members = res.team.members;
        const teamCsv = members
          .filter(m => m.email !== loggedInUser.email)
          .reduce((bucket, current) => bucket.concat(current.email), [])
          .join(", ");
        setTeamMembers(teamCsv);
      })
      .catch(e => {
        antMessage.error(
          "Sorry, we could not load your existing team. Pleaes contact help@humans.fut.io for help. The error was: " +
            e.message
        );
      });
  }, []);

  const priceDescription = `$${monthlyPrice}
  per month ${userIsBillingAnnually ? "(billed annually)" : ""}`;
  return (
    <div>
      <h1>Invite Your Team</h1>
      <p>Invite both new and existing team members. </p>
      <div style={{ textAlign: "left", marginTop: 18 }} className="form-group">
        <label>Team Member Emails</label>{" "}
        <textarea
          className="form-control"
          value={teamMembers}
          onChange={e => setTeamMembers(e.target.value)}
          name="existing-team-members"
          rows="4"
        />
        <span style={{ color: "#aaa", marginTop: 4, display: "block" }}>
          Separate emails by comma (ex: 1@acme.com, 2@acme.com, etc)
        </span>
        <div style={{ marginTop: 30, marginBottom: 0, textAlign: "center" }}>
          <a
            className="btn btn-primary"
            onClick={async () => {
              try {
                setIsInviting(true);
                nprogress.start();
                // ensure user did not enter their own email
                if (
                  teamMembers &&
                  teamMembers
                    .split(",")
                    .some(eml => loggedInUser.emails.includes(eml.trim()))
                ) {
                  window.alert(
                    "This is only to add additional team members. You can add other emails that you own from your settings page."
                  );
                  return;
                }

                await mailbotsAdminBrowser.addTeamMembersByCsv({
                  teamMemberEmailsCsv: teamMembers,
                  budgetCent: 0
                });
                setIsInviting(false);
                tourControls.goToStepId("invite-team-done");
              } catch (e) {
                console.log(e);
                antMessage.error(
                  "Sorry, we ran into an issue inviting your team. Please email help@humans.fut.io. The error was: " +
                    e.message
                );
              }
              nprogress.done();
            }}
          >
            {!isInviting ? "Invite Now" : "...Inviting"}
          </a>
          <br />
          <a
            className="btn btn-default"
            onClick={() => tourControls.goToStepId("setup-sms-cal")}
          >
            Later
          </a>{" "}
        </div>
      </div>
    </div>
  );
};
export const tourCardTeamBillingInviteTeam = ({
  tourControls,
  loggedInUser
}) => {
  return {
    id: "invite-team",
    // This has to be in a React component to manage state, etc.
    content: (
      <TourCardTeamBillingInviteTeam
        tourControls={tourControls}
        loggedInUser={loggedInUser}
      />
    ),
    buttonText: null
  };
};

export const tourCardTeamInvitesDone = ({ tourControls, loggedInUser }) => {
  return {
    id: "invite-team-done",
    content: (
      <div>
        <h1>✅ Invitations Sent</h1>
        <p>
          Manage budgets, addons and team members from <br />
          <code> Account &gt; Team</code>
        </p>
      </div>
    ),
    onBackClick: () => tourControls.goToStepId("invite-team"),
    onClick: () => tourControls.next(),
    buttonText: "Next"
  };
};

export const tourCardPricingIndividual = ({
  tourControls,
  loggedInUser,
  onClickNext
}) => [
  {
    id: "new-pricing",
    content: (
      <div>
        <h1 className="serif">New, Simplified Pricing</h1>
        <p>
          One base plan unlocks unlimited followups. Invest in Skills only when
          they make sense for you.
        </p>

        {hasLegacyPersonalPlan(loggedInUser) ? (
          <BlueCallout style={{ textAlign: "left" }}>
            <strong>You are on a legacy plan</strong>
            <br />
            You can keep the same pricing and limits, but you can also upgrade
            if you'd like! Moving to our latest plan will:
            <ul>
              <li>🚀 remove your followup limit</li>
              <li>🥋 allow you to install premium skills</li>
              <li>✨ unlock features only available on new plans</li>
            </ul>
          </BlueCallout>
        ) : null}

        {hasLegacyProPlan(loggedInUser) ||
        (hasLegacyCompanyPlan(loggedInUser) && !hasEmployees(loggedInUser)) ? (
          <BlueCallout
            style={{ textAlign: "left", height: 350, overflowY: "scroll" }}
          >
            <strong>
              ⭐️ Your monthly price will not change, but a few plan limits are
              changing
            </strong>
            <br />
            <br />
            As a legacy user, you will be automatically placed on our newest
            plan. There are some great new features but also a few new limits.
            Here are the highlights.
            <br />
            <br />
            <strong>Unlimited Followups 🎉</strong>
            <br />
            Schedule as many followups as you'd like! As long as you are not
            sharing your FUT account with multiple users, or heavily automating
            reminders, you have unlimited followups. Reasonable limits are in
            place. Please contact us to request increases if needed.
            <br />
            <br />
            <strong>Connected Inboxes are $4/mo each (paid annually) 📧</strong>
            <br />
            As a legacy user, you have up to 3 connected inboxes. Note that{" "}
            <a
              href="https://help.followupthen.com/knowledge-base/rd-aliases/"
              target="_blank"
              rel="nofollower noopener noreferrer"
            >
              aliases do not need to be connected
            </a>
            , so this should be plenty for most users.
            <br />
            <br />
            If you have more than 3 connected inboxes, you can leave your
            connected inboxes as-is for now. Billing changes trigger when you
            add or remove new inboxes. Your pricing will not change at this
            time. (Contact us if you have questions on this point).
            <br />
            <br />
            <strong>SMS Message Limits 💬</strong>
            <br />
            We are introducing pricing tiers for SMS messages. Very few should
            be impacted. The messaging is clear when limits are approached.
            <br />
            <br />
            <strong>Multi-User Account Limits 👥</strong>
            <br />
            With our new, affordable team features, we will be limiting the
            ability to share a single FUT account with multiple users. Please
            contact us if you are managing a shared support inbox.
          </BlueCallout>
        ) : null}

        {hasLegacyCompanyPlan(
          loggedInUser ? (
            <BlueCallout>
              <p>
                Company billing has changed. Please{" "}
                <Link to={window.location.pathname + "?tour=existingTeamOwne"}>
                  click here
                </Link>{" "}
                to for the team owner tour.
              </p>
            </BlueCallout>
          ) : null
        )}

        <br />
        {process.env.REACT_APP_BILLING_ENFORCED !== "yes" ? (
          <BlueCallout>
            🎉 We are not yet charging for premium skills in beta. Enjoy!
          </BlueCallout>
        ) : null}
      </div>
    ),
    buttonText: "Next",
    // go straight to migration if we are auto-migrating billing. else, ask to upgrade
    onClick: () => {
      if (onClickNext) {
        onClickNext();
        return;
      }

      if (hasLegacyPersonalPlan(loggedInUser) || isOnFreePlan(loggedInUser)) {
        tourControls.goToStepId("upgrade");
      } else if (!isOnV3(loggedInUser)) {
        tourControls.goToStepId("do-migration");
        // they were auto-migrated and already on a paid plan
      } else if (isPaying(loggedInUser)) {
        tourControls.goToStepId("setup-sms-cal");
      } else {
        tourControls.next();
      }
    }
  }
];

export const tourCardBillingTeam = ({ tourControls, loggedInUser }) => {
  return [
    {
      id: "new-pricing-team",
      content: (
        <div>
          {" "}
          <h1>New Pricing</h1>
          <div>
            <p>
              Now only <strong>$4/mo</strong> 🎉 per member <br />
              Grant unique{" "}
              <PopoverStyled
                content={
                  <div>
                    Skills (ex, response detection, sms and calendar
                    integration) let team members add features to FollowUpThen
                    to compliment their unique workflows. Use budgets to control
                    spending. Your team account is only charged for skills that
                    are used. Read more about{" "}
                    <a
                      href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
                      target="_blank"
                      rel="nofollower noopener noreferrer"
                    >
                      team pricing
                    </a>
                    .
                  </div>
                }
              >
                skill budgets
              </PopoverStyled>{" "}
              to each team member .
            </p>
            <BlueCallout
              style={{
                textAlign: "left",
                padding: isOnMobile() ? 15 : 40,
                margin: isOnMobile() ? 8 : 20
              }}
            >
              <strong>Pricing Changes</strong>
              <br />
              <ul>
                <li>
                  Each team member is now $4/member/month (billed annually.
                  $5/mo billed monthly). We no longer offer an additional free
                  seat.
                </li>
                <li>
                  Everyone on your team will receive a discounted (optional)
                  add-on with discounted access to SMS and Response Detection,
                  keeping your per-member costs the same.
                </li>
              </ul>{" "}
              After migration, go to <strong>Account &gt; Billing</strong> for
              pricing details. Read more about{" "}
              <a
                href="https://help.followupthen.com/2020/12/30/new-pricing/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                our new pricing
              </a>{" "}
              and our{" "}
              <a
                href="https://help.followupthen.com/knowledge-base/fair-billing-policy-teams/"
                target="_blank"
                rel="nofollower noopener noreferrer"
              >
                team plans
              </a>
              .
            </BlueCallout>
          </div>
        </div>
      ),
      buttonText: "Next"
    }
  ];
};

export const tourCardWelcomeToV3 = ({ tourControls, loggedInUser, onSkip }) => {
  return [
    {
      id: "welcome-existing-user",
      content: (
        <div>
          <h1 className="serif">
            Welcome to <br />
            FollowUpThen
          </h1>
          <img src={bannerFutureEmailImg} style={{ width: "100%" }} />
          <div style={{ ...stylesText, textAlign: "center" }}>
            {/* {getTeamAdmin(loggedInUser).email ? (
            <p style={{ textAlign: "center" }}>
              You've been invited to join the team of{" "}
              <span style={{ whiteSpace: "nowrap", textDecoration: "italic" }}>
                {getTeamAdmin(loggedInUser).name ||
                  getTeamAdmin(loggedInUser).email}
              </span>
            </p>
          ) : ( */}
            <p>
              Introducing your new <br />
              Personal Followup Assistant 👋
            </p>
            {/* )} */}
          </div>
        </div>
      ),
      buttonText: "What's New?",
      stepFooter: (
        <>
          {isEmployee(loggedInUser) &&
          loggedInUser.opt_into_fut_mailbot ? null : (
            <a
              onClick={() => {
                logger.log("skipped migration tour");
                if (onSkip) {
                  onSkip();
                  return;
                }
                debugger;
                if (
                  isPaying(loggedInUser) &&
                  !isEmployee(loggedInUser) &&
                  !isTeamOwner(loggedInUser)
                ) {
                  tourControls.goToStepId("new-pricing");
                } else if (isEmployee(loggedInUser) && !isOnV3(loggedInUser)) {
                  tourControls.goToStepId("setup-sms-cal");
                } else if (
                  isTeamOwner(loggedInUser) &&
                  hasEmployees(loggedInUser)
                ) {
                  tourControls.goToStepId("new-pricing-team");
                } else if (!isOnV3(loggedInUser)) {
                  tourControls.goToStepId("do-migration");
                } else {
                  tourControls.closeTour();
                }
              }}
              style={{
                color: "#aaa",
                display: "block",
                padding: 15,
                textDecoration: "none",
                marginTop: -55,
                marginBottom: 5
              }}
            >
              Skip
            </a>
          )}
        </>
      )
    }
  ];
};

export const tourCardFamiliarFast = ({
  tourControls,
  loggedInUser,
  onSkip
}) => {
  return [
    {
      id: "familiar-fast",
      content: (
        <div>
          <h1 className="serif">
            Friendly. Familiar. <br />
            And (really) fast.
          </h1>
          <p>
            {/* Migrating or signing up directly on v3 */}
            {loggedInUser.opt_into_fut_mailbot
              ? "Add a followup to any email in seconds."
              : "It's still as fast and as simple as before"}{" "}
          </p>
          <img
            src="https://fut-cdn.s3.amazonaws.com/autocomplete-anim.gif"
            style={{ width: "85%" }}
          />
          <p className="caption">
            Tip: Set up autocomplete by emailing{" "}
            <PopoverStyled
              content={
                <p>
                  When you email this address, we'll respond with about 70
                  followup emails in the 'cc' field. Hit "Reply all" to teach
                  your email program those date formats.
                  <br />
                  <br />
                  <strong>What is fut.io?</strong> It's our handy short domain.
                  Identical to followupthen.com, but fewer keystrokes.
                </p>
              }
            >
              autocomplete@fut.io
            </PopoverStyled>
          </p>
        </div>
      ),
      buttonText: "Next",
      stepFooter: onSkip ? (
        <div>
          <a
            style={{
              // cover "back" button with Skip Tour
              position: "relative",
              color: "#aaa",
              display: "block",
              padding: 15,
              textDecoration: "none",
              marginTop: -50,
              marginBottom: 5,
              backgroundColor: "white"
            }}
            onClick={onSkip}
          >
            Skip Tour
          </a>
        </div>
      ) : null
    }
  ];
};

export const tourCardMigrationDoneEnjoyThePresent = ({
  tourControls,
  loggedInUser
}) => [
  {
    id: "and-more",
    content: (
      <div>
        {" "}
        <h1> 🎉 Migration Complete</h1>
        <p>
          Your new and improved personal followup assistant ready for action.
          Feel free to contact us with any feedback.
        </p>
      </div>
    ),
    buttonText: "Close",
    onClick: () => {
      stripAllQueryParams();
      tourControls.closeTour();
      // window.location.reload();
    }
  }
];

export const tourCardNewUserDone = ({ tourControls, loggedInUser }) => [
  {
    id: "new-user-done",
    content: (
      <div>
        {" "}
        <h1>Happy Following Up!</h1>
        <p>May your inbox never be the same again.</p>
        <p>Read more about how to use FollowUpThen:</p>
        <p style={{ marginTop: 15 }}>
          <a
            href="https://www.followupthen.com/how"
            target="_blank"
            rel="nofollower noopener noreferrer"
            className="btn btn-primary"
          >
            How To Use Guide
          </a>
        </p>{" "}
      </div>
    ),
    buttonText: "Close",
    onClick: () => {
      tourControls.closeTour();
      window.location.href = `${window.location.host}/${window.location.pathname}`;
    }
  }
];

export const TourCardMigrationDoMigration = ({
  tourControls,
  loggedInUser,
  switchToV3,
  onMigrationDone
}) => {
  const [isMigrating, setIsMigrating] = useState(false);
  const [totalFuts, setTotalFuts] = useState(0);
  const [migratedFuts, setMigratedFuts] = useState(0);

  const migrateFUTs = async e => {
    await new Promise(resolve => {
      mailbotsAdminBrowser.userMigrateToV3({
        statusUpdateCb: migrationStatus => {
          console.log("Update:", migrationStatus);
          setTotalFuts(
            parseInt(migrationStatus.v3) + parseInt(migrationStatus.v2)
          );
          setMigratedFuts(parseInt(migrationStatus.v3));
        },
        doneCb: () => {
          console.log("Followups migrated:");
          resolve();
        }
        // error handling done internally
      });
    });
  };

  // Turn on our new and improved daily reminder task during migration
  const createUpcomingRemindersTask = async () => {
    // warning: Magic string that must match fut-mailbot
    const DAILY_SUMMARY_SEARCH_KEY = "sys_list";

    try {
      const result = await mailbotsAdminBrowser.getTasks({
        search_keys: [DAILY_SUMMARY_SEARCH_KEY],
        invisible: true
      });

      const alreadyHasSummaryTask = result.tasks && result.tasks.length;
      if (alreadyHasSummaryTask) return;

      // create upcoming FUTs task is none exist
      const timezone = loggedInUser.timezone;
      const naturalTimeResponse = await mailbotsAdminBrowser.naturalTime({
        format: "tomorrow6am",
        timezone
      });

      await mailbotsAdminBrowser.createTask({
        verbose: 0,
        webhook: false,
        task: {
          command: `everyWeekDay6am-list.1day@followupthen.com`,
          stored_data: {},
          search_keys: [DAILY_SUMMARY_SEARCH_KEY],
          reference_email: {
            to: "",
            subject: "Upcoming Followups",
            html: ""
          },
          trigger_time: naturalTimeResponse.time_ts ?? 0,
          invisible: true
        }
      });
      logger.log("upcoming futs invisible task created during migration");
    } catch (e) {
      logger.log("upcoming futs invisible task error in migration", {
        level: "error",
        data: { error: e }
      });
    }
  };

  return (
    <div>
      {isMigrating ? (
        <div>
          <h1>Migrating...</h1>
          <Loading />
          <p style={{ ...stylesText, color: "#ccc", textAlign: "center" }}>
            Migrated {migratedFuts} of {totalFuts}
          </p>
          <p style={{ ...stylesText, textAlign: "center" }}>
            This might take a few minutes. Please do not close your browser.
            Email help@humans.fut.io if you run into trouble.
          </p>
        </div>
      ) : (
        <div>
          {" "}
          <h1>Migrate to the new FollowUpThen</h1>
          <p>Welcome! 👋 </p>
          {isTeamOwner(loggedInUser) && hasEmployees(loggedInUser) ? (
            <BlueCallout>
              This will migrate team billing and only your followups. You can
              invite your team to migrate in the next step.
            </BlueCallout>
          ) : null}
          <a
            style={{ marginTop: 50 }}
            onClick={async e => {
              try {
                setIsMigrating(true);
                await migrateFUTs();
                createUpcomingRemindersTask();
                let user = {};
                user.opt_into_fut_mailbot = 1;
                // @todo https://github.com/mailbots/fut-core-api/issues/2853
                user.accepted_terms = 1;
                if (!isEmployee(loggedInUser)) {
                  user.opt_into_billing_v3 = 1;
                }
                await mailbotsAdminBrowser.userUpdateSelf({ user });
                setIsMigrating(false);
                logger.log("v3 migration done");
                if (onMigrationDone) return onMigrationDone();
                tourControls.next();
              } catch (e) {
                window.alert(
                  `We hit an error while migrating your followups. Try refreshing the page to try again. \
Contact help@humans.fut.io if you need help. \n\n The error was: ${e.message}`
                );
                console.error("Error caught migrating account", e);
                logger.log("Error caught migrating account", {
                  level: "error",
                  data: { error_str: e.message }
                });
              }
              setIsMigrating(false);
            }}
            className="btn btn-primary"
          >
            Migrate
          </a>
        </div>
      )}
    </div>
  );
};

export const tourCardMigrationDoMigration = ({
  tourControls,
  loggedInUser,
  switchToV3,
  onMigrationDone
}) => [
  {
    id: "do-migration",
    content: (
      <TourCardMigrationDoMigration
        tourControls={tourControls}
        loggedInUser={loggedInUser}
        switchToV3={switchToV3}
        onMigrationDone={onMigrationDone}
      />
    ),
    buttonText: null
  }
];

export const tourCardMigrationIsMigrating = () => ({
  content: (
    <div>
      <h1>Migrating Tasks...</h1>
      <Loading />
      <p style={stylesText}>
        This might take a few minutes. If you have large number of tasks, please
        try refreshing the page after a few minutes (even if you receive an
        error). Email help@humans.fut.io if you run into trouble.
      </p>
    </div>
  )
});

export const tourCardSetupSmsCalendar = ({ tourControls, loggedInUser }) => [
  {
    id: "setup-sms-cal",
    content: (
      <div style={{ display: "relative" }}>
        <h1>You're All Set ✅</h1>
        <p>
          Your account is ready for action. Feel free to contact us from the
          menu in the upper-right with any questions or feedback.
        </p>
        <br />
        <p>
          <strong>Note:</strong> If you integrated FollowUpThen with your
          calendar, the URL will need to be{" "}
          <a
            href={`${process.env.REACT_APP_MAILBOTS_WEB_APP_BASE}/skills/ical/settings"}`}
          >
            {" "}
            updated
          </a>
          . Your other settings have been automatically migrated.
        </p>
        {isTeamOwner(loggedInUser) && hasEmployees(loggedInUser) ? (
          <BlueCallout>
            Your billing has been updated. Existing team member have been
            granted a legacy addon which enables SMS and Response Detection at a
            discounted rate. You can cancel this addon at any time to reduct
            costs. <Link to="/billing">View Billing Details</Link>.
          </BlueCallout>
        ) : null}
      </div>
    ),
    buttonText: "Get Started",
    onClick: () => {
      window.location.href = "/skills"; // reload to account for migrated / updated user
      tourControls.closeTour();
    }
  }
];

export const tourCardsValidateEmail = ({ tourControls, loggedInUser }) => [
  {
    id: "validate-email",
    content: (
      <div>
        <h1>Confirm Your Email</h1>
        <p>
          Please click the link we just sent to{" "}
          <span style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
            {loggedInUser.email}
          </span>
        </p>
        <ContentButtons>
          <a
            onClick={() => {
              logger.log("signup: no email 1");
              tourControls.goToStepId("no-email-1");
            }}
          >
            No email?
          </a>{" "}
        </ContentButtons>
      </div>
    )
  },
  {
    id: "no-email-1",
    content: (
      <div>
        <h2>Ok, let's try this</h2>
        <span style={{ fontSize: 50 }}>🤔</span>
        <p>
          <ul style={{ textAlign: "left", maxWidth: 420, margin: "auto" }}>
            <li>Check your spam folder</li>
            <li>
              Look for emails from <strong>noreply@followupthen.com</strong>
            </li>
            <li>
              Add the above address to your address book and re-send the email
              (below)
            </li>
            <li>
              Is {loggedInUser.email} the wrong email?{" "}
              <a
                style={{ color: "#333", textDecoration: "underline" }}
                onClick={() => {
                  if (!isOnFreePlan(loggedInUser)) {
                    window.alert(`Please create a new account, then contact help@humans.fut.io to transfer \
your subscription to the correct email. In your request, include the last 4 digits your card number for reference. \n 
We're happy you upgraded 🙌`);
                  }
                  logout();
                  logger.log("signup: wrong email, started over");
                }}
              >
                Start over
              </a>
            </li>
          </ul>
        </p>
        <br />
        <br />
        <ContentButtons>
          <a
            onClick={async e => {
              e.preventDefault();
              try {
                nprogress.start();
                let res = await mailbotsAdminBrowser.resendValidation({
                  email: loggedInUser.email
                });
                if (res instanceof Error) throw res;
                antMessage.success(res.message);
                logger.log("signup: re-sent welcome email");
                nprogress.done();
              } catch (e) {
                nprogress.done();
                console.error(e);
                antMessage.error(e.message);
                logger.log("signup: error re-sending email", {
                  level: "error",
                  data: { error_str: e.message }
                });
              }
            }}
          >
            <RedoOutlined /> Resend
          </a>{" "}
          <a
            onClick={() => {
              logger.log("signup: no email 2");
              tourControls.goToStepId("no-email-2");
            }}
          >
            I still didn't receive it
          </a>{" "}
        </ContentButtons>
      </div>
    ),
    buttonText: ""
  },
  {
    id: "no-email-2",
    content: (
      <div>
        <h2>Let's get the humans!</h2>
        <LargeMoji>🚑</LargeMoji>
        <p>
          Contact us at{" "}
          <a href="mailto:help@humans.fut.io">help@humans.fut.io</a>.
        </p>
        <p>
          You can also contact your email provider and ask them to whitelist{" "}
          <strong>noreply@followupthen.com</strong>.
        </p>
      </div>
    ),
    buttonText: "" // cannot close this tour since their email is not validated
  }
];

/**
 * Utility Fns
 */

export const handleUpdateUser = async (userData, loggedInUser) => {
  try {
    nprogress.start();
    if (!userData.password && !loggedInUser.password_set) {
      throw Error("Please choose a password");
    }

    if (!loggedInUser.password_set && userData.password.length < 5) {
      throw Error(
        "Sorry, that password is a bit too short. Please try another."
      );
    }

    if (!userData.timezone) {
      throw Error(
        "For some reason we not understand your timezone. Email help@humans.fut.io if you need help."
      );
    }

    // !userData.timezone ||
    const updatePayload = {
      name: userData.fullName,
      password: userData.password,
      timezone: userData.timezone,
      accepted_terms: userData.termsChecked,
      opt_into_fut_mailbot: true
    };
    const res = await mailbotsAdminBrowser.userUpdateSelf({
      user: updatePayload
    });
    nprogress.done();
    return res;
  } catch (e) {
    nprogress.done();
    console.error(e);
    antMessage.error(e.message);
    throw e;
  }
};

export const LargeMoji = props => (
  <span style={{ fontSize: 50 }}>{props.children}</span>
);

const ContentButtons = props => (
  <p
    style={{
      display: "flex",
      justifyContent: "space-around",
      maxWidth: 350,
      margin: "auto",
      marginTop: 70,
      textAlign: "center"
    }}
  >
    {props.children}
  </p>
);
