/**
 * Get the cache object if it exists or a reasonable default.
 */
function getCache() {
  if (window.futCache) {
    return window.futCache;
  }

  // data array will hold objects that
  // have a id equal to a task id,
  // a task object if it is a basic task
  // or it could also have other fields
  // needed by a verbose task
  return {
    key: "",
    lastUpdated: Date.now(),
    data: []
  };
}

/**
 * Cache a new array of tasks.
 */
export function cacheTasks(key, tasks) {
  const cache = getCache();

  // check if the cached tasks are the same
  // as the new ones and don't update the cache if it is
  const existingDataIds = cache.data.map(datum => datum.id).join("");
  const newDataIds = tasks.map(t => t.id).join("");
  if (existingDataIds !== newDataIds) {
    window.futCache = {
      key,
      lastUpdated: Date.now(),
      data: tasks.map(task => ({ id: task.id, task }))
    };
  } else {
    // console.log("cached data is the same as new one");
  }
  setTaskCacheIsStale(false);
}

/**
 * Get array of cached tasks.
 */
export function getCachedTasks(key) {
  const cache = getCache();

  // if the cache key is different it should be invalidated
  if (key !== null && key !== cache.key) {
    cacheTasks(key, []);
    return [];
  }

  // if the cache is older than 3 minutes, invalidate it
  if (Date.now() - cache.lastUpdated > 3 * 60 * 1000) {
    console.log("all tasks cache expired, invalidating");
    cacheTasks(key, []);
    return [];
  }

  return cache.data.map(datum => datum.task);
}

/**
 * Get a cached task by id if it exists.
 */
export function getCachedTaskById(taskId) {
  const cache = getCache();
  const found = cache.data.find(datum => datum.id == taskId);
  if (found) return found.task;

  return null;
}

/**
 * Cache a verbose task.
 */
export function cacheVerboseTask(verboseResponse) {
  let cache = getCache();

  // find the regular task in the cache and update with verbose info
  const found = cache.data.find(datum => datum.id == verboseResponse.task.id);
  if (found) {
    // add the value to the existing object in the array
    Object.assign(found, { ...verboseResponse });
  }
}

/**
 * Get a verbose task response from the cache if it exists.
 */
export function getCachedVerboseTaskById(taskId) {
  let cache = getCache();

  // check if the tasks exists and has verbose info
  const found = cache.data.find(datum => datum.id == taskId);
  if (found && !!found.messages) {
    return found;
  }

  return null;
}

/**
 * Invalidate all cached data
 */
export function invalidateTaskCache() {
  cacheTasks("", []);
}

/**
 * Simple implementation fo stale while refresh
 */
export function setTaskCacheIsStale(isStale = true) {
  window.futCacheIsStale = isStale;
}

export function taskCacheIsStale() {
  return window.futCacheIsStale;
}
