import React, { useContext, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";

import {
  conversationalDate,
  userDate,
  isFutAddress,
  isOwnerAddress,
  taskInErrorState
} from "../lib/utils";
import { Tooltip } from "antd";
import { PopoverStyled } from "../Layout";
import { SkillsAppliedToTask } from "./SkillsAppliedToTask";
import { GlobalContext } from "../App";

const TaskDetails = styled.div`
  & > a,
  a:visited {
    color: #2c3e50;
  }

  &:hover > a {
    text-decoration: none;
  }

  flex: 5;
  margin-left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: flex-start;
  justify-content: center;
`;

const TaskSubject = styled.p`
  font-size: 13px;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TaskBody = styled.span`
  fontsize: 11px;
  color: #aaaaaa;
  margin-bottom: 0;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TaskDueDate = styled.p`
  white-space: nowrap;
  font-size: 12px;
  color: #11a2d2;
  margin: 0;
  height: 15px;
`;

const TaskContact = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70vw;
`;

const VCenter = styled.div`
  display: flex;
  align-items: center;
  height: 40;
`;

// Ex: "email@email and 2 more"
// The commit for this line deletes and earlier method "getPeopleString"
// which used the People and the reference_email to build this string.
const getRecipientString = ({ task, format = "short" }) => {
  let toRecipients = task.reference_email.to || [];
  toRecipients = toRecipients.filter(recipient => !isFutAddress(recipient));
  if (!toRecipients.length) return "";
  if (format === "short") {
    if (toRecipients.length === 1) return toRecipients[0];
    return `${toRecipients[0]} and ${toRecipients.length - 1} more`;
  }
  return toRecipients.map(r => r.name).join(", ");
};

// https://github.com/mailbots/fut-core-api/issues/2895
const getPeopleString = ({ task }) => {
  const people =
    (task && task.relationships && task.relationships.people) || [];
  // joe@smith.com and x more...
  if (people.length > 1) {
    return `${people[0].name || people[0].email} and ${people.length - 1} more`;
  }
  return null;
};

/**
 * Retrive every email from to, cc, bcc, from and forwarded messages, includes duplicates
 */
const getAllEmailsFromRefEmail = referenceEmail => {
  // used only for parsing From address @todo – core API should really parse all emails into standard object
  let parseEmailNaive = emailStr => {
    if (!emailStr) return [];
    const matches = emailStr.match(/<(.*)>/);
    if (!matches) return [emailStr];
    if (typeof matches[1] == "string") return matches[1];
    return [];
  };

  let allEmails = [
    ...(referenceEmail.to || []),
    ...(referenceEmail.cc || []),
    ...(referenceEmail.bcc || [])
  ];
  if (referenceEmail.forwarded_message) {
    const fwdMsg = referenceEmail.forwarded_message;
    allEmails = [...allEmails, ...(fwdMsg.to || [])];
    allEmails = [...allEmails, ...(fwdMsg.cc || [])];
    allEmails = [...allEmails, parseEmailNaive(fwdMsg.from)];
  }

  return allEmails
    .filter(e => !isOwnerAddress(e))
    .filter(e => !isFutAddress(e));
};

const isExternal = referenceEmail => {
  const ccAddressList = referenceEmail.cc || [];
  return ccAddressList.some(isFutAddress);
};

const TaskListItem = props => {
  const { loggedInUser } = useContext(GlobalContext);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  const handleOnClick = e => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
    props.history.push(`/tasks/${props.id}`);
  };

  return (
    <div
      className="tasks-list-item"
      style={props.completed ? { opacity: 0.5 } : {}}
      onClick={handleOnClick}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 10px 0 5px"
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <input
            type="checkbox"
            onChange={e => {
              if (props.select) props.select(props.id, e.target.checked);
            }}
            checked={props.selected || false}
            style={{ margin: 0, transform: "scale(1.3)" }}
            onClick={e => e.stopPropagation()}
          ></input>
        </div>
        <TaskDetails>
          <TaskContact
            title={getRecipientString({ task: props, format: "long" })}
          >
            {getRecipientString({ task: props })}{" "}
            {isExternal(props.reference_email) ? (
              <Tooltip title="Others will receive this followup">
                <span
                  className="glyphicon glyphicon-send"
                  style={{ color: "#4d4d4d" }}
                />
              </Tooltip>
            ) : null}
          </TaskContact>

          <TaskSubject>
            {props.reference_email.subject}{" "}
            {/* <TaskBody>
                  {props.reference_email.text &&
                    props.reference_email.text.slice(0, 150)}
                </TaskBody> */}
          </TaskSubject>
        </TaskDetails>

        <SkillsAppliedToTask task={props} history={props.history} />

        <VCenter style={{ flex: 1 }}>
          {props.trigger_time && (
            <TaskDueDate
              title={
                "Triggers on: " +
                userDate({
                  timestamp: props.trigger_time,
                  timezone: loggedInUser.timezone
                })
              }
            >
              {props.trigger_time &&
                conversationalDate({ timestamp: props.trigger_time })}{" "}
            </TaskDueDate>
          )}
          {taskInErrorState(props) && (
            <span
              className="glyphicon glyphicon-exclamation-sign text-warning"
              style={{ margin: 3 }}
              title="View task for details."
            ></span>
          )}
        </VCenter>
      </div>
    </div>
  );
};
export default withRouter(TaskListItem);
