import React, { useEffect } from "react";
import { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { message as antMessage } from "antd";
import nprogress from "nprogress";
import { logger } from "../lib/Logger";

import Card from "../Card";
import { Container, ItemContent } from "../Layout";
import { MultiSelectInput } from "../MultiSelectInput";
import { mailbotsAdminBrowser } from "../lib/utils";
import { getPersonTagOptions } from "../lib/peopleUtils";

const AddPerson = withRouter(({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [emails, setEmails] = useState([]);
  const [emailsInputValue, setEmailsInputValue] = useState("");
  const [organization, setOrganization] = useState("");
  const [notes, setNotes] = useState("");

  const [allPeopleAttributes, setAllPeopleAttributes] = useState([]);

  useEffect(() => {
    const loadAllPeopleAttributes = async () => {
      try {
        const attrRes = await mailbotsAdminBrowser.getAllPeopleAttributes();
        setAllPeopleAttributes(attrRes.attributes);
      } catch (e) {
        antMessage.error("Error loading all people attributes");
        console.error(e);
      }
    };

    loadAllPeopleAttributes();
    logger.log("add person screen shown");
  }, []); // componentDidMount

  const submitAddPersonForm = async e => {
    try {
      e.preventDefault();
      setIsLoading(true);
      nprogress.start();
      const { person } = await mailbotsAdminBrowser.createPerson({
        person: {
          attributes: [
            {
              attribute: "full_name",
              title: "Name",
              type: "text",
              value: name.trim(),
              display_order: 1,
              hidden: false,
              readonly: false
            },
            {
              attribute: "first_name",
              title: "First Name",
              type: "text",
              value: name.trim().split(" ")[0],
              display_order: 2,
              hidden: false,
              readonly: false
            },
            {
              attribute: "emails",
              title: "Emails",
              type: "list",
              value: emails,
              display_order: 3,
              hidden: false,
              readonly: false
            },
            {
              attribute: "organization",
              title: "Organization",
              type: "text",
              value: organization.trim(),
              display_order: 4,
              hidden: false,
              readonly: false
            },
            {
              attribute: "tags",
              title: "Tags",
              type: "global_multiselect",
              value: tags,
              display_order: 5,
              hidden: false,
              readonly: false
            },
            {
              attribute: "notes",
              title: "Notes",
              type: "text",
              value: notes.trim(),
              display_order: 6,
              hidden: false,
              readonly: false
            }
          ]
        }
      });

      setIsLoading(false);
      nprogress.done();

      // navigate to person edit
      history.push(`/people/${person.id}`);

      logger.log("new person added");
    } catch (error) {
      console.error(error);
      antMessage.error(error.message);

      setIsLoading(false);
      nprogress.done();
    }
  };

  return (
    <Container>
      <a name="top">&nbsp;</a>
      <ItemContent className="task-actions">
        <br />
        <Link
          to="/people"
          className="back-link"
          style={{
            color: "#aaaaaa"
          }}
        >
          <i className="glyphicon glyphicon-chevron-left" />{" "}
          <span className="hide-for-mobile">People</span>
        </Link>
        <br />

        <Card type="large">
          <h2>Add Person</h2>
          <form onSubmit={submitAddPersonForm}>
            {/* name */}
            <div className="form-group">
              <label htmlFor="full_name" className="control-label">
                Name
              </label>
              <input
                name="full_name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                className="form-control"
                id="full_name"
                key="full_name"
              />
            </div>

            {/* tags */}
            <div className="form-group">
              <label htmlFor="tags" className="control-label">
                Tags
              </label>
              <div style={{ border: "1px solid #ddd" }}>
                <MultiSelectInput
                  controlStyle={{
                    paddingTop: 4
                  }}
                  name="tags"
                  options={getPersonTagOptions(allPeopleAttributes)}
                  value={tags.map(tag => ({ label: tag, value: tag }))}
                  onChange={event => setTags(event.map(evt => evt.value))}
                />
              </div>
            </div>

            {/* emails */}
            <div className="form-group">
              <label htmlFor="emails" className="control-label">
                Emails
              </label>
              <div style={{ border: "1px solid #ddd" }}>
                <MultiSelectInput
                  controlStyle={{
                    paddingTop: 4
                  }}
                  value={emails.map(email => ({ label: email, value: email }))}
                  onChange={event => setEmails(event.map(evt => evt.value))}
                  hideAutocomplete={true}
                ></MultiSelectInput>
              </div>
            </div>

            {/* organization */}
            <div className="form-group">
              <label htmlFor="organization" className="control-label">
                Organization
              </label>
              <input
                name="organization"
                type="text"
                value={organization}
                onChange={e => setOrganization(e.target.value)}
                className="form-control"
                id="organization"
                key="organization"
              />
            </div>

            {/* notes */}
            <div className="form-group">
              <label htmlFor="notes" className="control-label">
                Notes
              </label>
              <textarea
                name="notes"
                value={notes}
                onChange={e => setNotes(e.target.value)}
                className="form-control"
                id="notes"
                key="notes"
              />
            </div>
            <br />
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? "Add Person" : "Adding..."}
            </button>
          </form>
        </Card>
      </ItemContent>
    </Container>
  );
});

export default AddPerson;
