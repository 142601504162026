import { MailBotsClient } from "@mailbots/mailbots-sdk";
import cookies from "js-cookie";
import queryString from "query-string";
import urljoin from "url-join";

class MailBotsAdminBrowser extends MailBotsClient {
  /**
   * Create new user using MailBotsAdminNode lib in server/auth.js
   */
  register(data) {
    const requestOptions = {
      method: "POST",
      url: "/auth/register",
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    return this.makeRequest(requestOptions);
  }

  /**
   *  Login using MailBotsAdminNode lib in server/auth.js
   */
  login(data) {
    const requestOptions = {
      method: "POST",
      url: "/auth/login",
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };

    return this.makeRequest(requestOptions);
  }

  /**
   * Invalidates all access tokens for user across all devices
   */
  logout() {
    const requestOptions = {
      method: "POST",
      url: this.config.apiHost + `/api/v1/auth/logout`,
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Login a user using Google OAuth.
   */
  loginWithGoogle(data) {
    const requestOptions = {
      method: "POST",
      url: "/auth/google-auth",
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Login a user using Microsoft Identity Platform v2.
   */
  loginWithMicrosoft(data) {
    const requestOptions = {
      method: "POST",
      url: "/auth/microsoft-auth",
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    return this.makeRequest(requestOptions);
  }

  /**
   *  Get history for task object
   *  @todo move to sdk
   */
  getTaskHistory(taskId) {
    const requestOptions = {
      method: "GET",
      url: this.config.apiHost + `/api/v1/tasks/${taskId}/events`,
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json"
      }
    };

    return this.makeRequest(requestOptions);
  }

  /**
   * Recover password using MailBotsAdminNode lib in server/auth.js
   */
  forgotPassword({ email }) {
    const requestOptions = {
      method: "POST",
      url: this.config.apiHost + "/api/v1/auth/forgot_password",
      headers: {
        "Content-Type": "application/json"
      },
      data: { email }
    };
    return this.makeRequest(requestOptions);
  }

  /**
 * Type references:
 interface Email {
  to: string,
  cc?: string,
  bcc?: string,
  subject: string,
  html: string
}

interface Task {
  id: number,
  command: string,
  trigger_time?: number | null,
  completed?: boolean,
  reference_email?: Email,
  stored_data: {
    [key: string]: any
  },
  search_keys?: string[]
}

interface Message {
  to: string,
  cc?: string[],
  bcc?: string[],
  from?: string,
  subject: string,
  body: string | Array<{type: string, text: string}>
}}
 */

  /**
   * Invite an email (like your own email) to use a MailBot
   * @param {object} params - see Postman Tasks > Preview Task
   * pass params.verbose = 1 to render the HTML preview
   */
  previewTask(params, cancelToken) {
    let urlParams = {};
    if (params.verbose) {
      urlParams.verbose = 1;
    }
    let serializedParams = queryString.stringify(urlParams);
    let qs = serializedParams ? `?${serializedParams}` : "";
    let requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, "/api/v1/tasks/preview", qs),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: params,
      json: true
    };

    if (cancelToken) {
      requestOptions.cancelToken = cancelToken;
    }

    return this.makeRequest(requestOptions);
  }

  /**
   * Invite an email (like your own email) to use a MailBot
   * @param {String} email - email to invite
   * @param {Boolean} fromInviter - Should this be an invitation from the logged in user?
   * @param {function} cb
   */
  invite({ email, mailbotClientId, fromInviter }) {
    const requestBody = {
      client_id: mailbotClientId,
      email_address: email
    };
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, "/api/v1/invites/"),
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: requestBody
    };

    if (fromInviter) {
      if (!this._accessToken) {
        throw new Error("You must be logged in to perform this ation");
      }
      Object.assign(requestOptions.headers, {
        Authorization: `Bearer ${this._accessToken}`
      });
    }
    return this.makeRequest(requestOptions);
  }

  /**
   * Initiate OAuth2 Flow via MailBots API endpoint
   */
  authorizeMailBot(data = {}) {
    if (!data.redirect_uri || !data.scope || !data.client_id)
      throw new Error("Inadequate data provided to authorize");
    data.response_type = data.response_type || "code";
    data.state = data.state || "4a9fjdsa"; //TODO: Require this
    let qs = queryString.stringify(data);
    const requestOptions = {
      method: "POST",
      url: this.config.apiHost + "/api/v1/oauth2/authorize?" + qs,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("mailbotsToken")
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Get the OAuth login URI using MailBotsAdminNode (when not doing implicit OAuth flow)
   */
  getLoginUri() {
    return this.makeRequest({
      method: "GET",
      url: "/auth/get-login-uri",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  /**
   * Resend account validation email
   */
  resendValidation({ email }) {
    return this.makeRequest({
      method: "POST",
      url: "/auth/resend-validation",
      headers: {
        "Content-Type": "application/json"
      },
      data: { email }
    });
  }

  /**
   * Retrieve mailbotsToken using auth code using MailBotsAdminNode server/router.js
   */
  getAccessToken(code) {
    return this.makeRequest({
      method: "GET",
      url: "/auth/get-token?code=" + code,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  /**
   * TODO: From here down, methods are copied / pasted from mailbotsAdminNode.
   * Ideally there should be a new lib for admin functions, organized identically
   * to mailbotshq-js / mailbots-sdk-js, that extends it to provide browser + node features. This is
   * going to have to work for now.
   */
  /**
   * User update his/her own settings
   * @param {Object} data.user  – {name, password, timezone, preferred_date_format, preferred_date_format_js, postpone_times}
   * @return {Object} Standard user object
   */
  userUpdateSelf(data) {
    const requestOptions = {
      method: "PUT",
      url: urljoin(this.config.apiHost + "/api/v1/users/self"),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: data
    };
    return this.makeRequest(requestOptions);
  }

  enableGopherLogs() {
    return this.userUpdateSelf({
      user: {
        enable_gopherlog: true
      }
    });
  }

  disableGopherLogs() {
    return this.userUpdateSelf({
      user: {
        enable_gopherlog: false
      }
    });
  }

  /**
   * Link a new email.
   * @param {string} data.email An email address.
   * @return {Object} Standard user object
   * @deprecated
   */
  userUnlinkEmail({ email }) {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(
        this.config.apiHost + "/api/v1/users/self/email-addresses/" + email
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Link an existing email.
   * @param {string} data.email An email address.
   * @return {Object} Standard user object
   * @deprecated
   */
  userLinkEmail({ email }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost + "/api/v1/users/self/email-addresses"),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: { email }
    };
    console.log(requestOptions);

    return this.makeRequest(requestOptions);
  }

  /**
   * @param {object} params
   * @param {string} params.email - email address
   * @param {object} params.data - updated email data
   * @param {string} params.data.sender_validation? - "none" | "warn" | "fail"
   * @param {number} params.data.primary? – make this the primary email 1 or 0
   */
  updateEmail({ email, data }) {
    const requestOptions = {
      method: "PUT",
      url: urljoin(
        this.config.apiHost + "/api/v1/users/self/email-addresses/" + email
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * When a user links a new email to their account they receive a validation email which (quite frequently)
   * get lots and needs to be reresent.
   * @param {object} params
   * @param {string} params.email - email address
   */
  resendLinkValidationEmail({ email }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost +
          "/api/v1/users/self/email-addresses/" +
          email +
          "/resend_validation_email"
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Get all user email addresses
   * @return {Object} - respnse format below
   * @example {
    "status": "success",
    "user": {
        "id": 2
    },
    "email_addresses": [
        {
            "email": "reilly@isimple.net",
            "sender_validation": "warn",
            "is_validated": true,
            "status": "verified",
            "primary": false
        }]
    }
   */
  getEmailAddresses() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost + "/api/v1/users/self/email-addresses"),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /*
   * Create a MailBot (Admin Only)
   */
  createMailBot(params, cb) {
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost + "/api/v1/mailbots"),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Update a MailBot (Admin Only)
   */
  updateMailBot(params, cb) {
    if (!params.mailbot.mailbotid) {
      throw Error("mailbotid` is required to update a MailBot");
    }

    const requestOptions = {
      method: "PUT",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbot.mailbotid}`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Get a list of MailBots (Admin only)
   */
  getMailBots(params, cb) {
    const qs = params ? queryString.stringify(params) : "";
    const requestOptions = {
      method: "GET",
      url: urljoin(`${this.config.apiHost}/api/v1/mailbots?${qs}`),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Get a single mailbot (Admin only)
   */
  getMailBot(params, cb) {
    //TODO: Better error handling
    if (!params.mailbotid) {
      throw Error("mailbotid is required to fetch a MailBot ");
    }
    const requestOptions = {
      method: "GET",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /**
   * Get users of a MailBot . Available to mailbot owner only
   * @param {object} params
   * @param {function} cb
   * @return {Promise}
   */
  getMailBotUsers(params, cb) {
    if (!params.mailbotid) {
      throw Error("mailbotid is required to get users");
    }
    const requestOptions = {
      method: "GET",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}/users`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Get MailBot  Settings
   * Similar to getMailBotData, but it fires a webhook to the mailbot with
   * the private data, which then responds with JSON to render settings form.
   * @param {object} params
   * @param {number} params.mailbotid
   * @param {object} params.urlParams
   */
  getMailBotSettings(params, cb) {
    //TODO: Better error handling
    if (!params.mailbotid) {
      throw Error("mailbotid is required to fetch a MailBot ");
    }
    const qs = params.urlParams
      ? "?" + queryString.stringify(params.urlParams)
      : "";
    delete params.urlParams;
    const requestOptions = {
      method: "GET",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}/settings${qs}`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Save MailBot  Settings
   * Same as saveBotData, but it fires a webhook to the mailbot for
   * validation / modification.
   */
  saveMailBotSettings(params, cb) {
    if (!params.mailbotid) {
      throw Error("mailbotid is required to fetch a MailBot ");
    }
    const mailbotid = params.mailbotid;
    delete params.mailbotid;
    const qs = params.urlParams
      ? "?" + queryString.stringify(params.urlParams)
      : "";
    delete params.urlParams;
    const requestOptions = {
      method: "POST",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${mailbotid}/settings${qs}`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Uninstall a MailBot  (Admin only)
   */
  uninstallMailBot(params, cb) {
    if (!params.mailbotid) {
      throw Error("mailbotid is required to uninstall");
    }

    const requestOptions = {
      method: "DELETE",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}/uninstall/`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Install a MailBot  (Admin only)
   */
  installMailBot(params, cb) {
    if (!params.mailbotid) {
      throw Error("mailbotid is required to install");
    }

    const requestOptions = {
      method: "PUT",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}/install/`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  /*
   * Delete a MailBot
   */
  deleteMailBot(params, cb) {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(
        `${this.config.apiHost}/api/v1/mailbots/${params.mailbotid}`
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      },
      data: params
    };
    return this.makeRequest(requestOptions, cb);
  }

  getDeveloperLogs(filter, cb) {
    filter = filter || { type: ["webhook", "submit_failed"] };
    const qs = queryString.stringify(filter, { arrayFormat: "bracket" });
    if (!filter.mailbotid)
      throw new Error(
        "Fetching developer logs requires specifying an mailbotid"
      );

    console.log("qs", qs);

    const requestOptions = {
      method: "GET",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/mailbots/${filter.mailbotid}/logs/?` + qs
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };

    return this.makeRequest(requestOptions, cb);
  }

  /**
   * Retrieve logged-in user's bot logs
   * @param  {object} filter - Filter. Ex: `{type: ['api', 'submit_failed'], mailbot: ['subdomain'], since: 1517948366, num: 10}`)
   * @return {Promise} Promise resolving to log results in the form of: `{status: "success", logs[...]}`
   */
  getLogs(filter, cb) {
    filter = filter || { type: ["webhook", "submit_failed"] };
    const qs = queryString.stringify(filter, { arrayFormat: "bracket" });

    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, "/api/v1/logs?" + qs),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };

    return this.makeRequest(requestOptions, cb);
  }

  /**
   * Retrieve logged-in user's delivery logs
   * @param  {object} filter - {delivery_status: "bounced" | "complained" | "deferred" | "sent"}
   * @return {Promise} Promise resolving to log results in the form of: `{status: "success", logs[...]}`
   */
  getDeliveryLogs(filter = {}, cb) {
    const qs = queryString.stringify(filter, { arrayFormat: "bracket" });

    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, "/api/v1/delivery_logs?" + qs),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };

    return this.makeRequest(requestOptions, cb);
  }

  /**
   * Invite users to this bot. If an Auth token is included, the invitation email
   * includes the name of the logged in user who is sending the invitation.
   * "emails" param can be either an array of email addresses, or a string with a single
   * email address.
   * @param {array|string} emails A single email address, or an array of emails to invite
   * @param {function} [cb] Optional callback (also can be used with promises)
   */
  invite(emails, cb) {
    const requestBody = {
      client_id: this.config.clientId,
      email_address: emails
    };
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, "/api/v1/invites/"),
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: requestBody
    };

    if (this._accessToken) {
      Object.assign(requestOptions.headers, {
        Authorization: `Bearer ${this._accessToken}`
      });
    }
    return this.makeRequest(requestOptions, cb);
  }

  /**
   * Get all people (contacts)
   * @param {object} params - see Postman Tasks > Preview Task
   */
  getPeople(params) {
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, "/api/v1/people/search"),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      json: true,
      data: params
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Get all people (contacts)
   * @param {number} id - see Postman Tasks > Preview Task
   */
  getPerson(id) {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, "/api/v1/people/", String(id)),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      json: true
    };
    return this.makeRequest(requestOptions);
  }

  getAllPeopleAttributes() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, "/api/v1/people/attributes/"),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      json: true
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Create activity event history for a person
   */
  getPersonEvents(personid) {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/people/${personid}/events`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Create activity event history for a person
   */
  deletePerson(personid) {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(this.config.apiHost, `/api/v1/people/${personid}`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Start Stripe Checkout Session
   */
  getStripeCheckoutSession({ stripePlanIds = [], successUrl, cancelUrl }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/billing/stripe_checkout_session`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        plans: stripePlanIds,
        success_url: successUrl,
        cancel_url: cancelUrl
      }
    };
    return this.makeRequest(requestOptions);
  }

  getStripeSetupCardSession({ successUrl, cancelUrl }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/billing/stripe_setup_card_session`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        success_url: successUrl,
        cancel_url: cancelUrl
      }
    };
    return this.makeRequest(requestOptions);
  }

  getBillingInfo() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getPaymentMethod() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/payment_method/`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  deletePaymentMethod({ paymentMethodId }) {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/billing/self/payment_methods/${paymentMethodId}`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getInvoices() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/invoices/`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getUpcomingInvoices() {
    const requestOptions = {
      method: "GET",
      url: urljoin(
        this.config.apiHost,
        `api/v1/billing/self/invoices/upcoming/`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getAllPlans() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/billing_plans/`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  changeBasePlan({ stripeId }) {
    const requestOptions = {
      method: "PUT",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/base_plan`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        plan: stripeId
      }
    };
    return this.makeRequest(requestOptions);
  }

  changeBillingInterval({ interval }) {
    const requestOptions = {
      method: "PUT",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/interval`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        interval
      }
    };
    return this.makeRequest(requestOptions);
  }

  // core-api removes all add-ons for their and any team-member accounts.
  // @todo - have them contact support to do this.
  downgradeAllBilling() {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(this.config.apiHost, `/api/v1/billing/self/base_plan`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   *
   * @param {*} param0
   */
  addSubscriptionAddons({ userId, plans }) {
    if (!Array.isArray(plans)) Error("plans must be an array");
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/billing/self/subscription_addons`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        addons: [{ userid: userId, plans }]
      }
    };
    return this.makeRequest(requestOptions);
  }

  removeSubscriptionAddons({ userId, plans }) {
    if (!Array.isArray(plans)) Error("plans must be an array");
    const requestOptions = {
      method: "DELETE",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/billing/self/subscription_addons`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: {
        addons: [{ userid: userId, plans }]
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Teams
   */
  setTeamSettings() {
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getTeamMembersAndSettings() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  addTeamMember({ email, budgetCent }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/members`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: { email, budget_cent: budgetCent }
    };
    return this.makeRequest(requestOptions);
  }

  async addTeamMembersByCsv({ teamMemberEmailsCsv, budgetCent = 0 }) {
    const emails = teamMemberEmailsCsv.split(",");
    let ctEmails = 0;
    let errorMessages = [];
    await Promise.all(
      emails
        .map(e => e.trim())
        .map(email => {
          return this.addTeamMember({ email, budgetCent })
            .then(() => ++ctEmails)
            .catch(e => {
              console.error(e);
              errorMessages.push(
                `Error adding ${email} to your team: ` +
                  e.message +
                  `\n Contact help@human.fut.io if you need help.`
              );
            });
        })
    );
    if (errorMessages.length > 0) throw Error(errorMessages.join("\n "));
    return ctEmails;
  }

  updateTeamMember({ email, budgetCent }) {
    const requestOptions = {
      method: "PUT",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/members/${email}`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data: { budget_cent: budgetCent }
    };
    return this.makeRequest(requestOptions);
  }

  resendTeamInvite({ email }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/teams/self/members/${email}/invitation`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  deleteTeamMember({ email }) {
    const requestOptions = {
      method: "DELETE",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/members/${email}`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getTeamStats() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/stats`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  // send event to the mailbot to whom the current auth token belongs
  broadcastMailBotEvent({ data }) {
    const requestOptions = {
      method: "POST",
      url: urljoin(
        this.config.apiHost,
        `/api/v1/mailbots/self/broadcast_event`
      ),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      },
      data
    };
    return this.makeRequest(requestOptions);
  }

  createTeamSignupHash() {
    const requestOptions = {
      method: "PUT",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/signup_hash`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  getTeamSignupHash() {
    const requestOptions = {
      method: "GET",
      url: urljoin(this.config.apiHost, `/api/v1/teams/self/signup_hash`),
      headers: {
        Authorization: "Bearer " + cookies.get("mailbotsToken"),
        "Content-Type": "application/json; charset=UTF-8"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * User migrate self to v3. 
   * Call recursively until task_count for v2 goes to zero.
   * @return {
    "status": "success",
    "migration": {
        "task_count": {
            "v2": 0,
            "v3": "4"
        }
    }
  }
   */
  migrateBatchToV3() {
    const requestOptions = {
      method: "PUT",
      url: urljoin(
        this.config.apiHost + "/api/v1/users/self/migrate_tasks_to_v3"
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  // same as migrateToV3
  migrateBatchToV2() {
    const requestOptions = {
      method: "PUT",
      url: urljoin(
        this.config.apiHost + "/api/v1/users/self/migrate_tasks_to_v2"
      ),
      headers: {
        Authorization: `Bearer ${this._accessToken}`,
        "Content-Type": "application/json"
      }
    };
    return this.makeRequest(requestOptions);
  }

  /**
   * Recursively call the migration fn, calling the status update fn along the way
   * @param {*} statusUpdateCb
   */
  async userMigrateToVersion({ statusUpdateCb, doneCb, target }) {
    const batchMigrationFn =
      target === "v3"
        ? this.migrateBatchToV3.bind(this)
        : this.migrateBatchToV2.bind(this);
    const { migration, status } = await batchMigrationFn();
    const { task_count = {} } = migration;
    let numRemaining = target === "v3" ? task_count.v2 : task_count.v3;

    // recurse if there are tasks remaining
    if (parseInt(numRemaining) > 0) {
      if (typeof statusUpdateCb === "function") statusUpdateCb(task_count);
      console.log("remaining FUTs to migrate: ", task_count);
      await this.userMigrateToVersion({ statusUpdateCb, doneCb, target }); // avoid parallel requests
    } else {
      if (typeof doneCb === "function") doneCb(migration);
    }
  }

  // migrate user to FUT V3
  userMigrateToV3({ statusUpdateCb, doneCb }) {
    return this.userMigrateToVersion({
      statusUpdateCb,
      doneCb,
      target: "v3"
    });
  }

  // migrate user to FUT V2
  userMigrateToV2({ statusUpdateCb, doneCb }) {
    return this.userMigrateToVersion({
      statusUpdateCb,
      doneCb,
      target: "v2"
    });
  }

  // Get and cache all user-facing skills. Excludes system skills like fut-news and agenda
  availableSkills;
  async getAvailableSkills() {
    if (this.availableSkills) return Promise.resolve(this.availableSkills);
    return this.getMailBots().then(res => {
      // dash flag is in title ex: SMS (-sms)
      const dashSkills = res.mailbots.filter(sk => sk.name.includes("(-"));
      // other skills we need to show the user in the TaskList
      const otherSkills = res.mailbots.filter(sk => {
        // @todo migrate to skill attributes after lerna
        const viewableSystemSkills = ["recurring", "list", "cc", "mem"];
        if (viewableSystemSkills.includes(sk.subdomain)) return true;
      });
      this.availableSkills = [...new Set([...dashSkills, ...otherSkills])]; // defensively de-duplicate
      return this.availableSkills;
    });
  }
}

export default MailBotsAdminBrowser;
