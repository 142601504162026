import React, { Component } from "react";
import nprogress from "nprogress";
import {
  axiosCache,
  isCoreSkill,
  mailbotsAdminBrowser as mailbotsClient
} from "./lib/utils";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import urlJoin from "url-join";
import faker from "faker/locale/en";
import Dialog from "./Dialog";
import MailBotUsageStats from "./MailBotUsageStats";
import MailBotUsageUsers from "./MailBotUsageUsers";
import MailBotUsageLogs from "./MailBotUsageLogs";
import MailBotDevInfoPanel from "./MailBotDevInfoPanel";
import GlitchHelper from "./lib/GlitchHelper";
import DocsLink from "./DocsLink";
import { message as antMessage } from "antd";
import Modal from "./Modal";
import Card from "./Card";
import { ExpandableStateful } from "./Expandable";

class YouTubeVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    console.log("should youtube video update?");
    return false;
  }
  render = () => (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        width="560"
        height="315"
        // src="https://www.youtube.com/embed/wylh5VI_kx8?showinfo=0" // mailbots
        src="https://www.youtube.com/embed/h7kg_wh_UDg?showinfo=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

const ListenAuthorizeButton = ({ listeningForAutoInit, form }) => (
  <div style={{ padding: 20 }}>
    {/* 
  Glitch is setting up right now...
TODO: Switch messaging based on state: 
isListening, glitchSuccess, popupFail, glitchTimeout 
*/}
    {listeningForAutoInit ? (
      <span>
        <span
          className="loader"
          style={{ display: "inline-block", marginBottom: -5 }}
        >
          {" "}
        </span>{" "}
        Glitch is setting up....
      </span>
    ) : (
      <a
        className="btn btn-primary"
        target="_blank"
        href={form.install_url}
        rel="noopener noreferrer"
      >
        Next: Authorize Your Skill
      </a>
    )}
  </div>
);

// Create Glitch mailbot in popuup at the same time
// Move button listener to MailBotModalState
// Implement mailbot modal state
const SettingUpMailBotModal = ({
  visible,
  onClose,
  listeningForAutoInit,
  form,
  autoInitFailed = false
}) => (
  <Modal visible={visible} onClose={onClose}>
    <Card
      type="medium"
      style={{
        padding: 30,
        width: "80vw",
        maxWidth: 850,
        textAlign: "center"
      }}
    >
      <p>
        Watch a video while we're setting things up. Also available in our{" "}
        <a
          href="https://fut.readme.io/reference/skill-development-tools"
          target="_blank"
          rel="noopener noreferrer"
        >
          docs
        </a>
        .
      </p>
      <YouTubeVideo />
      {!autoInitFailed ? (
        <ListenAuthorizeButton
          listeningForAutoInit={listeningForAutoInit}
          form={form}
        />
      ) : (
        <GlitchAutoInitFailed />
      )}
    </Card>
  </Modal>
);

const VideoOnlyModal = ({ visible, onClose }) => (
  <Modal visible={visible} onClose={onClose}>
    <Card
      type="medium"
      style={{
        padding: 30,
        width: "80vw",
        maxWidth: 1200,
        textAlign: "center"
      }}
    >
      <YouTubeVideo />
    </Card>
  </Modal>
);

const GlitchAutoInitFailed = () => (
  <span
    style={{
      display: "inline-block",
      marginBottom: -5,
      padding: 20,
      margin: 20,
      border: "1px solid #efefef"
    }}
  >
    ⚠️ Sorry, auto-setup magic didn't work quite as expected. Let's try another
    way: Go to
    <a href="https://glitch.com/edit/#!/fut-skill-hi5">this Glitch project</a>,
    click "Remix", then come back register it as a new skill.
  </span>
);

class MailBotDetailsDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      isLoading: true,
      listeningForAutoInit: null,
      autoInitFailed: false,
      intent: "edit",
      showExtensionSetupModal: false,
      isSettingUpLocally: false,
      showVideoOnlyModal: false,
      botFromThisTemplate: null,
      showMailBotSetupModal: false
    };
    this.glitch = null; // Instance of GlitchHelper
  }
  componentDidCatch() {
    this.setState({
      dialogText:
        "Developer portal could not load. Have you confirmed your email address?"
    });
  }

  componentDidMount(props) {
    // we are passed a MailBot  id, so we're editing
    window.scrollTo(0, 0);
    if (this.props.id) {
      nprogress.start();
      mailbotsClient
        .getMailBot({ mailbotid: this.props.id })
        .then(response => {
          if (!response.mailbot.hasOwnProperty("oauth2_client_secret")) {
            console.log("unable to edit mailbot not owned by this user");
            this.setState({
              dialogText: `Sorry, you do not have permission to edit this skill`,
              dialogType: `alert-warning`
            });
          } else {
            this.glitch = new GlitchHelper(response.mailbot);
            this.setState({
              form: response.mailbot,
              isLoading: false,
              intent: "edit"
            });
          }
        })
        .catch(err => {
          console.log("error", err);
          this.setState({
            dialogText: `Sorry, there was an error fetching this skill (id: ${this.props.id})`,
            dialogType: `alert-warning`
          });
        })
        .finally(() => {
          nprogress.done();
        });

      // no id means we're creating a new form
    } else {
      this.setState({
        intent: "create",
        isLoading: false
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.hasOwnProperty("id")) {
      this.setState({
        form: {},
        intent: "create"
      });
    }
  }

  handleOnChange = event => {
    let type = event.target.type;
    let mailbot = this.state.form;

    switch (type) {
      case "checkbox":
        mailbot[event.target.name] = event.target.checked;
        break;
      default:
        mailbot[event.target.name] = event.target.value;
    }

    //auto-fill callback url if base_url contains glitch
    if (event.target.name === "base_url") {
      const cleanBaseUrl = event.target.value.trim();
      //prettier-ignore
      mailbot["oauth2_redirect_uri"] = urlJoin(cleanBaseUrl,"/auth/callback");
      mailbot["base_url"] = cleanBaseUrl;
      mailbot["webhook_url"] = urlJoin(cleanBaseUrl, "webhooks");
      mailbot["install_url"] = urlJoin(cleanBaseUrl, "auth/login");
    }

    this.setState({
      form: mailbot
    });
  };

  // Convert a normal text block into a subdomain
  subdomainify = str => str.toLowerCase().replace(/\s+/g, "-");

  errorCreatingMailBot = err => {
    console.log("error creating mailbot", err);
    window.scrollTo(0, 0);
    antMessage.error(
      "Error: " +
        (err.message ||
          "Sorry, something went wrong while creating your mailbot. Having trouble? Contact us: help@humans.fut.io")
    );
    this.setState({
      isLoading: false,
      form: this.state.form
    });
  };

  /***********************************
   * Section: The new AutoMagical creation of a new MailBot AND Glitch in one shot.
   * - Automatically generate MailBot based on mailbotTemplates,
   * - Clone Glitch project from appropriate URL, passing env from new MailBot
   * - Wait for auto-init for that project
   * - Alert the user to authorize the MailBot
   **********************************/

  handleCreateBotMailBotAndGlitchFromTemplate = async (e, mailbotTemplate) => {
    e.preventDefault();
    const randomMailBot = this.getRandomMailBotFromTemplate(mailbotTemplate);
    const res = await this.createMailBot(randomMailBot);
    this.setState(
      {
        intent: "edit",
        form: res.mailbot,
        showMailBotSetupModal: true
      },
      () => {
        this.initGlitchProject(null, randomMailBot.glitch_remix_url);
      }
    );
  };

  /**
   * This also creates a random mailbot from a template but does not init
   * Glitch. Instead it takes the person to the "edit" screen.
   */
  handleCreateBotLocallyFromTemplate = async (e, mailbotTemplate = "blank") => {
    try {
      const randomMailBot = this.getRandomMailBotFromTemplate(mailbotTemplate);
      nprogress.start();
      const res = await this.createMailBot(randomMailBot);
      nprogress.done();
      // TODO: React router not routing..
      window.location.href = "/developer/skills/" + res.mailbot.id;
    } catch (e) {
      nprogress.done();
      antMessage.error(e.message);
    }
  };

  getRandomMailBotFromTemplate(templateKey) {
    // generate memorzble random name
    function getRandomName() {
      //prettier-ignore
      const randomName = `${capitalizeFirstLetter(faker.commerce.productAdjective())} ${capitalizeFirstLetter(faker.random.word())}`;
      const randomSubdomain =
        faker.commerce.productAdjective().toLowerCase() +
        Math.random().toString().slice(2, 4);
      return { randomName, randomSubdomain };
    }
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const mailbotTemplate = this.mailbotTemplates[templateKey];
    const { randomName, randomSubdomain } = getRandomName();
    const randomMailBot = {};
    randomMailBot.glitch_remix_url = mailbotTemplate.glitch_remix_url;
    randomMailBot.name = `${randomName} (${mailbotTemplate.name})`;
    randomMailBot.subdomain = randomSubdomain;
    randomMailBot.description = mailbotTemplate.description;
    randomMailBot.devmode = true;

    //  TODO Better random icons!
    // prettier-ignore
    randomMailBot.icon = `https://fut-cdn.s3.amazonaws.com/mailbots/random-mailbot-icons/${Math.ceil(Math.random() * 7)}.png`;
    randomMailBot.autocomplete_examples = mailbotTemplate.autocomplete_examples;
    randomMailBot.oauth2_scope =
      randomMailBot.oauth2_scope || this.OAUTH_SCOPES_DEFAULTS;
    return randomMailBot;
  }

  /**
   * Example MailBot Starter Templates
   */
  mailbotTemplates = {
    blank: {
      name: "Blank",
      description: `Hello World! See https://fut.readme.io/ to get started`,
      autocomplete_examples: [],
      glitch_remix_url: "https://glitch.com/edit/#!/remix/example-blank-mailbot"
    },
    hi5: {
      name: "High Five",
      description: `A simple FollowUpThen Skill example: High fives, when when you need them. 🤚`,
      autocomplete_examples: [],
      glitch_remix_url: "https://glitch.com/edit/#!/remix/fut-skill-hi5"
    },
    todo: {
      name: "ToDo",
      description:
        "Create, edit and retrieve todo items right from your email.",
      glitch_remix_url: "https://glitch.com/edit/#!/remix/example-todo-mailbot",
      autocomplete_examples: ["todo", "my-todos"]
    },
    news: {
      name: "News",
      description:
        "Createa an interactive newsletter populated by the Product Hunt (or any) API",
      glitch_remix_url: "https://glitch.com/edit/#!/remix/example-news-mailbot",
      autocomplete_examples: ["producthunt"]
    },
    ticket: {
      name: "Tickets",
      description: `Manage tickets and issues without leaving your inbox.`,
      glitch_remix_url:
        "https://glitch.com/edit/#!/remix/example-ticket-mailbot",
      autocomplete_examples: ["github"]
    }
  };

  /**
   * Called from "submit" when intention is to "create" and "autoGenerateMailBotFromTemplate"
   * when a template is selected
   */
  createMailBotWithGlitch = async e => {
    if (typeof e === "function") e.preventDefault();
    try {
      const res = await this.createMailBot();
      this.setState(
        {
          intent: "edit",
          form: res.mailbot,
          showMailBotSetupModal: true
        },
        () => {
          this.initGlitchProject();
        }
      );
      nprogress.done();
    } catch (e) {
      nprogress.done();
      this.errorCreatingMailBot(e);
      this.setState({
        showMailBotSetupModal: true,
        isSettingUpLocally: true
      });
    }
  };

  handleCloseVideoOnlyModal = () =>
    this.setState({ showVideoOnlyModal: false });

  handleShowVideoOnlyModal = () => this.setState({ showVideoOnlyModal: true });

  /**
   * To initiate a Glitch project, Glitch needs an open project URL in the browser.
   * (not just a GET request). To get this to happen the background we are dynamically
   * setting the src of an iframe on the page (#glitch-setup-form). This is invisible
   * to the user. Once the Glitch project initiates, listenForGlitchAutoInit (below) takes
   * care of the rest.
   */
  initGlitchProject = (e, glitchBaseUrl) => {
    if (typeof e === "function") e.preventDefault();
    this.glitch = new GlitchHelper(this.state.form);
    const glitchRemixUrl = this.glitch.getRemixUrl(glitchBaseUrl);
    const glitchIframe = document.querySelector("#glitch-setup-iframe");
    glitchIframe.src = glitchRemixUrl;
    this.listenForGlitchAutoInit();
  };

  /**
   * This methods currently depends on the this.state.showMailBotSetupModal
   * to be showing for the status spinner to be showing. Once complete,
   * the user clicks to move to the next step.
   *
   * The MailBots lib fires a callabck to the Core API when
   * set up is complete,  populating its own redirect_uri, url, etc.
   * Once these values are present, we consider the project set up.
   */
  listenForGlitchAutoInit = e => {
    let waitingFor = null;
    this.setState({
      listeningForAutoInit: true,
      dialogText: null
    });
    this.refreshIntervalId = window.setInterval(async () => {
      // MailBot urls are cached. See utils.js for details.
      axiosCache.store.clear();
      const res = await mailbotsClient.getMailBot({
        mailbotid: this.props.id || this.state.form.id
      });
      const mailbot = res.mailbot;
      console.log("refreshing", mailbot);
      if (
        mailbot.oauth2_redirect_uri &&
        mailbot.webhook_url &&
        mailbot.install_url &&
        mailbot.base_url
      ) {
        console.log("MailBot URLs were populated");
        this.setState({
          form: mailbot,
          listeningForAutoInit: false
        });
        antMessage.success("Skill auto-setup complete!");

        window.clearInterval(this.refreshIntervalId);
        // Alternatively, listening until install is detected
        // if (mailbot.installed) {
        //   console.log("MailBot  install detected");
        //   window.clearInterval(this.refreshIntervalId);
        // }
      }
      waitingFor += 3000;
      if (waitingFor > 1000 * 60 * 3) {
        this.setState({
          dialogText: `Our automagic setup ran into trouble. Contact help@humans.fut.io for help.`,
          dialogType: `alert-warning`,
          listeningForAutoInit: true,
          autoInitFailed: true
        });
      }
    }, 5000);
  };

  /**
   * Creates MailBot from form (not template).
   * Only used when the user creates a MailBot from the old "Create Mailbot"
   * form. Otherwise the MailBot is automatically generated from the template.
   */
  createMailBotFromForm = async e => {
    e.preventDefault();
    nprogress.start();
    let res;
    try {
      res = await this.createMailBot();
      antMessage.success("Skill created");
      nprogress.done();
      // @todo - history.push broken. This page needs refactoring
      // this.props.history.push(`/developer/skills/${res.mailbot.id}/edit`);
      window.location.href = `/developer/skills/${res.mailbot.id}/edit`;
      window.scrollTo(0, 0);
    } catch (e) {
      nprogress.done();
      this.errorCreatingMailBot(e);
    }
  };

  /**
   * Both methods of creating a MailBot (above) delegate to this lower-level method
   */
  OAUTH_SCOPES_DEFAULTS =
    "get_user_info mailbot_manage_self read_own_tasks manage_own_tasks";

  createMailBot = mailbot => {
    if (mailbot) {
      return mailbotsClient.createMailBot({ mailbot });
    }
    this.state.form.devmode = true;
    this.state.form.oauth2_scope = this.OAUTH_SCOPES_DEFAULTS;

    return mailbotsClient.createMailBot({
      mailbot: this.state.form
    });
  };

  closeSettingUpMailBotModal = () => {
    this.setState({
      showMailBotSetupModal: false
    });
  };

  /************************************
   * End MailBot Creation Section
   ************************************/

  handleUpdateMailBot = async event => {
    event.preventDefault();
    nprogress.start();
    try {
      this.state.form.mailbotid = this.state.form.id;
      this.state.form.oauth2_scope = this.state.form.oauth2_scope.trim();
      this.state.form.autocomplete_examples =
        this.state.form.autocomplete_examples.replace(/\s/g, "");
      let res = await mailbotsClient.updateMailBot({
        mailbot: this.state.form
      });
      if (res instanceof Error) throw res;

      window.scrollTo(0, 0);
      antMessage.success("Skill saved");
      this.setState({
        isLoading: false,
        form: res.mailbot
      });
      nprogress.done();
      this.props.history.push(`/developer/skills/${this.state.form.id}`);
    } catch (err) {
      nprogress.done();
      antMessage.error(
        err.message ||
          "Something went wrong. If this keeps happening do you mind letting us know? help@humans.fut.io?"
      );
    }
  };

  componentWillUnmount = () => {
    if (this.refreshIntervalId) {
      window.clearInterval(this.refreshIntervalId);
    }
  };

  /**
   * Reset mailbot so we can start over
   */
  unlinkMailBotApp = async e => {
    e.preventDefault();
    nprogress.start();
    let res = await this.uninstallMailBot();
    if (res instanceof Error) throw res;
    // set empty values
    let res2 = await mailbotsClient.updateMailBot({
      mailbot: {
        name: this.state.form.name,
        mailbotid: this.state.form.id,
        base_url: "",
        install_url: "",
        webhook_url: "",
        oauth2_redirect_uri: ""
      }
    });
    if (res2 instanceof Error) throw res2;
    this.setState({ form: res.mailbot });
    nprogress.done();
    antMessage.success("Your mailbot has been reset.");
  };

  uninstallMailBot = async () => {
    try {
      nprogress.start();
      const res = await mailbotsClient.uninstallMailBot({
        mailbotid: this.state.form.id
      });
      this.setState(state => {
        state.form.installed = false;
        return state;
      });
      nprogress.done();
      return res;
    } catch (err) {
      nprogress.done();
      antMessage.error(err.message || JSON.stringify(err));
      this.setState({ isLoading: false });
    }
  };

  deleteMailBot = async e => {
    e.preventDefault();
    try {
      if (!window.confirm("Are you sure?")) return;
      nprogress.start();
      const res = await mailbotsClient.deleteMailBot({
        mailbotid: this.state.form.id
      });
      antMessage.success("MailBot successfully deleted");
      this.props.history.push("/developer");
      nprogress.done();
      return res;
    } catch (err) {
      nprogress.done();
      antMessage.error(err.message || JSON.stringify(err));
      this.setState({ isLoading: false });
    }
  };

  getScopes = function () {
    const scope_names = {
      get_user_info: "Get user info",
      mailbot_manage_self: "Store own data",
      manage_tasks: "Read and write tasks",
      read_tasks: "Read tasks"
    };

    const scope_descriptions = {
      get_user_info: "Read user information sch as email address and timezone.",
      mailbot_manage_self: "Set and retrieve its own data such as settings.",
      manage_tasks: "Read, edit or delete any MailBots task on user account. ",
      read_tasks: "Read all MailBots tasks on user account."
    };

    let jsx = [];
    for (let scope in scope_names) {
      jsx.push(
        <div className="checkbox" key={scope}>
          <label>
            <input
              type="checkbox"
              onChange={this.onFormFieldChange}
              value={this.state.form.scopes[scope] || ""}
              name={`scopes[${scope}`}
              id={scope}
            />
            <strong>{scope_names[scope]}:</strong>
            {scope_descriptions[scope]}
          </label>
        </div>
      );
    }
    return jsx;
  };

  // Build sample env file for local installs
  getSampleEnvFile() {
    let extraEnv = "";
    switch (process.env.REACT_APP_NODE_ENV) {
      case "dev":
        extraEnv = `
API_HOST=https://fut-reilly-22.mailbots.com/
MAILBOTS_ADMIN=http://localhost:3000/
DEBUG=mailbots:*`;
        break;
      case "staging":
        extraEnv = `
API_HOST=http://staging1.www.fut.io/
MAILBOTS_ADMIN=http://localhost:3000/`;
        break;
      default:
        // nothing
        break;
    }

    let env = `CLIENT_ID=${this.state.form.oauth2_client_id}
CLIENT_SECRET=${this.state.form.oauth2_client_secret}
SCOPE="${this.state.form.oauth2_scope}"
MAILBOT_ID=${this.state.form.id}
MAIL_DOMAIN=${this.state.form.subdomain}.eml.bot
REDIRECT_URI=${
      this.state.form.oauth2_redirect_uri ||
      "(Set up MailBot URL set up here and on app.mailbots.com)"
    }`;
    return env + extraEnv;
  }

  render() {
    const CreateMailBotPanel = () => {
      const glitch = new GlitchHelper(this.state.form);

      const editInGlitchLink = () =>
        glitch.isGlitch() && (
          <a
            className="btn btn-secondary"
            target="_blank"
            href={glitch.getEditUrl()}
          >
            {" "}
            <span className="glyphicon glyphicon-wrench" /> Edit In Glitch
          </a>
        );

      return (
        <div className="main-panel" key="extPanel">
          <div className="row">
            <div className="col-sm-12">
              {!this.state.form
                .base_url /* If mailbot has not yet been created .... */ && (
                <div>
                  <h3>Build Your Skill</h3>
                  <p>
                    Prototype on Glitch or quickly spin up a local environment.
                  </p>
                  <br />
                  <div>
                    {this.state.listeningForAutoInit &&
                    !this.state.autoInitFailed ? (
                      <span>
                        <span
                          className="loader"
                          style={{ display: "inline-block", marginBottom: -5 }}
                        >
                          {" "}
                        </span>{" "}
                        ...Glitch is setting up.
                      </span>
                    ) : (
                      <a
                        className="btn btn-primary"
                        href={glitch.getRemixUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.initGlitchProject}
                      >
                        Create in Glitch
                      </a>
                    )}
                    {this.state.autoInitFailed ? (
                      <GlitchAutoInitFailed />
                    ) : null}
                    {!this.state.listeningForAutoInit ? (
                      <a
                        className="btn btn-secondary"
                        target="_blank"
                        href="https://github.com/mailbots/mailbots#quick-start-followupthen-skills"
                        rel="noopener noreferrer"
                      >
                        Create on Localhost
                      </a>
                    ) : null}
                  </div>
                  <br />
                </div>
              )}

              {isCoreSkill(this.state.form) ? (
                <div
                  style={{
                    padding: 20,
                    margin: "20px 0",
                    backgroundColor: "rgb(235,250,255)"
                  }}
                >
                  ⚡️ This skill lives inside fut-mailbot.
                </div>
              ) : null}

              {!isCoreSkill(this.state.form) &&
                this.state.form.base_url &&
                !this.state.form
                  .installed /* If we have a MailBot  but it isn't installed .... */ && (
                  <div>
                    <h3>
                      {/* <span className="glyphicon glyphicon-warning-sign icon-orange" />{" "} */}
                      Connect Your Skill
                    </h3>
                    <p>Connect skill to your FollowUpThen account.</p>
                    <br />
                    <div>
                      <a
                        className="btn btn-primary"
                        target="_blank"
                        href={this.state.form.install_url}
                        rel="noopener noreferrer"
                      >
                        Connect
                      </a>
                      {editInGlitchLink()}
                    </div>
                    <br />
                    <br />
                  </div>
                )}

              {isCoreSkill(this.state.form) ||
              (this.state.form.base_url && this.state.form.installed) ? (
                <div>
                  <h3>Build Your Skill</h3>
                  <p>
                    Read the <a href="https://fut.readme.io">docs</a>, or start
                    with an{" "}
                    <a href="https://github.com/mailbots/example-skills">
                      example
                    </a>
                    .
                  </p>
                  <br />
                  <Link
                    className="btn btn-primary"
                    to={`/sandbox/${this.state.form.id}`}
                  >
                    {" "}
                    Open Sandbox
                  </Link>
                  {editInGlitchLink()}
                  <br />
                  <br />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    };

    const AdvancedCreateExtPanel = () => (
      <div className="main-panel" key="installPanel">
        <div className="row">
          <div className="col-sm-12">
            <p
              style={{
                display: "inline",
                width: 100,
                whiteSpace: "nowrap",
                float: "right"
              }}
            >
              <Link to={`/developer/skills/${this.state.form.id}/manage`}>
                <span className="glyphicon glyphicon-pencil" /> Edit
              </Link>
            </p>
            <h2>Credentials</h2>
            <div className="mailbot-settings">
              <div>
                <label>Client ID:</label>
                <input
                  ref={el => (this._clientid = el)}
                  readOnly
                  value={this.state.form.oauth2_client_id || ""}
                />
              </div>

              <div>
                <label>Client Secret: </label>
                <input
                  readOnly
                  value={this.state.form.oauth2_client_secret || ""}
                />
              </div>

              <div>
                <label>Scope: </label>
                <input readOnly value={this.state.form.oauth2_scope || ""} />
              </div>

              <div>
                <label>Redirect URI: </label>
                <input
                  readOnly
                  value={
                    this.state.form.oauth2_redirect_uri ||
                    "(Bot setup incomplete)"
                  }
                />
              </div>
              {!this.state.form.oauth2_redirect_uri && (
                <div className="well" style={{ marginTop: 50 }}>
                  <strong>Your skill does not yet have a public URL.</strong>
                  <ol>
                    <li>
                      <a
                        target="_blank"
                        href="https://github.com/mailbots/mailbots#installing"
                        rel="noopener noreferrer"
                      >
                        Set up your MailBot
                      </a>{" "}
                      starting with the below environment.{" "}
                    </li>
                    <li>
                      Add the base URL on the{" "}
                      <Link
                        to={`/developer/skills/${this.props.id}/manage#base_url`}
                      >
                        settings page
                      </Link>
                    </li>
                    <li>
                      Copy the updated .env settings into your MailBot app
                      again.
                    </li>
                  </ol>
                </div>
              )}

              <div style={{ marginTop: 50 }}>
                <p>For your .env</p>
                <pre className="sample-env">{this.getSampleEnvFile()}</pre>
              </div>

              <WarningWell />
            </div>
          </div>
        </div>
      </div>
    );

    const WarningWell = () => (
      <div style={{ marginTop: 50 }}>
        <h2>Need Help?</h2>
        <p>
          Send us an email at{" "}
          <a href="mailto:help@humans.fut.io">help@humans.fut.io</a>.
        </p>
        <p>
          You can also start this bot froms scratch over by unlinking it below.
          Your MailBot's existing code will be left untouched but unlinks our
          conneciton to it, allowing yout create a new bot from scratch at a
          different URL.
        </p>
        <p>
          <a onClick={this.unlinkMailBotApp}>Unlink Bot / Start Over</a>
        </p>

        <h2>
          <span className="glyphicon glyphicon-warning-sign icon-orange" />{" "}
          Danger Zone
        </h2>
        <p>
          <a href="" onClick={this.deleteMailBot} style={{ color: "maroon" }}>
            Delete MailBot
          </a>
          <br />
          All data related to your MailBot, users stored data by your users will
          be irrevocably deleted.
        </p>
      </div>
    );

    const quickCreateFromTemplate = () => (
      <div>
        <VideoOnlyModal
          visible={this.state.showVideoOnlyModal}
          onClose={this.handleCloseVideoOnlyModal}
        />

        <h1 style={{ fontFamily: "Lora", marginBottom: 5 }}>New Skill</h1>
        <p>
          Craft a perfect followup by providing the right information at exactly
          the right moment.
        </p>
        <div className="bot-templates">
          <div
            className="main-panel bot-template hover-panel"
            onClick={e => {
              e.preventDefault();
              this.setState({ intent: "createFresh" });
            }}
          >
            <h3>Register New Skill</h3>
            <p>Create a skill on your localhost.</p>
          </div>
          <div
            className="main-panel bot-template hover-panel"
            onClick={
              e => this.handleCreateBotMailBotAndGlitchFromTemplate(e, "hi5")
              // this.handleCreateBotLocallyFromTemplate()
            }
          >
            <h3>Glitch Quick Start</h3>
            <p>Clone a working, example skill for tinkering on Glitch.com.</p>
          </div>
          {/* <div className="bot-templates hover-panel">
          <div
            className="main-panel bot-template"
            onClick={e =>
              this.handleCreateBotMailBotAndGlitchFromTemplate(e, "blank")
            }
          >
            <h3>📃 Blank</h3>
            <p>Hello world!</p>
          </div> */}
          {/* <div
            className="main-panel bot-template"
            onClick={e =>
              this.handleCreateBotMailBotAndGlitchFromTemplate(e, "todo")
            }
          >
            <h3>✅ ToDo Bot</h3>
            <p>
              A fully functional, email-based todo MailBot. It remembers your
              todos, reminds you about them and lets you complete them all
              without leaving your inbox.
            </p>
          </div> */}
          {/* <div
            className="main-panel bot-template"
            onClick={e =>
              this.handleCreateBotMailBotAndGlitchFromTemplate(e, "news")
            }
          >
            <h3>📰 News Bot</h3>
            <p>
              (UI / Demonstration Only)
              <br /> Receive this week's top products every Friday. Upvote right
              from your email!
            </p>
          </div> */}
          {/* <div
            className="main-panel bot-template"
            onClick={e =>
              this.handleCreateBotMailBotAndGlitchFromTemplate(e, "ticket")
            }
          >
            <h3>🔖 Ticket Bot</h3>
            <p>
              (UI / Demonstration Only)
              <br /> A MailBot that listens for new Github issues. Assign,
              label, comment or close the ticket without leaving email.{" "}
            </p>
          </div> */}
          {/* <div className="main-panel bot-template">
            <h3>🍕 Pizza Bot</h3>
            <p>
              This MailBot uses the <a href="">Dominos API</a> to offer a
              one-click pizza order each Friday.
            </p>
          </div> */}
          {/* <div className="main-panel bot-template">
            <h3>💲 CRM Bot</h3>
            <p>
              Instead of just a followup reminder, this MailBot sends you
              everything needed to followup without leaving your inbox.
            </p>
          </div> */}
          {/* {process.env.NODE_ENV === "development" && (
            <div style={{ margin: 50 }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#ccc" }}
                // href="https://github.com/mailbots/mailbots#installing"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ intent: "createFresh" });
                }}
              >
                {" "}
                + MailBot
              </a>
              <br />
            </div>
          )} */}
        </div>
      </div>
    );

    const createEditMailBotPanel = () => (
      <form key="editExtPanel">
        {this.state.intent === "create" && (
          <Link to="/developer">
            <i className="glyphicon glyphicon-chevron-left" /> Back
          </Link>
        )}
        <div className="main-panel">
          {this.state.intent === "edit" ? (
            <h2>Skill Details</h2>
          ) : (
            <div>
              <h2>New Skill </h2>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="inputEmail" className="control-label">
              Name
            </label>
            <input
              onChange={this.handleOnChange}
              value={this.state.form.name || ""}
              name="name"
              type="text"
              className="form-control"
              id="name"
              placeholder="My Skill"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description" className="control-label">
              Description
            </label>
            <textarea
              onChange={this.handleOnChange}
              value={this.state.form.description || ""}
              name="description"
              className="form-control"
              rows="8"
              id="description"
              placeholder="What does your skill do?"
            />
            <span className="help-block">
              Text-only for first 140 characters.{" "}
              <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">
                Markdown
              </a>{" "}
              is supported after that.{" "}
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="subdomain" className="control-label">
              Skill Flag
            </label>
            <input
              onChange={this.handleOnChange}
              value={this.state.form.subdomain || ""}
              name="subdomain"
              type="text"
              className="form-control"
              id="subdomain"
              placeholder="(Ex: sms)"
              required
            />
            <span className="help-block">
              Ex:{" "}
              <code>3days-{this.state.form.subdomain}@followupthen.com</code>{" "}
              Subject to change until approved.
            </span>
          </div>
          <div className="form-group">
            <label htmlFor="base_url" className="control-label">
              Base URL
            </label>
            <input
              onChange={this.handleOnChange}
              value={this.state.form.base_url || ""}
              name="base_url"
              type="text"
              className="form-control"
              id="base_url"
              placeholder="(Ex: https://foo.ngrok.io)"
            />
            <span className="help-block">
              Local dev{" "}
              <a
                href="https://github.com/mailbots/mailbots#quick-start-followupthen-skills"
                target="_blank"
                rel="noopener noreferrer"
              >
                quickstart
              </a>
              .{" "}
            </span>
          </div>

          {this.state.intent === "edit" && (
            <div>
              <div className="form-group">
                <label htmlFor="oauth2_scope" className="control-label">
                  Scopes
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.oauth2_scope || ""}
                  name="oauth2_scope"
                  type="text"
                  className="form-control"
                  id="oauth2_scope"
                  placeholder="What permissions your bot requires"
                  required
                />
                <span className="help-block">
                  Separate{" "}
                  <a
                    href="https://fut.readme.io/reference#oauth-scopes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    available permission scopes
                  </a>{" "}
                  by space. Ex: "get_user_info mailbot_manage_self
                  read_own_tasks manage_own_tasks"
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="developer_name" className="control-label">
                  Developer Name
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.developer_name || ""}
                  name="developer_name"
                  type="text"
                  className="form-control"
                  id="developer_name"
                  placeholder="Developer Name"
                />
                <span className="help-block">Introduce yourself</span>
              </div>

              <div className="form-group">
                <label htmlFor="developer_name" className="control-label">
                  Privacy Policy URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.privacy_policy_url || ""}
                  name="privacy_policy_url"
                  type="text"
                  className="form-control"
                  id="privacy_policy_url"
                  placeholder="(ex: https://www.site.com/privacy)"
                />
                <span className="help-block">Required for publishing</span>
              </div>

              <div className="form-group">
                <label htmlFor="developer_name" className="control-label">
                  Help URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.help_url || ""}
                  name="help_url"
                  type="text"
                  className="form-control"
                  id="help_url"
                  placeholder="(ex: mailto:support@acme.com)"
                />
                <span className="help-block">
                  Can be a mailto link, a link to your help site
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="developer_name" className="control-label">
                  Skill Homepage
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.learn_more_url || ""}
                  name="learn_more_url"
                  type="text"
                  className="form-control"
                  id="learn_more_url"
                  placeholder="(ex: https://www.site.com/)"
                />
                <span className="help-block">
                  Where users can read more about your bot
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="icon" className="control-label">
                  Skill Icon URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.icon || ""}
                  name="icon"
                  type="text"
                  className="form-control"
                  id="icon"
                  placeholder="Enter the URL of your bot icon"
                />
              </div>

              <div className="form-group">
                <label htmlFor="oauth2_redirect_uri" className="control-label">
                  OAuth Redirect URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.oauth2_redirect_uri || ""}
                  name="oauth2_redirect_uri"
                  type="text"
                  className="form-control"
                  id="oauth2_redirect_uri"
                  placeholder="(ex: https://my-mailbots-mailbot.glitch.me/auth/callback)"
                />
                <span className="help-block">
                  For Node.js starter kit, use:{" "}
                  <code>
                    {urlJoin(this.state.form.base_url, "auth/callback")}
                  </code>{" "}
                  <a href="https://fut.readme.io/reference#section-oauth-urls">
                    More...
                  </a>
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="install_url" className="control-label">
                  Install URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.install_url || ""}
                  name="install_url"
                  type="text"
                  className="form-control"
                  id="install_url"
                  placeholder="This url usually initiates the OAuth2 process. (Defaults to Base Url) "
                />
                <span className="help-block">
                  Your OAuth login url. Enter `fut-mailbot` if it
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="webhook_url" className="control-label">
                  Webhook URL
                </label>
                <input
                  onChange={this.handleOnChange}
                  value={this.state.form.webhook_url || ""}
                  name="webhook_url"
                  type="text"
                  className="form-control"
                  id="webhook_url"
                  placeholder="(ex: https://my-mailbots-mailbot.glitch.me/webhooks)"
                />
                <span className="help-block">
                  This is usually{" "}
                  <code>{urlJoin(this.state.form.base_url, "webhooks")}</code>{" "}
                  <a href="https://developers.mailbots.com">More...</a>
                </span>
              </div>

              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.form.devmode}
                    onChange={this.handleOnChange}
                    name="devmode"
                    id="devmode"
                  />
                  <strong>Development Mode: </strong>
                  Tasks for this bot will not count against your quota and will
                  be permenantly deleted after a few days.
                </label>
              </div>

              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.form.enabled}
                    onChange={this.handleOnChange}
                    name="enabled"
                    id="enabled"
                  />
                  <strong>Enabled: </strong>
                  Allow others to use your Skill, but do not list it publicly.
                </label>
                <span className="help-block">
                  {this.state.form.enabled ? (
                    <span>
                      Others can use your mailbot by visiting your install URL:{" "}
                      {this.state.form.install_url || this.state.form.base_url}
                      <DocsLink href="https://fut.readme.io/docs/sharing" />
                    </span>
                  ) : (
                    <span>
                      Only you have permission to view and install this mailbot
                    </span>
                  )}
                </span>
              </div>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.form.published}
                    onChange={this.handleOnChange}
                    name="published"
                    id="published"
                  />
                  <strong>Published: </strong>
                  List as a publicly available skill
                </label>
              </div>

              {/* @todo Confirm that only admins can publish skills. Display this block if user is not admin */}
              {/* <div className="form-group well" style={{ padding: 10 }}>
                <label>Publishing</label>
                <p>
                  Ready to publish on the MailBots Directory?
                  <DocsLink href="https://fut.readme.io/docs/sharing#section-publishing" />
                </p>
              </div> */}
            </div>
          )}
          <br />

          <div className="form-group">
            {(this.state.intent === "create" ||
              this.state.intent === "createFresh") && (
              <button
                type="submit"
                className="btn btn-mailbots btn-primary"
                id="save_settings"
                onClick={this.createMailBotFromForm}
              >
                Create
              </button>
            )}

            {this.state.intent === "edit" && (
              <button
                type="submit"
                className="btn btn-mailbots btn-primary"
                id="save_settings"
                onClick={this.handleUpdateMailBot}
              >
                Save Changes
              </button>
            )}
          </div>
        </div>
      </form>
    );

    return (
      <div>
        {/* See Glitch Helpers for how this iFrame is used */}
        <iframe
          id="glitch-setup-iframe"
          src="https://www.glitch.com/"
          display={{ visibility: "hidden" }}
          width="1"
          height="1"
          frameBorder="0"
        />
        {
          // todo: consolidate dialog logic, pull state up (redux)
          this.state.dialogText && (
            <Dialog {...this.state}>{this.state.dialogText}</Dialog>
          )
        }

        <SettingUpMailBotModal
          visible={this.state.showMailBotSetupModal}
          form={this.state.form}
          onClose={this.closeSettingUpMailBotModal}
          listeningForAutoInit={this.state.listeningForAutoInit}
          autoInitFailed={this.state.autoInitFailed}
        />

        {this.state.intent === "edit" &&
          this.state.hasOwnProperty("form") &&
          this.state.form &&
          this.state.form.id && (
            <div>
              <div className="menu-panel clearfix">
                <h2 style={{ fontFamily: "Lora" }}>{this.state.form.name}</h2>

                <ul
                  className="nav subnav nav-pills"
                  role="tablist"
                  style={{
                    display: "none",
                    whiteSpace: "nowrap",
                    minWidth: 320
                  }}
                >
                  {/* adding icons... */}
                  <li>
                    <NavLink
                      to={`/developer/skills/${this.state.form.id}`}
                      exact
                    >
                      <span className="glyphicon glyphicon-wrench" /> Build
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to={`/developer/skills/${this.state.form.id}/manage`}
                    >
                      <span className="glyphicon glyphicon-pencil" /> Edit
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      to={`/developer/skills/${this.state.form.id}/stats`}
                    >
                      <span className="glyphicon glyphicon-signal" /> Stats
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/developer/skills/${this.state.form.id}/users`}
                    >
                      <span className="glyphicon glyphicon-user" /> Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/developer/skills/${this.state.form.id}/logs`}
                    >
                      <span className="glyphicon glyphicon-list" /> Logs
                    </NavLink>
                  </li>
                </ul>
              </div>

              <Switch>
                <Route
                  path="/developer/create"
                  render={() => {
                    return [
                      <CreateMailBotPanel key="1" />,
                      !this.state.isLoading && createEditMailBotPanel()
                    ];
                  }}
                />

                <Route
                  path="/developer/skills/:id/stats"
                  render={() => (
                    <MailBotUsageStats metrics={this.state.form.metrics} />
                  )}
                />

                <Route
                  path="/developer/skills/:id/users"
                  render={() => (
                    <MailBotUsageUsers mailbotid={this.state.form.id} />
                  )}
                />

                <Route
                  path="/developer/skills/:id/logs"
                  render={() => (
                    <MailBotUsageLogs mailbotid={this.state.form.id} />
                  )}
                />

                <Route
                  path="/developer/skills/:id/manage"
                  render={() =>
                    !this.state.isLoading && createEditMailBotPanel()
                  }
                />

                <Route
                  path="/developer/skills/:id"
                  render={() => {
                    return (
                      <div>
                        {this.state.form && (
                          <div>
                            <CreateMailBotPanel />
                            {this.state.form.base_url && (
                              <div className="main-panel" key="infoPanel">
                                <MailBotDevInfoPanel
                                  mailbot={this.state.form}
                                  uninstallMailBot={this.uninstallMailBot}
                                />
                              </div>
                            )}
                            <AdvancedCreateExtPanel />
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </Switch>
            </div>
          )}

        {this.state.intent === "createFresh" &&
          !this.state.isLoading &&
          createEditMailBotPanel()}

        {this.state.intent === "create" &&
          !this.state.isLoading &&
          quickCreateFromTemplate()}
      </div>
    );
  }
}

export default withRouter(MailBotDetailsDev);
