import React, { Component } from "react";
import EventListener from "react-event-listener";

const stackedStyles = {
  whiteSpace: "nowrap",
  listStyle: "none",
  padding: 20
};

export const LeftNavStacked = ({ children, header, inlineStackedStyles }) => (
  <ul
    className="subnav nav-stacked"
    style={{ ...stackedStyles, ...inlineStackedStyles }}
    role="tablist"
  >
    {header && <li className="subnav-header">{header}</li>}
    {children}
  </ul>
);

const mobileStyles = {
  ul: {
    width: 150,
    color: "black",
    textAlign: "left",
    listStyle: "none",
    paddingLeft: 0
  },
  a: {
    color: "grey",
    textDecoration: "none",
    display: "block",
    marginTop: 5,
    width: 100
  }
};

export const LeftNavMobile = ({
  children,
  header,
  inlineMobileStyles,
  mobileTitle,
  inlineDropdownStyles
}) => (
  <ul style={{ ...mobileStyles.ul, ...inlineMobileStyles }}>
    {header && <li className="subnav-header">{header}</li>}
    <li className="dropdown">
      <a
        href=""
        className="dropdown-toggle"
        data-toggle="dropdown"
        role="button"
        aria-expanded="false"
        style={mobileStyles.a}
      >
        {mobileTitle ? <span style={{ margin: 7 }}>{mobileTitle}</span> : null}
        <span className="glyphicon glyphicon-align-justify" />{" "}
        <span className="caret" />
      </a>
      <ul
        className="dropdown-menu"
        role="menu"
        style={{ ...inlineDropdownStyles }}
      >
        {children}
      </ul>
    </li>
  </ul>
);

class NavLeft extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  getMenu(children) {
    if (this.state.width < 768)
      return <LeftNavMobile {...this.props}>{children}</LeftNavMobile>;
    else return <LeftNavStacked {...this.props}>{children}</LeftNavStacked>;
  }

  render() {
    return (
      <div className="nav-left-container">
        <EventListener target="window" onResize={this.handleResize} />{" "}
        {this.getMenu(this.props.children)}
      </div>
    );
  }
}

export default NavLeft;
