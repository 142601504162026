import React from "react";
import FilterLink from "../FilterLink";

const isAnyFilter = () => {
  return window.location.search.indexOf("status") === -1;
};

const TaskFilterByStatus = props => {
  return (
    <React.Fragment>
      <li className="subnav-header" style={{ paddingTop: 0 }}>
        Status
      </li>
      <li>
        <FilterLink
          id="current"
          filterObj={{
            status: null
          }}
          isActive={() => isAnyFilter()}
          onFilter={props.applyFilter}
        >
          Upcoming
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="completed"
          filterObj={{
            status: "completed"
          }}
          onFilter={props.applyFilter}
        >
          Completed
        </FilterLink>
      </li>
    </React.Fragment>
  );
};

export default TaskFilterByStatus;
