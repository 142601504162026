import React, { useState } from "react";

import Modal from "../Modal";
import ScheduleWidget from "../ScheduleWidget";
import Card from "../Card";

const RescheduleModal = props => {
  const [newCommand, setNewCommand] = useState("");

  return (
    <Modal visible={props.isVisible} onClose={props.onCloseModal}>
      <Card type="large">
        <h3 className="modal-title" id="deleteModalLabel">
          Reschedule
        </h3>
        <p>Choose a time format to be applied to the selected tasks</p>
        <div className="form-group" style={{ marginTop: 24, marginBottom: 24 }}>
          <div style={{ border: "1px solid #ddd", borderRadius: 4 }}>
            <ScheduleWidget
              inputRef={ref => ref && ref.focus()}
              onChange={command => setNewCommand(command)}
              getTask={() => ({ reference_email: { to: [] } })}
            />
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.onSubmit(newCommand);
          }}
          disabled={props.isBusy}
        >
          {!props.isBusy ? "Reschedule" : "Rescheduling..."}
        </button>
        {"  "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.onCloseModal}
        >
          Cancel
        </button>
      </Card>
    </Modal>
  );
};

export default RescheduleModal;
