import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const MailBotChart = props => {
  let { children, data } = props;

  // translates MailBots API response into rechart-compatible data formats
  const getGraphableData = metric => {
    let graphableData = [];
    for (let key in metric) {
      let date = key; //MailBots API uses dates as keys
      let statEntry = { date: date };

      // for simple metrics (ex: ['2017-12.21': 23])
      if (typeof metric[key] === "number") {
        statEntry.value = metric[key];

        // for "multi-dimensional" metrics (ex: ['2017-12.21': {stat1: 23, stat2: 32}])
      } else {
        for (let stat in metric[key]) {
          let value = metric[key][stat];
          statEntry[stat] = value;
        }
      }
      graphableData.push(statEntry);
    }
    return graphableData;
  };

  return (
    <LineChart
      width={600}
      height={300}
      data={getGraphableData(data)}
      margin={{ top: 25, right: 0, left: 0, bottom: 5 }}
    >
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid strokeDasharray="2 2" />
      <Tooltip />
      <Legend />
      {children}
    </LineChart>
  );
};

export default MailBotChart;
