import React from "react";
import FilterLink from "../FilterLink";
import { GlobalContext } from "../App";

const isAnyFilter = () => {
  return window.location.search.indexOf("delivery_addr") === -1;
};

const TaskFilterByDeliveryAddress = props => {
  const { loggedInUser } = React.useContext(GlobalContext);

  // if no emails linked, not need to show this option
  if (loggedInUser.emails && loggedInUser.emails.length === 1) return null;

  return (
    <React.Fragment>
      <li className="subnav-header">Delivery Address</li>
      <li>
        <FilterLink
          id="any"
          filterObj={{
            delivery_addr: null
          }}
          onFilter={props.applyFilter}
          isActive={() => isAnyFilter()}
        >
          Any
        </FilterLink>
      </li>
      {loggedInUser &&
        loggedInUser.emails.reverse().map((email, index) => (
          <li>
            <FilterLink
              key={index}
              id={email}
              filterObj={{
                delivery_addr: email
              }}
              onFilter={props.applyFilter}
            >
              {email}
            </FilterLink>
          </li>
        ))}
    </React.Fragment>
  );
};

export default TaskFilterByDeliveryAddress;
