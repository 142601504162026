import React from "react";
import loadingAnimation from "../src/images/loadingEmailAnimGrey.gif";

const Loading = ({ loadingText = null, style = {} }) => (
  <div
    style={{
      display: "block",
      textAlign: "center",
      opacity: 0.8,
      ...style
    }}
  >
    <img src={loadingAnimation} alt="Loading..." width="175px" />{" "}
    {loadingText && <p>{loadingText}</p>}
  </div>
);

export default Loading;
