import React, { useState, useContext, useEffect, useRef } from "react";
import ModalSimple from "../ModalSimple";
import { useHistory, Link } from "react-router-dom";
import {
  handleUpgradeClick,
  userIsBillingAnnually,
  isOnPersonalAstPlan,
  isOnBasicPlan,
  getStripePlanPrice,
  isOnFreePlan,
  userHasStripeAddOnPlan,
  isEmployee,
  isOnCustomPlan,
  getRemainingBudgetCent,
  startStripeCheckout,
  getUserPlanNames,
  isOnLegacyPlan,
  getTeamAdmin,
  isTeamOwner,
  getFeatureLimits,
  hasEmployees,
  getPriceFromStripeId,
  hasLegacyPersonalPlan,
  hasLegacyCompanyPlan,
  getMyPlans,
  calculateTeamTotalPrice,
  getPriceForAugmentedPlans,
  referralCreditWaitingForFreeUpgrade,
  getStoreCredit,
  isPaying,
  hasLegacyProPlan,
  migrateBilling,
  getPriceFromPlanKey,
  hasBasePlan
} from "./billingHelpers";
import { PlanLimits } from "./SettingsAccount";
import {
  InviteTeamGreyCallout,
  RequestAdminPermission
} from "./UpgradeDialogs";
import nProgress from "nprogress";
import { GlobalContext } from "../App";
import styled from "styled-components";
import { Switch, Popover } from "antd";
import "antd/dist/antd.css";

import { BlueCallout, GreyCallout, PopoverStyled } from "../Layout";
import { WelcomeToV3Tour } from "../ModalTours/TourWelcomeToV3";
import Expandable, { ExpandableStateful } from "../Expandable";
import { LoadingOutlined, LoginOutlined } from "@ant-design/icons";
import { isOnMobile, mailbotsAdminBrowser, sendGtmEvent } from "../lib/utils";
import { message as antMessage } from "antd";
import { logger } from "../lib/Logger";

/**
 * Currently the single source of upgrading an account, adding add-on subscriptions.
 * This dialog is only for up-selling. Changing plans around is handled in Billing.jsx
 *
 */
export const ChoosePlanDialog = ({
  isModalVisible,
  setIsModalVisible = () => {},
  onClose,
  overrides = {},
  onSuccess = () => {}
}) => {
  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const [skillsList, setSkillsList] = useState([]);
  const [legacyLimitsExpanded, setLegacyLimitsExpanded] = useState(false);
  const [billAnnually, setBillAnnually] = useState(
    userIsBillingAnnually(loggedInUser)
  );
  const history = useHistory();

  useEffect(() => {
    loadSkillInfo();
  }, [JSON.stringify(loggedInUser)]);

  const alreadyLogged = useRef(false);
  useEffect(() => {
    if (
      isModalVisible !== false && // set to 'undefined' during the tour @todo - debug / refactor
      !alreadyLogged.current
    ) {
      alreadyLogged.current = true;
      logger.log("billing: choose plan dialog shown");
      sendGtmEvent("pricing_viewed", {
        category: "upgrade",
        action: "pricing_viewed"
      });
    }
  });

  const loadSkillInfo = async () => {
    try {
      const { mailbots } = await mailbotsAdminBrowser.getMailBots();
      setSkillsList(mailbots);
    } catch (e) {
      console.error("error loading skills:" + e.message);
      // antMessage.error("Error loading skills");
    }
  };

  // one of the three main plan options
  const BasePlanOption = ({
    name,
    planKey = null,
    price,
    description,
    featureList = [],
    currentPlan = false,
    downgradablePlan = false,
    overrides = {}
  }) => {
    const { loggedInUser } = useContext(GlobalContext);
    const history = useHistory();
    const priceStyle = {
      color: "black",
      fontSize: 40,
      fontWeight: "bold",
      marginBottom: 30
    };
    const ChooseButton = () => (
      <span>
        {!currentPlan && !downgradablePlan ? (
          <a
            onClick={async e => {
              const sleep = ms => new Promise(r => setTimeout(r, ms));
              e.target.text = "Upgrading...";
              nProgress.start();
              if (isOnLegacyPlan(loggedInUser)) {
                setLegacyLimitsExpanded(true);
                await sleep(200); // workaround for async setState
              }
              await handleUpgradeClick(
                planKey,
                loggedInUser,
                billAnnually,
                reloadUser,
                history
              );
              if (onSuccess) onSuccess();
              if (onClose) onClose(e);
              nProgress.done();
            }}
            className="btn btn-primary"
            style={{
              width: "85%",
              maxWidth: 300,
              position: "absolute",
              bottom: 15
            }}
          >
            Select
          </a>
        ) : null}

        {currentPlan ? (
          <button
            className="btn"
            style={{
              width: "85%",
              position: "absolute",
              bottom: 15,
              maxWidth: 300,
              color: "#949494",
              backgroundColor: "rgb(245 245 245)"
            }}
          >
            Current
          </button>
        ) : null}

        {downgradablePlan ? (
          <a
            className="btn"
            style={{
              width: "85%",
              position: "absolute",
              bottom: 15,
              maxWidth: 300,
              color: "#949494",
              backgroundColor: "rgb(245 245 245)"
            }}
            // disabled={true}f
            onClick={e => {
              e.preventDefault();
              alert(
                "Please go to Account > Settings > Billing to downgrade your plan."
              );
              onClose(e);
              history.push("/billing");
            }}
          >
            Downgrade
          </a>
        ) : null}
      </span>
    );

    const { Description } = overrides;

    return (
      <div
        style={{
          textAlign: "left",
          minWidth: 200,
          border: "1px solid #efefef",
          borderRadius: 8,
          padding: 20,
          position: "relative"
        }}
      >
        <span style={priceStyle}>${price}</span>
        /mo
        <p style={{ textTransform: "uppercase", marginBottom: 0 }}>{name}</p>
        {Description ? (
          <Description />
        ) : (
          <p style={{ color: "#aaa", fontStyle: "italic" }}>{description}</p>
        )}
        <ul style={{ listStyle: "none", paddingLeft: 0, marginBottom: 50 }}>
          {featureList.map((feature, i) => (
            <Popover key={i} content={feature.tooltip}>
              <li
                key={feature.text + i}
                style={{ position: "relative", whiteSpace: "nowrap" }}
              >
                <i className="glyphicon glyphicon-check"></i> {feature.text}
              </li>
            </Popover>
          ))}
        </ul>
        <ChooseButton />
      </div>
    );
  };

  const ResponsivePricingGrid = styled.div`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
    margin-left: -18px; /* @todo */
    grid-column-gap: 20px;
    text-align: left;
    padding-bottom: 50px;
    @media (max-width: 825px) {
      grid-template-columns: 90%;
      grid-row-gap: 50px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  `;

  const tooltipWhatIsAnActiveFut = (
    <>
      What is an{" "}
      <a
        target="_blank"
        href="https://help.followupthen.com/knowledge-base/how-are-active-followups-calculated/"
        rel="nofollow noreferrer"
      >
        Active Followup?
      </a>
    </>
  );
  const tooltipAllFeaturesFromLowerPlan = (
    <>All features from earlier plans included</>
  );

  const BasePlanGrid = () => (
    <ResponsivePricingGrid>
      <BasePlanOption
        name="Free"
        planKey="free"
        price={0}
        description="Free Forever"
        currentPlan={isOnFreePlan(loggedInUser)}
        downgradablePlan={!isOnFreePlan(loggedInUser)}
        featureList={[
          { text: "Basic Reminders", tooltip: <>Recurring reminder</> }
        ]}
        key="free"
      />
      <BasePlanOption
        name={`Base Plan`}
        planKey="v3_base"
        price={getStripePlanPrice("v3_base", billAnnually)}
        currentPlan={isOnBasicPlan(loggedInUser)}
        downgradablePlan={isOnPersonalAstPlan(loggedInUser)}
        description="Basic + À-La-Carte Options"
        featureList={[
          {
            text: "Recurring Reminders",
            tooltip: (
              <div style={{ maxWidth: 300 }}>
                As easy as every3days@fut.io. Combine with other skills for even
                more functionality. Ex: every3days-sms@fut.io
                <Link to="/skills/recurring">Read more</Link>
              </div>
            )
          },
          {
            text: "Basic skills included",
            tooltip: (
              <div style={{ maxWidth: 300 }}>
                <strong>Cancel:</strong> Avoid duplicate followups on the same
                thread by appending -c to your date format (ex: 3days-c@fut.io).{" "}
                <Link to="/skills/c">Read more</Link>
                <br />
                <br />
                <strong>Task: </strong> Keep following up every day until a
                "complete" button is clicked (ex: 3days-t@fut.io){" "}
                <Link to="/skills/t">Read more</Link>
                <br />
                <br />
                <strong>Calendar URL: </strong> Subscribe to a feed of your
                upcoming reminders on your calendar{" "}
                <Link to="/skills/ical">Read more</Link>
              </div>
            )
          },
          {
            text: "Buy premium skills a-la-carté",
            tooltip: (
              <p style={{ maxWidth: 300 }}>
                Pay for only the skills you need in the{" "}
                <Link to="/skills/1675">Skills Directory</Link>. For example,
                receive an SMS with your reminder, or integrate with your
                calendar.
              </p>
            )
          },
          {
            text: "Bulk actions",
            tooltip: (
              <p style={{ maxWidth: 300 }}>
                Perform bulk actions on your followups such as rescheduling,
                deleting and completing. Bulk a CSV is included on all plans
                &mdash; your data belongs to you.
              </p>
            )
          },
          // Require this on free accounts
          {
            text: "Email-based actions",
            tooltip: (
              <div style={{ maxWidth: 500 }}>
                <img
                  src="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
                  style={{ width: 250, float: "right" }}
                />
                <p>
                  "Actions” are the buttons in your emails that that get things
                  done, for example, postponing a followup.
                </p>
                <p>
                  Email-Based Actions (shown here), let you quickly complete
                  actions without leaving your inbox, even when offline.
                </p>
                <a
                  href="https://help.followupthen.com/knowledge-base/email-base-actions-troubleshooting-and-faq/"
                  target="_blank"
                  rel="follower noreferrer"
                >
                  Read more
                </a>
              </div>
            )
          }
          // @todo show this without implying 1 connected inbox is included in the base pricing
          // {
          //   text: "1 Connected Inbox",
          //   tooltip: (
          //     <>
          //       Connect 1 inboxes for response detection and whitelabel sending
          //       (billed separately)
          //     </>
          //   )
          // }
        ]}
      />
      <BasePlanOption
        name="Professional Package"
        planKey="personalAst"
        price={
          // Personal assistant / professional package plan is actually an add-on
          getStripePlanPrice("personalAst", billAnnually) +
          getStripePlanPrice("v3_base", billAnnually)
        }
        currentPlan={isOnPersonalAstPlan(loggedInUser)}
        description="Package Plan"
        overrides={{
          Description: () => (
            <p style={{ color: "#aaa", fontStyle: "italic" }}>
              Package Plan{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                Best value
              </span>
            </p>
          )
        }}
        featureList={[
          {
            text: "Everything one Base Plan, plus..."
          },
          {
            text: "Response Detection",
            tooltip: <>Automatically cancel a followup if there is a reply </>
          },
          {
            text: "Whitelabel followups",
            tooltip: (
              <>
                Send followups from your own email address without any mention
                of FollowUpThen{" "}
              </>
            )
          },
          {
            text: "Skill package *",
            tooltip: (
              <span style={{ display: "inline-block", maxWidth: 300 }}>
                Optimized for maximum levels of inbox automation and
                productivity. Includes:
                <ul>
                  <li>response detection</li>
                  <li>sms</li>
                  <li>smart cancellation</li>
                  <li>zapier</li>
                  <li>memorize</li>
                  <li>google calendar integration</li>
                  <li>calendar url integration</li>
                  <li>tasks</li>
                  <li>whitelabel sending</li>
                  <li>...more to come</li>
                </ul>
                {/* <p>
                  Purchased individually, this is a <strong>$50/mo</strong>.
                </p> */}
                <p>
                  * Limited to one user per account. Some of our future skills
                  will be included in this package, some will be billed
                  separately.
                </p>
              </span>
            )
          },
          {
            text: "Followup in bulk",
            tooltip: (
              <>Light-weight bulk followup tools from your own email address </>
            )
          }
        ]}
        key="pa"
      />
    </ResponsivePricingGrid>
  );

  const SetAnnualMonthlyBilling = () => (
    <div style={{ textAlign: "center", marginTop: -15, marginBottom: 15 }}>
      <div
        style={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: "31% 5% 60%",
          margin: 50,
          marginLeft: "auto",
          marginRight: "auto",
          width: 200
        }}
      >
        <span onClick={() => setBillAnnually(false)}>Monthly</span>
        <Switch
          defaultChecked
          onClick={() => {
            logger.log("billing: toggled monthly annual billing");
            setBillAnnually(!billAnnually);
          }}
          checked={billAnnually}
        />
        <span onClick={() => setBillAnnually(true)}>Annual</span>
        {!billAnnually ? (
          <span
            style={{
              position: "absolute",
              right: "-80px",
              color: "green",
              fontWeight: "bolder",
              backgroundColor: "#d6efd6",
              padding: "2px 10px 2px 10px",
              top: "-1px",
              borderRadius: "21px"
            }}
          >
            Save 20%
          </span>
        ) : null}
      </div>
      {/* {!billAnnually ? (
        <p>
          Save over 17% by{" "}
          <a
            onClick={e => {
              e.preventDefault();
              setBillAnnually(true);
            }}
          >
            billing annually
          </a>
          .
        </p>
      ) : (
        <div>
          Annual prices shown.{" "}
          <a
            onClick={e => {
              e.preventDefault();
              setBillAnnually(false);
            }}
            className="text-muted"
          >
            Show monthly
          </a>{" "}
          {!isOnFreePlan(loggedInUser) &&
          !userIsBillingAnnually(loggedInUser) ? (
            <div>
              Switch to annual billing on your{" "}
              <Link to="/account" onClick={() => setIsModalVisible(false)}>
                account page
              </Link>
            </div>
          ) : null}
          .
        </div>
      )} */}
    </div>
  );

  // Plan Add-on Options List
  const AddOnPlanOption = ({
    name,
    planKey,
    description,
    hasBillingPlan = false,
    purchased = false,
    moreLink = ""
  }) => {
    const { loggedInUser, reloadUser } = useContext(GlobalContext);
    const history = useHistory();
    const AddButton = () =>
      hasBillingPlan ? (
        <a
          onClick={() => {
            nProgress.start();
            handleUpgradeClick(
              planKey,
              loggedInUser,
              billAnnually,
              reloadUser,
              history
            );
            nProgress.done();
          }}
        >
          +Add
        </a>
      ) : (
        <span className="text-muted">(Choose base plan first)</span>
      );

    return (
      <div>
        {userHasStripeAddOnPlan(planKey, loggedInUser) ? (
          <span className="icon-green" style={{ fontWeight: "bold" }}>
            {" "}
            <i className="glyphicon glyphicon-check"></i> Installed
          </span>
        ) : null}
        <div
          // className="info-dialog"
          style={{
            width: "100%",
            display: "grid",
            opacity: purchased ? 0.5 : 1,
            gridTemplateColumns: "75% 25%",
            alignItems: "center",
            columnGap: 10,
            marginBottom: 15
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>{name}</span>
            <br />
            {description} {moreLink ? <a href={moreLink}>..more</a> : null}
          </div>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: 18, fontWeight: "bold" }}>
              +${getStripePlanPrice(planKey, billAnnually)}
            </span>
            /mo <br />
            {!userHasStripeAddOnPlan(planKey, loggedInUser) ? (
              <AddButton />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const AddOnPlanGrid = () => (
    <div
      style={{
        maxWidth: 760,
        marginTop: 50,
        textAlign: "left",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <h2>Available Add-Ons </h2>
      {isOnFreePlan(loggedInUser) || isOnBasicPlan(loggedInUser) ? (
        <p>
          Note: Response Detection and SMS are included in the Personal
          Assistant plan.
        </p>
      ) : null}
      <div>
        <AddOnPlanOption
          name="SMS"
          planKey="sms"
          description={`Get an sms with your followup`}
          moreLink="/skills/123"
          purchased={userHasStripeAddOnPlan("sms", loggedInUser)}
          hasBillingPlan={!isOnFreePlan(loggedInUser)}
        />
        <AddOnPlanOption
          name="Response Detection"
          planKey="r"
          description={`Cancel on reply (included in Professional Package)`}
          moreLink="/skills/123"
          purchased={
            userHasStripeAddOnPlan("r", loggedInUser) ||
            isOnPersonalAstPlan(loggedInUser)
          }
          hasBillingPlan={
            !isOnFreePlan(loggedInUser) || isOnPersonalAstPlan(loggedInUser)
          }
        />
        <AddOnPlanOption
          name="Whitelabel Sending"
          planKey="marketing"
          description={`Sent from your own outbox`}
          hasBillingPlan={!isOnFreePlan(loggedInUser)}
        />
      </div>
    </div>
  );

  const MigrateAccountButton = () => (
    <>
      <a
        onClick={async e => {
          e.target.text = "migrating...";
          await migrateBilling(loggedInUser);
        }}
        className="btn btn-primary"
      >
        Upgrade
      </a>
    </>
  );

  const UpgradeButton = ({ loggedInUser }) => {
    if (hasBasePlan(getMyPlans(loggedInUser))) return null;
    return (
      <a
        className="btn btn-primary"
        onClick={async e => {
          const sleep = ms => new Promise(r => setTimeout(r, ms));
          e.target.text = "🚀 Upgrading...";
          nProgress.start();
          if (isOnLegacyPlan(loggedInUser)) {
            // setLegacyLimitsExpanded(true);
            await sleep(200); // workaround for async setState
          }
          logger.log("billing: clicked upgrade button");
          await handleUpgradeClick(
            "v3_base",
            loggedInUser,
            billAnnually,
            reloadUser,
            history
          );
          if (onSuccess) onSuccess();
          if (onClose) onClose(e);
          nProgress.done();
        }}
        className="btn btn-primary"
      >
        {isOnFreePlan(loggedInUser) ? "14-Day Free Trial" : null}
        {isOnLegacyPlan(loggedInUser) ? "Upgrade" : null}
      </a>
    );
  };

  const Header = ({ loggedInUser }) => {
    if (overrides.Header !== undefined) return overrides.Header;

    if (hasBasePlan(getMyPlans(loggedInUser))) {
      return (
        <div style={{ marginBottom: 30, marginTop: 30 }}>
          <h1 className="serif">My Plan</h1>
          <h3 className="serif">
            Explore premium skills in the Skills Directory
          </h3>
        </div>
      );
    }

    // assume upgrade needed (free or legacy)
    return (
      <div style={{ marginBottom: 30 }}>
        <h1
          style={{
            color: "black",
            fontSize: 100,
            marginTop: 50
          }}
          className="serif"
        >
          ${billAnnually ? 4 : 5}
          <span className="per-mo">/mo</span>
        </h1>
        {!isEmployee(loggedInUser) ? <SetAnnualMonthlyBilling /> : null}
        <h3 className="serif">Unlock the tools of a Followup Master 🥋</h3>
        <br />
        <UpgradeButton loggedInUser={loggedInUser} />
      </div>
    );
  };

  return (
    <ModalSimple
      visible={isModalVisible}
      onClose={onClose}
      style={{
        textAlign: "center",
        maxWidth: 800
      }}
      className="upgrade-modal"
    >
      {process.env.REACT_APP_BILLING_ENFORCED !== "yes" ? (
        <BlueCallout>
          Plan limits are not yet enforced. Thanks for being a beta tester! Read
          more about our{" "}
          <a
            href="https://help.followupthen.com/2020/12/30/new-pricing/"
            target="_blank"
            rel="nofollower noopener noreferrer"
          >
            new pricing
          </a>
        </BlueCallout>
      ) : null}

      {isEmployee(loggedInUser) && isOnLegacyPlan(loggedInUser) ? (
        <BlueCallout>
          <p>
            👋 <strong>Your team is on a legacy billing plan</strong>. <br />{" "}
            Your team administrator ({getTeamAdmin(loggedInUser).email}) can
            upgrade your team by visiting app.followupthen.com
          </p>
          <p>
            In the meantime, visit your{" "}
            <Link to="/billing" onClick={() => onClose()}>
              billing page
            </Link>{" "}
            to see which skills are already included in your current plan.
          </p>
        </BlueCallout>
      ) : null}

      {/* Warn user if their subscription limits do not match the latest plans or if they are legacy  */}
      {!isEmployee(loggedInUser) &&
      !isOnFreePlan(loggedInUser) &&
      (isOnCustomPlan(loggedInUser) || isOnLegacyPlan(loggedInUser)) ? (
        <BlueCallout style={{ textAlign: "center" }}>
          👉{" "}
          <strong>
            {isOnLegacyPlan(loggedInUser) ? (
              <>
                You are on a legacy plan (
                {getUserPlanNames(loggedInUser).join("")})
              </>
            ) : (
              <>You are on a custom plan.</>
            )}
          </strong>{" "}
          {isTeamOwner(loggedInUser) &&
          hasEmployees(loggedInUser) &&
          isOnLegacyPlan(loggedInUser) ? (
            <>
              {" "}
              Note: Changing your plan will not migrate your team. View your{" "}
              <Link to="/billing" onClick={() => onClose()}>
                billing page
              </Link>{" "}
              for details.
            </>
          ) : null}{" "}
          {skillsList.length ? (
            <Expandable
              expanded={legacyLimitsExpanded}
              onExpandToggle={() =>
                setLegacyLimitsExpanded(!legacyLimitsExpanded)
              }
              containerStyle={{ margin: "20px 0", display: "inline" }}
              more="Compare Plan Limits"
              less="Hide current plan limits"
            >
              <br />
              <br />
              <p>Here is your current plan.</p>
              <strong>Current Monthly Price:</strong>
              <br />${getPriceForAugmentedPlans(getMyPlans(loggedInUser))}/mo
              <br />
              <br />
              <PlanLimits
                limits={getFeatureLimits(loggedInUser).limits}
                skillsList={skillsList}
              />
            </Expandable>
          ) : (
            <div style={{ margin: 10 }}>
              <LoadingOutlined /> ...Loading plan limits
            </div>
          )}
        </BlueCallout>
      ) : null}

      <Header loggedInUser={loggedInUser} />

      {overrides.SubHeader ? overrides.SubHeader : null}

      {referralCreditWaitingForFreeUpgrade(loggedInUser) ? (
        <BlueCallout>
          💸 Good news! You have a ${getStoreCredit(loggedInUser)} credit.{" "}
          <PopoverStyled
            content={
              <p>
                You may have used an affiliate link to sign up. You too can give
                someone a $5 credit (and earn a $5 credit yourself!) by using
                your Affiliate Link, available in the Account menu.
              </p>
            }
          >
            How?
          </PopoverStyled>
        </BlueCallout>
      ) : null}

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">
            Unlimited Active Followups
            <PopoverStyled
              content={
                <div>
                  Reasonable limits are in place to prevent automated, bulk
                  mailing, shared accounts and heavy programatic use. The free
                  plan allows for 50 active followups. Read how{" "}
                  <a
                    href="https://help.followupthen.com/knowledge-base/how-are-active-followups-calculated/"
                    target="_blank"
                    rel="nofollower noopener noreferrer"
                  >
                    active followups are calculated
                  </a>
                  .
                </div>
              }
            >
              *
            </PopoverStyled>
          </h2>
          <p>
            Schedule followups on anything and everything. Plan your future
            without limits.{" "}
          </p>
        </div>
        <div className="show">
          <img
            style={{ width: 275 }}
            src="https://fut-cdn.s3.amazonaws.com/ill-unlimited-futs.png"
          />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Email-Based Actions</h2>
          <p>
            Get things done with incredible efficiency, and without leaving your
            inbox. Perfect for mobile and working offline.
          </p>
        </div>
        <div className="show">
          <img
            style={{ width: 275 }}
            src="https://files-m9hk8fowd.vercel.app/anim_email_based_actions_macos_v3.gif"
          />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Bulk Actions</h2>
          <p>
            Handle massive numbers of followups with ease using bulk actions.
            Perfect for planning your future inbox, or clearing out your
            reminders before the weekend.
          </p>
        </div>
        <div className="show">
          <img
            style={{ width: 225 }}
            src="https://fut-cdn.s3.amazonaws.com/anim-bulk-actions.gif"
          />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Attachments</h2>
          <p>
            Receive your boarding pass just before a flight, documents just in
            time for a meeting, or a picture of something to remember.
          </p>
        </div>
        <div className="show">
          <img src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-attachments.png" />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Tasks (-t)</h2>
          <p>
            Unlike normal followups, tasks will continue to followup every 24
            hours until marked as complete (or cancelled). Create a task by
            adding -t to the end of the followup time.
          </p>
        </div>
        <div className="show">
          <img src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-task.png" />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Cancellation (-c)</h2>
          <p>
            Quickly cancel earlier followups on the same thread by including -c
            in your time format. For example: 3days-c@followupthen.com
          </p>
        </div>
        <div className="show">
          <img src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-cancel.png" />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Memorize</h2>
          <p>
            Commit relationships, facts or inspirational quotes to memory using
            spaced repetition, a memorization technique that reminds you at
            increasing intervals.
          </p>
        </div>
        <div className="show">
          <img src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-person-mind.png" />
        </div>
      </div>

      <div className="feature-walk">
        <div className="tell">
          <h2 className="serif blue-accent">Calendar Integration</h2>
          <p>
            Subscribe a calendar feed of your upcoming followups on your
            calendar. This is a generic integration which works on nearly every
            modern calendaring program, including Outlook, Google Calendar,
            Office365, iCal and more.{" "}
          </p>
        </div>
        <div className="show">
          <img src="https://fut-cdn.s3.amazonaws.com/fut-skill-icons-block/icon-cal.png" />
        </div>
      </div>

      <UpgradeButton loggedInUser={loggedInUser} />

      <div style={{ margin: "100px 20px" }}>
        <h3>🚀 Premium Skills</h3>
        <p>
          After upgrading, you can also purchase premium skills from the{" "}
          <Link to="/skills">Skills Directory</Link>.
        </p>
      </div>

      {isTeamOwner(loggedInUser) ? (
        <InviteTeamGreyCallout loggedInUser={loggedInUser} />
      ) : null}

      {/* <BasePlanGrid /> */}

      <div style={{ textAlign: "left", marginBottom: 75 }}>
        <h2 className="serif">Frequently Asked Questions</h2>
        <h4>
          I have more than one email address. Do all of them get upgraded?
        </h4>
        <p>
          Yes! One premium account is all you need. After upgrading, simply
          scroll down to the "Link accounts" section of your settings page and
          add other addresses you own. Not only will they have the premium
          features but the followups scheduled from each of these emails will be
          consolidated under your FollowUpThen account.
        </p>

        <h4>What is an Active Followup?</h4>
        <p>
          An <strong>Active Followup</strong> is one that is scheduled in the
          future. During any given month, a followup may be active for 1 minute
          (1min@fut.io), or it may have been scheduled years ago, but is still
          pending. Each counts as an active followup.
          <a
            href="https://help.followupthen.com/knowledge-base/how-are-active-followups-calculated/"
            target="_blank"
            rel="nofollower noopener noreferrer"
          >
            Read More
          </a>
        </p>

        <h4>What if I am not happy with your service?</h4>
        <p>
          Just send us an email at help@humans.fut.io to let us know. We'll be
          happy to process a refund for your last transaction.
        </p>

        <h4>Do you offer discounts for nonprofits?</h4>
        <p>
          If you are a 501(c)3 organization we are happy to grant you an
          additional 2 months free on your first year's subscriptions. We do not
          yet have the ability to charge different monthly rates for different
          organizations.
        </p>
      </div>

      <div>
        👋 Questions? Look at our{" "}
        <a
          className="text-muted"
          href="https://help.followupthen.com/knowledge-base/pricing-guide/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          Pricing Guide
        </a>{" "}
        or send us an email:{" "}
        <a href="mailto:help@humans.fut.io">help@humans.fut.io</a>
        {overrides.Footer ? overrides.Footer : null}
      </div>
    </ModalSimple>
  );
};
