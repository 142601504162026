import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { logger } from "./lib/Logger";
import { ReactComponent as Exclam } from "./images/Exclam.svg";
// import AppSandbox from './AppSandbox';
// import registerServiceWorker from './registerServiceWorker';

class ErrorBoundary extends React.Component {
  state = {};
  componentDidCatch(error, info) {
    // google analytics
    this.setState({
      error
    });
  }
  render() {
    if (this.state.error) {
      logger.log("web app crash", {
        level: "error",
        data: { error: this.state.error }
      });
      return (
        <div
          style={{
            padding: 50,
            color: "#333",
            fontSize: 14,
            fontFamily: "Lato, Helvetica, Arial",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 500,
            height: 500,
            margin: "auto",
            marginTop: "17vh",
            textAlign: "center"
          }}
        >
          <Exclam />

          <div>
            <h1 style={{ fontFamily: "Lora" }}>
              Oops! That didn't go as expected{" "}
            </h1>
            <p>
              If this continues to happen, feel free to involve a human:
              help@humans.fut.io
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);
// registerServiceWorker();

// ReactDOM.render((<AppSandbox />), document.getElementById('root'));
// registerServiceWorker();
