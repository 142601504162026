import React from "react";

const DocsLink = props => {
  return (
    <span>
      {" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.href}
        className={props.className || "docs"}
        style={{
          fontSize: 10,
          margin: 7,
          textTransform: "lowercase",
          ...props.style
        }}
      >
        <span className="glyphicon glyphicon-book"> </span>{" "}
        {props.linkText || "Docs"}
      </a>{" "}
    </span>
  );
};

export default DocsLink;
