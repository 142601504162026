import React from "react";
import { Route, Switch, Link, NavLink } from "react-router-dom";
import MailBotList from "./MailBotList";
import MailBotDetailsDev from "./MailBotDetailsDev";
import NavLeft from "./NavLeft";
import { ExportOutlined } from "@ant-design/icons";

const DevPortal = props => (
  <div style={{ maxWidth: 1000, marginLeft: "auto", marginRight: "auto" }}>
    <div className="col-sm-2 subnav">
      <NavLeft>
        <li>
          <NavLink to="/developer" exact>
            My Skills
          </NavLink>
        </li>
        <li>
          <NavLink to="/developer/create">New Skill</NavLink>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://fut.readme.io/"
          >
            Docs <ExportOutlined />
          </a>
        </li>
      </NavLeft>
    </div>
    <div className="col-sm-8">
      <Switch>
        <Route
          path="/developer/create/"
          render={({ match, history }) => {
            return <MailBotDetailsDev history={history} />;
          }}
        />

        <Route
          path="/developer/skills/:id"
          render={({ match, history }) => (
            <MailBotDetailsDev id={match.params.id} history={history} />
          )}
        />
        <Route
          render={() => (
            <div>
              <h1
                style={{ fontFamily: "Lora", marginTop: 20, marginBottom: 5 }}
              >
                My Skills
              </h1>
              <MailBotList owner="self" loggedInUser={props.loggedInUser} />
            </div>
          )}
        />
      </Switch>
    </div>
  </div>
);

export default DevPortal;
