import { mailbotsAdminBrowser, sendGtmEvent } from "../lib/utils";
import { message as antMessage } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { mailbotIsInstalled } from "../lib/utils";
import * as _ from "lodash";
import nprogress from "nprogress";
import { logger } from "../lib/Logger";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

/**
 * Plans are defined in Stripe and the Core API
 * [{
            "name": "professional",
            "stripe_id": "professional",
            "limits": {
                "active_futs": 1000,
                "people": 500
            },
            "base_plan": true,
            "monthly_price_cent": 600,
            "plan_key": "basePlan",
            "billing_interval": "monthly"
        }
 */
let cachedStripePlans = [];
export const cacheStripeBillingPlans = async () => {
  if (cachedStripePlans.length) return cachedStripePlans;
  return mailbotsAdminBrowser
    .getAllPlans()
    .then(res => {
      const { plans } = res;
      cachedStripePlans = plans;
      return cachedStripePlans;
    })
    .catch(e => {
      logger.log("error fetching cached plans", {
        level: "error",
        error_str: e.message
      });
      // antMessage.error("error loading cached plans:" + e.message);
    });
};

const getCachedPlans = (stripePlans = null) => {
  if (stripePlans) return stripePlans;
  if (cachedStripePlans && cachedStripePlans.length) return cachedStripePlans;
  console.log("Warning: Cached plans not found");
  cacheStripeBillingPlans();
  // debugger; // <-- Should not fire.
  return [];
};

// returns both monthly + annual StripeIds for a given plan
export const getStripeIdsForPlan = (planKey, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  return allPlans
    .filter(plan => plan.plan_key === planKey)
    .map(plan => plan.stripe_id);
};

export const getPlanKeyFromStripeId = (stripeId, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  const foundPlan = allPlans.find(plan => plan.stripe_id === stripeId);
  if (foundPlan) return foundPlan.plan_key;
  return null;
};

export const getLimitsForPlanKey = (planKey, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  if (!planKey) planKey = "free";
  const plans = allPlans.filter(plan => plan.plan_key === planKey);
  if (!plans || !plans[0]) return {};
  const planLimits = plans[0].limits;
  // Make sure matching plan keys (ex: monthly, yearly) have the same limits
  const allHaveSameLimits = plans.every(p => _.isEqual(p.limits, planLimits));
  if (!allHaveSameLimits)
    console.error(`Limits for planKey ${planKey} do not have same limits`);
  return planLimits;
};

// @todo - this will change when plans API is created
export const isBasePlan = (stripeId, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  const baseStripeIds = [
    ...getStripeIdsForPlan("oldPremium", allPlans),
    ...getStripeIdsForPlan("basePlan", allPlans),
    ...getStripeIdsForPlan("v3_base", allPlans),
    ...Object.keys(legacyStripePlanPrices)
  ];
  return baseStripeIds.includes(stripeId);
};

// check if this key exists. note: It also must be in config.yml in core-api, not checked here
export const planKeyExists = (planKey, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  return !!allPlans.find(plan => plan.plan_key === planKey);
};

// "å la carte" plans are for one, isolated skill. Indicated, by contention, with a plan key exactly matching the subdoamin,
export const hasALaCartePlan = (skillFlag, stripePlans = null) =>
  planKeyExists(skillFlag, stripePlans);

// check if this key exists. note: It also must be in config.yml in core-api, not checked here
export const planExistsForFlag = (flag, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  return !!allPlans.some(
    plan =>
      plan.limits && plan.limits.skills && plan.limits.skills.includes(flag)
  );
};

// get the proper stripe plan id
export const getStripePlanId = (planKey, billAnnually, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  if (!planKey) throw Error("getStripePlan is missing planKey");
  const interval = billAnnually ? "yearly" : "monthly";
  const stripePlan = allPlans.find(
    p => p.plan_key === planKey && p.billing_interval === interval
  );
  if (stripePlan) return stripePlan.stripe_id;
  return null;
};

// Temporarily honor legacy V2 pricing plans
const legacyStripePlanPrices = {
  personal: 2,
  premium: 2,
  professional: 5,
  professional_yearly: 4,
  company: 9,
  company_yearly: 7.16
};

// get price of a plan by iterating through everything until found
export const getPriceFromStripeId = stripeId => {
  if (legacyStripePlanPrices[stripeId]) return legacyStripePlanPrices[stripeId];
  const plan = cachedStripePlans.find(plan => plan.stripe_id === stripeId);
  if (plan) return plan.monthly_price_cent / 100;
  return 0;
};

// conventionally, the plan key matches skill subdomain / flag
export const getPriceFromPlanKey = (
  planKey,
  billAnnually,
  stripePlans = null
) => {
  const allPlans = getCachedPlans(stripePlans);
  const skillPrice = getStripePlanPrice(planKey, billAnnually, allPlans);
  return skillPrice;
};

// get total prices from array of plans
export const getPriceForAugmentedPlans = plans => {
  if (!Array.isArray(plans)) return 0;
  return plans.reduce((total, plan) => {
    if (plan.monthly_price_cent) {
      return total + plan.monthly_price_cent / 100;
    } else if (plan.monthly_price_cent === 0) {
      return total + 0;
    } else if (plan.stripe_plan_id) {
      return total + getPriceFromStripeId(plan.stripe_plan_id);
    } else {
      console.warn("Could not get plan price");
      return total + 0;
    }
  }, 0);
};

// get the total price of all team members plans in account
export const calculateTeamTotalPrice = (loggedInUser, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  const subscriberDetails = getTeamSubscriberDetails(loggedInUser);
  const totalPrice = subscriberDetails.reduce((total, userDetails) => {
    const plans = augmentPlansWithDefaults(userDetails.plans, allPlans);
    const newTotal = total + getPriceForAugmentedPlans(plans);
    return newTotal;
  }, 0);
  return Math.round(totalPrice * 100) / 100;
};

// get the proper stripe plan price
// if overrideStripePlans isn't passed, global cachedStripePlans is used.
export const getStripePlanPrice = (
  planKey,
  billAnnually,
  stripePlans = null
) => {
  const allPlans = getCachedPlans(stripePlans);
  const interval = billAnnually ? "yearly" : "monthly";
  const plan = allPlans
    .filter(plan => !plan.archived)
    .find(
      plan => plan.plan_key === planKey && plan.billing_interval === interval
    );
  if (plan) {
    return plan.monthly_price_cent > 0
      ? plan.monthly_price_cent / 100
      : "(contact us)";
  } else {
    return "(contact us)"; // Assume it's free  if we don't have a matching add-on plan
  }
};

// ex: company, company_yearly, professional, professional_yearly, etc
const legacyPlanIsYearly = stripePlanId => {
  if (stripePlanId === "personal" || stripePlanId === "premium") return true;
  return stripePlanId && stripePlanId.includes("yearly");
};

// is user currently on monthly annual plan. Note: plans have to all share the same billing interval
export const userIsBillingAnnually = (loggedInUser, stripePlans = null) => {
  if (!loggedInUser || !loggedInUser.billing) return true;
  const myPlans = getMyPlans(loggedInUser, stripePlans);
  if (!myPlans || !myPlans.length) return true;
  // a free user can sometimes plans with a null key
  if (
    myPlans.every(
      plan => plan.plan_key === null && plan.stripe_plan_id === null
    )
  )
    return true;

  // account for legacy plans
  if (myPlans.some(plan => legacyPlanIsYearly(plan.stripe_plan_id)))
    return true;

  // non-legacy plans
  const isBillingAnnually = myPlans.some(
    plan => plan.billing_interval === "yearly"
  );
  return isBillingAnnually;
};

// get all of a user's paid plans with the stock limits as defined in config.yml
// note that a user can have modified limits which are not shown by this fn.
// The free plan is not included, but it grant features
export const getMyPlans = (loggedInUser, stripePlans = null) => {
  const subscriberDetails = getMySubscriberDetails(loggedInUser);
  if (!subscriberDetails || !subscriberDetails.plans) return [];
  let plans = subscriberDetails.plans.filter(p => !!p.plan_key);
  if (!plans.length) return [];
  const augmentedPlans = augmentPlansWithDefaults(plans, stripePlans);
  return augmentedPlans;
};

// get a specific user's plans
export const getPlansByUserId = (userId, loggedInUser, stripePlans = null) => {
  const subscriberDetails = getSubscriberDetailsByUserId(userId, loggedInUser);
  if (!subscriberDetails) return [];
  const plans = subscriberDetails.plans;
  const augmentedPlans = augmentPlansWithDefaults(plans, stripePlans);
  return augmentedPlans;
};

// suppliment an array of plans with full plan data
const augmentPlansWithDefaults = (plans, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  const augmentedPlans = plans
    .map(plan => {
      const fullPlan = allPlans.find(
        cp => cp.stripe_id === plan.stripe_plan_id
      );
      if (fullPlan) return fullPlan;
      return plan;
    })
    .filter(p => p); // remove empties
  return augmentedPlans;
};

// get only my subscriber_details
export const getMySubscriberDetails = loggedInUser => {
  return getTeamSubscriberDetails(loggedInUser).find(
    sd => sd.userid == loggedInUser.id
  );
};

// get only subscriber_details for a given userId
export const getSubscriberDetailsByUserId = (userId, loggedInUser) => {
  return getTeamSubscriberDetails(loggedInUser).find(sd => sd.userid == userId);
};

// get subscriber_details, which contains a breakdown of all employees and their plans
export const getTeamSubscriberDetails = loggedInUser => {
  if (
    !loggedInUser ||
    !loggedInUser.billing ||
    !Array.isArray(loggedInUser.billing.subscriber_details)
  ) {
    return [];
  }
  return loggedInUser.billing.subscriber_details;
};

// get names of all plans user installed
export const getUserPlanNames = (loggedInUser, stripePlans = null) => {
  return getMyPlans(loggedInUser, stripePlans).map(
    plan => plan.name || plan.stripe_plan_id
  );
};

// check if a user has a particular add-on, either monthly or annually
export const userHasStripeAddOnPlan = (
  planKey,
  loggedInUser,
  stripePlans = null
) => {
  if (!planKey || loggedInUser) Error("planKey and loggedInUser required");
  const allPlans = getCachedPlans(stripePlans);
  const myPlans = getMyPlans(loggedInUser, allPlans);
  return myPlans.some(plan => plan.plan_key === planKey);
};

// check if a user has a particular add-on, either monthly or annually
export const userHasStripePlanId = (
  loggedInUser,
  stripePlanId,
  stripePlans = null
) => {
  if (!stripePlanId || loggedInUser)
    Error("stripePlanId and loggedInUser required");
  const allPlans = getCachedPlans(stripePlans);
  const myPlans = getMyPlans(loggedInUser, allPlans);
  return myPlans.some(plan => plan.stripe_id === stripePlanId);
};

// The personal assistant plan (aka "Professional Package")  is actually the basic plan, plus the personal assistant plan. Simpler to
// show to the user this way
export const isOnFreePlan = loggedInUser => {
  if (
    !loggedInUser ||
    !loggedInUser.billing ||
    !Array.isArray(loggedInUser.billing.subscriber_details)
  ) {
    return true;
  }
  const userPlans = loggedInUser.billing.subscriber_details.find(
    sub => Number(sub.userid) === Number(loggedInUser.id)
  );
  if (
    typeof userPlans !== "object" ||
    !userPlans.plans ||
    !Array.isArray(userPlans.plans) ||
    !userPlans.plans.length
  )
    return true;
  // legacy plans may have plan_key === null, but stripe_plan_id set to the legacy plan
  const hasFreePlan = userPlans.plans.some(
    p => !p.plan_key && (!p.stripe_plan_id || p.stripe_plan_id === "0") // can be 'null', empty string '', or "0"
  ); // @todo plan_key === "free"  https://github.com/mailbots/fut-core-api/issues/2639#issuecomment-715349572
  if (hasFreePlan) return true;
  return false;
};

export const isPaying = loggedInUser => !isOnFreePlan(loggedInUser);

// Legacy FUT V2 plans...
export const hasLegacyPersonalPlan = loggedInUser => {
  if (!loggedInUser || !loggedInUser.billing) return;
  return (
    loggedInUser.billing.base_plan_id === "personal" ||
    loggedInUser.billing.base_plan_id === "premium" ||
    loggedInUser.billing.base_plan_id === "personal_yearly"
  );
};

export const hasLegacyProPlan = loggedInUser => {
  if (!loggedInUser || !loggedInUser.billing) return;
  return (
    loggedInUser.billing.base_plan_id === "old_premium" ||
    loggedInUser.billing.base_plan_id === "professional" ||
    loggedInUser.billing.base_plan_id === "professional_yearly"
  );
};

export const hasLegacyCompanyPlan = loggedInUser => {
  if (!loggedInUser || !loggedInUser.billing) return;
  return (
    loggedInUser.billing.base_plan_id === "company" ||
    loggedInUser.billing.base_plan_id === "company_yearly"
  );
};

export const hasAnyBillingPlan = () => {};

export const hasBasePlan = myPlans => {
  return Array.isArray(myPlans) && myPlans.some(p => p.plan_key === "v3_base");
};
// user has the base plan, but not the personalAst addon
export const isOnBasicPlan = (loggedInUser, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  if (!loggedInUser || !loggedInUser.billing) return false;
  const myPlans = getMyPlans(loggedInUser, stripePlans);

  const hasPersonalAstAddon = userHasStripeAddOnPlan(
    "personalAst",
    loggedInUser,
    allPlans
  );
  return hasBasePlan(myPlans) && !hasPersonalAstAddon;
};

export const isOnLegacyPlan = loggedInUser => {
  return !hasBasePlan(getMyPlans(loggedInUser)) && !isOnFreePlan(loggedInUser);
};

// user must have a base plan to have the personalAst plan
export const isOnPersonalAstPlan = loggedInUser =>
  userHasStripeAddOnPlan("personalAst", loggedInUser);

// if they are not an employee, they are, or could potentiall be a team owner
// note this returns true for everyone on a legacy company plan
export const isTeamOwner = loggedInUser => {
  if (!loggedInUser) return false;
  const isTeamOwner = loggedInUser.team_owner;

  const isOrCouldBeTeamOwner =
    isPaying(loggedInUser) && !isEmployee(loggedInUser);

  return (
    isOrCouldBeTeamOwner || (hasLegacyCompanyPlan(loggedInUser) && isTeamOwner)
  );
};

// a "team owner" may or may not have employees yet. See isTeamOwner fn above.
export const hasEmployees = loggedInUser => {
  return getTeamSubscriberDetails(loggedInUser).length > 1;
};

export const numEmployees = loggedInUser => {
  return getTeamSubscriberDetails(loggedInUser).length;
};

// is they have a team_admin, assume it's an employee
// Team member excludes teamOwner
export const isEmployee = loggedInUser => {
  if (!loggedInUser || !loggedInUser.billing) return false;
  if (loggedInUser.team_owner) return false;
  const teamAdminExists = loggedInUser.billing.team_admin;
  const teamAdminIsSelf = loggedInUser.id === loggedInUser.billing.team_admin;
  if (teamAdminExists && !teamAdminIsSelf) return true;
  return false;
};

export const getTeamAdmin = loggedInUser =>
  (loggedInUser && loggedInUser.billing && loggedInUser.billing.team_admin) ||
  {};

export const getRemainingBudgetCent = loggedInUser => {
  return loggedInUser.billing && loggedInUser.billing.budget_remaining_cent
    ? loggedInUser.billing.budget_remaining_cent
    : 0;
};

// is they have a team_admin, assume it's an employee
// Team member excludes teamOwner
export const isSoloPaidUser = loggedInUser => {
  const ownsMultipleSubscribers =
    getTeamSubscriberDetails(loggedInUser).length > 1;
  return (
    !ownsMultipleSubscribers &&
    !isOnFreePlan(loggedInUser) &&
    !isEmployee(loggedInUser)
  );
};

// get billing limits for only this user (company users show limits for all users)
// free users default to the current active free plan limits, not their subscription limits JSON
export const getFeatureLimits = (loggedInUser, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  const defaultLimits = getLimitsForPlanKey("free", allPlans);
  if (
    !loggedInUser ||
    !loggedInUser.billing ||
    !Array.isArray(loggedInUser.billing.subscriber_details)
  )
    return [defaultLimits];
  const thisUserDetails = loggedInUser.billing.subscriber_details.filter(
    sd => sd.userid == loggedInUser.id // @todo - match types
  );
  // defensively return limits
  if (!thisUserDetails || !thisUserDetails.length) {
    return defaultLimits;
  } else if (thisUserDetails.length > 1) {
    logger.log("Duplicate billing limits detected", {
      level: "error",
      data: { thisUserDetails }
    });
    return [defaultLimits]; // default to free limits on failure
  } else if (thisUserDetails.length === 1) {
    return thisUserDetails[0];
  } else {
    logger.log("Unexpected error loading billing plans.", {
      level: "error",
      data: {
        loggedInUser,
        thisUserDetails
      }
    });
  }
  return [defaultLimits]; // default to free limits on failure
};

export const getAllowedSkills = (loggedInUser, stripePlans = null) => {
  const featureLimits = getFeatureLimits(loggedInUser, stripePlans);
  if (!featureLimits || !featureLimits.limits) return [];
  if (!featureLimits.limits.skills) return [];
  return featureLimits.limits.skills;
};

// return specific key from `subscriptionlimits` db field
export const userGetFeatureLimit = ({
  feature,
  loggedInUser,
  stripePlans = null
}) => {
  const featureLimits = getFeatureLimits(loggedInUser, stripePlans);
  if (!featureLimits || !featureLimits.limits) return false;
  if (!featureLimits.limits[feature]) return false;
  return featureLimits.limits[feature];
};

// User user authorized to user the current skill?
// if it's not in a plan, the user has it for free
export const userPaidForSkill = (
  loggedInUser,
  skillFlag,
  stripePlans = null
) => {
  if (process.env.REACT_APP_BILLING_ENFORCED !== "yes") {
    console.warn("Billing is not enabled");
    logger.log("billing  not enabled", {
      level: "warn"
    });
    return true;
  }
  const allPlans = getCachedPlans(stripePlans);
  if (!loggedInUser || !loggedInUser.billing) return false;
  if (!allPlans || !allPlans.length) return false;

  // Note: if if it does not exist in any package, or only exists in free package, it is unrestricted
  const isRestrictedSkill = flag => {
    // account for plans which increase limits other than skills, for example, increasing active_futs
    const plansWithFlag = allPlans.filter(p => p.plan_key === flag);
    const affectsActiveFutLimits = plansWithFlag.some(
      p => !!p && p.limits && p.limits.active_futs
    );
    if (affectsActiveFutLimits) return true;

    const skillFlagExistsOnPaidPlans = allPlans
      .filter(p => p.stripe_id !== "free")
      .some(
        plan =>
          plan.limits &&
          Array.isArray(plan.limits.skills) &&
          plan.limits.skills.includes(flag)
      );
    if (skillFlagExistsOnPaidPlans) return true;
    return false;
  };
  if (!isRestrictedSkill(skillFlag)) return true;
  const allowedSkills = getAllowedSkills(loggedInUser, stripePlans);
  if (!Array.isArray(allowedSkills)) return false;

  // all-access users
  if (allowedSkills.some(flag => flag === "*")) return true;

  const hasUserPaidForSkill = allowedSkills.includes(skillFlag);

  return hasUserPaidForSkill;
};

// get an array of plans user can buy to get the current skill
export const getPlansForSkill = (loggedInUser, skillFlag) => {
  const plansWithLimits = cachedStripePlans.filter(
    p => p.limits && p.limits.skills && p.limits.skills.includes(skillFlag)
  );
  return plansWithLimits;
};

// these are all plans that are dedicate to one and only one skill. (Not packages).
// This is the used on the context of multiple plans for one skil. If a user upgrades an
// add-on plan, this method is used to downgrade all other dedicated skill plans
export const getDedicatedPlansForSkill = (loggedInUser, skillFlag) => {
  return (
    getPlansForSkill(loggedInUser, skillFlag)
      // filter for only single-flag plans (ex: skills: [sms])
      .filter(p => p.limits && p.limits.skills && p.limits.skills.length === 1)
      // hide archived plans
      .filter(p => !p.archived)
  );
};

// plans with exactly 2 variants are assumed to have only
// annual and monthly costs.
export const hasMultiplePlans = (loggedInUser, skillFlag) => {
  const plans = getDedicatedPlansForSkill(loggedInUser, skillFlag);
  return plans.length > 2;
};

// get usage for only this user (copmany users show more limits)
export const getFeatureUsage = loggedInUser => {
  if (!loggedInUser) return {};
  return loggedInUser.feature_usage_monthly;
};

/**
 * CheckBilling Limits
 * There are three knids of billing limits:
 * 1. Number of FUTs (controlled by base plan)
 * 2. Penople Add-on plan
 * 3. Skill add-on plans
 */
// @todo – DRY
export const checkSkillBillingLimits = (skill, billing) => {
  if (!skill) return false;
  const pkg = cachedStripePlans.find(pkg => {
    return (
      pkg.limits &&
      pkg.limits.skills &&
      pkg.limits.skills.includes(skill.subdomain)
    );
  });

  if (!pkg) return false;

  return (
    Array.isArray(billing.subscriber_details) &&
    Array.isArray(billing.subscriber_details[0].plans) &&
    !billing.subscriber_details[0].plans.some(plan => plan === pkg.stripePlanId)
  );
};

export const checkActiveFutBillingLimits = (
  loggedInUser,
  stripePlans = null
) => {
  if (process.env.REACT_APP_BILLING_ENFORCED !== "yes") return "ok";
  let remainingFuts;
  try {
    const featureLimits = getFeatureLimits(loggedInUser, stripePlans);
    let availableActiveFuts =
      (featureLimits && featureLimits.limits.active_futs) || 0;

    const activeFuts =
      (loggedInUser.feature_usage_monthly &&
        loggedInUser.feature_usage_monthly.active_futs) ||
      0;
    remainingFuts = availableActiveFuts - activeFuts;
  } catch (e) {
    console.error("error getting limits", e.message);
    remainingFuts = 50; // default
  }

  // check limits
  let status = "ok";
  if (remainingFuts < 10) status = "warn";
  if (remainingFuts === 0) status = "upgrade_required";

  return { remainingFuts, status };
};

export const checkPeopleBillingLimits = loggedInUser => {
  const billing = loggedInUser.billing;

  const subscribedSkills =
    (billing.subscriber_details &&
      billing.subscriber_details[0] &&
      billing.subscriber_details[0].limits.skills) ||
    [];

  return subscribedSkills.some(sk => sk === "people");
};

export const employeeHasBudgetForSkill = (
  loggedInUser,
  flag,
  stripePlans = null
) => {
  const budgetLeft = getRemainingBudgetCent(loggedInUser) / 100;
  const cost = getPriceFromPlanKey(
    flag,
    userIsBillingAnnually(loggedInUser, stripePlans),
    stripePlans
  );
  const hasBudget = budgetLeft >= cost;
  if (hasBudget) {
    logger.log("billing: employee has budget for skill");
  } else {
    logger.log("billing: employee does not have budget for skill");
  }
  return hasBudget;
};

export const upgradeLegacyPlanToV3BasePlan = async ({
  loggedInUser,
  billingAnnually
}) => {
  if (!loggedInUser) throw Error("loggedInuser not found");
  return mailbotsAdminBrowser.changeBasePlan({
    stripeId: getStripePlanId("v3_base", billingAnnually)
  });
};

export const confirmBillingAmount = ({
  planKey,
  stripePlanId,
  billingAnnually
}) => {
  let recurringPrice;
  if (planKey) {
    const monthlyPrice = getStripePlanPrice(planKey, billingAnnually);
    recurringPrice = billingAnnually ? monthlyPrice * 12 : monthlyPrice;
  } else if (stripePlanId) {
    recurringPrice = getPriceFromStripeId(stripePlanId);
  }
  const isConfirmed =
    window.confirm(`This will begin your subscription of $${recurringPrice}${
      billingAnnually ? "/yr" : "/mo"
    }. \
Credits from any partially used subscriptions will be applied to your purchase.

Ok to proceed?

You can cancel any time from 'Account > Billing'

`);
  if (!isConfirmed) return false;
  logger.log(`billing: confirmed amount ${planKey}`);
  return true;
};

/**
 * Because the personal assistant / professional package plan is basically an add-on, subscribing to the plan requires
 * adding two products: the base plan, plus the add-on. If user already has the base plan, we just
 * add the add-on. (Composing plans like this allows flexibility between team members).
 * 0. If the user is on a legacy plan, first, switch them to the v3 base
 * 1. Free user chooses free plan: Start checkout with base plan
 * 2. Free user chooses person assistnat: Start checkout with two items: base plan and personal ast add-on
 * 3. Existing premium user upgrades to personal assistant / professional package plan: Call "subscribeToAddOns"
 */
export const handleUpgradeClick = async (
  planKey,
  loggedInUserParam,
  billingAnnually,
  reloadUser,
  history
) => {
  logger.log(`billing: upgrade btn clicked ${planKey}`);
  sendGtmEvent("upgrade_clicked", {
    category: "upgrade",
    action: "upgrade_clicked",
    label: planKey
  });

  // open happy dialog, confetti, etc
  const celebrateUpgrade = upgradeLevel => {
    if (history && typeof history.push === "function") {
      history.push(window.location.pathname + "?upgradedPlan=v3_base");
    } else {
      window.alert(
        `Thanks for upgrading to the ${upgradeLevel} plan 🎉! Please contact us if you have any questions.`
      );
    }
  };

  const confirmCompanyHasMigrated = loggedInUser => {
    if (isEmployee(loggedInUser) && isOnLegacyPlan(loggedInUser)) {
      logger.log("billing: employee team not yet migrated");
      window.alert(
        "Please tell your team administrator to visit app.followupthen.com to upgrade your team to our new and improved system."
      );
      return false;
    }
    return true;
  };

  const confirmLegacyPlanChanges = loggedInUser => {
    let confirmMessage = `To confirm, this will replace your legacy (${getUserPlanNames(
      loggedInUser
    ).join(
      ","
    )}) plan with our new billing system, modernizing your account and \
unlocking the ability to install new skills.\n\n`;
    if (!hasLegacyPersonalPlan(loggedInUser) && planKey !== "personalAst") {
      confirmMessage += `Please note that our new Base Plan does not come with Response Detection or SMS \
reminders. These are now premium skills and can be added form the Skills Marketplace.\n\n`;
    }
    if (!isEmployee(loggedInUser) && !hasLegacyCompanyPlan(loggedInUser)) {
      confirmMessage += `We've been hard at work continuing to improve \
FollowUpThen. Your subscription directly helps to fund our progress 🙌\n\n`;
    }

    confirmMessage += `Note: This action cannot be undone.`;

    const confirmed = window.confirm(confirmMessage);
    if (confirmed) {
      logger.log("billing: confirm legacy plan change ok");
    } else {
      logger.log("billing: confirm legacy plan change cancelled");
    }
    return confirmed;
  };

  // reloaded in the case of legacy user
  let loggedInUser = loggedInUserParam;
  try {
    if (isOnLegacyPlan(loggedInUser)) {
      try {
        nprogress.start();
        if (!confirmCompanyHasMigrated(loggedInUser)) return;
        // if (!confirmLegacyPlanChanges(loggedInUser)) return;
        if (!confirmBillingAmount({ planKey, billingAnnually })) return;
        const upgradeRes = await upgradeLegacyPlanToV3BasePlan({
          loggedInUser,
          billingAnnually
        });
        if (!upgradeRes) return;
        // now that user is on V3 base, reload them
        loggedInUser = await reloadUser();

        // if they chose base plan, nothing more to do..
        if (planKey === "v3_base") {
          nprogress.done();
          celebrateUpgrade("v3_base");
          logger.log("billing: upgraded legacy user to v3_base plan");
          return;
        }
      } catch (e) {
        console.error(e);
        logger.log("billing: legacy plan upgrade error", {
          level: "error",
          data: { error_msg: e.message }
        });
        window.alert(
          "Sorry, we could not upgrade your account. Please contact help@humans.fut.io for help. The error was " +
            e.message
        );
      }
    }

    //  Removing this warning for now on the assumption that it creates unnecessary FOMO for
    //  who don't understand what their legacy "custom limits" might be. (based on usage stats)
    // if (isOnCustomPlan(loggedInUser)) {
    //   let confirmMessage = `To confirm, this will reset your limits to the those from your selected plan.`;
    //   confirmMessage += `\nNote: This action cannot be undone.`;
    //   const continueWithUpgrade = window.confirm(confirmMessage);
    //   if (!continueWithUpgrade) {
    //     logger.log("billing: upgrade custom plan cancelled");
    //   } else {
    //     logger.log("billing: upgrade custom plan confirmed");
    //   }
    //   if (!continueWithUpgrade) return;
    // }

    if (planKey === "v3_base" && isOnBasicPlan(loggedInUser)) {
      // handle legacy user...
      if (isOnBasicPlan(loggedInUser)) {
        window.alert(
          "It looks like you already have a base plan. Please contact humans@fut.io for help"
        );
      }
    } else if (planKey === "v3_base" && isOnFreePlan(loggedInUser)) {
      logger.log("billing: free user starts checkout for base plan");
      sendGtmEvent("clicked_upgrade_base_plan", {
        category: "upgrade",
        action: "clicked_upgrade_base_plan"
      });
      nprogress.start();
      await startStripeCheckout({
        stripePlanIds: [getStripePlanId("v3_base", billingAnnually)],
        upgradeLevel: "v3_base"
      });
    } else if (planKey === "personalAst" && isOnFreePlan(loggedInUser)) {
      logger.log("billing: free user starts checkout for personalAst plan");
      const stripePlanIds = [
        getStripePlanId("personalAst", billingAnnually),
        getStripePlanId("v3_base", billingAnnually)
      ];
      if (isOnFreePlan(loggedInUser)) {
        await startStripeCheckout({
          stripePlanIds,
          upgradeLevel: "personalAst"
        });
      }
    } else if (planKey === "personalAst" && isOnBasicPlan(loggedInUser)) {
      logger.log(
        "billing: base plan user started checkout for personalAst plan"
      );
      // -sms and -r are included in personal assistant / professional package plan. Idempotently remove them.
      if (
        userHasStripeAddOnPlan("sms", loggedInUser) ||
        userHasStripeAddOnPlan("r", loggedInUser)
      ) {
        logger.log("billing: canceling sms and r during personalAst upgrade");
        const smsPlanId = getStripePlanId("sms", billingAnnually);
        const rPlanId = getStripePlanId("r", billingAnnually);
        await mailbotsAdminBrowser.removeSubscriptionAddons({
          userId: loggedInUser.id,
          plans: [smsPlanId, rPlanId]
        });
      }
      if (!confirmBillingAmount({ planKey, billingAnnually })) return;
      await mailbotsAdminBrowser.addSubscriptionAddons({
        userId: loggedInUser.id,
        plans: [getStripePlanId("personalAst", billingAnnually)]
      });
      await reloadUser();
      celebrateUpgrade("personalAst");
      logger.log("billing: base plan user upgraded to personalAst");

      // window.setTimeout(() => window.location.reload(), 1000);
    } else {
      // they are clicking an add-on plan, ex: a Skill
      if (isOnFreePlan(loggedInUser)) {
        window.alert("Please choose a base plan first");
        return;
      }
      if (!planKeyExists(planKey)) {
        window.alert("Invalid planKey. Contact humans@fut.io for help");
        return;
      }
      if (!confirmBillingAmount({ planKey, billingAnnually })) return;
      mailbotsAdminBrowser
        .addSubscriptionAddons({
          userId: loggedInUser.id,
          plans: [getStripePlanId(planKey, userIsBillingAnnually)]
        })
        .then(() => {
          logger.log(`billing: subscribed to skill ${planKey}`);
          sendGtmEvent("upgraded_addon_skill", {
            category: "upgrade",
            action: "upgraded_addon_skill",
            label: planKey
          });
          antMessage.success(
            "Subscribed! Manage this subscription from your billing page."
          );
        })
        .catch(e => {
          logger.log(`billing: subscribed to skill error ${planKey}`, {
            level: "error",
            error_msg: e.msg
          });
          console.error("Error", e);
          antMessage.error(
            "Something went wrong. Please contact help@humans.fut.io for help"
          );
        });
    }
  } catch (e) {
    console.error(e);
    antMessage.error(
      "error subscribing: " +
        e.message +
        "Please contact help@humans.fut.io for help"
    );
  }
};

/**
 * Subscribe a user directly to a stripePlanId
 * @param {*} loggedInUser
 * @param {*} stripePlanId
 * @returns Promise
 */
export const subscribeToAddonPlan = async ({
  loggedInUser,
  stripePlanId,
  planKey
}) => {
  try {
    const planKey = getPlanKeyFromStripeId(stripePlanId);
    const billingAnnually = userIsBillingAnnually(loggedInUser);

    // We first remove other add=ons, then add the new one.
    // Note: The order matters. We must first remove the existing add-on, then add the new one
    // See: https://github.com/mailbots/fut-core-api/issues/2850#issuecomment-953469051
    // if this addon has multiple tiers, remove any redundant plans. It is assumed that if a planKey
    // has more than 2 plans (monthly / yearly) then it has multiple tiers.
    const allPlansForSkill = getDedicatedPlansForSkill(loggedInUser, planKey);
    const plansToDowngrade = allPlansForSkill.filter(
      plan => plan.stripe_id !== stripePlanId
    );
    await mailbotsAdminBrowser.removeSubscriptionAddons({
      userId: loggedInUser.id,
      plans: plansToDowngrade.map(p => p && p.stripe_id)
    });
    console.log("downgrading other plans");
    logger.log(`billing: downgrading related dedicated plans for ${planKey}`, {
      foundPlans: plansToDowngrade.map(p => p.stripe_id)
    });

    // add the new plan
    await mailbotsAdminBrowser.addSubscriptionAddons({
      userId: loggedInUser.id,
      plans: [stripePlanId]
    });
    logger.log(
      `billing: subscribed to skill ${planKey} ${
        billingAnnually ? "(annually)" : "(monthly)"
      }`
    );
    sendGtmEvent("upgraded_addon_skill", {
      category: "upgrade",
      action: "upgraded_addon_skill",
      label: planKey
    });
    antMessage.success(
      "Subscribed! Manage this subscription from your billing page."
    );
  } catch (e) {
    logger.log(`billing: subscribed to skill error ${planKey}`, {
      level: "error",
      data: { error_msg: e.msg }
    });
    console.error("Error", e);
    window.alert("There was error with the addon");
    throw new Error(e);
  }
};

const friendlyError = error =>
  `Upgrade error: ${
    error.message || "an unexpected billing error"
  }. Please contact help@humans.fut.io if you need help!`;

// Subscribing for first time
export const startStripeCheckout = async ({
  stripePlanIds,
  upgradeLevel = null
}) => {
  if (!Array.isArray(stripePlanIds))
    window.alert("No Stripe Plan Ids provided");
  try {
    const res = await mailbotsAdminBrowser.getStripeCheckoutSession({
      successUrl: `${
        process.env.REACT_APP_MAILBOTS_WEB_APP_BASE
      }/skills?upgradedPlan=${upgradeLevel || stripePlanIds.join(",")}`,
      cancelUrl: `${process.env.REACT_APP_MAILBOTS_WEB_APP_BASE}/skills`,
      stripePlanIds
    });
    const sessionId = res.session_id;

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId
    });
    if (error) {
      antMessage.error(error);
    }
  } catch (e) {
    antMessage.error(friendlyError(e));
  }
};

// get full plan data for all team members
export const getTeamSummary = (
  loggedInUser,
  teamMembers,
  stripePlans = null
) => {
  const allPlans = getCachedPlans(stripePlans);
  if (!loggedInUser || !teamMembers || !teamMembers.length) return [];

  // populate team member plans from loggedInUser (the team owner)
  const teamMembersWithPlans = teamMembers.map(member => ({
    ...member,
    plans: getPlansByUserId(member.id, loggedInUser, allPlans)
  }));

  // calculate budgets from plans
  const teamMembersWithBudgets = teamMembersWithPlans.map(member => {
    const totalBudget = member.budget_cent ? member.budget_cent / 100 : 0;
    const usedBudget = getPriceForAugmentedPlans(member.plans);
    let availableBudget = totalBudget - usedBudget;
    if (availableBudget < 0) availableBudget = 0;
    return {
      ...member,
      availableBudget,
      usedBudget,
      totalBudget
    };
  });

  return teamMembersWithBudgets;
};

// get packages that contain a specific skill (-sms)
// NOTE: This has been constrinted to only return the v3_base plan if it has the skill
export const getPackagesForSkillFlag = (flag, stripePlans = null) => {
  const allPlans = getCachedPlans(stripePlans);
  if (!allPlans) return [];
  const allPackages = allPlans.filter(
    plan =>
      plan.limits &&
      Array.isArray(plan.limits.skills) &&
      plan.limits.skills.includes(flag)
  );

  // constrain to only v3_base package for now
  // https://github.com/mailbots/fut-admin-ui/issues/175
  const v3Base = allPackages.filter(p => p.plan_key === "v3_base");
  // prever v3_base if available, otherwise show other packages where this is available
  // return v3Base.length ? v3Base : allPackages;
  return v3Base.length ? v3Base : [];
};

// a package plan allows users to purchase multiple skill at once
// note: v3_based is the only package at this time.
export const hasPackagePlan = (flag, stripePlans = null) => {
  return getPackagesForSkillFlag(flag, stripePlans).length > 0;
};

// custom plans can be manually set by in the support UI, or if a user has a legacy plan
// this method checks if the user's plan differs.
export const isOnCustomPlan = (loggedInUser, stripePlans = null) =>
  loggedInUser &&
  loggedInUser.billing &&
  loggedInUser.billing.has_custom_limits;

// As of 2020-12-30, we are letting people upgrade to legacy for al imited time...
// @todo - delete this after offer expires
export const showLegacyPlanUpgrade = loggedInUser => {
  const basePlanId =
    loggedInUser && loggedInUser.billing && loggedInUser.billing.base_plan_id;
  // free users are eligible for the legacy upgrade
  if (!basePlanId) return true;
  // A "goodPlan" for this fn is one that is preferred over the legacy plan
  const goodPlans = ["professional", "company", "v3_base"];
  const alreadyHasGoodPlan = goodPlans.some(planId =>
    basePlanId.startsWith(planId)
  );
  if (alreadyHasGoodPlan) return false;
  return true;
};

// Calculate all credits from referring other users.
export const getStoreCredit = loggedInUser => {
  let storeCredit =
    (loggedInUser &&
      loggedInUser.billing &&
      loggedInUser.billing.referral_store_credit) ||
    0;
  // if the user has "referred_by" still set, they have not used their credit from being referred by another user
  if (referralCreditWaitingForFreeUpgrade(loggedInUser)) {
    storeCredit += 5;
  }
  return storeCredit;
};

export const getReferralHash = loggedInUser =>
  loggedInUser && loggedInUser.referral_hash;

export const referralCreditWaitingForFreeUpgrade = loggedInUser => {
  if (!loggedInUser) return false;
  const hasReferralToken = !!loggedInUser.referred_by;
  const notYetBilled =
    isOnFreePlan(loggedInUser) ||
    loggedInUser.billing.subscription_status === "trialing";
  return hasReferralToken && notYetBilled;
};

export const isTrialing = loggedInUser => {
  return getSubscriptionStatus(loggedInUser) === "trialing";
};

export const getSubscriptionStatus = loggedInUser => {
  return loggedInUser && loggedInUser.billing.subscription_status;
};

// upgrade dialog scenarios
export const getSkillUpgradeState = (
  loggedInUser,
  mailbot,
  needsActivation = false,
  stripePlans = null
) => {
  if (!mailbot) return null;
  if (false) {
    // make it easy to reorder conditions
  } else if (
    isEmployee(loggedInUser) &&
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    !employeeHasBudgetForSkill(loggedInUser, mailbot.subdomain, stripePlans)
  ) {
    return "request_budget";
  } else if (
    isPaying(loggedInUser) &&
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    hasALaCartePlan(mailbot.subdomain, stripePlans) &&
    !hasBasePlan(getMyPlans(loggedInUser, stripePlans))
  ) {
    return "upgrade_addon_plan_legacy";
  } else if (
    isPaying(loggedInUser) &&
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    hasALaCartePlan(mailbot.subdomain, stripePlans)
  ) {
    return "upgrade_addon_plan";
  } else if (
    isPaying(loggedInUser) &&
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    hasPackagePlan(mailbot.subdomain, stripePlans)
  ) {
    return "upgrade_package";
  } else if (
    !isPaying(loggedInUser) &&
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) && // "paid for" includes free
    hasPackagePlan(mailbot.subdomain, stripePlans) &&
    !hasALaCartePlan(mailbot.subdomain, stripePlans)
  ) {
    return "offer_package";
  } else if (
    !isPaying(loggedInUser) &&
    // While billing is not being enforced, the user has "paid for" this skill, so addon plan should not be offered
    !userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    hasALaCartePlan(mailbot.subdomain, stripePlans)
  ) {
    return "offer_addon_plan";
  } else if (
    userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    !mailbotIsInstalled(mailbot)
  ) {
    return "install";
  } else if (
    userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    mailbotIsInstalled(mailbot) &&
    needsActivation
  ) {
    return "setup_dialog";
  } else if (
    userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    mailbotIsInstalled(mailbot) &&
    hasMultiplePlans(loggedInUser, mailbot.subdomain, stripePlans) &&
    !needsActivation
  ) {
    return "ok_more_choices";
  } else if (
    userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans) &&
    mailbotIsInstalled(mailbot) &&
    !needsActivation
  ) {
    return "ok";
  } else {
    let debug_arr = [];
    debug_arr.push("mailbotIsInstalled(mailbot)", mailbotIsInstalled(mailbot));
    debug_arr.push(
      "userPaidForSkill(loggedInUser, mailbot.subdomain)",
      userPaidForSkill(loggedInUser, mailbot.subdomain, stripePlans)
    );
    debug_arr.push("this.state.needsActivation", needsActivation);
    debug_arr.push("isPaying(loggedInUser)", isPaying(loggedInUser));
    debug_arr.push(
      "hasPackagePlan(mailbot.subdomain)",
      hasPackagePlan(mailbot.subdomain, stripePlans)
    );
    debug_arr.push(
      "planExistsForFlag(mailbot.subdomain)",
      planExistsForFlag(mailbot.subdomain, stripePlans)
    );
    logger.log("billing: unhandled upgrade state", {
      level: "error",
      data: { debug_arr }
    });
    return "unhandled_upgrade_dialog";
  }
};

// Note: This will be handled primarily on the back-end after https://github.com/mailbots/fut-core-api/issues/2840
// has been completed.
export const migrateBilling = async loggedInUser => {
  const user = {};
  user.opt_into_billing_v3 = true;
  return await mailbotsAdminBrowser
    .userUpdateSelf({ user })
    .then(res => {
      logger.log("successfully migrated to v3 billing");
    })
    .catch(e => {
      logger.log("error migrating to v3 billing", {
        level: "error",
        data: { error_str: e.message }
      });
      console.error("Error migrating billing", e);
      antMessage.error(
        "There was an error migrating your billing. Please contact help@humans.fut.io for help. The error was:" +
          e.message
      );
      throw Error(e);
    });
};
