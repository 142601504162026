import React from "react";
import CreatableSelect from "react-select/lib/AsyncCreatable";
import CreatableSelectInput from "react-select/lib/components/Input";
import chroma from "chroma-js";
import * as _ from "lodash";

const DefaultIndicatorsContainer = () => null;
const MultiSelectDOMInput = props => {
  let readOnly;
  const { selectProps } = props;
  if (selectProps.single && Array.isArray(selectProps.value)) {
    readOnly = selectProps.value.length ? true : false;
  }

  return <CreatableSelectInput {...props} readOnly={readOnly} />;
};

export const MultiSelectInput = ({
  value,
  placeholder = null,
  onChange,
  options,
  loadOptions, // callback fn to return options. If not passed, it just uses options
  inputValue,
  formatCreateLabel = () => {},
  onInputChange,
  labelLengthOffset,
  isMulti = true,
  controlStyle = {},
  className,
  fieldHelp,
  innerRef,
  isValidNewOption,
  onKeyDown,
  onBlur
}) => {
  // Copied from React Select Style example with minor modifications
  const autocompleteStyles = {
    control: styles => ({
      ...styles,
      ...controlStyle,
      border: 0,
      borderBottom: "1px solid #efefef",
      borderRadius: 4,
      boxShadow: 0,
      paddingLeft: labelLengthOffset,
      paddingBottom: 5
    }),
    placeholder: styles => ({
      ...styles,
      color: "rgb(170, 170, 170)"
    }),
    menu: styles => ({
      ...styles,
      zIndex: 9999
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#006e92");
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#006e92"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : "#006e92",
        cursor: isDisabled ? "not-allowed" : "default"
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#006e92");
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        fontSize: "1.2em"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#006e92"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#006e92",
      ":hover": {
        backgroundColor: "#006e92",
        color: "white"
      }
    })
  };

  /**
   * fieldHelp is the tool-tip help icon floating to the right of the field. We use Bootstrap tooltips
   * to make this work which requir this hacky workaround.
   */
  setTimeout(window.activateTooltips, 500);
  const IndicatorsContainer = fieldHelp
    ? () => (
        <span
          className="glyphicon glyphicon-question-sign"
          data-toggle="tooltip"
          data-placement="left"
          title={fieldHelp}
          style={{ color: "#aaa" }}
        ></span>
      )
    : DefaultIndicatorsContainer;

  /**
   * Wrap the native loadOptions wrapper to fix the problem created by the debounced select.
   * In sum: When Lodash's debounced function is called within the debounce period, it returns
   * the value from the last result. So even if the user quickly types "John", for each keystroke,
   * loash returns the result from the query when it was just "J" (first letter).
   * As a workaround: Filter the last returned results based on current input value.
   * See: https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
   */
  const loadFilteredOptions = async inputVal => {
    const opts = loadOptions ? await loadOptions() : options;
    const fOpts = opts.filter(o => {
      if (typeof o.label !== "string" || typeof inputVal !== "string") {
        console.error("loadFilteredOptions received a non-string value");
      }
      return o.label.toLowerCase().startsWith(inputVal.toLowerCase());
    });

    const uniqueOptsMap = new Map();
    fOpts.forEach(o => {
      uniqueOptsMap.set(o.label, o);
    });

    return [...uniqueOptsMap.values()];
  };

  /**
   * Handle onChange event for single select and multselect events
   */
  const handleOnChange = value => {
    if (!isMulti && Array.isArray(value) && value.length) {
      value = [value.pop()];
    }
    onChange(value);
  };

  /**
   * On some touch capable devices, react-select sends both
   * onChange event as well as onBlur so make sure to debounce
   * the onChange handler and run the blur call second.
   *
   * https://github.com/JedWatson/react-select/issues/3198
   */
  const debouncedOnChange = _.debounce(handleOnChange, 300, {
    leading: true,
    trailing: false
  });

  return (
    <CreatableSelect
      single={!isMulti}
      isMulti={true}
      inputValue={inputValue}
      styles={autocompleteStyles}
      onInputChange={onInputChange}
      onBlur={e => {
        if (e.target.value) {
          const selection = [{ label: e.target.value, value: e.target.value }];
          // make sure blur event is handled after onChange for
          // touch capable devices
          setTimeout(() => {
            debouncedOnChange(selection);
          }, 0);
        }
      }}
      value={value}
      noOptionsMessage={() => null}
      placeholder={placeholder}
      formatCreateLabel={formatCreateLabel}
      onChange={debouncedOnChange}
      // cacheOptions={true} // save previously fetched requests
      defaultOptions={options} // options that immediatley show when input field is focused
      loadOptions={loadFilteredOptions} // async load function. If omitted, it uses static
      components={{ IndicatorsContainer, Input: MultiSelectDOMInput }}
      className={className}
      isValidNewOption={isValidNewOption || undefined}
      onKeyDown={onKeyDown || undefined}
    />
  );
};
