import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  mailbotsAdminBrowser,
  userDate,
  handleWebhookResponseStatus
} from "../lib/utils";
import { message as antMessage } from "antd";
import Loading from "../Loading";
import nProgress from "nprogress";
import Card from "../Card";
import ScheduleWidget from "../ScheduleWidget";
import { Label, GreyLink } from "../MinForm";
import md5 from "md5";
import { MultiSelectInput } from "../MultiSelectInput";
import SettingsPage from "mailbots/dist/lib/settings-page.js";
import * as _ from "lodash";
import moment from "moment";
import { Container, RightFixedMenu, ItemContent } from "../Layout";
import {
  getPersonTagOptions,
  getPersonAttribute,
  setPersonAttribute,
  initPersonAttributes,
  getPersonName,
  getAllPersonEmails,
  getPersonEmail
} from "../lib/peopleUtils";
import CreateFutModal from "../Tasks/CreateFutModal";
import { BackButton, returnableLink } from "../BackButton";
import { logger } from "../lib/Logger";

const PersonCard = props => {
  const { children, menu, title, avatarUrl, ...restProps } = props;
  return (
    <Card
      type="large"
      style={{ margin: "12 0", position: "relative" }}
      {...restProps}
    >
      <div
        style={{
          width: "100%",
          textAlign: "right",
          borderBottom: "1px solid #efefef",
          display: "flex",
          flexDirection: "row-reverse"
        }}
      >
        {menu &&
          menu.map((link, index) => (
            <a
              key={index}
              onClick={link.onClick}
              style={{
                padding: "5px 15px",
                color: "rgb(118, 118, 118)",
                borderBottom:
                  link.active && link.active() ? "2px solid #11A2D2" : "",
                textDecoration: "none",
                marginBottom: -1
              }}
            >
              {link.text}
            </a>
          ))}
        {title && (
          <h2
            style={{
              marginTop: 0,
              marginRight: "auto",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {title}
          </h2>
        )}
      </div>
      {children}
    </Card>
  );
};

class Person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: null,
      personId: this.props.personId, // this is sometimes set async when looking up user by email
      personTasks: null,
      personHistory: null,
      allPeopleAttributes: null,
      showAttrEditor: false,
      testField: null,
      isCreatingFut: false,
      showCreateFutModal: false
    };

    // As of Oct, 21 we're purging non-active people, making person history less useful.
    // See https://github.com/mailbots/fut-core-api/issues/2848 We experimentally re-introduce and
    // upgrade users for this feature at some point.
    this.SHOW_PERSON_HISTORY = false;
  }

  loadPerson = async ({ personId, personEmail }) => {
    try {
      let person;
      if (personId) {
        const res = await mailbotsAdminBrowser.getPerson(personId);
        person = res.person;
      } else if (personEmail) {
        person = await this.getOrCreatePersonByEmail(personEmail);
      } else {
        throw Error(
          `Sorry, we ran into a problem retrieving this person. Please email help@humans.fut.io for help`
        );
      }

      if (!person) throw Error("Sorry, we had trouble loading this person");

      person = initPersonAttributes(person);
      this.setState({ person });
      return person;
    } catch (e) {
      console.error(e);
      antMessage.error("Error:" + e.message);
    }
  };

  getOrCreatePersonByEmail = async personEmail => {
    const res = await mailbotsAdminBrowser.searchPeople({
      filter: [
        {
          attribute: "emails",
          operator: "inc",
          value: personEmail
        }
      ]
    });
    const { people } = res;
    if (people.length === 1) {
      return people[0];
    } else if (people.length === 0) {
      const person = await this.createPersonWithEmail(personEmail);
      return person;
    }
  };

  createPersonWithEmail = async personEmail => {
    const { person } = await mailbotsAdminBrowser.createPerson({
      person: {
        attributes: [
          {
            attribute: "emails",
            title: "Emails",
            type: "list",
            value: [personEmail],
            display_order: 3,
            hidden: false,
            readonly: false
          }
        ]
      }
    });
    return person;
  };

  loadAllPeopleAttributes = async () => {
    try {
      const attrRes = await mailbotsAdminBrowser.getAllPeopleAttributes();
      this.setState({ allPeopleAttributes: attrRes.attributes });
    } catch (e) {
      antMessage.error("Error loading all people attributes");
      console.error(e);
    }
  };

  loadPersonTasks = async personId => {
    const res = await mailbotsAdminBrowser.getTasks({
      personid: personId
    });
    const uniqueTasks = _.uniqBy(res.tasks, t => t.id);
    this.setState({
      personTasks: uniqueTasks
    });
  };

  loadPersonHistory = async personId => {
    try {
      let res = await mailbotsAdminBrowser.getPersonEvents(personId);
      this.setState({ personHistory: res.events });
    } catch (e) {
      console.error(e);
      antMessage.error("There was an error loading this person's history");
    }
  };

  componentDidMount = async () => {
    try {
      nProgress.start();
      let personId;
      if (this.props.personId) {
        personId = this.props.personId;
        await this.loadPerson({ personId: this.props.personId });
      } else if (this.props.personEmail) {
        let person = await this.loadPerson({
          personEmail: this.props.personEmail
        });
        personId = person.id;
      }
      this.loadAllPeopleAttributes();
      this.populateAvatarUrl();
      this.loadPersonTasks(personId);
      this.loadPersonHistory(personId);
      nProgress.done();
      logger.log("person details screen shown");
    } catch (e) {
      antMessage.error(e.message);
      console.error(e);
      nProgress.done();
    }
  };

  handlePersonAttrOnChange = event => {
    let type = event.target.type;
    let attribute = event.target.name;
    let value = type === "checkbox" ? event.target.checked : event.target.value;
    const person = _.cloneDeep(this.state.person);
    const newPerson = setPersonAttribute(person, attribute, value);
    this.setState({ person: newPerson });
  };

  handleSavePerson = async event => {
    try {
      event.preventDefault();
      this.setState({ isSaving: true });
      nProgress.start();
      const person = this.state.person;
      await mailbotsAdminBrowser.updatePerson({ person });
      antMessage.success("Saved ✅");
      nProgress.done();
      this.setState({ isSaving: false });
      logger.log("person details updated");
    } catch (e) {
      this.setState({ isSaving: false });
      nProgress.done();
      antMessage.error(
        "There was an error updating this person. Please contact help@humans.fut.io for help"
      );
      console.error(e);
    }
  };

  onRescheduleSuccess = async e => {
    nProgress.start();
    antMessage.success("Followup Created ✅");
    const res = await this.loadPersonTasks(
      this.props.personId || this.state.person.id
    );
    const res2 = await this.loadPersonHistory(
      this.props.personId || this.state.person.id
    );
    nProgress.done();
  };

  handleDeletePerson = async () => {
    logger.log("person details delete button clicked");
    if (!window.confirm(`Are you sure? This action cannot be undone.`)) return;
    try {
      await mailbotsAdminBrowser.deletePerson(this.state.person.id);
      this.props.history.push("/people");
      logger.log("person details deleted");
    } catch (e) {
      console.error("error deleting person", e);
      antMessage.error(e.message);
    }
  };

  toggleAttrEditor = () => {
    this.setState({ showAttrEditor: !this.state.showAttrEditor });
  };

  /**
   * @todo - populate gravatar froma all person email addresses
   */
  populateAvatarUrl = async () => {
    if (!this.state.person) return;
    const email = (this.state.person && this.state.person.email) || "";
    const gravatarUrl = `https://www.gravatar.com/avatar/${md5(
      email.trim()
    )}/?d=404`;

    const placeholderUrl = `https://www.gravatar.com/avatar/${md5(
      email.trim()
    )}/?d=mp`;

    const gravatarRes = await fetch(gravatarUrl);
    let avatarUrl;
    if (gravatarRes.status !== 404) {
      avatarUrl = gravatarRes.url;
    } else {
      avatarUrl = placeholderUrl;
    }

    this.setState(state => {
      state.person.avatarUrl = avatarUrl;
      return state;
    });
  };

  handleOnSubmit = async (namespace, formData) => {
    nProgress.start();
    const currentPersonAttributes = addDefaultAttributesIfNoneExist(
      this.state.person.attributes
    );
    const attributes = this.convertFormToPersonAttrs(
      formData,
      currentPersonAttributes
    );

    try {
      const res = await mailbotsAdminBrowser.updatePerson({
        person: {
          id: this.state.person.id,
          attributes
        }
      });
      this.setState({ person: res.person });
      nProgress.done();
      antMessage.success("Person updated");
    } catch (e) {
      nProgress.done();
      console.error(e);
    }
  };

  /**
   * Convert Dynamic JSON form output, convert to person attribute, preserving the datatype of the original
   * value on the person attribute.
   * @todo – Use when we implement dynamic custom people fields
   */
  convertFormToPersonAttrs(formData, originalPersonAttributes) {
    let updatedAttributes = [];
    for (let key in formData) {
      const origAttrValue = getPersonAttribute(key, originalPersonAttributes);
      const newAttrValue = formData[key];
      const typedNewValue = matchOriginalType(origAttrValue, newAttrValue);
      updatedAttributes.push({ attribute: key, value: typedNewValue });
    }
    return updatedAttributes;

    function matchOriginalType(originalValue, newValue) {
      if (typeof originalValue === "number") {
        return parseInt(newValue);
      } else if (typeof originalValue === "string") {
        return String(newValue);
      } else if (originalValue === null) {
        return String(newValue);
      } else if (originalValue === undefined) {
        return String(newValue);
      } else if (typeof originalValue === "object") {
        console.error("object coersion not yet supported");
        debugger;
      } else {
        console.error("unrecognized type");
        debugger;
      }
    }
  }

  createFollowup = async ({ command, subject, body }) => {
    try {
      if (!command) {
        antMessage.error("Please enter a schedule time");
        return;
      }

      nProgress.start();
      this.setState({ isCreatingFut: true });
      const personEmail = getPersonEmail(this.state.person);
      let res = await mailbotsAdminBrowser.createTask({
        webhook: true,
        task: {
          command,
          reference_email: {
            from: this.props.loggedInUser.primary_email,
            to: [command],
            cc: [],
            bcc: [],
            subject,
            html: body
          },
          people: [{ email: personEmail }]
        }
      });

      const newTask = res.task;

      // In the rare event they create a FUT without a valid trigger time, send them to the task details page a warning.
      if (!newTask.trigger_time) {
        let viewFutUrl = returnableLink({
          toPath: `/tasks/${newTask.id}`,
          backPath: window.location.pathname,
          backText: `Create Task`
        });
        viewFutUrl += `&warning=${window.encodeURIComponent(
          "Your task is not yet scheduled, most likely due to an incorrect date format. Please reschedule it below 👇"
        )}`;
        return this.props.history.push(viewFutUrl);
      }

      antMessage.info("Followup scheduled");
      await this.loadPerson({ personEmail });
      await this.loadPersonHistory(this.state.person.id);
      await this.loadPersonTasks(this.state.person.id);
      this.setState({ showCreateFutModal: false, isCreatingFut: false });
      nProgress.done();
      logger.log("created followup from web app person view", {
        taskId: newTask.id
      });
    } catch (error) {
      this.setState({ isCreatingFut: false });
      antMessage.error(error.message);
    }

    nProgress.done();
  };

  render() {
    const styles = {
      anchor: {
        display: "block",
        position: "relative",
        top: -165,
        visibility: "hidden"
      }
    };

    const Avatar = ({ avatarUrl }) => (
      <div
        style={{
          borderRadius: 100,
          width: 70,
          height: 70,
          backgroundColor: "#aaa",
          color: "white",
          align: "center",
          margin: "0px 13px",
          float: "left",
          overflow: "hidden",
          display: "inline-block"
        }}
      >
        <img src={avatarUrl} width="100%"></img>
      </div>
    );

    const renderTasks = tasks => {
      if (tasks.length === 0)
        return (
          <div>
            <h4 style={{ color: "rgb(200, 200, 200)" }}>(No Followups)</h4>
          </div>
        );
      return tasks.map(task => (
        <Link
          key={task.id}
          className="clickable-panel"
          to={returnableLink({
            toPath: `/tasks/${task.id}`,
            backPath: window.location.pathname,
            backText: getPersonName(this.state.person)
          })}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ flex: 4 }}>{task.reference_email.subject}</div>
          <div
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              fontSize: 12,
              color: "#11a2d2",
              margin: 0,
              height: "15px"
            }}
          >
            {task.trigger_time
              ? userDate({
                  timestamp: task.trigger_time,
                  momentDateFormat:
                    this.props.loggedInUser.preferred_date_format_js,
                  timezone: this.props.loggedInUser.timezone
                })
              : "not scheduled"}
          </div>
          <hr />
        </Link>
      ));
    };

    const nextFutAt = this.state.person
      ? getPersonAttribute({
          key: "next_fut_at",
          personAttributes: this.state.person.attributes,
          momentDateFormat: this.props.loggedInUser.preferred_date_format_js,
          timezone: this.props.loggedInUser.timezone
        })
      : "(no upcoming followup scheduled)";

    return (
      <Container>
        <CreateFutModal
          isVisible={this.state.showCreateFutModal}
          onCloseModal={() => this.setState({ showCreateFutModal: false })}
          loggedInUser={this.props.loggedInUser}
          onSuccess={async () => {
            antMessage.info("Followup scheduled");
            this.loadPerson({ personId: this.state.person.id });
            this.loadPersonHistory(this.state.person.id);
            this.loadPersonTasks(this.state.person.id);
            this.setState({ showCreateFutModal: false });
          }}
          relatedPeople={[getPersonEmail(this.state.person)]}
          onError={e => {
            console.error(e);
            antMessage.error(
              e.message + " Need help? Feedback? Email help@humans.fut.io"
            );
            nProgress.done();
          }}
        />

        <a name="top">&nbsp;</a>
        <ItemContent className="task-actions">
          <br />

          <BackButton defaultTo="/people" defaultBackText="People" />
          {/* <Link
            to="/people"
            className="back-link"
            style={{
              color: "#aaaaaa"
            }}
          >
            <i className="glyphicon glyphicon-chevron-left" />{" "}
            <span className="hide-for-mobile">People</span>
          </Link> */}
          <br />

          {this.state.person ? (
            <div>
              <PersonCard
                title={getPersonName(this.state.person)}
                avatarUrl={getPersonAttribute({
                  key: "avatar_url",
                  personAttributes: this.state.person.attributes
                })}
              >
                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Name
                  </label>
                  <input
                    name="full_name"
                    type="text"
                    onChange={this.handlePersonAttrOnChange}
                    value={getPersonAttribute({
                      key: "full_name",
                      personAttributes: this.state.person.attributes
                    })}
                    className="form-control"
                    id="full_name"
                    key="full_name"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Tags
                  </label>
                  <div style={{ border: "1px solid #ddd" }}>
                    <MultiSelectInput
                      controlStyle={{
                        paddingTop: 4
                      }}
                      name="tags"
                      options={getPersonTagOptions(
                        this.state.allPeopleAttributes
                      )}
                      value={getPersonTagOptions(this.state.person.attributes)}
                      formatCreateLabel={input => input}
                      onChange={event =>
                        this.handlePersonAttrOnChange({
                          target: {
                            name: "tags",
                            value: event.map(evt => evt.value)
                          }
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Emails
                  </label>
                  <div style={{ border: "1px solid #ddd", paddingTop: 4 }}>
                    <MultiSelectInput
                      hideAutocomplete={true}
                      value={getAllPersonEmails(this.state.person).map(
                        email => ({
                          label: email,
                          value: email
                        })
                      )}
                      onChange={event =>
                        this.handlePersonAttrOnChange({
                          target: {
                            name: "emails",
                            value: event.map(evt => evt.value)
                          }
                        })
                      }
                    ></MultiSelectInput>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Organization
                  </label>
                  <input
                    name="organization"
                    type="text"
                    onChange={this.handlePersonAttrOnChange}
                    value={
                      getPersonAttribute({
                        key: "organization",
                        personAttributes: this.state.person.attributes
                      }) || ""
                    }
                    className="form-control"
                    id="organization"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Notes
                  </label>
                  <textarea
                    name="notes"
                    onChange={this.handlePersonAttrOnChange}
                    value={
                      getPersonAttribute({
                        key: "notes",
                        personAttributes: this.state.person.attributes
                      }) || ""
                    }
                    className="form-control"
                    id="notes"
                  />
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.handleSavePerson}
                  disabled={this.state.isSaving}
                >
                  {!this.state.isSaving ? "Save" : "Saving..."}
                </button>

                {/* <DynamicJsonForm
                  settingForm={getJsonFormFromPeopleAttr(
                    this.state.person.attributes,
                    this.props.loggedInUser.preferred_date_format_js
                  )}
                  handleOnSubmit={this.handleOnSubmit}
                  loggedInUser={this.props.loggedInUser}
                /> */}
              </PersonCard>

              <PersonCard
                menu={[
                  {
                    text: "+",
                    onClick: () => {
                      this.setState({ showCreateFutModal: true });
                    }
                  },
                  {
                    onClick: () => {
                      this.props.history.push(
                        `/tasks/?personid=${this.state.person.id}`
                      );
                    },
                    text: "All"
                  }
                ]}
                title="Followups"
              >
                <a name="followups" style={styles.anchor}>
                  &nbsp;
                </a>
                {this.state.personTasks ? (
                  renderTasks(this.state.personTasks)
                ) : (
                  <div className="loader"></div>
                )}
              </PersonCard>

              {this.SHOW_PERSON_HISTORY ? (
                <PersonCard title="History">
                  <a name="history" style={styles.anchor}>
                    &nbsp;
                  </a>
                  {this.state.personHistory ? (
                    this.state.personHistory.length ? (
                      this.state.personHistory.map(event => (
                        <div key={event.id}>
                          <span className="text-muted">
                            {userDate({
                              timestamp: event.created,
                              timezone: this.props.loggedInUser.timezone,
                              momentDateFormat:
                                this.props.loggedInUser.preferred_date_format_js
                            })}
                            {/* ({event.type}) */}
                          </span>
                          <h5>{event.title}</h5>
                          {event.body}
                          {Array.isArray(event.links) &&
                            event.links.map((link, index) => (
                              <div key={index} style={{ marginTop: 10 }}>
                                {/** @todo  this is naive. parse link.link_url, url-join params and use <Link /> */}
                                <a
                                  href={returnableLink({
                                    toPath: link.link_url,
                                    backPath: window.location.pathname,
                                    backText: getPersonName(this.state.person)
                                  })}
                                  rel="noopener noreferrer"
                                >
                                  {link.link_text}
                                </a>
                              </div>
                            ))}
                          <hr />
                        </div>
                      ))
                    ) : (
                      <div className="text-muted">No history</div>
                    )
                  ) : (
                    <div className="loader"></div>
                  )}
                </PersonCard>
              ) : null}
            </div>
          ) : (
            <Loading />
          )}
        </ItemContent>

        {this.state.person && (
          <RightFixedMenu className="minform">
            <div style={{ marginTop: 50 }} className="hide-for-mobile">
              <GreyLink
                onClick={() => {
                  this.setState({ showCreateFutModal: true });
                  logger.log("create fut dialog opened from person details");
                }}
              >
                <span className="glyphicon glyphicon-plus"></span> Create
                Followup
              </GreyLink>
              <GreyLink onClick={() => window.scrollTo(0, 0)}>
                <i className="glyphicon glyphicon-user" />{" "}
                <span className="hidden-xs">Details</span>{" "}
              </GreyLink>
              <GreyLink href="#followups">
                <i className="glyphicon glyphicon-circle-arrow-up" />{" "}
                <span className="hidden-xs">Followups</span>{" "}
              </GreyLink>
              {this.SHOW_PERSON_HISTORY ? (
                <GreyLink href="#history">
                  <i className="glyphicon glyphicon-time" />{" "}
                  <span className="hidden-xs">History</span>{" "}
                </GreyLink>
              ) : null}
              <GreyLink onClick={this.handleDeletePerson} href="#history">
                <i className="glyphicon glyphicon-trash" />{" "}
                <span className="hidden-xs">Delete</span>{" "}
              </GreyLink>
            </div>
          </RightFixedMenu>
        )}
      </Container>
    );
  }
}

const addDefaultAttributesIfNoneExist = peopleAttributes => {
  const defaultAttributes = [
    {
      attribute: "org",
      title: "Organization",
      type: "text",
      value: "",
      hidden: false,
      readonly: false,
      display_order: 0
    },
    {
      attribute: "notes",
      title: "Notes",
      type: "textarea",
      value: "",
      hidden: false,
      readonly: false,
      display_order: 0
    }
  ];

  const peopleAttrWithDefaults = defaultAttributes.reduce(
    (reducedAttrs, thisDefault) => {
      if (!reducedAttrs.find(attr => attr.attibute === thisDefault.attribute)) {
        return reducedAttrs.concat(thisDefault); // add default
      }
      return reducedAttrs;
    },
    peopleAttributes // initial value for reducer
  );
  return peopleAttrWithDefaults;
};

/**
 * This uses a simple switch method to render the dynamic input form based on the attribute type.
 * See commit 66da1ff64 for a WIP oop, polymorphic implementation and d191a82e5d1e8 for a builder implementation
 * if this implementation becomes hard to maintain. So far, procedural is winning.
 */
export function getJsonFormFromPeopleAttr(
  peopleAttributes,
  momentDateFormat = "ddd, D MMMM YYYY h:mma"
) {
  let baseObj = { settings: {} };
  const form = new SettingsPage({
    responseJson: baseObj,
    namespace: "root", // @todo if / when we let skills extend people
    menuTitle: "Tmp"
  });

  const peopleAttrWithDefaults =
    addDefaultAttributesIfNoneExist(peopleAttributes);

  const sortedFilteredAttr = _.sortBy(peopleAttrWithDefaults, [
    "display_order"
  ]).filter(a => a.attribute !== "tags");

  sortedFilteredAttr.forEach(attribute => {
    attribute.readOnly = !!attribute.readonly; // convert to boolean (and match camelCasing)
    if (attribute.hidden) return;
    switch (attribute.type) {
      case "input":
        form.input({
          name: attribute.attribute,
          title: attribute.title,
          defaultValue: String(attribute.value),
          readOnly: attribute.readOnly
        });
        break;
      case "textarea":
        form.textarea({
          name: attribute.attribute,
          title: attribute.title,
          defaultValue: String(attribute.value),
          readOnly: attribute.readOnly
        });
        break;
      case "checkbox":
        form.checkbox({
          name: attribute.attribute,
          title: attribute.title,
          defaultValue: attribute.value === "false" ? false : !!attribute.value
        });
        break;
      case "select":
        form.select({
          name: attribute.attribute,
          title: attribute.title,
          options: attribute.value
        });
        break;
      case "date":
        const d = moment(attribute.value);
        let formattedDateStr = d.isValid() ? d.format(momentDateFormat) : "";
        form.input({
          name: attribute.attribute,
          title: attribute.title,
          defaultValue: formattedDateStr,
          readOnly: true
        });
        break;
      default:
        console.warn("unrecognized type:", attribute.type);
        form.input({
          name: attribute.attribute,
          title: attribute.title,
          defaultValue: String(attribute.value),
          readOnly: attribute.readOnly
        });
    }
  });
  form.button({ type: "submit", text: "Save" });
  const retSettings = form.getSettingsFormJSON();
  // settings form namespaces are used to separate multiple settings forms. See below or details.
  // https://github.com/mailbots/mailbots/blob/a7627c02c623d820c7e1b7525b83f9c26b547b11/src/lib/settings-page.ts#L47
  retSettings.namespace = form.namespace;
  return { form: retSettings };
}

export default withRouter(Person);
