import React, { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { message as antMessage } from "antd";
import { logger } from "./lib/Logger";
import icon from "./images/microsoftButtonIcon.svg";

const MicrosoftLoginButton = props => {
  const [msApp, setMsApp] = useState();

  useEffect(() => {
    const init = async () => {
      const app = new PublicClientApplication({
        auth: {
          clientId: process.env.REACT_APP_MS_CLIENT_ID,
          authority: process.env.REACT_APP_MS_AUTHORITY,
          redirectUri: process.env.REACT_APP_MS_REDIRECT_URI
        },
        cache: {
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        }
      });

      setMsApp(app);
    };

    try {
      init();
    } catch (error) {
      console.error("failed to init ms auth button", error);
    }
  }, []);

  const login = async () => {
    try {
      if (!msApp) {
        throw new Error("oauth library not initialized");
      }

      // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
      const loginRequest = {
        scopes: ["openid", "email", "profile", "User.Read"]
      };
      const result = await msApp.loginPopup(loginRequest);

      props.onLogin(result.accessToken);
    } catch (error) {
      console.error(error.message);
      antMessage.error(`Microsoft login failed (${error.message})`);
      logger.log("failed to log in user via Microsoft", {
        level: "error",
        error
      });
    }
  };

  return (
    <button
      id="msLoginBtn"
      className="btn btn-outlined btn-block"
      disabled={props.disabled || false}
      onClick={login}
      style={{ position: "relative" }}
    >
      <i
        style={{
          position: "absolute",
          left: "3px",
          top: "3px",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "3px"
        }}
      >
        <img
          src={icon}
          alt="logo"
          style={{ width: "25px", border: "3px solid white" }}
        />
      </i>
      Sign in with Office365
    </button>
  );
};
export default MicrosoftLoginButton;
