import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import DocsLink from "./DocsLink";

const autocompleteExamples = mailbot => {
  if (
    !mailbot.autocomplete_examples ||
    typeof mailbot.autocomplete_examples !== "string"
  ) {
    return "(Add example email commands on the 'Edit' page)";
  }
  return mailbot.autocomplete_examples.split(",").map(email => {
    return (
      <span key={email + Math.floor(Math.random() * 1000)}>
        <a href={"mailto:" + email}>{email}</a>{" "}
      </span>
    );
  });
};

export default function MailBotDevInfoPanel({
  mailbot,
  uninstallMailBot,
  style
}) {
  return (
    <div className="row" style={{ style }}>
      <div className="col-sm-12">
        <p
          style={{
            display: "inline-block",
            width: 125,
            whiteSpace: "nowrap",
            float: "right"
          }}
        >
          <Link to={`/developer/skills/${mailbot.id}/manage`}>
            <span className="glyphicon glyphicon-pencil" /> Edit Skill Details
          </Link>
        </p>
        <h2>Skill Details</h2>
        <div>
          <h4>How To Invoke Your Skill</h4>
          <p>
            Append your skill flag to any task command.
            <br />
            <pre
              style={{
                marginLeft: 0,
                paddingLeft: 0,
                color: "black",
                fontSize: 14
              }}
            >
              3days-<strong>{mailbot.subdomain}</strong>@followupthen.com
            </pre>{" "}
            {/* <DocsLink href="https://dash.readme.io/project/skills/v1.0/refs/email-commands" /> */}
            {/* <em>{autocompleteExamples(mailbot)}</em> */}
          </p>

          <h4>Webhook URL</h4>
          <p className="mailbot-settings">
            The FUT API sends{" "}
            <a
              href="https://fut.readme.io/reference/task-lifecycle"
              target="_blank"
            >
              lifecycle events
            </a>{" "}
            to this to this URL.
            <br />
            {/* <a href={mailbot.webhook_url}>{mailbot.webhook_url}</a> */}
            <input readOnly value={mailbot.webhook_url} />
          </p>

          <h4>Settings</h4>
          <p>
            Where users manage their settings.
            <br />
            <Link to={`/skills/${mailbot.id}/settings`}>
              {`${window.location.host}/skills/${mailbot.id}/settings`}
            </Link>
          </p>

          <h4>Install</h4>
          <p>
            Where users begin OAuth2 connection process.
            <br />
            <a href={mailbot.install_url}>{mailbot.install_url}</a>
            <br />
          </p>

          <h4>Uninstall</h4>
          <p>
            Test onboarding, skill cleanup.
            <a href="#" onClick={uninstallMailBot}>
              <br />
              Uninstall
            </a>
            <br />
          </p>

          <h4>Receive 3rd Party Webhooks</h4>
          <p>
            Handle webhooks from external systems{" "}
            <DocsLink href="https://fut.readme.io/reference/perfect-timing#publishing-and-handling-events" />
          </p>
          {mailbot.event_url ? (
            <div className="mailbot-settings">
              <input readOnly value={mailbot.event_url || ""} />
            </div>
          ) : (
            <pre>(Connect skill to generate unique webhook URL)</pre>
          )}

          <h4>Who Can Use It?</h4>
          {mailbot.published && mailbot.enabled ? (
            <p>
              Your Skill is live for everyone.{" "}
              <Link to={`/skills/${mailbot.id}`}>See it here</Link>.{" "}
              <DocsLink href="https://fut.readme.io/v1.0/docs/sharing" />
            </p>
          ) : null}
          {!mailbot.published && !mailbot.enabled && (
            <p>
              Your skill is visible to <strong>only you</strong>. Change this in
              your skill's{" "}
              <Link to={`/develop/skills/${mailbot.id}`}>settings</Link>.
            </p>
          )}
          {!mailbot.published && mailbot.enabled && (
            <p>
              Your skill is available, but not yet published on the directory.
              Send beta testers and internal users to this URL:
              <br />
              <Link to={`/skills/${mailbot.id}`}>
                {`${window.location.host}/skills/${mailbot.id}`}
              </Link>
              Contact us when you are ready for publishing.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
