import {
  CloseCircleOutlined,
  CloseOutlined,
  SearchOutlined
} from "@ant-design/icons";
import React from "react";
import { InputIconSet } from "./Form";

export const SearchBox = ({
  placeholder = "",
  handleOnChange,
  handleSearch,
  value,
  isSearching = false,
  style = {},
  setRef = null,
  iconRight = null,
  inputStyle = {},
  handleSearchOnClick = null
}) => (
  <InputIconSet
    style={style}
    placeholder={placeholder}
    onChange={handleOnChange}
    onSubmit={handleSearch}
    value={value}
    inputStyle={{
      border: 0,
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.05)",
      height: 35,
      paddingLeft: 30,
      ...inputStyle
    }}
    setRef={setRef}
    iconLeft={
      <>
        {isSearching ? (
          <div
            id="initial-loader"
            className="loader"
            style={{ height: 14, width: 14 }}
          />
        ) : (
          <SearchOutlined
            onClick={handleSearchOnClick ? handleSearchOnClick : () => {}}
            style={{
              fontSize: 14,
              color: "#333",
              padding: 2,
              cursor: handleSearchOnClick ? "pointer" : "default"
            }}
          />
        )}
      </>
    }
    iconRight={iconRight ? iconRight : null}
  />
);
