import React, { useState } from "react";
import _ from "lodash";
import Card from "../Card";

import Modal from "../Modal";
import {
  getPersonName,
  setPersonAttribute,
  getPersonAttribute,
  getAllPersonEmails,
  initPersonAttributes
} from "../lib/peopleUtils";
import EditPersonForm from "./EditPersonForm";
import { useEffect } from "react";

const MergeModal = props => {
  const [firstPerson, setFirstPerson] = useState();
  const [secondPerson, setSecondPerson] = useState();
  const [primaryPersonIndex, setPrimaryPersonIndex] = useState(0);

  useEffect(() => {
    setFirstPerson(
      props.people[0] ? initPersonAttributes(props.people[0]) : undefined
    );
    setSecondPerson(
      props.people[1] ? initPersonAttributes(props.people[1]) : undefined
    );
    setPrimaryPersonIndex(0);
  }, [props.people]);

  const updatePerson = (person, newInfo) => {
    let updatedPerson = setPersonAttribute(person, "full_name", newInfo.name);
    updatedPerson = setPersonAttribute(person, "tags", newInfo.tags);
    updatedPerson = setPersonAttribute(person, "emails", newInfo.emails);
    updatedPerson = setPersonAttribute(
      person,
      "organization",
      newInfo.organization
    );
    updatedPerson = setPersonAttribute(person, "notes", newInfo.notes);

    return updatedPerson;
  };

  return (
    <Modal visible={props.isVisible} onClose={() => props.onCloseModal()}>
      <Card type="large">
        <h4 className="modal-title" id="mergeModalLabel">
          Select the primary user, copy over relevant information and click
          'merge'
        </h4>

        <div style={{ margin: "10px 0" }}>
          {/* <ul style={}>
            {props.people.map(person => (
              <li key={person.id}>{getPersonName(person)}</li>
            ))}
          </ul> */}
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <label>
                <input
                  type="radio"
                  checked={primaryPersonIndex === 0}
                  onChange={e => {
                    if (e.target.checked) {
                      setPrimaryPersonIndex(0);
                    }
                  }}
                />{" "}
                Make {getPersonName(firstPerson)} primary
              </label>
            </div>
            <div style={{ flex: 1, marginLeft: "10px" }}>
              <label>
                <input
                  type="radio"
                  checked={primaryPersonIndex === 1}
                  onChange={e => {
                    if (e.target.checked) {
                      setPrimaryPersonIndex(1);
                    }
                  }}
                />{" "}
                Make {getPersonName(secondPerson)} primary
              </label>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            {firstPerson ? (
              <div style={{ flex: 1 }}>
                <EditPersonForm
                  key={firstPerson.id}
                  allPeopleAttributes={props.allPeopleAttributes}
                  value={{
                    name: getPersonName(firstPerson),
                    tags: getPersonAttribute({
                      key: "tags",
                      personAttributes: firstPerson.attributes
                    }),
                    emails: getAllPersonEmails(firstPerson),
                    organization: getPersonAttribute({
                      key: "organization",
                      personAttributes: firstPerson.attributes
                    }),
                    notes: getPersonAttribute({
                      key: "notes",
                      personAttributes: firstPerson.attributes
                    })
                  }}
                  onChange={newInfo => {
                    const updatedFirstPerson = updatePerson(
                      firstPerson,
                      newInfo
                    );
                    setFirstPerson({ ...updatedFirstPerson });
                  }}
                ></EditPersonForm>
              </div>
            ) : null}

            {secondPerson ? (
              <div
                style={{
                  flex: 1,
                  marginLeft: "10px",
                  paddingLeft: "10px",
                  borderLeft: "1px solid #ccc"
                }}
              >
                <EditPersonForm
                  key={secondPerson.id}
                  allPeopleAttributes={props.allPeopleAttributes}
                  value={{
                    name: getPersonName(secondPerson),
                    tags: getPersonAttribute({
                      key: "tags",
                      personAttributes: secondPerson.attributes
                    }),
                    emails: getAllPersonEmails(secondPerson),
                    organization: getPersonAttribute({
                      key: "organization",
                      personAttributes: secondPerson.attributes
                    }),
                    notes: getPersonAttribute({
                      key: "notes",
                      personAttributes: secondPerson.attributes
                    })
                  }}
                  onChange={newInfo => {
                    const updatedSecondPerson = updatePerson(
                      secondPerson,
                      newInfo
                    );
                    setSecondPerson({
                      ...updatedSecondPerson
                    });
                  }}
                ></EditPersonForm>
              </div>
            ) : null}
          </div>
        </div>

        <button
          onClick={() => {
            const primaryPerson =
              primaryPersonIndex === 0 ? firstPerson : secondPerson;
            const secondaryPerson =
              primaryPersonIndex === 0 ? secondPerson : firstPerson;

            // merge all email addresses to the first person
            const allEmails = [
              ...getAllPersonEmails(secondPerson),
              ...getAllPersonEmails(firstPerson)
            ];
            const allUniqueEmails = [...new Set(allEmails)];
            setPersonAttribute(firstPerson, "emails", allUniqueEmails);

            props.onSubmit({ primaryPerson, secondaryPerson });
          }}
          type="button"
          className="btn btn-primary"
          disabled={props.isBusy}
        >
          {!props.isBusy ? "Merge" : "Merging..."}
        </button>
        {"  "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </button>
      </Card>
    </Modal>
  );
};

export default MergeModal;
