import React from "react";
import { checkActiveFutBillingLimits } from "./Billing/billingHelpers";
import { AddBasePlan } from "./Billing/UpgradeDialogs";

export const BannerUpgrade = ({ loggedInUser }) => {
  const isInSkillsDir = () => window.location.pathname.startsWith("/skills");
  let { status: futUpgradeStatus, remainingFuts } =
    checkActiveFutBillingLimits(loggedInUser);

  // negative numbers can happen in edge cases
  remainingFuts = remainingFuts <= 0 ? 0 : remainingFuts;

  // upgrade warning
  if (loggedInUser && futUpgradeStatus === "warn" && !isInSkillsDir()) {
    return (
      <AddBasePlan
        loggedInUser={loggedInUser}
        upgradeText={`${remainingFuts} ${
          remainingFuts > 1 ? "followups" : "followup"
        } left this month.`}
      />
    );
  }

  // out of FUTs
  else if (
    loggedInUser &&
    futUpgradeStatus === "upgrade_required" &&
    !isInSkillsDir()
  ) {
    return (
      <AddBasePlan
        loggedInUser={loggedInUser}
        upgradeText={`Congratulations, you've hit your followup limit!`}
      />
    );
  } else {
    return null;
  }
};
