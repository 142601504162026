import React from "react";

import Modal from "../Modal";
import { useState } from "react";
import Card from "../Card";
import { Link } from "react-router-dom";

const FromEmailAddressModal = props => {
  const DISPLAYED_TASKS_NO = 3;
  const moreTasks = props.selectedTasks.slice(DISPLAYED_TASKS_NO).length;

  const [newEmailAddress, setNewEmailAddress] = useState(
    props && props.loggedInUser && props.loggedInUser.primary_email
  );

  return (
    <Modal visible={props.isVisible} onClose={() => props.onCloseModal()}>
      <Card style={{ maxWidth: 500 }} type="large">
        <h3 className="modal-title" id="fromEmailAddressModalLabel">
          Change delivery address
        </h3>
        <p>
          Choose below, or{" "}
          <Link to="/settings">link another email address</Link>. This will not
          affect the delivery address for external recipients.
        </p>
        <div className="form-group">
          <select
            id="email"
            className="form-control"
            value={newEmailAddress}
            onChange={e => setNewEmailAddress(e.target.value)}
          >
            {props.loggedInUser &&
              props.loggedInUser.emails.map(email => (
                <option key={email} value={email}>
                  {email}
                </option>
              ))}
          </select>
        </div>
        <button
          onClick={() => {
            props.onSubmit(newEmailAddress);
          }}
          type="button"
          className="btn btn-primary"
          disabled={props.isBusy}
        >
          {props.isBusy ? "Updating..." : "Update"}
        </button>{" "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onCloseModal()}
        >
          Close
        </button>
      </Card>
    </Modal>
  );
};

export default FromEmailAddressModal;
