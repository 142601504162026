import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "../Modal";
import ScheduleWidget from "../ScheduleWidget";
import { AddressField } from "../EmailEditor";
import {
  mailbotsAdminBrowser,
  addSkillToCommand,
  hasSkillErrors,
  handleWebhookResponseStatus,
  hasWebhookErrors
} from "../lib/utils";
import { message as antMessage } from "antd";
import { TaskPreview } from "../TaskPreview";
import _ from "lodash";
import { logger } from "../lib/Logger";
import { BlueCallout, PopoverStyled } from "../Layout";
import { userPaidForSkill } from "../Billing/billingHelpers";

const WHITELABEL_SKILL_FLAG = "wl";
// For Oct, 2021 simplifications, disabling scheduling FUTs for others to reduce bugs and help us ship.
const ENABLE_SCHEDULE_SEND_OTHERS = false;

const CreateFutModal = props => {
  const [newCommand, setNewCommand] = useState("");
  const [recipient, setRecipient] = useState("self");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [from, setFrom] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [showTaskPreview, setShowTaskPreview] = useState(false);
  const [showRelatedPeople, setShowRelatedPeople] = useState(false);
  const [relatedPeople, setRelatedPeople] = useState([]);
  const [whitelabelSettings, setWhitelabelSettings] = useState([]);

  // Registered in the ScheduleWidget Component. Allows this component to clear the command when recipients switch (ie, between "self" and "others")
  const clearCommandFnRef = useRef(null);

  useEffect(
    function initRecipients() {
      if (Array.isArray(props.recipients)) {
        setRecipient(props.recipients.length ? "others" : "self");
        setEmailRecipients(
          props.recipients.map(email => ({ label: email, value: email }))
        );
      }
    },
    [props.recipients]
  );

  useEffect(
    function initRelatedPeople() {
      if (Array.isArray(props.relatedPeople)) {
        Array.isArray(props.relatedPeople) &&
          setRelatedPeople(
            props.relatedPeople.map(personEmail => {
              if (!personEmail) return;
              return { label: personEmail, value: personEmail };
            })
          );
        setShowRelatedPeople(true);
      }
    },
    [JSON.stringify(props.relatedPeople)]
  );

  useEffect(() => {
    async function loadWhiteLabelOutboxes() {
      const params = {
        urlParams: { coreSkillFlag: WHITELABEL_SKILL_FLAG },
        mailbotid: process.env.REACT_APP_FUT_MAILBOT_ID
      };
      try {
        const settingsRes = await mailbotsAdminBrowser.getMailBotSettings(
          params
        );
        const whitelabelSettings =
          settingsRes &&
          settingsRes.settings &&
          settingsRes.settings[WHITELABEL_SKILL_FLAG] &&
          settingsRes.settings[WHITELABEL_SKILL_FLAG].formData;

        // default to user's primary email if that oubtox is connected, otherwise, add the first on the list
        if (Array.isArray(whitelabelSettings.connected_outboxes)) {
          const primaryEmail = whitelabelSettings.connected_outboxes.find(
            email => email === props.loggedInUser.primary_email
          );
          const fromAddress =
            primaryEmail || whitelabelSettings.connected_outboxes[0];
          setFrom(fromAddress);
        }
        setWhitelabelSettings(whitelabelSettings);
      } catch (e) {
        console.warn(
          "Something went wrong loading account sending preferences"
        );
      }
    }
    loadWhiteLabelOutboxes();
  }, []);

  const reset = () => {
    setSubject("");
    setBody("");
    setEmailRecipients([]);
    setRelatedPeople([]);
    setNewCommand("");
    setRecipient("self");
  };

  const resetPeople = () => {
    setEmailRecipients([]);
    setRelatedPeople([]);
    setShowRelatedPeople(false);
  };

  const swapRelatedRecipientsAndPeople = () => {
    const people = relatedPeople;
    const recipients = emailRecipients;
    setRelatedPeople(recipients);
    setEmailRecipients(people);
  };

  /**
   * Get returns related people in an API-friendly way
   * @returns {object} {email: "email@email.com"}
   */
  const getRelatedPeopleForApi = () => {
    let peopleForApi;
    if (Array.isArray(relatedPeople)) {
      peopleForApi = relatedPeople.map(p => {
        if (typeof p === "string") {
          return { email: p };
        } else if (p && p.value) {
          return { email: p.value };
        } else if (!p) {
          return {};
        } else {
          console.error("Unrecognized person type", typeof p);
          debugger;
          return p;
        }
      });
      return peopleForApi;
    }
  };
  const { Title } = props.overrides || {};

  // https://github.com/JedWatson/react-select/issues/3075 @todo: Filter results. Inaccurate results still happening
  const loadEmailRecipients = async partialRecipient => {
    try {
      const searchRes = await mailbotsAdminBrowser.searchPeople({
        filter: [
          {
            attribute: "emails",
            operator: "inc",
            value: partialRecipient
          }
        ]
      });
      if (
        !searchRes.people ||
        !Array.isArray(searchRes.people) ||
        !searchRes.people.length
      )
        return null;
      const emailRecipients = searchRes.people.map(
        convertPeopleToAutocompleteOpts
      );
      return emailRecipients;
    } catch (e) {
      console.error(e);
    }
  };

  const loadEmailRecipientsDebounced = _.debounce(loadEmailRecipients, 1000, {
    leading: true
  });

  const getPersonAttr = (person, attr, fallback = null) => {
    let personAttr;
    try {
      personAttr = person.attributes.filter(a => a.attribute == attr)[0];
      if (personAttr && personAttr.value) return personAttr.value;
    } catch (e) {
      personAttr = `(No ${attr} attribute)`;
    }
    return personAttr;
  };

  // value is "id" in this case so we can link to the person
  const convertPeopleToAutocompleteOpts = person => {
    const name = getPersonAttr(person, "name");
    const emails = getPersonAttr(person, "emails");
    let email = "(no email)";
    if (Array.isArray(emails)) {
      email = emails[0];
    }
    const label = name ? `${name}<${email}>` : email;
    return { label, value: email };
  };

  // This method should handle all UI customizations and text-descriptions based on user's format selection
  const handleNewFormatSelected = command => {
    if (command && command.includes("-p")) {
      setShowRelatedPeople(true);
    }
    setNewCommand(command);
  };

  const connectedOutboxes = () =>
    whitelabelSettings &&
    Array.isArray(whitelabelSettings.connected_outboxes) &&
    whitelabelSettings.connected_outboxes;

  return (
    <div>
      <Modal
        visible={props.isVisible}
        onClose={() => {
          reset();
          props.onCloseModal();
        }}
      >
        <Modal
          visible={showTaskPreview}
          onClose={() => setShowTaskPreview(false)}
        >
          <TaskPreview
            task={getPreviewTask()}
            loggedInUser={props.loggedInUser}
          />
        </Modal>

        <div className="main-panel create-task-panel">
          {Title ? (
            <Title />
          ) : (
            <h3 className="modal-title" id="followupModalLabel">
              Create Followup
            </h3>
          )}
          {ENABLE_SCHEDULE_SEND_OTHERS ? (
            <form className="plaintext" style={{ display: "inline-flex" }}>
              For{" "}
              <label
                className="plaintext"
                style={{ display: "inline-flex", marginLeft: 7 }}
              >
                <input
                  style={{ marginRight: 3 }}
                  type="radio"
                  name="recipent"
                  value="self"
                  checked={recipient === "self"}
                  onChange={() => {
                    setRecipient("self");
                    swapRelatedRecipientsAndPeople();
                    clearCommandFnRef.current();
                  }}
                />{" "}
                myself{" "}
              </label>{" "}
              <label
                className="plaintext"
                style={{ display: "inline-flex", marginLeft: 7 }}
              >
                <input
                  style={{ marginRight: 2 }}
                  type="radio"
                  name="recipient"
                  value="others"
                  checked={recipient === "others"}
                  onChange={() => {
                    setRecipient("others");
                    swapRelatedRecipientsAndPeople();
                    clearCommandFnRef.current();
                  }}
                />{" "}
                someone else
              </label>
            </form>
          ) : null}
          <div style={{ margin: "16px 0" }}>
            {emailRecipients && emailRecipients.length > 25 ? (
              <div className="alert alert-warning">
                Sorry, we can only schedule followups for 25 people currently.
              </div>
            ) : null}

            {recipient === "others" ? (
              <div>
                {!userPaidForSkill(
                  props.loggedInUser,
                  WHITELABEL_SKILL_FLAG
                ) ? (
                  <BlueCallout style={{ margin: 0 }}>
                    <img
                      src="https://files-l96ogm5km-rsweetland.vercel.app/3002875%20-%20email%20empty%20envelope%20letter%20mail%20newsletter%20subscription.png"
                      style={{
                        width: 70,
                        float: "left",
                        margin: "-10px 18px 0px 0px"
                      }}
                    />
                    Scheduling followups for others requires the{" "}
                    <Link to={`/skills/${WHITELABEL_SKILL_FLAG}`}>
                      Whitelabel
                    </Link>{" "}
                    skill which sends followups directly from your address.
                  </BlueCallout>
                ) : (
                  <div>
                    {connectedOutboxes().length ? (
                      <div className="form-group">
                        <label htmlFor="senderFrom">From: </label>
                        <select
                          onChange={e => {
                            debugger;
                            setFrom(e.target.value);
                          }}
                          className="form-control"
                          id="senderFrom"
                        >
                          {connectedOutboxes().map(outbox => (
                            <option key={outbox} value={outbox}>
                              {outbox}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <BlueCallout style={{ margin: 0 }}>
                        Connect an outbox in{" "}
                        <Link
                          to={`/skills/${WHITELABEL_SKILL_FLAG}/settings/${WHITELABEL_SKILL_FLAG}`}
                        >
                          whitelabel settings
                        </Link>{" "}
                        to send delayed followups.
                      </BlueCallout>
                    )}
                  </div>
                )}
              </div>
            ) : null}

            {recipient === "others" ? (
              <div className="form-group">
                <label htmlFor="subject">Recipient:</label>
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: 4,
                    marginBottom: 12
                  }}
                >
                  <AddressField
                    name="people"
                    value={emailRecipients}
                    // loadOptions={() => [{ label: "foo", value: "bar" }]}
                    loadOptions={loadEmailRecipientsDebounced}
                    onAutocompleteChange={setEmailRecipients}
                  />
                </div>
              </div>
            ) : null}

            {props.loggedInUser && !props.loggedInUser.timezone ? (
              <BlueCallout style={{ marginLeft: 0 }}>
                Please <Link to="/settings">select your timezone</Link>. We are
                currently using GMT by default.
              </BlueCallout>
            ) : null}

            <div className="form-group">
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <label htmlFor="subject">When:</label>
                {!showRelatedPeople &&
                recipient !== "others" &&
                process.env.REACT_APP_SHOW_PEOPLE === "yes" &&
                userPaidForSkill(props.loggedInUser, "p") ? (
                  <a
                    onClick={() => setShowRelatedPeople(true)}
                    className="muted-link"
                  >
                    Related People
                  </a>
                ) : null}
              </div>
              <div
                style={{
                  border: "1px solid #ddd",
                  borderRadius: 4,
                  marginBottom: 12,
                  position: "relative"
                }}
              >
                <ScheduleWidget
                  onChange={handleNewFormatSelected}
                  getTask={getPreviewTask}
                />
                <div
                  className="help-block"
                  style={{
                    position: "absolute",
                    bottom: -35,
                    right: 0,
                    color: "#999"
                  }}
                >
                  Add{" "}
                  <PopoverStyled
                    content={
                      <p>
                        Skills add special abilities to your followup. For
                        example, the format <code>3days-sms</code> sends an sms
                        with your reminder.{" "}
                        <a target="_blank" href="/skills">
                          Explore Skills
                        </a>
                      </p>
                    }
                  >
                    Skills
                  </PopoverStyled>{" "}
                  to your followup.
                </div>
              </div>
            </div>

            {/** If user has -p in date format */}
            {recipient === "self" && showRelatedPeople ? (
              <div className="form-group">
                <label htmlFor="subject">Related People:</label>
                <AddressField
                  name="related-people"
                  value={relatedPeople}
                  loadOptions={loadEmailRecipientsDebounced}
                  onAutocompleteChange={setRelatedPeople}
                  inputStyle={{
                    border: "1px solid #ddd",
                    borderRadius: 4,
                    marginBottom: 12
                  }}
                />
                <div
                  className="help-block"
                  style={{
                    float: "right",
                    marginTop: -7,
                    color: "#999"
                  }}
                >
                  <a
                    onClick={() => {
                      resetPeople();
                      setShowRelatedPeople(false);
                    }}
                    className="muted-link"
                  >
                    Unlink related people
                  </a>
                </div>
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <input
                name="subject"
                className="form-control"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="body">Message:</label>
              <textarea
                rows="3"
                name="body"
                className="form-control"
                value={body}
                onChange={e => setBody(e.target.value)}
              ></textarea>
            </div>
          </div>
          <button
            onClick={() => {
              createFollowup({
                emailRecipients: emailRecipients.map(rcp => rcp.value),
                command: newCommand,
                subject,
                body,
                from
              });
            }}
            type="button"
            className="btn btn-primary"
            disabled={isBusy}
          >
            {!isBusy ? "Create Followup" : "Creating..."}
          </button>
          <a
            className="muted-link"
            style={{ marginLeft: 20 }}
            onClick={e => {
              e.preventDefault();
              setShowTaskPreview(true);
            }}
          >
            Preview
          </a>
        </div>
      </Modal>
    </div>
  );

  // if this followup is intended for others, add the delay skill
  //  alter the reference email to add the format to 'cc' and return the new task
  function autoApplyWhitelabelSkill(srcTask, emailRecipients) {
    if (!srcTask.command) return srcTask;
    let task = _.cloneDeep(srcTask);
    if (recipient === "others") {
      task.command = addSkillToCommand(task.command, "wl");
      task.reference_email.to = emailRecipients;
      task.reference_email.cc = [task.command];
    }
    return task;
  }

  function getPreviewTask() {
    const previewTask = {
      command: newCommand,
      reference_email: {
        from:
          (from && from.toLowerCase()) ||
          (props &&
            props.loggedInUser &&
            props.loggedInUser.primary_email.toLowerCase()),
        to: newCommand,
        subject,
        html: body
      },
      people: getRelatedPeopleForApi()
    };

    // account for -d scenario where FUT is being sent to someone else
    const recipients = emailRecipients.map(rcp => rcp.value);
    const delayablePreviewTask = autoApplyWhitelabelSkill(
      previewTask,
      recipients
    );
    return delayablePreviewTask;
  }

  async function createFollowup({
    emailRecipients,
    command,
    subject,
    body,
    from
  }) {
    try {
      setIsBusy(true);

      // ensure they have whitelabel sending to message others
      if (
        recipient === "others" &&
        (!userPaidForSkill(props.loggedInUser, WHITELABEL_SKILL_FLAG) ||
          !connectedOutboxes().length)
      ) {
        alert(
          "Please add the whitelabel skill and connect an inbox to schedule emails for others"
        );
        setIsBusy(false);
        return;
      }

      // prevent skills from being executed when scheduling FUTs for others
      if (recipient === "others" && command.includes("-")) {
        alert(
          "Sorry, skills are not yet supported while scheduling followups for others. Feel free to send feedback to help@humans.fut.io"
        );
        setIsBusy(false);
        return;
      }

      if (!command) {
        props.onError("When is this followup due?");
        setIsBusy(false);
        return;
      }
      if (!subject && !body) {
        props.onError("Try adding a Subject or Message");
        setIsBusy(false);
        return;
      }

      if (typeof props.onState === "function") props.onStart();

      let task = {
        command,
        reference_email: {
          from:
            (from && from.toLowerCase()) ||
            props.loggedInUser.primary_email.toLowerCase(),
          to: [command],
          cc: [],
          bcc: [],
          subject,
          html: body
        },
        people: getRelatedPeopleForApi()
      };
      if (recipient === "others") {
        task = autoApplyWhitelabelSkill(task, emailRecipients);
      }

      let res = await mailbotsAdminBrowser.createTask({
        webhook: true,
        task
      });

      // ex: a skill failed (anything onther than "success" statys)
      if (hasSkillErrors(res)) {
        antMessage.warn("There was an error with one of your skills.");
        props.history.push(`/tasks/${res.task.id}`);
        return;
      }

      // show webhook warn, error, etc message
      handleWebhookResponseStatus(res);

      // ex: invalid format of fut-mailbot error
      if (hasWebhookErrors(res)) {
        antMessage.warn("Please reschedule to try again.");
        props.history.push(`/tasks/${res.task.id}`);
        return;
      }

      const newTask = res.task;
      reset();

      // In the rare event they create a FUT without a valid trigger time, send them to the task details page a warning.
      if (!newTask.trigger_time) {
        let viewFutUrl = `/tasks/${newTask.id}?bk=${window.encodeURIComponent(
          `Create Task`
        )}&url=${window.encodeURIComponent(window.location.pathname)}`;

        viewFutUrl += `&warning=${window.encodeURIComponent(
          "Your task is not yet scheduled, most likely due to an incorrect date format. Please reschedule it below 👇"
        )}`;
        return props.history.push(viewFutUrl);
      }
      setIsBusy(false);
      props.onSuccess();
      logger.log("followup created by web app", { taskId: newTask.id });
    } catch (error) {
      console.error(error);
      setIsBusy(false);
      props.onError("Error creating followup: " + error.message);
    }
  }
};

export default withRouter(CreateFutModal);
