import React, { useContext } from "react";
import { useHistory } from "react-router";
import { GlobalContext } from "../App";
import ModalTour from "./ModalTour";
import {
  hasEmployees,
  hasLegacyPersonalPlan,
  isEmployee,
  isOnFreePlan,
  isPaying,
  isTeamOwner
} from "../Billing/billingHelpers";
import {
  tourCardFamiliarFast,
  tourCardBillingTeam,
  tourCardBudget,
  tourCardMigrationDoneEnjoyThePresent,
  tourCardMigrationDoMigration,
  tourCardPricingIndividual,
  tourCardSkills,
  tourCardTeamBillingInviteTeam,
  tourCardTeamInvitesDone,
  tourCardUpgrade,
  tourCardWelcomeToV3,
  tourCardSetupSmsCalendar,
  tourCardWelcomeNewUser,
  tourCardBcc,
  tourCardCc,
  tourCardNewUserDone,
  tourCardsValidateEmail
} from "./tourCards";
import { isOnV3, sendGtmEvent } from "../lib/utils";
import { logger } from "../lib/Logger";

/**
 * Various tours for welcoming different users in different states
 * @param tourKey (newEmployee | newUser | existingIndividualUser | existingTeamOwner) - which tour to show
 */

export const WelcomeToV3Tour = ({
  switchToV3,
  currentStepId,
  closeTour,
  tourKey,
  containerStyle = {}
}) => {
  const { loggedInUser } = useContext(GlobalContext);
  const history = useHistory();

  let tour = {};

  // send analytics event
  if (tourKey === "newUser" && loggedInUser && loggedInUser.is_validated) {
    sendGtmEvent("email_validated", {
      category: "signup",
      action: "email_validated",
      label: "in_tour"
    });
  }

  tour.newUser = tourControls => [
    ...tourCardWelcomeNewUser({
      tourControls,
      loggedInUser
    }),
    ...tourCardBcc({ tourControls }),
    ...tourCardCc({ tourControls }),
    ...tourCardFamiliarFast({
      tourControls,
      loggedInUser,
      onSkip: () => {
        if (loggedInUser.is_validated) tourControls.closeTour();
        else tourControls.goToStepId("validate-email");
        logger.log("new user skipped tour");
      }
    }),
    ...tourCardSkills({ tourControls, loggedInUser }),
    ...tourCardUpgrade({ tourControls, loggedInUser }),
    ...(!loggedInUser.is_validated
      ? tourCardsValidateEmail({ tourControls, loggedInUser })
      : tourCardNewUserDone({ tourControls, loggedInUser }))
  ];

  tour.existingIndividualUser = tourControls => [
    ...tourCardWelcomeToV3({
      tourControls,
      loggedInUser,
      onSkip: () => {
        tourControls.goToStepId("do-migration");
        logger.log("existing user skipped tour");
      }
    }),
    ...tourCardFamiliarFast({ tourControls, loggedInUser }),
    ...tourCardSkills({ tourControls, loggedInUser }),
    ...tourCardPricingIndividual({
      tourControls,
      loggedInUser,
      onClickNext: () => {
        if (hasLegacyPersonalPlan(loggedInUser) || isOnFreePlan(loggedInUser)) {
          tourControls.goToStepId("upgrade");
          // users on professional or company plans migrate without needing to upgrade
        } else if (!isOnV3(loggedInUser)) {
          tourControls.goToStepId("do-migration");

          // already migrated but are on the tour again
        } else if (isOnV3(loggedInUser) && isPaying(loggedInUser)) {
          tourControls.goToStepId("setup-sms-cal");
        } else {
          tourControls.next();
        }
      }
    }),
    ...tourCardUpgrade({
      tourControls,
      loggedInUser,
      onUpgradeSuccess: () => {
        tourControls.goToStepId("do-migration");
      }
    }),
    ...(!loggedInUser.migrated_to_v3 || !loggedInUser.opt_into_fut_mailbot
      ? tourCardMigrationDoMigration({
          tourControls,
          loggedInUser,
          switchToV3: switchToV3,
          onMigrationDone: () => {
            if (isPaying(loggedInUser)) {
              tourControls.goToStepId("setup-sms-cal");
            } else {
              tourControls.next();
            }
          }
        })
      : []),
    ...tourCardMigrationDoneEnjoyThePresent({ tourControls, loggedInUser }),
    ...tourCardSetupSmsCalendar({ tourControls, loggedInUser, history })
  ];

  tour.existingTeamOwner = tourControls => [
    ...(!loggedInUser.accepted_terms
      ? tourCardWelcomeNewUser({
          tourControls,
          loggedInUser
        })
      : tourCardWelcomeToV3({
          tourControls,
          loggedInUser,
          onSkip: () => tourControls.goToStepId("new-pricing-team")
        })),
    ...tourCardFamiliarFast({ tourControls, loggedInUser }),
    ...tourCardSkills({ tourControls, loggedInUser }),
    ...tourCardBillingTeam({ tourControls, loggedInUser }),
    ...(!loggedInUser.migrated_to_v3
      ? tourCardMigrationDoMigration({
          tourControls,
          loggedInUser,
          switchToV3: switchToV3
        })
      : []),
    tourCardTeamBillingInviteTeam({ tourControls, loggedInUser }),
    tourCardTeamInvitesDone({ tourControls, loggedInUser }),
    ...tourCardMigrationDoneEnjoyThePresent({ tourControls }),
    ...tourCardSetupSmsCalendar({ tourControls, loggedInUser, history })
  ];

  tour.existingEmployee = tourControls => [
    ...(!loggedInUser.accepted_terms
      ? tourCardWelcomeNewUser({
          tourControls,
          loggedInUser
        })
      : tourCardWelcomeToV3({
          tourControls,
          loggedInUser,
          onSkip: () => tourControls.goToStepId("do-migration")
        })),
    ...tourCardFamiliarFast({ tourControls, loggedInUser }),
    ...tourCardSkills({ tourControls, loggedInUser }),
    ...tourCardBudget({ tourControls, loggedInUser }),
    ...(!loggedInUser.migrated_to_v3
      ? tourCardMigrationDoMigration({
          tourControls,
          loggedInUser,
          switchToV3: switchToV3
        })
      : []),
    ...tourCardSetupSmsCalendar({ tourControls, loggedInUser, history })
  ];

  tour.newEmployee = tourControls => [
    ...tourCardWelcomeNewUser({
      tourControls,
      loggedInUser
    }), // accounts for employees and others
    ...tourCardFamiliarFast({
      tourControls,
      loggedInUser,
      onSkip: () => {
        if (loggedInUser.is_validated) tourControls.closeTour();
        else tourControls.goToStepId("validate-email");
      }
    }),
    ...tourCardBcc({ tourControls }),
    ...tourCardCc({ tourControls }),
    ...tourCardSkills({ tourControls, loggedInUser }),
    ...tourCardBudget({ tourControls, loggedInUser }),
    ...tourCardNewUserDone({ tourControls, loggedInUser })
  ];

  if (tourKey)
    return (
      <ModalTour
        tourSteps={tour[tourKey] || []}
        containerStyle={containerStyle}
        currentStepId={currentStepId}
        onClose={closeTour || null}
      />
    );
  else return null;
};
