import React from "react";
import FilterLink from "../FilterLink";

const isAnyFilter = () => {
  return window.location.search.indexOf("recipient") === -1;
};

const TaskFilterByExtRecipient = props => {
  return (
    <React.Fragment>
      <li className="subnav-header">External Recipient</li>
      <li>
        <FilterLink
          id="any_people"
          filterObj={{
            recipient: null
          }}
          isActive={() => isAnyFilter()}
          onFilter={props.applyFilter}
        >
          Any
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="cc"
          filterObj={{
            recipient: "cc"
          }}
          onFilter={props.applyFilter}
        >
          Someone Else
        </FilterLink>
      </li>
    </React.Fragment>
  );
};

export default TaskFilterByExtRecipient;
