import ordinal from "ordinal";
// ported from fut-vcard-generator
// https://github.com/mailbots/fut-autocomplete/blob/9a49e1c6293facdeeaef1eeb3923b97343cfaa64/client/src/App.js#L1
export class FormatGenerator {
  constructor() {}

  _numInterval = ({
    maxInt,
    minInt = 1,
    timeWord,
    prepend = "",
    additionalItems = null
  }) => {
    let timeIntervals = [];
    for (let i = minInt; i <= maxInt; i++) {
      if (i === 1) {
        timeIntervals.push(prepend + i + timeWord.replace(/s$/, "")); // drop the "s"
      } else {
        timeIntervals.push(prepend + i + timeWord); // every2Days
      }
    }
    if (additionalItems) {
      timeIntervals = timeIntervals.concat(additionalItems);
    }
    return timeIntervals;
  };

  getHours = (maxInt = 18) =>
    this._numInterval({
      maxInt,
      timeWord: "hours",
      additionalItems: ["24hours", "36hours", "48hours"]
    });

  getDays = (maxInt = 14) =>
    this._numInterval({
      maxInt,
      timeWord: "days",
      additionalItems: ["30days", "45days", "60days", "90days"]
    });

  getWeeks = (maxInt = 8) =>
    this._numInterval({
      maxInt,
      timeWord: "weeks"
    });

  getMonths = (maxInt = 12) =>
    this._numInterval({
      maxInt,
      timeWord: "months",
      additionalItems: ["18months", "24months"]
    });

  getYears = (maxInt = 5) =>
    this._numInterval({
      maxInt,
      timeWord: "years"
    });

  getTimes12am = (maxInt = 12) =>
    this._numInterval({
      maxInt,
      timeWord: "am"
    });

  getTimes12pm = (maxInt = 12) =>
    this._numInterval({
      maxInt,
      timeWord: "pm"
    });

  getTimes24 = () =>
    this._numInterval({
      maxInt: 23,
      timeWord: "00"
    });

  getRecurringDaysOfMonth = () => {
    let formats = [];
    for (let i = 1; i <= 31; i++) {
      const nth = ordinal(i);
      formats.push(`every${nth}`);
    }
    return formats;
  };

  getDefaultFormats = (categorized = false) => {
    const categorizedDefaultFormats = {
      hours: this.getHours(),
      days: this.getDays(),
      weeks: this.getWeeks(),
      months: this.getMonths(),
      years: this.getYears(),

      times_12: this.getTimes12am().concat(this.getTimes12pm()),
      times_24: this.getTimes24(),

      day_names_full: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ],
      day_names_abbr: ["mon", "tues", "weds", "thur", "fri", "sat", "sun"],
      month_names_full: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
      ],
      recurring_common: ["everyDay", "everyWeekday", "daily"],
      recurring_days_of_week: [
        "everyMonday",
        "everyTuesday",
        "everyWednesday",
        "everyThursday",
        "everyFriday",
        "everySaturday",
        "everySunday"
      ],
      recurring_days_of_month: this.getRecurringDaysOfMonth()
    };

    const flattenedDefaultFormats = Object.values(
      categorizedDefaultFormats
    ).reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);

    return flattenedDefaultFormats;
  };
}
