import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import urlJoin from "url-join";

const loadBackBtnFromUrl = () => {
  const qs = queryString.parse(window.location.search);
  if (!qs.bk) return null;
  const backText = window.decodeURIComponent(qs.bk);
  const backPath = window.decodeURIComponent(qs.url);
  return { backText, backPath };
};

// builds a link that points to a page with the BackButton component.
// the BackButton looks for these values in URL to build the proper back button
export const returnableLink = ({ toPath, backPath, backText }) => {
  // @todo https://github.com/mailbots/fut-core-api/issues/2644 (Apostrophes break CORS)
  let safeBackText = backText.replace("'", "");
  const [baseLink, search] = toPath.split("?");
  let qs = queryString.parse(search);
  qs.bk = safeBackText;
  qs.url = backPath;
  return `${baseLink}?${queryString.stringify(qs)}`;
};

// Back Button that allows for overrides within the url `bk` and `url` params (see above returnableLink)
export const BackButton = ({ defaultTo, defaultBackText }) => {
  const linkStyle = {
    color: "#aaaaaa",
    margin: "-13px 0px 0px 10px"
  };
  return (
    <React.Fragment>
      {loadBackBtnFromUrl() ? (
        <Link to={loadBackBtnFromUrl().backPath} style={linkStyle}>
          <i className="glyphicon glyphicon-chevron-left" />{" "}
          {loadBackBtnFromUrl().backText}
        </Link>
      ) : (
        <Link to={defaultTo} style={linkStyle}>
          <i className="glyphicon glyphicon-chevron-left" />{" "}
          <span className="hide-for-mobile">{defaultBackText}</span>
        </Link>
      )}
    </React.Fragment>
  );
};
