import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { MicroHelpBlock } from "./MinForm";
import { message as antMessage } from "antd";
import {
  userDate,
  mailbotsAdminBrowser,
  handleWebhookResponseStatus,
  hasSkillErrors,
  getAutocompleteOptions
} from "./lib/utils";
import _ from "lodash";
import { TaskPreview } from "./TaskPreview";
import Modal from "./Modal";
import { AutoComplete, Popover } from "antd";
import { GlobalContext } from "./App";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  WarningOutlined
} from "@ant-design/icons";
const { Option } = AutoComplete;

function ScheduleWidget(props) {
  const { loggedInUser } = useContext(GlobalContext);

  const stripDomain = command => {
    if (!command) return "";
    return command.split("@")[0];
  };

  // init autocomplete options, starting with user's customized postpone times
  const getFilteredTimeFormats = partialTime => {
    const allTimeOpts = [
      ...(loggedInUser && loggedInUser.postpone_times
        ? loggedInUser.postpone_times.split(",")
        : []),
      ...getAutocompleteOptions()
    ];
    let filteredTimeOpts;
    if (!partialTime) filteredTimeOpts = allTimeOpts;
    else filteredTimeOpts = allTimeOpts.filter(t => t.includes(partialTime));
    return _.uniq(filteredTimeOpts);
  };

  const [task, setTask] = useState(props.getTask());
  const [previewTask, setPreviewTask] = useState(null);
  const [commandState, setCommandState] = useState(stripDomain(task.command));
  const [formatWarnings, setFormatWarnings] = useState(false); // if we should show format warnings based on format
  const [formatHasChanged, setFormatHasChanged] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previewThinking, setPreviewThinking] = useState(false);
  const [showPreview, setShowPreview] = useState(false); // dialog showing fully rendered task
  const [savingThinking, setSavingThinking] = useState(false);
  const [savingThinkingText, setSavingThinkingText] = useState("..saving task");
  const [availableSkills, setAvailableSkills] = useState([]); // loaded from api to render skill autocomplete

  // manage autocomplete state
  const [mode, setMode] = useState("time"); // time or skill. Depending on mode, it shows either time or skill autocomplete opts
  const [autoCompleteTimeOpts, setAutoCompleteTimeOpts] = useState(
    getFilteredTimeFormats()
  );
  const [autoCompleteSkillOpts, setAutocCompleteSkillOpts] = useState([]); // skills loaded async

  // override sub components
  const overrides = props.overrides ? props.overrides : {}; // props can internal components

  // When task props change, update task state
  useEffect(() => {
    setTask(task);
  }, [JSON.stringify(task)]);

  // Populate mailbot list for skills
  useEffect(() => {
    mailbotsAdminBrowser
      .getMailBots()
      .then(res => {
        setAvailableSkills(
          res.mailbots.filter(
            m => m.name.includes("(-") // flag must be in title ex: SMS (-sms)
          )
        );
      })
      .catch(e => {
        antMessage.error(e.message);
        console.log("Error fetching skills");
      });
  }, []);

  // cache mailbot icons
  useEffect(() => {
    const preloadMailbotIcons = mailbots => {
      mailbots.forEach(mailbot => (new Image().src = mailbot.icon));
    };
    if (availableSkills.length > 0) preloadMailbotIcons(availableSkills);
  });

  /**
   * Previewing a command requires the surrounding context of the task (to check, for example
   * if it is in the to/cc/bcc fields) and the logged in user (to warn about limits, etc).
   * The ScheduleWidget fetches a new context before every preview with the new command.
   */
  const handlePreviewTask = async command => {
    setPreviewThinking(true);

    // parent provides this callback to send latest state of the task before previewing, since
    // there may have been additional change. Ex: email content changed, etc.
    const taskContext = props.getTask();
    try {
      let previewPayload = {
        verbose: 1, // the rendered messages are shown only in full preview
        webhook: 1,
        task: {
          ...taskContext,
          command: getFormattedCommand(command)
        }
      };

      if (!previewPayload.task.command) return; // empty date format

      let previewRes = await mailbotsAdminBrowser.previewTask(previewPayload);
      handleWebhookResponseStatus(previewRes);

      const formatWarnings = hasSkillErrors(previewRes);
      setFormatWarnings(formatWarnings);

      let renderedPreviewTask = previewRes.task;
      renderedPreviewTask.messages = previewRes.messages; // The UI almost always tasks to own its messages @todo core API
      setPreviewTask(renderedPreviewTask);
      setPreviewThinking(false);

      return null;
    } catch (e) {
      console.error(e);
      setPreviewThinking(false);
    }
  };

  const previewTaskDebounced = useCallback(
    _.debounce(handlePreviewTask, 1500),
    []
  );

  const getFormattedCommand = cmd => {
    if (cmd) return `${cmd}@followupthen.com`.replace(/\s/g, "");
    return null;
  };

  // a task preview is continuously generated during the onChange event. This just shows it
  const handleShowPreview = event => {
    event.preventDefault();
    setShowPreview(true);
  };

  // when user clicks save button, set spinner and status text
  // accept newCommand directly as a workaround for async setState race conditions
  const handleOnSubmit = async (e, newCommandParam = null) => {
    if (e) e.preventDefault();
    setSavingThinking(true);
    try {
      await props.onSubmit(
        getFormattedCommand(newCommandParam || commandState)
      );
    } catch (e) {
      console.error(e);
      setSavingThinkingText("⚠️ Something went be wrong: " + e.message);
    }
    setSavingThinking(false);
  };

  let savingTextStarted = false; // prevent render function from continuing to invoke this after it has been started
  const getSavingThinkingText = () => {
    if (!savingTextStarted) {
      let stillWaiting = [
        "...contacting skills",
        "...waiting for skills",
        "...waiting for skills",
        "...waiting a few more seconds",
        "Something may be wrong. Please contact help@humans.fut.io"
      ];
      let waitStep = 0;
      const handle = setInterval(() => {
        if (!savingThinking) {
          window.clearInterval(handle);
          savingTextStarted = true;
          setSavingThinkingText("...saving task");
        }
        // hang on the last step if we make it there...
        const noMoreSteps = waitStep === stillWaiting.length - 1;
        if (noMoreSteps) waitStep = stillWaiting.length - 1;
        setSavingThinkingText(stillWaiting[waitStep]);
        ++waitStep;
      }, 4000);
      savingTextStarted = true;
    }
    return <MicroHelpBlock>{savingThinkingText}</MicroHelpBlock>;
  };

  const previewTaskIsReady = () =>
    previewTask && previewTask.command === getFormattedCommand(commandState);

  const HelpText = () =>
    !_.isUndefined(overrides.HelpText) ? (
      overrides.HelpText
    ) : (
      <span>&nbsp;</span>
    );

  const PreviewThinkingText = !_.isUndefined(overrides.PreviewThinkingText)
    ? overrides.PreviewThinkingText
    : () => (
        <span>
          ...verifying format.
          {props.onSubmit ? " You can still save without waiting." : null}
        </span>
      );

  const InvalidDateText = !_.isUndefined(overrides.InvalidDateText)
    ? overrides.InvalidDateText
    : () => (
        <span>
          <i className="glyphicon glyphicon-remove" style={{ color: "red" }} />{" "}
          This format is not yet supported. Try another variation. Here are some
          examples:
          <ul>
            <li>weds3pm</li>
            <li>330pm</li>
            <li>1500</li>
            <li>21JulyAt3pm</li>
            <li>every3rd</li>
            <li>every2ndMonday</li>
          </ul>{" "}
          (ex: weds9am, 1pmJan20th){" "}
          <a href="https://www.followupthen.com/how">More...</a>
        </span>
      );

  const ValidDateText = !_.isUndefined(overrides.ValidDateText)
    ? overrides.ValidDateText
    : props => (
        <span>
          {userDate({
            timestamp: props.triggerTimestamp,
            momentDateFormat: props.loggedInUser.preferred_date_format_js,
            timezone: props.loggedInUser.timezone
          })}{" "}
          {props.handleShowPreview && (
            <a onClick={props.handleShowPreview}>Preview</a>
          )}
        </span>
      );

  const ValidDateWithWarningText = !_.isUndefined(overrides.ValidDateText)
    ? overrides.ValidDateText
    : props => (
        <span>
          <i
            className="glyphicon glyphicon-warning-sign icon-orange"
            style={{ color: "orange" }}
          />{" "}
          {userDate({
            timestamp: props.triggerTimestamp,
            momentDateFormat: props.loggedInUser.preferred_date_format_js,
            timezone: props.loggedInUser.timezone
          })}{" "}
          {props.handleShowPreview && (
            <>
              <a onClick={props.handleShowPreview}>View task preview</a> for
              warnings
            </>
          )}
        </span>
      );

  const getStatusIcon = ({ previewThinking, savingThinking, validDate }) => {
    if (previewThinking && !savingThinking) {
      return (
        <div
          className="loader"
          style={{ display: "inline-block", height: 14, width: 14 }}
        />
      );
    } else if (!formatHasChanged) {
      return null;
    } else if (validDate === "valid") {
      return <QuestionCircleOutlined style={{ color: "#aaa" }} />;
    } else if (validDate === "warning") {
      return <WarningOutlined style={{ color: "orange" }} />;
    } else if (validDate === "invalid") {
      return <CloseCircleOutlined style={{ color: "red" }} />;
    } else {
      // empty state
      return null;
    }
  };

  const StatusText = ({
    validDate,
    triggerTimestamp,
    loggedInUser,
    previewThinking,
    savingThinking,
    handleShowPreview
  }) => {
    if (previewThinking && !savingThinking) {
      return <PreviewThinkingText />;
    } else if (savingThinking) {
      return getSavingThinkingText();
    } else if (!formatHasChanged) {
      return <HelpText />;
    } else if (validDate === "valid") {
      return (
        <ValidDateText
          loggedInUser={loggedInUser}
          triggerTimestamp={triggerTimestamp}
          handleShowPreview={handleShowPreview}
        />
      );
    } else if (validDate === "warning") {
      return (
        <ValidDateWithWarningText
          loggedInUser={loggedInUser}
          triggerTimestamp={triggerTimestamp}
          handleShowPreview={handleShowPreview}
        />
      );
    } else if (validDate === "invalid") {
      return <InvalidDateText />;
    } else {
      // empty state
      return null;
    }
  };

  const isEmptyState = !previewTask || !commandState;

  const isValidFormat = () => {
    if (!previewTask) return null;
    const validDate = !!previewTask.trigger_time;
    if (!validDate) return "invalid";
    // warnings come from skills that return warnings or errors via skillsLog
    if (validDate && formatWarnings) return "warning";
    return "valid";
  };

  /***
   * Dynamically change rendering of dropdown based on command "mode".
   * For example, if the command is "SMS -sms", the dropdown should show the skils list.
   * Another mode is simply showing the time formats. Another may be showing skill details.
   */

  // if last char is a "-", show skill autocomplete options nothing else. Early return
  const getInputMode = command => {
    if (!command) return false;
    if (command.search(/^[a-z0-9]+-/) === 0) return "skill"; // ex 3days-sms or 0900-sms or 3Years-sms- @todo account for ISO8601 format (ex: 2020-01-01) [a-z0-9]+-[^\d]
    return "time";
  };

  const handleScheduleInputChange = cmd => {
    if (!cmd) return resetAutoComplete();
    const command = cmd.split("@")[0]; // account for people appending the domain in the format ex: '3days@fut.io'
    setCommandState(command);
    setPreviewThinking(true);
    const mode = getInputMode(command);
    setMode(mode); // skill or command mode

    if (mode === "time") {
      const filteredTimeOpts = getFilteredTimeFormats(command);
      setAutoCompleteTimeOpts(filteredTimeOpts);
    } else if (mode === "skill") {
      // ex, match on whatever is after the very last "-" mark
      const partialSkillName = command.split("-").pop();
      const filteredSkills = getFilteredSkills(partialSkillName);
      setAutocCompleteSkillOpts(filteredSkills);
    }

    if (props.onChange) props.onChange(getFormattedCommand(command));
    setFormatHasChanged(true);
    previewTaskDebounced(command);
  };

  const getFilteredSkills = partialSkillName => {
    if (!partialSkillName) return availableSkills;
    const skillsWithFormatFlag = availableSkills.filter(
      m => m.name.includes("(-") // ex: SMS (-sms)  must be in title
    );
    const filteredSkills = skillsWithFormatFlag.filter(sk =>
      sk.name.includes(partialSkillName)
    );
    return filteredSkills;
  };

  // User clicked on or pressed "enter" on an autocomplete option
  // if we're searching skills, append skill flag to existing command
  const handleOptionSelect = option => {
    let newCommand;
    if (mode === "skill") {
      // blank state
      if (!commandState) return;
      // remove last, partially typed skill
      const [cmd, ...skills] = commandState.split("-");
      skills.pop();
      // add the full selected skill flag back
      const skillFlag = option;
      const newSkills = [...skills, skillFlag].join("-");
      newCommand = `${cmd}-${newSkills}`;
    } else {
      // set to selected time format
      newCommand = option;
    }
    handleScheduleInputChange(newCommand);
    clearAutoComplete();
    return newCommand;
  };

  /**
   * When date picker shows the "Save" button, hitting enter should hit the save
   * button. part of a larger form (ie, in create fut dialog), enter should not submit the form
   * Note that "enter" still works to select format from the autocomplete dropdown
   */
  const handleEnterKey = e => {
    const ENTER_KEYCODE = 13;
    if (e.keyCode !== ENTER_KEYCODE) return;
    clearAutoComplete();
    if (!props.onSubmit) {
      e.preventDefault();
    }
  };

  const clearAutoComplete = () => {
    setAutocCompleteSkillOpts([]);
    setAutoCompleteTimeOpts([]);
  };

  const resetAutoComplete = () => {
    setCommandState();
    setAutoCompleteTimeOpts(getFilteredTimeFormats());
    setAutocCompleteSkillOpts([]); // initial state shows only time options, no skills until "-"
  };

  return (
    <>
      {showPreview && (
        <Modal
          visible={true} // parent hides and shows this component
          onClose={() => setShowPreview(false)}
          style={{ zIndex: 1100 }}
        >
          <TaskPreview
            task={previewTask}
            loggedInUser={loggedInUser}
            handleOnClose={() => setShowPreview(false)}
          />
        </Modal>
      )}
      <div style={{ ...props.style, position: "relative" }}>
        <div style={{ margin: 0, padding: 0 }}>
          <div
            className="minform"
            style={{ width: "100%", minWidth: 240, position: "relative" }}
          >
            <form onSubmit={handleOnSubmit}>
              <AutoComplete
                ref={props.inputRef}
                value={commandState}
                onChange={handleScheduleInputChange}
                className={
                  props.className
                    ? `ant-select-customize-input ${props.className}`
                    : `ant-select-customize-input schedule-widget`
                }
                style={props.inputStyle}
                onFocus={e => {
                  e && e.target.select();
                  setFormatHasChanged(true); // people often reschedule for the same date / interval
                  setIsOpen(true);
                  if (props.onFocus) props.onFocus(e);
                }}
                open={isOpen}
                onBlur={e => {
                  setIsOpen(false);
                  if (props.onBlur) props.onBlur(e);
                }}
                onSelect={opt => {
                  const newCommand = handleOptionSelect(opt);
                  if (props.submitOnSelect) handleOnSubmit(null, newCommand);
                }}
                onKeyDown={handleEnterKey}
                placeholder={
                  props.placeholder ? props.placeholder : `ex: everyTues`
                }
                notFoundContent={null}
              >
                {mode === "time"
                  ? // Option must be directly used. HOC not supported here: https://github.com/ant-design/ant-design/issues/11909
                    autoCompleteTimeOpts.map(time => (
                      <Option key={time} value={time}>
                        {time}
                      </Option>
                    ))
                  : null}
                {mode === "skill"
                  ? // HOC not supported (see above)
                    autoCompleteSkillOpts.map(skill => (
                      <Option key={skill.subdomain} value={skill.subdomain}>
                        <div style={{ display: "flex" }}>
                          <img
                            src={skill.icon}
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "8px"
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between"
                            }}
                          >
                            <div>-{skill.subdomain}</div>
                            <div style={{ fontSize: "0.8em", color: "grey" }}>
                              {skill.name}
                              <a
                                onClick={e => e.stopPropagation()}
                                target="_blank"
                                href={`/skills/${skill.subdomain}`}
                              >
                                {" "}
                                <ArrowRightOutlined />
                              </a>
                            </div>
                          </div>
                        </div>
                      </Option>
                    ))
                  : null}
              </AutoComplete>
            </form>
            {/* submit button */}
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
              }}
            >
              {formatHasChanged && props.onSubmit ? (
                /* Only show save button if something has changed, and we have a handler for it */
                <SaveButton
                  handleOnSubmit={handleOnSubmit}
                  savingThinking={savingThinking}
                />
              ) : null}
            </div>
          </div>
          {previewTaskIsReady() && showPreview && (
            <div>
              <Modal
                visible={true} // parent hides and shows this component
                onClose={() => setShowPreview(false)}
                style={{ zIndex: 1100 }}
              >
                <TaskPreview task={previewTask} loggedInUser={loggedInUser} />
              </Modal>
            </div>
          )}
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            right: props.onSubmit ? 50 : 16, // no "save" button if no onSubmit
            display: "inline-flex",
            alignItems: "center",
            height: "100%"
          }}
        >
          {previewTask && previewTask.trigger_time ? (
            <span style={{ color: "#aaa", fontSize: 11, marginRight: 8 }}>
              {userDate({
                timestamp: previewTask ? previewTask.trigger_time : null,
                timezone: loggedInUser.timezone
              })}
            </span>
          ) : null}
          <Popover
            content={
              <StatusText
                initialState={isEmptyState}
                validDate={isValidFormat()}
                triggerTimestamp={previewTask ? previewTask.trigger_time : null}
                loggedInUser={loggedInUser}
                previewThinking={previewThinking}
                savingThinking={savingThinking}
                handleShowPreview={
                  !_.isUndefined(props.handleShowPreview)
                    ? props.handleShowPreview
                    : handleShowPreview
                }
              />
            }
            placement="top"
          >
            {getStatusIcon({
              previewThinking,
              savingThinking,
              validDate: isValidFormat()
            })}
          </Popover>
        </div>
      </div>
    </>
  );
}

ScheduleWidget.propTypes = {
  // task: PropTypes.object.isRequired,
};

export default ScheduleWidget;

// separate component to prevent rerendering during onBlur
const SaveButton = ({ handleOnSubmit, savingThinking }) => (
  <>
    {savingThinking ? (
      <div
        id="initial-loader"
        className="loader"
        style={{ height: 14, width: 14 }}
      />
    ) : (
      <a
        style={{
          textDecoration: "none",
          textTransform: "uppercase",
          fontSize: 11,
          color: "#11A2D2",
          padding: "5px"
        }}
        onClick={handleOnSubmit}
      >
        Save
      </a>
    )}
  </>
);
