import React from "react";
import FilterLink from "../FilterLink";

export default props => {
  return (
    <React.Fragment>
      <li className="subnav-header">Sort By</li>

      <li>
        <FilterLink
          id="due"
          filterObj={{
            order_by: "due"
          }}
          onFilter={props.applyFilter}
        >
          Due
        </FilterLink>
      </li>
      <li>
        <FilterLink
          id="created"
          filterObj={{
            order_by: "created"
          }}
          onFilter={props.applyFilter}
        >
          Created
        </FilterLink>
      </li>
    </React.Fragment>
  );
};
