import React, { Component } from "react";
import {
  getAccessToken,
  handleWebhookResponseStatus,
  mailbotsAdminBrowser
} from "./lib/utils";
import nprogress from "nprogress";
import { message as antMessage } from "antd";
import Loading from "./Loading";
import _ from "lodash";
import FooterMessage from "./FooterMessage";
import queryString from "query-string";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { DynamicJsonForm } from "./DynamicJsonForm";

class MailBotSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mailbotSettings: {}
    };
  }

  // forms can provide multiple custom submit buttons, each of which can affect the URL params. One
  // set of these can be passed at any given time.
  currentUrlParams; // this is kept in sync with the page url params, except in cases where rerendering triggers ♾loop
  setUrlParams({ urlParams, updateQS = true }) {
    if (this.currentUrlParams == urlParams) return; // prevent infinite loop
    if (urlParams && urlParams.coreSkillFlag) {
      delete urlParams.coreSkillFlag; // flag is used by fut-mailbot to load the proper core-skill settings page
    }
    const qs = queryString.stringify(urlParams ? urlParams : {});
    if (updateQS) {
      this.props.history.push(`${window.location.pathname}?${qs}`); // ⚠️Triggers react router rerender, thus loosing refs to the form!
    }
    this.currentUrlParams = urlParams;
  }

  getUrlParams() {
    if (this.currentUrlParams) {
      const params = this.currentUrlParams;
      if (!params.coreSkillFlag && this.props.mailbotFlag)
        params.coreSkillFlag = this.props.mailbotFlag;
    }
    let qs = queryString.parse(window.location.search);
    // Append mailbot coreSkillFlag (ex: &coreSkillFlag=sms) so fut-mailbot can route call to prope core skill
    qs.coreSkillFlag = this.props.mailbotFlag;
    return qs;
  }

  // params passed to a submit button are not showin in the URL. They do, however, make it into the
  // request payload under the `url_params` key
  handleOnSubmit = async (namespace, formData) => {
    try {
      nprogress.start();
      const saveSettingsRes = await mailbotsAdminBrowser.saveMailBotSettings({
        mailbotid: this.props.mailbotId,
        urlParams: this.getUrlParams() || null,
        settings: {
          [namespace]: formData
        }
      });
      if (
        saveSettingsRes.webhook &&
        saveSettingsRes.webhook.status == "failed"
      ) {
        console.error(saveSettingsRes.webhook.message);
        antMessage.error(saveSettingsRes.webhook.message);
        return;
      }
      handleWebhookResponseStatus(saveSettingsRes);
      // ex: bot.set("url_params", {"foo", "bar"}); // in a mailbot
      if (saveSettingsRes.url_params) {
        this.setUrlParams({ urlParams: saveSettingsRes.url_params });
      }
      await this.loadSettings();
      // rerender parent components
      this.props.reloadUser({ accessToken: getAccessToken() });
      antMessage.success("Settings saved");
      nprogress.done();
    } catch (e) {
      nprogress.done();
      await this.loadSettings();
      // rerender parent components
      this.props.reloadUser({ accessToken: getAccessToken() });
      antMessage.error(e.message);
    }
  };

  // Removes display-only settings, which start with "_"
  // to prevent them from being stored in mailbot.stored_data
  // TODO: Delete if unneeded
  getSavableData(formData) {
    console.log(formData);
    const savableData = {};
    for (const key in formData) {
      if (key.search(/^_.*/) !== 0) {
        savableData[key] = formData[key];
      }
    }
    return savableData;
  }

  handleOnError = errors => {
    antMessage.error(errors);
    console.log("There were form validation errors: ", errors);
    nprogress.done();
  };

  componentDidMount() {
    this.loadSettings();
  }

  loadSettings = async () => {
    try {
      nprogress.start();
      const params = {
        urlParams: this.getUrlParams(),
        mailbotid: this.props.mailbotId
      };
      const res = await mailbotsAdminBrowser.getMailBotSettings(params);
      if (_.get(res, "mailbot.webhook.status") === "failed") {
        throw new Error(
          `Something went wrong while fetching settings ${_.get(
            res,
            "mailbot.webhook.message"
          )}`
        );
      }
      handleWebhookResponseStatus(res);
      const preparedForms = this.prepareSettingsForms(res.settings);
      this.setState({ mailbotSettings: preparedForms });
      nprogress.done();
      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
      antMessage.error(e.message);
      nprogress.done();
    }
  };

  emptySettingsForm = {
    emptyDialog: {
      JSONSchema: {
        type: "object",
        properties: {
          dialog: {
            title: "Settings Not Implemented",
            type: "string"
          }
        }
      },
      uiSchema: {
        dialog: {
          "ui:widget": "customTextWidget",
          "ui:options": {
            label: false,
            text: "This mailbot does not have settings"
          }
        }
      }
    }
  };

  prepareSettingsForms = mailbotSettings => {
    const preparedForms = [];

    // https://github.com/rsweetland/followupthen/issues/2214
    function convertEmptyArraysToObjects(object) {
      for (const key in object) {
        if (object[key] instanceof Array && object[key].length === 0) {
          object[key] = {};
        }
      }
    }

    for (const namespace in mailbotSettings) {
      convertEmptyArraysToObjects(mailbotSettings[namespace]);
      preparedForms.push({
        form: mailbotSettings[namespace],
        namespace: namespace
      });
    }

    return preparedForms;
  };

  render() {
    if (
      Array.isArray(this.state.mailbotSettings) &&
      this.state.mailbotSettings.length &&
      !this.state.loading
    ) {
      // Render settings forms created by onSettingsViews webhook events
      // This page is rendered on both /skills/{id}/settings/{namespace} and /settings/{namespace}
      // Here we save the baseUrl so to the namespace routing / redirect works the same on both
      const searchExp = /(.*)\/settings.*/;
      const matches = searchExp.exec(window.location.pathname);
      const baseUrl = matches[1] + "/settings/";
      const firstNamespace = baseUrl + this.state.mailbotSettings[0].namespace;

      return (
        <Switch>
          {this.state.mailbotSettings.map(settingsForm => (
            <Route
              key={settingsForm.namespace}
              path={baseUrl + settingsForm.namespace}
              render={() => {
                const urlParams = _.get(
                  settingsForm,
                  "form.formMeta.urlParams"
                );
                if (urlParams && urlParams.coreSkillFlag)
                  delete urlParams.coreSkillFlag; // used internally only. Do not populate in query stirng.
                // only update qs if we are out of sync, lest we infinitely loop
                if (this.currentUrlParams !== urlParams) {
                  this.setUrlParams({ urlParams });
                }
                return (
                  <DynamicJsonForm
                    settingForm={settingsForm}
                    handleOnSubmit={this.handleOnSubmit.bind(this)}
                    loggedInUser={this.props.loggedInUser}
                    setUrlParams={this.setUrlParams.bind(this)}
                  />
                );
              }}
            />
          ))}

          {/* the fut-mailbot settings are embedded within the general settings page. When first loading, 
        it is incorrect to direct them to the first namespace. The "general" settings page shoudl render instead */}
          {window.location.pathname !== "/settings" && (
            <Redirect to={firstNamespace} />
          )}
        </Switch>
      );
    } else if (this.state.loading) {
      return <Loading loadingText="Loading settings..." />;
    } else {
      return (
        <FooterMessage>
          <h2>No Settings Here...</h2>
          <p>
            No settings were found. Need help?{" "}
            <a
              href="​https://help.followupthen.com/knowledge-base/privacy/"
              target="_blank"
              rel="nofollower noopener noreferrer"
            >
              Contact us
            </a>
          </p>
        </FooterMessage>
      );
    }
  }
}
export default withRouter(MailBotSettings);
