import React, { Component } from "react";
import { mailbotsAdminBrowser } from "./lib/utils";
import Loading from "./Loading";
import Card from "./Card";
import { Link } from "react-router-dom";

class MailBotUsageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userUsage: null
    };
  }

  async componentDidMount() {
    let userUsage = await mailbotsAdminBrowser.getMailBotUsers({
      mailbotid: this.props.mailbotid
    });
    this.setState({
      userUsage
    });
  }

  render = () => {
    return (
      <div>
        {this.state.userUsage ? (
          <Card style={{ padding: 5 }}>
            <div style={{ width: "100%", overflow: "scroll" }}>
              <h5>MailBot Users</h5>
              <p>
                Stats given are for the current month unless otherwise marked.
              </p>
              <table
                className="table table-striped"
                style={{ width: "100%", overflow: "scroll" }}
              >
                <thead>
                  <tr className="user-stats-header">
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Tasks Created</th>
                    <th>Total Pending Tasks</th>
                    <th>Last Emailed</th>
                    <th>Timezone</th>
                    <th>Sent (Mo)</th>
                    <th>Deferred (Mo)</th>
                    <th>Bounced (Mo)</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.userUsage.users &&
                    this.state.userUsage.users.map(user => (
                      <tr>
                        <td>{user.userid}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.task_created_monthly}</td>
                        <td>{user.task_pending}</td>
                        <td>{user.email_outbound_last_at}</td>
                        <td>{user.timezone}</td>
                        <td>{user.email_outbound_sent_monthly}</td>
                        <td>{user.email_outbound_deferred_monthly}</td>
                        <td>{user.email_outbound_bounced_monthly}</td>
                        <td>
                          <Link
                            to={`/developer/skills/${this.props.mailbotid}/logs?userid=${user.userid}`}
                          >
                            Logs
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        ) : (
          <Loading />
        )}
      </div>
    );
  };
}

export default MailBotUsageUsers;
