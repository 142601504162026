import cookie from "js-cookie";
import nprogress from "nprogress";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { message as antMessage } from "antd";
import { GlobalContext } from "./App";
import Card from "./Card";
import { GeneralSettings } from "./GeneralSettings";
import { ResponsiveSettingsSubnav } from "./Layout";
import { logger } from "./lib/Logger";
import { mailbotsAdminBrowser } from "./lib/utils";
import Loading from "./Loading";
import MailBotDetailsSettings from "./MailBotDetailsSettings";
import { SettingsMenu } from "./SettingsMenu";

function Settings() {
  const [futMailBot, setFutMailBot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loggedInUser, reloadUser } = useContext(GlobalContext);
  const history = useHistory();

  useEffect(() => {
    loadFutMailBot();
    displayLinkedEmailUrlMessage();
    logger.log("settings screen shown");
  }, []);

  /**
   * The "Customize" and "Notifications" settings are handled by fut-mailbot.
   * @todo Move these to their own skills.
   */
  const loadFutMailBot = async () => {
    const mailbotid = process.env.REACT_APP_FUT_MAILBOT_ID;
    if (!mailbotid)
      throw Error("FUT MailBot ID has not been set into env. See package.json");
    nprogress.start();
    setIsLoading(true);
    try {
      const res = await mailbotsAdminBrowser.getMailBot({ mailbotid });
      const mailbot = res.mailbot;
      nprogress.done();
      setFutMailBot(mailbot);
      setIsLoading(false);
    } catch (err) {
      nprogress.done();
      logger.log("error loading futmailbot from general settings", {
        data: { error_obj: err }
      });
      antMessage.error("Error loading settings. Please contact us for help");
      setIsLoading(false);
    }
  };

  // Handle when user confirms linking an email
  const displayLinkedEmailUrlMessage = () => {
    const qs = queryString.parse(window.location.search) || {};
    const fromEmail = decodeURIComponent(qs.gfr);
    if (qs["link-email"] == "success") {
      antMessage.success(`New email successfully linked to your account.`);
      const redirect = cookie.get("mailbotsRedirect");
      if (redirect) {
        history.push(redirect);
        cookie.remove("mailbotsRedirect");
      }
    } else if (qs["link-email"] == "failed") {
      antMessage.error(`Something went wrong linking ${fromEmail}.
      Has it been already linked? If not, please contact us for help.`);
    }
  };

  return (
    <div>
      <div className="main-container" style={{ maxWidth: 600 }}>
        <div className="main-middle">
          {isLoading ? (
            <Loading />
          ) : (
            <Card className="settings-card" type="large">
              <Route
                key="general_settings_new"
                exact
                path="/settings"
                render={() => <GeneralSettings />}
              />
              <Route
                key="fut_mailbot"
                render={() =>
                  futMailBot && (
                    <MailBotDetailsSettings
                      mailbotId={futMailBot.id}
                      mailbotFlag={futMailBot.subdomain}
                      loggedInUser={loggedInUser}
                      reloadUser={reloadUser}
                      // callback to dynamically render mailbot submenu (see git history for details)
                      // setSettingsSubmenu={this.setSettingsSubmenu}
                    />
                  )
                }
              />
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
