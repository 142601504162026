import React, { Component, useContext, useState } from "react";
import { mailbotsAdminBrowser, isIgnoringDuplicateAccounts } from "./lib/utils";
import { message as antMessage } from "antd";
import nprogress from "nprogress";
import Alert from "./Alert";
import { Link } from "react-router-dom";
import * as cookie from "js-cookie";
import * as moment from "moment-timezone";
import { ignoreDuplicateAccounts, hasDuplicateAccounts } from "./lib/utils";
import queryString from "query-string";
import { logger } from "./lib/Logger";
import { Popover } from "antd";
import { GlobalContext } from "./App";

const style = {
  banner: {
    backgroundColor: "#333333",
    padding: 7,
    color: "white",
    textAlign: "center",
    fontSize: 11,
    position: "relative",
    zIndex: 5,
    link: {
      color: "white",
      textDecoration: "underline"
    }
  }
};

const IGNORE_TZ_WARN_COOKIE = "ignoreTzDiscrepancy";
class BannerFactory extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    try {
      // check if the user's browser timezone offset
      // is different from the server-side timezone offset saved in their settings
      // ref: https://github.com/mailbots/fut-admin-ui/issues/115#issuecomment-882657289
      const browserTz = moment.tz.guess();
      const userTz = this.props.loggedInUser.timezone;

      const browserTzOffset = moment.tz(moment.utc(), browserTz).utcOffset();
      const userTzOffset = moment.tz(moment.utc(), userTz).utcOffset();

      this.setState({
        userHasTzDiscrepancy: userTzOffset !== browserTzOffset,
        showTimezoneBanner: cookie.get(IGNORE_TZ_WARN_COOKIE) !== "true"
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleReendValidationEmail = async e => {
    e.preventDefault();
    try {
      nprogress.start();
      let res = await mailbotsAdminBrowser.resendValidation({
        email: this.props.loggedInUser.email
      });
      if (res instanceof Error) throw res;
      antMessage.success(res.message);
      nprogress.done();
    } catch (e) {
      nprogress.done();
      console.error(e);
      antMessage.error(e.message);
    }
  };

  render() {
    const Banner = ({ children }) => (
      <div style={style.banner}>
        <span className="glyphicon glyphicon-bell" />
        {children}
      </div>
    );

    const ValidationEmailBanner = () => (
      <Alert level="grey">
        &nbsp; We sent a confirmation email to{" "}
        {this.props.loggedInUser.email || ""}. Did you get it?
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://help.followupthen.com/knowledge-base/my-followups-are-getting-bounced-or-deferred-by-my-email-provider/"
          target="_blank"
          rel="nofollower noopener noreferrer"
        >
          Help
        </a>
        &nbsp;&nbsp;&nbsp;
        <a onClick={this.handleReendValidationEmail}>Resend</a>
      </Alert>
    );

    const AccountSoftLocked = () => {
      const [modalVisible, setModalVisible] = useState();
      const { reloadUser } = useContext(GlobalContext);
      return (
        <Alert level="warning">
          Your account has been temporarily locked.{" "}
          <Popover
            content={
              <div style={{ maxWidth: 600, padding: 20 }}>
                <p>
                  <strong>Why was my account locked?</strong>
                </p>
                <p>
                  In order to maintain platform health and bring account
                  problems to your attention, we may lock accounts which:
                </p>
                <ul>
                  <li>
                    Are having follouwp delivery problems (ie, our emails are
                    bouncing)
                  </li>
                  <li>
                    Schedule high volumes of followups (paid accounts have
                    higher limits)
                  </li>
                  <li>Try to schedule too many followups before validating</li>
                </ul>
                <p>
                  You can unlock your account a limited number of times before
                  it requires a FollowUpThen staff member to assist. Need help?{" "}
                  <a
                    target="_blank"
                    rel="nofollower noopener noreferrer"
                    href="https://help.followupthen.com/contact"
                  >
                    Contact us
                  </a>
                  .
                </p>

                <button
                  onClick={async e => {
                    e.persist();
                    try {
                      e.target.innerText = "...unlocking";
                      await mailbotsAdminBrowser.userUpdateSelf({
                        user: {
                          flagged: 0
                        }
                      });
                      await reloadUser();
                      antMessage.success("Account unlocked");
                    } catch (e) {
                      antMessage.error("Error unblocking account");
                      console.error(e);
                    }
                    e.target.innerText = "Unlock Account";
                  }}
                  className="btn btn-primary"
                >
                  Unlock Account
                </button>
              </div>
            }
          >
            <span style={{ textDecoration: "underline", cursor: "pointer" }}>
              Unlock Account
            </span>
          </Popover>
        </Alert>
      );
    };

    const AccountHardLocked = ({ userWarning }) => (
      <Alert level="danger">
        Your account has been locked{" "}
        {userWarning === "delivery_errors" ? (
          <span>due to delivery errors</span>
        ) : null}
        . To unlock your account, please fix or unlink the{" "}
        <Link
          to="/delivery-logs"
          style={{ color: "white", textDecoration: "underline" }}
        >
          bouncing emails
        </Link>
        , then{" "}
        <a
          target="_blank"
          rel="nofollower noopener noreferrer"
          style={{ color: "white", textDecoration: "underline" }}
          href="https://help.followupthen.com/contact"
        >
          contact us
        </a>{" "}
        to unlock your account.
      </Alert>
    );

    const UserWarning = ({ type }) => {
      // tmp - core-api initially listed alternate bounce warning for delivery errors
      // These should be automatically cleaned up by users via the UI. Verify with below query before removing
      // select count(*) from user where settings like '%"userWarning":"A followup%';
      if (type && type.includes("could not be delivered")) {
        type = "delivery_errors";
      }
      let warning;
      switch (type) {
        case "delivery_errors":
          warning = (
            <div>
              ⚠️ Delivery problems detected on your account.{" "}
              <Link to="/delivery-logs">Fix Problems</Link>
            </div>
          );
          break;
      }
      if (warning) {
        return <Alert level="grey">{warning}</Alert>;
      } else {
        logger.log("unrecognized user warning", {
          level: "error",
          error_str: warning
        });
        return null;
      }
    };

    const TeamWarnAlreadyAMember = () => (
      <Alert level="warning">
        It looks like your account is already part of a company account. You may
        have already accepted this (or another) company invitation. Feel free to{" "}
        <a href="https://help.followupthen.com/contact">contact us</a> for help.
      </Alert>
    );

    const DuplicateAccountBanner = () => (
      <Alert dissmissable={false} level="grey">
        ⚠️ Duplicate account detected.{" "}
        <Link to="/settings">Link your emails under one account</Link>.{" "}
        <a
          style={{ color: "#aaa" }}
          onClick={e => {
            e.preventDefault();
            ignoreDuplicateAccounts();
            logger.log("ignored duplicate account warning");
            this.setState({ hideAlert: false });
          }}
        >
          Ignore this message.
        </a>
      </Alert>
    );

    const TimezoneWarnBanner = () => {
      const ignoreTzWarn = () => {
        cookie.set(IGNORE_TZ_WARN_COOKIE, "true");
        this.setState({ showTimezoneBanner: false });
      };
      return (
        <Alert level="grey">
          Change timezones? <Link to="/settings">Update your settings</Link> or{" "}
          <a onClick={ignoreTzWarn}>ignore</a>.
        </Alert>
      );
    };

    const isMergingEmails = window.location.pathname.includes("settings");

    let arrayOfBanners = [];
    if (
      hasDuplicateAccounts() &&
      !isIgnoringDuplicateAccounts() &&
      !isMergingEmails &&
      this.props.loggedInUser.accepted_terms // dont' show during onboarding
    ) {
      logger.log("duplicate account banner shown");
      arrayOfBanners.push(<DuplicateAccountBanner />);
    }
    if (this.props.loggedInUser && !this.props.loggedInUser.is_validated) {
      arrayOfBanners.push(<ValidationEmailBanner />);
    }
    if (this.props.loggedInUser && this.props.loggedInUser.flagged === 1) {
      arrayOfBanners.push(<AccountSoftLocked />);
    }
    if (this.props.loggedInUser && this.props.loggedInUser.flagged === 2) {
      arrayOfBanners.push(
        <AccountHardLocked userWarning={this.props.loggedInUser.user_warning} />
      );
    }
    if (this.props.loggedInUser && this.props.loggedInUser.user_warning) {
      arrayOfBanners.push(
        <UserWarning type={this.props.loggedInUser.user_warning} />
      );
    }
    if (
      this.state.userHasTzDiscrepancy &&
      this.state.showTimezoneBanner &&
      !!this.props.loggedInUser.timezone &&
      this.props.loggedInUser.accepted_terms // dont' show during onboarding
    ) {
      arrayOfBanners.push(<TimezoneWarnBanner />);
    }

    if (
      queryString.parse(window.location.search)["error-type"] ===
      "already-a-member"
    ) {
      // Legacy employees upgrading to the new comany account should not see this error.
      // @todo Uncomment after all legacy users are migrated
      // arrayOfBanners.push(<TeamWarnAlreadyAMember />);
    }

    return arrayOfBanners.length ? (
      <div
        style={{
          width: "92%",
          maxWidth: 600,
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          zIndex: 1
        }}
      >
        {arrayOfBanners.map((banner, i) => (
          <div key={i}>{banner}</div>
        ))}
      </div>
    ) : null;
  }
}

export default BannerFactory;
