import React from "react";
import Card from "../Card";
import Modal from "../Modal";

const ConfirmModal = props => {
  return (
    <Modal visible={props.isVisible} onClose={() => props.onCloseModal()}>
      <Card type="large" style={{ maxWidth: 500 }}>
        <h3 className="modal-title" id="deleteModalLabel">
          {props.title}
        </h3>
        <p> {props.description}</p>
        <button
          onClick={() => {
            props.onSubmit();
          }}
          type="button"
          className="btn btn-primary"
          disabled={props.isBusy}
        >
          {!props.isBusy ? props.buttonText : props.buttonVerbingText}
        </button>
        {"  "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </button>
      </Card>
    </Modal>
  );
};

export default ConfirmModal;
