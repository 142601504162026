import { Popover } from "antd";
import React from "react";
import styled from "styled-components";

/**
 * @todo: Instead of inclinde styles, use classNames from style.css
 * .main-container, etc. These are already responsive and will save work.
 */

export const Container = ({ children, className = "", style = {} }) => (
  <div
    style={{
      width: "95vw",
      maxWidth: 800,
      margin: "auto",
      paddingTop: 20,
      display: "flex",
      ...style
    }}
    className={`layout-container ${className}`}
  >
    {children}
  </div>
);

/**
 * Right-hand fixed menu for CreateTask, TaskDetails, Person, etc.
 * This usually contains modals. Becuase it is position fixed, it creates
 * a new z-index stacking context for all of its children.
 */
export const RightFixedMenu = ({ children, className = "", style = {} }) => (
  <div
    style={{
      flex: 1,
      margin: 20,
      marginTop: 55,
      position: "relative",
      ...style
    }}
    className={`layout-right-menu ${className}`}
  >
    <div
      className="layout-right-menu-contents"
      style={{ position: "fixed", zIndex: 1, width: 250 }}
    >
      {" "}
      {children}
    </div>
  </div>
);

/**
 * On subpages that show details of somethign: A task, person, create task, create person, etc.
 * This is whatever "Item" we're dealing with
 */
export const ItemContent = ({ children, className = "", style = {} }) => (
  <div style={{ flex: 4 }} className={`layout-item ${className}`}>
    {children}
  </div>
);

/**
 * Settings Submenu Container
 */
export const ResponsiveSettingsSubnav = styled.div.attrs({
  className: "main-left subnav"
})`
  @media (max-width: 768px) {
    right: 95% !important;
  }
`;

export const BlueCallout = ({ children, style = {} }) => (
  <div
    style={{
      backgroundColor: "rgb(235, 245, 255)",
      padding: 20,
      margin: 20,
      ...style
    }}
  >
    {children}
  </div>
);

export const GreyMonoblock = ({ children, style = {} }) => (
  <div
    style={{
      backgroundColor: "#fafafa",
      padding: 10,
      fontFamily: "monospace",
      whiteSpace: "normal",
      overflow: "auto",
      width: "95%",
      textAlign: "left",
      ...style
    }}
  >
    {children}
  </div>
);

export const GreyCallout = ({ children, style = {} }) => (
  <div
    style={{
      padding: "47px",
      background: "#f5f5f5",
      borderRadius: "11px",
      margin: "0",
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "49px",
      ...style
    }}
  >
    {children}
  </div>
);

/** @todo Replace the native Antd Popover with this  */
export const PopoverStyled = ({ content, children }) => (
  <Popover content={<div style={{ maxWidth: 300 }}>{content}</div>}>
    {" "}
    <span style={{ color: "#11A2D2", cursor: "pointer" }}>{children}</span>
  </Popover>
);
