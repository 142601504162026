import React from "react";
import { useState } from "react";
import { GreyLink } from "./MinForm";

/**
 * Render a list of items that is initially collapsed
 * to a max number of items. Rendering is done using
 * a function that returns jsx passed by props.
 */
function ExpandableList({ items, maxItems = 5, renderItem }) {
  const [expanded, setExpanded] = useState(false);

  const viewableItems =
    Array.isArray(items) && items.slice(0, expanded ? items.length : maxItems);
  const canBeExpanded = items && items.length > maxItems;
  const moreItemsCount = items && items.length - maxItems;
  return (
    <>
      {viewableItems ? viewableItems.map(item => renderItem(item)) : null}

      {/* show toggle */}
      {canBeExpanded ? (
        <a
          className="muted-link"
          onClick={e => {
            e.preventDefault();
            setExpanded(!expanded);
          }}
        >
          {expanded ? "Hide" : `...and ${moreItemsCount} more`}
        </a>
      ) : null}
    </>
  );
}

export default ExpandableList;
