import React, { useContext, useEffect, useState } from "react";
import { ThanksForUpgrading } from "./TourThanksForUpgrading";
import { message as antMessage } from "antd";
import queryString from "query-string";
import { GlobalContext } from "../App";
import { WelcomeToV3Tour } from "./TourWelcomeToV3";
import {
  getTourForUser,
  isOnV3,
  sendGtmEvent,
  stripAllQueryParams
} from "../lib/utils";

export function ModalTourFactory(props) {
  const [showNewUserWelcome, setShowNewUserWelcome] = useState(false);
  const [showUpgradeThanksModal, setShowUpgradeThanksModal] = useState(false);
  const [showEmailValidatedModal, setShowEmailValidatedModal] = useState(false);
  const [v3TourActiveStepId, setV3TourActiveStepId] = useState(null); // move user directly to step based on state
  const [v3TourKey, setV3TourKey] = useState("newUser");

  const { loggedInUser } = useContext(GlobalContext);

  const qs = queryString.parse(window.location.search);

  // Show ant message if user is not validated
  useEffect(() => {
    if (showEmailValidatedModal) {
      sendGtmEvent("email_validated", {
        category: "signup",
        action: "email_validated",
        label: "from_email"
      });
      antMessage.success("Your email has been validated!");
    }
  }, [showEmailValidatedModal]);

  useEffect(() => {
    // Control by qs: http://localhost:3000/skills?tour=existingTeamOwner&stepId=invite-team
    setV3TourActiveStepId(qs.stepId || "welcome-new-user");
    setV3TourKey(qs.tour || "newUser");

    // upgrade thanks modal can appear a z-index above a pending modal tour
    if (qs.upgradedPlan) {
      setShowUpgradeThanksModal(true);
    }

    const { welcome, tour } = qs;
    // user just clicked validation link..only show toast, not dialog
    if (welcome === "new-user" && loggedInUser && loggedInUser.accepted_terms) {
      setShowEmailValidatedModal(true);
    } else {
      const tourInfo = getTourForUser(loggedInUser, { welcome, tour });
      setShowNewUserWelcome(!!tourInfo.dialog);
      setV3TourActiveStepId(tourInfo.stepId);
      setV3TourKey(tourInfo.tourKey);
    }
  }, [JSON.stringify(loggedInUser), JSON.stringify(qs)]);

  if (!loggedInUser) return null;

  return (
    <div>
      {showUpgradeThanksModal ? (
        <ThanksForUpgrading
          containerStyle={{ zIndex: 5 }}
          visible={showUpgradeThanksModal}
          onClose={() => {
            stripAllQueryParams();
            setShowUpgradeThanksModal(false);
          }}
          closeTour={() => setShowUpgradeThanksModal(false)}
        />
      ) : null}
      {showNewUserWelcome ? (
        <WelcomeToV3Tour
          switchToV3={props.switchToFutV3}
          currentStepId={v3TourActiveStepId}
          tourKey={v3TourKey}
          closeTour={null} // can only skip from within the tour
        />
      ) : null}{" "}
      {/* @todo optional tour after validating */}
      {/* {showEmailValidatedModal ? (
        <TourEmailValidated
          closeTour={() => {
            stripAllQueryParams();
            setShowEmailValidatedModal(false);
          }}
        />
      ) : null}{" "} */}
    </div>
  );
}
