import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { message as antMessage } from "antd";
import { CSVLink } from "react-csv";
import queryString from "query-string";
import nprogress from "nprogress";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import { logger } from "../lib/Logger";
import { ReactComponent as PedistalGuy } from "../images/PedistalGuy.svg";

// import { this.mailbotsAdminBrowser } from "../lib/utils";
import NavLeft from "../NavLeft";
import { SearchBox } from "../SearchBox";
import { getActiveSkills } from "../lib/getActiveSkills";
import { BulkActions } from "../BulkActions";
import Loading from "../Loading";
import FooterMessage from "../FooterMessage";
import TaskListItem from "./TaskListItem";
import TaskFilterByDueDate from "./TaskFilterByDueDate";
import TaskFilterBySkill from "./TaskFilterBySkill";
import TaskFilterByExtRecipient from "./TaskFilterByExtRecipient";
import TaskFilterByStatus from "./TaskFilterByStatus";
import TaskFilterByDeliveryAddress from "./TaskFilterByDeliveryAddress";
import RescheduleModal from "./RescheduleModal";
import FromEmailAddressModal from "./FromEmailAddressModal";
import TaskSortBy from "./TaskSortBy";
import TasksSortOrder from "./TasksSortOrder";
import Expandable from "../Expandable";
import {
  cacheTasks,
  getCachedTasks,
  invalidateTaskCache,
  taskCacheIsStale
} from "./TaskCache";
import CreateFutModal from "./CreateFutModal";
import ConfirmModal from "./ConfirmModal";
import { checkActiveFutBillingLimits } from "../Billing/billingHelpers";
import Alert from "../Alert";
import { ButtonOnBg } from "../ButtonOnBg";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  InteractionOutlined,
  RedoOutlined,
  SendOutlined,
  StopOutlined
} from "@ant-design/icons";
import { isOnMobile } from "../lib/hookisMobile";
import {
  getMailbotsAdminBrowser,
  naturalTs,
  mailbotsAdminBrowser
} from "../lib/utils";
import moment from "moment";

class Tasks extends Component {
  NUM_RESULTS = 30;
  modalPreRunDone = () => {};
  mailbotsAdminBrowser = mailbotsAdminBrowser; // populate this.mailbotsAdmiBrowser to test race condition

  constructor(props) {
    super(props);

    this.state = {
      hasPageLoaded: false,
      tasks: [],
      showCreateFutModal: false,
      showRescheduleModal: false,
      showConfirm: false,
      confirmModal: {
        title: "",
        description: "",
        buttonText: "",
        buttonVerbingText: ""
      },
      showUpdateFromEmailModal: false,
      viewRestartCount: 0,
      bulkActionIsRunning: false,
      expandTaskFilter: false,
      taskFilter: {},
      mailbots: [],
      selectedMailBot: null,
      isSearching: false, // for showing search icon
      activeSkills: [],
      isCreatingFut: false,
      searchIsOpen: false,
      scrollY: 0,
      loadingTasks: false
    };

    this.taskSearchRef = null; // focus / blur search box handling
    this.mainContainerRef = React.createRef();
  }

  /**
   * Lifecycle hooks.
   */

  /**
   * Note: "search" applies to url query. (see above)
   */
  componentDidMount = async () => {
    try {
      // this.mailbotsAdminBrowser = getMailbotsAdminBrowser();
      // check if the user has been redirected
      // from the people page -> send messages action
      // and open the create FUT modal
      // recipient emails will be passed as a comma separated array
      this.showWelcomFromQs(this.props.location.search);
      const search = queryString.parse(this.props.location.search);
      if (search.openCreateFut === "true") {
        this.setState({
          showCreateFutModal: true,
          createFutRecipients: decodeURIComponent(search.recipients).split(",")
        });
      }
      if (search.search) {
        this.setState({
          isSearching: true,
          searchIsOpen: true
        });
      }
      logger.log("viewed followups");

      await this.setStateFromUrl(this.props.location.search);
      await this.loadMailBots();
      if (this.state.tasks.length === 0) await this.loadTasks(0);
      this.setState({ hasPageLoaded: true, isSearching: false });

      // for huge monitors, load one more page of tasks. This has to be done after the tasks have loaded,
      // setTimeout is a hack. When we refactor this to use hooks, we should useLayoutEffect which depends on tasks state.
      let handle = setInterval(async () => {
        if (!this.windowHigherThanTaskList()) {
          clearInterval(handle);
          return;
        } else if (
          this.state.tasks &&
          this.state.tasks.length &&
          this.windowHigherThanTaskList() &&
          this.state.hasMoreItems &&
          !this.state.loadingTasks
        ) {
          console.log("loading more tasks for large screen");
          this.handleInfiniteScroll();
        }
      }, 2000); // must be greater than handleInfiniteScroll debounce value
    } catch (error) {
      console.error(error);
    }

    window.onscroll = e => {
      this.setState({ scrollY: window.scrollY });
    };
  };

  windowHigherThanTaskList = () => {
    if (!this.mainContainerRef.current) return;
    const taskListContainerHeight = this.mainContainerRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    return windowHeight > taskListContainerHeight;
  };

  // @todo deprecated
  showWelcomFromQs(searchStr) {
    const qs = queryString.parse(searchStr);
    if (qs.welcome === "migrated-user") {
      this.setState({
        welcomeMigrationModal: true
      });
    }
    // @todo redirect to new-user in admin UI
    if (qs.welcome === "new-user" || qs.welcome === "1") {
      this.setState({
        showNewUserWelcome: true
      });
    }
    if (qs.welcome === "team") {
      this.setState({
        showNewTeamWelcome: true
      });
    }
  }

  closeWelcomeMigrationModal = () => {
    this.setState({
      welcomeMigrationModal: false
    });
  };

  closeWelcomeNewUserModal = () => {
    this.setState({
      showNewUserWelcome: false
    });
  };

  closeWelcomeTeamUserModal = () => {
    this.setState({
      showNewTeamWelcome: false
    });
  };

  /**
   * Note: The the "search" variable confusingly applies
   * to he query param of the URL here, not the search
   * text of the input box or fitler.
   */
  UNSAFE_componentWillReceiveProps = async nextProps => {
    this.showWelcomFromQs(window.location.search);
    try {
      const search = this.props.location.search;
      const newSearch = nextProps.location.search;
      if (newSearch !== search) {
        await this.setStateFromUrl(newSearch);
        this.loadSelectedMailBot(this.state.mailbots, this.state.taskFilter);
        this.taskPage = 0; // reset pagination
        await this.loadTasks(this.taskPage);

        this.setState({ isSearching: false });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * This component treats the URL as the single source of truth. Actions first
   * change the URL, which then get saved to state with this method.
   */
  setStateFromUrl = async search => {
    return new Promise((resolve, reject) => {
      let taskFilter = queryString.parse(search);
      this.setState(
        {
          taskFilter,
          search: taskFilter.search ? taskFilter.search : ""
        },
        resolve
      );
    });
  };

  /**
   * Applies filters to URL which are persisted in state with setStateFromUrl().
   * Props change automatically when URL changes thanks to withRouter()
   *
   * In Sum:
   * 1. applyFilter() updates url
   * 2. setStateFromUrl() state changes
   * 3. TaskListContaner props update thanks to withRouter
   *
   * Then, within TaskListContainer:
   * 4. loadTasks
   * 5. getTaskFilter sets filters, merging it with default filter values
   *
   **/
  applyFilter = (e, filter) => {
    if (e) e.preventDefault();
    let newFilter = { ...this.state.taskFilter, ...filter };

    //when the filter_* property is set to null, delete related filters
    for (let k in newFilter) {
      if (newFilter[k] === null) {
        delete newFilter[k];
        if (k === "filter_due") {
          delete newFilter["due_before"];
          delete newFilter["due_after"];
          delete newFilter["filter_due"];
        } else if (k === "skills") {
          delete newFilter["skills"];
        } else if (k === "recipient") {
          delete newFilter["recipient"];
        } else if (k === "status") {
          delete newFilter["status"];
        } else if (k === "delivery_addr") {
          delete newFilter["delivery_addr"];
        } else if (k === "filter_mailbot") {
          delete newFilter["filter_mailbot"];
          delete newFilter["mailbot"];
        } else if (k === "filter_person") {
          delete newFilter["filter_person"];
          delete newFilter["person"];
        }
      }
    }
    let qs = queryString.stringify(newFilter);
    this.props.history.push("?" + qs);

    logger.log("filtered followups", { data: { filter_obj: newFilter } });
  };

  /**
   * Populate mailbots
   */
  loadMailBots = async () => {
    try {
      let res = await this.mailbotsAdminBrowser.getMailBots({
        inc_installed: 1,
        inc_published: 1
      }); // fetch both installed + public so we can show native skills (-c) an (-t)
      this.loadSelectedMailBot(res.mailbots, this.state.taskFilter); //TODO: Best practices: Set state in one place?
      const activeSkills = await getActiveSkills(res.mailbots);
      this.setState({
        mailbots: res.mailbots,
        activeSkills
      });
    } catch (e) {
      console.log(e);
      // this.props.location.history.push("/login");
      antMessage.error(
        "There was an error loading your skills" || JSON.stringify(e)
      );
    }
  };

  /**
   * Derive which mailbot is selected, populate it in state.
   */
  loadSelectedMailBot = (mailbots, taskFilter) => {
    if (taskFilter.skills) {
      const selectedMailbotSubdomain = taskFilter.skills.replace("sk_", "");
      const selectedMailBot = mailbots.filter(
        mailbot => mailbot.subdomain === selectedMailbotSubdomain
      )[0];
      this.setState({ selectedMailBot });
    } else {
      this.setState({ selectedMailBot: null });
    }
  };

  handleSearchOnChange = e => {
    let search = e.target.value || null;
    this.setState({ search });
    if (search === null) {
      this.applyFilter(e, { search }); //reset search if query is deleted
    }
  };

  handleSearch = e => {
    e.preventDefault();
    if (this.state.search) {
      this.setState({ isSearching: true });
      this.applyFilter(e, {
        search: this.state.search
      });
    }
    logger.log("user is searching followups");
  };

  handleSearchOnClear = e => {
    this.setState({ isSearching: false, searchIsOpen: false });
    // only if search content populated was entered
    if (this.state.search) {
      this.applyFilter(e, { search: null });
    }
    logger.log("cleared search");
  };

  toggleCreateFutModal = async e => {
    try {
      e.preventDefault();
      const billingLimits = await checkActiveFutBillingLimits(
        this.props.loggedInUser
      );
      if (billingLimits.remainingFuts < 1) {
        antMessage.warn(
          "You've reached your monthly followup limit. In order to create new followups, please upgrade your plan."
        );
      } else {
        this.setState({ showCreateFutModal: true });
      }
      logger.log("create fut dialog opened");
    } catch (error) {
      antMessage.error(error.message);
    }
  };

  /**
   * Load MailBots Tasks.
   * Called on upon initial mount, whenever the props change
   * and during infinite scroll.
   * Note behavior regarding cached tasks. When tasks are marked as stale, this entire
   * method is executed, the state is set with the earlier cached state. It then finally
   * invalidates cach cache and reloads itself with fresh data. (Ie, the Stale while refresh technique)
   */
  loadTasks = async (page = 0) => {
    const getTaskFilter = taskFilter => {
      let filter = {
        // hide the message body. Optimizes listing, but requires loading each individual task
        verbose: false,
        page: page,
        per_page: taskFilter.limit || this.NUM_RESULTS,
        order_by: taskFilter.order_by || "due", //created | completed_on
        order_dir: taskFilter.order_dir || "asc",
        status: taskFilter.status || "open", //completed
        due_after: taskFilter.due_after || Math.floor(Date.now() / 1000), // naturalTs("now")
        // tmp - once https://github.com/mailbots/fut-core-api/issues/2790 is fixed, revert this commit
        // due_after: taskFilter.due_after || null, // naturalTs("now")
        search_keys: taskFilter.attributes || []
      };
      if (taskFilter.mailbot) filter.mailbot = taskFilter.mailbot;
      if (taskFilter.search) filter.search = taskFilter.search;
      if (taskFilter.due_before) filter.due_before = taskFilter.due_before;
      // the FUT is sent to the email from which it was scheduled, so the core-api filters on 'from'
      // while all user-facing language references "deliver address"
      if (taskFilter.delivery_addr) filter.from = taskFilter.delivery_addr;
      if (taskFilter.skills) filter.search_keys.push(taskFilter.skills);
      if (taskFilter.status === "completed") {
        filter.order_by = "completed_on";
        filter.order_dir = "desc";
        filter.due_after = null; // remove after mailbots/fut-core-api#2790 is done
      }
      if (taskFilter.recipient) filter.search_keys.push(taskFilter.recipient);
      if (taskFilter.personid) filter.personid = taskFilter.personid;
      if (taskFilter.invisible) filter.invisible = taskFilter.invisible; // background tasks. For example, user setting to send a daily summary of followups
      if (taskFilter.tags) {
        filter.search_keys = taskFilter.tags
          .split(",")
          .map(t => window.decodeURIComponent(t.trim())); // example: ?tags=sk_recurring or ?tags=msgid_%3CCAEJ2LrOQQAzXW%2B-UzV8gVfGq%3DSdecTkxXgX31dviEvN%3DCHK2Dg%40mail.gmail.com%3E
      }

      // @todo de-duplicate above tags filter
      if (taskFilter.search_keys) {
        filter.search_keys = taskFilter.search_keys
          .split(",")
          .map(t => window.decodeURIComponent(t.trim())); // example: ?tags=sk_recurring or ?tags=msgid_%3CCAEJ2LrOQQAzXW%2B-UzV8gVfGq%3DSdecTkxXgX31dviEvN%3DCHK2Dg%40mail.gmail.com%3E
      }

      for (let key in filter) {
        if (filter[key] === null) {
          delete filter[key];
        }
      }
      return filter;
    };

    try {
      nprogress.start();
      this.setState({ loadingTasks: true });
      let filter = getTaskFilter(this.state.taskFilter);
      let res = { tasks: [] };
      if (page === 0) {
        res.tasks = getCachedTasks(this.props.location.search);
      }

      if (res.tasks.length === 0) {
        res = await this.mailbotsAdminBrowser.getTasks(filter);
        console.log("tasks list loaded by http req");
      } else {
        console.log("tasks list loaded from cache");
      }

      // console.log(axiosCache.store);
      nprogress.done();
      this.setState({ loadingTasks: false });

      if (res instanceof Error) throw new Error(res);
      if (!res.tasks || res.tasks.length == 0) {
        let noTasks = null;
        let tasks = this.state.tasks;
        if (filter.page === 0) {
          noTasks = true;
          tasks = [];
        }
        return this.setState({
          hasMoreItems: false,
          tasksLoaded: true,
          noTasks,
          tasks
        });
      }
      const hasMoreItems = () => {
        const hasMore = res.tasks.length < this.NUM_RESULTS ? false : true;
        return hasMore;
      };
      let allTasks = res.tasks;

      if (filter.page > 0) {
        allTasks = [...this.state.tasks, ...allTasks]; // add to existing tasks if we're paginating
      }
      const uniqueTasks = _.uniqBy(allTasks, task => task.id);
      this.setState({
        tasks: uniqueTasks,
        tasksLoaded: true,
        noTasks: false,
        hasMoreItems: hasMoreItems()
      });

      if (taskCacheIsStale()) {
        // now that stale data is there as a placeholder, run it again with refreshed data (ie, stale while refresh)
        invalidateTaskCache();
        this.loadTasks(page);
      } else {
        cacheTasks(this.props.location.search, uniqueTasks);
      }
    } catch (e) {
      nprogress.done();
      this.setState({
        tasksLoaded: true
      });
      //TODO: Add error status codes to mailbots-js-sdk
      if (e.message.includes("Your login information was incorrect.")) {
        console.log("Login Redirect from getTasks 401 error");
      } else {
        antMessage.error(e.message);
        console.log("Error: Unknown task list error: ", e.message);
      }
      this.props.history.push("/login");
    }
  };

  taskPage = 1;
  handleInfiniteScroll = _.debounce(() => {
    if (!this.state.loadingTasks) {
      this.loadTasks(this.taskPage);
      this.taskPage++;
    }
  }, 1000);

  /**
   * Run an api call for a step of a bulk action.
   * Will throw errors/warnings in a format that
   * <BulkActions/> component understands.
   */
  runBulkActionStep = async (apiCallPromise, task) => {
    const taskUrl = `/tasks/${task.id}`;
    try {
      const result = await apiCallPromise;
      if (result.statusCode === 200) {
        // check for skill errors
        if (
          result.webhook &&
          result.webhook.passthrough_data &&
          result.webhook.passthrough_data.skillsLog
        ) {
          console.log("got passthorugh data");
          const errorLogs = result.webhook.passthrough_data.skillsLog.filter(
            log => log.status === "error"
          );

          if (errorLogs.length > 0) {
            const logs = errorLogs.map(log => ({
              text: log.skillResponse || `There was an error with ${log.name}`,
              iconUrl:
                log.icon_url || "https://files-38348em97-mailbots.vercel.app/"
            }));

            console.warn("got skill warning", logs);
            throw {
              type: "warning",
              message: "",
              link: {
                url: taskUrl,
                name: task.reference_email.subject
              },
              logs
            };
          }
        }
      } else {
        throw {
          type: "error",
          message: result.status,
          link: {
            url: taskUrl,
            name: task.reference_email.subject
          }
        };
      }
    } catch (error) {
      // if this is a custom error, throw it as is
      if (!!error.type) throw error;

      // throw other http error
      throw {
        statusCode: error.statusCode,
        message: error.message,
        link: {
          url: taskUrl,
          name: task.reference_email.subject
        }
      };
    }
  };

  getSelectedTasks() {
    return this.state.tasks.filter(task => task.selected);
  }

  // all tasks grouped by time section
  getTasksGroupedByTime = tasks => {
    let alreadyChunkedTaskIds = [];
    const getTaskChunkBetween = (naturalStartOrTS, naturalEndOrTS) => {
      const startingTs =
        typeof naturalStartOrTS === "number"
          ? naturalStartOrTS
          : naturalTs(naturalStartOrTS);
      const endingTs =
        typeof naturalEndOrTS === "number"
          ? naturalEndOrTS
          : naturalTs(naturalEndOrTS);

      const filteredTasks = tasks
        // by time
        .filter(
          task => task.trigger_time > startingTs && task.trigger_time < endingTs
        )
        // exclude if already in another chunk
        .filter(task => !alreadyChunkedTaskIds.includes(task.id));
      if (!filteredTasks.length) return null;

      alreadyChunkedTaskIds = alreadyChunkedTaskIds.concat(
        filteredTasks.map(task => task.id)
      );
      return filteredTasks;
    };

    let chunkedTasks = [
      {
        title: "Today",
        tasks: getTaskChunkBetween("now", "11:59:59 pm tonight")
      },
      {
        title: "Tomorrow",
        tasks: getTaskChunkBetween("11:59:59pm tonight", "11:59:59pm tomorrow")
      },
      {
        title: "This Week",
        tasks: getTaskChunkBetween("now", "11:59:59pm saturday")
      },
      {
        title: "Next Week",
        tasks: getTaskChunkBetween(
          "11:59:59pm saturday",
          naturalTs("11:59:59pm saturday", { advance: { days: 7 } })
        )
      },
      {
        title: "Future",
        tasks: getTaskChunkBetween(
          naturalTs("11:59:59pm saturday", { advance: { days: 7 } }),
          "in 1000 years"
        )
      },
      {
        title: "Past",
        tasks: getTaskChunkBetween("20 years ago", "now")
      }
    ];

    // log error and debug info if grouping logic leaves out any tasks
    if (alreadyChunkedTaskIds.length !== tasks.length) {
      let missingTasksArr = tasks
        .filter(task => alreadyChunkedTaskIds.includes(task.id))
        .map(task => new Date(task.trigger_time * 1000).toString());
      logger.log("task date grouping error", {
        level: "error",
        data: { missingTasksArr }
      });
    }

    return chunkedTasks;
  };

  selectTaskId(id, selected) {
    const newTasks = this.state.tasks.map(task => {
      if (task.id === id) task.selected = selected;

      return task;
    });
    this.setState({ tasks: newTasks });
  }

  getEmptyTasksDialog = () => {
    const NoTasksForMailBot = () => (
      <FooterMessage>
        <PedistalGuy />
        <h2 style={{ fontFamily: "Lora" }}>No Tasks Found For Skill</h2>
        <br />
        <p>
          Try searching{" "}
          <a
            href=""
            onClick={e => this.applyFilter(e, { status: "completed" })}
          >
            completed followups
          </a>
          .
        </p>
        <p>
          {" "}
          How to use{" "}
          <Link to={`/skills/${this.state.selectedMailBot.id}`}>
            {this.state.selectedMailBot.name || ""}
          </Link>{" "}
        </p>
      </FooterMessage>
    );

    const NoTasksFoundForQuery = () => (
      <FooterMessage>
        <PedistalGuy />
        <h2 style={{ fontFamily: "Lora" }}>No Followups Found</h2>
        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
          <li>
            Try searching{" "}
            <a
              href=""
              onClick={e => this.applyFilter(e, { status: "completed" })}
            >
              completed followups
            </a>
          </li>
          <li>
            Do you use{" "}
            <a
              href="https://help.followupthen.com/knowledge-base/linking-details/"
              target="_blank"
              rel="noopener noreferrer"
            >
              multiple emails
            </a>
            ?
          </li>
        </ul>
      </FooterMessage>
    );

    const NoTasksAtAll = () => (
      <FooterMessage>
        <PedistalGuy />
        <h2 style={{ fontFamily: "Lora" }}>Schedule A Followup</h2>
        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
          <li>Create a new followup above </li>
          <li>Try a 1 minute experiment by emailing 1min@fut.io</li>
          <li>
            Save keystrokes with{" "}
            <a
              href=""
              onClick={() => this.props.history.push("/skills/autocomplete")}
            >
              autocomplete
            </a>
          </li>
        </ul>
      </FooterMessage>
    );

    if (this.state.selectedMailBot && this.state.selectedMailBot.id)
      return <NoTasksForMailBot />;
    if (this.props.history.location.search) return <NoTasksFoundForQuery />;
    return <NoTasksAtAll />;
  };

  toggleAllTasks(selected) {
    const newTasks = this.state.tasks.map(task => {
      task.selected = selected;
      return task;
    });
    this.setState({ tasks: newTasks });
  }

  anyTasksAreChecked = () => this.state.tasks.some(task => !!task.selected);

  refreshTasks = async () => {
    // reload tasks
    invalidateTaskCache();
    this.setState({ viewRestartCount: this.state.viewRestartCount + 1 });
    await this.loadTasks(0);
    this.toggleAllTasks(false);
  };

  // handle reschedule
  finishReschedulePreRun;
  handleReschedulePreRun = finishPreRun => {
    this.setState({ showRescheduleModal: true });
    this.finishReschedulePreRun = finishPreRun;
    logger.log("bulk reschedule clicked");
  };

  handleRescheduleDialogSubmit = newCommand => {
    this.setState({ bulkActionIsRunning: true });
    this.finishReschedulePreRun(newCommand);
    logger.log("bulk reschedule running");
  };

  // handle delete modal actions
  finishDeletePreRun;
  handleDeletePreRun = finishPreRun => {
    this.setState({ showDeleteModal: true });
    this.finishDeletePreRun = finishPreRun;
    logger.log("bulk delete clicked");
  };

  handlDeleteDialogSubmit = () => {
    this.setState({ bulkActionIsRunning: true });
    this.finishDeletePreRun(true); // continue
    logger.log("bulk delete running");
  };

  // handle generic confirm modal actions
  finishConfirmPreRUn;
  handleConfirmPreRun = finishPreRun => {
    this.setState({ showConfirmModal: true });
    this.finishConfirmPreRUn = finishPreRun;
  };

  handleConfirmPreRunSubmit = () => {
    this.setState({ bulkActionIsRunning: true });
    this.finishConfirmPreRUn(true); // continue
  };

  resetConfirmModal = () => {
    this.setState({ showConfirmModal: false, bulkActionIsRunning: false });
  };

  // handle update from email modal actions
  finishUpdateFromEmailPreRun;
  handleUpdateFromEmailPreRun = finishPreRun => {
    this.setState({ showUpdateFromEmailModal: true });
    this.finishUpdateFromEmailPreRun = finishPreRun;
    logger.log("bulk from email update clicked");
  };

  handlUpdateFromEmailDialogSubmit = newEmailAddress => {
    this.setState({ bulkActionIsRunning: true });
    this.finishUpdateFromEmailPreRun(newEmailAddress);
    logger.log("bulk from email update running");
  };

  render() {
    const selectedTasksToCSV = () =>
      this.getSelectedTasks().map(task => ({
        id: task.id,
        contacts: task.allContacts,
        subject: task.reference_email.subject,
        from: task.reference_email.from,
        created: task.created,
        due: task.trigger_time
      }));

    const csvHeaders = [
      { label: "Product ID", key: "id" },
      { label: "Contacts", key: "contacts" },
      { label: "Subject", key: "subject" },
      { label: "From", key: "from" },
      { label: "Created At", key: "created" },
      { label: "Due", key: "due" }
    ];

    const confirmPromise = msg =>
      new Promise((resolve, reject) => {
        let confirmed = window.confirm(msg);
        return confirmed ? resolve(true) : reject(false);
      });

    const bulkActions = [
      {
        name: "Cancel",
        glyph: <StopOutlined />,
        preRun: async finishPreRun => {
          this.setState(state => {
            const confirm = state.confirmModal;
            confirm.title = "Are you sure?";
            confirm.description =
              "This will cancel the selected followups. They are retained for 6 weeks in the 'completed' section (filter > completed).";
            confirm.buttonText = "Complete Tasks";
            confirm.buttonVerbingText = "Completing...";
            return state;
          });
          this.handleConfirmPreRun(finishPreRun);
        },
        bulkIterator: task => {
          const promise = this.mailbotsAdminBrowser.completeTask({
            task: { id: task.id },
            webhook: true
          });
          return this.runBulkActionStep(promise, task);
        },
        postRun: async (errors, warnings) => {
          this.resetConfirmModal();
          this.setState({ bulkActionIsRunning: false });
          await this.refreshTasks();
          // @todo: If errors or warnings, force display of ErrorModal. Else, antMessage.success()
          if (errors.length) {
            antMessage.error("one or more tasks could not be completed");
          } else if (warnings.length) {
            antMessage.warn("one or more tasks were completed with warnings");
          } else {
            antMessage.success("tasks successfully completed");
          }
        }
      },
      {
        name: "Follow Up Now",
        glyph: <SendOutlined />,
        preRun: async finishPreRun => {
          this.setState(state => {
            const confirm = state.confirmModal;
            confirm.title = "Send selected followups?";
            confirm.description = `This sends selected followups as if they  \
              were due now. Followups are left incomplete with their original due date.`;
            confirm.buttonText = "Send Followups";
            confirm.buttonVerbingText = "Sending...";
            return state;
          });
          this.handleConfirmPreRun(finishPreRun);
        },
        bulkIterator: task => {
          const promise = this.mailbotsAdminBrowser.triggerTask({
            trigger_url: task.trigger_url,
            webhook: true
          });
          return this.runBulkActionStep(promise, task);
        },
        postRun: async (errors, warnings) => {
          await this.refreshTasks();
          this.resetConfirmModal();
          if (errors.length) {
            antMessage.error("one or more tasks could not be triggered");
          } else if (warnings.length) {
            antMessage.warn("one or more tasks were triggered with warnings");
          } else {
            antMessage.success("tasks successfully triggered");
          }
        }
      },
      {
        name: "Reschedule",
        glyph: <CalendarOutlined />,
        preRun: finishPreRun => {
          this.handleReschedulePreRun(finishPreRun);
        },
        bulkIterator: (task, newCommand) => {
          const triggerTime = newCommand.split("@")[0];
          const promise = this.mailbotsAdminBrowser.updateTask({
            task: {
              id: task.id,
              command: newCommand
            },
            webhook: true
          });
          return this.runBulkActionStep(promise, task);
        },
        postRun: (errors, warnings) => {
          this.refreshTasks();
          this.setState({ bulkActionIsRunning: false });
          this.setState({ showRescheduleModal: false });
          if (errors.length) {
            antMessage.error("one or more tasks could not be rescheduled");
          } else if (warnings.length) {
            antMessage.warn("one or more tasks were rescheduled with warnings");
          } else {
            antMessage.success("tasks successfully rescheduled");
          }
        }
      },
      {
        name: "Change Delivery Address",
        glyph: <InteractionOutlined />,
        preRun: finishPreRun => {
          this.handleUpdateFromEmailPreRun(finishPreRun);
        },
        bulkIterator: (task, newEmailAddress) => {
          const promise = this.mailbotsAdminBrowser.updateTask({
            task: {
              id: task.id,
              reference_email: {
                from: newEmailAddress
              }
            },
            webhook: true
          });
          return this.runBulkActionStep(promise, task);
        },
        postRun: (errors, warnings) => {
          this.setState({ bulkActionIsRunning: false });
          this.setState({ showUpdateFromEmailModal: false });
          if (errors.length) {
            antMessage.error("one or more tasks could not be update");
          } else if (warnings.length) {
            antMessage.warn("one or more tasks were updated with warnings");
          } else {
            antMessage.success("tasks successfully updated");
          }
          return this.refreshTasks();
        }
      },
      {
        name: "Download CSV",
        glyph: <DownloadOutlined />,
        bulkIterator: () => {},
        customJsx: () => (
          <CSVLink
            key={this.getSelectedTasks().length}
            filename="my-tasks.csv"
            data={selectedTasksToCSV()}
            headers={csvHeaders}
          >
            <DownloadOutlined />
            <span> Download (.csv)</span>
          </CSVLink>
        )
      },
      {
        name: "Delete",
        glyph: <DeleteOutlined />,
        preRun: async finishPreRun => {
          this.setState(state => {
            const confirm = state.confirmModal;
            confirm.title = "Are you sure?";
            confirm.description = `This will permanently delete the selected followups.`;
            confirm.buttonText = "Delete Tasks";
            confirm.buttonVerbingText = "Deleting...";
            return state;
          });
          this.handleConfirmPreRun(finishPreRun);
        },
        bulkIterator: task => {
          const promise = this.mailbotsAdminBrowser.deleteTask({
            task: { id: task.id },
            webhook: true
          });
          return this.runBulkActionStep(promise, task);
        },
        postRun: async (errors, warnings) => {
          await this.refreshTasks();
          this.resetConfirmModal();
          this.setState({ bulkActionIsRunning: false });
          if (errors.length) {
            antMessage.error("one or more tasks could not be deleted");
          } else if (warnings.length) {
            antMessage.warn("one or more tasks were deleted with warnings");
          } else {
            antMessage.success("tasks successfully deleted");
          }
        }
      }
    ];

    const TaskSectionPanel = ({ tasks, title }) => {
      if (!tasks || !tasks.length) return null;
      return (
        <div
          className="list-container"
          style={{ paddingTop: 15, borderRadius: 7 }}
        >
          {title ? (
            <>
              <h1
                style={{
                  fontFamily: "Lora",
                  margin: 0,
                  marginLeft: 45,
                  marginTop: isOnMobile() ? -10 : 0,
                  fontSize: isOnMobile() ? 30 : 40
                }}
              >
                {title}
              </h1>
              <hr style={{ margin: 2 }} />
            </>
          ) : null}
          {tasks.map(task => (
            <TaskListItem
              {...task}
              key={task.id}
              select={this.selectTaskId.bind(this)}
            />
          ))}
        </div>
      );
    };

    return (
      <div
        className="main-container"
        ref={this.mainContainerRef}
        style={{ overflowAnchor: "none" }}
      >
        <div
          className="main-middle"
          style={{ maxWidth: 650, margin: "auto", width: "100%" }}
        >
          <CreateFutModal
            isVisible={this.state.showCreateFutModal}
            loggedInUser={this.props.loggedInUser}
            recipients={this.state.createFutRecipients}
            onCloseModal={() => this.setState({ showCreateFutModal: false })}
            onStart={() => {
              nprogress.start();
            }}
            onSuccess={() => {
              this.refreshTasks();
              nprogress.done();
              antMessage.info("We've got you covered");
              this.setState({ showCreateFutModal: false });
            }}
            onError={message => {
              antMessage.error(message);
              nprogress.done();
            }}
          />
          <RescheduleModal
            isVisible={this.state.showRescheduleModal}
            isBusy={this.state.bulkActionIsRunning}
            onCloseModal={() => this.setState({ showRescheduleModal: false })}
            onSubmit={this.handleRescheduleDialogSubmit}
            loggedInUser={this.props.loggedInUser}
          />
          <ConfirmModal
            isVisible={this.state.showConfirmModal}
            isBusy={this.state.bulkActionIsRunning}
            onCloseModal={() => this.setState({ showConfirmModal: false })}
            onSubmit={this.handleConfirmPreRunSubmit}
            title={this.state.confirmModal.title}
            description={this.state.confirmModal.description}
            buttonText={this.state.confirmModal.buttonText}
            buttonVerbingText={this.state.confirmModal.buttonVerbingText}
          />
          <FromEmailAddressModal
            isVisible={this.state.showUpdateFromEmailModal}
            isBusy={this.state.bulkActionIsRunning}
            selectedTasks={this.getSelectedTasks()}
            loggedInUser={this.props.loggedInUser}
            onCloseModal={() =>
              this.setState({ showUpdateFromEmailModal: false })
            }
            onSubmit={this.handlUpdateFromEmailDialogSubmit}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 8,
              transition: "all 0.2s ease-in-out",
              height: 45,
              width: "102%",
              marginLeft: "-1%",
              zIndex: 2,
              paddingRight: 8,
              paddingLeft: 8,
              ...(this.state.scrollY > 50
                ? {
                    position: "sticky",
                    top: 1,
                    backgroundColor: "white",
                    borderRadius: 5,
                    boxShadow: "2px 2px 20px 0px #ccc"
                  }
                : {})
            }}
          >
            {/* left-side bulk actions */}
            <div>
              {this.anyTasksAreChecked() ? (
                <BulkActions
                  actions={bulkActions}
                  items={this.getSelectedTasks()}
                  totalItemsCount={this.state.tasks.length}
                  toggleAll={checked => this.toggleAllTasks(checked)}
                ></BulkActions>
              ) : null}
            </div>
            {/* right-side actions */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative"
              }}
            >
              <a
                href=""
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                <ButtonOnBg
                  text={isOnMobile() ? `` : `Filter`}
                  icon={
                    !_.isEmpty(this.state.taskFilter)
                      ? "FilterFilled"
                      : "FilterOutlined"
                  }
                />
              </a>
              <ul
                className={`dropdown-menu ${
                  isOnMobile() ? `dropdown-menu-right` : `dropdown-menu-left`
                }`}
                role="menu"
                style={{ padding: 15, overflowY: "auto", maxHeight: "80vh" }}
              >
                {!_.isEmpty(this.state.taskFilter) ? (
                  <li>
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        this.props.history.push("/tasks");
                        this.setState({
                          isSearching: false,
                          searchIsOpen: false
                        });
                      }}
                    >
                      <CloseOutlined /> Clear Filters
                    </a>
                    <hr />
                  </li>
                ) : null}
                <TaskFilterByStatus applyFilter={this.applyFilter} />
                <TaskFilterByDueDate applyFilter={this.applyFilter} />
                <TaskFilterByDeliveryAddress applyFilter={this.applyFilter} />
                <TaskFilterByExtRecipient applyFilter={this.applyFilter} />
                <TaskSortBy applyFilter={this.applyFilter} />
                <TasksSortOrder applyFilter={this.applyFilter} />
                <TaskFilterBySkill
                  activeSkills={this.state.activeSkills}
                  applyFilter={this.applyFilter}
                />
              </ul>
              {!this.state.searchIsOpen ? (
                <ButtonOnBg
                  text={isOnMobile() ? `` : `Search`}
                  icon="SearchOutlined"
                  onClick={() => this.setState({ searchIsOpen: true })}
                />
              ) : null}
              {this.state.searchIsOpen ? (
                <SearchBox
                  placeholder={`Subject or Person`}
                  handleOnChange={this.handleSearchOnChange}
                  handleSearch={this.handleSearch}
                  handleSearchOnClick={this.handleSearch}
                  handleSearchOnClear={this.handleSearchOnClear}
                  value={this.state.search}
                  isSearching={this.state.isSearching}
                  setRef={ref => {
                    if (!ref) return;
                    this.taskSearchRef = ref;
                    this.taskSearchRef.focus();
                    this.taskSearchRef.onBlur = () => {
                      if (!this.state.search) {
                        this.setState({ searchIsOpen: false });
                      }
                    };
                  }}
                  style={{ width: 180, margin: 4 }}
                  inputStyle={{ paddingRight: 27 }}
                  iconRight={
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        this.handleSearchOnClear();
                      }}
                    >
                      <CloseOutlined style={{ fontSize: 14, color: "#333" }} />
                    </a>
                  }
                />
              ) : null}
              <ButtonOnBg
                text={isOnMobile() ? `` : `New`}
                icon="PlusOutlined"
                onClick={e => this.toggleCreateFutModal(e)}
              />
            </div>
          </div>

          {this.state.taskFilter.status === "completed" ? (
            <Alert level="grey">
              Completed followups are retained for 6 weeks.
            </Alert>
          ) : null}

          {!this.state.hasPageLoaded ? (
            <Loading key="loading" />
          ) : this.state.tasks.length === 0 ? (
            this.getEmptyTasksDialog()
          ) : (
            <InfiniteScroll
              useWindow
              pageStart={0}
              initialLoad={false}
              loadMore={this.handleInfiniteScroll.bind(this)}
              hasMore={this.state.hasMoreItems}
              loader={<Loading key="loading" />}
              key={this.state.viewRestartCount}
            >
              {this.getTasksGroupedByTime(this.state.tasks).map(
                (group, index) => (
                  <TaskSectionPanel
                    key={index}
                    tasks={group.tasks}
                    title={group.title}
                  />
                )
              )}
            </InfiniteScroll>
          )}
        </div>
        <div style={{ height: 50 }}>{/* room to scroll */}</div>
      </div>
    );
  }
}

export default withRouter(Tasks);
