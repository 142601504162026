import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import nprogress from "nprogress";
import { mailbotsAdminBrowser } from "./lib/utils";
import Loading from "./Loading";
import MailBotDetails from "./MailBotDetails";
import { message as antMessage } from "antd";
import queryString from "query-string";
import { logger } from "./lib/Logger";

class MailBotDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mailbot: null
    };
  }

  async refreshMailBot() {
    nprogress.start();
    const mailbotid = this.props.mailbotid || this.state.mailbot.id;
    if (!mailbotid) return;
    try {
      const res = await mailbotsAdminBrowser.getMailBot({ mailbotid });
      const mailbot = res.mailbot;

      mailbot.owner = mailbot.owner.email;

      // Take developer right to Sandbox after installing
      // if (
      //   this.isMailBotOwner(mailbot) &&
      //   this.justInstalled() &&
      //   mailbot.devmode
      // ) {
      //   this.props.history.push(`/sandbox/${mailbotid}?hello`);
      // } else {
      //   // antMessage.success("MailBot  installed and ready for use!");
      // }

      nprogress.done();
      this.setState({
        isLoading: false,
        mailbot: mailbot
      });
    } catch (err) {
      nprogress.done();
      console.log(err);
      this.setState({ isLoading: false });
    }
  }

  isMailBotOwner(mailbot) {
    return !!mailbot.oauth2_client_secret;
  }

  justInstalled() {
    // MailBots usually goes to welcome settings when first installed
    return this.props.location.pathname.includes("settings/welcome");
  }

  componentDidMount() {
    this.refreshMailBot();
    logger.log("skills details screen shown");
  }

  UNSAFE_componentWillReceiveProps() {
    this.refreshMailBot();
  }

  uninstallMailBot = async () => {
    nprogress.start();
    try {
      await mailbotsAdminBrowser.uninstallMailBot({
        mailbotid: this.state.mailbot.id
      });
      antMessage.success(
        "Skill deactivated! Feedback? We'd love to hear help@humans.fut.io"
      );
    } catch (err) {
      antMessage.error(err.message);
      nprogress.done();
      this.setState({ isLoading: false });
    }
    this.refreshMailBot();
  };

  getPermissions() {
    return (
      <div>
        <h4>Permissions</h4>
        <ul>
          <li>{this.oauth2_scope}</li>
          <li>Manage Reminders</li>
          <li>Manage Self</li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div>
        {/* <Link to="/skills">
            <i className="glyphicon glyphicon-chevron-left" /> Skills
          </Link> */}
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <MailBotDetails
            mailbot={this.state.mailbot}
            uninstallMailBot={this.uninstallMailBot}
            loggedInUser={this.props.loggedInUser}
            reloadUser={this.props.reloadUser}
          />
        )}
      </div>
    );
  }
}

export default withRouter(MailBotDetailsContainer);
