import React from "react";
import Modal from "./Modal";
import Card from "./Card";
/**
 * Modal with self-contained state of being open / closed. Use Modal.jsx for more flexibility.
 * These should probably be combined at some point.
 */
class ModalSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: true
    };
  }

  render() {
    return (
      <Modal
        visible={
          this.props.visible === undefined
            ? this.state.modalVisible
            : this.props.visible
        }
        style={this.props.containerStyle}
        onClose={this.props.onClose}
      >
        <Card
          type="small"
          style={{
            padding: 5,
            width: "95vw",
            maxWidth: 1200,
            maxHeight: "100vh", // allows verfical scrollin
            textAlign: "center",
            ...this.props.style
          }}
          className={this.props.className}
        >
          {this.props.children}
        </Card>
      </Modal>
    );
  }
}

export default ModalSimple;
