import React from "react";

const FloatingCard = props => (
  <section
    style={{
      width: "95vw",
      maxWidth: 500,
      paddingTop: 30,
      marginLeft: "auto",
      marginRight: "auto"
    }}
  >
    <div className="text-center">
      {props.header}
      <div className="main-content card">{props.children}</div>
    </div>
    <div className="text-center text-muted">{props.footer}</div>
  </section>
);

export default FloatingCard;
