import React from "react";
import styled from "styled-components";
import * as AntIcons from "@ant-design/icons";

const IconBg = styled.div`
  border-radius: 4px;
  color: #333;
  height: 2px;
  padding: 15px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
  &:active {
    background-color: #d5d5d5;
  }
`;

export function ButtonOnBg(props) {
  const { icon, text, onClick, ...rest } = props;
  const IconComponent = AntIcons[icon];
  return (
    <IconBg onClick={onClick} {...rest}>
      {icon ? <IconComponent /> : null}
      {text ? <span style={{ marginLeft: 4 }}>{text}</span> : null}
    </IconBg>
  );
}
