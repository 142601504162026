import React, { Component } from "react";
import nprogress from "nprogress";
import {
  mailbotsAdminBrowser,
  getAuthorizedAccounts,
  setAccessToken
} from "./lib/utils";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import mailbotsHero from "./images/mailbotsHero.png";
import futLogo from "./images/futLogo.svg";
import Loading from "./Loading";
import { SelectTimezone } from "./SelectTimezone";
import { message as antMessage } from "antd";

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailbot: {},
      mailbotIsTrusted: false,
      userIsValidated: null,
      form: {},
      mailbotLoaded: false,
      userLoaded: false
    };
  }

  fullyLoaded = () => {
    return this.state.mailbotLoaded && this.state.userLoaded;
  };

  redirectIfAlreadyInstalled(mailbot) {
    if (mailbot.installed) {
      antMessage.success(`${mailbot.name} is installed and ready for use`);
      this.props.history.push(`/skills/${mailbot.id}/settings`);
    }
  }

  loadMailBot = async mailbotid => {
    try {
      const res = await mailbotsAdminBrowser.getMailBot({ mailbotid });
      this.redirectIfAlreadyInstalled(res.mailbot);
      const mailbotIsTrusted = this.props.isTrustedMailBot(
        res.mailbot.oauth2_client_id
      );
      this.setState({
        mailbot: res.mailbot || {},
        mailbotIsTrusted,
        mailbotLoaded: true
      });
    } catch (e) {
      antMessage.error("Error getting mailbot");
      this.setState({ mailbotLoaded: true });
      console.log(e);
      return false;
    }
  };

  listenForLoggedInUser = (props = null) => {
    props = props || this.props;
    // monitor when user object has been laoded so we can render UI
    if (props.loggedInUser) {
      this.setState({
        userLoaded: true
      });
    }
  };

  componentDidMount = async () => {
    const mailbotId = this.props.params.mailbotid;
    if (mailbotId) {
      await this.loadMailBot(mailbotId);
      this.listenForLoggedInUser();
      this.setState({ mailbotLoaded: true });
    } else {
      antMessage.error(
        "Sorry, something went wrong. Did you add the complete link from the email?"
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // monitor when user object has been laoded so we can render UI
    this.listenForLoggedInUser(nextProps);

    // Auto-check forms box and change message if user has already validated
    if (this.userAlreadyValidated(nextProps.loggedInUser)) {
      this.setState(state => {
        state.form.terms = true;
        return state;
      });
      console.log("user is validated, setting checkbox");
    }
  }

  componentDidUpdate() {
    // If ext trusted and user is validated, send directly to install
    if (
      this.userAlreadyValidated(this.props.loggedInUser) &&
      this.state.mailbotIsTrusted
    ) {
      window.location.href = this.state.mailbot.install_url;
    }
  }

  userAlreadyValidated = loggedInUser => {
    return Boolean(loggedInUser && loggedInUser.accepted_terms);
  };

  handleOnChange = event => {
    let type = event.target.type;
    const form = { ...this.state.form };
    switch (type) {
      case "checkbox":
        form[event.target.name] = event.target.checked;
        break;
      default:
        form[event.target.name] = event.target.value;
        break;
    }

    this.setState({
      form
    });
  };

  /**
   * TODO: De-Duplicate – this also exists in Register Component
   * Workaround to capture fields the user did not touch,
   * (ie, the timezone option), thus not firing an onChange event.
   */
  captureWholeForm = function () {
    return new Promise((resolve, reject) => {
      let fields = document.getElementsByClassName("mailbots-data");
      let form = {};
      for (let prop in fields) {
        let k = fields[prop].name;
        let v = fields[prop].value;
        if (k && v) {
          form[k] = v;
        }
        let newForm = Object.assign({}, this.state.form, form);
        this.setState(
          {
            form: { ...newForm }
          },
          resolve
        );
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    await this.captureWholeForm();
    try {
      if (!this.state.form.terms)
        throw new Error("Please accept the terms before continuing");
      let updateRes = await mailbotsAdminBrowser.userUpdateSelf({
        user: {
          timezone: this.state.form.timezone,
          accepted_terms: this.state.form.terms
        }
      });
      const redirect =
        this.state.mailbot.install_url || `/skills/${this.props.mailbotid}`;
      window.location.href = redirect;
    } catch (e) {
      console.error(e);
      antMessage.error(e.message);
    }
  };

  render() {
    const mailbotName = (
      <span style={{ whiteSpace: "nowrap" }}>
        {this.state.mailbot.name || ""}
      </span>
    );

    return (
      <div className="container-fluid" id="content" style={{ paddingTop: 30 }}>
        {this.fullyLoaded() ? (
          <div style={{ marginBottom: 200 }}>
            <section className="row">
              <div className="col-md-5 col-md-offset-4 col-sm-8 col-sm-offset-2 text-center">
                <img
                  src={futLogo}
                  alt="MailBots Logo"
                  style={{ width: 150, marginBottom: 20 }}
                />

                <br />

                {this.state.mailbot.icon && (
                  <span style={{ whiteSpace: "nowrap" }}>
                    <img
                      src={this.state.mailbot.icon}
                      alt={`${this.state.mailbot.name} Icon`}
                      style={{ height: 75, marginBottom: 20 }}
                    />
                  </span>
                )}
                {this.state.mailbot.name && false && (
                  <div className="text-left" style={{ marginBottom: 100 }}>
                    <div className="panel-body">
                      <img
                        src={this.state.mailbot.icon}
                        alt={`Icon for ${this.state.mailbot.name}`}
                        style={{ float: "left", margin: 15, width: 150 }}
                      />
                      <h2>{this.state.mailbot.name}</h2>
                      <p>By: {this.state.mailbot.developer_name}</p>
                      <p>
                        {this.state.mailbot.description.slice(0, 90)}{" "}
                        <a
                          href={`/skills/${this.state.mailbot.id}`}
                          target="_blank"
                        >
                          more...
                        </a>
                      </p>
                    </div>
                  </div>
                )}

                <div className="text-left">
                  <div className="row">
                    <div className="col-sm-12" style={{ maxWidth: 700 }}>
                      {this.userAlreadyValidated(this.props.loggedInUser) ? (
                        <div>
                          <p className="lead">
                            You are about to be redirected away from MailBots to{" "}
                            {mailbotName}, developed by{" "}
                            {this.state.mailbot.developer_name}. Please make
                            sure you trust this developer before continuing.
                          </p>
                          <button
                            type="submit"
                            className="btn btn-primary btn-mailbots btn-block"
                            onClick={() =>
                              (window.location.href =
                                this.state.mailbot.install_url)
                            }
                          >
                            Continue
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p className="lead" style={{ marginTop: 60 }}>
                            {mailbotName} was built using{" "}
                            <a
                              href="https://www.mailbots.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              MailBots,
                            </a>{" "}
                            a platform for creating and managing email-bots.
                            After confirming your MailBots account, you will be
                            redirected to {mailbotName} by{" "}
                            {this.state.mailbot.developer_name}.
                          </p>

                          <form method="post" onSubmit={this.handleSubmit}>
                            <fieldset>
                              {!this.userAlreadyValidated() && (
                                <div>
                                  <label>Confirm your timezone</label>
                                  <div className="form-group">
                                    <SelectTimezone
                                      onChange={this.handleOnChange}
                                      className="mailbots-data"
                                    />
                                  </div>
                                  <label
                                    className="form-check"
                                    htmlFor="terms"
                                    style={{ fontWeight: "normal" }}
                                  >
                                    <span style={{ marginTop: 5 }}>
                                      <input
                                        type="checkbox"
                                        style={{ margin: "0 7px 20px 0" }}
                                        required
                                        defaultChecked={this.state.form.terms}
                                        onChange={this.handleOnChange}
                                        name="terms"
                                        id="terms"
                                      />
                                    </span>
                                    I agree to MailBot 's{" "}
                                    <a
                                      className="muted"
                                      href="https://www.followupthen.com/privacy"
                                    >
                                      privacy policy
                                    </a>{" "}
                                    and{" "}
                                    <a
                                      className="muted"
                                      href="https://www.followupthen.com/terms"
                                    >
                                      terms of service
                                    </a>
                                    .
                                  </label>
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-mailbots btn-block"
                                  >
                                    Continue
                                  </button>
                                </div>
                              )}
                            </fieldset>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default withRouter(Confirm);
