import React, { Component } from "react";
import { mailbotsAdminBrowser } from "./lib/utils";
import _ from "lodash";
import Modal from "./Modal";
import Emails from "./Emails";
import { message as antMessage } from "antd";
import Card from "./Card";
import Loading from "./Loading";
import { userDate } from "./lib/utils";

export class TaskPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewThinking: false,
      previewTask: {}
    };
  }
  // Get task payload from state, which * almost * exactly matches what the API expects.

  handleLoadPreview = async task => {
    const taskProps = task;
    if (!task.command.includes("@")) {
      task.command = `${task.command}@${process.env.REACT_APP_FUT_MAILBOT_EMAIL_DOMAIN}`;
    }

    try {
      const previewPayload = {
        verbose: 1,
        webhook: 1,
        suppress_email: 1,
        task: taskProps
      };

      this.setState({ previewThinking: true });
      let previewRes = await mailbotsAdminBrowser.previewTask(previewPayload);
      const previewTask = previewRes.task;
      previewTask.messages = previewRes.messages; // Email component expects them together
      this.setState({
        previewTask,
        previewThinking: false
      });
    } catch (e) {
      alert("Error! Check browser console");
      console.error(e);
      this.setState({ previewThinking: false });
    }
  };

  /**
   * A preview task may have only been validated by date, not by extensions. If it has a verbsoe
   * response (ie, task.messages already exist) we assume we have enough data to show the preview.
   */
  componentDidMount = () => {
    // if (this.props.task.messages && this.props.task.messages.length) {
    //   this.setState({
    //     previewTask: this.props.task
    //   });
    // } else if (this.props.task) {
    if (this.props.task) {
      this.handleLoadPreview(this.props.task);
    }
  };

  currentProps;
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(this.currentProps, nextProps)) return;
    this.currentProps = nextProps;
    this.handleLoadPreview(nextProps.task);
  }

  render() {
    const cardStyle = {
      zIndex: 3,
      width: "90vw",
      maxWidth: 700
    };
    const innerCardStyle = {
      backgroundColor: "rgb(250, 250, 250)",
      overflow: "auto",
      maxHeight: "100vh"
    };
    return (
      <Card type="large" style={cardStyle} innerStyle={innerCardStyle}>
        {!this.state.previewThinking && this.state.previewTask ? (
          <div>
            <p style={{ textAlign: "center" }}>
              Sending on{" "}
              {userDate({
                timestamp: this.state.previewTask.trigger_time,
                momentDateFormat:
                  this.props.loggedInUser.preferred_date_format_js,
                timezone: this.props.loggedInUser.timezone
              })}
            </p>
            <Emails
              tasks={[this.state.previewTask]}
              onMailtoSendSuccess={() => {}}
              onMailtoSendError={() => {}}
              onMailtoPreSend={() => {
                antMessage.info(
                  `Once the task has been created these buttons will be activated`
                );
                return null; // aborts the sending process
              }}
              loggedInUser={this.props.loggedInUser}
            />
          </div>
        ) : (
          <Loading loadingText="Building preview..." />
        )}
      </Card>
    );
  }
}
