import React, { useState } from "react";
/**
 * simple div that vertically expands. Shows more/less at the bottom.
 */
const Expandable = ({
  more = "More",
  less = "Less",
  expanded,
  onExpandToggle,
  children,
  style = {},
  containerStyle = {},
  overrides = {},
  togglePlacement = "bottom"
}) => {
  const DefaultMore = () => (
    <span>
      <span className="glyphicon glyphicon-chevron-down"></span>
      {more}
    </span>
  );

  const DefaultLess = () => (
    <span>
      <span className="glyphicon glyphicon-chevron-up"></span>
      {less}
    </span>
  );

  const More = overrides.More ? overrides.More : DefaultMore;
  const Less = overrides.Less ? overrides.Less : DefaultLess;
  const Toggle = () => (
    <a
      onClick={e => {
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        onExpandToggle();
        return false;
      }}
      style={{ color: "#11a2d2", ...style }}
    >
      {expanded ? <Less /> : <More />}
    </a>
  );

  return (
    <div style={{ ...containerStyle }}>
      {togglePlacement === "top" ? <Toggle /> : null}
      {expanded ? children : null}
      {togglePlacement === "bottom" ? <Toggle /> : null}
    </div>
  );
};

// Expandable varient which controls its own state
export const ExpandableStateful = props => {
  const [expanded, setExpanded] = useState(props.initiallyExpanded || false);
  return (
    <Expandable
      expanded={expanded}
      onExpandToggle={() => setExpanded(!expanded)}
      {...props}
    >
      {props.children}
    </Expandable>
  );
};
export default Expandable;
