import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import queryString from "query-string";

/**
 * FilterLink
 * Renders a link that invokes a function that applies filters, and
 * keeps track of the link being active based on a wildcard search
 * of the filter id in the URL.
 * @param {string} id - Unique id, searched for in URL (wildcard) to
 * detect if 'active'. Should match the filterObj.filter_* value
 * (below) for 'active' state to work.
 * @param {obj} filterObj - Key / values of filters to be applied.
 * @param {string} filterObj.filter_{*} Filter names start with filter_
 * For example,{filter_due: 1521399600}. Common keys between let
 * filters can reassign the value of this filter which updates the menu
 * state accordingly.
 * @param {string} filterName - Which filter we are applying. Lets you
 * reassign the value of this filter which updates menu state accordingly.
 * @param {function} onFilter - Callback function that accepts the onClick
 * click event and the filterObject. ex: onFilter(event, filterObj).
 */
const FilterLink = ({
  id,
  filterObj,
  children,
  onFilter,
  location,
  isActive
}) => (
  <NavLink
    className="filter-link text-ellipsis"
    to="#" //click event suppressed
    isActive={
      isActive
        ? isActive
        : () => {
            const qs = queryString.parse(location.search);
            const filterValues = Object.values(qs);
            if (filterValues.includes("r")) {
            }
            const active = filterValues.some(v => v === id);
            return active;
          }
    }
    onClick={e => {
      e.preventDefault();
      onFilter(e, filterObj);
    }}
    title={typeof children === "string" ? children : null}
  >
    {children}
  </NavLink>
);

export default withRouter(FilterLink);
