import React, { useState } from "react";
import { MultiSelectInput } from "../MultiSelectInput";
import { getPersonTagOptions } from "../lib/peopleUtils";

import Modal from "../Modal";

const TagModal = props => {
  const [tags, setTags] = useState([]);

  return (
    <Modal visible={props.isVisible} onClose={() => props.onCloseModal()}>
      <div className="main-panel">
        <h5 className="modal-title" id="tagModalLabel">
          Add tags to selected people
        </h5>

        <div style={{ border: "1px solid #ddd", margin: "10px 0" }}>
          <MultiSelectInput
            controlStyle={{ paddingTop: 4, width: "100%", maxWidth: 250 }}
            name="tags"
            options={getPersonTagOptions(props.allPeopleAttributes)}
            value={tags.map(tag => ({ label: tag, value: tag }))}
            formatCreateLabel={input => input}
            onChange={event => setTags(event.map(evt => evt.value))}
          />
        </div>

        <button
          onClick={() => {
            props.onSubmit(tags);
          }}
          type="button"
          className="btn btn-primary"
          disabled={props.isBusy}
        >
          {!props.isBusy ? "Apply Tags" : "Applying Tags..."}
        </button>
        {"  "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default TagModal;
