import React, { Component } from "react";
import { mailbotsAdminBrowser, isMailBotsEmail } from "./lib/utils";
import { message as antMessage } from "antd";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import EmailEditor from "./EmailEditor";
import PropTypes from "prop-types";

/**
 * Renders an compose email UI window that actually works.
 * To get the results of the email, use the onSendSuccess callback
 * 
 * Video Walkthrough of this: https://www.youtube.com/watch?v=iPWEkpK2Gms&feature=youtu.be 
 * This component emulates an email-based interaction with MailBots. 
 * It is the EmailEditor dumb component, combined with the ability 
 * to  "send email" to MailBots (which is, in this case, an API call).
 * 
     <Emulator
        onSendSuccess={ // a function that accepts an array of tasks created by emulator }
        onPreSend={ // Trigger loading spinners, etc }
        onSendError={ // Handle emulator error }
        email={ // pass email object to pre-populate }
        status={ // Set true / false for loading spinners }
        showAddressFields={ // Bool - to show expanded email fields by default? }
        loggedInUser={ // Pass logged in user to set mailto "from" address }
      />

 */

class Emulator extends Component {
  constructor(props) {
    super(props);
  }

  handleSendEmail = async (e, referenceEmail) => {
    e.preventDefault();

    //to, cc, bcc must be array values
    function emailStringToArray(emailString) {
      if (emailString && typeof emailString === "string") {
        return emailString.split(",");
      } else if (emailString && emailString instanceof Array) {
        return emailString;
      } else {
        return [];
      }
    }

    // Toastify the webhook status messages for developer
    // TODO: Centralize. This is duplicated in LogRow.jsx.
    function showWebhookStatusMessages(responses) {
      responses.map(response => {
        if (!response.webhook) return;
        let status = response.webhook.status;
        const message = response.webhook.message;
        if (!message) return;
        const statusHashMap = {
          success: "success",
          warn: "warn",
          warning: "warn",
          fail: "error",
          info: "info"
        };
        const toastMethod = statusHashMap[status] || "info"; // ex: antMessage.warn('message');
        antMessage[toastMethod](String(message));
      });
    }

    referenceEmail.to = emailStringToArray(referenceEmail.to);
    referenceEmail.cc = emailStringToArray(referenceEmail.cc);
    referenceEmail.bcc = emailStringToArray(referenceEmail.bcc);
    referenceEmail.html = referenceEmail.html || referenceEmail.text;
    referenceEmail.from = referenceEmail.from || this.props.loggedInUser.email;

    // handle custom email headers
    try {
      if (referenceEmail.customHeaders) {
        const customHeaders = JSON.parse(referenceEmail.customHeaders);
        referenceEmail.headers = Object.assign(
          referenceEmail.headers,
          customHeaders
        );
        delete referenceEmail.customHeaders;
      }
    } catch (e) {
      return antMessage.error(
        "Failed to parse custom headers. Please enter valid JSON."
      );
    }

    if (typeof this.props.onPreSend === "function") {
      // Abort sending process by returning `null` in the onPreSend callback
      let preSendRes = this.props.onPreSend(e, referenceEmail);
      if (preSendRes === null) return;
    }
    const promises = [];
    [...referenceEmail.to, ...referenceEmail.cc, ...referenceEmail.bcc].forEach(
      recipient => {
        if (isMailBotsEmail(recipient)) {
          const payload = {};
          payload.reference_email = referenceEmail;
          payload.command = recipient.trim();
          // payload.gfr = referenceEmail.from;
          // @todo sign request https://github.com/mailbots/fut-core-api/issues/2843
          // payload.gfrs = "";
          referenceEmail.server_recipient = recipient.trim();
          if (recipient.search(/ao?\+/) !== -1) {
            payload.verbose = 1;
            payload.webhook = true;
            promises.push(mailbotsAdminBrowser.sendAction(payload));
          } else {
            promises.push(
              mailbotsAdminBrowser.createTask({
                webhook: true,
                suppress_email: 1,
                task: payload,
                verbose: 1
              })
            );
          }
        }
      }
    );

    try {
      const responses = await Promise.all(promises);
      if (responses[0] instanceof Error) {
        throw responses[0];
      }

      showWebhookStatusMessages(responses);

      // Put messages inside tasks
      const tasks = responses.map(response => {
        const task = response.task || {};
        task.messages = response.messages || [];
        return task;
      });

      if (typeof this.props.onSendSuccess === "function")
        this.props.onSendSuccess(tasks);
    } catch (err) {
      console.log(err);
      if (typeof this.props.onSendError === "function")
        this.props.onSendError(err);
    }
  };

  render() {
    return (
      <div>
        <EmailEditor
          {...this.props}
          style={{ minWidth: "100%", ...this.props.style }}
          autocompleteEmails={this.props.autocompleteEmails}
          showHeaderFields={this.props.showHeaderFields}
          handleSubmitEmail={this.handleSendEmail}
          sendButtonContent={
            <span>
              <span
                className="glyphicon glyphicon-send"
                style={{ zIndex: 500 }}
              >
                {" "}
              </span>{" "}
              SEND
            </span>
          }
        />
      </div>
    );
  }
}

Emulator.propTypes = {
  onSendSuccess: PropTypes.func.isRequired,
  onSendError: PropTypes.func,
  onPreSend: PropTypes.func,
  email: PropTypes.object,
  status: PropTypes.string, // "loading" or not TODO: Booleanize

  // passed through to email editor
  loggedInUser: PropTypes.object,
  showAddressFields: PropTypes.bool
};

export default withRouter(Emulator);
