import {
  mailbotsAdminBrowser,
  convertMailBotToSkill,
  mailbotIsInstalled
} from "./utils";
import { message as antMessage } from "antd";
/**
 * Fetch all active skills on that user's account, including those that are automatically enabled.
 * If an array of MailBots is passed, it does not again fetch installed MailBots.
 */
export async function getActiveSkills(cachedMailBots) {
  try {
    let mailbots = cachedMailBots;
    if (!mailbots) {
      const res = await mailbotsAdminBrowser.getMailBots({
        inc_installed: 1,
        inc_published: 1
      });
      mailbots = res.mailbots;
    }
    return mailbots.filter(mailbotIsInstalled).map(convertMailBotToSkill);
  } catch (e) {
    console.error(e);
    antMessage.error("There was an error fetching installed skills");
    return [];
  }
}

// Example skills: See ISkillInfo in fut-mailbot
// const futMbSkills = [
//   {
//     name: "Response Detection (-r)",
//     description: "Cancel the followup if a reply is received",
//     icon_url: "",
//     search_key: "response_detection",
//     flag: "r",
//     more_info_url: "https://www.followupthen.com/how#response",
//     mailbotid: null, // If it's a MailBot
//     show_as_search_filter: true, // Search futs by this skill?
//     show_as_scheduling_option: true // Show while scheduling?
//   }];
// export default futMbSkills;
