import React, { useEffect, useState } from "react";

// for use in class components. Use hook for fun components
export const isOnMobile = () => window && window.innerWidth < 768;

export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function isMobileWidth(width) {
    return width <= 768;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isMobile: isMobileWidth(width) };
};
