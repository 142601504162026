import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import MailBotChart from "./MailBotChart";
import _ from "lodash";

class MailBotUsageStats extends Component {
  getDeliverabilityData = () => {
    let graphableData = [];
    let { deliverability } = this.props.metrics;
    for (let key in deliverability) {
      let date = key;
      let sent = _.get(deliverability[key], "sent", 0);
      let deferred = _.get(deliverability[key], "deferred", 0);
      let bounced = _.get(deliverability[key], "bounced", 0);
      graphableData.push({ date, sent, deferred, bounced });
    }
    return graphableData;
  };

  render = () => {
    return (
      <div className="main-panel">
        <div className="row">
          <div className="col-sm-7">
            <h1>Bot Activity</h1>
            <h4>Active Users</h4>
            <MailBotChart data={this.props.metrics.unique_users}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#11a2d2"
                strokeWidth={3}
              />
            </MailBotChart>

            <h4>Email Sent</h4>
            <MailBotChart data={this.props.metrics.email_sent}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#11a2d2"
                strokeWidth={3}
              />
            </MailBotChart>

            <h4>Webhooks</h4>
            <MailBotChart data={this.props.metrics.daily_webhooks}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#11a2d2"
                strokeWidth={3}
              />
            </MailBotChart>

            <h4>Deliverability</h4>
            <LineChart
              width={600}
              height={200}
              data={this.getDeliverabilityData()}
              margin={{ top: 25, right: 0, left: 0, bottom: 5 }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <CartesianGrid strokeDasharray="2 2" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="sent"
                stroke="green"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="deferred"
                stroke="orange"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="bounced"
                stroke="red"
                strokeWidth={3}
              />
            </LineChart>

            <h4>HTTP Response Codes</h4>
            <MailBotChart data={this.props.metrics.http_response_codes}>
              <Line
                type="monotone"
                dataKey="200"
                stroke="green"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="400"
                stroke="yellow"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="404"
                stroke="orange"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="500"
                stroke="red"
                strokeWidth={3}
              />
            </MailBotChart>
          </div>
        </div>
      </div>
    );
  };
}

export default MailBotUsageStats;
