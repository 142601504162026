import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as bluebirdPromise from "bluebird";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import nprogress from "nprogress";
import Card from "./Card";
import {
  getFeatureLimits,
  userGetFeatureLimit
} from "./Billing/billingHelpers";
import { GlobalContext } from "./App";
import { setShowUpgradeModal } from "./ModalManager";
import { ButtonOnBg } from "./ButtonOnBg";
import { isOnMobile } from "./lib/hookisMobile";

const BulkContainer = styled.div``;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  @media (max-width: 768px) {
    padding-left: 3px;
  }
`;

const ProgressContainer = styled.div`
  position: relative;
  border-radius: 1px;
  width: 100%;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #11a2d2;
  border-radius: 1px;
  transition: all 300ms;
`;

// interface IBulkActionError {
//   type: "error" | "warning",
//   message: string,
//   link: {
//     url: string,
//     name: string
//   },
//   logs?: Array<{
//     text: string,
//     iconUrl: string
//   }>
// }

// interface IBulkAction {
//   name: string, // displayed name
//   glyph: string, // button icon (ex. 'ok-circle')

//   // callback method which will be called before
//   // running the bulk iteration.
//   // if the callback returns a falsey value,
//   // the bulk action will not fire
//   preRun: () => Promise<any>,

//   // callback method which will be called for
//   // each selected item.
//   // it can throw error objects of type IBulkActionError
//   // and the component can show detailed error tables
//   bulkIterator: (item: any) => Promise<any>,

//   // callback method which will be called after
//   // running the bulk iteration.
//   // will be passed two arrays for errors and warnings
//   // that were thrown during the bulk iteration
//   postRun: (errors, warnings) => Promise<any>

//   // override the default action UI with a custom
//   // method that returns HTML.
//   customJsx: () => React.JSX
// }

/**
 * Component used to display a dynamic list of bulk action buttons.
 * It renders a progress bar when running an action.
 * The available actions must be provided via props. Each action
 * can provide 3 types of callback functions to be ran at different
 * stages of the bulk run.
 *
 * @param {object} props
 * @param {IBulkAction[]} props.actions - list of action buttons to display
 * @param {any[]} props.items - array of selected items
 * @param {number} props.totalItemsCount - number of total items (needed for determining toggle all checkbox state)
 * @param {(checked: boolean) => {}} props.toggleAll - callback method for handling check/uncheck all items
 */
export const BulkActions = props => {
  const availableActions = props.actions || [];
  let items = props.items || [];
  const allItemsCount = items.length;
  const checkBoxRef = React.createRef();

  const [totalBulkActionsCount, setTotalBulkActionsCount] = useState(0);
  const [completedBulkActionsCount, setCompletedBulkActionsCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [isErrorsModalVisible, setIsErrorsModalVisible] = useState(false);
  const { loggedInUser } = useContext(GlobalContext);

  useEffect(() => {
    const inputEl = checkBoxRef.current;

    if (inputEl) {
      const totalItemsCount = props.totalItemsCount || 0;
      if (allItemsCount > 0 && allItemsCount < totalItemsCount) {
        inputEl.indeterminate = true;
      } else if (allItemsCount === 0) {
        inputEl.indeterminate = false;
        inputEl.checked = false;
      } else if (allItemsCount === totalItemsCount) {
        inputEl.indeterminate = false;
        inputEl.checked = true;
      }
    }
  }, [allItemsCount]);

  const getButtonColor = () => (items.length > 0 ? "#11a2d2" : "#999");

  const toggleAll = checked => {
    const handler = props.toggleAll;
    if (handler) handler(checked);
  };

  const runBulkPreRun = (action, e) => {
    if (!bulkActionsAvailable(e)) return;
    if (items.length === 0) {
      // if there are no selected items, we got nothing to do
      return;
    }

    // if there are too many items selected, don't run the action
    // and show an error
    const MAX_SELECTED_ITEMS = 100;
    if (items.length > MAX_SELECTED_ITEMS) {
      setErrors([
        {
          message: `Please choose fewer than ${MAX_SELECTED_ITEMS} items.`
        }
      ]);
      setIsErrorsModalVisible(true);
      return;
    }

    // run pre hook
    const preRunHandler = action.preRun;
    if (preRunHandler) {
      const callback = preRunResult => {
        // only run the bulk action if the pre run returned a truthy value
        if (preRunResult) runBulk(action, preRunResult);
      };

      // run the handler
      preRunHandler(callback);
    } else {
      // if the action doesn't have a preRun handler,
      // skip it and run the iterator
      runBulk(action);
    }
  };

  const runBulk = async (action, preRunResult) => {
    const sleep = ms => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    const inFlightErrors = [];
    const inFlightWarnings = [];
    setWarnings(inFlightWarnings);
    setErrors(inFlightErrors);
    try {
      let localCompletedCount = 0;
      setCompletedBulkActionsCount(0);
      setTotalBulkActionsCount(allItemsCount);

      // start progress bar
      nprogress.start();

      // iterate through items and run the bulkIterator
      const bulkReq = bluebirdPromise.map(
        items,
        async (item, crtItemIndex) => {
          for (let i = 0; i < 6; i++) {
            try {
              await action.bulkIterator(item, preRunResult, crtItemIndex);
              setCompletedBulkActionsCount(++localCompletedCount);
              nprogress.set(localCompletedCount / allItemsCount);

              break;
            } catch (error) {
              if (error.statusCode === 429 && i < 5) {
                // retry quota exceeded exceptions with exponential backoff timeouts
                console.log(
                  "request rejected because API calls quota was exceeded",
                  error
                );
                await sleep(300 * (i + 1));
              } else {
                console.error(error);
                if (error.type === "warning") {
                  inFlightWarnings.push(error);
                  setWarnings(inFlightWarnings);
                } else {
                  inFlightErrors.push(error);
                  setErrors(inFlightErrors);
                }

                setCompletedBulkActionsCount(++localCompletedCount);
                nprogress.set(localCompletedCount / allItemsCount);
                break;
              }
            }
          }
        },
        { concurrency: 4 }
      );

      await bulkReq;

      // run post hook
      const postRun = action.postRun;
      if (postRun) {
        await postRun(inFlightErrors, inFlightWarnings, items);
      }

      // force show error modal if any where thrown during bulk run
      if (inFlightErrors.length > 0 || inFlightWarnings.length > 0)
        setIsErrorsModalVisible(true);
    } catch (error) {
      console.error(error);
    }

    nprogress.done();
  };

  const ErrorsModal = () => (
    <Modal
      visible={isErrorsModalVisible}
      onClose={() => setIsErrorsModalVisible(false)}
    >
      <Card type="large">
        <div
          className="modal-body"
          style={{
            maxHeight: "calc(100vh - 220px)",
            overflow: "auto",
            maxWidth: 600
          }}
        >
          <h2>Oops...</h2>
          <ul style={{ padding: 0, listStyle: "none" }}>
            {errors.map((error, i) => {
              return (
                <li key={i}>
                  {error.message}
                  <br />
                  {!!error.link ? (
                    <div>
                      <Link to={error.link.url} target="_blank">
                        {error.link.name}
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
            {warnings.map((warning, i) => {
              return (
                <li key={i}>
                  <div className="row">
                    <div className="col-md-4">
                      {!!warning.link ? (
                        <div>
                          <Link to={warning.link.url} target="_blank">
                            <span
                              className="glyphicon glyphicon-exclamation-sign"
                              style={{
                                color: "#d02963",
                                marginRight: 5
                              }}
                            ></span>
                            {warning.link.name}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-8">
                      {warning.logs.map(log => (
                        <div>
                          <img
                            src={
                              log.iconUrl ||
                              "https://files-38348em97-mailbots.vercel.app/"
                            }
                            width="20px"
                            style={{ marginRight: 3 }}
                            alt={`Icon: ${log.text}`}
                          />{" "}
                          <span>{log.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsErrorsModalVisible(false)}
          >
            Close
          </button>
        </div>
      </Card>
    </Modal>
  );

  // called both with onClick handler, and onLoad (with no event)
  const bulkActionsAvailable = e => {
    if (process.env.REACT_APP_BILLING_ENFORCED !== "yes") return true;
    if (userGetFeatureLimit({ feature: "bulk_actions", loggedInUser })) {
      return true;
    } else {
      // only show the window alert if this is an onClick context
      if (e) {
        e.stopPropagation();
        window.alert(
          "Upgrade your account to enable this and other useful features"
        );
      }
      return false;
    }
  };

  const StyledCheckButton = styled.button`
            padding: 8px 8px 8px 16px;
            height: 100%;
            color: ${getButtonColor()},
            background-color: transparent`;

  return (
    <BulkContainer>
      <ErrorsModal></ErrorsModal>
      {/* <ProgressContainer>
        <ProgressBar
          style={{
            width:
              (completedBulkActionsCount / totalBulkActionsCount) * 100 + "%",
            minWidth: totalBulkActionsCount === 0 ? "0" : "10%"
          }}
        ></ProgressBar>
      </ProgressContainer> */}

      <ControlsContainer>
        {/* select all togler */}
        <button
          style={{
            padding: 5,
            paddingLeft: isOnMobile() ? 15 : 30,
            backgroundColor: "transparent",
            position: "relative"
          }}
          className="btn btn-secondary btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Select All Items"
          key="Select All Items"
          onClick={e => toggleAll(e.target.checked)}
        >
          <input
            ref={checkBoxRef}
            type="checkbox"
            id="selectAll"
            style={{ transform: "scale(1.3)" }}
          ></input>
        </button>
        <ul className="nav">
          <li className="dropdown">
            <ButtonOnBg
              data-toggle="dropdown"
              className="proto-button"
              icon="DownOutlined"
              text={isOnMobile() ? null : "Bulk Actions"}
            />
            <ul className="dropdown-menu" role="menu">
              {/* custom actions */}
              {!bulkActionsAvailable() ? (
                <li>
                  <a
                    style={{
                      backgroundColor: "#f2f2f2",
                      fontSize: 12,
                      padding: 7,
                      borderRadius: 4,
                      marginBottom: 3
                    }}
                    onClick={() => setShowUpgradeModal(true)}
                  >
                    ⭐️ Upgrade to enable bulk actions
                  </a>
                </li>
              ) : null}
              {availableActions.map(action => (
                <li key={action.name}>
                  {action.customJsx ? (
                    action.customJsx({ onClick: bulkActionsAvailable, action })
                  ) : (
                    <a onClick={e => runBulkPreRun(action, e)}>
                      {typeof action.glyph === "string" ? (
                        <span
                          className={"glyphicon glyphicon-" + action.glyph}
                        ></span>
                      ) : (
                        action.glyph // Assume React Element
                      )}
                      {" " + action.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </ControlsContainer>
    </BulkContainer>
  );
};
