import React, { useContext } from "react";
import { getSkillsAppliedToTask } from "../lib/utils";
import { GlobalContext } from "../App";
import { Tooltip } from "antd";

export const SkillsAppliedToTask = ({ task, history, showLimit = 3 }) => {
  const { availableSkills } = useContext(GlobalContext);

  let skillsApplied = getSkillsAppliedToTask(task, availableSkills);

  // handle andXMore "ex: -sms, -t, -r and 3 more";
  let andXMore = null;
  if (skillsApplied.length > showLimit) {
    andXMore = (
      <Tooltip
        title={
          <>
            {skillsApplied.map(sk => (
              <>
                <a
                  style={{ color: "white" }}
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/skills/${sk.subdomain}`);
                  }}
                  key={sk.domain}
                >
                  {sk.name}
                </a>
                <br />
              </>
            ))}
          </>
        }
      >
        <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
          {" "}
          +{skillsApplied.length - showLimit} more
        </span>
      </Tooltip>
    );
    skillsApplied = skillsApplied.slice(0, showLimit);
  }

  return (
    <div
      style={{
        flex: 1,
        display: "inline-flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 10,
        whiteSpace: "nowrap"
      }}
    >
      {skillsApplied.map(sk => (
        <Tooltip title={sk.name} key={sk.subdomain}>
          <a
            onClick={e => {
              e.stopPropagation();
              history.push(`/skills/${sk.subdomain}`);
              return false;
            }}
          >
            <img
              key={sk.subdomain}
              style={{ width: 25, paddingLeft: -10 }}
              src={sk.icon}
            />
          </a>
        </Tooltip>
      ))}{" "}
      {andXMore}
    </div>
  );
};
