import React from "react";
import FilterLink from "../FilterLink";

const isAnyFilter = () => {
  return window.location.search.indexOf("skills") === -1;
};

const TaskFilterBySkill = props => {
  return (
    <React.Fragment>
      <li key="skill" className="subnav-header">
        Skill
      </li>
      <li key="skill_any">
        <FilterLink
          id="all_skills"
          filterObj={{
            skills: null
          }}
          isActive={() => isAnyFilter()}
          onFilter={props.applyFilter}
        >
          Any
        </FilterLink>
      </li>
      {props.activeSkills.map(skill => (
        <li key={skill.name}>
          <FilterLink
            id={skill.search_key}
            filterObj={{
              skills: skill.search_key
            }}
            onFilter={props.applyFilter}
          >
            {skill.name}
          </FilterLink>
        </li>
      ))}
      {/*
      // Manually add skills to search by like this:
      <li>
        <FilterLink
          id="github"
          filterObj={{
            skills: "github"
          }}
          onFilter={this.applyFilter}
        >
          GitHub (-github)
        </FilterLink>
      </li> */}
    </React.Fragment>
  );
};

export default TaskFilterBySkill;
